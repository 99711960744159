<template>
  <v-autocomplete
    v-model="assignment"
    prepend-inner-icon="$mdi-account-tie"
    placeholder="Who would you like to assign to?"
    label="Assignment"
    outlined
    dense
    return-object
    clearable
    no-filter
    hide-no-data
    :data-testid="dataTestid"
    :item-text="getFullName"
    :disabled="disabled"
    :search-input.sync="search"
    :success="success"
    :error-messages="errorMessages"
    :items="assignments"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
    @click:clear="assignment = null"
  >
    <template #append v-if="hasAppendSlot">
      <slot name="append" />
    </template>
    <template #append-outer v-if="hasAppendOuterSlot">
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import { searchAddHierarchy } from "@/api/appointment.service";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  props: {
    success: Boolean,
    id: Number,
    errorMessages: Array,
    value: Object,
    clearable: Boolean,
    disabled: Boolean,
    dataTestid: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    let assignments = [];
    let assignment = null;
    if (this.value && Object.keys(this.value).length && this.value?.id) {
      assignment = this.value;
      assignments = [this.value];
    }
    return {
      assignments,
      assignment,
      timer: null,
      loading: false,
      search: null
    };
  },
  computed: {
    hasAppendSlot() {
      return !!this.$slots["append"];
    },
    hasAppendOuterSlot() {
      return !!this.$slots["append-outer"];
    }
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.assignment = null;
        this.assignments = [];
        return;
      }
      this.assignment = value;
      this.assignments = [value];
    },
    assignment(value) {
      this.$emit("input", value);
    },
    search(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (
        this.assignments.some(
          assignment => this.getFullName(assignment) === value
        ) &&
        this.assignments.length === 1
      ) {
        return;
      }

      if (this.assignmentTimer) {
        clearTimeout(this.assignmentTimer);
      }

      this.assignmentTimer = setTimeout(this.getItems, 400);
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    getFullName(row) {
      const status = row.status ? `Status: ${row.status}` : null;
      return [row.ownableName, row.name, status].filter(Boolean).join(" · ");
    },
    async getItems() {
      this.loading = true;
      try {
        const assignments = await searchAddHierarchy(this.id, this.search);
        assignments.sort((a, b) =>
          this.getFullName(a).localeCompare(this.getFullName(b))
        );
        this.assignments = assignments;
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
