import { getHttpClient } from "@/http-client";
import { serializeObject } from "@/util/helpers";
import SmokerChartFactory from "@/factories/SmokerChartFactory";

const baseUrl = "api/boss/smoker_charts";

function SmokerChart(model = {}) {
  return {
    id: model?.id,
    carrierName: model?.carrierName,
    name: model?.name,
    complete: model?.complete,
    documentUid: model?.documentUid
  };
}

function setSmokerChartFromRequest(req = {}) {
  const sc = SmokerChart();

  sc.carrierName = req?.carrier?.name;
  sc.id = req?.id;
  sc.name = req?.name;
  sc.complete = req?.complete;
  sc.documentUid = req?.document?.uid;

  return sc;
}

let cancelToken = null;
export async function getSmokerCharts(params) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!response?.data) return;

  return response.data.smoker_charts.map(setSmokerChartFromRequest);
}

export async function updateCompleteness(id, complete) {
  return getHttpClient().put(`${baseUrl}/${id}`, {
    smoker_chart: { complete }
  });
}

export async function uploadSmokerChartDocument(id, file) {
  return getHttpClient().post(
    `${baseUrl}/${id}/documents`,
    await serializeObject({ file })
  );
}

export const getAll = async id => {
  const params = new URLSearchParams();
  params.append("carrier_id", id);
  const { data } = await getHttpClient().get(`${baseUrl}/all`, {
    params
  });

  return data.smoker_charts.map(rawSmokerChart => {
    const smokerChart = new SmokerChartFactory();
    smokerChart.setFromRequest(rawSmokerChart);
    return smokerChart;
  });
};

export const searchSmokerCharts = name => {
  const params = new URLSearchParams();
  params.append("name", name);
  return getHttpClient().get(`${baseUrl}/search`, { params });
};
