import { ImpairedRiskQuote } from "@/factories/ImpairedRiskQuote";
import {
  getQuickQuote,
  updateQuickQuote,
  addQuickQuoteAccess,
  removeQuickQuotesAccess,
  updateQuickQuoteAccess
} from "@/api/quick-quotes.service";
import { defineStore } from "pinia";
import { set } from "vue";

export const useImpairedRiskQuoteView = defineStore(
  "impaired-risk-quote-view",
  {
    state: () => ({
      ...ImpairedRiskQuote()
    }),
    getters: {
      simplifiedOffers() {
        return this.offers.map(o => ({
          key: `${o.id}`,
          title: o.carrier.name,
          text: o.offer,
          avatar: o.carrier.avatarUrl,
          carrierId: o.carrier.id,
          pending: !o.offer
        }));
      },
      offersWithResponses() {
        return this.simplifiedOffers.filter(o => !o.pending);
      },
      pendingOffers() {
        return this.simplifiedOffers.filter(o => o.pending);
      }
    },
    actions: {
      async getData(id) {
        const impairedRiskQuote = await getQuickQuote(id);
        set(this, "$state", impairedRiskQuote);
      },
      updateAttribute(attribute) {
        const body = {
          status: { status: this.status }
        }[attribute];

        return updateQuickQuote(this.id, body);
      },
      createAccess(access) {
        const params = {
          ownable_id: access.id,
          ownable_type: access.type
        };
        return addQuickQuoteAccess(this.id, params);
      },
      deleteAccess(accessId) {
        const access = this.room.find(a => a.id === accessId);
        return removeQuickQuotesAccess(this.id, access.id);
      },
      updateAccess(accessId, attribute) {
        const access = this.room.find(a => a.id === accessId);
        const map = {
          email: { email: access.email ? 1 : 0 }
        };

        return updateQuickQuoteAccess(this.id, access.id, map[attribute]);
      }
    }
  }
);
