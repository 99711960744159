import sanitizeHtml from "sanitize-html";

export default value =>
  sanitizeHtml(value, {
    allowedTags: ["a", "div", "br", "li", "html", "body", "p", "ul", "ol"],
    allowedAttributes: {
      a: ["href"]
    },
    disallowedTagsMode: "escape",
    selfClosing: ["br"],
    allowedSchemes: ["http", "https", "mailto", "tel"],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ["href"],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false
  });

export const customChatTemplateSanitizer = value => {
  return sanitizeHtml(value, {
    allowedTags: [
      "a",
      "b",
      "blockquote",
      "body",
      "br",
      "code",
      "div",
      "em",
      "center",
      "title",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "head",
      "html",
      "i",
      "img",
      "li",
      "ol",
      "p",
      "pre",
      "small",
      "span",
      "strong",
      "sub",
      "sup",
      "table",
      "tbody",
      "td",
      "tfoot",
      "th",
      "thead",
      "tr",
      "u",
      "ul"

      // new tags for templates?
      // "style",
      // "meta",
      // "link"
    ],
    allowedAttributes: {
      a: ["href"],
      img: ["src"],
      "*": ["style"],
      h1: ["style"]
    },
    disallowedTagsMode: "discard",
    allowedSchemes: ["https", "mailto", "tel"],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false
  });
};
