<template>
  <v-data-table
    must-sort
    data-testid="application-versions-table"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :server-items-length="table.meta.value.total"
    :loading="table.loading.value"
    :options="table.options.value"
    :footer-props="table.footerProps.value"
    @update:options="updateOptions"
  >
    <template #top>
      <v-row class="ma-0" align="center">
        <h1 class="text-h5 pa-3">
          {{ props.title }}
        </h1>
        <v-spacer />
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              data-testid="application-versions-table-refresh"
              @click="getData"
            >
              <v-icon> {{ mdiRefresh }} </v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-row>
      <v-divider />
    </template>

    <template #[`item.changedBy`]="{ item }">
      <span v-if="item.changedBy">
        {{ item.changedBy.email }}
      </span>
      <span v-else> System </span>
    </template>

    <template #[`item.changedObject`]="{ item }">
      <template v-if="item.changedObject">
        <router-link
          v-if="item.changedObject.routerLink"
          :to="item.changedObject.routerLink"
        >
          {{ item.changedObject.type }} #{{ item.changedObject.id }}
        </router-link>
        <span v-else>
          {{ item.changedObject.type }} #{{ item.changedObject.id }}
        </span>
      </template>
    </template>

    <template #[`item.changes`]="{ item }">
      <application-version-item :changes="item.changes" />
    </template>

    <template #[`item.createdAt`]="{ item }">
      {{ timestampFormatter(item.createdAt, null, "date-time") }}
    </template>
  </v-data-table>
</template>

<script setup>
import ApplicationVersionItem from "@/components/application-versions/ApplicationVersionItem.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import { mdiRefresh } from "@mdi/js";

import { parseErrorMessage, timestampFormatter } from "@/util/helpers";
import { getApplicationVersions } from "@/api/application-versions.service";

import { useSnackbarStore } from "@/stores/snackbar";
import { useTable } from "@/composables/table.composable";
import { defineProps } from "vue";

const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  changesById: {
    type: Number,
    required: false
  },
  changesByType: {
    type: String,
    required: false
  },
  changesToId: {
    type: Number,
    required: false
  },
  changesToType: {
    type: String,
    required: false
  }
});

if (
  !(props.changesById && props.changesByType) &&
  !(props.changesToId && props.changesToType)
) {
  throw new Error(
    "Either changesById and changesByType or changesToId and changesToType must be provided"
  );
}

const changesBy = props.changesById && props.changesByType;

const snackbar = useSnackbarStore();

const table = useTable({
  shouldIncludeCancelToken: true,
  getData: getApplicationVersions,
  options: new TableOptions(["createdAt"], [true]),
  headers: [
    new TableHeader({
      text: "Created At",
      value: "createdAt",
      map: "createdAt",
      sortFilterMap: "created_at",
      width: "200px",
      ...TableHeader.IS_SORTABLE
    }),
    changesBy
      ? null
      : new TableHeader({
          text: "Changed By",
          value: "changedBy",
          map: "changedBy",
          sortFilterMap: "changedBy",
          width: "200px"
        }),
    changesBy
      ? new TableHeader({
          text: "Changed Object",
          value: "changedObject",
          map: "changedObject",
          width: "200px"
        })
      : null,

    new TableHeader({
      text: "Changes",
      value: "changes",
      map: "changes",
      sortFilterMap: "changes"
    })
  ].filter(Boolean)
});

async function getData() {
  try {
    const additionalFilter = {};
    if (changesBy) {
      additionalFilter.changes_by_id = props.changesById;
      additionalFilter.changes_by_type = props.changesByType;
    } else {
      additionalFilter.changes_to_id = props.changesToId;
      additionalFilter.changes_to_type = props.changesToType;
    }
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateOptions(newOptions) {
  if (table.optionsEquivalence(newOptions)) return;
  table.options.value = newOptions;
  getData();
}

getData();
</script>
