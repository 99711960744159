export default class TableOptions {
  groupBy = [];
  groupDesc = [];
  sortBy = [];
  sortDesc = [];
  itemsPerPage = 10;
  page = 1;
  status = null;
  constructor(initialSortBy = undefined, initialDesc = undefined) {
    this.sortBy = initialSortBy || [];
    this.sortDesc = initialDesc || [];
  }
}
