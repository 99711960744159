<template>
  <sign-up-bad-referral
    v-if="signupStore.invalidCode"
    class="preliminary-card"
  />
  <sign-up-form class="preliminary-card" v-else />
</template>

<script setup>
import SignUpForm from "@/components/auth/SignUpForm.vue";
import SignUpBadReferral from "@/components/auth/SignUpBadReferral.vue";
import { useSignupStore } from "@/stores/sign-up";

const signupStore = useSignupStore();
</script>
