<template>
  <v-card class="preliminary-card">
    <v-col>
      <v-row class="ma-0" align="center" justify="center">
        <app-logo large />
      </v-row>
    </v-col>

    <v-card-title> Onboarding </v-card-title>
    <v-card-subtitle class="text-left">
      Just a few more pieces of information to get started.
    </v-card-subtitle>
    <v-divider />
    <v-card color="section" flat tile class="rounded-b">
      <v-card-text class="text-left">
        <label
          for="npn-question"
          class="text-left mb-1"
          style="font-size: 1rem"
        >
          Do you have a National Producer Number?
        </label>
        <v-radio-group
          v-model="licensed"
          row
          class="mt-0"
          id="npn-question"
          dense
          :success="answeredLicensed"
        >
          <v-radio label="Yes" :value="true" data-testid="is-licensed" />
          <v-radio label="No" :value="false" data-testid="not-licensed" />
        </v-radio-group>

        <v-scroll-x-transition mode="out-in">
          <fieldset
            v-if="licensed === true"
            class="width-full no-border"
            key="licensed"
          >
            <v-row dense>
              <v-col cols="12">
                <legend class="text-left mb-1" style="font-size: 1rem">
                  What is your National Producer Number?
                </legend>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="onboardingData.npn"
                  prepend-inner-icon="$mdi-pound"
                  outlined
                  label="National Producer Number"
                  type="text"
                  inputmode="numeric"
                  persistent-hint
                  dense
                  hint=" "
                  autofocus
                  data-testid="npn"
                  :disabled="loading"
                  :success="
                    npnValidation.success && Boolean(onboardingData.npn)
                  "
                  :error-messages="npnValidation.errorMessages"
                >
                  <template #message="{ message }">
                    <v-row class="pa-3">
                      {{ message }}
                      <v-spacer />
                      <a
                        href="https://www.nipr.com/PacNpnSearch.htm"
                        target="_blank"
                      >
                        Don't know your NPN?
                      </a>
                    </v-row>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </fieldset>
          <fieldset
            v-else-if="licensed === false"
            key="unlicensed"
            class="no-border"
          >
            <v-row dense>
              <v-col cols="12">
                <legend class="text-left mb-1" style="font-size: 1rem">
                  What is your name?
                </legend>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="onboardingData.firstName"
                  label="First Name"
                  outlined
                  dense
                  autofocus
                  hint=" "
                  :prepend-inner-icon="mdiAccount"
                  data-testid="first-name"
                  :disabled="loading"
                  :success="
                    firstNameValidation.success &&
                    Boolean(onboardingData.firstName)
                  "
                  :error-messages="firstNameValidation.errorMessages"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="onboardingData.lastName"
                  label="Last Name"
                  outlined
                  dense
                  data-testid="last-name"
                  :prepend-inner-icon="mdiAccountMultiple"
                  :disabled="loading"
                  :success="
                    lastNameValidation.success &&
                    Boolean(onboardingData.lastName)
                  "
                  :error-messages="lastNameValidation.errorMessages"
                />
              </v-col>
            </v-row>
          </fieldset>
        </v-scroll-x-transition>
        <v-scroll-y-transition mode="out-in">
          <v-row v-if="answeredLicensed" dense>
            <v-col cols="12">
              <label
                for="emails-question"
                class="text-left mb-1"
                style="font-size: 1rem"
              >
                Subscribe to marketing emails?
              </label>
              <v-radio-group
                v-model="onboardingData.marketing"
                row
                dense
                class="mt-0"
                id="emails-question"
                :success="marketingValidation.success"
                :error-messages="marketingValidation.errorMessages"
              >
                <v-radio
                  label="Yes"
                  :value="true"
                  data-testid="marketing-yes"
                />
                <v-radio label="No" :value="false" data-testid="marketing-no" />
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <div class="checkbox-width">
                <v-checkbox
                  v-model="onboardingData.termsAccepted"
                  dense
                  class="mt-0"
                  data-testid="actions"
                  :success="termsAcceptedValidation.success"
                  :error-messages="termsAcceptedValidation.errorMessages"
                  :disabled="loading"
                >
                  <template #label>
                    I agree to the
                    <a
                      href="https://github.com/back9ins/terms"
                      target="_blank"
                      class="pl-1"
                      @click.stop="event => event.stopPropagation()"
                    >
                      terms and conditions.
                    </a>
                  </template>
                </v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-scroll-y-transition>
      </v-card-text>
      <v-scroll-y-transition mode="out-in">
        <v-card-actions v-if="answeredLicensed">
          <v-btn
            color="primary"
            class="text-none"
            block
            data-testid="continue"
            :loading="loading"
            @click="submit"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-scroll-y-transition>
    </v-card>
  </v-card>
</template>

<script setup>
import AppLogo from "@/components/AppLogo.vue";

import {
  computedValidation,
  someTextValidator,
  parseErrorMessage
} from "@/util/helpers";
import useVuelidate from "@vuelidate/core";
import { computed, ref } from "vue";
import { createAgentForUser } from "@/api/agents.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useRouter } from "@/composables/compatible.composables";
import { useHead } from "@unhead/vue";
import { mdiAccount, mdiAccountMultiple } from "@mdi/js";

useHead({
  title: "New User Onboarding"
});

const snackbar = useSnackbarStore();

const onboardingData = ref({
  npn: "",
  marketing: null,
  firstName: "",
  lastName: "",
  termsAccepted: false
});

const loading = ref(false);

const licensed = ref(null);

const answeredLicensed = computed(() => licensed.value !== null);

const v$ = useVuelidate(
  {
    onboardingData: {
      npn: {
        required: v => !licensed.value || Boolean(v),
        minLength: v => !licensed.value || `${v}`.length >= 4
      },
      marketing: {
        required: v => [true, false].includes(v)
      },
      termsAccepted: {
        required: v => v === true
      },
      firstName: {
        required: v => someTextValidator(!licensed.value, v, 2)
      },
      lastName: {
        required: v => someTextValidator(!licensed.value, v, 2)
      }
    }
  },
  { onboardingData },
  { $autoDirty: true, $scope: null }
);

const npnValidation = computedValidation(v$.value.onboardingData.npn, {
  required: "Required",
  minLength: "Must be 4 characters long"
});
const marketingValidation = computedValidation(
  v$.value.onboardingData.marketing,
  { required: "Required" }
);
const termsAcceptedValidation = computedValidation(
  v$.value.onboardingData.termsAccepted,
  {
    required: "Required"
  }
);
const firstNameValidation = computedValidation(
  v$.value.onboardingData.firstName,
  {
    required: "Required"
  }
);
const lastNameValidation = computedValidation(
  v$.value.onboardingData.lastName,
  {
    required: "Required"
  }
);

const router = useRouter();
async function submit() {
  const valid = await v$.value.$validate();
  if (!valid) return;

  const body = { marketing: onboardingData.value.marketing };

  if (licensed.value) {
    body.npn = onboardingData.value.npn;
  } else {
    body.first_name = onboardingData.value.firstName;
    body.last_name = onboardingData.value.lastName;
  }

  try {
    loading.value = true;
    await createAgentForUser(body);
    if (router) router.push({ name: "Home" });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
</script>
