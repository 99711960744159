import { getHttpClient } from "@/http-client";
const baseUrl = "api/boss/transactions";

export function createTransaction(params) {
  const query = new URLSearchParams();
  query.append("create_payments", true);
  return getHttpClient().post(`${baseUrl}?${query.toString()}`, params);
}

export function deleteTransaction(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}

export const simulateTransaction = async body => {
  let transaction;
  let isErrored = false;
  try {
    const { data } = await getHttpClient().post(`${baseUrl}?simulate`, body);
    transaction = data.transaction;
  } catch (e) {
    if (!e?.response?.data?.transaction) throw e;
    transaction = e.response.data.transaction;
    isErrored = true;
  }

  const {
    forecast,
    payments,
    errors,
    commissions,
    override: finalOverride
  } = transaction;

  return { forecast, payments, errors, commissions, finalOverride, isErrored };
};

let caseTransactionCancel = null;
export const getCaseTransactionsAndParties = async ({
  caseId,
  commissionType
}) => {
  if (caseTransactionCancel !== null) {
    caseTransactionCancel.cancel("Operation canceled due to new request.");
  }
  caseTransactionCancel = getHttpClient().CancelToken.source();
  const params = new URLSearchParams();
  params.append("case_id", caseId);
  params.append("commission_type", commissionType);
  const { data } = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: caseTransactionCancel.token
  });
  return { transactions: data?.transactions, parties: data?.parties };
};

export const revertTransaction = transactionId => {
  return getHttpClient().post(`${baseUrl}/${transactionId}/revert`);
};

export const reflowTransaction = async transactionId => {
  try {
    await getHttpClient().post(`${baseUrl}/${transactionId}/reflow`);
  } catch (e) {
    if (!e?.response?.data?.transaction) throw e;
    const errors = e.response.data.transaction.errors;
    throw `Reflow was unsuccessful. No data was modified due to: ${errors}`;
  }
};

export const auditTransaction = transactionId => {
  return getHttpClient().post(`${baseUrl}/${transactionId}/audit`);
};

export const searchCaseTransactions = async (policyNumber, caseId) => {
  const params = new URLSearchParams();
  if (policyNumber) params.append("policy_number", policyNumber);
  else if (caseId) params.append("case_id", caseId);
  const { data } = await getHttpClient().get(`${baseUrl}/search_cases`, {
    params
  });

  const { cases } = data;
  return cases;
};
