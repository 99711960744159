const commissionTypes = [
  "First Year",
  "Excess",
  "Renewal",
  "PUA",
  "Term Rider",
  "Bonus"
];

const paymentTypes = ["Flat", "Retained", "Net Retained", "Street"];

export { commissionTypes, paymentTypes };
