<template>
  <div>
    Authentication Key:
    <v-fade-transition mode="out-in">
      <tt :key="authKey" data-testid="auth-key">
        {{ authKey }}
      </tt>
    </v-fade-transition>
    <div class="mb-2 inline-block">
      <v-fade-transition mode="out-in">
        <v-btn
          v-if="authKey === APIKEY_DISPLAY"
          icon
          color="accent"
          data-testid="reveal-auth-key"
          @click="updateAuthKeyValue"
        >
          <v-icon>$mdi-eye</v-icon>
        </v-btn>
        <v-btn
          v-else
          data-testid="copy-auth-key"
          icon
          color="accent"
          @click="copyAuthKey"
        >
          <v-icon>$mdi-content-copy</v-icon>
        </v-btn>
      </v-fade-transition>
    </div>
  </div>
</template>

<script setup>
import { useSnackbarStore } from "@/stores/snackbar";
import { defineProps, ref } from "vue";
const props = defineProps({
  webhook: {
    type: Object,
    required: true
  }
});
const snackbar = useSnackbarStore();
const APIKEY_DISPLAY = "* * * * * * * * * * * * * * * * * * ";
const authKey = ref(APIKEY_DISPLAY);

function updateAuthKeyValue() {
  authKey.value = props.webhook.authenticationKey;
}

function copyAuthKey() {
  navigator.clipboard.writeText(props.webhook.authenticationKey);
  snackbar.showSuccessSnackbar({
    message: "Authentication Key copied to clipboard"
  });
}
</script>
