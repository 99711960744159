import { lineFormatter, listToSentence } from "@/util/helpers";

export default function CarrierFactory(model = {}) {
  return {
    avatarUrl: model?.avatarUrl || null,
    id: model?.id || undefined,
    name: model?.name || undefined,
    products: model?.products || [],
    phone: model?.phone || {},
    supportsInstantAps: model?.supportsInstantAps || false,
    setFromRequest(model = {}) {
      this.avatarUrl = model?.avatar_url;
      this.id = model?.id;
      this.name = model?.name;
      this.products = model?.products;
      this.phone = model?.phone;
      this.supportsInstantAps = model?.instant_aps;
    }
  };
}

export function Carrier(model = {}) {
  return {
    avatarUrl: model?.avatarUrl || null,
    id: model?.id || undefined,
    name: model?.name || undefined,
    products: model?.products || [],
    phone: model?.phone || {},
    supportsInstantAps: model?.supportsInstantAps || false
  };
}

export function setCarrierFromRaw(rawModel = {}) {
  const carrier = Carrier();
  carrier.avatarUrl = rawModel?.avatar_url;
  carrier.id = rawModel?.id;
  carrier.name = rawModel?.name;
  carrier.products = rawModel?.products;
  carrier.phone = rawModel?.phone;
  carrier.supportsInstantAps = rawModel?.instant_aps;
  return carrier;
}

export function CarrierTableItem(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    lines: model?.lines,
    website: model?.website,
    multipleUplines: model?.multipleUplines,
    annualization: model?.annualization,
    availableInQuoteAndApply: model?.availableInQuoteAndApply,
    websites: model?.websites
  };
}

export function setCarrierTableItemFromRaw(rawModel = {}) {
  const model = CarrierTableItem();
  model.id = rawModel?.id;
  model.name = rawModel?.name;
  model.lines = rawModel?.lines;
  model.multipleUplines = rawModel?.multiple_uplines;
  model.annualization = rawModel?.annualization;
  model.availableInQuoteAndApply = rawModel?.strife_approved;

  if (rawModel?.actives) {
    const lines = [];
    Object.keys(rawModel.actives).forEach(line => {
      if (!rawModel.actives[line]) return;
      lines.push(lineFormatter(line));
    });

    model.lines = listToSentence(lines);
  }

  if (rawModel?.websites) {
    const sites = [];
    let mainSite;

    Object.keys(rawModel.websites).forEach(v => {
      let website = rawModel.websites[v];
      if (website === "http://") return;
      website = website.replace("https://", "").replace("http://", "").trim();
      if (!website) return;
      if (v === "main") mainSite = website;
      else sites.push(website);
    });

    model.website = mainSite || sites?.[0];
  }
  return model;
}
