export default function StatisticItemFactory(model = {}) {
  return {
    type: model?.type || null,
    value: model?.value || null,
    get typeInfo() {
      return SITE_STATISTICS[this.type];
    },
    get text() {
      return this.typeInfo.text;
    },
    get to() {
      return this.typeInfo.to;
    },
    get statisticType() {
      return this.typeInfo.statisticType;
    },
    get hint() {
      return this.typeInfo.hint;
    },
    setFromRequest(model = {}) {
      this.type = model?.type;
      this.value = model?.value;
    }
  };
}

function StatisticDisplayFactory(model = {}) {
  return {
    text: model?.text || "",
    statisticType: model?.statisticType || "",
    to: model?.to || undefined,
    hint: model?.hint || false
  };
}

export const STATISTIC_TYPES = {
  PENDING_CASES: "pending_cases",
  PENDING_PRODUCTION_CREDIT: "pending_production_credit",
  PLACEMENT_RATIO: "placement_ratio",
  ACTIVE_CASES: "downline_lifetime_active_cases"
};

const UNIT = {
  NUMBER: "number",
  PERCENT: "percent",
  DOLLAR: "dollar"
};

const SITE_STATISTICS = {
  [STATISTIC_TYPES.PENDING_CASES]: new StatisticDisplayFactory({
    text: "Pending Cases",
    statisticType: UNIT.NUMBER,
    to: { name: "CasesTable" }
  }),
  [STATISTIC_TYPES.PENDING_PRODUCTION_CREDIT]: new StatisticDisplayFactory({
    text: "Pending Production Credit",
    statisticType: UNIT.DOLLAR,
    to: { name: "CasesTable" },
    hint: true
  }),
  [STATISTIC_TYPES.PLACEMENT_RATIO]: new StatisticDisplayFactory({
    text: "Placement Ratio",
    statisticType: UNIT.PERCENT,
    to: { name: "PersonalReportView" }
  }),
  [STATISTIC_TYPES.ACTIVE_CASES]: new StatisticDisplayFactory({
    text: "Lifetime Active Cases",
    statisticType: UNIT.NUMBER
  })
};
