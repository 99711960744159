<template>
  <v-card color="section" flat outlined>
    <v-card-title class="text-h4">Details</v-card-title>
    <v-row class="ma-0">
      <v-col cols="12">
        <v-card flat rounded>
          <v-card-title class="text-h6">General Settings</v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-if="!isSignUpOrSelectAgent" cols="12">
                <h5>
                  Product Types (click to enable/disable)
                  <v-btn
                    icon
                    color="accent"
                    data-testid="settings-product-type-edit"
                    :loading="savingBuffer.productTypes.controller.saving"
                    @click="toggleLockedProductType"
                  >
                    <v-icon v-if="lockedProductTypes"> $mdi-pencil </v-icon>
                    <v-icon v-else> $mdi-content-save </v-icon>
                  </v-btn>
                </h5>
                <v-chip-group
                  v-model="productTypes"
                  multiple
                  mandatory
                  active-class="primary"
                  column
                  @hover="showLockedText"
                >
                  <v-chip
                    v-for="product in PRODUCT_TYPES"
                    :data-testid="`settings-product-type-toggle-${product.text}`"
                    :disabled="lockedProductTypes"
                    :key="product.text"
                    :value="product.value"
                  >
                    {{ product.text }}
                  </v-chip>
                  <v-col class="pa-0" align="center" justify="center">
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.productTypes.controller.value"
                    />
                  </v-col>
                </v-chip-group>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="greeting"
                  prepend-inner-icon="$mdi-handshake"
                  outlined
                  dense
                  label="Greeting"
                  rows="2"
                  data-testid="settings-greeting"
                  :success="Boolean(greeting)"
                  @input="saveAttribute('greeting')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.greeting.controller.value"
                    />
                  </template>
                </v-textarea>
              </v-col>
              <v-col v-if="!isSignUpOrSelectAgent" cols="12">
                <v-textarea
                  v-model="introEmail"
                  label="Intro Email"
                  outlined
                  dense
                  rows="13"
                  prepend-inner-icon="$mdi-email"
                  persistent-hint
                  data-testid="settings-intro-email"
                  :hint="INTRO_HINT"
                  :success="Boolean(introEmail)"
                  @input="saveAttribute('introEmail')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.introEmail.controller.value"
                    />
                  </template>
                </v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="disclosure"
                  prepend-inner-icon="$mdi-information"
                  outlined
                  dense
                  label="Disclosure"
                  rows="2"
                  data-testid="settings-disclosure"
                  :success="Boolean(disclosure)"
                  @input="saveAttribute('disclosure')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.disclosure.controller.value"
                    />
                  </template>
                </v-textarea>
              </v-col>
              <v-col
                v-if="verbosePricingPlan.show_override_phone"
                cols="12"
                lg="6"
              >
                <div class="checkbox-width">
                  <v-checkbox
                    v-model="overridePhone"
                    icon
                    class="mt-0"
                    prepend-icon="$mdi-deskphone"
                    hint="Override the default phone number"
                    persistent-hint
                    data-testid="settings-override-phone"
                    :success="Boolean(overridePhone)"
                    @change="saveAttribute('overridePhone')"
                  >
                    <template #label>
                      Override Phone
                      <active-save-indicator
                        class="ml-2"
                        :controller="
                          savingBuffer.overridePhone.controller.value
                        "
                      />
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
              <v-col cols="12" lg="6" v-if="overridePhone">
                <phone-input
                  v-model="phoneNumber"
                  data-lpignore="true"
                  label="Phone Number"
                  outlined
                  dense
                  prepend-inner-icon="$mdi-phone"
                  persistent-hint
                  data-testid="settings-phone-number"
                  hint="The phone number the user sees."
                  :success="phoneNumberValidation.success"
                  :error-messages="phoneNumberValidation.errorMessages"
                  @input="saveAttribute('phoneNumber')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.phoneNumber.controller.value"
                    />
                  </template>
                </phone-input>
              </v-col>
              <v-col v-if="!isSignUpOrSelectAgent" cols="12" lg="6">
                <v-select
                  v-model="defaultMode"
                  prepend-inner-icon="$mdi-calendar"
                  outlined
                  dense
                  class="mt-0"
                  persistent-hint
                  data-testid="settings-default-mode"
                  label="Default Premium Mode"
                  hint="The default premium mode the user sees. The user can still change the premium mode."
                  :items="MODES"
                  :success="Boolean(defaultMode)"
                  @change="saveAttribute('defaultMode')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.defaultMode.controller.value"
                    />
                  </template>
                </v-select>
              </v-col>
              <template v-if="!isSignUpOrSelectAgent">
                <v-col cols="12" lg="6">
                  <div class="checkbox-width">
                    <v-checkbox
                      v-model="useOwnersSchedulingLink"
                      prepend-icon="$mdi-calendar"
                      dense
                      class="mt-0"
                      persistent-hint
                      hint=" "
                      data-testid="settings-use-owners-scheduling-link"
                      :success="Boolean(useOwnersSchedulingLink)"
                      @change="saveAttribute('useOwnersSchedulingLink')"
                    >
                      <template #message>
                        When enabled users can schedule appointments utilizing
                        {{ ownable.name }}'s default scheduling link.
                        <router-link
                          :to="{
                            name:
                              ownable.typeModel === 'Agent'
                                ? 'AgentEdit'
                                : 'AgencyEdit',
                            params: {
                              id: ownable.id
                            },
                            query: {
                              page: 'profile'
                            }
                          }"
                          >Add/Edit default scheduling link</router-link
                        >
                      </template>
                      <template #label>
                        Use {{ ownable.name }}'s Default Scheduling link
                        <active-save-indicator
                          class="ml-2"
                          :controller="
                            savingBuffer.useOwnersSchedulingLink.controller
                              .value
                          "
                        />
                      </template>
                    </v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12" lg="6" v-if="!useOwnersSchedulingLink">
                  <v-text-field
                    v-model="customSchedulingLink"
                    data-lpignore="true"
                    label="Custom Scheduling Link"
                    prepend-inner-icon="$mdi-calendar"
                    prefix="https://"
                    outlined
                    dense
                    clearable
                    persistent-hint
                    hint=" "
                    data-testid="settings-custom-scheduling-link"
                    :success="customSchedulingLinkValidation.success"
                    :error-messages="
                      customSchedulingLinkValidation.errorMessages
                    "
                    @click:clear="saveAttribute('customSchedulingLink')"
                    @input="saveAttribute('customSchedulingLink')"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        class="ml-2"
                        :controller="
                          savingBuffer.customSchedulingLink.controller.value
                        "
                      />
                    </template>
                    <template #message="{ message }">
                      <v-row class="ma-0">
                        <template v-if="message.length > 1">{{
                          message
                        }}</template>
                        <a v-else href="https://calendly.com/" target="_blank"
                          >Click here to sign up for Calendly.</a
                        >
                      </v-row>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <div class="checkbox-width">
                    <v-checkbox
                      v-model="chat"
                      icon
                      class="mt-0"
                      prepend-icon="$mdi-message"
                      hint=" "
                      data-testid="settings-chat"
                      persistent-hint
                      :success="Boolean(chat)"
                      @change="saveAttribute('chat')"
                    >
                      <template #message
                        >Enable a Chat Assistant to prompt clients to schedule a
                        meeting with you. This requires a scheduling link.
                        <router-link
                          :to="{
                            name:
                              ownable.typeModel === 'Agent'
                                ? 'AgentEdit'
                                : 'AgencyEdit',
                            params: {
                              id: ownable.id
                            },
                            query: {
                              page: 'profile'
                            }
                          }"
                          >Add/Edit default scheduling link.</router-link
                        >
                        <a
                          href="https://app.back9ins.com/help-center/articles/57"
                          target="_blank"
                        >
                          Learn about Chat</a
                        >.
                      </template>
                      <template #label>
                        Chat Assistant
                        <active-save-indicator
                          class="ml-2"
                          :controller="savingBuffer.chat.controller.value"
                        />
                      </template>
                    </v-checkbox>
                  </div>
                </v-col>
              </template>
              <v-col cols="12" lg="6">
                <v-select
                  v-model="startPage"
                  prepend-inner-icon="$mdi-book-open-page-variant"
                  outlined
                  dense
                  label="Start Page"
                  hint="The first page the user sees. The default is Apply, but Quote & Apply can be used as a Life Insurance Calculator (Life Insurance Calculator), a sign up page for your downline to sign up with BackNine (Agent Sign Up), display a list of your agents to then use Quote & Apply (Select Agent Dropdown), or a referral form (Refer)."
                  persistent-hint
                  data-testid="settings-start-page"
                  :items="startPageItems"
                  :success="Boolean(startPage)"
                  @change="saveAttribute('startPage')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.startPage.controller.value"
                    />
                  </template>
                </v-select>
              </v-col>
              <v-col v-if="!isSignUpOrSelectAgent" cols="12" lg="6">
                <v-select
                  v-model="limitExam"
                  :items="LIMIT_EXAM_ITEMS"
                  prepend-inner-icon="$mdi-calendar-week"
                  outlined
                  dense
                  class="mt-0"
                  success
                  persistent-hint
                  data-testid="settings-limit-exam"
                  label="Limit To No Exam Products"
                  hint="Filter quotes available by exam requirements"
                  @change="saveAttribute('limitExam')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.limitExam.controller.value"
                    />
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="!isSignUpOrSelectAgent" cols="12">
        <v-card flat rounded>
          <v-card-title class="text-h6">Death Benefit Defaults</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="6">
                <currency-input
                  v-model="accidentalDeathDefault"
                  prepend-inner-icon="$mdi-cash"
                  outlined
                  dense
                  label="Accidental Death Default"
                  data-testid="settings-accidental-death-default"
                  :decimal-length="0"
                  :success="Boolean(accidentalDeathDefault)"
                  @input="saveAttribute('accidentalDeathDefault')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="
                        savingBuffer.accidentalDeathDefault.controller.value
                      "
                    />
                  </template>
                </currency-input>
              </v-col>
              <v-col cols="12" lg="6">
                <currency-input
                  v-model="finalExpenseDefault"
                  prepend-inner-icon="$mdi-cash-usd"
                  outlined
                  dense
                  label="Final Expense Default"
                  data-testid="settings-final-expense-default"
                  :decimal-length="0"
                  :success="Boolean(finalExpenseDefault)"
                  @input="saveAttribute('finalExpenseDefault')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="
                        savingBuffer.finalExpenseDefault.controller.value
                      "
                    />
                  </template>
                </currency-input>
              </v-col>
              <v-col cols="12" lg="6">
                <currency-input
                  v-model="permanentDefault"
                  prepend-inner-icon="$mdi-cash-100"
                  outlined
                  dense
                  label="Term/ROP Term/Permanent Default"
                  data-testid="settings-permanent-default"
                  :decimal-length="0"
                  :success="Boolean(permanentDefault)"
                  @input="saveAttribute('permanentDefault')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="
                        savingBuffer.permanentDefault.controller.value
                      "
                    />
                  </template>
                </currency-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card flat rounded>
          <v-card-title class="text-h6">Advanced</v-card-title>
          <v-card-text>
            The default settings are recommended for most agents. Adjust these
            settings with caution.
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="6">
                <advisor-search
                  v-model="marketingAs"
                  class="has-appended-button"
                  prepend-inner-icon="$mdi-bullhorn"
                  outlined
                  dense
                  label="Market As"
                  persistent-hint
                  hint="Determines who client correspondence and webpage title comes from. If locked, Market As won't change even when the agent changes who their commissions are paid to."
                  data-testid="settings-marketing-as"
                  :clearable="false"
                  :success="Boolean(marketingAs)"
                  @input="saveAttribute('marketingAs')"
                >
                  <template #append>
                    <v-btn
                      icon
                      key="MarketAsLock"
                      data-testid="settings-toggle-market-as-lock"
                      @mousedown.stop.prevent="toggleMarketAsLock"
                      @touchstart.stop.prevent="toggleMarketAsLock"
                    >
                      <v-icon v-if="marketAsLock">$mdi-lock</v-icon>
                      <v-icon v-else>$mdi-lock-open-variant-outline</v-icon>
                    </v-btn>
                  </template>
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.marketingAs.controller.value"
                    />
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.marketAsLock.controller.value"
                    />
                  </template>
                </advisor-search>
              </v-col>
              <v-col cols="12" lg="6">
                <div class="checkbox-width">
                  <v-checkbox
                    v-model="marketAsAgency"
                    prepend-icon="$mdi-domain"
                    outlined
                    dense
                    class="mt-0"
                    success
                    hint="Hides the agent's name and shows the Commissions Assigned To's name"
                    persistent-hint
                    data-testid="settings-market-as-agency"
                    @change="saveAttribute('marketAsAgency')"
                  >
                    <template #label>
                      Market as Agency
                      <active-save-indicator
                        class="ml-2"
                        :controller="
                          savingBuffer.marketAsAgency.controller.value
                        "
                      />
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
              <v-col cols="12" lg="6">
                <div class="checkbox-width">
                  <v-checkbox
                    v-model="emailAgent"
                    prepend-icon="$mdi-email"
                    outlined
                    dense
                    class="mt-0"
                    success
                    hint="Email the agent on eApp correspondence."
                    persistent-hint
                    data-testid="settings-email-agent"
                    @change="saveAttribute('emailAgent')"
                  >
                    <template #label>
                      Email Agent
                      <active-save-indicator
                        class="ml-2"
                        :controller="savingBuffer.emailAgent.controller.value"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
              <v-col v-if="!isSignUpOrSelectAgent" cols="12" lg="6">
                <div class="checkbox-width">
                  <v-checkbox
                    v-model="test"
                    prepend-icon="$mdi-dev-to"
                    outlined
                    dense
                    label="Test"
                    class="mt-0"
                    success
                    hint="Applications, Exams, and DocuSigns are deleted nightly."
                    persistent-hint
                    data-testid="settings-test"
                    @change="saveAttribute('test')"
                  >
                    <template #label>
                      Test
                      <active-save-indicator
                        class="ml-2"
                        :controller="savingBuffer.test.controller.value"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
              <v-col cols="12" lg="6" v-if="test && !isSignUpOrSelectAgent">
                <div class="checkbox-width">
                  <v-checkbox
                    v-model="hideTestModeBanner"
                    prepend-icon="$mdi-dev-to"
                    outlined
                    dense
                    class="mt-0"
                    success
                    hint="Hides the 'This Quote & Apply Widget is in Test Mode' banner at the top of the widget"
                    persistent-hint
                    data-testid="settings-hide-test-mode-banner"
                    @change="saveAttribute('hideTestModeBanner')"
                  >
                    <template #label>
                      Hide Test Mode Banner
                      <active-save-indicator
                        class="ml-2"
                        :controller="
                          savingBuffer.hideTestModeBanner.controller.value
                        "
                      />
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
              <v-col cols="12" lg="6">
                <div class="checkbox-width">
                  <v-checkbox
                    v-model="activeFirstInput"
                    prepend-icon="$mdi-import"
                    outlined
                    dense
                    class="mt-0"
                    success
                    hint="Causes the first input on Quote & Apply to be active when Quote & Apply loads. This can cause the page to scroll (i.e. jump) if Quote & Apply is below the fold. This setting is only used when Quote & Apply is embedded and not in a modal."
                    persistent-hint
                    data-testid="settings-active-first-input"
                    @change="saveAttribute('activeFirstInput')"
                  >
                    <template #label>
                      Active First Input
                      <active-save-indicator
                        class="ml-2"
                        :controller="
                          savingBuffer.activeFirstInput.controller.value
                        "
                      />
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
              <template v-if="!isWhiteglove && !isSignUpOrSelectAgent">
                <v-col cols="12" lg="6">
                  <div class="checkbox-width">
                    <v-checkbox
                      v-model="captureEmail"
                      prepend-icon="$mdi-email"
                      outlined
                      dense
                      class="mt-0"
                      hint="Force the user to provide their email before viewing quotes."
                      persistent-hint
                      data-testid="settings-capture-email"
                      :success="Boolean(captureEmail)"
                      @change="saveAttribute('captureEmail')"
                    >
                      <template #label>
                        Require Email Before Viewing Quotes
                        <active-save-indicator
                          class="ml-2"
                          :controller="
                            savingBuffer.captureEmail.controller.value
                          "
                        />
                      </template>
                    </v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12" lg="6">
                  <div class="checkbox-width">
                    <v-checkbox
                      v-model="capturePhone"
                      prepend-icon="$mdi-phone"
                      dense
                      class="mt-0"
                      hint="Force the user to provide their phone number before viewing quotes"
                      persistent-hint
                      data-testid="settings-capture-phone"
                      :success="Boolean(capturePhone)"
                      @change="saveAttribute('capturePhone')"
                    >
                      <template #label>
                        Require Phone Before Viewing Quotes
                        <active-save-indicator
                          class="ml-2"
                          :controller="
                            savingBuffer.capturePhone.controller.value
                          "
                        />
                      </template>
                    </v-checkbox>
                  </div>
                </v-col>
              </template>
              <v-col cols="12" lg="6" v-if="showHideFromSearchEngines">
                <div class="checkbox-width">
                  <v-checkbox
                    v-model="hideFromSearchEngines"
                    prepend-icon="$mdi-search-web"
                    dense
                    class="mt-0"
                    success
                    hint="Instructs search engines to not index this Quote & Apply website by removing it from BackNine's sitemap.xml and adding to the disallow list for robots.txt."
                    persistent-hint
                    data-testid="settings-hide-from-search-engines"
                    @change="saveAttribute('hideFromSearchEngines')"
                  >
                    <template #label>
                      Hide from Search Engines
                      <active-save-indicator
                        class="ml-2"
                        :controller="
                          savingBuffer.hideFromSearchEngines.controller.value
                        "
                      />
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
              <v-col cols="12" lg="6">
                <advisor-search
                  v-model="assignable"
                  class="has-appended-button"
                  prepend-inner-icon="$mdi-clipboard-account"
                  outlined
                  dense
                  label="Assignable"
                  hint="The assignable is given access to the eApp. The assignable becomes a connection for agents who register with this Quote & Apply. If locked, the assignable won't change even when the agent changes who their commissions are paid to. If the pricing plan of the Quote & Apply website is Affiliate, the assignable is who is paid."
                  persistent-hint
                  data-testid="settings-assignable"
                  :clearable="false"
                  :success="Boolean(assignable)"
                  @input="saveAttribute('assignable')"
                >
                  <template #append>
                    <v-btn
                      v-if="!isWhiteglove"
                      icon
                      style="z-index: 3"
                      key="AssignableLock"
                      data-testid="settings-assignable-lock"
                      @mousedown.stop.prevent="toggleAssignableLock"
                      @touchstart.stop.prevent="toggleAssignableLock"
                    >
                      <v-icon v-if="assignableLock">$mdi-lock</v-icon>
                      <v-icon v-else>$mdi-lock-open-variant-outline</v-icon>
                    </v-btn>
                  </template>
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.assignableLock.controller.value"
                    />
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.assignable.controller.value"
                    />
                  </template>
                </advisor-search>
              </v-col>
              <v-col v-if="!isSignUpOrSelectAgent" cols="12" lg="6">
                <v-select
                  v-model="overrideState"
                  clearable
                  item-text="full"
                  item-value="text"
                  prepend-inner-icon="$mdi-home-group"
                  outlined
                  dense
                  label="Override State"
                  hint="Set a default state instead of defaulting to the state of user's IP address."
                  persistent-hint
                  data-testid="settings-override-state"
                  :items="availableStates"
                  :success="Boolean(overrideState)"
                  @change="saveAttribute('overrideState')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.overrideState.controller.value"
                    />
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="embedLocation"
                  :placeholder="domain"
                  data-lpignore="true"
                  prepend-inner-icon="$mdi-code-tags"
                  outlined
                  dense
                  prefix="https://"
                  label="Embed Location (optional)"
                  :success="Boolean(embedLocation)"
                  persistent-hint
                  hint="Indicate where you have embedded Quote & Apply"
                  data-testid="settings-embed-location"
                  @input="saveAttribute('embedLocation')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.embedLocation.controller.value"
                    />
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- GROUP 2+ -->
      <v-col cols="12" v-if="isGroupTwoPlus">
        <v-card flat rounded>
          <v-card-title class="text-h6">Admin - Group 2 +</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="6">
                <v-select
                  v-model="approvedDomainPricingPlan"
                  prepend-inner-icon="$mdi-floor-plan"
                  outlined
                  dense
                  label="Pricing Plan"
                  item-text="name"
                  item-value="id"
                  data-testid="settings-pricing-plan"
                  :disabled="savingBuffer.pricingPlan.saving"
                  :loading="savingBuffer.pricingPlan.saving"
                  :items="pricingPlanOptions"
                  :success="Boolean(pricingPlan)"
                >
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.pricingPlan.controller.value"
                    />
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" lg="6" v-if="!isWhiteglove">
                <advisor-search
                  v-model="billable"
                  prepend-inner-icon="$mdi-office-building"
                  outlined
                  dense
                  label="Billable"
                  data-testid="settings-billable"
                  :clearable="false"
                  :success="Boolean(billable)"
                  @input="saveAttribute('billable')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.billable.controller.value"
                    />
                  </template>
                </advisor-search>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  :value="domain"
                  readonly
                  data-lpignore="true"
                  prepend-inner-icon="$mdi-chart-areaspline"
                  outlined
                  dense
                  label="URL"
                  persistent-hint
                  hint=" "
                  data-testid="settings-domain"
                  :success="Boolean(domain)"
                  @click="editDomain"
                  @input="saveAttribute('domain')"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { url } from "vuelidate/lib/validators";
import { states as stateItems } from "@/data/states";

import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { startPageItems } from "@/data/filter-options";
import { parseErrorMessage } from "@/util/helpers";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/user";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useActiveSave } from "@/composables/active-save.composable";

const MODES = [
  { text: "Annually", value: 1 },
  { text: "Semi-Annual", value: 2 },
  { text: "Quarterly", value: 4 },
  { text: "Monthly", value: 12 }
];

const PRODUCT_TYPES = [
  { value: "accidental_death", text: "Accidental Death" },
  { value: "iul", text: "Accumulation IUL" },
  { value: "fex", text: "Final Expense" },
  {
    value: "linked_benefit",
    text: "Linked Benefit"
  },
  { value: "ltc", text: "Long Term Care" },
  { value: "non_med", text: "Non Med Term" },
  { value: "piul", text: "Protection IUL" },
  { value: "pvul", text: "Protection VUL" },
  { value: "rop_term", text: "ROP Term" },
  { value: "term", text: "Term" },
  { value: "ul", text: "Universal Life" },
  { value: "whole_life", text: "Whole Life" }
];

const LIMIT_EXAM_ITEMS = [
  { text: "Allow All", value: null },
  { text: "Possibly No Exam", value: "possibly" },
  { text: "Guaranteed No Exam", value: "none" }
];

const INTRO_HINT =
  "[FirstName]: Client's first name. [LastName]: Client's last name. [Link]link[/Link]: Link to application. Replace \"link\" with hyperlinked text. [CompanyName] Agent or Agency name and CA license number.";

export default {
  components: {
    AdvisorSearch,
    ActiveSaveIndicator,
    CurrencyInput,
    PhoneInput
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  data() {
    const savingBuffer = {
      accidentalDeathDefault: useActiveSave(),
      activeFirstInput: useActiveSave(),
      appointmentAssignable: useActiveSave(),
      appointmentAssignableLock: useActiveSave(),
      assignable: useActiveSave(),
      assignableLock: useActiveSave(),
      billable: useActiveSave(),
      captureEmail: useActiveSave(),
      capturePhone: useActiveSave(),
      chat: useActiveSave(),
      customSchedulingLink: useActiveSave(),
      defaultMode: useActiveSave(),
      disclosure: useActiveSave(),
      emailAgent: useActiveSave(),
      finalExpenseDefault: useActiveSave(),
      greeting: useActiveSave(),
      hideFromSearchEngines: useActiveSave(),
      hideTestModeBanner: useActiveSave(),
      introEmail: useActiveSave(),
      limitExam: useActiveSave(),
      marketAsAgency: useActiveSave(),
      marketAsLock: useActiveSave(),
      marketingAs: useActiveSave(),
      overridePhone: useActiveSave(),
      overrideState: useActiveSave(),
      permanentDefault: useActiveSave(),
      phoneNumber: useActiveSave(),
      productTypes: useActiveSave(),
      pricingPlan: useActiveSave(),
      startPage: useActiveSave(),
      test: useActiveSave(),
      useOwnersSchedulingLink: useActiveSave(),
      embedLocation: useActiveSave()
    };

    return {
      savingBuffer,
      stateItems,
      PRODUCT_TYPES,
      startPageItems,
      lockedProductTypes: true,
      lockedTooltipVisible: false,
      domainProductTypes: [],
      isMounted: false,
      INTRO_HINT,
      MODES,
      LIMIT_EXAM_ITEMS
    };
  },
  computed: {
    ...mapState(useUserStore, ["isGroupTwoPlus"]),
    ...mapState(useApprovedDomainView, ["ownable", "isSignUpOrSelectAgent"]),
    ...mapWritableState(useApprovedDomainView, [
      "accidentalDeathDefault",
      "activeFirstInput",
      "appointmentAssignable",
      "appointmentAssignableLock",
      "assignable",
      "assignableLock",
      "billable",
      "captureEmail",
      "capturePhone",
      "chat",
      "customDomain",
      "customSchedulingLink",
      "defaultMode",
      "disclosure",
      "domain",
      "emailAgent",
      "embedLocation",
      "finalExpenseDefault",
      "greeting",
      "hideFromSearchEngines",
      "hideTestModeBanner",
      "introEmail",
      "isWhiteglove",
      "limitExam",
      "marketAsAgency",
      "marketAsLock",
      "marketingAs",
      "overridePhone",
      "overrideState",
      "permanentDefault",
      "phoneNumber",
      "pricingPlan",
      "pricingPlanOptions",
      "productTypes",
      "startPage",
      "test",
      "useOwnersSchedulingLink",
      "verbosePricingPlan"
    ]),
    showHideFromSearchEngines() {
      return Boolean(this.customDomain?.id);
    },
    approvedDomainPricingPlan: {
      get() {
        return this.pricingPlan;
      },
      async set(val) {
        if (!this.isMounted) return;
        try {
          await this.updateApprovedDomain("pricingPlan", val);
          this.pricingPlan = val;
          this.$emit("refresh");
        } catch (e) {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        }
      }
    },
    availableStates() {
      return this.stateItems.filter(state =>
        this.ownable?.insuranceLicenses?.includes(state.text)
      );
    },
    customSchedulingLinkValidation() {
      const success = !this.$v.customSchedulingLink.$invalid;
      const errorMessages = [];
      if (!this.$v.customSchedulingLink.$dirty)
        return { success, errorMessages };
      if (!this.$v.customSchedulingLink.validLink)
        errorMessages.push("Invalid Link");
      return { success, errorMessages };
    },
    embedLocationValidation() {
      const success = !this.$v.embedLocation.$invalid;
      const errorMessages = [];
      if (!this.$v.embedLocation.$dirty) return { success, errorMessages };
      if (!this.$v.embedLocation.validLink) errorMessages.push("Invalid Link");
      return { success, errorMessages };
    },
    phoneNumberValidation() {
      const success = !this.$v.phoneNumber.$invalid;
      const errorMessages = [];
      if (!this.$v.phoneNumber.$dirty) return { success, errorMessages };
      if (!this.$v.phoneNumber.validPhoneNumber)
        errorMessages.push("Phone numbers must be 10 digits in length");
      return { success, errorMessages };
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useApprovedDomainView, ["updateApprovedDomain"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    toggleMarketAsLock() {
      this.marketAsLock = !this.marketAsLock;
      this.saveAttribute("marketAsLock");
    },
    toggleAssignableLock() {
      this.assignableLock = !this.assignableLock;
      this.saveAttribute("assignableLock");
    },

    showLockedText() {
      if (!this.lockedProductTypes) return;
      this.lockedTooltipVisible = true;
    },
    hideLockedText() {
      if (!this.lockedProductTypes) return;
      this.lockedTooltipVisible = false;
    },
    toggleLockedProductType() {
      if (!this.isMounted) return;
      if (!this.lockedProductTypes) {
        if (!this.hasPassedValidation("productTypes")) return;
        this.saveAttribute("productTypes", 0);
      }
      this.lockedProductTypes = !this.lockedProductTypes;
    },
    hasPassedValidation(attribute) {
      if (!this.isMounted) return false;

      const isAppointmentAssignableAttr = [
        "appointmentAssignable",
        "appointmentAssignableLock"
      ].includes(attribute);

      if (isAppointmentAssignableAttr && this.ownable.lockCommissions)
        return false;
      if (
        attribute === "customSchedulingLink" &&
        !this.customSchedulingLinkValidation.success
      )
        return false;
      if (attribute === "phoneNumber" && !this.phoneNumberValidation.success)
        return false;
      return true;
    },
    saveAttribute(attribute, timeout = 300) {
      if (!this.isMounted) return;
      if (!this.hasPassedValidation(attribute)) return;

      this.savingBuffer[attribute].debounceUpdate(
        () => this.updateApprovedDomain(attribute),
        timeout
      );
    },
    editDomain() {
      const oldDomain = this.domain;
      this.showDialog({
        component: "TextEditDialog",
        title: "Edit Domain",
        label: "Domain",
        subtitle:
          "Change the domain of this Quote & Apply website. This will change the continue URL for all related eApps with matching resume links.",
        value: this.domain,
        rules: {
          required: v => Boolean(v)
        },
        rulesMessages: [{ key: "required", message: "Required" }],
        func: async v => {
          this.domain = v;
          try {
            await this.updateApprovedDomain("domain");
          } catch (e) {
            this.domain = oldDomain;
            throw e;
          }
        }
      });
    }
  },
  validations: {
    phoneNumber: {
      validPhoneNumber: value => value?.length === 14
    },
    embedLocation: {
      validLink(value) {
        if (!value) return true;
        if (value.includes("http://") || value.includes("https://"))
          return false;
        return url(`https://${value}`);
      }
    },
    customSchedulingLink: {
      validLink(value) {
        if (!value) return true;
        if (value.includes("http://") || value.includes("https://"))
          return false;
        return url(`https://${value}`);
      }
    }
  }
};
</script>
