import { getHttpClient } from "@/http-client";
import {
  setVendorFromRequest,
  setRequestFromVendor
} from "@/factories/VendorFactory";
const baseUrl = "/api/boss/vendors";

export async function getVendors({ carrier, type, available } = {}) {
  const params = new URLSearchParams();
  if (type) params.append("type", type);
  if (carrier) params.append("carrier_id", carrier.id);
  if ([true, false].includes(available)) params.append("available", available);
  const res = await getHttpClient().get(baseUrl, { params });

  if (!res?.data) return;

  return res.data.map(setVendorFromRequest);
}

export const saveVendor = vendor => {
  return getHttpClient().put(
    `${baseUrl}/${vendor.id}`,
    setRequestFromVendor(vendor)
  );
};

export const createVendor = vendor => {
  return getHttpClient().post(baseUrl, setRequestFromVendor(vendor));
};

export const deleteVendor = vendorId => {
  return getHttpClient().delete(`${baseUrl}/${vendorId}`);
};
