<template>
  <v-text-field
    data-lpignore="true"
    prepend-inner-icon="$mdi-pound"
    class="no-tick"
    label="CRD Number"
    outlined
    dense
    type="text"
    inputmode="numeric"
    :error-messages="errorMessages"
    :success="success"
    v-model="crd"
    persistent-hint
    hint=" "
    @blur="$emit('blur')"
  >
    <template #message="{ message }">
      <v-row class="pa-3">
        {{ message }}
        <v-spacer />
        <a href="https://brokercheck.finra.org/" target="_blank">
          Don't know your CRD Number?
        </a>
      </v-row>
    </template>
    <template #append-outer v-if="hasAppendOuterSlot">
      <slot name="append-outer" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    success: Boolean,
    errorMessages: Array,
    value: [String, Number]
  },
  data() {
    return { crd: this.value || null };
  },
  watch: {
    crd(value) {
      this.$emit("input", value);
    }
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots["append-outer"];
    }
  }
};
</script>
