import { getHttpClient } from "@/http-client";
import { setApplicationVersionTableItemFromRequest } from "@/factories/ApplicationVersion";
const baseUrl = "api/boss/application_versions";

export async function getApplicationVersions(urlParams, cancelToken) {
  let params = urlParams;
  if (!params) params = new URLSearchParams();
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const { data } = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  const items = data.application_versions.map(
    setApplicationVersionTableItemFromRequest
  );

  return { items, meta: data.meta };
}
