import { uuidv4, generateRandomColor } from "@/util/helpers";

export const RADIO_OPTION_RADIUS = 7;
export const RADIO_OPTION_DIAMETER = RADIO_OPTION_RADIUS * 2;
export const CHECKBOX_SIDE_LENGTH = 14;
export const CHECKBOX_RADII = 2;

export const RADIO_OPTION_TYPE = "radio";
export const SELECT_OPTION_TYPE = "select";
export const TEXT_OPTION_TYPE = "text";
export const TEXT_VERBATIM_OPTION_TYPE = "text_output_verbatim_question";
export const NOT_ON_PDF_OPTION_TYPE = "not_on_pdf";
export const SIGNATURE_OPTION_TYPE = "signature";
export const SIGNATURE_DATE_OPTION_TYPE = "date_signed";
export const CHECKBOX_OPTION_TYPE = "checkbox";
export const DATE_OPTION_TYPE = "date";

export const pdfFieldTypes = [
  { value: CHECKBOX_OPTION_TYPE, text: "Checkbox" },
  { value: NOT_ON_PDF_OPTION_TYPE, text: "Not on PDF" },
  { value: RADIO_OPTION_TYPE, text: "Radio" },
  { value: TEXT_OPTION_TYPE, text: "Text" },
  { value: TEXT_VERBATIM_OPTION_TYPE, text: "Text - Output Verbatim Question" }
];

export const textOptions = [
  { value: true, text: "Yes" },
  { value: 0, text: "No - No Character Limit" },
  { value: false, text: "No - Limit Characters" }
];

export const quoteAndApplyFieldTypes = [
  { value: SELECT_OPTION_TYPE, text: "Select" },
  { value: TEXT_OPTION_TYPE, text: "Text" },
  { value: RADIO_OPTION_TYPE, text: "Radio" },
  { value: CHECKBOX_OPTION_TYPE, text: "Checkbox" },
  { value: DATE_OPTION_TYPE, text: "Date" }
];

export const docusignRequiredOf = [
  { text: "agent1", value: "agent1" },
  { text: "insured1", value: "insured1" },
  { text: "insured1/responsible1", value: "insured1/responsible1" },
  { text: "insured1/owner1", value: "insured1/owner1" },
  { text: "owner1", value: "owner1" }
];

export const insuredIndexOptions = [
  { text: "Insured", value: 0 },
  { text: "Joint Insured", value: 1 }
];

export const FORM_STATUS = {
  INCOMPLETE: "Incomplete",
  COMPLETE: "Complete",
  COMPLETE_NOT_LATEST: "Complete - Not Latest",
  COMPLETE_READY_FOR_REVIEW: "Complete - Ready for Review"
};

export const CATEGORY_STATES_STATUS = [
  "Incomplete",
  "Complete",
  "Not Required"
];
// Application Question Link
export function ApplicationQuestionLinkFactory(model = {}) {
  return {
    applicationQuestion: model?.applicationQuestion,
    childAqls: model?.childAqls,
    parentAql: model?.parentAql,
    color: model?.color,
    coordinates: model?.coordinates || [],
    smartApplicationQuestion: model?.smartApplicationQuestion || null,
    id: model?.id,
    question: model?.question,
    isTiaField: model?.isTiaField,
    isDocusignField: model?.isDocusignField,
    isDocusignRequired: model?.isDocusignRequired,
    docusignRequiredOf: model?.docusignRequiredOf,
    pdfFieldType: model?.pdfFieldType,
    additionalForm: model?.additionalForm,
    requiredValue: model?.requiredValue,
    quoteAndApplyType: model?.quoteAndApplyType,
    parentQuestion: model?.parentQuestion || null,
    verbatimQuestion: model?.verbatimQuestion,
    insuredIndex: model?.insuredIndex,
    requiredParentValue: model?.requiredParentValue,
    referenceField: model?.referenceField,
    referenceForm: model?.referenceForm,
    positions: model?.positions,
    comb: model?.comb,
    obscureBackground: model?.obscureBackground,
    get verboseDisplayText() {
      let page;
      if (this.coordinates.length) {
        page = this.coordinates.find(({ page }) => Boolean(page))?.page;
      }
      let pageNum;
      if (this.pdfFieldType === NOT_ON_PDF_OPTION_TYPE && !page) {
        pageNum = "Not on PDF";
      } else if (page) {
        pageNum = page;
      }
      let copiedFromText = "";
      if (this.referenceField) {
        copiedFromText = `- Copied From ${this.referenceField} on ${this.referenceForm}`;
      }

      let textArr = [pageNum, this.id, copiedFromText];
      if (!this.id) {
        textArr = ["[NOT CREATED]", pageNum, this.field];
      }

      return textArr.filter(Boolean).join(" ");
    },
    get questionSpecificText() {
      let page;
      if (this.coordinates.length) {
        page = this.coordinates.find(({ page }) => Boolean(page))?.page;
      }
      const pageNum = page || null;
      let additionalText = this.applicationQuestion?.name;
      if (this.verbatimQuestion) {
        additionalText = this.verbatimQuestion.substring(0, 12);
      }

      return [pageNum, this.id, additionalText].filter(Boolean).join(" ");
    }
  };
}

export function setAqlFromRequest(aql, model = {}) {
  let applicationQuestion = null;
  if (model?.application_question) {
    applicationQuestion = new ApplicationQuestionFactory();
    setAppQuestionFromRequest(applicationQuestion, model?.application_question);
  }
  let smartApplicationQuestion = null;
  if (model?.smart_application_question) {
    smartApplicationQuestion = new ApplicationQuestionFactory();
    setAppQuestionFromRequest(
      smartApplicationQuestion,
      model?.smart_application_question
    );
  }
  const coordinates = [];
  // Field options only
  if (Array.isArray(model?.field)) {
    setCoordinatesFromFieldOptions(model, coordinates);
  } else if (model?.field) {
    setCoordinatesFromText(model, coordinates);
  }

  const childAqls = [];
  model?.child_application_question_links?.forEach(rawChild => {
    const child = new ApplicationQuestionLinkFactory(rawChild);
    childAqls.push(child);
  });

  let parentAql = null;
  if (model?.parent_application_question_link) {
    parentAql = new ApplicationQuestionLinkFactory(
      model.parent_application_question_link
    );
  }

  aql.applicationQuestion = applicationQuestion;
  aql.childAqls = childAqls;
  aql.parentAql = parentAql;
  aql.color = generateRandomColor();
  aql.coordinates = coordinates;
  aql.smartApplicationQuestion = smartApplicationQuestion;
  aql.id = model?.id?.toString();
  aql.question = model?.question;
  aql.isTiaField = model.tia;
  aql.isDocusignField = model.docusign;
  aql.isDocusignRequired = model.docusign_required;
  aql.docusignRequiredOf = model.required_of;
  aql.pdfFieldType = model.application_field_type;
  aql.additionalForm = model.additional_form;
  aql.requiredValue = model.required_value || null;
  aql.quoteAndApplyType = model.field_type;
  aql.parentQuestion = model.parent_application_question_link?.id?.toString();
  aql.verbatimQuestion = model.verbatim_question;
  aql.insuredIndex = model.insured_index || 0;
  aql.requiredParentValue = model.required_parent_value;
  aql.referenceField =
    model?.reference_application_question_link?.id?.toString();
  aql.referenceForm =
    model?.reference_application_question_link?.form?.id?.toString();
  aql.positions = model?.positions;
  aql.comb = model?.comb;
  aql.obscureBackground = Boolean(model?.background);
}

export function getAqlCopyRequestBody(aql) {
  const field = getFieldData(aql);
  let reference_application_question_link_id = null;
  if (aql.referenceField) {
    reference_application_question_link_id = +aql.referenceField;
  }
  return {
    field,
    reference_application_question_link_id
  };
}

function getRadioFieldData(aql) {
  const field = [];
  aql.coordinates.forEach(coordinate => {
    field.push({
      x: coordinate.x,
      y: coordinate.y,
      page: coordinate.page,
      text: coordinate.text,
      value: coordinate.value,
      virtual: coordinate.virtual
    });
  });
  return field;
}

function getTextFieldData(aql) {
  if (!aql.coordinates.length) return {};
  const { x, y, page, width, height, options } = aql.coordinates[0];

  let vals = [];
  if (options?.length) {
    vals = options.map(({ text, value, virtual }) => ({
      text,
      value,
      virtual
    }));
  }

  const field = {
    x,
    y,
    page,
    width,
    height,
    options: vals
  };

  return field;
}

function getNotOnPdfFieldData(aql) {
  if (!aql.coordinates.length) return undefined;
  const coordinate = aql.coordinates[0];
  const field = coordinate.options.map(({ text, value }) => ({
    text,
    value,
    virtual: true
  }));

  return field;
}

function getFieldData(aql) {
  const pdfTypes = [RADIO_OPTION_TYPE, CHECKBOX_OPTION_TYPE];
  let pdfTypeNeedsOptions = pdfTypes.includes(aql.pdfFieldType);
  if (
    aql.pdfFieldType === NOT_ON_PDF_OPTION_TYPE &&
    [SELECT_OPTION_TYPE, RADIO_OPTION_TYPE, CHECKBOX_OPTION_TYPE].includes(
      aql.quoteAndApplyType
    )
  ) {
    pdfTypeNeedsOptions = true;
  }
  const isOnPdf = aql.pdfFieldType !== NOT_ON_PDF_OPTION_TYPE;

  if (pdfTypeNeedsOptions) return getRadioFieldData(aql);
  else if (isOnPdf) return getTextFieldData(aql);
  return getNotOnPdfFieldData(aql);
}

export function getAqlUpdateRequestBody(aql) {
  const field = getFieldData(aql);
  let reference_application_question_link_id = null;
  if (aql.referenceField) {
    reference_application_question_link_id = +aql.referenceField;
  }

  const body = {
    field,
    question: aql.question,
    reference_application_question_link_id,
    tia: aql.isTiaField,
    docusign: aql.isDocusignField,
    docusign_required: aql.isDocusignRequired,
    required_of: aql.docusignRequiredOf,
    application_field_type: aql.pdfFieldType,
    additional_form: aql.additionalForm,
    required_value: aql.requiredValue,
    parent_application_question_link_id: aql.parentQuestion || null,
    required_parent_value: aql.requiredParentValue,
    field_type: aql.quoteAndApplyType,
    verbatim_question: aql.verbatimQuestion,
    insured_index: aql.insuredIndex,
    comb: aql.comb,
    background: aql.obscureBackground
  };

  if (aql.applicationQuestion) {
    body.application_question_id = aql.applicationQuestion.id;
    body.method_name = aql.applicationQuestion.name;
  } else {
    body.application_question_id = null;
    body.method_name = null;
  }

  if (aql.smartApplicationQuestion) {
    body.smart_application_question_id = aql.smartApplicationQuestion.id;
    body.smart_method_name = aql.smartApplicationQuestion.name;
  } else {
    body.smart_application_question_id = null;
    body.smart_method_name = null;
  }
  return body;
}

export function getAqlCreateRequestBody(aql) {
  const ds = {};

  const field = getFieldData(aql);
  if (Array.isArray(field)) {
    if (field.length) ds.field = getFieldData(aql);
  } else if (field) {
    ds.field = getFieldData(aql);
  }

  if (aql.parentQuestion) {
    ds.parent_application_question_link_id = aql.parentQuestion;
  }

  if (aql.pdfFieldType) {
    ds.application_field_type = aql.pdfFieldType;
  }

  if (aql.applicationQuestion?.id) {
    ds.application_question_id = aql.applicationQuestion?.id;
  }

  if (aql.applicationQuestion?.name) {
    ds.method_name = aql.applicationQuestion?.name;
  }

  if (aql.insuredIndex || aql.insuredIndex === 0) {
    ds.insured_index = aql.insuredIndex;
  }

  if (aql.referenceField) {
    ds.reference_application_question_link_id = aql.referenceField;
  }

  return ds;
}

// Application Question

export function ApplicationQuestionFactory(model = {}) {
  return {
    application_field_type: model?.application_field_type,
    created_at: model?.created_at,
    deleted_at: model?.deleted_at,
    id: model?.id,
    name: model?.name,
    required_of: model?.required_of,
    updated_at: model?.updated_at,
    valid_answers: model?.valid_answers,
    verbatim_question: model?.verbatim_question,
    result: model?.result
  };
}

export function setAppQuestionFromRequest(aq, model = {}) {
  aq.application_field_type = model?.application_field_type;
  aq.created_at = model?.created_at;
  aq.deleted_at = model?.deleted_at;
  aq.id = model?.id;
  aq.name = model?.name;
  aq.required_of = model?.required_of;
  aq.updated_at = model?.updated_at;
  aq.valid_answers = model?.valid_answers;
  aq.verbatim_question = model?.verbatim_question;
  aq.result = model?.result;
}

// Application Question Link Field

export function AqlField(model = {}) {
  const { text, value, virtual } = new AqlFieldOption(model);

  return {
    page: model?.page,
    x: model?.x,
    y: model?.y,
    width: model?.width,
    height: model?.height,
    text,
    value,
    virtual,
    options: model?.options || [],
    order: 0,
    isFieldOption: model?.isFieldOption || false,
    uuid: uuidv4()
  };
}

// Application Question Link Field Option

export function AqlFieldOption(model = {}) {
  return {
    text: model?.text || "",
    value: model?.value || "",
    virtual: model?.virtual || false
  };
}

function setCoordinatesFromFieldOptions(aql, coordinates) {
  aql?.field?.forEach(({ page, x, y, value, text, virtual }) => {
    const aqlField = new AqlField({
      text,
      value,
      virtual,
      page,
      x,
      y,
      isFieldOption: true
    });
    coordinates.push(aqlField);
  });
}

function makeCoordinatesPositive(coordinate) {
  let { width, x, y, height } = coordinate;
  if (width < 0) {
    x = x + width;
    width = Math.abs(width);
  }

  if (height < 0) {
    y = y + width;
    height = Math.abs(height);
  }

  return { x, width, height, y };
}

function setCoordinatesFromText(aql, coordinates) {
  const options = [];
  if (aql?.field?.options?.length) {
    aql.field.options.forEach(fo => {
      const aqlFieldOption = new AqlFieldOption(fo);
      options.push(aqlFieldOption);
    });
  }

  const aqlField = new AqlField({
    options,
    page: aql.field.page,
    ...makeCoordinatesPositive(aql.field)
  });

  coordinates.push(aqlField);
}
