<template>
  <v-card flat color="section" tile>
    <v-card-text :class="{ 'pa-0': noPadding }">
      <v-data-table
        data-testid="product-commissions-table"
        class="transparent-data-table"
        :items="table.mappedItems.value"
        :headers="table.headers.value"
        :options="table.options.value"
      >
        <template #top v-if="user.isGroupFour">
          <v-btn
            data-testid="add-payout"
            class="text-none my-3"
            color="primary"
            @click="addPayout"
          >
            <v-icon> $mdi-plus</v-icon> Payout
          </v-btn>
        </template>

        <template #[`item.percent`]="{ item }">
          <percentage-formatter :value="item.percent" />
        </template>

        <template #[`item.actions`]="{ item }">
          <v-btn
            data-testid="edit-payout"
            icon
            @click="editPayout(item.additional)"
          >
            <v-icon color="primary">$mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            data-testid="remove-payout"
            icon
            @click="removePayout(item.additional)"
          >
            <v-icon color="red">$mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";
import { defineProps, set } from "vue";

import { useUserStore } from "@/stores/user";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import { deletePayout } from "@/api/payouts.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";

const props = defineProps({
  product: Object,
  noPadding: Boolean
});

const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const table = useTable({
  items: props.product.payouts,
  headers: [
    new TableHeader({
      text: "Comp Builder",
      value: "compBuilder",
      map: "compBuilder.name",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Payor",
      value: "payor",
      map: "payor.name"
    }),
    new TableHeader({
      text: "Commission Type",
      value: "commissionType",
      map: "commissionType"
    }),
    new TableHeader({
      text: "Percent",
      value: "percent",
      map: "percent"
    }),
    new TableHeader({
      text: "Start Year",
      value: "startYear",
      map: "startYear"
    }),
    new TableHeader({
      text: "End Year",
      value: "endYear",
      map: "endYear"
    })
  ],
  options: new TableOptions(["compBuilder"], [false])
});

if (user.isGroupFour) {
  table.headers.value.push(
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    })
  );
}

function removePayout(item) {
  dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Remove payout from ${props.product.name}?`,
    subtitle: "This cannot be undone",
    func: async () => {
      await deletePayout(item.id);
      const index = table.items.value.findIndex(i => i.id === item.id);
      table.items.value.splice(index, 1);
      snackbar.showSuccessSnackbar({
        message: "Payout has been deleted",
        timeout: 6000
      });
    }
  });
}

function editPayout(item) {
  dialog
    .showDialog({
      component: "AddPayoutDialog",
      payors: props.product.payorOptions,
      productId: props.product.id,
      carrierId: props.product.carrier.id,
      value: item
    })
    .then(result => {
      if (!result.payout) return;
      const index = table.items.value.findIndex(i => i.id === item.id);
      if (index === -1) return;
      set(table.items.value, index, result.payout);
    });
}
function addPayout() {
  dialog
    .showDialog({
      component: "AddPayoutDialog",
      payors: props.product.payorOptions,
      productId: props.product.id,
      carrierId: props.product.carrier.id
    })
    .then(result => {
      if (!result.payout) return;
      table.items.value.push(result.payout);

      snackbar.showSuccessSnackbar({
        message: "Payout has been added.",
        timeout: 6000
      });
    });
}
</script>
