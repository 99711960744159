var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticStyle:{"background-color":"transparent"},attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.tableOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"py-3",class:{ 'px-3': _vm.evenAddAccessPadding, 'px-6': !_vm.evenAddAccessPadding },attrs:{"align":"center"}},[_vm._t("top"),(_vm.$slots.top)?_c('v-spacer'):_vm._e(),(_vm.searchType === 'advisor')?_c('advisor-search',{attrs:{"hide-details":"","data-testid":"access-advisor-search"},model:{value:(_vm.access),callback:function ($$v) {_vm.access=$$v},expression:"access"}}):_vm._e(),(_vm.searchType === 'audience')?_c('audience-search',{attrs:{"hide-details":"","carrierId":_vm.carrierId,"data-testid":"access-audience-search"},model:{value:(_vm.access),callback:function ($$v) {_vm.access=$$v},expression:"access"}}):_vm._e(),_c('v-btn',{staticClass:"text-none ml-2",attrs:{"data-testid":"access-add-advisor","loading":_vm.addingAccess,"color":_vm.buttonColor},on:{"click":_vm.addAccess}},[_c('v-icon',[_vm._v("$mdi-plus")]),_vm._v(" "+_vm._s(_vm.buttonText)+" ")],1)],2)]},proxy:true},{key:`item.name`,fn:function({ item }){return [(
        (_vm.nameLink || item.additional.routerLink) &&
        !item.additional.tableHelpers.deleting &&
        !item.additional.disabled
      )?_c('router-link',{attrs:{"to":item.additional.routerLink || _vm.nameLink(item),"data-testid":"access-name"}},[_vm._v(" "+_vm._s(item.name)+" ")]):[_vm._v(" "+_vm._s(item.name)+" ")]]}},_vm._l((_vm.checkboxes),function(checkbox){return {key:`item.${checkbox.value}`,fn:function({ item }){return [_c('td',{key:_vm.getSavingBufferKey(item, checkbox.value),staticStyle:{"width":"4rem !important"}},[(!checkbox.if || checkbox.if(item))?_c('v-checkbox',{attrs:{"data-testid":`access-checkbox-${checkbox.text}`,"disabled":item.additional.tableHelpers.deleting || item.additional.disabled},on:{"change":function($event){return _vm.updateAttribute(item, checkbox.value)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('active-save-indicator',{attrs:{"controller":_vm.savingBuffer[_vm.getSavingBufferKey(item, checkbox.value)]}})]},proxy:true}],null,true),model:{value:(item[checkbox.value]),callback:function ($$v) {_vm.$set(item, checkbox.value, $$v)},expression:"item[checkbox.value]"}}):_vm._e()],1)]}}}),{key:`item.actions`,fn:function({ item }){return [(
        (!_vm.deletable || (_vm.deletable && _vm.deletable(item))) &&
        !item.additional.disabled
      )?_c('v-btn',{attrs:{"icon":"","data-testid":"access-delete","loading":item.additional.tableHelpers.deleting},on:{"click":function($event){return _vm.deleteAccess(item)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" $mdi-delete ")])],1):_vm._e()]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }