<template>
  <div>
    <v-list nav>
      <template v-for="item in sidebarData">
        <v-list-group
          v-if="item.subItems"
          :key="item.title"
          :id="item.id ? item.id : null"
        >
          <template #activator>
            <v-list-item-icon>
              <v-icon> {{ item.icon }} </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ item.title }} </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="subItem in item.subItems">
            <v-list-group
              v-if="subItem.subItems"
              :key="subItem.title"
              no-action
              sub-group
            >
              <template #activator>
                <v-list-item-content>
                  <v-list-item-title> {{ subItem.title }} </v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="subsubItem in subItem.subItems">
                <v-list-item
                  exact
                  :key="subsubItem.title"
                  link
                  :to="
                    subsubItem.routeName ? { name: subsubItem.routeName } : null
                  "
                  :href="
                    subsubItem.externalLink ? subsubItem.externalLink : null
                  "
                  :target="item.externalLink ? '_blank' : 'self'"
                >
                  <v-list-item-title>
                    {{ subsubItem.title }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list-group>
            <v-list-item
              v-else
              exact
              :key="subItem.title"
              link
              :to="subItem.routeName ? { name: subItem.routeName } : null"
              :href="subItem.externalLink ? subItem.externalLink : null"
              :target="item.externalLink ? '_blank' : 'self'"
            >
              <v-list-item-icon>
                <v-icon> {{ subItem.icon }} </v-icon>
              </v-list-item-icon>
              <v-list-item-title> {{ subItem.title }} </v-list-item-title>
            </v-list-item>
          </template>
        </v-list-group>
        <v-list-item
          class="mb-0"
          v-else-if="item.click"
          exact
          :key="item.title"
          link
          :id="item.id ? item.id : null"
          :target="item.externalLink ? '_blank' : 'self'"
          :color="item.title === 'Quote & Apply' ? QAColor : 'primary'"
          @click="item.click"
        >
          <v-list-item-icon>
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              :class="item.title === 'Quote & Apply' ? `${QAColor}--text` : ''"
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="mb-0"
          v-else
          exact
          :key="item.title"
          link
          :id="item.id ? item.id : null"
          :to="
            item.routeName
              ? {
                  name: item.routeName,
                  params: item.params
                }
              : null
          "
          :href="item.externalLink ? item.externalLink : null"
          :target="item.externalLink ? '_blank' : 'self'"
          :color="item.title === 'Quote & Apply' ? QAColor : 'primary'"
        >
          <v-list-item-icon>
            <v-icon :color="item.title === 'Quote & Apply' ? QAColor : null">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              :class="item.title === 'Quote & Apply' ? `${QAColor}--text` : ''"
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <div :class="{ 'bottom-margin': bottomMargin }" />
  </div>
</template>

<script>
import { getFilteredRoutes } from "@/data/sidebar";
import isHoliday from "@/util/is-holiday";
import { useUserStore } from "@/stores/user";
export default {
  props: {
    bottomMargin: Boolean
  },
  data() {
    const QAColor = isHoliday() ? "primary" : "accent";
    const user = useUserStore();
    return {
      QAColor,
      sidebarData: getFilteredRoutes(user)
    };
  }
};
</script>
