<template>
  <v-row class="pa-3" align="center" justify="center">
    <template v-if="loading">
      <h3>Loading Quote & Apply Report</h3>
      <v-progress-circular class="ml-3" indeterminate />
    </template>
    <template v-else-if="!loading && !report">
      <h3>Unable to load reports</h3>
    </template>
    <v-card
      v-else
      v-for="analytic in analytics"
      :key="analytic.title"
      class="my-1 width-full"
      cols="12"
      dense
    >
      <v-card-title class="text-h4 justify-center">
        {{ analytic.title }}
      </v-card-title>
      <v-row dense class="ma-0 pa-2" justify="center">
        <v-col
          v-for="(item, index) in analytic.data"
          lg="3"
          cols="6"
          :key="'all' + index"
        >
          <v-card flat color="section">
            <v-card-title class="justify-center">
              <percentage-formatter
                v-if="item.filter === 'percentage'"
                :value="item.value"
                is-decimal
              />
              <currency-formatter
                v-else-if="item.filter === 'wholeCurrency'"
                :value="item.value"
                :decimal-length="0"
              />
              <number-formatter v-else :value="Math.trunc(item.value)" />
            </v-card-title>
            <v-card-subtitle class="text-center">
              {{ item.title }}
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";
import NumberFormatter from "@/components/shared/formatters/NumberFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import { getQuoteAndApplyReport } from "@/api/reports.service";
import { useHead } from "@unhead/vue";

export default {
  components: { CurrencyFormatter, NumberFormatter, PercentageFormatter },
  setup() {
    useHead({ title: "Quote & Apply Report" });
  },
  data() {
    return {
      analytics: [
        {
          title: "All",
          data: [
            this.analyticFactory("Created All Time", "created_all_time"),
            this.analyticFactory("Created Last 30 Days", "created_last_30"),
            this.analyticFactory("Completed All Time", "completed_all_time"),
            this.analyticFactory("Completed Last 30 Days", "completed_last_30"),
            this.analyticFactory("Completed Last 7 Days", "completed_last_7"),
            this.analyticFactory(
              "Average Premium for Completed eApps",
              "average_premium",
              "wholeCurrency"
            ),
            this.analyticFactory(
              "Placement Ratio for Completed eApps",
              "placement_ratio",
              "percentage"
            )
          ]
        },
        {
          title: "Agent Links",
          data: [
            this.analyticFactory(
              "Agent Links - Active Links",
              "links_created_last_30"
            ),
            this.analyticFactory(
              "Agent Links - Views in Last 30 Days",
              "link_views_last_30"
            ),
            this.analyticFactory(
              "Agent Links - Created Last 30 Days",
              "links_created_last_30"
            ),
            this.analyticFactory(
              "Agent Links - Completed Last 30 Days",
              "links_completed_last_30"
            )
          ]
        },
        {
          title: "Agent Website",
          data: [
            this.analyticFactory(
              "Agent Websites - 100% - Active Websites",
              "premium_active_websites"
            ),
            this.analyticFactory(
              "Agent Websites - 100% - Views in Last 30 Days",
              "premium_website_views_last_30"
            ),
            this.analyticFactory(
              "Agent Websites - 100% - Created Last 30 Days",
              "premium_created_last_30"
            ),
            this.analyticFactory(
              "Agent Websites - 100% - Completed Last 30 Days",
              "premium_completed_last_30"
            )
          ]
        },
        {
          title: "White Glove",
          data: [
            this.analyticFactory(
              "White Glovee - 50% - Active Websites",
              "plus_active_websites"
            ),
            this.analyticFactory(
              "White Glove - 50% - Views in Last 30 Days",
              "plus_website_views_last_30"
            ),
            this.analyticFactory(
              "White Glove - 50% - Created Last 30 Days",
              "plus_created_last_30"
            ),
            this.analyticFactory(
              "White Glove - 50% - Completed Last 30 Days",
              "plus_completed_last_30"
            )
          ]
        },
        {
          title: "Affiliate",
          data: [
            this.analyticFactory(
              "Affiliate - Fee - Active Websites",
              "basic_active_websites"
            ),
            this.analyticFactory(
              "Affiliate - Views in Last 30 Days",
              "basic_website_views_last_30"
            ),
            this.analyticFactory(
              "Affiliate - Fee - Created Last 30 Days",
              "basic_created_last_30"
            ),
            this.analyticFactory(
              "Affiliate - Fee - Completed Last 30 Days",
              "basic_completed_last_30"
            )
          ]
        }
      ],
      loading: false,
      report: null
    };
  },
  created() {
    this.getReport();
  },
  methods: {
    analyticFactory(title, key, filter = null) {
      return {
        title,
        key,
        value: 0,
        filter
      };
    },
    async getReport() {
      this.loading = true;
      this.error = false;
      try {
        this.report = await getQuoteAndApplyReport();
        this.analytics.forEach(g =>
          g.data.forEach(a => (a.value = this.report[a.key]))
        );
      } catch (e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
