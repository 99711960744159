<template>
  <v-card color="section" flat tile>
    <v-card-text class="pa-3">
      <v-row v-if="showSideBySide" class="my-0">
        <v-col cols="12" :md="expanded ? 8 : 4" class="pr-1 py-0">
          <slot name="left" />
        </v-col>
        <v-col cols="12" :md="expanded ? 4 : 8" class="pl-2 py-0">
          <slot name="right" />
        </v-col>
      </v-row>
      <v-tabs-items v-else v-model="activeTab" touchless>
        <v-tab-item>
          <slot name="left" />
        </v-tab-item>
        <v-tab-item>
          <slot name="right" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    showSideBySide: Boolean,
    activeTab: Number,
    expanded: Boolean
  }
};
</script>
