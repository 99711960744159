<template>
  <v-autocomplete
    v-model="state"
    outlined
    dense
    prepend-inner-icon="$mdi-home-group"
    auto-select-first
    :data-testid="dataTestid"
    :disabled="disabled"
    :label="localLabel"
    :items="states"
    :multiple="multiple"
    :success="success"
    :error-messages="errorMessages"
    @blur="$emit('blur')"
    @change="$emit('change')"
  >
    <template #append-outer>
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import { states } from "@/data/states";

export default {
  props: {
    dataTestid: {
      type: String,
      required: false,
      default: ""
    },
    abbreviated: Boolean,
    success: Boolean,
    errorMessages: Array,
    multiple: Boolean,
    label: String,
    value: [String, Array],
    disabled: Boolean
  },
  data() {
    const formattedStates = states.map(({ full, text }) => {
      if (this.abbreviated) {
        return { text, value: text };
      }
      return { text: full, value: text };
    });
    return {
      localLabel: this.label || "State",
      state: this.value || null,
      states: formattedStates
    };
  },
  watch: {
    value(val) {
      this.state = val;
    },
    state(value) {
      this.$emit("input", value);
    }
  }
};
</script>
