<template>
  <v-col cols="12" style="padding-left: 12px">
    <div
      style="border-left: 5px solid #4caf50"
      :style="{
        borderLeftColor: documentIds.length === 0 ? 'red' : '#4caf50'
      }"
    >
      <v-card flat tile color="transparent">
        <v-card-title>Documents</v-card-title>
        <v-card-subtitle>
          Please provide at least one document for why you answered "Yes" to the
          question "{{ question.questionText }}"
        </v-card-subtitle>
        <v-card-text>
          <file-drag-and-drop
            v-model="newDocument"
            multiple
            upload
            :success="newDocumentValidation.success"
            :error-messages="newDocumentValidation.errorMessages"
            :disabled="readonly"
            :upload-func="createDocument"
          />
          <v-list class="py-0" style="max-width: 30rem">
            <v-list-item
              v-for="documentId in documentIds"
              :data-testid="`document-${documentId}`"
              :key="documentId"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ documents[documentId].name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="!readonly">
                <v-row class="ma-0">
                  <v-btn
                    icon
                    color="primary"
                    :data-testid="`document-${documentId}-download`"
                    v-bind="downloadDocument(documentId)"
                  >
                    <v-icon> $mdi-download </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="error"
                    :data-testid="`document-${documentId}-delete`"
                    @click.stop="deleteConfirmation(documentId)"
                  >
                    <v-icon> $mdi-delete </v-icon>
                  </v-btn>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
  </v-col>
</template>

<script>
import { downloadFileAsLink, parseErrorMessage } from "@/util/helpers";
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import { mapActions } from "pinia";
import { getDocumentUrl } from "@/api/documents.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useAgentSettingsStore } from "@/stores/agent-settings";
export default {
  components: {
    FileDragAndDrop
  },
  props: {
    questionId: [String, Number],
    readonly: Boolean
  },
  setup(props) {
    const agent = useAgentSettingsStore();

    return {
      question: agent.contractingQuestions[props.questionId],
      createAndAddDocument: agent.createAndAddDocument,
      deleteAndRemoveDocument: agent.deleteAndRemoveDocument
    };
  },
  data() {
    return {
      newDocument: []
    };
  },
  computed: {
    documents() {
      return this.question.documents;
    },
    documentIds() {
      return Object.keys(this.documents);
    },
    newDocumentValidation() {
      const success = !this.$v.documents.$invalid;
      const errorMessages = [];
      if (!this.$v.newDocument.$dirty) return { success, errorMessages };
      if (!this.$v.newDocument.allValidSizes)
        errorMessages.push(
          "Please confirm all files have data or try Re-uploading these files"
        );
      return { success, errorMessages };
    }
  },

  methods: {
    ...mapActions(useSnackbarStore, [
      "showSuccessSnackbar",
      "showErrorSnackbar"
    ]),
    ...mapActions(useDialogStore, ["showDialog"]),
    async createDocument(file) {
      try {
        await this.createAndAddDocument({ file, questionId: this.questionId });
        this.showSuccessSnackbar({
          message: "Successfully Uploaded Document"
        });
        this.newDocument = [];
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      }
    },
    deleteConfirmation(documentId) {
      if (this.readonly) return;

      this.showDialog({
        component: "ConfirmationDialog",
        title: "Are you sure you want to delete this document?",
        subtitle: "Please confirm your intent",
        func: () =>
          this.deleteAndRemoveDocument({
            questionId: this.questionId,
            documentUid: this.documents[documentId].uid,
            documentId
          })
      });
    },
    downloadDocument(documentId) {
      return downloadFileAsLink(
        getDocumentUrl(this.documents[documentId].uid),
        this.$vuetify.breakpoint.mdAndDown
      );
    },
    valid() {
      this.$v.$touch();
      return !this.$v.$invalid;
    }
  },
  validations: {
    documents: {
      atLeastOne: val => val?.length > 0
    },
    newDocument: {
      allValidSizes: val => !val.some(doc => doc?.size === 0)
    }
  }
};
</script>
