import AddBuildChartDialog from "@/dialogs/AddBuildChartDialog.vue";
import AddEftDialog from "@/dialogs/AddEftDialog.vue";
import AddLoginDialog from "@/dialogs/AddLoginDialog.vue";
import AddPayoutDialog from "@/dialogs/AddPayoutDialog.vue";
import AddProductBuildChartDialog from "@/dialogs/AddProductBuildChartDialog.vue";
import AddProductCitizenshipProfileDialog from "@/dialogs/AddProductCitizenshipProfileDialog.vue";
import AddressItemDialog from "@/dialogs/AddressItemDialog.vue";
import AddSmokerChartDialog from "@/dialogs/AddSmokerChartDialog.vue";
import AddTemporaryInsuranceProfileDialog from "@/dialogs/AddTemporaryInsuranceProfileDialog.vue";
import AdvisorEoDialog from "@/dialogs/AdvisorEoDialog.vue";
import AliasCreateOrEdit from "@/dialogs/AliasCreateOrEdit.vue";
import AppointmentCreate from "@/components/appointments/AppointmentCreate.vue";
import CarrierCompBuilderCreate from "@/components/carriers/CarrierCompBuilderCreate.vue";
import CategoryStatesDialog from "@/dialogs/CategoryStatesDialog.vue";
import ChangeAccessDialog from "@/dialogs/ChangeAccessDialog.vue";
import ChangeAvatarDialog from "@/dialogs/ChangeAvatarDialog.vue";
import ChangeEmailDialog from "@/dialogs/ChangeEmailDialog.vue";
import ChatTemplateViewer from "@/dialogs/ChatTemplateViewer.vue";
import CommissionDialog from "@/dialogs/CommissionDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import ContactDetailsDialog from "@/dialogs/ContactDetailsDialog.vue";
import CopyDialog from "@/dialogs/CopyDialog.vue";
import CreateAffiliationDialog from "@/dialogs/CreateAffiliationDialog.vue";
import CreateFormDialog from "@/dialogs/CreateFormDialog.vue";
import CreateNoteDialog from "@/dialogs/CreateNoteDialog.vue";
import ContractingQuestionExplanationDialog from "@/dialogs/ContractingQuestionExplanationDialog.vue";
import CreateQuoteAndApplySite from "@/dialogs/CreateQuoteAndApplySite.vue";
import CreateTrainingDialog from "@/dialogs/CreateTrainingDialog.vue";
import CreatorImageDialog from "@/components/help-center/HelpCenterCreatorImageDialog.vue";
import DeleteDialog from "@/dialogs/DeleteDialog.vue";
import EditConnectionDialog from "@/dialogs/EditConnectionDialog.vue";
import EditPayPeriodDialog from "@/dialogs/EditPayPeriodDialog.vue";
import EditVendorDialog from "@/dialogs/EditVendorDialog.vue";
import EmployeeReviewDialog from "@/dialogs/EmployeeReviewDialog.vue";
import EmploymentHistoryDialog from "@/dialogs/EmploymentHistoryDialog.vue";
import HelpCenterReader from "@/components/help-center/HelpCenterReader.vue";
import LeadContactedDialog from "@/dialogs/LeadContactedDialog.vue";
import MarketingManagerEditDialog from "@/dialogs/MarketingManagerEditDialog.vue";
import MygaRateDialog from "@/dialogs/MygaRateDialog.vue";
import NewAgencyDialog from "@/dialogs/NewAgencyDialog.vue";
import NewCommissionAssignmentDialog from "@/dialogs/NewCommissionAssignmentDialog.vue";
import RateBandDialog from "@/dialogs/RateBandDialog.vue";
import SignerEdit from "@/dialogs/SignerEdit.vue";
import SmokingTypeDialog from "@/dialogs/SmokingTypeDialog.vue";
import SynthesizeQuoteRequestDialog from "@/dialogs/SynthesizeQuoteRequestDialog.vue";
import TransactionCreate from "@/components/commissions/TransactionCreate.vue";
import UploadDocumentDialog from "@/components/shared/dialogs/UploadDocumentDialog.vue";
import WelcomeTourDialog from "@/dialogs/WelcomeTourDialog.vue";

import DocumentDialog from "@/dialogs/DocumentDialog.vue";
import TextEditDialog from "@/dialogs/TextEditDialog.vue";

export default {
  AddBuildChartDialog,
  AddEftDialog,
  AddLoginDialog,
  AddPayoutDialog,
  AddProductBuildChartDialog,
  AddProductCitizenshipProfileDialog,
  AddressItemDialog,
  AddSmokerChartDialog,
  AddTemporaryInsuranceProfileDialog,
  AdvisorEoDialog,
  AliasCreateOrEdit,
  AppointmentCreate,
  CarrierCompBuilderCreate,
  CategoryStatesDialog,
  ChangeAccessDialog,
  ChangeAvatarDialog,
  ChangeEmailDialog,
  ChatTemplateViewer,
  CommissionDialog,
  ConfirmationDialog,
  ContactDetailsDialog,
  CopyDialog,
  CreateAffiliationDialog,
  CreateFormDialog,
  CreateNoteDialog,
  ContractingQuestionExplanationDialog,
  CreateQuoteAndApplySite,
  CreateTrainingDialog,
  CreatorImageDialog,
  DeleteDialog,
  DocumentDialog,
  EditConnectionDialog,
  EditPayPeriodDialog,
  EditVendorDialog,
  EmployeeReviewDialog,
  EmploymentHistoryDialog,
  HelpCenterReader,
  LeadContactedDialog,
  MarketingManagerEditDialog,
  MygaRateDialog,
  NewAgencyDialog,
  NewCommissionAssignmentDialog,
  RateBandDialog,
  SignerEdit,
  SmokingTypeDialog,
  SynthesizeQuoteRequestDialog,
  TransactionCreate,
  UploadDocumentDialog,
  WelcomeTourDialog,
  TextEditDialog
};
