<template>
  <v-card>
    <v-card-text>
      <v-data-table
        must-sort
        data-testid="contract-parties-table"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :server-items-length="table.meta.value.total"
        :loading="table.loading.value"
        :options="table.options.value"
        :footer-props="footerProps"
        @update:options="updateOptions"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5">Clients</h1>
            <v-spacer />
            <v-tooltip v-if="table.filter.value.view !== 'all'" top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  data-testid="contract-parties-table-email-data"
                  @click="confirmAndEmailData"
                >
                  <v-icon>$mdi-file-export</v-icon>
                </v-btn>
              </template>
              <span>Email Data</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  data-testid="contract-parties-table-refresh"
                  @click="getData"
                >
                  <v-icon>$mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
          <generic-table-filter-header
            :value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>
        <template #[`item.name`]="{ item }">
          <v-btn
            class="text-none"
            color="primary"
            depressed
            small
            :to="{
              name: 'ContractPartyView',
              params: {
                id: item.additional.id
              }
            }"
          >
            <v-icon
              v-if="item.additional.type === 'individual'"
              class="mr-1"
              small
            >
              $mdi-human-greeting</v-icon
            >
            <v-icon v-else class="mr-1" small> $mdi-domain </v-icon>
            {{ item.name }}
          </v-btn>
        </template>
        <template #[`item.email`]="{ item }">
          <a :href="`mailto:${item.email}`"> {{ item.email }} </a>
        </template>
        <template #[`item.income`]="{ item }">
          <currency-formatter :value="item.income" :decimal-length="0" />
        </template>
        <template #[`item.birthdate`]="{ item }">
          <timestamp-formatter :value="item.birthdate" parser="sole-day" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import { parseErrorMessage } from "@/util/helpers";
import { useUserStore } from "@/stores/user";

import {
  getContractParties,
  emailContractParties
} from "@/api/contract-party.service";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTableStore } from "@/stores/table";
import { useTable } from "@/composables/table.composable";
import { computed } from "vue";
import { storeToRefs } from "pinia";

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      sortFilterMap: "contract_parties.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Email",
      value: "email",
      map: "email",
      sortFilterMap: "contract_parties.email",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Birthdate",
      value: "birthdate",
      map: "birthdate",
      sortFilterMap: "contract_parties.birthdate",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Income",
      value: "income",
      map: "income",
      sortFilterMap: "contract_parties.income",
      ...TableHeader.IS_CURRENCY,
      ...TableHeader.IS_NUMBER_RANGE_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Shared With",
      value: "additionalAdvisor",
      map: "additional_advisor",
      sortFilterMap: [
        { key: "shared_with_id", value: "id" },
        { key: "shared_with_type", value: "type" }
      ],
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL
    }),
    new TableHeader({
      text: "Has Inforce Cases",
      value: "hasInforceCases",
      map: "hasInforceCases",
      sortFilterMap: "with_active_cases",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_CHECKBOX_TYPE
    })
  ],
  getData: getContractParties,
  emailData: emailContractParties
});

const user = useUserStore();
if (user.isGroupTwoPlus) {
  table.headers.value.unshift(
    new TableHeader({
      text: "View",
      value: "view",
      map: "view",
      order: 1,
      sortFilterMap: "view",
      selectableOptions: [
        { text: "All Clients", value: "all" },
        { text: "My Clients", value: "my" }
      ],
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_MANDATORY
    })
  );
}

const { contractPartiesTable } = storeToRefs(useTableStore());

table.filter.value = getLoadInFilter();
table.options.value = getLoadInOptions();

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(table.options.value, table.meta.value),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

function confirmAndEmailData() {
  const dialog = useDialogStore();
  dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Email Data?",
    subtitle: "Please confirm you would like this data emailed to you.",
    func: () => emailData()
  });
}
async function emailData() {
  const snackbar = useSnackbarStore();
  try {
    await table.emailData();
    snackbar.showSuccessSnackbar({
      message: `You will receive a copy of this data in your inbox shortly.`,
      timeout: 6000
    });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(error),
      timeout: -1
    });
  }
}

async function getData() {
  const additionalFilter = {};
  if (user.isGroupOne) additionalFilter.view = "my";
  try {
    await table.getData(additionalFilter);
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function getLoadInFilter() {
  let filter = {};
  if (user.isGroupThreePlus) {
    filter.view = "all";
  } else if (user.isGroupTwoPlus) {
    filter.view = "my";
  }
  if (contractPartiesTable.value.filter) {
    Object.keys(contractPartiesTable.value.filter).forEach(key => {
      if (!table.headers.value.find(header => header.value === key)) return;
      filter[key] = contractPartiesTable.value.filter[key];
    });
  }
  return filter;
}

function getLoadInOptions() {
  let options = new TableOptions(["name"], [false]);
  if (Object.keys(contractPartiesTable.value.options).length) {
    options = contractPartiesTable.value.options;
  }
  return options;
}

function updateOptions(newOptions) {
  if (table.optionsEquivalence(newOptions)) return;
  table.options.value = newOptions;
  contractPartiesTable.value.options = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  contractPartiesTable.value.filter = filter;
  table.resetPage();
  getData();
}

getData();
</script>
