<template>
  <v-card>
    <v-card-title>
      <v-row class="pl-3 pt-3 pb-2" align="start">
        Help Center Article Creator
        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" icon @click="toggleFullscreen">
              <v-icon v-if="fullscreen">$mdi-fullscreen-exit</v-icon>
              <v-icon v-else>$mdi-fullscreen</v-icon>
            </v-btn>
          </template>
          <span v-if="fullscreen"> Exit Fullscreen</span>
          <span v-else>Enter Fullscreen</span>
        </v-tooltip>
        <v-tooltip bottom v-if="article.id">
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              icon
              color="error"
              @click="destroyArticle"
            >
              <v-icon>$mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Delete Article</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="articleIsPublic && article.minimumGroup === null"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              :href="publicLink"
              color="primary"
              target="_blank"
            >
              <v-icon>$mdi-link-variant</v-icon>
            </v-btn>
          </template>
          <span>View Public Article</span>
        </v-tooltip>
      </v-row>
    </v-card-title>
    <v-card-subtitle>Create an article in minutes!</v-card-subtitle>
    <v-card-text>
      <v-row class="ma-0">
        <v-col cols="6">
          <v-card tile color="section" flat>
            <v-card-title> Editor </v-card-title>
            <v-card-subtitle> Input your markdown here. </v-card-subtitle>
            <v-col cols="12">
              <v-text-field
                data-lpignore="true"
                label="Article Title"
                v-model="article.name"
                outlined
                prepend-inner-icon="$mdi-format-title"
                :success="titleValidation.success"
                :error-messages="titleValidation.errorMessages"
                :loading="loadingArticle"
                counter="255"
              />
            </v-col>
            <hr />
            <v-toolbar dense color="secondary" flat>
              <v-toolbar-items>
                <v-btn
                  icon
                  @click="insertFormat('header')"
                  :disabled="loadingArticle"
                >
                  <v-icon>$mdi-format-header-1</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="insertFormat('header2')"
                  :disabled="loadingArticle"
                >
                  <v-icon>$mdi-format-header-2</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="insertFormat('header3')"
                  :disabled="loadingArticle"
                >
                  <v-icon>$mdi-format-header-3</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="insertFormat('bold')"
                  :disabled="loadingArticle"
                >
                  <v-icon>$mdi-format-bold</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="insertFormat('italic')"
                  :disabled="loadingArticle"
                >
                  <v-icon>$mdi-format-italic</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="linkDialog('link')"
                  :disabled="loadingArticle"
                >
                  <v-icon>$mdi-link</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="linkDialog('image')"
                  :disabled="loadingArticle"
                >
                  <v-icon>$mdi-image</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="linkDialog('document')"
                  :disabled="loadingArticle"
                >
                  <v-icon>$mdi-file-document</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="insertFormat('unorderedlist')"
                  :disabled="loadingArticle"
                >
                  <v-icon>$mdi-format-list-bulleted</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="insertFormat('orderedlist')"
                  :disabled="loadingArticle"
                >
                  <v-icon>$mdi-format-list-numbered</v-icon>
                </v-btn>
                <v-menu>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      :disabled="loadingArticle"
                    >
                      <v-icon>$mdi-video-wireless-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="insertFormat('youtube')">
                      <v-list-item-content>
                        <v-list-item-title>YouTube </v-list-item-title>
                        <v-list-item-subtitle>
                          Can be any YouTube url, confirm on the right
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="insertFormat('vimeo')">
                      <v-list-item-content>
                        <v-list-item-title>Vimeo </v-list-item-title>
                        <v-list-item-subtitle>
                          Does not work in all circumstances e.g.
                          https://vimeo.com/690688918 works
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text class="pb-0">
              <v-textarea
                placeholder="Content"
                v-model="article.content"
                rows="12"
                auto-grow
                ref="ta"
                :success="contentValidation.success"
                :error-messages="contentValidation.errorMessages"
                :loading="loadingArticle"
              />
              <v-combobox
                label="Tags"
                outlined
                dense
                chips
                small-chips
                deletable-chips
                multiple
                prepend-inner-icon="$mdi-label"
                no-data-text="Press 'Enter' to add tag"
                :items="articleTags"
                :success="hasTags"
                v-model="article.tags"
              >
                <template #no-data>
                  <v-list-item>
                    Press "Tab" or click out of this box to add a tag
                  </v-list-item>
                </template>
              </v-combobox>
              <v-select
                label="Visible By"
                :items="visibilityOptions"
                outlined
                dense
                hide-details
                success
                v-model="article.minimumGroup"
              />
            </v-card-text>
            <v-card-actions class="px-4 pb-3">
              <v-spacer />
              <v-checkbox
                v-model="article.published"
                :disabled="loadingArticle || creatingArticle"
                label="Publish Article"
              />
              <v-btn
                class="text-none ml-4"
                color="primary"
                :disabled="loadingArticle"
                :loading="creatingArticle"
                @click="saveArticle"
              >
                <v-icon class="mr-1">$mdi-content-save</v-icon> Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card tile color="section" flat height="100%">
            <v-card-title>Preview</v-card-title>
            <v-card-subtitle>
              A preview of your content will appear here. Actual style may vary.
            </v-card-subtitle>
            <v-card-text>
              <viewer :article="article" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Viewer from "@/components/help-center/HelpCenterViewer.vue";
import { parseErrorMessage } from "@/util/helpers";
import { mapActions, mapState, mapWritableState } from "pinia";
import {
  deleteArticle,
  getArticleById,
  updateArticle,
  uploadArticleImage
} from "@/api/help-desk.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useInstanceStore } from "@/stores/instance";
import { useHead } from "@unhead/vue";

const DEFAULT_TAGS = ["help", "blog", "press-release"];
export default {
  components: {
    Viewer
  },
  props: {
    id: { type: [String, Number], required: false }
  },
  data() {
    return {
      DEFAULT_TAGS,
      article: {
        tags: [],
        name: "",
        content: "",
        published: false,
        id: null,
        minimumGroup: null
      },
      creatingArticle: false,
      loadingArticle: false,
      articleIsPublic: false
    };
  },
  setup({ id }) {
    const head = useHead({
      title: id ? "Article Editor" : "Article Creator"
    });
    return { head };
  },
  created() {
    if (this.$route.name === "HelpCenterArticleCreate") return;
    this.fetchArticle(this.id);
  },
  destroyed() {
    this.fullscreen = false;
    this.breadcrumb = "";
    this.closeDialog();
  },
  computed: {
    ...mapState(useUserStore, { highestGroup: "highest_group" }),
    ...mapWritableState(useInstanceStore, ["fullscreen", "breadcrumb"]),
    visibilityOptions() {
      return [
        { text: "Public, Visible to the World", value: null },
        { text: "BOSS Users, Requires Sign-In", value: 1 },
        { text: "All BackNine Staff", value: 2 },
        { text: "Upper Level BackNine Staff", value: 3 },
        { text: "Admin Only", value: 4 }
      ].filter(({ value }) => value <= this.highestGroup);
    },
    hasTags() {
      return Boolean(this.article.tags.length);
    },
    articleTags() {
      return [
        ...new Set([
          ...DEFAULT_TAGS,
          ...this.article.tags.map(val => val.toLowerCase())
        ])
      ].filter(Boolean);
    },
    urlTitle() {
      return this.article.name
        .replace(/\s+/g, "-")
        .replace(/[^0-9.a-zA-Z-]/g, "");
    },
    titleValidation() {
      const success = !this.$v.article.name.$invalid;
      const errorMessages = [];
      if (this.$v.article.name.$dirty) {
        if (!this.$v.article.name.required) errorMessages.push("Required");
        if (!this.$v.article.name.length)
          errorMessages.push("Title is too long");
      }
      return {
        success,
        errorMessages
      };
    },
    contentValidation() {
      const success = !this.$v.article.content.$invalid;
      const errorMessages = [];
      if (this.$v.article.content.$dirty) {
        if (!this.$v.article.content.required) errorMessages.push("Required");
      }
      return {
        success,
        errorMessages
      };
    },
    publicLink() {
      if (!this.article.link) return null;
      return `${process.env.VUE_APP_HELP_CENTER}/a/${this.article.link}`;
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, [
      "showSuccessSnackbar",
      "showErrorSnackbar"
    ]),
    ...mapActions(useDialogStore, ["showDialog", "closeDialog"]),
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    fetchArticle(id) {
      this.loadingArticle = true;
      getArticleById(id)
        .then(res => {
          this.$set(this, "article", res);
          this.breadcrumb = this.article.name;
          this.head.patch({ title: this.article.name });
          this.articleIsPublic = Boolean(this.article.published);
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
          this.$router.replace({ name: "Home" });
        })
        .finally(() => {
          this.loadingArticle = false;
        });
    },
    destroyArticle() {
      this.showDialog({
        component: "DeleteDialog",
        title: `Are you sure you want to delete ${this.article.name}?`,
        subtitle: "This will delete the public document too.",
        func: () => deleteArticle(this.article.id)
      })
        .then(res => {
          if (!res.delete) return;
          this.showSuccessSnackbar({
            message: `Successfully Deleted ${this.article.name}`
          });
          this.$router.push({ name: "HelpCenter" });
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        });
    },
    linkDialog(type) {
      const locationStart =
        this.$refs.ta.$refs.input.selectionStart ||
        Math.max(this.article.content.length - 1, 0);
      this.showDialog({
        component: "CreatorImageDialog",
        type,
        articleImageUploadFunc: file =>
          uploadArticleImage(this.article.id, file)
      }).then(res => {
        if (!res.markdown) return;
        const textBefore = this.article.content.slice(0, locationStart);
        const textAfter = this.article.content.slice(locationStart);
        this.article.content = textBefore + res.markdown + textAfter;
        this.$nextTick(() => {
          this.$refs.ta.$refs.input.focus();
        });
      });
    },
    insertFormat(type) {
      const locationStart =
        this.$refs.ta.$refs.input.selectionStart ||
        Math.max(this.article.content.length - 1, 0);
      let character = "";
      if (type === "header") {
        character = locationStart === 0 ? "# " : "\n# ";
      } else if (type === "header2") {
        character = locationStart === 0 ? "## " : "\n## ";
      } else if (type === "header3") {
        character = locationStart === 0 ? "### " : "\n### ";
      } else if (type === "bold") {
        character = " **bold text** ";
      } else if (type === "italic") {
        character = " *italicized text* ";
      } else if (type === "link") {
        character = " [title](https://www.example.com) ";
      } else if (type === "image") {
        character =
          locationStart === 0
            ? "![alt text](image.jpg)"
            : "\n![alt text](image.jpg)";
      } else if (type === "orderedlist") {
        character = locationStart === 0 ? "1. " : "\n1. ";
      } else if (type === "unorderedlist") {
        character = locationStart === 0 ? "- " : "\n- ";
      } else if (type === "youtube") {
        character = " @[youtube](insert-youtube-link)";
      } else if (type === "vimeo") {
        character = " @[vimeo](insert-vimeo-link)";
      }

      const textBefore = this.article.content.slice(0, locationStart);
      const textAfter = this.article.content.slice(locationStart);
      this.article.content = textBefore + character + textAfter;
      this.$nextTick(() => {
        this.$refs.ta.$refs.input.focus();
      });
    },
    saveArticle() {
      this.$v.$touch();
      if (this.creatingArticle || this.$v.$invalid) return;
      this.creatingArticle = true;

      updateArticle(this.article.id, {
        name: this.article.name,
        content: this.article.content,
        published: Boolean(this.article.published),
        tags: this.article.tags,
        minimum_group: this.article.minimumGroup
      })
        .then(() => {
          this.showSuccessSnackbar({
            message: `Successfully updated ${this.article.name}`
          });
          this.articleIsPublic = Boolean(this.article.published);
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        })
        .finally(() => {
          this.creatingArticle = false;
        });
    }
  },
  validations() {
    return {
      article: {
        name: { required, length: val => val.length <= 255 && val.length > 0 },
        content: { required }
      }
    };
  }
};
</script>
