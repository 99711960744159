<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    item-class="additional.rowClass"
  >
    <template #[`item.premium`]="{ item }">
      <currency-formatter :value="item.premium" />
    </template>
    <template #[`item.dollar`]="{ item }">
      <currency-formatter :value="item.dollar" />
    </template>
    <template #[`item.percent`]="{ item }">
      <percentage-formatter :value="item.percent" :decimal-length="3" />
    </template>
    <template #[`item.split`]="{ item }">
      <percentage-formatter
        floor
        is-decimal
        :value="item.split"
        :decimal-length="0"
      />
    </template>
    <template #[`item.actions`]="{ item }">
      <v-tooltip v-if="item.additional.errors" top>
        <template #activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" icon>
            <v-icon color="white"> $mdi-information </v-icon>
          </v-btn>
        </template>
        <span> {{ item.additional.errors }} </span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";
import TableHeader from "@/classes/data-table/TableHeader";
import Table from "@/classes/data-table/Table";
export default {
  components: {
    CurrencyFormatter,
    PercentageFormatter
  },
  props: {
    payments: Array,
    loading: Boolean
  },
  mixins: [GenericTableMixin],
  data() {
    const headers = [
      new TableHeader({
        text: "Payable",
        value: "payable",
        map: "payable.name"
      }),
      new TableHeader({
        text: "Assigned Payable",
        value: "assignedPayable",
        map: "assigned_payable.name"
      }),
      new TableHeader({
        text: "Payor",
        value: "payor",
        map: "payor.name"
      }),
      new TableHeader({
        text: "Commission Type",
        value: "commissionType",
        map: "commission_type"
      }),
      new TableHeader({
        text: "Payment Type",
        value: "paymentType",
        map: "payment_type"
      }),
      new TableHeader({
        text: "Dollar",
        value: "premium",
        map: "premium"
      }),
      new TableHeader({
        text: "Percent",
        value: "percent",
        map: "percent"
      }),
      new TableHeader({
        text: "Split",
        value: "split",
        map: "split_percent"
      }),
      new TableHeader({
        text: "Commission",
        value: "dollar",
        map: "dollar"
      }),
      new TableHeader({
        text: "",
        value: "actions",
        map: ""
      })
    ];

    const paymentsTable = new Table({
      text: "Payments",
      headers,
      key: "payments"
    });

    return {
      table: paymentsTable
    };
  },
  computed: {
    headers() {
      return this.table.headers;
    },
    items() {
      return this.tableMap(
        this.payments.map(v => ({
          ...v,
          rowClass: v.errors ? "error white--text" : null
        })),
        this.table.headers
      );
    },
    sort: {
      get() {
        return this.table.sort;
      },
      set(value) {
        this.table.sort = value;
      }
    },
    options: {
      get() {
        return this.table.options;
      },
      set(value) {
        this.table.options = value;
      }
    },
    sortFilterMap() {
      return this.table.sortFilterMap;
    }
  }
};
</script>
