<template>
  <v-app-bar
    v-if="$vuetify.breakpoint.lgAndUp"
    key="lg-menu-items"
    v-show="!fullscreen"
    class="d-print-none"
    flat
    color="background"
  >
    <v-btn
      v-if="hasQuoteAndApplyLink"
      depressed
      :class="{ 'header-btn-color-light': !$vuetify.theme.dark }"
      :href="quoteAndApplyLink"
      target="_blank"
      color="primary"
      rounded
      class="text-none launch-button mr-1"
      :id="quoteAndApplyTourId"
    >
      <div class="animated-launch-button"></div>
      <quote-and-apply-icon class="mr-1" />
      <template v-if="hasEApps"> Launch Quote & Apply </template>
      <template v-else> Start Your First Quote & Apply Application </template>
    </v-btn>
    <v-btn
      v-else-if="hasNpn"
      depressed
      :class="{ 'header-btn-color-light': !$vuetify.theme.dark }"
      @click="openCreateQuoteAndApplySiteDialog"
      color="primary"
      rounded
      class="text-none launch-button mr-1"
    >
      <div class="animated-launch-button"></div>
      <quote-and-apply-icon class="mr-1" />
      <template> Create Quote & Apply Website </template>
    </v-btn>
    <template v-if="multiManagers || schedulingLink">
      <v-btn
        color="accent"
        rounded
        class="text-none"
        depressed
        :href="schedulingLink"
        target="_blank"
        v-if="!multiManagers && schedulingLink"
        id="schedule-a-meeting"
      >
        <v-icon class="mr-1">$mdi-calendar</v-icon>
        {{ schedulingLinkText }}
      </v-btn>
      <template v-else>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              target="_blank"
              class="text-none ml-1"
              color="accent"
              rounded
              depressed
            >
              <v-icon class="mr-1"> $mdi-calendar </v-icon> Schedule a meeting
              <v-icon>$mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="link in schedulingLinks"
              :key="link.text"
              link
              :href="link.url"
              target="_blank"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ link.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </template>
    <v-spacer />
    <v-toolbar-items>
      <sitewide-search-button ref="sitewideSearch" autofocus start-expanded />
      <model-create-menu />
      <notifications-indicator />
      <user-menu />
    </v-toolbar-items>
  </v-app-bar>
  <v-app-bar
    v-else-if="$vuetify.breakpoint.mdOnly"
    key="md-menu-items"
    class="d-print-none"
    v-show="!fullscreen"
    app
    flat
    color="sidebar"
    elevation="2"
  >
    <v-toolbar-items>
      <v-app-bar-nav-icon @click="$emit('toggle-drawer')" />
    </v-toolbar-items>
    <app-logo />
    <v-spacer />
    <v-toolbar-items>
      <sitewide-search-button ref="sitewideSearch" autofocus start-expanded />
      <v-btn
        v-if="hasQuoteAndApplyLink"
        depressed
        class="text-none px-1"
        icon
        :href="quoteAndApplyLink"
        target="_blank"
        color="primary"
        :id="quoteAndApplyTourId"
      >
        <quote-and-apply-icon class="mr-1" />
      </v-btn>
      <v-btn
        v-else-if="hasNpn"
        depressed
        icon
        color="primary"
        class="text-none px-1"
        @click="openCreateQuoteAndApplySiteDialog"
      >
        <quote-and-apply-icon class="mr-1" />
      </v-btn>
      <model-create-menu />
    </v-toolbar-items>
  </v-app-bar>
  <v-app-bar
    v-else
    key="small-menu-items"
    v-show="!fullscreen"
    class="d-print-none"
    app
    flat
    bottom
    color="sidebar"
    elevation="2"
  >
    <v-btn
      text
      :style="{ width: hasQuoteAndApplyLink ? '20%' : '25%' }"
      :to="{ name: 'Home' }"
      exact
    >
      <v-icon>$mdi-home</v-icon>
    </v-btn>
    <v-btn
      v-if="hasQuoteAndApplyLink"
      text
      :style="{ width: hasQuoteAndApplyLink ? '20%' : '25%' }"
      exact
      target="_blank"
      color="primary"
      :href="quoteAndApplyLink"
      :id="quoteAndApplyTourId"
    >
      <quote-and-apply-icon />
    </v-btn>
    <v-btn
      text
      :style="{ width: hasQuoteAndApplyLink ? '20%' : '25%' }"
      @click="showFullPageSearch"
    >
      <v-icon>$mdi-magnify</v-icon>
    </v-btn>
    <model-create-menu
      :width="hasQuoteAndApplyLink ? '20%' : '25%'"
      :color="$vuetify.theme.dark ? 'white' : 'black'"
      text
    />
    <v-btn
      text
      :style="{ width: hasQuoteAndApplyLink ? '20%' : '25%' }"
      @click="$emit('toggle-drawer')"
    >
      <v-icon>$mdi-menu</v-icon>
    </v-btn>
    <v-dialog v-model="showSearch" fullscreen persistent>
      <v-card class="height-full">
        <sitewide-search-button
          :has-quote-and-apply-link="hasQuoteAndApplyLink"
          always-visible
          label=""
          not-dense
          overflow
          ref="search"
          @close="showSearch = false"
        >
          <template #icon>
            <v-btn @click="showSearch = false" icon color="primary">
              <v-icon> $mdi-arrow-left </v-icon>
            </v-btn>
          </template>
        </sitewide-search-button>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import UserMenu from "@/components/UserMenu.vue";
import ModelCreateMenu from "@/components/ModelCreateMenu.vue";

import AppLogo from "@/components/AppLogo.vue";

import SitewideSearchButton from "@/components/shared/SitewideSearchButton.vue";
import NotificationsIndicator from "@/components/NotificationsIndicator.vue";

import QuoteAndApplyIcon from "@/components/shared/QuoteAndApplyIcon.vue";
import { useUserStore } from "@/stores/user";
import { mapActions, mapState } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { useInstanceStore } from "@/stores/instance";
export default {
  components: {
    UserMenu,
    AppLogo,
    ModelCreateMenu,
    SitewideSearchButton,
    NotificationsIndicator,
    QuoteAndApplyIcon
  },
  data() {
    const userStore = useUserStore();

    return {
      currentLogin: userStore.login.id,
      route: null,
      showingSearch: false,
      showSearch: false,
      togglePhone: false,
      quoteAndApplyTourId: "launch-quote-and-apply"
    };
  },
  computed: {
    ...mapState(useInstanceStore, ["fullscreen"]),
    ...mapState(useUserStore, ["logins", "loginable"]),
    hasQuoteAndApplyLink() {
      return Boolean(this.loginable.approved_domain_url);
    },
    quoteAndApplyLink() {
      return this.loginable.approved_domain_url;
    },
    hasEApps() {
      return this.loginable.has_eapps;
    },
    hasNpn() {
      return this.loginable.npn;
    },
    schedulingLinks() {
      return this.loginable?.marketing_manager?.scheduling_links || [];
    },
    schedulingLink() {
      if (!this.schedulingLinks.length) return null;
      return this.schedulingLinks[0].url;
    },
    schedulingLinkText() {
      if (!this.schedulingLinks.length) return null;
      return this.schedulingLinks[0].text;
    },
    multiManagers() {
      if (!this.schedulingLinks) return false;
      return this.schedulingLinks?.length > 1;
    }
  },
  watch: {
    "$route.path"() {
      this.showSearch = false;
    }
  },
  methods: {
    ...mapActions(useDialogStore, ["showDialog"]),
    showFullPageSearch() {
      this.showSearch = true;
      this.$nextTick(() => {
        this.$nextTick(() =>
          this.$refs.search.$refs.searchRef.$refs.input.focus()
        );
      });
    },
    openCreateQuoteAndApplySiteDialog() {
      this.showDialog({
        component: "CreateQuoteAndApplySite"
      });
    }
  }
};
</script>
