<template>
  <v-card>
    <v-card-title>
      <v-row class="ma-0" align="center"> Add Party </v-row>
    </v-card-title>
    <v-card-text>
      <new-party
        ref="newPartyRef"
        :case-id="props.caseId"
        :insured-address="props.insuredAddress"
        :line="props.line"
        :only-type="props.onlyType"
        :hide-role="props.hideRole"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="text-none"
        outlined
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        data-testid="add-party-create"
        class="text-none"
        color="primary"
        :loading="loading"
        @click="createRole"
      >
        Create Role
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import NewParty from "@/components/cases/case-dialogs/NewParty.vue";

import { useDialogStore } from "@/stores/dialog";
import { ref, defineProps } from "vue";

const props = defineProps({
  caseId: {
    type: Number,
    required: false,
    default: null
  },
  insuredAddress: {
    type: Object,
    required: false,
    default: () => null
  },
  line: {
    type: String,
    required: false,
    default: "life"
  },
  onlyType: {
    type: String,
    required: false,
    default: null,
    validates: v => ["Individual", "Entity", null].includes(v)
  },
  hideRole: Boolean
});

const dialog = useDialogStore();

const loading = ref(false);

const newPartyRef = ref(null); //templateref

// refs are arrays in vue 2 within a v-for
async function createRole() {
  if (!newPartyRef.value?.create) return;
  loading.value = true;
  const result = await newPartyRef.value.create();
  if (result) dialog.closeDialog({ party: result });
  loading.value = false;
}
</script>
