<template>
  <v-fade-transition mode="out-in">
    <p
      v-if="resourceAccess && resourceEmailNotifications"
      class="mb-0"
      key="all"
    >
      {{ connectionName }} has access to and receives emails about
      {{ advisorName }}'s {{ resource }}
    </p>
    <p v-else-if="resourceAccess" class="mb-0" key="some">
      {{ connectionName }} has access to {{ advisorName }}'s {{ resource }}
    </p>
    <p v-else class="mb-0" key="none">
      {{ connectionName }} has no access to {{ advisorName }}'s {{ resource }}
    </p>
  </v-fade-transition>
</template>

<script>
export default {
  props: {
    connectionName: {
      type: String,
      required: false,
      default: "The advisor"
    },
    advisorName: {
      type: String,
      required: true
    },
    resourceAccess: Boolean,
    resourceEmailNotifications: Boolean,
    resource: {
      type: String,
      required: true
    }
  }
};
</script>
