import { getHttpClient } from "@/http-client";
import { useInstanceStore } from "@/stores/instance";

const baseUrl = "api/boss/marketing";
export function getMarketingTemplate(title, template_name) {
  const params = new URLSearchParams();
  params.append("title", title);
  params.append("template_name", template_name);
  const instance = useInstanceStore();
  return `${instance.apiUrl}/${baseUrl}/get_template?${params.toString()}`;
}

export async function getMarketingTemplateHtml(title, template_name) {
  const params = new URLSearchParams();
  params.append("title", title);
  params.append("template_name", template_name);
  const { data } = await getHttpClient().get(
    `${baseUrl}/get_template_html?${params.toString()}`
  );
  return data;
}
export function sendMarketingTemplate(email_string, template_name, title) {
  return getHttpClient().post(`${baseUrl}/send_template`, {
    email_string,
    template_name,
    title
  });
}
