<template>
  <v-data-table
    class="transparent-data-table"
    data-testid="advisor-profile-campaigns-table"
    :loading="table.loading.value"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :disable-pagination="true"
    :hide-default-footer="true"
  />
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import { useTable } from "@/composables/table.composable";

import { parseErrorMessage, timestampFormatter } from "@/util/helpers";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { useSnackbarStore } from "@/stores/snackbar";
import { storeToRefs } from "pinia";

const snackbar = useSnackbarStore();
const advisorView = useAdvisorProfileView();

const { campaigns } = storeToRefs(advisorView);
const table = useTable({
  headers: [
    new TableHeader({ text: "Campaign", value: "campaign", map: "campaign" }),
    new TableHeader({
      text: "Recipients",
      value: "recipientCount",
      map: "recipientCount"
    }),
    new TableHeader({ text: "Sent", value: "createdAt", map: "createdAt" })
  ],
  getData: async () => {
    await advisorView.getRecentCampagins();
    return {
      items: campaigns.value.map(item => ({
        ...item,
        createdAt: timestampFormatter(item.createdAt)
      }))
    };
  }
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getData();
</script>
