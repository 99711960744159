import NoteFactory from "@/factories/NoteFactory";
import { ENTITY_INDICATE } from "@/factories/ContractPartyFactory";
import {
  CONTINGENT_BENEFICIARY,
  INSURED,
  JOINT_INSURED,
  PRIMARY_BENEFICIARY
} from "@/factories/RoleFactory";
export function Entity(model = {}) {
  return {
    addresses: model?.addresses || null,
    assets: model?.assets || null,
    avatarUrl: model?.avatarUrl || null,
    bankruptcy: model?.bankruptcy || null,
    bankruptcyDetails: model?.bankruptcyDetails || null,
    electronicApplicationId: model?.electronicApplicationId || null,
    email: model?.email || null,
    formationDate: model?.formationDate || null,
    id: model?.id || null,
    income: model?.income || null,
    irrevocable: model?.irrevocable || null,
    liabilities: model?.liabilities || null,
    name: model?.name || null,
    networth: model?.networth || null,
    notes: model?.notes || null,
    phoneWork: model?.phoneWork || null,
    responsibleIndividual: model?.responsibleIndividual || null,
    taxType: model?.taxType || null,
    tin: model?.tin || null,
    role: model?.role || null
  };
}

export function NewCaseEntity(model = {}) {
  return {
    name: model?.name || null,
    tin: model?.tin || null,
    email: model?.email || null,
    date: model?.date || null,
    roles: model?.roles || [],
    beneficiaryAmount: model?.beneficiaryAmount || null,
    relationship: model?.relationship || null,
    irrevocable: model?.irrevocable || null
  };
}

export function setEntityFromRequest(model = {}) {
  const entity = Entity();
  entity.addresses = model?.addresses;
  entity.assets = model?.assets;
  entity.avatarUrl = model?.avatar_url;
  entity.bankruptcy = model?.bankruptcy;
  entity.bankruptcyDetails = model?.bankruptcy_details;
  entity.electronicApplicationId = model?.electronic_application_id;
  entity.email = model?.email;
  entity.formationDate = model?.formation_date;
  entity.id = model?.id;
  entity.income = model?.income;
  entity.irrevocable = model?.irrevocable;
  entity.liabilities = model?.liabilities;
  entity.name = model?.name;
  entity.networth = model?.networth;
  entity.phoneWork = model?.phone_work;
  entity.responsibleIndividual = model?.responsible_individual;
  entity.taxType = model?.tax_type;
  entity.tin = model?.tin;

  entity.notes = [];
  model?.notes?.forEach(rawNote => {
    const note = new NoteFactory();
    note.setFromRequest(rawNote);
    entity.notes.push(note);
  });
  return entity;
}

export function EntityToCreateRequest(entity) {
  return {
    birthdate: entity.date,
    email: entity.email,
    irrevocable: entity.irrevocable ? "1" : "0",
    name: entity.name,
    natural_non_natural_indicate: ENTITY_INDICATE,
    party_id: entity.tin,
    roles_attributes: entity.roles.map(role => {
      const attributes = {
        role
      };

      if ([PRIMARY_BENEFICIARY, CONTINGENT_BENEFICIARY].includes(role)) {
        attributes.beneficiary_amount = +(
          entity.beneficiaryAmount / 100
        ).toFixed(2);
        attributes.beneficiary_qualifier = "Percent";
      }
      if (![INSURED, JOINT_INSURED].includes(role)) {
        attributes.relationship = entity.relationship;
      }

      return attributes;
    })
  };
}
