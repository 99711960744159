<template>
  <v-expansion-panels>
    <v-expansion-panel
      v-for="{ title, component, href, class: itemClass } in items"
      :key="title"
      :readonly="Boolean(href)"
    >
      <v-expansion-panel-header v-if="href" hide-actions class="pa-0">
        <a :href="href" target="_blank" class="pa-3 pl-6">
          {{ title }}
          <v-icon small color="primary" style="margin-bottom: 1px">
            $mdi-open-in-new
          </v-icon>
        </a>
      </v-expansion-panel-header>
      <template v-else>
        <v-expansion-panel-header> {{ title }}</v-expansion-panel-header>
        <v-expansion-panel-content class="no-expansion-panel-content-padding">
          <component :is="component" :class="itemClass" />
        </v-expansion-panel-content>
      </template>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import Builds from "@/components/underwriting/Builds.vue";
import BuildChartsTable from "@/components/underwriting/BuildChartsTable.vue";
import ImpairedRiskQuotesTable from "@/components/impaired-risk-quote/ImpairedRiskQuotesTable.vue";
import QuestionnairesTable from "@/components/underwriting/QuestionnairesTable.vue";
import SmokingTypesTable from "@/components/underwriting/SmokingTypesTable.vue";
import SmokerChartsTable from "@/components/underwriting/SmokerChartsTable.vue";

import { useUserStore } from "@/stores/user";
import { useHead } from "@unhead/vue";
export default {
  name: "UnderwritingView",
  setup() {
    useHead({ title: "Underwriting" });
  },
  components: {
    SmokingTypesTable,
    SmokerChartsTable,
    QuestionnairesTable,
    ImpairedRiskQuotesTable,
    Builds,
    BuildChartsTable
  },
  data() {
    const user = useUserStore();
    const isCaseManager = user.loginable.is_case_manager;
    const isGroupThreePlus = user.isGroupThreePlus;

    const items = [
      {
        title: "Carrier Underwriting Requirements",
        href: "https://prodinfo.ipipeline.com/search?GAID=2479"
      },
      {
        title: "Nicotine & Cannabis",
        component: "SmokingTypesTable"
      },
      {
        title: "Height & Weight",
        component: "Builds"
      }
    ];

    if (isGroupThreePlus) {
      items.push(
        {
          title: "Smoker Charts",
          component: "SmokerChartsTable",
          class: "pa-3"
        },
        {
          title: "Build Charts",
          component: "BuildChartsTable"
        }
      );
    }

    if (isCaseManager) {
      items.push(
        {
          title: "Impaired Risk Questionnaires",
          component: "QuestionnairesTable"
        },
        {
          title: "Impaired Risk Quotes",
          component: "ImpairedRiskQuotesTable",
          class: "pa-3"
        }
      );
    }
    items.sort((a, b) => a.title.localeCompare(b.title));
    return {
      items
    };
  }
};
</script>
