<template>
  <span>{{ formattedValue }}</span>
</template>

<script setup>
import { parsedDaysTill, parsedHoursTill } from "@/util/helpers";
import { computed, defineProps } from "vue";

const props = defineProps({
  value: {
    type: [String, Number],
    default: null
  },
  format: {
    type: String,
    default: "days",
    required: false
  }
});

const formatDict = {
  days: parsedDaysTill,
  hours: parsedHoursTill
};

const formattedValue = computed(() => {
  const format = formatDict[props.format];
  return format(props.value);
});
</script>
