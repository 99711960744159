import { getRouterLink } from "@/util/helpers";

function RecentAdvisor(model = {}) {
  return {
    id: model?.id || 0,
    lastSubmitted: model?.lastSubmitted || "",
    name: model?.name || "",
    pending: model?.pending || 0,
    type: model?.type || "",
    yearToDate: model?.yearToDate || 0,
    routerLink: model?.routerLink || null
  };
}

export function setRecentAdvisorFromRequest(raw = {}) {
  const advisor = RecentAdvisor();

  advisor.id = raw?.id;
  advisor.lastSubmitted = raw?.last_submitted;
  advisor.name = raw?.name;
  advisor.pending = raw?.pending;
  advisor.type = raw?.type?.model;
  advisor.yearToDate = raw?.year_to_date;
  advisor.routerLink = getRouterLink(advisor.type, advisor.id);

  return advisor;
}

function RecentAppointment(model = {}) {
  return {
    carrierId: model?.carrierId || 0,
    carrierName: model?.carrierName || "",
    updatedAt: model?.updatedAt || "",
    effectiveDate: model?.effectiveDate || "",
    id: model?.id || 0,
    name: model?.name || "",
    status: model?.status || "",
    terminatedDate: model?.terminatedDate || "",
    routerLink: model?.routerLink || null
  };
}

export function setRecentAppointmentFromRequest(raw = {}) {
  const appointment = RecentAppointment();

  appointment.carrierId = raw?.carrier?.id;
  appointment.carrierName = raw?.carrier?.name;
  appointment.updatedAt = raw?.updated_at;
  appointment.effectiveDate = raw?.effective_date;
  appointment.id = raw?.id;
  appointment.name = raw?.name;
  appointment.status = raw?.status;
  appointment.terminatedDate = raw?.terminated_date;
  appointment.routerLink = getRouterLink("Appointment", appointment.id);

  return appointment;
}

function RecentCampaign(model = {}) {
  return {
    campaign: model?.campaign || "",
    createdAt: model?.createdAt || "",
    recipientCount: model?.recipientCount || 0,
    recipients: model?.recipients || ""
  };
}

export function setRecentCampaignFromRequest(raw = {}) {
  const campaign = RecentCampaign();

  campaign.campaign = raw?.campaign;
  campaign.createdAt = raw?.created_at;
  campaign.recipientCount = raw?.recipient_count;
  campaign.recipients = raw?.recipients;

  return campaign;
}

function RecentCase(model = {}) {
  return {
    carrierId: model?.carrierId || 0,
    carrierName: model?.carrierName || "",
    insuredId: model?.insuredId || 0,
    insuredName: model?.insuredName || "",
    createdAt: model?.createdAt || "",
    id: model?.id || "",
    policyNumber: model?.policyNumber || "",
    productionCreditTotal: model?.productionCreditTotal || 0,
    status: model?.status || "",
    routerLink: model?.routerLink || null
  };
}

export function setRecentCaseFromRequest(raw = {}) {
  const recentCase = RecentCase();
  recentCase.carrierId = raw?.carrier?.id;
  recentCase.carrierName = raw?.carrier?.name;
  recentCase.insuredId = raw?.insured?.id;
  recentCase.insuredName = raw?.insured?.name;
  recentCase.createdAt = raw?.created_at;
  recentCase.id = raw?.id;
  recentCase.policyNumber = raw?.policy_number;
  recentCase.productionCreditTotal = raw?.production_credit_total;
  recentCase.status = raw?.status;
  recentCase.routerLink = getRouterLink("Case", recentCase.id);
  return recentCase;
}

function RecentContractParty(model = {}) {
  return {
    birthdate: model?.birthdate || "",
    email: model?.email || "",
    id: model?.id || 0,
    name: model?.name || "",
    primaryPhone: model?.primaryPhone || "",
    type: model?.type || "",
    routerLink: model?.routerLink || null
  };
}

export function setRecentContractPartyFromRequest(raw = {}) {
  const contractParty = RecentContractParty();
  contractParty.birthdate = raw?.birthdate;
  contractParty.email = raw?.email;
  contractParty.id = raw?.id;
  contractParty.name = raw?.name;
  contractParty.primaryPhone = raw?.primary_phone;
  contractParty.type = raw?.type;
  contractParty.routerLink = getRouterLink("ContractParty", contractParty.id);
  return contractParty;
}

export function RecentElectronicApplication(model = {}) {
  return {
    updated: model?.updated || "",
    editedStatus: model?.editedStatus || "",
    benefitAmount: model?.benefitAmount || 0,
    id: model?.id || 0,
    name: model?.name || "",
    productId: model.productId || 0,
    productName: model?.productName || "",
    carrierId: model?.carrierId || 0,
    carrierName: model?.carrierName || "",
    status: model?.status || "",
    routerLink: model?.routerLink || null
  };
}

export function setRecentElectronicApplicationFromRequest(raw = {}) {
  const eapp = RecentElectronicApplication();
  eapp.updated = raw?.updated_at;
  eapp.editedStatus = raw?.edited_status;
  eapp.benefitAmount = raw?.benefit_amount;
  eapp.id = raw?.id;
  eapp.name = raw?.name;
  eapp.productId = raw?.product?.id;
  eapp.productName = raw?.product?.name;
  eapp.carrierId = raw?.product?.carrier?.id;
  eapp.carrierName = raw?.product?.carrier?.name;
  eapp.status = raw?.status;
  eapp.routerLink = getRouterLink("ElectronicApplication", eapp.id);
  return eapp;
}

function RecentPayment(model = {}) {
  return {
    insuredName: model?.insured || "",
    payorName: model?.payor || null,
    premium: model?.premium || 0,
    dollar: model?.dollar || 0,
    createdDate: model?.createdDate || null
  };
}

export function setRecentPaymentFromRequest(raw = {}) {
  const payment = RecentPayment();
  payment.insuredName = raw?.commission?.appointment_case?.insured?.name;
  payment.payorName = raw?.commission?.payor?.name;
  payment.premium = raw?.premium;
  payment.dollar = raw?.dollar;
  payment.createdDate = raw?.created_date;
  return payment;
}

function RecentPayPeriod(model = {}) {
  return {
    paidDate: model?.paidDate || null,
    id: model?.id || null,
    paidAmount: model?.paidAmount || null,
    payingTo: model?.payingTo || null,
    routerLink: model?.routerLink || null
  };
}

export function setRecentPayPeriodFromRequest(raw = {}) {
  const payPeriod = RecentPayPeriod();
  payPeriod.paidDate = raw?.paid_date;
  payPeriod.id = raw?.id;
  payPeriod.paidAmount = raw?.paid_amount;
  payPeriod.payingTo = raw?.paying_to;
  payPeriod.routerLink = getRouterLink("PayPeriod", payPeriod.id);
  return payPeriod;
}

function RecentQuote(model = {}) {
  return {
    createdAt: model?.createdAt || "",
    id: model?.id || 0,
    line: model?.line || "",
    lineText: model?.lineText || "",
    name: model?.name || "",
    status: model?.status || "",
    routerLink: model?.routerLink || null
  };
}

export function setRecentQuoteFromRequest(raw = {}) {
  const quote = RecentQuote();
  quote.createdAt = raw?.created_at;
  quote.id = raw?.id;
  quote.line = raw?.line;
  quote.lineText = raw?.line_text;
  quote.name = raw?.name;
  quote.status = raw?.status;
  quote.routerLink = getRouterLink("Quote", quote.id);
  return quote;
}
