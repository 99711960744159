<template>
  <v-card tile flat color="section">
    <v-card-title>Metadata</v-card-title>
    <v-card-text>
      <pre> {{ parsedMetadata }}</pre>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "pinia";
import { useEappViewStore } from "@/stores/eapp-view";
export default {
  computed: {
    ...mapState(useEappViewStore, ["metadata"]),
    parsedMetadata() {
      try {
        return JSON.parse(this.metadata, null, 2);
      } catch (e) {
        return this.metadata;
      }
    }
  }
};
</script>
