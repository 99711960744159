<template>
  <v-row dense>
    <v-col cols="12" v-if="!disabled">
      <v-row class="pa-3">
        <v-select
          v-model="newImpairmentType"
          prepend-inner-icon="$mdi-stethoscope"
          label="New Impairment Type"
          outlined
          dense
          data-testid="new-impairment-type"
          :items="availableUnderwritingRationale"
        />
        <v-btn
          class="text-none ml-2"
          color="accent"
          data-testid="add-impairment"
          @click="createImpairment"
        >
          <v-icon>$mdi-plus</v-icon> Add
        </v-btn>
      </v-row>
    </v-col>

    <v-col
      v-for="(value, key, index) in underwritingRationale"
      cols="12"
      :key="key"
      :data-testid="`rationale-${key}`"
    >
      <v-textarea
        v-model="underwritingRationale[key]"
        class="has-append-outer-button"
        outlined
        dense
        data-testid="rationale-text"
        :label="`${index + 1}. ${key} Impairment`"
        :disabled="disabled"
        :success="Boolean(value)"
        @input="updateRationaleValue(key)"
      >
        <template #append-outer v-if="!disabled">
          <active-save-indicator
            :controller="savingBuffer[key].controller.value"
            margin-top
          />
          <v-btn
            icon
            color="error"
            size="24"
            data-testid="remove-rationale"
            @click="removeRationale(key)"
          >
            <v-icon>$mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-textarea>
    </v-col>
    <p v-if="emptyUnderwritingRationale" class="px-1">
      No underwriting rationale have been entered.
    </p>
  </v-row>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { IMPAIRMENT_CONCERNS } from "@/factories/ImpairedRiskQuote";
import { computed, ref, defineProps, defineEmits, del, set } from "vue";
import { useActiveSave } from "@/composables/active-save.composable";

const props = defineProps({
  value: { type: Object, required: false, default: () => null },
  func: { type: Function, required: false, default: null },
  disabled: Boolean
});

const emit = defineEmits(["input"]);

const underwritingRationale = ref(props.value || {});
const newImpairmentType = ref(undefined);

const savingBuffer = IMPAIRMENT_CONCERNS.reduce((acc, curr) => {
  acc[curr] = useActiveSave();
  return acc;
}, {});
savingBuffer.root = useActiveSave();

const emptyUnderwritingRationale = computed(
  () => !Object.keys(underwritingRationale.value).length
);

const availableUnderwritingRationale = computed(() =>
  IMPAIRMENT_CONCERNS.filter(val => !(val in underwritingRationale.value))
);

function createImpairment() {
  if (!newImpairmentType.value) return;
  set(underwritingRationale.value, newImpairmentType.value, "");
  newImpairmentType.value = undefined;
}

function removeRationale(rationale) {
  del(underwritingRationale.value, rationale);
  updateRationaleValue("root");
}

function updateRationaleValue(attribute) {
  emit("input", underwritingRationale.value);
  if (!props.func || props.disabled) return;
  savingBuffer[attribute].controller.value.needsUpdate = true;
  updateAttributes();
}

let timer;
function updateAttributes() {
  if (timer) clearTimeout(timer);
  timer = setTimeout(() => {
    const attributesToUpdate = Object.keys(savingBuffer).filter(
      k => savingBuffer[k].controller.value.needsUpdate
    );

    const promise = props.func();

    attributesToUpdate.forEach(attr =>
      savingBuffer[attr].attachToPromise(promise)
    );
  }, 500);
}
</script>
