export default function AddressFactory(model = {}) {
  return {
    street_address: model?.street_address || null,
    city: model?.city || null,
    zip: model?.zip || null,
    state: model?.state || null,
    id: model?.id || null,
    startDate: model?.startDate || null,
    endDate: model?.endDate || null,
    addressType: model?.addressType || null,
    primary: Boolean(model?.primary) || false,
    setFromRequest(model) {
      this.street_address = model?.street_address;
      this.city = model?.city;
      this.zip = model?.zip;
      this.state = model?.state;
      this.id = model?.id;
      this.startDate = model?.start_date;
      this.endDate = model?.end_date;
      this.addressType = model?.address_type;
      this.primary = Boolean(model?.primary);
    },
    generateAttributeMap() {
      return {
        street_address: { street_address: this.street_address },
        city: { city: this.city },
        zip: { zip: this.zip },
        state: { state: this.state },
        id: { id: this.id },
        startDate: { start_date: this.startDate },
        endDate: { end_date: this.endDate },
        addressType: { address_type: this.addressType },
        primary: { primary: Boolean(this.primary) }
      };
    },
    toUpdateRequest() {
      const attributeMap = this.generateAttributeMap();

      return {
        ...attributeMap.startDate,
        ...attributeMap.endDate,
        ...attributeMap.street_address,
        ...attributeMap.city,
        ...attributeMap.state,
        ...attributeMap.id,
        ...attributeMap.zip,
        ...attributeMap.addressType,
        ...attributeMap.primary
      };
    },
    toCreateRequest() {
      const attributeMap = this.generateAttributeMap();

      return {
        ...attributeMap.startDate,
        ...attributeMap.endDate,
        ...attributeMap.street_address,
        ...attributeMap.city,
        ...attributeMap.state,
        ...attributeMap.zip,
        ...attributeMap.addressType,
        ...attributeMap.primary,
        ...attributeMap.addressType
      };
    },
    toDisplayString() {
      return [this.street_address, this.city, this.state, this.zip]
        .filter(Boolean)
        .join(", ");
    },
    toBasicRequest() {
      return {
        street_address: this.street_address,
        city: this.city,
        state: this.state,
        zip: this.zip
      };
    }
  };
}

export function Address(model = {}) {
  return {
    street_address: model?.street_address || null,
    city: model?.city || null,
    zip: model?.zip || null,
    state: model?.state || null,
    id: model?.id || null,
    startDate: model?.startDate || null,
    endDate: model?.endDate || null,
    addressType: model?.addressType || null,
    primary: Boolean(model?.primary) || false
  };
}

export function setAddressFromRaw(raw) {
  const address = Address();

  address.street_address = raw?.street_address;
  address.city = raw?.city;
  address.zip = raw?.zip;
  address.state = raw?.state;
  address.id = raw?.id;
  address.startDate = raw?.start_date;
  address.endDate = raw?.end_date;
  address.addressType = raw?.address_type;
  address.primary = Boolean(raw?.primary);

  return address;
}

export function setRawFromAddress(address) {
  return {
    street_address: address.street_address,
    city: address.city,
    zip: address.zip,
    state: address.state,
    id: address.id,
    start_date: address.startDate,
    end_date: address.endDate,
    address_type: address.addressType,
    primary: Boolean(address.primary)
  };
}

export function formatAddress(address) {
  return [address?.street_address, address?.city, address?.state, address?.zip]
    .filter(Boolean)
    .join(", ");
}
