<template>
  <v-card flat tile color="section">
    <v-card-title> Marketing </v-card-title>
    <v-card-subtitle>
      Add an email signature or fast access via QR Code.
    </v-card-subtitle>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="4">
          <v-text-field
            data-lpignore="true"
            v-model="buttonText"
            prepend-inner-icon="$mdi-label"
            outlined
            success
            label="Button Text"
            data-testid="marketing-button-text"
            dense
            hint="The text that will appear on the button"
            persistent-hint
          />
        </v-col>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="buttonExplanationText"
            data-lpignore="true"
            prepend-inner-icon="$mdi-web"
            outlined
            success
            persistent-hint
            hint="Modify the explanation under the button. Use [ ] to wrap the text you want to be a link"
            clearable
            label="Button Explanation Text"
            data-testid="marketing-button-explanations"
            dense
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            data-lpignore="true"
            v-model="buttonLink"
            prepend-inner-icon="$mdi-web"
            outlined
            success
            label="Button Link"
            data-testid="marketing-button-link"
            dense
            persistent-hint
            hint="The link that both the button and the explanation text link will take you to"
          />
        </v-col>

        <v-col lg="6" cols="12">
          <color-picker-input
            v-model="accentColor"
            label="Primary Color"
            data-testid="color-picker-fill-accent"
            hint="Adjust the primary of the button"
          />
        </v-col>

        <v-col lg="6" cols="12">
          <color-picker-input
            v-model="primaryColor"
            label="Accent Color"
            data-testid="color-picker-fill-primary"
            hint="Adjust the accent color of the button"
          />
        </v-col>

        <v-col lg="6" cols="12">
          <color-picker-input
            v-model="innerButtonTextColor"
            label="Inner Button Text Color"
            data-testid="color-picker-fill-primary"
            hint="Adjust the text color within the button"
            persistent-hint
          />
        </v-col>
        <v-col lg="6" cols="12">
          <color-picker-input
            v-model="outerButtonTextColor"
            label="Outer Button Text Color"
            data-testid="color-picker-fill-primary"
            hint="Adjust the text color outside of the button"
            persistent-hint
          />
        </v-col>
        <v-col lg="6" cols="12">
          <v-slider
            v-model.number="borderRadius"
            tick="1"
            min="0"
            max="40"
            label="Edge Roundness"
            data-testid="border-radius"
            persistent-hint
            dense
            hint="Alter the roundness of the Regular and Gradient buttons"
            outlined
            success
          />
        </v-col>
        <v-col cols="12" lg="6">
          <v-slider
            v-model.number="scale"
            :tick="1"
            :min="100"
            :max="150"
            label="Scale"
            data-testid="scale"
            dense
            outlined
            success
            persistent-hint
            hint="Alter the propotional scale of styles"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
          md="6"
          v-for="style in copyableButtons"
          :key="style.title"
        >
          <v-card
            :data-testid="`marketing-button-style-${style.title}`"
            flat
            @click="copyHtml(style.id)"
          >
            <v-card-title> {{ style.title }} </v-card-title>
            <v-card-subtitle> {{ style.subtitle }} </v-card-subtitle>
            <v-card-text>
              <v-row class="ma-0 height-full" justify="center" align="center">
                <component
                  :is="style.component"
                  :canvas-parent-id="style.id"
                  :font-size="fontSize"
                  :scale="scale / 100"
                  :button-text="buttonText"
                  :button-link="buttonLink"
                  :primary-color="primaryColor"
                  :accent-color="accentColor"
                  :inner-button-text-color="innerButtonTextColor"
                  :outer-button-text-color="outerButtonTextColor"
                  :border-radius="borderRadius"
                  v-bind="style.componentProps"
                >
                  <template v-if="buttonExplanationText" #explanation-text>
                    <approved-domain-marketing-explanation-text
                      :id="`${style.id}-text`"
                      :button-link="buttonLink"
                      :button-explanation-text="buttonExplanationText"
                      :font-size="fontSize"
                      :scale="scale"
                    />
                  </template>
                </component>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn text class="text-none grey--text">
                <v-icon> $mdi-content-copy </v-icon> Click to Copy
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ApprovedDomainMarketingButton from "@/components/approved-domain/approved-domain-marketing/ApprovedDomainMarketingButton.vue";
import ApprovedDomainMarketingQrCode from "@/components/approved-domain/approved-domain-marketing/ApprovedDomainMarketingQrCode.vue";
import ApprovedDomainMarketingSlogan from "@/components/approved-domain/approved-domain-marketing/ApprovedDomainMarketingSlogan.vue";
import ApprovedDomainMarketingExplanationText from "@/components/approved-domain/approved-domain-marketing/ApprovedDomainMarketingExplanationText.vue";
import ColorPickerInput from "@/components/shared/ColorPickerInput.vue";

import { useSnackbarStore } from "@/stores/snackbar";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { storeToRefs } from "pinia";
import { markRaw, ref, watch } from "vue";

const snackbar = useSnackbarStore();
const { embedLocation, domain, colors } = storeToRefs(useApprovedDomainView());

const fontSize = 12;
const buttonText = ref("Life Covered");
const buttonLink = ref(`https://${embedLocation.value || domain.value}`);
const scale = ref(1);
const primaryColor = ref(colors.value.light.primary);
const accentColor = ref(colors.value.light.accent);
const borderRadius = ref(10);
const innerButtonTextColor = ref("#FFFFFF");
const outerButtonTextColor = ref("#000000");
const buttonExplanationText = ref(
  "Looking for Life Insurance? [Click] for a quote then apply in less than 10 minutes!"
);

const regularButton = {
  id: "regular",
  title: "Regular",
  subtitle:
    "Add some extra color to your email signature. Can guide eyes easily",
  component: markRaw(ApprovedDomainMarketingButton),
  componentProps: {}
};
const gradientButton = {
  id: "gradient",
  title: "Gradient",
  subtitle:
    "A standard button with a splash of variety, guaranteed to attract attention.",
  component: markRaw(ApprovedDomainMarketingButton),
  componentProps: {
    gradient: true
  }
};

const qr = {
  id: "qrcode",
  title: "QR Code",
  subtitle: "Use this QR Code to easily share your Quote & Apply.",
  component: markRaw(ApprovedDomainMarketingQrCode),
  componentProps: {}
};

const slogan = {
  id: "slogan",
  title: "Slogan",
  subtitle: "Use this simple call to action in your email signature.",
  component: markRaw(ApprovedDomainMarketingSlogan)
};

const copyableButtons = [slogan, regularButton, gradientButton, qr];

watch(colors, () => {
  primaryColor.value = colors.value.light.primary;
  accentColor.value = colors.value.light.accent;
});

function copyHtml(parentId) {
  const id = `${parentId}-canvas`;

  const canvas = document.getElementById(id);
  if (!canvas) return;

  const sigBox = document.createElement("div");
  sigBox.appendChild(canvasToEl(canvas));

  if (buttonExplanationText.value) {
    const textId = `${parentId}-text`;
    const text = document.getElementById(textId);
    if (!text) return;
    sigBox.appendChild(text.cloneNode(true));
  }

  document.body.appendChild(sigBox);

  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(sigBox);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(sigBox);
    selection.removeAllRanges();
    selection.addRange(range);
  }
  document.execCommand("Copy");
  document.body.removeChild(sigBox);
  snackbar.showSuccessSnackbar({
    message: "Copied image to your clipboard"
  });
}

function canvasToEl(canvas) {
  const img = document.createElement("img");
  img.src = canvas.toDataURL("image/png");

  const a = document.createElement("a");
  a.href = buttonLink.value;
  a.target = "_blank";
  a.contentEditable = true;
  a.appendChild(img);
  return a;
}
</script>

<style scoped>
#qrcode-canvas {
  width: 148px !important;
  height: 148px !important;
}
</style>
