import { getHttpClient } from "@/http-client";

import HomeStatisticFactory from "@/factories/home/HomeStatisticFactory";
import HomeGoalFactory from "@/factories/home/HomeGoalFactory";
import HomeRecentAdvisorFactory from "@/factories/home/HomeRecentAdvisorFactory";
import HomeRecentCaseFactory from "@/factories/home/HomeRecentCaseFactory";
import HomeRecentEAppFactory from "@/factories/home/HomeRecentEAppFactory";
import HomeRecentRequirementFactory from "@/factories/home/HomeRecentRequirementFactory";
import { useUserStore } from "@/stores/user";

const baseURL = "api/boss/dashboard";

export const getAdvisorCards = async () => {
  const { data } = await getHttpClient().get(`${baseURL}/cards`);
  const pending = new HomeStatisticFactory();
  pending.setFromRequest({
    type: "pending_cases",
    value: data?.pending_cases
  });
  const downline = new HomeStatisticFactory();
  downline.setFromRequest({
    type: "downline_lifetime_active_cases",
    value: data?.downline_lifetime_active_cases
  });
  const placement = new HomeStatisticFactory();
  placement.setFromRequest({
    type: "placement_ratio",
    value: data?.placement_ratio
  });
  const productionCredit = new HomeStatisticFactory();
  productionCredit.setFromRequest({
    type: "pending_production_credit",
    value: data?.pending_production_credit
  });
  return [pending, downline, placement, productionCredit];
};

export const getAdvisorGoals = async () => {
  const { data } = await getHttpClient().get(`${baseURL}/goals`);
  const user = useUserStore();
  const goals = [
    new HomeGoalFactory({
      type: "complete_walkthrough",
      completed: user.tutorials.series_walkthrough
    }),
    new HomeGoalFactory({
      type: "start_a_quote_and_apply_eapp",
      completed: data?.start_a_quote_and_apply_eapp
    }),
    new HomeGoalFactory({
      type: "complete_contracting",
      completed: data?.complete_contracting
    }),
    new HomeGoalFactory({
      type: "submit_a_case",
      completed: data?.submit_a_case
    }),
    new HomeGoalFactory({
      type: "earn_a_commission",
      completed: data?.earn_a_commission
    }),
    new HomeGoalFactory({
      type: "request_a_quote",
      completed: data?.request_a_quote
    }),
    new HomeGoalFactory({
      type: "create_an_impaired_risk_quote",
      completed: data?.create_an_impaired_risk_quote
    })
  ];

  return goals;
};

export const getAdvisorRecents = async () => {
  const { data } = await getHttpClient().get(`${baseURL}/recents`);
  const cases =
    data?.cases
      ?.map(rawCase => {
        const recentCase = new HomeRecentCaseFactory();
        recentCase.setFromRequest(rawCase);
        return recentCase;
      })
      ?.slice(0, 5) || [];

  const advisors =
    data?.advisors
      ?.map(rawAdvisor => {
        const advisor = new HomeRecentAdvisorFactory();
        advisor.setFromRequest(rawAdvisor);
        return advisor;
      })
      ?.slice(0, 5) || [];

  const eApps =
    data?.electronic_applications
      ?.map(rawEApp => {
        const eApp = new HomeRecentEAppFactory();
        eApp.setFromRequest(rawEApp);
        return eApp;
      })
      ?.slice(0, 5) || [];

  const tasks =
    data?.requirements
      ?.map(rawRequirement => {
        const requirement = new HomeRecentRequirementFactory();
        requirement.setFromRequest(rawRequirement);
        return requirement;
      })
      ?.slice(0, 5) || [];

  return { cases, advisors, eApps, tasks };
};
