<template>
  <v-card flat color="section">
    <v-card-title> Commission Hierarchy </v-card-title>
    <v-card-subtitle>
      Commission Hierarchy applies only to commissions above Street Level and
      does not impact where an advisor's commissions are assigned to.
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12" lg="6" order="1">
          <v-row class="pa-3 mb-3">
            <h3>In Hierarchy</h3>
            <active-save-indicator
              :controller="hierarchySavingBuffer.controller.value"
            />
          </v-row>
          <p class="mb-0">
            Advisors in the "In Hierarchy" section receive the difference
            between their commission rate and the preceding advisor's commission
            rate. Highest commissions start with hierarchy position 1, lowest is
            closest to the agent.
          </p>
        </v-col>
        <v-col cols="12" lg="6" order-lg="2" order="3">
          <v-row class="pa-3 mb-3">
            <h3>Not in Hierarchy</h3>
          </v-row>
          <p class="mb-0">
            Advisors in the "Not in Hierarchy" section do not receive override
            commissions from {{ store.name }}'s businesses. If they should be
            receiving commissions, drag them to the "In Hierarchy" section.
          </p>
          <p class="mb-0">
            <strong>
              Not seeing your Advisor? Advisors in your hierarchy must have
              access.
              <a @click="emit('go-to-admin')">
                Give your desired advisor access.
              </a>
            </strong>
          </p>
        </v-col>
        <v-col cols="12" lg="6" order="2" order-lg="3">
          <v-card class="pa-3" flat>
            <draggable
              v-model="inHierarchy"
              group="hierarchy"
              draggable=".item"
              :disabled="props.readonly"
              :style="{ minHeight: inHierarchy.length ? null : '5rem' }"
            >
              <div
                v-if="!inHierarchy.length"
                style="padding-top: 1.5rem; text-align: center"
              >
                Drag an advisor from "Not in Hierarchy" to get started
              </div>
              <template v-else>
                <v-list-item
                  v-for="(connection, index) in inHierarchy"
                  :key="connection.id"
                  class="draggable my-1 hierarchy-item item"
                >
                  <v-list-item-icon>
                    <v-icon>$mdi-drag</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ index + 1 }}. {{ connection.connectedAdvisorName }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </draggable>
            <v-list-item
              v-if="inHierarchy.length"
              class="my-1 advisor-hierarchy-item immovable"
            >
              <v-list-item-icon>
                <v-icon class="white--text"> $mdi-account </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text">
                  {{ store.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6" order="4">
          <v-card class="pa-3" flat>
            <draggable
              group="hierarchy"
              :sort="false"
              :value="notInHierarchy"
              :disabled="props.readonly"
              :style="{ minHeight: notInHierarchy.length ? null : '5rem' }"
            >
              <div
                v-if="!notInHierarchy.length"
                style="padding-top: 1.5rem; text-align: center"
              >
                All advisors are in the hierarchy!
              </div>
              <template v-else>
                <v-list-item
                  v-for="connection in notInHierarchy"
                  :key="connection.id"
                  class="draggable my-1 not-in-hierarchy-item"
                >
                  <v-list-item-icon>
                    <v-icon>$mdi-drag</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ connection.connectedAdvisorName }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </draggable>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
//
// Advisor Hierarchy works so that the bottom advisor has hierarchyOrder of 1
// e.g.
//
// 1. Test Agent        => hierarchy_order = 3
// 2. Test Other Agent  => hierarchy_order = 2
// 3. Test PNC Agent    => hierarchy_order = 1
// Current Advisor
import draggable from "vuedraggable";

import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { useActiveSave } from "@/composables/active-save.composable";
import { computed, defineProps, defineEmits } from "vue";

const hierarchySavingBuffer = useActiveSave();
const props = defineProps({
  readonly: Boolean
});

const emit = defineEmits(["go-to-admin"]);

const store = useAgentSettingsStore();

const inHierarchyConnections = computed(() => {
  return Object.values(store.connections).filter(val => val.hierarchy);
});
const notInHierarchyConnections = computed(() => {
  return Object.values(store.connections).filter(val => !val.hierarchy);
});
const inHierarchy = computed({
  get() {
    const connections = [...inHierarchyConnections.value];
    connections.sort((a, b) => (a.hierarchyOrder - b.hierarchyOrder) * -1);
    return connections;
  },
  set(val) {
    store.setHierarchy(val.reverse());
    store.updateHierarchy();
  }
});
const notInHierarchy = computed(() => {
  const connections = [...notInHierarchyConnections.value];
  connections.sort((a, b) =>
    a.connectedAdvisorName.localeCompare(b.connectedAdvisorName)
  );
  return connections;
});
</script>
