<template>
  <div>
    <span class="grey--text lighten-2"> {{ props.title }} </span>
    <br />

    <template
      v-for="{ key, to, text, addComma, addAnd } in linksWithPunctuation"
    >
      <router-link :key="key" :to="to">
        {{ text }}
        <template v-if="addComma">,</template>
      </router-link>
      <template v-if="addAnd"> and </template>
    </template>
  </div>
</template>

<script setup>
import { computed, defineProps, toRef } from "vue";
const props = defineProps({
  title: { type: String, required: true },
  links: { type: Array, required: true }
});

const links = toRef(props, "links");
const linksWithPunctuation = computed(() => {
  return links.value.map((link, index) => {
    const addComma = links.value.length > 2 && links.value.length - 1 !== index;
    const addAnd = links.value.length > 1 && links.value.length - 2 === index;
    return {
      ...link,
      addComma,
      addAnd
    };
  });
});
</script>
