<template>
  <v-row dense>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="insured.ownable.firstName"
        data-lpignore="true"
        prepend-inner-icon="$mdi-account"
        label="First Name"
        outlined
        dense
        :data-testid="testIdPrefix('first-name')"
        :success="firstNameValidation.success"
        :error-messages="firstNameValidation.errorMessages"
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="insured.ownable.lastName"
        data-lpignore="true"
        prepend-inner-icon="$mdi-account-multiple"
        label="Last Name"
        outlined
        dense
        :data-testid="testIdPrefix('last-name')"
        :success="lastNameValidation.success"
        :error-messages="lastNameValidation.errorMessages"
      />
    </v-col>
    <v-col cols="12" md="6">
      <date-input
        v-model="insured.ownable.birthdate"
        data-lpignore="true"
        prepend-inner-icon="$mdi-calendar"
        label="Date of Birth"
        outlined
        dense
        :data-testid="testIdPrefix('birthdate')"
        :success="birthdateValidation.success"
        :error-messages="birthdateValidation.errorMessages"
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-select
        v-model="insured.ownable.gender"
        prepend-inner-icon="$mdi-gender-male-female"
        label="Gender"
        outlined
        dense
        :data-testid="testIdPrefix('gender')"
        :items="GENDERS"
        :success="genderValidation.success"
        :error-messages="genderValidation.errorMessages"
      />
    </v-col>
    <v-col cols="12" md="6" v-if="displayUnderwritingClass">
      <v-select
        v-model="insured.underwritingClass"
        prepend-inner-icon="$mdi-stethoscope"
        label="Underwriting Class"
        outlined
        dense
        :data-testid="testIdPrefix('underwriting-class')"
        :items="underwritingClassItems"
        :success="underwritingClassValidation.success"
        :error-messages="underwritingClassValidation.errorMessages"
      />
    </v-col>
    <v-col cols="12" md="6" v-if="displayMaritalStatus">
      <v-select
        v-model="insured.ownable.maritalStatus"
        prepend-inner-icon="$mdi-ring"
        label="Marital Status"
        outlined
        dense
        :data-testid="testIdPrefix('marital-status')"
        :items="maritalStatusItems"
        :success="maritalStatusValidation.success"
        :error-messages="maritalStatusValidation.errorMessages"
      />
    </v-col>
    <v-col cols="12" md="6" v-if="displayIncome">
      <currency-input
        v-model="insured.ownable.income"
        prepend-inner-icon="$mdi-currency-usd"
        label="Income"
        outlined
        dense
        :data-testid="testIdPrefix('income')"
        :success="incomeValidation.success"
        :error-messages="incomeValidation.errorMessages"
      />
    </v-col>
    <v-col cols="12" md="6" v-if="displayOccupationAndDuties">
      <v-text-field
        v-model="insured.occupationAndDuties"
        data-lpignore="true"
        prepend-inner-icon="$mdi-briefcase"
        label="Occupation and Duties"
        outlined
        dense
        :data-testid="testIdPrefix('occupation')"
        :success="occupationAndDutiesValidation.success"
        :error-messages="occupationAndDutiesValidation.errorMessages"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import DateInput from "@/components/shared/DateInput.vue";
import * as QUOTE_TYPE from "@/constants/quote-types.constants";

import { maritalStatusItems } from "@/data/quote-request-items";
import parse from "date-fns/parse";
import isValid from "date-fns/isValid";
import { RATINGS_BY_LINE } from "@/data/case-data";
import { computedValidation } from "@/util/helpers";
import { useQuoteRequestStore } from "@/stores/quote-request";
import { storeToRefs } from "pinia";
import { computed, watch, defineProps } from "vue";
import useVuelidate from "@vuelidate/core";

const GENDERS = ["Male", "Female"];

const props = defineProps({ isJoint: Boolean });

const quoteRequest = useQuoteRequestStore();
const { [props.isJoint ? "jointInsured" : "insured"]: insured, line } =
  storeToRefs(quoteRequest);

const underwritingClassItems = computed(() => RATINGS_BY_LINE[line.value]);

const displayMaritalStatus = computed(
  () => [QUOTE_TYPE.LTC].includes(line.value) && !props.isJoint
);
const displayUnderwritingClass = computed(() =>
  [QUOTE_TYPE.LTC, QUOTE_TYPE.LIFE].includes(line.value)
);
const displayIncome = computed(
  () => [QUOTE_TYPE.DISABILITY].includes(line.value) && !props.isJoint
);
const displayOccupationAndDuties = computed(
  () => [QUOTE_TYPE.DISABILITY].includes(line.value) && !props.isJoint
);

watch(line, () => (insured.value.underwritingClass = null));
watch(displayMaritalStatus, () => (insured.value.ownable.maritalStatus = null));
watch(displayIncome, () => (insured.value.ownable.income = null));
watch(
  displayOccupationAndDuties,
  () => (insured.value.occupationAndDuties = null)
);

const v$ = useVuelidate(
  {
    insured: {
      underwritingClass: {
        required: v => !displayUnderwritingClass.value || !!v
      },
      occupationAndDuties: {
        required: v => !displayOccupationAndDuties.value || !!v
      },
      ownable: {
        firstName: { required: v => !!v },
        lastName: { required: v => !!v },
        birthdate: {
          required: v => !!v,
          valid: day => isValid(parse(day, "yyyy-MM-dd", new Date()))
        },
        gender: { required: v => !!v },
        maritalStatus: {
          required: v => !displayMaritalStatus.value || !!v
        },
        income: { required: v => !displayIncome.value || !!v }
      }
    }
  },
  {
    insured
  },
  { $scope: "quote-request" }
);

const firstNameValidation = computedValidation(
  v$.value.insured.ownable.firstName,
  [{ key: "required", message: "Required" }]
);
const lastNameValidation = computedValidation(
  v$.value.insured.ownable.lastName,
  [{ key: "required", message: "Required" }]
);

const birthdateValidation = computedValidation(
  v$.value.insured.ownable.birthdate,
  [
    { key: "required", message: "Required" },
    { key: "valid", message: "Invalid Date" }
  ]
);

const genderValidation = computedValidation(v$.value.insured.ownable.gender, [
  { key: "required", message: "Required" }
]);

const maritalStatusValidation = computedValidation(
  v$.value.insured.ownable.maritalStatus,
  [{ key: "required", message: "Required" }]
);

const incomeValidation = computedValidation(v$.value.insured.ownable.income, [
  { key: "required", message: "Required" }
]);

const occupationAndDutiesValidation = computedValidation(
  v$.value.insured.occupationAndDuties,
  [{ key: "required", message: "Required" }]
);

const underwritingClassValidation = computedValidation(
  v$.value.insured.underwritingClass,
  [{ key: "required", message: "Required" }]
);

function testIdPrefix(attr) {
  return props.isJoint ? `joint-${attr}` : `primary-${attr}`;
}
</script>
