<template>
  <v-form>
    <v-row dense justify="center">
      <v-col cols="12">
        <h3 class="text-h4 mb-3 font-weight-light">
          <template v-if="isGroupOne"> Invite a Downline Agent </template>
          <template v-else> Invite an Agent </template>
        </h3>
        <p class="text-body-1">
          <template v-if="isGroupOne">
            Create an account that you have access to and receive the difference
            between your commission level and theirs
          </template>
          <template v-else>Create a new agent</template>
        </p>

        <v-alert type="info" outlined border="left">
          Looking to invite many agents?
          <a
            @click="$emit('select-type', 'referring')"
            class="text-decoration-underline"
          >
            Use your referral link!
          </a>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <basic-email-input
          v-model="advisor.email"
          required
          autofocus
          outlined
          dense
          data-testid="advisor-email"
          prepend-inner-icon="$mdi-email"
          label="E-Mail Address"
          :disabled="loading"
          :success="emailValidation.success"
          :error-messages="emailValidation.errorMessages"
          @valid="emailIsValid = $event"
        />
      </v-col>
      <v-col cols="12">
        <div class="checkbox-width">
          <v-checkbox
            v-model="agentHasNpn"
            class="mt-1"
            label="Agent has NPN"
            data-testid="agent-has-npn"
          />
        </div>
      </v-col>
      <template v-if="!agentHasNpn">
        <v-col cols="12">
          <v-text-field
            v-model="advisor.firstName"
            data-lpignore="true"
            prepend-inner-icon="$mdi-account"
            outlined
            dense
            label="First Name"
            data-testid="agent-first-name"
            :disabled="loading"
            :success="firstNameValidation.success"
            :error-messages="firstNameValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="advisor.lastName"
            data-lpignore="true"
            prepend-inner-icon="$mdi-account-multiple"
            outlined
            dense
            label="Last Name"
            data-testid="agent-last-name"
            :disabled="loading"
            :success="lastNameValidation.success"
            :error-messages="lastNameValidation.errorMessages"
          />
        </v-col>
      </template>
      <v-col cols="12" v-else>
        <npn-input
          v-model="advisor.npn"
          validate
          data-testid="agent-npn"
          :disabled="loading"
          :success="npnValidation.success"
          :error-messages="npnValidation.errorMessages"
          @valid="npnIsValid = $event"
          @error-message="npnErrorMessage = $event"
        />
      </v-col>

      <v-col v-if="showTopLevelAdvisor" cols="12">
        <advisor-search
          v-model="advisor.ownable"
          label="Top Level Advisor"
          persistent-hint
          hint="Who is at the top of the hierarchy? Leave blank for no hierarchy."
          data-testid="agent-advisor"
          :disabled="loading"
          :success="ownableValidation.success"
          :error-messages="ownableValidation.errorMessages"
        />
      </v-col>

      <v-col v-if="showMarketingManagerInput" cols="12">
        <marketing-manager-search
          v-model="advisor.marketingManager"
          data-testid="agent-marketing-manager"
          return-object
          :disabled="loading"
          :success="marketingManagerValidation.success"
          :error-messages="marketingManagerValidation.errorMessages"
        />
      </v-col>
      <v-col cols="12" v-if="canAssignCommissions">
        <div class="checkbox-width">
          <v-checkbox
            v-model="advisor.assignment"
            data-testid="agent-assign-commissions"
            :label="assignmentLabel"
            :disabled="loading"
            :success="advisor.assignment"
          />
        </div>
      </v-col>
      <v-col cols="12" v-if="advisor.assignment">
        <div class="checkbox-width">
          <v-checkbox
            v-model="advisor.lockCommissions"
            label="Lock Commissions"
            hint="A commission lock disables an agent's or agency's ability to modify Commission Splits, Commission Rates, and who they assign commissions to."
            persistent-hint
            data-testid="agent-lock-commissions"
            :disabled="loading"
            :success="advisor.assignment"
          />
        </div>
      </v-col>
      <v-col cols="12" class="mt-3">
        <p style="font-size: 16px; font-weight: bold">
          Subscribe to marketing emails?
        </p>
        <v-radio-group
          v-model="advisor.marketing"
          data-testid="agent-marketing"
          row
          :disabled="loading"
          :success="marketingValidation.success"
          :error-messages="marketingValidation.errorMessages"
        >
          <v-radio :value="true" label="Yes" />
          <v-radio :value="false" label="No" />
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <v-btn
          color="primary"
          class="text-none"
          data-testid="agent-create"
          :loading="loading"
          @click="createAgent"
        >
          <v-icon class="mr-1"> $mdi-plus </v-icon> Create Agent
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import MarketingManagerSearch from "@/components/shared/MarketingManagerSearch.vue";
import { parseErrorMessage } from "@/util/helpers";
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";
import NpnInput from "@/components/shared/NpnInput.vue";

import { inviteDownline } from "@/api/invites.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { mapActions, mapState } from "pinia";
export default {
  name: "AgentForm",
  components: {
    MarketingManagerSearch,
    AdvisorSearch,
    BasicEmailInput,
    NpnInput
  },
  data() {
    return {
      agentHasNpn: true,
      emailIsValid: null,
      advisor: {
        firstName: null,
        lastName: null,
        email: null,
        npn: null,
        marketingManager: {},
        ownable: {},
        permissions: {
          appointments: true,
          appointmentsEmails: true,
          cases: true,
          casesEmails: true,
          contractParties: true,
          contractPartiesEmails: true,
          eapps: true,
          eappsEmails: true,
          payments: true,
          paymentsEmails: true,
          quickQuotes: true,
          quickQuotesEmails: true,
          quotes: true,
          quotesEmails: true
        }
      },
      loading: false,
      npnErrorMessage: "",
      npnIsValid: null,
      niprTimer: null,
      type: null
    };
  },
  watch: {
    "advisor.ownable"() {
      if (!this.canAssignCommissions) this.advisor.assignment = false;
    },
    "advisor.assignment"(val) {
      if (!val) this.advisor.lockCommissions = false;
    }
  },
  computed: {
    ...mapState(useUserStore, {
      isGroupOne: "isGroupOne",
      loginable: "loginable"
    }),
    currentAssignable() {
      if (this.isGroupOne) {
        if (this.loginable.type === "Agency") return this.loginable;
        return this.loginable?.assignable;
      }
      return this.advisor.ownable;
    },
    canAssignCommissions() {
      return (
        this.currentAssignable?.type === "Agency" ||
        this.currentAssignable?.assignable?.type === "Agency"
      );
    },
    assignmentLabel() {
      return `Assign Commissions to ${this.currentAssignable.name}`;
    },
    showTopLevelAdvisor() {
      return !this.isGroupOne && !this.advisor.marketingManager?.id;
    },
    showMarketingManagerInput() {
      return !this.isGroupOne && !this.advisor.ownable?.id;
    },
    ownableValidation() {
      const success =
        !this.$v.advisor.ownable.$invalid && Boolean(this.advisor?.ownable?.id);
      const errorMessages = [];
      if (!this.$v.advisor.ownable.$dirty) return { success, errorMessages };
      else if (!this.$v.advisor.ownable.required)
        errorMessages.push("Required");
      return { success, errorMessages };
    },
    emailValidation() {
      const success = !this.$v.advisor.email.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.email.$dirty) return { success, errorMessages };
      if (!this.$v.advisor.email.required) {
        errorMessages.push("Please provide a valid email address");
      }
      if (!this.$v.advisor.email.isValid) {
        errorMessages.push("Email is invalid");
      }
      return { success, errorMessages };
    },
    npnValidation() {
      const success = !this.$v.advisor.npn.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.npn.$dirty && !this.npnErrorMessage)
        return { success, errorMessages };
      if (!this.$v.advisor.npn.required) errorMessages.push("Required");
      if (!this.npnIsValid && !this.advisor.npn) {
        errorMessages.push("Invalid NPN");
      }
      if (this.npnErrorMessage) errorMessages.push(this.npnErrorMessage);
      else if (!this.$v.advisor.npn.valid) errorMessages.push("Invalid NPN");
      return { success, errorMessages };
    },
    firstNameValidation() {
      const success = !this.$v.advisor.firstName.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.firstName.$dirty) return { success, errorMessages };
      if (!this.$v.advisor.firstName.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    lastNameValidation() {
      const success = !this.$v.advisor.lastName.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.lastName.$dirty) return { success, errorMessages };
      if (!this.$v.advisor.lastName.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    marketingManagerValidation() {
      const success = !this.$v.advisor.marketingManager.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.marketingManager.$dirty)
        return { success, errorMessages };
      if (!this.$v.advisor.marketingManager.required)
        errorMessages.push("Required");
      return { success, errorMessages };
    },
    marketingValidation() {
      const success = !this.$v.advisor.marketing.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.marketing.$dirty) return { success, errorMessages };
      if (!this.$v.advisor.marketing.required) errorMessages.push("Required");
      return { success, errorMessages };
    }
  },
  validations() {
    return {
      advisor: {
        email: {
          required: Boolean,
          isValid: () => this.emailIsValid === true
        },
        ownable: {
          required: (val, { marketingManager }) =>
            this.isGroupOne || Boolean(val?.id) || Boolean(marketingManager?.id)
        },
        npn: {
          required: val => !this.agentHasNpn || Boolean(val),
          valid: value =>
            !this.agentHasNpn || (Boolean(value) && Boolean(this.npnIsValid))
        },
        firstName: {
          required: val => this.agentHasNpn || Boolean(val)
        },
        lastName: {
          required: val => this.agentHasNpn || Boolean(val)
        },
        marketingManager: {
          required: (val, { ownable }) =>
            this.isGroupOne || Boolean(val?.id) || Boolean(ownable?.id)
        },
        marketing: {
          required: val => [true, false].includes(val)
        }
      }
    };
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async createAgent() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;

        const body = {
          email: this.advisor.email,
          npn: undefined,
          subscribe_to_marketing_emails: this.advisor.marketing,
          assign_commissions: undefined,
          lock_commissions: undefined,
          marketing_manager_id: undefined,
          ownable_id: undefined,
          ownable_type: undefined,
          first_name: undefined,
          last_name: undefined
        };
        if (this.agentHasNpn) {
          body.npn = this.advisor.npn;
        } else {
          body.first_name = this.advisor.firstName;
          body.last_name = this.advisor.lastName;
        }

        if (this.advisor.assignment) {
          body.assign_commissions = this.advisor.assignment;
        }
        if (this.advisor.lockCommissions) {
          body.lock_commissions = this.advisor.lockCommissions;
        }

        if (!this.isGroupOne && this.advisor.ownable?.id) {
          body.ownable_id = this.advisor.ownable.id;
          body.ownable_type = this.advisor.ownable.type;
          body.marketing_manager_id = this.advisor.ownable.marketing_manager_id;
        } else if (!this.isGroupOne) {
          body.marketing_manager_id = this.advisor.marketingManager.id;
        }

        const id = await inviteDownline(body);
        this.$router.push({
          name: "AgentView",
          params: {
            id
          }
        });
      } catch (error) {
        this.showErrorSnackbar({
          message: parseErrorMessage(error),
          timeout: -1
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
