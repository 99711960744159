<template>
  <v-autocomplete
    autocomplete="false"
    prepend-inner-icon="$mdi-cube"
    placeholder="Search Products"
    label="Product"
    outlined
    dense
    v-model="product"
    item-text="name"
    return-object
    no-filter
    hide-no-data
    :disabled="disabled"
    :search-input.sync="productSearch"
    :success="success"
    :error-messages="errorMessages"
    :items="products"
    :loading="loading"
    :hide-details="hideDetails"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    ref="productSearch"
    @blur="$emit('blur')"
    @change="$emit('change')"
  >
    <template #append-outer v-if="hasAppendOuterSlot">
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import sortBy from "lodash/sortBy";
import { productSimpleSearch } from "@/api/products.service";
export default {
  props: {
    success: Boolean,
    errorMessages: Array,
    value: [Object, String],
    lines: {
      required: false,
      type: Array
    },
    disabled: Boolean,
    hideDetails: Boolean
  },
  data() {
    let products = [];
    let product = null;
    if (this.value && Object.keys(this.value).length && this.value.id) {
      products = [this.value];
      product = this.value;
    }
    return {
      productSearch: "",
      productTimer: null,
      loading: false,
      products,
      product
    };
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots["append-outer"];
    }
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.product = null;
        this.products = [];
        return;
      }
      this.product = value;
      this.products = [value];
    },
    product(value) {
      this.$emit("input", value);
    },
    productSearch(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (
        this.products.some(val => val?.name === value) &&
        this.products.length === 1
      ) {
        return;
      }

      if (this.productTimer) {
        clearTimeout(this.productTimer);
      }

      this.productTimer = setTimeout(() => {
        this.loading = true;
        productSimpleSearch(value, this.lines)
          .then(response => {
            this.products = sortBy(response.data.products, "name");
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  },
  methods: {
    focus() {
      this.$refs.productSearch.$refs.input.focus();
    },
    clear() {
      this.product = null;
      this.products = [];
    }
  }
};
</script>
