<template>
  <v-row class="ma-0">
    <v-col>
      <v-row v-if="!editing">
        <v-col>
          <h1>{{ titleText }}</h1>
        </v-col>
      </v-row>
      <v-sheet
        :color="editing ? null : 'section'"
        class="pa-3"
        :class="{ 'my-2': !editing }"
        rounded
      >
        <h3>Product Details</h3>
        <v-row dense>
          <v-col cols="12" lg="4" md="6">
            <v-text-field
              data-lpignore="true"
              prepend-inner-icon="$mdi-cube-outline"
              outlined
              dense
              label="Name"
              v-model="product.name"
              @input="updateAttribute('name')"
              :success="!$v.product.name.$invalid"
              :error-messages="
                $v.product.name.$dirty && !$v.product.name.required
                  ? ['Required']
                  : []
              "
            >
              <template #append-outer>
                <active-save-indicator :controller="savingBuffer['name']" />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" v-if="!editing">
            <carrier-search
              @change="updateAttribute('carrier')"
              :success="!$v.product.carrier.id.$invalid"
              :error-messages="
                $v.product.carrier.id.$dirty && !$v.product.carrier.id.required
                  ? ['Required']
                  : []
              "
              v-model="product.carrier"
            >
              <template #append-outer>
                <active-save-indicator :controller="savingBuffer['carrier']" />
              </template>
            </carrier-search>
          </v-col>

          <v-col cols="12" lg="4" md="6">
            <v-select
              prepend-inner-icon="$mdi-cube"
              outlined
              dense
              label="Line"
              @change="updateAttribute('line')"
              placeholder="Select Line"
              :items="LINES"
              v-model="product.line"
              :success="!$v.product.line.$invalid"
              :error-messages="
                $v.product.line.$dirty && !$v.product.line.required
                  ? ['Required']
                  : []
              "
            >
              <template #append-outer>
                <active-save-indicator :controller="savingBuffer['line']" />
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <v-select
              prepend-inner-icon="$mdi-percent"
              outlined
              dense
              :disabled="isAnnuity"
              label="Line Percentage"
              @change="updateAttribute('linePercentage')"
              :items="LINE_PERCENTAGES"
              v-model="product.linePercentage"
              :success="!$v.product.linePercentage.$invalid"
              :error-messages="
                $v.product.linePercentage.$dirty &&
                !$v.product.linePercentage.required
                  ? ['Required']
                  : []
              "
            >
              <template #append-outer>
                <active-save-indicator
                  :controller="savingBuffer['linePercentage']"
                />
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <v-text-field
              data-lpignore="true"
              prepend-inner-icon="$mdi-calendar"
              outlined
              dense
              label="Active Years"
              v-model="product.activeYears"
              :success="!$v.product.activeYears.$invalid"
              @input="updateAttribute('activeYears')"
              :error-messages="
                $v.product.activeYears.$dirty &&
                !$v.product.activeYears.required
                  ? ['Required']
                  : []
              "
            >
              <template #append-outer>
                <active-save-indicator
                  :controller="savingBuffer['activeYears']"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <v-text-field
              label="BackNine Policy Fee"
              prepend-inner-icon="$mdi-currency-usd"
              disabled
              outlined
              dense
              success
              :value="125"
            />
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet :color="editing ? null : 'section'" class="pa-3 mt-2" rounded>
        <h3>Product Percentages</h3>
        <v-row dense>
          <v-col cols="12" lg="4" md="6">
            <v-text-field
              data-lpignore="true"
              prepend-inner-icon="$mdi-percent"
              outlined
              dense
              label="Street First"
              type="text"
              inputmode="numeric"
              v-model="product.streetFirst"
              @input="updateAttribute('streetFirst')"
              :success="!$v.product.streetFirst.$invalid"
              :error-messages="
                $v.product.streetFirst.$dirty &&
                !$v.product.streetFirst.required
                  ? ['Required']
                  : []
              "
            >
              <template #append-outer>
                <active-save-indicator
                  :controller="savingBuffer['streetFirst']"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <v-text-field
              data-lpignore="true"
              prepend-inner-icon="$mdi-percent"
              outlined
              dense
              label="Street Excess"
              type="text"
              inputmode="numeric"
              v-model="product.streetExcess"
              @input="updateAttribute('streetExcess')"
              :success="!$v.product.streetExcess.$invalid"
              :error-messages="
                $v.product.streetExcess.$dirty &&
                !$v.product.streetExcess.required
                  ? ['Required']
                  : []
              "
            >
              <template #append-outer>
                <active-save-indicator
                  :controller="savingBuffer['streetExcess']"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <v-text-field
              data-lpignore="true"
              prepend-inner-icon="$mdi-percent"
              outlined
              dense
              label="Street Renewal"
              v-model="product.streetRenewal"
              type="text"
              inputmode="numeric"
              @input="updateAttribute('streetRenewal')"
              :success="!$v.product.streetRenewal.$invalid"
              :error-messages="
                $v.product.streetRenewal.$dirty &&
                !$v.product.streetRenewal.required
                  ? ['Required']
                  : []
              "
            >
              <template #append-outer>
                <active-save-indicator
                  :controller="savingBuffer['streetRenewal']"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" v-if="showPua">
            <v-text-field
              data-lpignore="true"
              prepend-inner-icon="$mdi-percent"
              outlined
              dense
              label="Street PUA"
              v-model="product.streetPua"
              type="text"
              inputmode="numeric"
              @input="updateAttribute('streetPua')"
              :success="!$v.product.streetPua.$invalid"
              :error-messages="
                $v.product.streetPua.$dirty && !$v.product.streetPua.required
                  ? ['Required']
                  : []
              "
            >
              <template #append-outer>
                <active-save-indicator
                  :controller="savingBuffer['streetPua']"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <v-text-field
              data-lpignore="true"
              prepend-inner-icon="$mdi-percent"
              outlined
              dense
              label="Total First"
              v-model="product.totalFirst"
              type="text"
              inputmode="numeric"
              @input="updateAttribute('totalFirst')"
              :success="!$v.product.totalFirst.$invalid"
              :error-messages="
                $v.product.totalFirst.$dirty && !$v.product.totalFirst.required
                  ? ['Required']
                  : []
              "
            >
              <template #append-outer>
                <active-save-indicator
                  :controller="savingBuffer['totalFirst']"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <v-text-field
              data-lpignore="true"
              prepend-inner-icon="$mdi-percent"
              outlined
              dense
              label="Total Excess"
              type="text"
              inputmode="numeric"
              v-model="product.totalExcess"
              @input="updateAttribute('totalExcess')"
              :success="!$v.product.totalExcess.$invalid"
              :error-messages="
                $v.product.totalExcess.$dirty &&
                !$v.product.totalExcess.required
                  ? ['Required']
                  : []
              "
            >
              <template #append-outer>
                <active-save-indicator
                  :controller="savingBuffer['totalExcess']"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <v-text-field
              data-lpignore="true"
              prepend-inner-icon="$mdi-percent"
              outlined
              dense
              label="Total Renewal"
              type="text"
              inputmode="numeric"
              v-model="product.totalRenewal"
              @input="updateAttribute('totalRenewal')"
              :success="!$v.product.totalRenewal.$invalid"
              :error-messages="
                $v.product.totalRenewal.$dirty &&
                !$v.product.totalRenewal.required
                  ? ['Required']
                  : []
              "
            >
              <template #append-outer>
                <active-save-indicator
                  :controller="savingBuffer['totalRenewal']"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" v-if="showPua">
            <v-text-field
              data-lpignore="true"
              prepend-inner-icon="$mdi-percent"
              outlined
              dense
              label="Total PUA"
              v-model="product.totalPua"
              type="text"
              inputmode="numeric"
              @input="updateAttribute('totalPua')"
              :success="!$v.product.totalPua.$invalid"
              :error-messages="
                $v.product.totalPua.$dirty && !$v.product.totalPua.required
                  ? ['Required']
                  : []
              "
            >
              <template #append-outer>
                <active-save-indicator :controller="savingBuffer['totalPua']" />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <v-text-field
              data-lpignore="true"
              prepend-inner-icon="$mdi-credit-card"
              outlined
              dense
              label="Chargeback"
              v-model="product.chargeback"
              @input="updateAttribute('chargeback')"
              :success="Boolean(product.chargeback)"
            >
              <template #append-outer>
                <active-save-indicator
                  :controller="savingBuffer['chargeback']"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" v-if="showSurrenderPeriod">
            <v-text-field
              data-lpignore="true"
              outlined
              dense
              label="Surrender Period"
              type="text"
              inputmode="numeric"
              @input="updateAttribute('surrenderPeriod')"
              prepend-inner-icon="$mdi-calendar"
              :success="Boolean(product.surrenderPeriod)"
              v-model="product.surrenderPeriod"
            >
              <template #append-outer>
                <active-save-indicator
                  :controller="savingBuffer['surrenderPeriod']"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" v-if="showRollingTarget">
            <v-text-field
              data-lpignore="true"
              prepend-inner-icon="$mdi-sack"
              outlined
              dense
              label="Rolling Target"
              @input="updateAttribute('rollingTarget')"
              :success="Boolean(product.rollingTarget)"
              v-model="product.rollingTarget"
            >
              <template #append-outer>
                <active-save-indicator
                  :controller="savingBuffer['rollingTarget']"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" v-if="showAgeNearest">
            <div class="checkbox-width">
              <v-checkbox
                dense
                label="Age Nearest"
                type="text"
                inputmode="numeric"
                :success="Boolean(product.ageNearest)"
                v-model="product.ageNearest"
                class="mt-1"
                @change="updateAttribute('ageNearest')"
              >
                <template #label>
                  Age Nearest
                  <active-save-indicator
                    class="ml-2"
                    :controller="savingBuffer['ageNearest']"
                  />
                </template>
              </v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <div class="checkbox-width">
              <v-checkbox
                dense
                class="mt-1"
                v-model="product.eDelivery"
                :success="product.eDelivery"
                @change="updateAttribute('eDelivery')"
              >
                <template #label>
                  Carrier eDelivery
                  <active-save-indicator
                    class="ml-2"
                    :controller="savingBuffer['eDelivery']"
                  />
                </template>
              </v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" v-if="showConversion">
            <v-textarea
              v-model="product.conversion"
              label="Conversion"
              prepend-inner-icon="$mdi-text"
              outlined
              :success="Boolean(product.conversion)"
              @input="updateAttribute('conversion')"
            >
              <template #append-outer>
                <active-save-indicator
                  class="ml-2"
                  :controller="savingBuffer['conversion']"
                />
              </template>
            </v-textarea>
          </v-col>
          <template v-if="editing">
            <v-col cols="12">
              <v-select
                label="Advisor Guide"
                v-model="product.advisorGuide"
                clearable
                :items="advisorGuides"
                :success="Boolean(product.advisorGuide)"
                item-value="id"
                item-text="name"
                outlined
                dense
                @change="updateAttribute('advisorGuide')"
              >
                <template #append-outer>
                  <active-save-indicator
                    class="ml-2"
                    :controller="savingBuffer['advisorGuide']"
                  />
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Consumer Guide"
                v-model="product.consumerGuide"
                clearable
                :success="Boolean(product.consumerGuide)"
                :items="consumerGuides"
                outlined
                item-value="id"
                item-text="name"
                dense
                @change="updateAttribute('consumerGuide')"
              >
                <template #append-outer>
                  <active-save-indicator
                    class="ml-2"
                    :controller="savingBuffer['consumerGuide']"
                  />
                </template>
              </v-select>
            </v-col>
          </template>
          <v-col cols="12" v-if="editing">
            <v-select
              label="Underwriting Guide"
              v-model="product.underwritingGuide"
              clearable
              :success="Boolean(product.underwritingGuide)"
              :items="underwritingGuides"
              outlined
              item-value="id"
              item-text="name"
              dense
              @change="updateAttribute('underwritingGuide')"
            >
              <template #append-outer>
                <active-save-indicator
                  class="ml-2"
                  :controller="savingBuffer['underwritingGuide']"
                />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-sheet>
      <v-row v-if="!editing">
        <v-col cols="12" lg="4" md="6">
          <v-btn
            @click="createProduct"
            :loading="loading"
            class="text-none"
            color="primary"
          >
            <v-icon class="mr-1">$mdi-plus</v-icon>
            Create Product
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import {
  VerboseProductFactory,
  LINES,
  LINE_PERCENTAGES
} from "@/factories/ProductFactory";

import { required, url } from "vuelidate/lib/validators";
import { productCreate, productSave } from "@/api/products.service";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
export default {
  mixins: [ActiveSaveMixin],

  props: {
    value: Object,
    editing: Boolean
  },
  components: {
    CarrierSearch,
    ActiveSaveIndicator
  },
  data() {
    let product;
    if (this.editing) {
      product = new VerboseProductFactory(this.value);
    } else {
      product = new VerboseProductFactory();
      product.primaryProduct = true;
    }

    const savingBuffer = this.generateSavingBuffer({
      items: [
        "activeYears",
        "ageNearest",
        "carrier",
        "eDelivery",
        "chargeback",
        "line",
        "linePercentage",
        "name",
        "planType",
        "consumerProductGuide",
        "rollingTarget",
        "streetExcess",
        "streetFirst",
        "streetRenewal",
        "streetPua",
        "surrenderPeriod",
        "totalExcess",
        "totalFirst",
        "totalRenewal",
        "totalPua",
        "conversion",
        "advisorGuide",
        "consumerGuide",
        "underwritingGuide"
      ]
    });
    return {
      consumerProductGuide: null,
      uploadingConsumerProductGuide: false,
      loading: false,
      editingCarrier: false,
      savingBuffer,
      product,
      LINES,
      LINE_PERCENTAGES,
      timer: null
    };
  },
  computed: {
    underwritingGuides() {
      if (!this.product?.guides?.length) return [];
      return this.product.guides.filter(
        ({ category }) => category === "Underwriting Guide"
      );
    },
    consumerGuides() {
      if (!this.product?.guides?.length) return [];
      return this.product.guides.filter(
        ({ category }) => category === "Consumer Product Guide"
      );
    },
    advisorGuides() {
      if (!this.product?.guides?.length) return [];
      return this.product.guides.filter(
        ({ category }) => category === "Advisor Product Guide"
      );
    },
    showConversion() {
      return Boolean(this.product.showConversion);
    },
    showPua() {
      return this.product.planType === "Whole Life";
    },
    showAgeNearest() {
      return ["linked_benefit", "life", "variable_life", "ltc"].includes(
        this.product.line
      );
    },
    showRollingTarget() {
      return ["linked_benefit", "life", "variable_life"].includes(
        this.product.line
      );
    },
    showSurrenderPeriod() {
      return ["annuity", "linked_benefit", "life", "variable_life"].includes(
        this.product.line
      );
    },
    isAnnuity() {
      if (this.product.line === "annuity") return true;
      return false;
    },
    titleText() {
      if (this.parentProduct) {
        return `Edit ${this.parentProduct.carrier.name} - ${this.parentProduct.name}`;
      }
      return "Create a Product";
    },
    fileValidation() {
      if (this.product.productGuide?.size !== 0)
        return ["Please reupload your files"];
      return [];
    }
  },
  created() {
    useHead({
      title: this.$route.name === "ProductCreate" ? "Create Product" : undefined
    });
  },
  watch: {
    product(value) {
      this.$emit("input", value);
    },
    "product.line"(value) {
      if (value === "annuity") {
        this.product.linePercentage = 5;
      }
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    editCarrier() {
      this.editingCarrier = true;
    },
    async createProduct() {
      if (this.editing) return;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      try {
        const id = await productCreate({
          product: this.product.toCreateRequest()
        });
        this.$router.push({
          name: "ProductView",
          params: { id }
        });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    },
    updateAttribute(attribute) {
      if (this.savingBuffer[attribute].timer)
        clearTimeout(this.savingBuffer[attribute].timer);
      this.savingBuffer[attribute].timer = setTimeout(() => {
        if (!this.editing) {
          return;
        }

        this.updateAttributeWrapper(
          () =>
            productSave(this.product.id, {
              product: this.product.getAttributeRequestValue(attribute)
            }),
          this.savingBuffer[attribute]
        );
      }, 300);
    }
  },

  validations() {
    let validators = {
      product: {
        consumerProductGuide: { url },
        name: { required },
        carrier: { id: { required } },
        planType: { required },
        line: { required },
        linePercentage: { required },
        activeYears: { required },
        streetFirst: { required },
        streetExcess: { required },
        streetRenewal: { required },
        streetPua: { required: val => !this.showPua || Boolean(val) },
        totalFirst: { required },
        totalExcess: { required },
        totalRenewal: { required },
        totalPua: { required: val => !this.showPua || Boolean(val) }
      }
    };

    return validators;
  }
};
</script>
