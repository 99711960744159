<template>
  <form-mapping-form-layout
    :has-questions="hasQuestions"
    :is-copied-form="isCopiedForm"
    :vertical="vertical"
  >
    <template #editor>
      <form-mapping-form-aqls
        ref="editor"
        :form-id="formId"
        :vertical="vertical"
        :cannot-copy-field="cannotCopyField"
        :copied-form="isCopiedForm"
        :is-copying-form="isCopyingForm"
        :sample-id="sampleId"
        :readonly="readonly"
      >
        <template #copy v-if="isCopiedForm">
          <form-mapping-form-aql-copy-button
            :loading="copyingField"
            :not-copyable-text="cannotCopyField"
            @copy-to-new="copyToNew"
            @copy-field="copyField"
          />
        </template>
      </form-mapping-form-aqls>
    </template>
    <template v-if="!isEappSubmission" #questions-not-on-pdf>
      <form-mapping-form-aqls-by-visibility
        :form-id="formId"
        :readonly="readonly || isCopiedForm"
      />
    </template>
    <template #right>
      <form-mapping-form-aqls-by-visibility
        v-if="isEappSubmission"
        all-questions
        :form-id="formId"
        :readonly="readonly || isCopiedForm"
      />
      <form-mapping-form-aqls-pdf
        v-else
        :key="`form-${formId}`"
        :is-copying-form="isCopyingForm"
        :is-copied="Boolean(isCopiedForm)"
        :form-id="formId"
        :vertical="vertical"
        :readonly="readonly"
      >
        <template #copy v-if="isCopiedForm">
          <form-mapping-form-aql-copy-button
            :loading="copyingField"
            :not-copyable-text="cannotCopyField"
            @copy-to-new="copyToNew"
            @copy-field="copyField"
          />
        </template>
      </form-mapping-form-aqls-pdf>
    </template>
  </form-mapping-form-layout>
</template>
<script>
import FormMappingFormAqls from "@/components/form-mapping/FormMappingFormAqls.vue";
import FormMappingFormAqlsPdf from "@/components/form-mapping/FormMappingFormAqlsPdf.vue";
import FormMappingFormAqlsByVisibility from "@/components/form-mapping/FormMappingFormAqlsByVisibility.vue";
import FormMappingFormLayout from "@/components/form-mapping/FormMappingFormLayout.vue";
import FormMappingFormAqlCopyButton from "@/components/form-mapping/FormMappingFormAqlCopyButton.vue";
import { useFormMappingStore } from "@/stores/form-mapping";
import { storeToRefs } from "pinia";

export default {
  components: {
    FormMappingFormAqlCopyButton,
    FormMappingFormAqlsByVisibility,
    FormMappingFormAqls,
    FormMappingFormAqlsPdf,
    FormMappingFormLayout
  },
  props: {
    copyingField: Boolean,
    readonly: Boolean,
    vertical: Boolean,
    isCopyingForm: Boolean,
    isCopiedForm: Boolean,
    formId: [Number, String],
    sampleId: [Number, String]
  },
  setup({ formId }) {
    const formStore = useFormMappingStore(formId);

    const {
      fields,
      activeField,
      currentFieldId,
      otherFields,
      category,
      isEappSubmission
    } = storeToRefs(formStore);

    return {
      activeTab: 0,
      dirty: false,
      creatingField: false,
      fields,
      activeField,
      currentFieldId,
      otherFields,
      category,
      isEappSubmission
    };
  },
  computed: {
    fieldCopiedTo() {
      if (!this.otherFields?.ids?.length) return null;
      const activeQuestionLinkId = this.activeField.id;
      return this.otherFields.ids.find(id => {
        if (!this.otherFields.by_id[id]) return false;
        return (
          this.otherFields.by_id[id].referenceField === activeQuestionLinkId
        );
      });
    },
    hasAlreadyBeenCopiedOnce() {
      return Boolean(this.fieldCopiedTo);
    },
    cannotCopyField() {
      if (!this.isCopiedForm) return "No field to copy from";
      // if the main edited field has already been created
      if (!this.activeField) return "";
      // if the copied field has no positional data

      const hasNoPositionalData = !this.activeField?.coordinates?.length;

      if (hasNoPositionalData) return "Field has no positional data";
      if (this.hasAlreadyBeenCopiedOnce)
        return `Field has already been copied to ${this.fieldCopiedTo}`;

      return "";
    },
    hasQuestions() {
      return Boolean(this.fields.ids.length);
    },
    textClass() {
      if (this.$vuetify.breakpoint.xl) return "truncate-400";
      return "truncate-200";
    }
  },
  methods: {
    copyField() {
      this.$emit("copy-field", this.activeField);
    },
    copyToNew() {
      this.$emit("copy-to-new", this.activeField);
    }
  }
};
</script>
