import { getHttpClient } from "@/http-client";

const baseUrl = "api/boss/payments";
export function createPayment(params) {
  return getHttpClient().post(`${baseUrl}`, params);
}
let cancelToken = null;
export function getPayments(params) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  return getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });
}
export function emailPayments(body) {
  return getHttpClient().post(`${baseUrl}/email`, body);
}
