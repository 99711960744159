import { PusherMock } from "pusher-js-mock";
import Pusher from "pusher-js";
import { useInstanceStore } from "@/stores/instance";
import { authorizeChat } from "@/api/notes.service";
import { authorizeContractingFormDownloadChannel } from "@/api/appointment.service";
import { authorizeNewAgencySubscription } from "@/api/agencies.service";

const getNewPusherInstance = (args = {}) => {
  if (process.env.VUE_APP_COMPONENT_TESTING) {
    return new PusherMock(args);
  }
  const instance = useInstanceStore();
  return new Pusher(instance.wss, {
    enabledTransports: ["ws"],
    forceTLS: true,
    ...args
  });
};

export function initSocketForChat(pusherId, { newNoteHandler = null }) {
  const [, id, type] = pusherId.split("-");
  const pusher = getNewPusherInstance({
    channelAuthorization: {
      customHandler: ({ channelName, socketId }, callback) =>
        authorizeChat({
          channelName,
          id,
          type,
          socketId,
          callback
        })
    }
  });

  const channel = pusher.subscribe(pusherId);
  if (newNoteHandler) channel.bind("new-note", newNoteHandler);

  if (process.env.VUE_APP_COMPONENT_TESTING) notifySubscription(pusher);
  return pusher;
}

export function initSocketForAppointmentContracting(
  id,
  {
    fetchFormsHandler,
    documentHandler = null,
    errorHandler = null,
    finishHandler = null
  }
) {
  const pusher = getNewPusherInstance({
    channelAuthorization: {
      customHandler: ({ channelName, socketId }, callback) =>
        authorizeContractingFormDownloadChannel(id, {
          channelName,
          socketId,
          callback
        })
    }
  });
  const channel = pusher.subscribe(`private-${id}-Appointment-contracting`);
  channel.bind("pusher:subscription_succeeded", fetchFormsHandler);
  if (documentHandler) channel.bind("document_id", documentHandler);
  if (errorHandler) channel.bind("error", errorHandler);
  if (finishHandler) channel.bind("finish", finishHandler);

  if (process.env.VUE_APP_COMPONENT_TESTING) notifySubscription(pusher);
  return pusher;
}

export function initSocketForAgencySignup(
  agencyId,
  { niprUpdateHandler = null, niprFailHandler }
) {
  const pusher = getNewPusherInstance({
    channelAuthorization: {
      customHandler: ({ channelName, socketId }, callback) =>
        authorizeNewAgencySubscription(agencyId, {
          channelName,
          socketId,
          callback
        })
    }
  });

  const channel = pusher.subscribe(`private-${agencyId}-Agency`);
  if (niprUpdateHandler) channel.bind("nipr-update", niprUpdateHandler);
  if (niprFailHandler) channel.bind("nipr-fail", niprFailHandler);

  if (process.env.VUE_APP_COMPONENT_TESTING) notifySubscription(pusher);
  return pusher;
}

function notifySubscription(pusher) {
  if (!process.env.VUE_APP_COMPONENT_TESTING) {
    throw "MUST ONLY BE UTILIZED IN MOCK MODE";
  }
  window.Cypress.pusher = pusher;
}
