<template>
  <v-card hover class="ma-3" :href="href" :to="to" target="_blank">
    <div class="grey--text font-weight-light px-4 pt-3" style="font-size: 14px">
      <slot name="type" />
    </div>
    <v-card-title
      class="py-0"
      style="
        font-weight: 500;
        letter-spacing: 0.3px;
        font-size: 1rem;
        display: block;
        line-height: 1.3;
      "
    >
      <slot name="subject" />
    </v-card-title>
    <v-card-subtitle
      style="font-size: 16px; color: #343434"
      :style="{ color: $vuetify.theme.dark ? null : '#343434' }"
      class="pb-0 mt-0"
    >
      <slot name="subtitle" />
    </v-card-subtitle>
    <v-card-text
      style="line-height: 1.2; white-space: pre-line; font-size: 14px"
    >
      <slot name="content" />
      <div class="mt-1">
        <a>
          <slot name="c2a" />
        </a>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    href: {
      type: String,
      required: false,
      default: ""
    },
    to: {
      type: Object,
      required: false,
      default: null
    }
  }
};
</script>
