<template>
  <v-card flat color="section">
    <v-card-title> Payment Method </v-card-title>
    <v-card-subtitle>
      Select a Payment Method to receive commissions.
    </v-card-subtitle>
    <v-card-text>
      <v-row class="ma-0">
        <v-select
          v-model="activeEft"
          item-text="payingTo"
          item-value="id"
          outlined
          dense
          label="Payment Method"
          prepend-inner-icon="$mdi-checkbook"
          style="max-width: 30rem"
          data-testid="payment-method-selector"
          :items="eftOptions"
          :success="activeEftValidation.success"
          :error-messages="activeEftValidation.errorMessages"
          :disabled="props.readonly"
        >
          <template #append-outer>
            <active-save-indicator
              :controller="savingBuffer.activeEft.controller.value"
            />
          </template>
        </v-select>
        <v-btn
          v-if="canDownloadEft"
          icon
          data-testid="payment-method-download"
          color="primary"
          target="_blank"
          v-bind="downloadEft"
        >
          <v-icon> $mdi-download</v-icon>
        </v-btn>
      </v-row>
      <v-btn
        class="text-none"
        color="accent"
        data-testid="add-payment-method"
        @click="addNewEft"
      >
        <v-icon> $mdi-plus</v-icon> Add Payment Method
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { downloadFileAsLink } from "@/util/helpers";
import { getDocumentUrl } from "@/api/documents.service";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { useSettingsViewStore } from "@/stores/settings-view";
import { computed, defineProps } from "vue";
import { useActiveSave } from "@/composables/active-save.composable";
import { useVuetify } from "@/composables/compatible.composables";

const props = defineProps({
  readonly: Boolean,
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});

let store;

if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}

const { efts } = storeToRefs(store);

const dialog = useDialogStore();

const savingBuffer = {
  activeEft: useActiveSave()
};

const user = useUserStore();

const eftOptions = computed(() => Object.values(efts.value));

const activeEft = computed({
  get() {
    return activeEftObject.value?.id;
  },
  set(eftId) {
    if (props.readonly) return;
    store.updateActiveEft(eftId);
  }
});

const activeEftObject = computed(() =>
  eftOptions.value.find(val => val.current === 1)
);

const canDownloadEft = computed(() => {
  if (user.isGroupOne || props.readonly) return false;
  return Boolean(efts.value[activeEft.value]?.eftDocument?.uid);
});

const activeEftValidation = computed(() => {
  const required = Boolean(activeEftObject.value);
  const isErrored = activeEftObject.value?.errors;

  const errorMessages = [];
  if (!required) errorMessages.push("Required");
  if (isErrored) {
    errorMessages.push(
      "The provided EFT is invalid. Please provide a valid EFT."
    );
  }
  return { success: !errorMessages.length, errorMessages };
});

const vuetify = useVuetify();
const downloadEft = computed(() => {
  return downloadFileAsLink(
    getDocumentUrl(efts.value[activeEft.value].eftDocument.uid),
    vuetify.breakpoint.mdAndDown
  );
});

function addNewEft() {
  dialog.showDialog({
    component: "AddEftDialog",
    module: props.module,
    scrollable: true
  });
}
</script>
