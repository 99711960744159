import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";
import { numberFormat } from "@/util/helpers";

const objectToUrlParams = (
  obj,
  parentKey = "",
  accumulator = new URLSearchParams(),
  level = 0
) => {
  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach(value => {
        let paramKey = `[${key}][]`;
        if (parentKey) paramKey = `${parentKey}${paramKey}`;
        else if (level === 0) paramKey = `${key}[]`;
        accumulator.append(paramKey, value);
      });
    } else if (typeof obj[key] === "object" && Boolean(obj[key])) {
      let paramKey = `${parentKey}`;
      if (!paramKey.length) paramKey = key;
      else paramKey += `[${key}]`;
      objectToUrlParams(obj[key], paramKey, accumulator, level + 1);
    } else {
      let paramKey = key;
      if (parentKey) paramKey = `${parentKey}[${key}]`;
      accumulator.append(paramKey, obj[key]);
    }
  });
};

function Table({
  text = "",
  method = null,
  options = new TableOptions(),
  headers = [],
  key = "",
  filter = {},
  rawItems = [],
  meta = {},
  loading = false,
  sort = {},
  loaded = false,
  icon = "",
  emailMethod = null,
  requests = 0,
  stats = {}
}) {
  return {
    text,
    method,
    options,
    headers,
    key,
    filter,
    sort,
    rawItems,
    meta,
    loading,
    loaded,
    icon,
    emailMethod,
    requests,
    stats,

    get mappedItems() {
      return this.tableMap(this.rawItems, this.headers);
    },
    get visibleHeaders() {
      return this.headers.filter(val => !val.isAdditional);
    },
    resetPage() {
      this.options.page = 1;
    },

    generateBody() {
      const body = {};
      this.toTableFilter(body);
      this.toTableSort(body);
      if (this.options.page >= 1) {
        body.page = this.options.page;
      }
      if (this.options.itemsPerPage >= 1) {
        body.count = this.options.itemsPerPage;
      }
      return body;
    },

    generateQuery(additionalFilter = {}) {
      const body = this.generateBody();
      const params = new URLSearchParams();
      objectToUrlParams(body, "", params);
      for (const [key, value] of Object.entries(additionalFilter)) {
        params.append(key, value);
      }
      return params;
    },

    async getData(setData = false, additionalFilter = {}, setMeta = true) {
      this.loading = true;
      try {
        const response = await this.method(
          this.generateQuery(additionalFilter)
        );
        if (setData) {
          if (!response?.data) return;
          let items = response.data;
          if (this.key) items = response.data[this.key];
          this.rawItems = items;

          if (setMeta) this.meta = response.data.meta;
        }
        this.loading = false;
        this.loaded = true;
        return response;
      } catch (e) {
        this.loading = false;
        this.loaded = true;
        throw e;
      }
    },

    async getPreMappedData(additionalFilter = {}) {
      this.loading = true;
      try {
        const result = await this.method(this.generateQuery(additionalFilter));
        let meta, items;
        if (Array.isArray(result)) {
          items = result;
        } else {
          meta = result?.meta;
          items = result?.items;
        }
        if (!items) return;
        this.rawItems = items;
        this.meta = meta;
        this.loading = false;
        this.loaded = true;
      } catch (e) {
        this.loading = false;
        this.loaded = true;
        throw e;
      }
    },

    emailData() {
      return this.emailMethod(this.generateBody());
    },

    append(manager, key, value) {
      if (manager.append) {
        manager.append(key, value);
      } else {
        manager[key] = value;
      }
    },

    toTableFilter(params = {}) {
      const filterKeys = Object.keys(this.filter);

      if (!filterKeys.length) return;
      filterKeys.forEach(rawKey => {
        const header = this.headers.find(h => h.value === rawKey);
        if (!this.filter[rawKey] && !header.sendFalse) return;
        let key = header.sortFilterMap || rawKey;

        const isDateType = header.filterType === TableHeader.DATE_TYPE;
        const isNumberFilter = header.filterType === TableHeader.NUMBER_RANGE;

        let rootFilter = params;
        if (!header.isQueryFilter) {
          if (!params.filter) params.filter = {};
          rootFilter = params.filter;
        }
        if (isDateType) {
          const finish = this.filter[rawKey].value.finish;
          const start = this.filter[rawKey].value.start;
          if (!start && !finish) return;

          if (!params.date) params.date = {};
          rootFilter = params.date;
          rootFilter[key] = {};
          if (finish) rootFilter[key]["finish"] = finish;
          if (start) rootFilter[key]["start"] = start;
          return;
        }

        if (isNumberFilter) {
          const min = this.filter[rawKey].value.min;
          const max = this.filter[rawKey].value.max;
          if (!min && !max) return;
          rootFilter[key] = {};
          if (min) rootFilter[key]["min"] = min;
          if (max) rootFilter[key]["max"] = max;
          return;
        }

        if (Array.isArray(header.sortFilterMap)) {
          header.sortFilterMap.forEach(sortFilter => {
            let val = get(this.filter[rawKey], sortFilter.value);
            if (!val) return;
            rootFilter[sortFilter.key] = val;
          });
          return;
        }

        let val = get(this.filter[rawKey], header.map) || this.filter[rawKey];
        if (header.sendFalse && val === false) rootFilter[key] = val;
        else if (val) rootFilter[key] = val;
      });
    },

    toTableSort(params = {}) {
      if (!Object.keys(this.options.sortBy).length) return;
      this.options.sortBy.forEach((item, index) => {
        const header = this.headers.find(val => val.value === item);
        if (!header) return;
        const val = this.options.sortDesc[index];
        const key = header.sortKey || header.sortFilterMap;
        if (!params["sorting"]) params["sorting"] = {};
        params["sorting"][key] = val ? "desc" : "asc";
      });
    },

    tableMap(
      data,
      headers,
      tableHelpers = { downloading: false, deleting: false, loading: false }
    ) {
      if (!data || !headers) return [];
      return data?.map((value, index) => {
        const rowData = {};
        headers.forEach(header => {
          if (header.filterType === TableHeader.DATE_TYPE) {
            const val = get(value, header.map);
            rowData[header.value] = val;
            return;
          }
          if (Array.isArray(header.map)) {
            const newVal = [];
            header.map.forEach(piece => {
              newVal.push(get(value, piece));
            });
            rowData[header.value] = newVal.join(" ");
            return;
          }
          if (typeof header.map === "function") {
            rowData[header.value] = header.map(value);
            return;
          }
          rowData[header.value] = get(value, header.map);
        });
        rowData.key = index + JSON.stringify(rowData);
        rowData.additional = {
          ...value,
          tableHelpers: cloneDeep(tableHelpers)
        };
        return rowData;
      });
    },
    pageTextFormatter(tableOptions, meta) {
      if (!meta || !meta?.total) return "No Results";
      const total = meta?.total || meta;
      const currentMinValue =
        (tableOptions.page - 1) * tableOptions.itemsPerPage + 1;
      const currentMaxValue = Math.min(
        total,
        tableOptions.page * tableOptions.itemsPerPage
      );

      return `${numberFormat(currentMinValue)}-${numberFormat(
        currentMaxValue
      )} of ${numberFormat(total)}`;
    },

    optionsEquivalence(oldOptions, newOptions) {
      const newValues = pick(newOptions, [
        "itemsPerPage",
        "page",
        "sortBy",
        "sortDesc"
      ]);
      const origValues = pick(oldOptions, [
        "itemsPerPage",
        "page",
        "sortBy",
        "sortDesc"
      ]);
      return isEqual(newValues, origValues);
    },
    handleCustomSorts(options, oldOptions) {
      if (!options.sortBy.length) return options;
      options.sortBy.forEach((option, index) => {
        const header = this.headers.find(header => option === header.value);
        if (!header?.invertedSort) return options;
        const oldOptionsIndex = oldOptions.sortBy.findIndex(
          value => value === option
        );
        if (oldOptionsIndex > -1) return;
        options.sortDesc[index] = true;
      });

      return options;
    }
  };
}

export default Table;
