<template>
  <v-data-table
    :headers="headers"
    :items="items"
    item-class="additional.rowClass"
    show-expand
    multi-sort
    :options="options"
    :loading="loading"
    item-key="additional.key"
  >
    <template #[`item.statementDate`]="{ item }">
      <router-link
        :class="{ 'white--text text-underline': item.additional.hasFatalFlaw }"
        :to="{
          name: 'StatementView',
          params: { id: item.additional.statement_id }
        }"
      >
        <timestamp-formatter :value="item.statementDate" parser="sole-day" />
      </router-link>
    </template>
    <template #[`item.amount`]="{ item }">
      <currency-formatter :value="item.amount" />
    </template>
    <template #[`item.directOverride`]="{ item }">
      <currency-formatter :value="item.directOverride" />
    </template>
    <template #[`item.totalOverride`]="{ item }">
      <currency-formatter :value="item.totalOverride" />
    </template>
    <template #[`item.transactionActions`]="{ item }">
      <v-menu bottom offset-y>
        <template #activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" icon>
            <v-icon> $mdi-dots-horizontal </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="!item.additional.any_paid"
            @click="destroyTransaction(item)"
          >
            <v-list-item-icon>
              <v-icon>$mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Delete Transaction </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="auditTransaction(item.additional.id)">
            <v-list-item-icon>
              <v-icon> $mdi-magnify-scan </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Audit Transaction </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <template
            v-if="!item.additional.reverted_by && item.additional.any_paid"
          >
            <v-list-item @click="reflowTransaction(item.additional.id)">
              <v-list-item-icon>
                <v-icon> $mdi-backup-restore </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Reflow Transaction </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="revertTransaction(item.additional.id)">
              <v-list-item-icon>
                <v-icon> $mdi-undo-variant </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Revert Transaction </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </template>
    <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="item.additional.hasAuditMessages"
            v-on="on"
            v-bind="attrs"
            @click.stop="expand(!isExpanded)"
            icon
          >
            <v-icon color="white"> $mdi-magnify-scan </v-icon>
          </v-btn>
        </template>
        <span> View Audit Messages </span>
      </v-tooltip>
    </template>
    <template #expanded-item="{ headers, item }">
      <td v-if="item.additional.hasAuditMessages" :colspan="headers.length">
        <v-list>
          <template
            v-for="(auditMessage, index) in item.additional.audit_messages"
          >
            <v-list-item :key="index">
              <v-list-item-icon>
                <v-icon v-if="auditMessage.level === 'fatal'" color="error">
                  $mdi-alert-circle
                </v-icon>
                <v-icon
                  v-else-if="auditMessage.level === 'debug'"
                  color="accent"
                >
                  $mdi-bug
                </v-icon>
                <v-icon v-else color="info"> $mdi-information </v-icon>
              </v-list-item-icon>
              {{ auditMessage.message }}
            </v-list-item>
            <v-divider
              v-if="index < item.additional.audit_messages.length - 1"
              :key="index + 'divider'"
            />
          </template>
        </v-list>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";

import Table from "@/classes/data-table/Table";
import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import {
  revertTransaction,
  reflowTransaction,
  auditTransaction,
  deleteTransaction
} from "@/api/transactions.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

export default {
  mixins: [GenericTableMixin],
  components: {
    CurrencyFormatter,
    TimestampFormatter
  },
  props: {
    isDialog: Boolean,
    transactions: Array,
    loading: Boolean
  },
  data() {
    const transactionHeaders = [
      new TableHeader({
        text: "Transaction ID",
        value: "transactionId",
        map: "id",
        ...TableHeader.IS_SORTABLE
      }),
      new TableHeader({
        text: "Statement Date",
        value: "statementDate",
        map: "statement_date",
        ...TableHeader.IS_SORTABLE
      }),
      new TableHeader({
        text: "Commission Type",
        value: "commissionType",
        map: "commission_type"
      }),
      new TableHeader({
        text: "Premium",
        value: "amount",
        map: "amount"
      }),
      new TableHeader({
        text: "Direct Override",
        value: "directOverride",
        map: "direct_commission"
      }),
      new TableHeader({
        text: "Total Override",
        value: "totalOverride",
        map: "override"
      }),
      new TableHeader({
        text: "Actions",
        value: "transactionActions",
        map: "transactionActions"
      })
    ];

    let transactionOptions = new TableOptions(
      ["transactionId", "statementDate"],
      [true, true]
    );
    const transactionsTable = new Table({
      text: "Transactions",
      headers: transactionHeaders,
      key: "transactions",
      options: transactionOptions
    });
    return {
      table: transactionsTable
    };
  },
  computed: {
    headers() {
      return this.table.headers;
    },
    items() {
      return this.tableMap(
        this.transactions.map(v => {
          let rowClass = null;
          const hasAuditMessages = Boolean(v.audit_messages?.length);
          const hasFatalFlaw = v.audit_messages?.some(v => v.level === "fatal");
          if (hasFatalFlaw) rowClass = "error white--text";
          return {
            ...v,
            key: JSON.stringify(v),
            hasAuditMessages,
            hasFatalFlaw,
            rowClass
          };
        }),
        this.table.headers
      );
    },
    sort: {
      get() {
        return this.table.sort;
      },
      set(value) {
        this.table.sort = value;
      }
    },
    options: {
      get() {
        return this.table.options;
      },
      set(value) {
        this.table.options = value;
      }
    },
    sortFilterMap() {
      return this.table.sortFilterMap;
    }
  },
  destroyed() {
    if (!this.isDialog) this.closeDialog();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog", "showDialog"]),
    revertTransaction(transactionId) {
      this.showDialog({
        component: "ConfirmationDialog",
        title: "Revert this transaction",
        subtitle: "This will undo the transaction",
        func: () => revertTransaction(transactionId)
      })
        .then(({ confirm }) => {
          if (confirm) this.$emit("fetch");
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        });
    },
    reflowTransaction(transactionId) {
      this.showDialog({
        component: "ConfirmationDialog",
        title: "Reflow this transaction",
        subtitle: "This will reflow the transaction",
        func: async () => {
          try {
            await reflowTransaction(transactionId);
          } catch (e) {
            this.showErrorSnackbar({
              message: parseErrorMessage(e),
              timeout: 10000
            });
          }
        }
      }).then(({ confirm }) => {
        if (confirm) this.$emit("fetch");
      });
    },
    auditTransaction(transactionId) {
      this.showDialog({
        component: "ConfirmationDialog",
        title: "Audit this transaction",
        subtitle: "This will audit the transaction",
        func: () => auditTransaction(transactionId)
      })
        .then(({ confirm }) => {
          if (confirm) this.$emit("fetch");
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        });
    },
    destroyTransaction(item) {
      this.showDialog({
        component: "DeleteDialog",
        title: `Delete Transaction?`,
        subtitle: "This cannot be undone",
        func: () => deleteTransaction(item.additional.id)
      }).then(result => {
        if (result?.delete) this.$emit("fetch");
      });
    }
  }
};
</script>
