<template>
  <v-col :cols="cols" :xl="xl" :lg="lg" :md="md" :sm="sm" :xs="xs">
    <!-- Carrier -->
    <template v-if="item === 'Carrier'">
      <span class="grey--text lightn-2">Carrier</span>
      <br />
      <span>
        <router-link
          :to="{
            name: 'CarrierPage',
            params: {
              id: model.carrier.id
            }
          }"
        >
          {{ model.carrier.name }}
        </router-link>
      </span>
    </template>
    <!-- Department -->
    <template v-else-if="item === 'Department'">
      <span class="grey--text lightn-2">Department</span>
      <br />
      <span> {{ model.department }} </span>
    </template>
    <!-- Work Phone -->
    <template v-else-if="item === 'Work Phone'">
      <span class="grey--text lightn-2">Work Phone</span>
      <br />
      <span>
        <a
          v-if="model.phoneWorkExtension"
          :href="`tel:${model.phoneWork}x${model.phoneWorkExtension}`"
          data-outbound-type="Personnel"
          :data-outbound-id="model.id"
          :data-outbound-number="`${model.phoneWork}x${model.phoneWorkExtension}`"
        >
          {{ model.phoneWork }}x{{ model.phoneWorkExtension }}
        </a>
        <a
          v-else
          data-outbound-type="Personnel"
          :data-outbound-id="model.id"
          :data-outbound-number="model.phoneWork"
          :href="`tel:${model.phoneWork}`"
        >
          {{ model.phoneWork }}
        </a>
      </span>
    </template>
    <!-- Mobile Phone -->
    <template v-else-if="item === 'Mobile Phone'">
      <span class="grey--text lightn-2">Mobile Phone</span>
      <br />
      <span>
        <a
          :href="`tel:${model.phoneMobile}`"
          data-outbound-type="Personnel"
          :data-outbound-id="model.id"
          :data-outbound-number="model.phoneMobile"
        >
          {{ model.phoneMobile }}
        </a>
      </span>
    </template>
    <!-- Fax -->
    <template v-else-if="item === 'Fax'">
      <span class="grey--text lightn-2">Fax</span>
      <br />
      <span>
        <a :href="`fax:${model.phoneFax}`">
          {{ model.phoneFax }}
        </a>
      </span>
    </template>
    <!-- Email -->
    <template v-else-if="item === 'Email'">
      <span class="grey--text lightn-2">Email</span>
      <br />
      <span>
        <a :href="`mailto:${model.email}`"> {{ model.email }} </a>
      </span>
    </template>
    <!-- Home Address -->
    <template v-else-if="item === 'Home Address'">
      <span class="grey--text lightn-2">Home Address</span>
      <br />
      <span>
        {{ homeAddress }}
      </span>
    </template>
    <!-- Business Address -->
    <template v-else-if="item === 'Business Address'">
      <span class="grey--text lightn-2">Business Address</span>
      <br />
      <span>
        {{ businessAddress }}
      </span>
    </template>
  </v-col>
</template>

<script>
import AddressFactory from "@/factories/AddressFactory";

export default {
  props: {
    item: String,
    model: Object,
    xl: {
      default: 2
    },
    lg: {
      default: 3
    },
    md: {
      default: 4
    },
    sm: {
      default: 6
    },
    xs: {
      default: 6
    },
    cols: {
      default: 12
    }
  },
  computed: {
    homeAddress() {
      return new AddressFactory(this.model.homeAddress).toDisplayString();
    },
    businessAddress() {
      return new AddressFactory(this.model.businessAddress).toDisplayString();
    }
  }
};
</script>
