<template>
  <access
    v-model="room"
    type="client"
    button-color="accent"
    data-testid="contract-party-access-table"
    :checkboxes="CHECKBOXES"
    :update-func="updateContractPartyAccess"
    :delete-func="deleteContractPartyAccess"
    :add-func="addContractPartyAccess"
  >
    <template #top>
      <v-card-title class="px-0"> Access </v-card-title>
    </template>
  </access>
</template>
<script setup>
import Access from "@/components/shared/Access.vue";

import { useContractPartyView } from "@/stores/contract-party-view";
import { storeToRefs } from "pinia";
import { useDialogStore } from "@/stores/dialog";

const CHECKBOXES = [
  { text: "Email", value: "email" },
  { text: "Agent", value: "agent" }
];

const dialog = useDialogStore();
const contractPartyView = useContractPartyView();
const { room } = storeToRefs(contractPartyView);

function addContractPartyAccess(advisor) {
  return contractPartyView.createAccess(advisor);
}

function deleteContractPartyAccess(access) {
  return dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Are you sure that you want remove this client access?",
    subtitle: "Please confirm your intent",
    func: () =>
      contractPartyView.deleteAccess(access.additional.contractPartyAccessId)
  });
}

function updateContractPartyAccess(access, attribute) {
  return contractPartyView.updateAccess(
    access.additional.contractPartyAccessId,
    attribute
  );
}
</script>
