<template>
  <v-data-table
    class="transparent-data-table"
    data-testid="advisor-profile-cases-table"
    :loading="table.loading.value"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :disable-pagination="true"
    :hide-default-footer="true"
  >
    <template #[`item.insuredName`]="{ item }">
      <router-link :to="item.additional.routerLink">
        {{ item.insuredName }}
      </router-link>
    </template>

    <template #footer>
      <v-row class="ma-0 pa-3">
        <v-spacer />
        <v-btn
          color="primary"
          class="text-none"
          text
          data-testid="advisor-profile-cases-table-view-all"
          @click="viewAll"
        >
          <v-icon class="mr-1"> {{ mdiOpenInApp }} </v-icon> View {{ name }}'s
          Cases
        </v-btn>
      </v-row>
    </template>
  </v-data-table>
</template>

<script setup>
import { mdiOpenInApp } from "@mdi/js";

import TableHeader from "@/classes/data-table/TableHeader";

import { parseErrorMessage, currencyFormat } from "@/util/helpers";

import { useSnackbarStore } from "@/stores/snackbar";
import { storeToRefs } from "pinia";
import { useTableStore } from "@/stores/table";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { useRouter } from "@/composables/compatible.composables";
import { useTable } from "@/composables/table.composable";

const router = useRouter();
const snackbar = useSnackbarStore();
const tableStore = useTableStore();
const advisorView = useAdvisorProfileView();
const { name, cases, tableFilterData } = storeToRefs(advisorView);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Insured",
      value: "insuredName",
      map: "insuredName"
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrierName",
      map: "carrierName"
    }),
    new TableHeader({
      text: "Policy Number",
      value: "policyNumber",
      map: "policyNumber"
    }),
    new TableHeader({ text: "Status", value: "status", map: "status" }),
    new TableHeader({
      text: "Production Credit",
      value: "productionCreditTotal",
      map: "productionCreditTotal"
    })
  ],
  getData: async () => {
    await advisorView.getRecentCases();
    return {
      items: cases.value.map(item => ({
        ...item,
        productionCreditTotal: currencyFormat(item.productionCreditTotal, 0)
      }))
    };
  }
});

function viewAll() {
  tableStore.casesTable.filter = {
    additionalAdvisor: tableFilterData.value
  };
  router.push({ name: "CasesTable" });
}

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getData();
</script>
