<template>
  <v-card flat>
    <v-card-title> Case Management Report </v-card-title>
    <v-card-subtitle>View the performance of case managers</v-card-subtitle>
    <v-divider />
    <v-card-text class="section">
      <v-fade-transition mode="out-in">
        <v-row v-if="loading" class="ma-0" key="loading">
          <p class="font-weight-regular mb-0">Loading Case Managers</p>
          <v-progress-circular class="ml-3" indeterminate size="18" width="2" />
        </v-row>
        <v-row
          v-else-if="caseManagers.length"
          dense
          justify="center"
          key="has-managers"
        >
          <v-col
            cols="12"
            xl="4"
            md="6"
            v-for="manager in caseManagers"
            :key="manager.name"
          >
            <v-card flat>
              <v-card-title data-testid="manager-name" class="justify-center">
                {{ manager.name }}
              </v-card-title>
              <v-row dense class="ma-0">
                <v-col cols="12" sm="4">
                  <v-card
                    flat
                    color="section"
                    class="tasks-score"
                    data-testid="tasks-score"
                  >
                    <v-card-title class="justify-center">
                      {{ manager.taskScore }}%
                    </v-card-title>
                    <v-card-subtitle class="text-center">
                      Task Score
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card
                    flat
                    color="section"
                    class="pending-cases-count"
                    data-testid="pending-cases-count"
                  >
                    <v-card-title class="justify-center">
                      {{ manager.pendingCases }}
                    </v-card-title>
                    <v-card-subtitle class="text-center">
                      Pending Cases
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card
                    flat
                    color="section"
                    class="task-duration"
                    data-testid="task-duration"
                  >
                    <v-card-title class="justify-center">
                      {{ manager.averageDuration }} days
                    </v-card-title>
                    <v-card-subtitle class="text-center">
                      Avg. Task Duration
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <p v-else class="mb-0" key="no-managers">No Case Managers Found</p>
      </v-fade-transition>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { getTasksReport } from "@/api/reports.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { ref } from "vue";

useHead({
  title: "Case Management Report"
});

const snackbar = useSnackbarStore();

const caseManagers = ref([]);
const loading = ref(false);

async function getCaseManagerData() {
  loading.value = true;
  try {
    caseManagers.value = await getTasksReport();
    caseManagers.value.sort((a, b) => a.name.localeCompare(b.name));
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

getCaseManagerData();
</script>

<style lang="scss">
.v-application {
  .v-card.tasks-score,
  .v-card.pending-cases-count,
  .v-card.task-duration {
    border-width: 1px !important;
    border-left-width: 5px !important;
    border-style: solid !important;
  }
  .v-card.tasks-score {
    border-color: green !important;
  }
  .v-card.pending-cases-count {
    border-color: blue !important;
  }
  .v-card.task-duration {
    border-color: orange !important;
  }
}
</style>
