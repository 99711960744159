<template>
  <v-card flat tile>
    <v-row class="ma-0" align="center">
      <v-col>
        <strong style="font-size: 1.2rem">
          {{ product.name }}
        </strong>
        <br />
        {{ product.carrier.name }}
      </v-col>
      <v-col>
        <v-row justify="end" class="ma-0">
          <carrier-image
            :image="product.carrier.avatarUrl"
            :id="product.carrier.id"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="ma-0">
      <v-col
        v-for="detail in cardDetails"
        :key="detail.title"
        xl="2"
        lg="3"
        md="4"
        sm="6"
        cols="12"
      >
        <component :is="detail.component" v-bind="detail.props" />
      </v-col>
    </v-row>
    <template v-if="guides.length">
      <v-divider />
      <v-row class="ma-0" align="center">
        <v-col
          v-for="(doc, index) in guides"
          xl="2"
          lg="3"
          md="4"
          sm="6"
          cols="12"
          :key="index"
        >
          <a v-bind="downloadDoc(doc)">
            <v-icon color="primary" small>$mdi-file-document</v-icon>
            {{ doc.category }}
          </a>
        </v-col>
      </v-row>
      <v-divider />
    </template>
    <v-row v-if="product.trainingInstructions" class="ma-0">
      <v-col cols="12">
        <v-alert type="info" class="mb-0">
          <strong>Product Training Required!</strong> Training Instructions:
          {{ product.trainingInstructions }}
        </v-alert>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import CardItemText from "@/components/shared/card-items/CardItemText.vue";
import CarrierImage from "@/components/shared/CarrierImage.vue";
import { downloadFileAsLink } from "@/util/helpers";
import { getDocumentUrl } from "@/api/documents.service";
import { useUserStore } from "@/stores/user";
import { markRaw, defineProps, computed, toRef } from "vue";
import { storeToRefs } from "pinia";
import { useVuetify } from "@/composables/compatible.composables";
const props = defineProps({
  product: {
    type: Object,
    required: true
  }
});

const product = toRef(props, "product");

const { isGroupTwoPlus, isGroupFour } = storeToRefs(useUserStore());

const firstYear = computed(() => ({
  component: markRaw(CardItemText),
  props: {
    title: "First Year",
    text: `${product.value.firstYearCommission}%`
  }
}));

const bronzeFirstYear = computed(() => {
  if (!product.value.schedules.bronze) return null;
  return {
    component: markRaw(CardItemText),
    props: {
      title: "Bronze First Year",
      text: `${product.value.schedules.bronze}%`
    }
  };
});

const silverFirstYear = computed(() => {
  if (!product.value.schedules.silver) return null;
  return {
    component: markRaw(CardItemText),
    props: {
      title: "Silver First Year",
      text: `${product.value.schedules.silver}%`
    }
  };
});

const goldFirstYear = computed(() => {
  if (!product.value.schedules.gold) return null;
  return {
    component: markRaw(CardItemText),
    props: {
      title: "Gold First Year",
      text: `${product.value.schedules.gold}%`
    }
  };
});

const platinumFirstYear = computed(() => {
  if (!product.value.schedules.platinum) return null;
  return {
    component: markRaw(CardItemText),
    props: {
      title: "Platinum First Year",
      text: `${product.value.schedules.platinum}%`
    }
  };
});

const diamondFirstYear = computed(() => {
  if (!product.value.schedules.diamond) return null;
  return {
    component: markRaw(CardItemText),
    props: {
      title: "Diamond First Year",
      text: `${product.value.schedules.diamond}%`
    }
  };
});

const onyxFirstYear = computed(() => {
  if (!product.value.schedules.onyx) return null;
  return {
    component: markRaw(CardItemText),
    props: {
      title: "Onyx First Year",
      text: `${product.value.schedules.onyx}%`
    }
  };
});

const totalFirst = computed(() => {
  if (!product.value.totalFirst || !isGroupTwoPlus.value) return null;
  return {
    component: markRaw(CardItemText),
    props: {
      title: "Total First Year",
      text: `${product.value.totalFirst}%`
    }
  };
});

const totalRenewal = computed(() => {
  if (!product.value.totalRenewal || !isGroupTwoPlus.value) return null;
  return {
    component: markRaw(CardItemText),
    props: {
      title: "Total Renewal",
      text: `${product.value.totalRenewal}%`
    }
  };
});

const chargeback = computed(() => {
  if (!product.value.chargeback || isGroupFour.value) return null;
  return {
    component: markRaw(CardItemText),
    props: {
      title: "Chargeback",
      text: product.value.chargeback
    }
  };
});

const linePercentage = computed(() => {
  if (!product.value.linePercentage || isGroupFour.value) return null;
  return {
    component: markRaw(CardItemText),
    props: {
      title: "Line Percentage",
      text: `${product.value.linePercentage}%`
    }
  };
});

const cardDetails = computed(() => {
  return [
    firstYear.value,
    bronzeFirstYear.value,
    silverFirstYear.value,
    goldFirstYear.value,
    platinumFirstYear.value,
    diamondFirstYear.value,
    onyxFirstYear.value,
    totalFirst.value,
    totalRenewal.value,
    chargeback.value,
    linePercentage.value
  ].filter(Boolean);
});
const guides = computed(() => {
  const guides = [];
  if (product.value.consumerGuide) {
    const guide = product.value.guides.find(
      ({ id }) => product.value.consumerGuide === id
    );
    if (guide) guides.push(guide);
  }
  if (product.value.underwritingGuide) {
    const guide = product.value.guides.find(
      ({ id }) => product.value.underwritingGuide === id
    );
    if (guide) guides.push(guide);
  }
  if (product.value.advisorGuide) {
    const guide = product.value.guides.find(
      ({ id }) => product.value.advisorGuide === id
    );
    if (guide) guides.push(guide);
  }

  return guides;
});

const vuetify = useVuetify();
function downloadDoc({ uid }) {
  return downloadFileAsLink(getDocumentUrl(uid), vuetify.breakpoint.mdAndDown);
}
</script>
