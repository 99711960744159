<template>
  <v-card flat tile>
    <v-card-text class="pa-0">
      <v-row align="center" class="ma-0">
        <v-col
          cols="12"
          md="6"
          order="2"
          order-md="1"
          :align="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
        >
          <strong style="font-size: 1.2rem" data-testid="name">{{
            nameWithTitle
          }}</strong>
          <br />
          <div v-if="businessAddressDisplay" data-testid="business-address">
            {{ businessAddressDisplay }}
          </div>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="6" order="1" order-md="2">
          <v-row class="ma-0" justify-md="end" justify="center">
            <avatar-edit :avatar="avatar" :editable="false" />
          </v-row>
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="ma-0">
        <v-col
          v-for="detail in cardDetails"
          :key="detail.key"
          cols="12"
          md="6"
          lg="3"
          xl="3"
        >
          <component
            :is="detail.component"
            v-bind="detail.props"
            :data-testid="detail.props.title"
            lg="12"
            xl="12"
          />
        </v-col>
        <v-col
          v-if="affiliations.length"
          cols="12"
          md="6"
          data-testid="affiliations"
        >
          <span class="grey--text lighten-2">
            Affiliated With (specified during signup)
          </span>
          <br />
          <div class="mx-n1">
            <v-tooltip
              v-for="affiliation in affiliations"
              :key="affiliation.id"
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  outlined
                  small
                  depressed
                  style="cursor: pointer"
                  class="text-none ma-1"
                  @click="viewAffiliation(affiliation.name)"
                >
                  {{ affiliation.name }}
                </v-btn>
              </template>
              Category: {{ affiliation.category }}, Click to View
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import AvatarEdit from "@/components/shared/AvatarEdit.vue";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { numberFormat, parseErrorMessage } from "@/util/helpers";
import { useTableStore } from "@/stores/table";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { computed } from "vue";
import { useRouter } from "@/composables/compatible.composables";
import {
  linkItem,
  routerLinkItem,
  textItem
} from "@/components/shared/card-items/card-items";

const advisorProfile = useAdvisorProfileView();
const snackbar = useSnackbarStore();
const user = useUserStore();
const router = useRouter();
const { isGroupTwoPlus } = storeToRefs(user);
const {
  affiliations,
  businessAddressDisplay,
  isAgency,
  name,
  avatar,
  signerName,
  signerRouterLink,
  phoneWork,
  phoneMobile,
  type,
  title,
  id,
  email,
  marketingManagerName,
  npn,
  deceasedAt,
  assignsToId,
  assignsToIsSigner,
  assignsToName,
  assignsToRouterLink,
  accountableName,
  accountableRouterLink,
  advisorCount,
  discovery
} = storeToRefs(advisorProfile);

const nameWithTitle = computed(() => {
  let display = name.value;
  const shouldShowTitle = assignsToIsSigner.value && title.value;
  if (shouldShowTitle) display = `${name.value}, ${title.value}`;
  if (deceasedAt.value) display = `${display} (Deceased)`;

  return display;
});

async function getAffiliations() {
  try {
    await advisorProfile.getAffiliations();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}
const cardDetails = computed(() => {
  const details = [];

  let assignsTo = false;
  if (!isAgency.value && Boolean(assignsToId.value)) {
    assignsTo = +assignsToId.value !== +id.value;
  }

  if (assignsTo) {
    details.push(
      routerLinkItem({
        title: assignsToIsSigner.value ? "Signer" : "Assigns To",
        text: assignsToName.value,
        to: assignsToRouterLink.value
      })
    );
  } else if (signerName.value) {
    details.push(
      routerLinkItem({
        title: "Signer",
        text: signerName.value,
        to: signerRouterLink.value
      })
    );
  }

  if (phoneWork.value) {
    details.push(
      linkItem({
        title: "Work Phone",
        text: phoneWork.value,
        href: `tel:${phoneWork.value}`,
        dataOutboundType: type.value,
        dataOutboundId: id.value,
        dataOutboundNumber: phoneWork.value
      })
    );
  }
  if (phoneMobile.value) {
    details.push(
      linkItem({
        title: "Mobile Phone",
        text: phoneMobile.value,
        href: `tel:${phoneMobile.value}`,
        dataOutboundType: type.value,
        dataOutboundId: id.value,
        dataOutboundNumber: phoneMobile.value
      })
    );
  }
  if (email.value) {
    details.push(
      linkItem({
        title: "Email",
        text: email.value,
        href: `mailto:${email.value}`
      })
    );
  }

  details.push(
    textItem({
      title: "Marketing Manager",
      text: marketingManagerName.value
    })
  );

  if (npn.value) {
    details.push(
      textItem({
        title: "NPN",
        text: npn.value.toString()
      })
    );
  }

  if (accountableName.value) {
    details.push(
      routerLinkItem({
        title: "SaaS Customer",
        text: accountableName.value,
        to: accountableRouterLink.value
      })
    );
  }

  if (advisorCount.value) {
    details.push(
      textItem({
        title: "Advisors",
        text: numberFormat(advisorCount.value)
      })
    );
  }
  if (discovery.value) {
    details.push(
      textItem({
        title: "Discovery",
        text: discovery.value
      })
    );
  }
  return details;
});

function viewAffiliation(name) {
  const table = useTableStore();
  table.affiliationsTable.search = name;
  if (router) router.push({ name: "Tables", query: { page: "affiliations" } });
}

if (!isAgency.value && isGroupTwoPlus.value) getAffiliations();
</script>
