<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="table in tables" :key="table.title">
      <v-expansion-panel-header> {{ table.title }} </v-expansion-panel-header>
      <v-expansion-panel-content class="no-expansion-panel-content-padding">
        <component :is="table.component" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import RateBandsTable from "@/components/products/RateBandsTable.vue";
import VendorTable from "@/components/vendors/VendorTable.vue";
import UsersTable from "@/components/users/UsersTable.vue";
import { markRaw } from "vue";

const tables = [
  { title: "Vendors", component: markRaw(VendorTable) },
  { title: "Rate Bands", component: markRaw(RateBandsTable) },
  { title: "Users", component: markRaw(UsersTable) }
];
</script>
