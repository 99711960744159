<template>
  <v-data-table
    class="transparent-data-table"
    data-testid="advisor-profile-quotes-table"
    :loading="table.loading.value"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :disable-pagination="true"
    :hide-default-footer="true"
  >
    <template #[`item.name`]="{ item }">
      <router-link class="text-none" :to="item.additional.routerLink">
        {{ item.name }}
      </router-link>
    </template>

    <template #footer>
      <v-row class="ma-0 pa-3">
        <v-spacer />
        <v-btn
          data-testid="advisor-profile-quotes-table-view-all"
          color="primary"
          class="text-none"
          text
          @click="viewAll"
        >
          <v-icon class="mr-1"> {{ mdiOpenInApp }} </v-icon> View {{ name }}'s
          Quotes
        </v-btn>
      </v-row>
    </template>
  </v-data-table>
</template>

<script setup>
import { mdiOpenInApp } from "@mdi/js";
import TableHeader from "@/classes/data-table/TableHeader";

import { parseErrorMessage, timestampFormatter } from "@/util/helpers";

import { storeToRefs } from "pinia";
import { useTableStore } from "@/stores/table";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { useRouter } from "@/composables/compatible.composables";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTable } from "@/composables/table.composable";

const snackbar = useSnackbarStore();
const router = useRouter();
const tableStore = useTableStore();
const advisorView = useAdvisorProfileView();
const { name, quotes, tableFilterData } = storeToRefs(advisorView);

const table = useTable({
  headers: [
    new TableHeader({ text: "Name", value: "name", map: "name" }),
    new TableHeader({ text: "Status", value: "status", map: "status" }),
    new TableHeader({ text: "Line", value: "lineText", map: "lineText" }),
    new TableHeader({
      text: "Created",
      value: "createdAt",
      map: "createdAt"
    })
  ],
  getData: async () => {
    await advisorView.getRecentQuotes();
    return {
      items: quotes.value.map(item => ({
        ...item,
        createdAt: timestampFormatter(item.createdAt)
      }))
    };
  }
});
async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

function viewAll() {
  tableStore.quotesTable.filter = {
    sharedWith: tableFilterData.value
  };
  router.push({ name: "Quotes" });
}

getData();
</script>
