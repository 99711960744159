<template>
  <v-card>
    <v-card-title>
      <v-row class="pa-3 pr-0">
        Top Advisors <v-spacer />
        <v-btn
          text
          color="primary"
          class="text-none"
          :to="{ name: 'Tables', query: { page: 'advisors' } }"
        >
          <v-icon class="mr-1"> $mdi-open-in-new </v-icon>
          View Advisors
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-list>
        <template v-if="advisors.length">
          <v-list-item
            link
            two-line
            v-for="(item, index) in advisors"
            :key="index"
            :to="{
              name: item.link.toLowerCase().includes('agent')
                ? 'AgentView'
                : 'AgencyView',
              params: { id: item.id }
            }"
            class="purple-list-item"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-row class="py-3">
                  <v-col cols="6" class="pb-0 pt-2">
                    Pending:
                    <strong>
                      <currency-formatter
                        :value="item.pending"
                        :decimal-length="0"
                      />
                    </strong>
                  </v-col>
                  <v-spacer />
                  <v-col cols="6" class="pb-0 pt-2" align="end">
                    Active YTD:
                    <strong>
                      <currency-formatter
                        :value="item.yearToDate"
                        :decimal-length="0"
                      />
                    </strong>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-else>
          <v-list-item two-line class="ma-1 secondary rounded-list-item">
            <v-list-item-content>
              <v-list-item-title>
                Your top agents will appear here
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
export default {
  components: {
    CurrencyFormatter
  },
  props: {
    advisors: Array
  }
};
</script>
