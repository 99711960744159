const scroll = (el, scrollTop = null) => {
  const top = scrollTop || el.scrollHeight - el.clientHeight;
  if (typeof el.scroll === "function") el.scroll({ top });
  else el.scrollTop = top; // eslint-disable-line no-param-reassign
};
const defaultConfig = {
  enabled: true,
  handlePrepend: false
};

const observers = new WeakMap();
const heights = new WeakMap();

/**
 * This function is called when a mutation is observed.
 * The config is used to determine what to do.
 */
const mutationObserved = (el, config) => {
  // if not enabled, do nothing.
  if (config.enabled === false) return;

  // if not handling prepend, simply scroll.
  if (config.handlePrepend === false) {
    scroll(el);
    return;
  }

  // if handling prepend, we need to calculate where to scroll to.
  // We're prepending if scrollTop is zero and heights has the el.
  // ScrollTop will be difference in scrollHeight before and after.
  const scrollTop =
    el.scrollTop === 0 && heights.has(el) && el.scrollHeight - heights.get(el);

  scroll(el, scrollTop);
  heights.set(el, el.scrollHeight);
};

/**
 * This object defines the directive itself.
 */
export const directive = {
  inserted: (el, binding) => {
    const config = { ...defaultConfig, ...binding.value };
    mutationObserved(el, config);
  },

  /**
   * When the directive binding is updated we have to update our MutationObserver.
   * We disconnect the old MutationObserver (if it already exists in observers).
   * We then create and save a new MutationObserver with the new callback.
   */
  update: (el, binding) => {
    if (observers.has(el)) observers.get(el).disconnect();
    const config = { ...defaultConfig, ...binding.value };
    const mutationCallback = () => {
      mutationObserved(el, config);
    };

    const resizeObserver = new ResizeObserver(mutationCallback);
    resizeObserver.observe(el, { childList: true, subtree: true });
    observers.set(el, resizeObserver);
  }
};

export default directive;
