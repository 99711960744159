import { AVAILABLE_SERIES } from "@/composables/series-composable";

export default function HomeGoalFactory(model = {}) {
  return {
    type: model?.type || null,
    completed: model?.completed || false,
    get typeInfo() {
      return SITE_GOALS[this.type];
    },
    get to() {
      return this.typeInfo.to;
    },
    get href() {
      return this.typeInfo.href;
    },
    get text() {
      return this.typeInfo.text;
    },
    get color() {
      return this.typeInfo.color;
    },
    setFromRequest({ type, completed }) {
      this.type = type;
      this.completed = completed;
    }
  };
}

function SiteGoalDisplayInfoFactory({ text, color, to, href }) {
  return {
    text,
    color,
    to,
    href
  };
}

const TYPES = {
  COMPLETE_CONTRACTING: "complete_contracting",
  CREATE_IMPAIRED: "create_an_impaired_risk_quote",
  EARN_A_COMMISSION: "earn_a_commission",
  REQUEST_A_QUOTE: "request_a_quote",
  START_QUOTE_AND_APPLY_EAPP: "start_a_quote_and_apply_eapp",
  SUBMIT_A_CASE: "submit_a_case",
  COMPLETE_WALKTHROUGH: "complete_walkthrough"
};

const COLORS = {
  PRIMARY: "primary",
  GOLD: "gold",
  COMPLETED: "grey"
};

const SITE_GOALS = {
  [TYPES.COMPLETE_WALKTHROUGH]: new SiteGoalDisplayInfoFactory({
    text: "Complete Walkthrough",
    color: COLORS.GOLD,
    to: {
      name: "HelpCenterSeries",
      params: { id: AVAILABLE_SERIES.WALKTHROUGH }
    }
  }),
  [TYPES.COMPLETE_CONTRACTING]: new SiteGoalDisplayInfoFactory({
    text: "Complete Contracting",
    color: COLORS.PRIMARY,
    to: { name: "LoggedInUserSettings" }
  }),
  [TYPES.CREATE_IMPAIRED]: new SiteGoalDisplayInfoFactory({
    text: "Create an Impaired Risk Quote",
    color: COLORS.GOLD,
    to: { name: "ImpairedRiskQuoteCreate" }
  }),
  [TYPES.EARN_A_COMMISSION]: new SiteGoalDisplayInfoFactory({
    text: "Earn a Commission",
    color: COLORS.PRIMARY,
    to: { name: "Commissions", query: { page: "payments" } }
  }),
  [TYPES.REQUEST_A_QUOTE]: new SiteGoalDisplayInfoFactory({
    text: "Create a Quote Request",
    color: COLORS.PRIMARY,
    to: { name: "CreateQuote" }
  }),
  [TYPES.START_QUOTE_AND_APPLY_EAPP]: new SiteGoalDisplayInfoFactory({
    text: "Start a Quote & Apply eApp",
    color: COLORS.PRIMARY,
    href: user => user?.approved_domain_url
  }),
  [TYPES.SUBMIT_A_CASE]: new SiteGoalDisplayInfoFactory({
    text: "Submit a Case",
    color: COLORS.GOLD,
    to: { name: "CaseSubmission" }
  })
};
