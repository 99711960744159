var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"must-sort":"","options":_vm.options,"footer-props":_vm.footerProps,"server-items-length":_vm.revenueTable.meta.total,"item-key":"additional.id"},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('generic-table-filter-header',{attrs:{"show-view-filter":_vm.isGroupTwoPlus,"value":_vm.filter,"headers":_vm.allHeaders,"loading":_vm.loading,"hover":"","color":"section"},on:{"update":_vm.updateFilter}})]},proxy:true},{key:`item.createdDate`,fn:function({ item }){return [_c('timestamp-formatter',{attrs:{"value":item.createdDate,"parser":"sole-day"}})]}},{key:`item.statementDate`,fn:function({ item }){return [_c('timestamp-formatter',{attrs:{"value":item.statementDate,"parser":"sole-day"}})]}},{key:`item.payPeriod`,fn:function({ item }){return [(item.payPeriod)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"primary","label":"","to":{
            name: 'PayPeriodView',
            params: {
              id: item.payPeriod
            }
          }}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("$mdi-calendar")]),_vm._v(" #"+_vm._s(item.payPeriod)+" ")],1)]}}],null,true)},[_c('span',[_vm._v("View Statement")])]):_vm._e()]}},{key:`item.parties`,fn:function({ item }){return [_vm._v(" Sender: "),_c('strong',[_vm._v(" "+_vm._s(item.paidBy)+" ")]),_vm._v(" "),_c('br'),_vm._v(" Recipient: "),_c('strong',[_vm._v(" "+_vm._s(item.paidTo)+" ")]),_vm._v(" "),_c('br'),_vm._v(" Agent: "),_c('strong',[_vm._v(" "+_vm._s(item.agent)+" ")])]}},{key:`item.premium`,fn:function({ item }){return [_c('currency-formatter',{attrs:{"value":item.premium}})]}},{key:`item.splitPercent`,fn:function({ item }){return [_c('percentage-formatter',{attrs:{"value":item.splitPercent}})]}},{key:`item.commission`,fn:function({ item }){return [_c('currency-formatter',{attrs:{"value":item.commission}}),_c('span',{staticClass:"grey--text"},[_vm._v(" ("),_c('percentage-formatter',{attrs:{"value":item.additional.percent}}),_vm._v(") ")],1)]}},{key:`item.policyNumber`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"accent","label":"","to":_vm.getRouterLink(
              'Case',
              item.additional.commission.appointment_case.case_id
            )}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("$mdi-briefcase")]),_vm._v(" "+_vm._s(item.policyNumber)+" ")],1)]}}],null,true)},[_c('span',[_vm._v("View Case")])])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }