<template>
  <v-card flat tile color="section">
    <v-card-text class="pa-0">
      <v-timeline>
        <v-timeline-item
          v-for="(item, index) in timeline"
          data-testid="timeline-item"
          :class="{ 'text-right': index % 2 === 1 }"
          :key="`step-${index}`"
          :icon="icon(item)"
        >
          <v-col>
            <h3>{{ item.description }}</h3>
            <h5>
              <timestamp-formatter :value="item.date" parser="sole-day" />
            </h5>
          </v-col>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>
<script>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import cloneDeep from "lodash/cloneDeep";
import sortBy from "lodash/sortBy";
export default {
  name: "TimelineViewer",
  components: { TimestampFormatter },
  props: {
    items: Array,
    expandable: Boolean
  },
  data() {
    return {
      rawTimeline: sortBy(cloneDeep(this.items), "date"),
      expanded: false
    };
  },
  computed: {
    timeline() {
      if (this.expandable && !this.expanded) return this.rawTimeline.slice(-1);
      return this.rawTimeline.filter(val => val.date);
    }
  },
  methods: {
    icon(item) {
      return item.date ? "$mdi-check" : "";
    }
  }
};
</script>
