<template>
  <v-card
    outlined
    class="my-1"
    :style="missingData ? 'border: 1px solid #ff5252' : ''"
    @click="$emit('edit')"
  >
    <v-row class="ma-0 pa-3" align="center">
      <template v-if="missingData">
        <strong class="error--text" data-testid="missing-address-data">
          Missing Address Data
        </strong>
        <span class="error--text pl-1"> Please Click to Edit</span>
      </template>
      <template v-else>
        <strong data-testid="address-text">
          {{ address.street_address }}, {{ address.city }},
          {{ address.state }}
          {{ address.zip }}
        </strong>
      </template>
      <v-spacer />

      <div v-if="showDates && !missingDateData" class="mr-1">
        <timestamp-formatter :value="address.startDate" parser="sole-day" />
        <template v-if="address.endDate">
          - <timestamp-formatter :value="address.endDate" parser="sole-day" />
        </template>
        <template v-else> - Present </template>
      </div>

      <div v-if="showDates && missingDateData" class="mr-1 error--text">
        <strong> Missing Address Dates </strong>
      </div>

      <div v-if="!readonly">
        <v-btn icon @click.stop="$emit('edit')" color="success" class="ml-1">
          <v-icon>$mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-if="deletable"
          icon
          color="error"
          data-testid="delete-item"
          @click.stop="$emit('delete')"
        >
          <v-icon>$mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-row>
  </v-card>
</template>

<script>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
export default {
  components: { TimestampFormatter },
  props: {
    address: {
      type: Object,
      required: true
    },
    showDates: Boolean,
    deletable: Boolean,
    readonly: Boolean
  },
  computed: {
    missingData() {
      const { street_address, city, state, zip } = this.address;
      return !(street_address && city && state && zip);
    },
    missingDateData() {
      if (!this.showDates) return false;
      const { startDate } = this.address;
      return !startDate;
    }
  }
};
</script>
