<template>
  <v-row class="ma-0">
    <card-item
      v-for="detail in cardDetails"
      :model="party"
      :data-testid="`party-card-${detail.title}`"
      :key="detail.title"
      :item="detail"
    />
  </v-row>
</template>
<script>
import capitalize from "lodash/capitalize";
import CardItem from "@/components/parties/PartyCardItem.vue";
import { getRouterLink } from "@/util/helpers";
export default {
  components: {
    CardItem
  },
  props: {
    party: Object,
    type: String
  },
  computed: {
    cardDetails() {
      let items = [
        {
          title: "Name",
          value: this.party.name
        },
        {
          title: "Signer Name",
          value: this.party.responsibleIndividual?.name,
          to: getRouterLink("Individual", this.party.responsibleIndividual?.id)
        },
        {
          title: "Primary Phone",
          value: this.party.phoneWork
        },
        {
          title: "Email",
          value: this.party.email
        },
        {
          title: "Formation Date",
          value: this.party.formationDate,
          filter: "parseSoleDay"
        },
        {
          title: "TIN",
          value: this.party.tin,
          filter: "tin"
        },
        {
          title: "Business Address",
          value: this.party.addresses?.business
        }
      ];

      if (this.type.toLowerCase() === "individual") {
        let occupationDuration = this.party.occupation?.duration;
        if (![null, undefined].includes(occupationDuration)) {
          occupationDuration += " Year";
          if (occupationDuration !== 1) occupationDuration += "s";
        }

        items = [
          {
            title: "Name",
            value: this.party.name
          },
          {
            title: "eApps",
            value: this.party.electronicApplicationAmount
          },
          {
            title: "Primary Phone",
            value: this.party.primaryPhone
          },
          {
            title: "Email",
            value: this.party.email
          },
          {
            title: "Gender",
            value: this.party.gender
          },
          {
            title: "Birthdate",
            value: this.party.birthdate,
            filter: "parseSoleDay"
          },
          {
            title: "SSN",
            value: this.party.partyId,
            filter: "ssn"
          },
          {
            title: "Home Address",
            value: this.party.addresses?.home
          },
          {
            title: "Business Address",
            value: this.party.addresses?.business
          },
          {
            title: "Income",
            value: this.party.income,
            filter: "currency"
          },

          {
            title: "Assets",
            value: this.party.assets,
            filter: "currency"
          },
          {
            title: "Liabilities",
            value: this.party.liabilities,
            filter: "currency"
          },
          {
            title: "Occupation",
            value: [
              capitalize(this.party.occupation?.occupation),
              this.party.occupation?.employer,
              occupationDuration
            ]
              .filter(Boolean)
              .join(", ")
          },
          {
            title: "Visa Type",
            value: this.party.visaType
          },
          {
            title: "US Entry Date",
            value: this.party.usEntryDate,
            filter: "parseSoleDay"
          }
        ];
      }

      return items.filter(val => Boolean(val.value) || val.value === 0);
    }
  }
};
</script>
