import DocumentFactory, {
  setDocumentFromRequest
} from "@/factories/DocumentFactory";

/**
 * @deprecated
 */
export default function NoteFactory(model = {}) {
  return {
    advisorAccess: model?.advisorAccess || null,
    carrierAccess: model?.carrierAccess || null,
    carrierEmail: model?.carrierEmail || null,
    createdAt: model?.createdAt || null,
    date: model?.date || null,
    documents: model?.documents || [],
    email: model?.email || null,
    id: model?.id || null,
    html: model?.html || null,
    note: model?.note || null,
    notableId: model?.notableId || null,
    notableType: model?.notableType || null,
    ownable: model?.ownable || null,
    phoneCall: model?.phoneCall || null,
    isTemplate: model?.isTemplate || false,
    copyToRelated: model?.copyToRelated || false,
    inboundEmail: model?.inboundEmail || "",
    fullEmail: model?.fullEmail || false,

    setFromRequest(model = {}) {
      this.advisorAccess = model?.advisor_access;
      this.carrierAccess = model?.carrier_access;
      this.carrierEmail = model?.carrier_email;
      this.createdAt = model?.created_at;
      this.date = model?.date;
      this.documents = model?.documents?.map(rawDocument => {
        const document = new DocumentFactory();
        document.setFromRequest(rawDocument);
        return document;
      });
      this.email = model?.email;
      this.id = model?.id;
      if (model?.is_html_safe) {
        this.html = model?.note;
      } else {
        this.note = model?.note;
      }
      this.notableId = model?.notableId;
      this.notableType = model?.notableType;
      this.ownable = model?.ownable;
      this.phoneCall = model?.phone_call;
      this.isTemplate = model?.is_template;
      this.copyToRelated = model?.copy_to_related;
      this.inboundEmail = model?.inbound?.via;
      this.fullEmail = Boolean(model?.full_email);
    },

    toCreateRequest(includeCarrierAdvisorAccess = false) {
      const baseRequest = {
        email: +this.email,
        phone_call: Boolean(this.phoneCall),
        notable_id: this.notableId,
        notable_type: this.notableType,
        note: this.note,
        is_template: this.isTemplate,
        copy_to_related: this.copyToRelated,
        carrier_email: Boolean(this.carrierEmail)
      };

      if (this.documents?.length) {
        baseRequest.documents_attributes = this.documents;
      }

      if (includeCarrierAdvisorAccess) {
        baseRequest.advisor_access = +this.advisorAccess;
        baseRequest.carrier_access = +this.carrierAccess;
      }

      return baseRequest;
    }
  };
}

export function Note(model = {}) {
  return {
    advisorAccess: model?.advisorAccess || null,
    carrierAccess: model?.carrierAccess || null,
    carrierEmail: model?.carrierEmail || null,
    createdAt: model?.createdAt || null,
    date: model?.date || null,
    documents: model?.documents || [],
    email: model?.email || null,
    id: model?.id || null,
    html: model?.html || null,
    note: model?.note || null,
    notableId: model?.notableId || null,
    notableType: model?.notableType || null,
    ownable: model?.ownable || null,
    phoneCall: model?.phoneCall || null,
    isTemplate: model?.isTemplate || false,
    copyToRelated: model?.copyToRelated || false,
    inboundEmail: model?.inboundEmail || "",
    fullEmail: model?.fullEmail || false
  };
}

export function setNoteFromRequest(model = {}) {
  const note = Note();
  note.advisorAccess = model?.advisor_access;
  note.carrierAccess = model?.carrier_access;
  note.carrierEmail = model?.carrier_email;
  note.createdAt = model?.created_at;
  note.fullEmail = Boolean(model?.full_email);
  note.date = model?.date;
  note.documents = model?.documents?.map(rawDocument => {
    return setDocumentFromRequest(rawDocument);
  });
  note.email = model?.email;
  note.id = model?.id;
  if (model?.is_html_safe) {
    note.html = model?.note;
  } else {
    note.note = model?.note;
  }
  note.notableId = model?.notableId;
  note.notableType = model?.notableType;
  note.phoneCall = model?.phone_call;
  note.isTemplate = model?.is_template;
  note.copyToRelated = model?.copy_to_related;
  note.inboundEmail = model?.inbound?.via;

  if (model?.ownable) {
    note.ownable = {
      id: model.ownable.id,
      name: model.ownable.name,
      type: model.ownable.type?.model || model.ownable.type,
      avatarUrl: model.ownable.avatar_url
    };
  }
  return note;
}

export function NoteToCreateRequest(note, includeCarrierAdvisorAccess = false) {
  const baseRequest = {
    email: +note.email,
    phone_call: Boolean(note.phoneCall),
    notable_id: note.notableId,
    notable_type: note.notableType,
    note: note.note,
    is_template: note.isTemplate,
    copy_to_related: note.copyToRelated,
    carrier_email: Boolean(note.carrierEmail)
  };

  if (note.documents?.length) {
    baseRequest.documents_attributes = note.documents;
  }

  if (includeCarrierAdvisorAccess) {
    baseRequest.advisor_access = +note.advisorAccess;
    baseRequest.carrier_access = +note.carrierAccess;
  }

  return baseRequest;
}
