var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-img',{attrs:{"width":"150","height":"60","contain":"","src":_vm.$vuetify.theme.dark ? _setup.darkAvatar : _setup.lightAvatar}})],1),_c('v-col',{attrs:{"data-testid":"billable","cols":"12","md":"6"}},[_c('h2',[_vm._v(_vm._s(_setup.billable.name))]),_c(_setup.CardItemA,{attrs:{"text":_setup.billable.phone_work,"data-outbound-type":_setup.billable.type,"data-outbound-id":_setup.billable.id,"data-outbound-number":_setup.billable.phone_work,"href":`tel:${_setup.billable.phone_work}`}})],1),_c('v-col',{staticClass:"align-items-start",class:{
          'text-right': _vm.$vuetify.breakpoint.mdAndUp,
          'text-left': !_vm.$vuetify.breakpoint.smAndDown
        },attrs:{"cols":"12","md":"6","data-testid":"billable-address"}},[_c('div',{staticClass:"white-space-pre-wrap"},[_vm._v(" "+_vm._s(_setup.addressToText(_setup.billable.primary_address))+" ")])])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"9"}},[_c('v-row',{staticClass:"ma-0"},_vm._l((_setup.items),function(item){return _c('v-col',{key:item.key,attrs:{"cols":"12","md":"4","sm":"6","data-testid":item.key}},[_c(item.component,_vm._b({tag:"component"},'component',item.componentProps,false))],1)}),1)],1),_c('v-col',{attrs:{"data-testid":"amount-due","cols":"12","md":"3","align":"end"}},[_c('h4',{staticClass:"section-header"},[_vm._v("Amount Due")]),_c('h1',{class:{
            'error--text': _setup.paid_amount < 0,
            'success--text': _setup.paid_amount > 0
          }},[_c(_setup.CurrencyFormatter,{attrs:{"value":_setup.paid_amount}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }