<template>
  <v-card>
    <v-card-title>Add Product Citizenship Profile</v-card-title>
    <v-card-text>
      <v-select
        prepend-inner-icon="$mdi-cube"
        v-model="productCitizenshipProfile.citizenshipProfile"
        outlined
        dense
        label="Citizenship Profile"
        return-object
        :items="citizenshipProfiles"
        :success="citizenshipProfileValidation.success"
        :error-messages="citizenshipProfileValidation.errorMessages"
        item-text="name"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        color="grey"
        class="text-none"
        @click="closeDialog"
        :disabled="loading"
      >
        Close
      </v-btn>
      <v-btn class="text-none" color="primary" @click="save" :loading="loading">
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { ProductCitizenshipProfile } from "@/factories/ProductFactory";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { addProductCitizenshipProfile } from "@/api/product-citizenship-profile.service";
import { getAllCitizenshipProfiles } from "@/api/citizenship-profile.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  props: {
    product: Object
  },
  data() {
    return {
      citizenshipProfiles: [],
      productCitizenshipProfile: new ProductCitizenshipProfile(),
      loading: false
    };
  },
  computed: {
    citizenshipProfileValidation() {
      const model = this.$v.productCitizenshipProfile.citizenshipProfile.id;
      const success = !model.$invalid;
      const errorMessages = [];
      if (!model.$dirty) return { success, errorMessages };
      else if (!model.required) errorMessages.push("Required");
      return { success, errorMessages };
    }
  },
  mounted() {
    this.getCitizenshipProfiles();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid || this.loading) return;
      this.loading = true;
      try {
        const body = this.productCitizenshipProfile.toCreateRequest(
          this.product.id
        );
        const id = await addProductCitizenshipProfile(body);
        this.productCitizenshipProfile.id = id;
        this.closeDialog({
          productCitizenshipProfile: this.productCitizenshipProfile
        });
      } catch (error) {
        this.showErrorSnackbar({
          message: `Unable to create citizenship profile: ${parseErrorMessage(
            error
          )}`,
          timeout: -1
        });
      } finally {
        this.loading = false;
      }
    },
    async getCitizenshipProfiles() {
      try {
        this.citizenshipProfiles = await getAllCitizenshipProfiles();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      }
    }
  },
  validations: {
    productCitizenshipProfile: {
      citizenshipProfile: { id: { required } }
    }
  }
};
</script>
