<template>
  <v-row>
    <v-col cols="12">
      <v-row class="ma-0">
        <v-col cols="12" align="center">
          <v-img
            width="150"
            height="60"
            contain
            :src="$vuetify.theme.dark ? darkAvatar : lightAvatar"
          />
        </v-col>
        <v-col data-testid="billable" cols="12" md="6">
          <h2>{{ billable.name }}</h2>

          <card-item-a
            :text="billable.phone_work"
            :data-outbound-type="billable.type"
            :data-outbound-id="billable.id"
            :data-outbound-number="billable.phone_work"
            :href="`tel:${billable.phone_work}`"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="align-items-start"
          data-testid="billable-address"
          :class="{
            'text-right': $vuetify.breakpoint.mdAndUp,
            'text-left': !$vuetify.breakpoint.smAndDown
          }"
        >
          <div class="white-space-pre-wrap">
            {{ addressToText(billable.primary_address) }}
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col cols="12">
      <v-row class="ma-0">
        <v-col cols="12" md="9" class="pa-0">
          <v-row class="ma-0">
            <v-col
              v-for="item in items"
              cols="12"
              md="4"
              sm="6"
              :key="item.key"
              :data-testid="item.key"
            >
              <component :is="item.component" v-bind="item.componentProps" />
            </v-col>
          </v-row>
        </v-col>
        <v-col data-testid="amount-due" cols="12" md="3" align="end">
          <h4 class="section-header">Amount Due</h4>
          <h1
            :class="{
              'error--text': paid_amount < 0,
              'success--text': paid_amount > 0
            }"
          >
            <currency-formatter :value="paid_amount" />
          </h1>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import CardItemA from "@/components/shared/card-items/CardItemA.vue";
import CardItemText from "@/components/shared/card-items/CardItemText.vue";
import { storeToRefs } from "pinia";

import { usePayPeriodViewStore } from "@/stores/pay-period-view";
import { computed, markRaw } from "vue";

const payPeriodView = usePayPeriodViewStore();

const { paid_date_value, id, eft, ownable, billable, paid_amount } =
  storeToRefs(payPeriodView);

const darkAvatar = require("@/assets/img/Logo-Dark.png");
const lightAvatar = require("@/assets/img/Logo-Light.png");

function addressToText(address) {
  return `${address.street_address}\n${address.city}, ${address.state} ${address.zip}`;
}

const items = computed(() => {
  const cardItems = [];
  const textComponent = markRaw(CardItemText);
  cardItems.push({
    key: "advisor",
    component: textComponent,
    componentProps: {
      title: "Advisor",
      text: ownable.value.name
    }
  });

  cardItems.push({
    key: "payment-type",
    component: textComponent,
    componentProps: {
      title: "Payment Type",
      text: eft.value.paying_to
    }
  });

  cardItems.push({
    key: "statement-number",
    component: textComponent,
    componentProps: {
      title: "Statement Number",
      text: `#${id.value}`
    }
  });

  cardItems.push({
    key: "owner-address",
    component: textComponent,
    componentProps: {
      title: `${ownable.value.name}'s Address`,
      text: addressToText(ownable.value.primary_address)
    }
  });

  cardItems.push({
    key: "payment-date",
    component: textComponent,
    componentProps: {
      title: "Payment Date",
      text: paid_date_value.value
    }
  });

  return cardItems;
});
</script>
