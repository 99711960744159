import { ChatTemplate } from "@/factories/ChatTemplate";
import { setDocumentFromRequest } from "@/factories/DocumentFactory";
import { setNoteFromRequest } from "@/factories/NoteFactory";
import { setRawFromAddress, Address } from "@/factories/AddressFactory";

export function Task(model = {}) {
  return {
    id: model?.id || null,
    type: model?.type || null,
    status: model?.status || null,
    description: model?.description || null,
    date: model?.date || null,
    assigned: TaskAdvisor(model?.assigned),
    ownable: TaskAdvisor(model?.ownable),
    documents: model?.documents || [],
    hidden: model?.hidden || false,
    email: model?.email || false,
    notes: model?.notes || [],
    followUpDate: model?.followUpDate || null,
    createdAt: model?.createdAt || null,
    updatedAt: model?.updatedAt || null,
    completedAt: model?.completedAt || null
  };
}

export function setTaskFromRequest(raw = {}) {
  const task = Task();
  task.id = raw?.id;
  task.type = raw?.type;
  task.status = raw?.status;
  task.createdAt = raw?.created_at;
  task.updatedAt = raw?.updated_at;
  task.completedAt = raw?.completed_at;
  task.description = raw?.description || raw?.text; // text is used for tasks?
  task.email = raw?.email;
  task.assigned = setTaskAdvisorFromRequest(raw?.assigned);
  task.ownable = setTaskAdvisorFromRequest(raw?.ownable);
  task.documents = [];
  raw?.documents?.forEach(d => {
    task.documents.push(setDocumentFromRequest(d));
  });

  task.notes = [];
  raw?.notes?.forEach(n => {
    task.notes.push(setNoteFromRequest(n));
  });

  task.hidden = raw?.hidden;
  task.email = Boolean(raw?.email);
  task.followUpDate = raw?.follow_up_date;

  return task;
}

export function Exam(model = {}) {
  return {
    id: model?.id,
    type: model?.type,
    status: model?.status,
    date: model?.date,
    ownable: TaskAdvisor(model?.ownable),
    documents: model?.documents || [],
    assigned: TaskAdvisor(model?.assigned),
    orderId: model?.orderId,
    orderNumber: model?.orderNumber,
    notes: model?.notes || [],
    description: model?.description,
    createdAt: model?.createdAt,
    completedAt: model?.completedAt,
    updatedAt: model?.updatedAt,
    email: model?.email,
    followUpDate: model?.followUpDate,
    vendor: model?.vendor || null
  };
}

export function setExamFromRequest(raw = {}) {
  const exam = Exam();
  exam.id = raw?.id;
  exam.type = raw?.type;
  exam.status = raw?.status;
  exam.date = raw?.date;
  exam.ownable = setTaskAdvisorFromRequest(raw?.ownable);
  exam.assigned = setTaskAdvisorFromRequest(raw?.assigned);
  exam.orderId = raw?.order?.id;
  exam.orderNumber = raw?.order_number;
  exam.description = raw?.description;
  exam.createdAt = raw?.created_at;
  exam.updatedAt = raw?.updated_at;
  exam.completedAt = raw?.completed_at;
  exam.email = Boolean(raw?.email);
  exam.followUpDate = raw?.follow_up_date;

  exam.documents = [];
  raw?.documents?.forEach(doc => {
    exam.documents.push(setDocumentFromRequest(doc));
  });

  exam.notes = [];
  raw?.notes?.forEach(note => {
    exam.notes.push(setNoteFromRequest(note));
  });

  if (raw?.vendor) {
    exam.vendor = {
      id: raw.vendor.id,
      name: raw.vendor.name,
      type: raw.vendor.type
    };
  }

  return exam;
}

export function PhysicianStatement(model = {}) {
  return {
    id: model?.id,
    type: model?.type,
    status: model?.status,
    description: model?.description,
    date: model?.date,
    ownable: TaskAdvisor(model?.ownable),
    assigned: TaskAdvisor(model?.assigned),
    orderNumber: model?.orderNumber,
    orderId: model?.orderId,
    createdAt: model?.createdAt,
    updatedAt: model?.updatedAt,
    completedAt: model?.completedAt,
    email: Boolean(model?.email),
    followUpDate: model?.followUpDate,
    documents: model?.documents || [],
    notes: model?.notes || [],
    vendor: model?.vendor || null
  };
}

export function setPhysicianStatementFromRequest(raw = {}) {
  const physicianStatment = PhysicianStatement();
  physicianStatment.id = raw?.id;
  physicianStatment.type = raw?.type;
  physicianStatment.status = raw?.status;
  physicianStatment.description = raw?.description;
  physicianStatment.date = raw?.date;
  physicianStatment.ownable = setTaskAdvisorFromRequest(raw?.ownable);
  physicianStatment.assigned = setTaskAdvisorFromRequest(raw?.assigned);
  physicianStatment.createdAt = raw?.created_at;
  physicianStatment.updatedAt = raw?.updated_at;
  physicianStatment.completedAt = raw?.completed_at;
  physicianStatment.email = raw?.email;
  physicianStatment.followUpDate = raw?.follow_up_date;
  physicianStatment.orderId = raw?.order?.id;
  physicianStatment.orderNumber = raw?.order_number;

  physicianStatment.documents = [];
  raw?.documents?.forEach(doc => {
    physicianStatment.documents.push(setDocumentFromRequest(doc));
  });

  physicianStatment.notes = [];
  raw?.notes?.forEach(note => {
    physicianStatment.notes.push(setNoteFromRequest(note));
  });

  if (raw?.vendor) {
    physicianStatment.vendor = {
      id: raw.vendor.id,
      name: raw.vendor.name,
      type: raw.vendor.type
    };
  }

  return physicianStatment;
}

export function TaskAdvisor(model = {}) {
  return {
    id: model?.id,
    type: model?.type,
    name: model?.name,
    avatarUrl: model?.avatarUrl
  };
}

export function setTaskAdvisorFromRequest(raw = {}) {
  const advisor = TaskAdvisor();
  advisor.id = raw?.id;
  advisor.type = raw?.type;
  if (!("type" in raw)) advisor.type = "Agent";
  advisor.name = raw?.name;
  advisor.avatarUrl = raw?.avatar_url;
  return advisor;
}

// Use these models when creating new requirements

export function NewTask(model = {}) {
  return {
    caseId: model?.caseId,
    template: ChatTemplate(model?.template),
    hidden: Boolean(model?.hidden),
    copyDocumentsToCase: Boolean(model?.copyDocumentsToCase),
    description: model?.description || "",
    deliveryRequirement: Boolean(model?.deliveryRequirement),
    dueDate: model?.dueDate || "",
    outstanding:
      model?.outstanding === undefined ? true : Boolean(model?.outstanding),
    assignee: model?.assignee,
    relatedDocuments: model?.relatedDocuments || [],
    sendEmail:
      model?.sendEmail === undefined ? true : Boolean(model?.sendEmail),
    contractParty: model?.contractParty
  };
}

export function NewExam(model = {}) {
  return {
    caseId: model?.caseId,
    template: ChatTemplate(model?.template),
    copyDocumentsToCase: Boolean(model?.copyDocumentsToCase),
    description: model?.description || "",
    orderNumber: model?.orderNumber || "",
    outstanding:
      model?.outstanding === undefined ? true : Boolean(model?.outstanding),
    assignee: model?.assignee,
    relatedDocuments: model?.relatedDocuments || [],
    sendEmail:
      model?.sendEmail === undefined ? true : Boolean(model?.sendEmail),
    contractParty: model?.contractParty,
    vendor: model?.vendor
  };
}

export function NewPhysicianStatement(model = {}) {
  return {
    caseId: model?.caseId,
    template: ChatTemplate(model?.template),
    copyDocumentsToCase: Boolean(model?.copyDocumentsToCase),
    sendHumanApi:
      model?.sendHumanApi === undefined ? true : Boolean(model?.sendHumanApi),
    doctor: model?.doctor,
    orderNumber: model?.orderNumber,
    outstanding:
      model?.outstanding === undefined ? true : Boolean(model?.outstanding),
    assignee: model?.assignee,
    relatedDocuments: model?.relatedDocuments || [],
    sendEmail:
      model?.sendEmail === undefined ? true : Boolean(model?.sendEmail),
    contractParty: model?.contractParty,
    doctorPhone: model?.doctorPhone || "",
    doctorAddress: Address(model?.doctorAddress),
    vendor: model?.vendor || null
  };
}

export function NewExamToCreateRequest(model) {
  return {
    order: {
      case_id: model.caseId,
      email: +model.sendEmail,
      documents: model.relatedDocuments,
      copy_documents_to_case: Boolean(model.copyDocumentsToCase),
      assignable_id: model.assignee?.id,
      assignable_type: model.assignee?.type,
      vendor_id: model.vendor?.id,
      orderable_attributes: {
        description: model.description,
        contract_party_id: model.contractParty?.id,
        order_number: model.orderNumber,
        orderable_type: "Exam",
        status: model.outstanding ? "Outstanding" : "Completed"
      }
    }
  };
}

export function NewPhysicianStatementToCreateRequest(model) {
  return {
    order: {
      case_id: model.caseId,
      email: +model.sendEmail,
      human_api: model.sendHumanApi,
      documents: model.relatedDocuments,
      copy_documents_to_case: Boolean(model.copyDocumentsToCase),
      assignable_id: model.assignee?.id,
      assignable_type: model.assignee?.type,
      vendor_id: model.vendor?.id,
      orderable_attributes: {
        contract_party_id: model.contractParty?.id,
        doctor: model.doctor,
        order_number: model.orderNumber,
        orderable_type: "PhysicianStatement",
        status: model.outstanding ? "Outstanding" : "Completed"
      }
    }
  };
}

export function NewParamedsToCreateRequest(model) {
  return {
    order: {
      case_id: model.caseId,
      email: +model.sendEmail,
      human_api: model.sendHumanApi,
      documents: model.relatedDocuments,
      copy_documents_to_case: Boolean(model.copyDocumentsToCase),
      assignable_id: model.assignee?.id,
      assignable_type: model.assignee?.type,
      vendor_id: model.vendor?.id,
      orderable_attributes: {
        contract_party_id: model.contractParty?.id,
        doctor: model.doctor,
        doctor_phone: model.doctorPhone,
        doctor_address: setRawFromAddress(model.doctorAddress),
        orderable_type: "PhysicianStatement",
        status: model.outstanding ? "Outstanding" : "Completed"
      }
    }
  };
}

export function NewInstantApsToCreateRequest(model) {
  return {
    case_id: model.caseId,
    hidden: model.hidden,
    copy_documents_to_case: Boolean(model.copyDocumentsToCase),
    doctor: model.doctor,
    contract_party_id: model.contractParty?.id,
    status: "Outstanding"
  };
}

export function NewTaskToCreateRequest(model) {
  return {
    task: {
      case_id: model.caseId,
      hidden: model.hidden,
      assignable_id: model.assignee?.id,
      assignable_type: model.assignee?.type,
      delivery_requirement: +model.deliveryRequirement,
      description: model.description,
      email: +model.sendEmail,
      status: model.outstanding ? "Outstanding" : "Completed",
      documents_attributes: model.relatedDocuments,
      follow_up_date: model.dueDate,
      copy_documents_to_case: Boolean(model.copyDocumentsToCase)
    }
  };
}
