<template>
  <v-list-item
    link
    :class="{ 'v-list-item--active': active }"
    @click="emit('click')"
  >
    <v-list-item-avatar>
      <v-img :src="avatar" contain />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ text }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script setup>
import { defineProps, defineEmits, toRefs } from "vue";
const props = defineProps({
  active: Boolean,
  avatar: { type: String, required: true },
  title: { type: String, required: true },
  text: { type: String, required: true }
});

const emit = defineEmits(["click"]);

const { active, avatar, title, text } = toRefs(props);
</script>
