<template>
  <v-autocomplete
    v-model="model"
    prepend-inner-icon="$mdi-shape"
    outlined
    dense
    :hide-details="hideDetails"
    :clearable="clearable"
    :loading="formCategories.fetchingFormCategories"
    :label="label"
    :disabled="readonly"
    :items="formCategories.items"
    :success="success"
    :error-messages="errorMessages"
  >
    <template v-if="$slots['append-outer']" #append-outer>
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useSearchItemsCache } from "@/stores/search-items-cache";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  props: {
    full: {
      type: [Object, String],
      default: () => {}
    },
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean
    },
    clearable: Boolean,
    hideDetails: Boolean,
    success: {
      type: Boolean
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    let model = this.value;
    if (this.full?.value) model = this.full.value;
    return { model };
  },
  watch: {
    model() {
      this.$emit("input", this.model);
      let full = this.model;
      if (full)
        full = this.formCategories.items.find(({ value }) => value === full);
      this.$emit("update:full", full);
    },
    full() {
      if (this.model === this.full?.value) return;
      this.model = this.full?.value;
    },
    value() {
      if (this.model === this.value) return;
      this.model = this.value;
    }
  },
  created() {
    this.getFormCategories();
  },
  computed: {
    ...mapState(useSearchItemsCache, ["formCategories"])
  },
  methods: {
    ...mapActions(useSearchItemsCache, ["getFormCategoriesList"]),
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async getFormCategories() {
      try {
        await this.getFormCategoriesList();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      }
    },
    clear() {
      this.model = null;
    }
  }
};
</script>
