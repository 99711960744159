import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { onBeforeUnmount, watch } from "vue";
import { useVuetify } from "@/composables/compatible.composables";
import isHoliday, { HOLIDAYS } from "@/util/is-holiday";

export const useThemeManager = () => {
  const user = useUserStore();
  const { darkMode, id } = storeToRefs(user);

  const vuetify = useVuetify();

  function handleDarkModeChange() {
    if (darkMode.value === null) initAutoDarkMode();
    else destroyAutoDarkMode();
    processDarkModeSelection();
  }

  function initHolidayColors() {
    const holiday = isHoliday();
    if (holiday === HOLIDAYS.VALENTINES) {
      this.$vuetify.theme.themes.light.primary = "#e6a6be";
      this.$vuetify.theme.themes.dark.primary = "#e6a6be";
    }
  }

  function processDarkModeSelection() {
    let dark = darkMode.value;
    if (dark === null) {
      dark = Boolean(
        window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
      );
    }

    vuetify.theme.dark = dark;
    vuetify.theme.light = !dark;
  }
  function initAutoDarkMode() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", processDarkModeSelection);
  }
  function destroyAutoDarkMode() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .removeEventListener("change", processDarkModeSelection);
  }

  onBeforeUnmount(destroyAutoDarkMode);

  watch(darkMode, handleDarkModeChange);
  watch(id, () => {
    handleDarkModeChange();
    processDarkModeSelection();
    initHolidayColors();
  });
};
