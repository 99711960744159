<template>
  <v-card flat color="section">
    <v-card-title> {{ title }} </v-card-title>
    <v-card-subtitle>
      <slot />
    </v-card-subtitle>
    <v-card-text
      v-if="isExpired || training.id"
      :data-testid="`${title}-status-text`"
    >
      <template v-if="isExpired">
        Your {{ title }} has expired. Please upload a new one.
      </template>

      <template v-else-if="training.id">
        {{ currentText }}
      </template>
    </v-card-text>
    <v-card-actions class="mx-1 flex-wrap">
      <v-btn
        class="text-none ma-1"
        color="accent"
        :data-testid="`${title}-create`"
        :outlined="Boolean(training.id)"
        @click="emit('create')"
      >
        <v-icon> $mdi-text-box-plus </v-icon> Upload {{ title }}
      </v-btn>
      <template v-if="training.id">
        <v-btn
          color="primary"
          outlined
          class="text-none ma-1"
          :data-testid="`${title}-download`"
          v-bind="download"
        >
          <v-icon>$mdi-download</v-icon> Download {{ title }}
        </v-btn>
        <v-btn
          color="error"
          outlined
          class="text-none ma-1"
          :data-testid="`${title}-delete`"
          @click="emit('delete')"
        >
          <v-icon>$mdi-delete</v-icon> Delete {{ title }}
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import { getDocumentUrl } from "@/api/documents.service";
import { downloadFileAsLink, isMobile } from "@/util/helpers";
import differenceInMonths from "date-fns/differenceInMonths";
import addMonths from "date-fns/addMonths";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { toRef, defineProps, computed, defineEmits, watch } from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  training: {
    type: Object,
    default: () => {}
  },
  expiresInMonths: {
    type: Number,
    default: null
  }
});

const emit = defineEmits(["create", "delete", "valid"]);

const title = toRef(props, "title");
const training = toRef(props, "training");
const expiresInMonths = toRef(props, "expiresInMonths");

const currentDate = computed(() => {
  if (!training.value.completedAt) return null;
  return parse(training.value.completedAt, "yyyy-MM-dd", new Date());
});

const isExpired = computed(() => {
  if (!currentDate.value || !expiresInMonths.value) return null;
  return (
    differenceInMonths(new Date(), currentDate.value) >= expiresInMonths.value
  );
});

watch(isExpired, () => emitValid());

function emitValid() {
  emit("valid", isExpired.value !== true);
}
emitValid();

const completedAt = computed(() => {
  if (!currentDate.value) return;
  return format(currentDate.value, "MM/dd/yyyy");
});
const expiresAt = computed(() => {
  if (!currentDate.value || !expiresInMonths.value) return;
  const expirationDate = addMonths(currentDate.value, expiresInMonths.value);
  return format(expirationDate, "MM/dd/yyyy");
});

const currentText = computed(() => {
  let text = `Your ${title.value} course "${training.value.courseName}" from ${training.value.providerName} was completed on ${completedAt.value}`;
  if (expiresAt.value) text += ` and will expire ${expiresAt.value}`;
  return `${text}.`;
});

const download = computed(() =>
  downloadFileAsLink(getDocumentUrl(training.value.documentUid), isMobile())
);
</script>
