import Vue from "vue";

import AddressFactory from "@/factories/AddressFactory";
import { ErrorsOmissions } from "@/factories/EoFactory";
import { AgentSettings, Training } from "@/factories/AgentSettingsFactory";
import { getCommunicationPreferenceRequest } from "@/factories/CommunicationPreferencesFactory";
import { setRawFromCommissionsSchedules } from "@/factories/CommissionSchedules";

import { serializeObject, soleDateSort } from "@/util/helpers";

import differenceInYears from "date-fns/differenceInYears";
import parse from "date-fns/parse";

import { useUserStore } from "@/stores/user";
import { defineStore } from "pinia";

import {
  createExplanation,
  updateExplanation,
  deleteExplanation
} from "@/api/explanations.service";
import { setActiveEft, createNewEft } from "@/api/eft.service";
import {
  updateAgent,
  getAgentSettings,
  deleteCommissionSplit,
  createCommissionSplit,
  updateCommissionSplit,
  updateAgentCommunicationPreferences,
  getAgentExternalResponders,
  addAgentExternalResponder,
  deleteAgentExternalResponder,
  deleteAddressRecord,
  updateAddressRecord,
  createAddressRecord,
  deleteAgent
} from "@/api/agents.service";
import { createLogin, destroyLogin } from "@/api/logins.service";
import { deleteConnection } from "@/api/connections.service";
import { deleteEo } from "@/api/eos.service";
import { updateMarketingManager } from "@/api/marketing-managers.service";
import {
  addDocumentToAnswer,
  removeDocumentFromAnswer
} from "@/api/contracting-answers.service";
import { deleteDocument } from "@/api/documents.service";
import { deleteOccupation } from "@/api/occupations.service";
import { deleteEducation } from "@/api/educations.service";
import { createAgency } from "@/api/agencies.service";

export const AGENT_SETTINGS = "agent";
export const useAgentSettingsStore = defineStore("agent-settings", {
  state: () => AgentSettings(),
  getters: {
    readyForSignature() {
      return Object.keys(this.pageValidation).every(key => {
        if (this.hideCommission && key === "commissions") return true;
        if (key === "signature") return true;
        return this.pageValidation[key];
      });
    },
    missingFiveYearsOfAddresses() {
      if (!this.homeAddressOrder.length) return true;
      if (Object.values(this.homeAddresses).some(v => !v.startDate)) {
        return true;
      }

      const lastOrderIndex = this.homeAddressOrder.length - 1;
      const oldestAddressIndex = this.homeAddressOrder[lastOrderIndex];

      const rawStartDate = this.homeAddresses[oldestAddressIndex].startDate;
      if (!rawStartDate) return true;
      const startDateObj = parse(rawStartDate, "yyyy-MM-dd", new Date());
      const startDateYears = differenceInYears(new Date(), startDateObj);

      return startDateYears < 5;
    },
    missingFiveYearsOfEmployment() {
      if (!this.occupationOrder.length) return true;
      const lastOrderIndex = this.occupationOrder.length - 1;
      const oldestOccupation = this.occupationOrder[lastOrderIndex];

      const rawStartDate = this.occupations[oldestOccupation].startDate;
      const startDateObj = parse(rawStartDate, "yyyy-MM-dd", new Date());
      const startDateYears = differenceInYears(new Date(), startDateObj);

      return startDateYears < 5;
    },
    shippingAddresses() {
      const addresses = [
        this.businessAddress,
        ...Object.values(this.homeAddresses)
      ];
      return addresses.filter(val => val?.id) || [];
    },
    shippingAddress() {
      if (this.businessAddress.primary) return this.businessAddress?.id;
      return this.shippingAddresses.find(val => val.primary)?.id;
    },
    name() {
      return `${this.firstName} ${this.lastName}`;
    },
    assignmentName() {
      return this.assignment?.name;
    },
    splitPercentTotal() {
      const commissionSplits = Object.values(this.commissionSplits);
      const reducer = (accumulator, current) =>
        accumulator + current.splitPercent;
      return commissionSplits.reduce(reducer, 0);
    }
  },
  actions: {
    async getAgentContractingData(id) {
      this.$reset();
      const agent = await getAgentSettings(id);
      this.$patch(agent);

      // Any dictionary type. required for vue 2 reactivity
      Vue.set(this, "credits", agent.credits);
      Vue.set(this, "contractingQuestions", agent.contractingQuestions);
      Vue.set(this, "occupations", agent.occupations);
      Vue.set(this, "efts", agent.efts);
      Vue.set(this, "businessAddress", agent.businessAddress);
      Vue.set(this, "homeAddresses", agent.homeAddresses);
      Vue.set(this, "commissionSplits", agent.commissionSplits);
      Vue.set(this, "connections", agent.connections);
      Vue.set(this, "logins", agent.logins);
      Vue.set(this, "relatedDocuments", agent.relatedDocuments);
      Vue.set(this, "assignment", agent.assignment);
      Vue.set(this, "defaultApprovedDomain", agent.defaultApprovedDomain);
      Vue.set(this, "agencies", agent.agencies);
      Vue.set(this, "primaryLogin", agent.primaryLogin);
    },
    updateShippingAddress(id) {
      Vue.set(
        this.businessAddress,
        "primary",
        +this.businessAddress.id === +id
      );
      Object.keys(this.homeAddresses).forEach(hId => {
        Vue.set(this.homeAddresses[hId], "primary", +id === +hId);
      });
      return updateAddressRecord(this.id, this.shippingAddress, {
        primary: true
      });
    },
    updateAddress(addressId, body) {
      return updateAddressRecord(this.id, addressId, body);
    },
    createAddress(body) {
      return createAddressRecord(this.id, body);
    },
    deleteAddress(id) {
      this.removeAddress(id);
      return deleteAddressRecord(this.id, id);
    },
    async getExternalResponders() {
      const emails = await getAgentExternalResponders(this.id);
      this.trustedSenders.splice(0, this.trustedSenders.length);
      this.trustedSenders.push(...emails);
    },
    deleteExternalResponder(email) {
      return deleteAgentExternalResponder(this.id, email);
    },
    addExternalResponder(email) {
      return addAgentExternalResponder(this.id, email);
    },
    setAssignment(assignment) {
      this.assignment.id = assignment.id;
      this.assignment.name = assignment.name;
      this.assignment.type = assignment.type;
    },
    setValidOccupation({ occupationId, valid }) {
      Vue.set(this.occupations[occupationId], "valid", valid);
    },
    storeQuestionIsValid({ questionId, valid }) {
      Vue.set(this.contractingQuestions[questionId], "valid", valid);
    },
    setValidPage({ page, valid }) {
      Vue.set(this.pageValidation, page, valid);
    },
    addCommissionSplit(commissionSplit) {
      Vue.set(this.commissionSplits, commissionSplit.id, commissionSplit);
    },
    storeSplitPercent({ value, splitId }) {
      Vue.set(this.commissionSplits[splitId], "splitPercent", value);
    },
    removeSplitPercent(splitId) {
      Vue.delete(this.commissionSplits, splitId);
    },
    storeSsn(value) {
      Vue.set(this, "ssn", value);
    },
    storeTitle(value) {
      Vue.set(this, "title", value);
    },
    addNewEft(value) {
      Object.keys(this.efts).forEach(eftId => {
        Vue.set(this.efts[eftId], "current", 0);
      });
      Vue.set(this.efts, value.id, value);
    },
    assignAgentToAgency(model) {
      Vue.set(this.agencies, model.id, model);
      Vue.set(this, "assignment", model);
    },
    addConnection(connection) {
      Vue.set(this.connections, connection.id, connection);
    },
    updateConnection(connection) {
      Vue.set(this.connections, connection.id, connection);
    },
    async deleteAml() {
      await deleteEducation(this.aml.id);
      this.setAml(new Training());
    },
    setAml(newAml) {
      Vue.set(this, "aml", newAml);
    },
    async deleteReg187() {
      await deleteEducation(this.reg187.id);
      this.setReg187(new Training());
    },
    setReg187(reg187) {
      Vue.set(this, "reg187", reg187);
    },
    setHierarchy(newHierarchy) {
      const allConnectionIds = [...Object.keys(this.connections)];
      newHierarchy.forEach((connection, index) => {
        Vue.set(this.connections[connection.id], "hierarchy", 1);
        Vue.set(this.connections[connection.id], "hierarchyOrder", index + 1);
        const connectionIndex = allConnectionIds.findIndex(
          val => +val === +connection.id
        );
        allConnectionIds.splice(connectionIndex, 1);
      });

      allConnectionIds.forEach(connection => {
        Vue.set(this.connections[connection], "hierarchy", 0);
        Vue.set(this.connections[connection], "hierarchyOrder", null);
      });
    },
    removeAddress(id) {
      Vue.delete(this.homeAddresses, id);
      Vue.set(
        this,
        "homeAddressOrder",
        Object.values(this.homeAddresses)
          .sort(soleDateSort)
          .map(val => val.id)
          .reverse()
      );
    },
    addNewAddress(address) {
      Vue.set(this.homeAddresses, address.id, new AddressFactory(address));
      Vue.set(
        this,
        "homeAddressOrder",
        Object.values(this.homeAddresses)
          .sort(soleDateSort)
          .map(val => val.id)
          .reverse()
      );
    },
    storeNpn(npn) {
      this.npn = npn;
    },
    storeBusinessAddress(businessAddress) {
      Vue.set(this, "businessAddress", businessAddress);
    },
    storeShippingAddress(id) {
      if (this.businessAddress.id === id) {
        Object.keys(this.homeAddresses).forEach(key => {
          Vue.set(this.homeAddresses[key], "primary", false);
        });
        Vue.set(this.businessAddress, "primary", true);
        return;
      }
      Vue.set(this.businessAddress, "primary", false);
      Object.keys(this.homeAddresses).forEach(key => {
        Vue.set(this.homeAddresses[key], "primary", +key === +id);
      });
    },
    addNewSchedulingLinkToCredit(credit) {
      this.credits[credit.id].schedulingLinks.push({ url: "", text: "" });
    },
    removeSchedulingLinkFromCredit({ credit, index }) {
      this.credits[credit.id].schedulingLinks.splice(index, 1);
    },
    storeQuestionAnswer({ questionId, value }) {
      this.contractingQuestions[questionId].answer = value;
    },
    addDocument({ questionId, documents }) {
      documents.forEach(document => {
        //Needed for reactivity
        Vue.set(
          this.contractingQuestions[questionId].documents,
          document.uid,
          document
        );
      });
    },
    storeActionDate({ questionId, explanationId, value }) {
      this.contractingQuestions[questionId].explanations[
        explanationId
      ].actionDate = value;
    },
    storeAction({ questionId, explanationId, value }) {
      this.contractingQuestions[questionId].explanations[explanationId].action =
        value;
    },
    storeReason({ questionId, explanationId, value }) {
      this.contractingQuestions[questionId].explanations[explanationId].reason =
        value;
    },
    storeExplanationIsValid({ questionId, explanationId, value }) {
      this.contractingQuestions[questionId].explanations[explanationId].valid =
        value;
    },
    storeExplanationText({ questionId, explanationId, value }) {
      this.contractingQuestions[questionId].explanations[
        explanationId
      ].explanation = value;
    },
    storeMarketingManager(value) {
      Vue.set(this.marketingManager, "id", value.marketing_manager_id);
      Vue.set(this.marketingManager, "name", value.name);
    },
    storePhoneWork(value) {
      this.phoneWork = value;
    },
    storePhoneWorkExtension(value) {
      this.phoneWorkExtension = value;
    },
    storeBirthdate(value) {
      this.birthdate = value;
    },
    storeGender(value) {
      this.gender = value;
    },
    storeDriversLicenseNumber(value) {
      this.driversLicense.number = value;
    },
    storeDriversLicenseState(value) {
      this.driversLicense.state = value;
    },
    storePhoneMobile(value) {
      this.phoneMobile = value;
    },
    storeEmail(value) {
      this.email = value;
    },
    addRelatedDocument(payload) {
      Vue.set(this.relatedDocuments, payload.uid, payload);
    },
    async removeOccupation(occupationId) {
      await deleteOccupation(occupationId);
      Vue.delete(this.occupations, occupationId);
      this.occupationOrder = Object.values(this.occupations)
        .sort(soleDateSort)
        .map(val => val.id)
        .reverse();
    },
    addOccupation(occupation) {
      Vue.set(this.occupations, occupation.id, occupation);
      this.occupationOrder = Object.values(this.occupations)
        .sort(soleDateSort)
        .map(val => val.id)
        .reverse();
    },
    async createCommissionSplit({ id }) {
      const split = await createCommissionSplit(this.id, {
        commission_split: {
          agent_id: id,
          split_percent: 0.01
        }
      });
      this.addCommissionSplit(split);
      return split;
    },
    deleteCommissionSplit({ id }) {
      return deleteCommissionSplit(this.id, id);
    },
    updateCommissionSplit({ id, splitPercent }) {
      return updateCommissionSplit(this.id, id, {
        commission_split: {
          split_percent: (splitPercent / 100).toFixed(2)
        }
      });
    },
    updateHideCommission() {
      return updateAgent(this.id, {
        hide_commission: this.hideCommission
      });
    },

    updateAnnualization() {
      return updateAgent(this.id, {
        annualization: this.annualization
      });
    },
    updateCommissionLock() {
      return updateAgent(this.id, {
        commission_lock: this.lockCommissions
      });
    },
    async updateAssignment() {
      if (this.assignment.type === "Self") {
        return updateAgent(this.id, {
          assignable_id: this.id,
          assignable_type: "Agent"
        });
      }
      await updateAgent(this.id, {
        assignable_id: this.assignment.id,
        assignable_type: this.assignment.type
      });
    },
    async createLogin(payload) {
      const user = useUserStore();
      const login = await createLogin(payload);
      if (user.login.id === login.id) {
        const userStore = useUserStore();
        userStore.addUserLogin(login);
      }
      Vue.set(this.logins, login.id, login);
    },
    async deleteLogin(id) {
      const user = useUserStore();
      await destroyLogin(id);
      if (id === user.id) {
        const userStore = useUserStore();
        userStore.removeUserLogin(id);
      }
      if (this.primaryLogin.id === id) {
        Vue.set(this, "primaryLogin", null);
      }
      Vue.delete(this.logins, id);
    },
    async deleteRelatedDocument(id) {
      await deleteDocument(id);
      Vue.delete(this.relatedDocuments, id);
    },
    updateHierarchy() {
      const reqConnections = [];

      Object.keys(this.connections).forEach(connectionId => {
        const connection = this.connections[connectionId];
        reqConnections.push({
          hierarchy_order: connection.hierarchyOrder,
          id: connection.id,
          hierarchy: connection.hierarchy
        });
      });

      return updateAgent(this.id, {
        connections_attributes: reqConnections
      });
    },
    async deleteConnection(id) {
      await deleteConnection(id);
      Vue.delete(this.connections, id);
    },
    async destroyEo() {
      await deleteEo(this.eo.id);
      Vue.set(this, "eo", ErrorsOmissions());
    },
    updateCommunicationPreference(type) {
      return updateAgentCommunicationPreferences(
        this.id,
        getCommunicationPreferenceRequest(this.communicationPreferences, type)
      );
    },
    updateCredit(creditId) {
      const credit = this.credits[creditId];
      const body = {
        scheduling_links: credit.schedulingLinks
      };
      return updateMarketingManager(creditId, body);
    },

    updateAttribute(agent) {
      return updateAgent(this.id, agent);
    },
    async createEft(eft) {
      const newEft = await createNewEft(eft);
      this.addNewEft(newEft);
    },
    updateActiveEft(id) {
      Object.keys(this.efts).forEach(otherEft => {
        Vue.set(this.efts[otherEft], "current", 0);
      });
      Vue.set(this.efts[id], "current", 1);

      return setActiveEft(this.efts[id]);
    },
    updateCommissionSchedules() {
      return updateAgent(this.id, {
        schedule: setRawFromCommissionsSchedules(this.commissions.current)
      });
    },
    updateExternalId() {
      return updateAgent(this.id, { external_id: this.externalId });
    },
    updateLegalFirstName() {
      return updateAgent(this.id, {
        legal_first_name: this.legalFirstName
      });
    },
    updateLegalLastName() {
      return updateAgent(this.id, {
        legal_last_name: this.legalLastName
      });
    },
    updateFirstName() {
      return updateAgent(this.id, { first_name: this.firstName });
    },
    updateLastName() {
      return updateAgent(this.id, { last_name: this.lastName });
    },
    updateCategory() {
      return updateAgent(this.id, { category: this.category });
    },
    updateSchedulingLink() {
      return updateAgent(this.id, {
        scheduling_link: this.schedulingLink
      });
    },
    updateMessageClient() {
      return updateAgent(this.id, {
        message_client: this.messageClient
      });
    },
    updateDefaultApprovedDomain() {
      return updateAgent(this.id, {
        default_approved_domain_id: this.defaultApprovedDomain.id
      });
    },
    clearContractingQuestion(questionId) {
      this.contractingQuestions[questionId].documents = [];
      this.contractingQuestions[questionId].explanations = {};
      if (this.contractingQuestions[questionId].parentId) {
        this.contractingQuestions[questionId].answer = null;
      }

      this.contractingQuestions[questionId].childIds.forEach(id =>
        this.clearContractingQuestion(id)
      );
    },
    async createContractingExplanation(questionId, explanation) {
      const question = this.contractingQuestions[questionId];
      const newExplanation = await createExplanation(
        question.questionId,
        explanation
      );
      Vue.set(
        this.contractingQuestions[questionId].explanations,
        newExplanation.id,
        newExplanation
      );
    },
    async updateContractingExplanation(questionId, explanationId, explanation) {
      const updatedExplanation = await updateExplanation(
        explanationId,
        explanation
      );
      Vue.set(
        this.contractingQuestions[questionId].explanations,
        updatedExplanation.id,
        updatedExplanation
      );
    },
    async deleteAndRemoveExplanation(questionId, explanationId) {
      await deleteExplanation(explanationId);
      Vue.delete(
        this.contractingQuestions[questionId].explanations,
        explanationId
      );
    },
    async createAndAddDocument({ questionId, file }) {
      const question = this.contractingQuestions[questionId];
      const body = await serializeObject({ file });
      const documents = await addDocumentToAnswer(question.questionId, body);
      this.addDocument({ questionId, documents });
    },
    async deleteAndRemoveDocument({ questionId, documentId, documentUid }) {
      const question = this.contractingQuestions[questionId];
      await removeDocumentFromAnswer(question.questionId, documentUid);
      Vue.delete(this.contractingQuestions[questionId].documents, documentId);
    },
    updateContractingAnswer(questionId) {
      const answer = this.contractingQuestions[questionId].answer;
      const body = {
        contracting_answers_attributes: [
          { id: this.contractingQuestions[questionId].questionId, answer }
        ]
      };

      return updateAgent(this.id, body);
    },
    updateTitle() {
      return updateAgent(this.id, { title: this.title });
    },
    updateActionDate({ questionId, explanationId }) {
      const body = {
        action_date:
          this.contractingQuestions[questionId].explanations[explanationId]
            .actionDate
      };
      return updateExplanation({ explanationId, body });
    },
    updateAction({ questionId, explanationId }) {
      const body = {
        action:
          this.contractingQuestions[questionId].explanations[explanationId]
            .action
      };
      return updateExplanation({ explanationId, body });
    },
    updateReason({ questionId, explanationId }) {
      const body = {
        reason:
          this.contractingQuestions[questionId].explanations[explanationId]
            .reason
      };
      return updateExplanation({ explanationId, body });
    },
    updateExplanationText({ questionId, explanationId }) {
      const body = {
        explanation:
          this.contractingQuestions[questionId].explanations[explanationId]
            .explanation
      };
      return updateExplanation({ explanationId, body });
    },
    updateMarketingManager() {
      return updateAgent(this.id, {
        marketing_manager_id: this.marketingManager.id
      });
    },
    updatePhoneWork() {
      let phone_work = this.phoneWork;
      if (this.phoneWorkExtension) {
        phone_work = `${this.phoneWork}x${this.phoneWorkExtension}`;
      }
      return updateAgent(this.id, { phone_work });
    },
    updateSsn() {
      return updateAgent(this.id, { ssn: this.ssn });
    },
    updateBirthdate() {
      return updateAgent(this.id, { birthdate: this.birthdate });
    },
    updateGender() {
      return updateAgent(this.id, { gender: this.gender });
    },
    updateDriversLicense() {
      return updateAgent(this.id, {
        drivers_license_attributes: {
          number: this.driversLicense.number,
          state: this.driversLicense.state,
          id: this.driversLicense.id
        }
      });
    },
    updateCrdNumber() {
      return updateAgent(this.id, { crd_number: this.crdNumber });
    },
    updatePhoneMobile() {
      return updateAgent(this.id, { phone_mobile: this.phoneMobile });
    },
    updateEmail() {
      return updateAgent(this.id, { email: this.email });
    },
    updateNpn() {
      return updateAgent(this.id, { npn: this.npn });
    },
    createNewAgency({ npn, email }) {
      return createAgency(npn, email, this.id);
    },
    delete() {
      return deleteAgent(this.id);
    },
    updateCaseConcierge() {
      return updateAgent(this.id, {
        case_concierge_id: this.caseConcierge?.id || null
      });
    },
    updateCaseManager() {
      return updateAgent(this.id, {
        case_manager_id: this.caseManager?.id || null
      });
    },
    updateDeceasedAt() {
      return updateAgent(this.id, { deceased_at: this.deceasedAt });
    }
  }
});
