<template>
  <div>
    <v-btn
      v-if="pdfFieldTypes.length === 1"
      icon
      class="ml-1"
      :loading="loading"
      @click="$emit('add-field', pdfFieldTypes[0].value)"
    >
      <v-icon> $mdi-plus-circle </v-icon>
    </v-btn>
    <v-menu v-else>
      <template #activator="{ on, attrs }">
        <v-btn icon class="ml-1" v-bind="attrs" v-on="on" :loading="loading">
          <v-icon> $mdi-plus-circle </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="({ text, value }, index) in pdfFieldTypes"
              :key="index"
              @click="$emit('add-field', value)"
            >
              {{ text }}
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-menu
      v-if="!isEappSubmission && !noDuplication"
      v-model="dupeMenu"
      :close-on-content-click="false"
    >
      <template #activator="{ on, attrs }">
        <v-btn icon class="ml-1" v-bind="attrs" v-on="on" :loading="loading">
          <v-icon> $mdi-content-duplicate </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <v-text-field
            label="Up/Down Offset"
            v-model="dupeOffsetY"
            hide-details
            outlined
            dense
            class="ma-2 no-tick"
            type="text"
            inputmode="numeric"
            @click.prevent
          />
          <v-text-field
            label="Left/Right Offset"
            v-model="dupeOffsetX"
            hide-details
            outlined
            dense
            class="ma-2 no-tick"
            type="text"
            inputmode="numeric"
            @click.prevent
          />
          <v-list>
            <v-list-item @click="duplicate('down')"> Down </v-list-item>
            <v-list-item @click="duplicate('right')"> Right </v-list-item>
            <v-list-item @click="duplicate('up')"> Up </v-list-item>
            <v-list-item @click="duplicate('left')"> Left </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import {
  NOT_ON_PDF_OPTION_TYPE,
  RADIO_OPTION_TYPE,
  CHECKBOX_OPTION_TYPE,
  TEXT_OPTION_TYPE,
  pdfFieldTypes
} from "@/factories/FormMappingFactory";
import { useFormMappingStore } from "@/stores/form-mapping";
import { storeToRefs } from "pinia";
export default {
  props: {
    formId: {
      type: [String, Number],
      required: true
    },
    loading: Boolean,
    noDuplication: Boolean
  },
  data: () => ({ dupeMenu: false }),
  setup({ formId }) {
    const formStore = useFormMappingStore(formId);
    const { isContracting, dupeOffsetY, dupeOffsetX, isEappSubmission } =
      storeToRefs(formStore);
    return { isContracting, dupeOffsetY, dupeOffsetX, isEappSubmission };
  },
  computed: {
    pdfFieldTypes() {
      if (this.isEappSubmission) {
        return pdfFieldTypes.filter(
          ({ value }) => value === NOT_ON_PDF_OPTION_TYPE
        );
      } else if (this.isContracting) {
        return pdfFieldTypes.filter(value =>
          [
            RADIO_OPTION_TYPE,
            CHECKBOX_OPTION_TYPE,
            TEXT_OPTION_TYPE,
            NOT_ON_PDF_OPTION_TYPE
          ].includes(value.value)
        );
      }
      return pdfFieldTypes;
    }
  },
  methods: {
    duplicate(direction) {
      this.dupeMenu = false;
      this.$emit("duplicate-field", direction);
    }
  }
};
</script>
