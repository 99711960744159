<template>
  <div>
    <v-row v-if="!disabled" align="center" class="ma-3">
      <agent-search
        v-model="newAgent"
        hide-details
        label="Agent"
        data-testid="commission-split-agent-search"
        :disabled="disabled"
      />
      <v-btn
        class="text-none ml-2"
        color="accent"
        data-testid="commission-split-agent-create"
        :loading="creatingCommissionSplit"
        :disabled="disabled"
        @click="createCommissionSplit"
      >
        <v-icon> $mdi-plus </v-icon> Add
      </v-btn>
    </v-row>
    <v-list v-if="hasCommissionSplits">
      <v-list-item v-for="split in commissionSplits" :key="split.id">
        <v-list-item-avatar>
          <v-img :src="split.agent.avatar" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <v-row class="pa-3" align="center">
              {{ split.agent.name }}
              <v-btn
                v-if="!disabled"
                icon
                small
                :data-testid="`commission-split-delete-${split.agent.name}`"
                :loading="split.loading"
                @click="deleteCommissionSplit(split)"
              >
                <v-icon color="error">$mdi-delete</v-icon>
              </v-btn>
              <v-spacer />
              <active-save-indicator
                :controller="savingBuffer[split.id]"
                class="mr-2"
              />
              {{ split.splitPercent }}%
            </v-row></v-list-item-title
          >
          <v-slider
            v-if="!disabled"
            v-model="split.splitPercent"
            min="1"
            max="99"
            hide-details
            :data-testid="`commission-split-slider-${split.agent.name}`"
            :disabled="disabled"
            @change="updateCommissionSplit(split)"
          />
        </v-list-item-content>
      </v-list-item>
      <div class="px-4">
        <v-divider />
        <v-row class="pa-3">
          <v-spacer />
          <h3
            class="pt-3"
            :class="{
              'success--text': validPercents,
              'error--text': !validPercents
            }"
          >
            {{ advisorName }}'s Split: {{ agentSplit }}%
          </h3>
        </v-row>
      </div>
    </v-list>
    <v-row
      v-else-if="!disabled"
      style="height: 100px"
      align="center"
      justify="center"
    >
      <p style="text-align: center">
        No Commission Splits Found. You can add an agent to split commissions
        with above.
      </p>
    </v-row>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";

import AgentSearch from "@/components/shared/AgentSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  name: "CommissionSplits",
  mixins: [ActiveSaveMixin],
  components: { AgentSearch, ActiveSaveIndicator },
  props: {
    value: Array,
    advisorName: String,
    updateFunc: Function,
    createFunc: Function,
    deleteFunc: Function,
    disabled: Boolean
  },
  data() {
    return {
      savingBuffer: this.generateSavingBuffer({
        items: this.value.map(split => split.id)
      }),
      newAgent: null,
      commissionSplits: cloneDeep(this.value),
      creatingCommissionSplit: false
    };
  },
  watch: {
    commissionSplits: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      }
    }
  },
  computed: {
    hasCommissionSplits() {
      return Boolean(this.commissionSplits.length);
    },
    accumulatedSplits() {
      const reducer = (accumulator, current) =>
        accumulator + current.splitPercent;
      return this.commissionSplits.reduce(reducer, 0);
    },
    agentSplit() {
      return 100 - this.accumulatedSplits;
    },
    validPercents() {
      return this.agentSplit >= 1;
    }
  },

  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    updateCommissionSplit(commissionSplit) {
      if (this.disabled) return;
      if (!this.validPercents) {
        this.showErrorSnackbar({
          message:
            "Commission Splits Exceed 100%, You must decrease an agents commission split."
        });
        return;
      }

      // Only can change split percent for now
      this.updateAttributeWrapper(
        () => this.updateFunc(commissionSplit),
        this.savingBuffer[commissionSplit.id]
      );
    },
    deleteCommissionSplit(commissionSplit) {
      if (this.disabled) return;

      const func = async () => {
        await this.deleteFunc(commissionSplit);
        const index = this.commissionSplits.findIndex(
          split => split.id === commissionSplit.id
        );
        this.commissionSplits.splice(index, 1);
      };
      this.showDialog({
        component: "ConfirmationDialog",
        title: `Are you sure that you want to remove ${commissionSplit.agent.name} from ${this.advisorName}'s commission splits?`,
        subtitle: "This cannot be undone",
        func
      });
    },
    async createCommissionSplit() {
      if (this.disabled) return;
      if (!this.newAgent?.id) return;
      if (2 + this.accumulatedSplits > 100) {
        this.showErrorSnackbar({
          message: `Commission Splits will exceed 100% by adding ${this.newAgent.name}, You must decrease an agents commission split.`
        });
        return;
      }
      this.creatingCommissionSplit = true;
      try {
        const commissionSplit = await this.createFunc(this.newAgent);
        this.commissionSplits.push(commissionSplit);

        this.$set(this, "newAgent", null);

        this.$set(
          this.savingBuffer,
          commissionSplit.id,
          this.activeSaveElementFactory()
        );
      } catch (e) {
        this.showErrorSnackbar({
          message: parseErrorMessage(e),
          timeout: 5000
        });
      } finally {
        this.creatingCommissionSplit = false;
      }
    }
  }
};
</script>
