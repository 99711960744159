import { getHttpClient } from "@/http-client";

import {
  setRecentAdvisorFromRequest,
  setRecentAppointmentFromRequest,
  setRecentCampaignFromRequest,
  setRecentCaseFromRequest,
  setRecentContractPartyFromRequest,
  setRecentElectronicApplicationFromRequest,
  setRecentPaymentFromRequest,
  setRecentPayPeriodFromRequest,
  setRecentQuoteFromRequest
} from "@/factories/Recents";

const baseUrl = "/api/boss/recents";

async function getRecents({ agent_id, agency_id, record }) {
  const params = new URLSearchParams();
  if (agent_id) params.append("agent_id", agent_id);
  if (agency_id) params.append("agency_id", agency_id);
  params.append("record", record);
  const { data } = await getHttpClient().get(baseUrl, { params });

  return data;
}

export async function getRecentAdvisors(args = { agent_id: 0, agency_id: 0 }) {
  const items = await getRecents({ ...args, record: "advisors" });

  return {
    items: items.map(setRecentAdvisorFromRequest)
  };
}

export async function getRecentAppointments(
  args = { agent_id: 0, agency_id: 0 }
) {
  const items = await getRecents({ ...args, record: "appointments" });

  return {
    items: items.map(setRecentAppointmentFromRequest)
  };
}

export async function getRecentCampagins(args = { agent_id: 0, agency_id: 0 }) {
  const items = await getRecents({ ...args, record: "campaigns" });

  return {
    items: items.map(setRecentCampaignFromRequest)
  };
}

export async function getRecentCases(args = { agent_id: 0, agency_id: 0 }) {
  const items = await getRecents({ ...args, record: "cases" });
  return {
    items: items.map(setRecentCaseFromRequest)
  };
}

export async function getRecentContractParties(
  args = { agent_id: 0, agency_id: 0 }
) {
  const items = await getRecents({ ...args, record: "contract_parties" });
  return {
    items: items.map(setRecentContractPartyFromRequest)
  };
}

export async function getRecentEApps(args = { agent_id: 0, agency_id: 0 }) {
  const items = await getRecents({
    ...args,
    record: "electronic_applications"
  });
  return {
    items: items.map(setRecentElectronicApplicationFromRequest)
  };
}

export async function getRecentPayments(args = { agent_id: 0, agency_id: 0 }) {
  const items = await getRecents({ ...args, record: "payments" });
  return {
    items: items.map(setRecentPaymentFromRequest)
  };
}

export async function getRecentPayPeriods(
  args = { agent_id: 0, agency_id: 0 }
) {
  const items = await getRecents({ ...args, record: "pay_periods" });
  return {
    items: items.map(setRecentPayPeriodFromRequest)
  };
}

export async function getRecentQuotes(args = { agent_id: 0, agency_id: 0 }) {
  const items = await getRecents({ ...args, record: "quotes" });
  return {
    items: items.map(setRecentQuoteFromRequest)
  };
}
