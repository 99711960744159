import { getHttpClient } from "@/http-client";
const baseURL = "api/boss/comp_builders";

export function createCompBuilder(body) {
  return getHttpClient().post(baseURL, body);
}
export function updateCompBuilder(id, params) {
  return getHttpClient().put(`${baseURL}/${id}`, params);
}
export function deleteCompBuilder(id) {
  return getHttpClient().delete(`${baseURL}/${id}`);
}
