<template>
  <v-card flat>
    <v-card-title>Commission Splits</v-card-title>
    <v-card-subtitle
      >Specify the agent(s) you would like to split the commission with on
      applications coming from this Quote & Apply website. The agent(s) you list
      will automatically be added on the agent's report for their respective
      percentage as an additional agent. The agent(s) must be licensed in the
      state of the application in order to be added to the application. You must
      maintain at least 1% commission split. Enabling Commission Splits on this
      Quote & Apply website will override the Commission Splits in the agent's
      settings.</v-card-subtitle
    >
    <v-card-text class="pt-0">
      <div class="checkbox-width">
        <v-checkbox
          v-model="overrideCommissionSplits"
          hide-details
          data-testid="commission-splits-override"
          :disabled="ownable.lockCommissions"
          @change="updateAttribute('overrideCommissionSplits')"
        >
          <template #label>
            <template v-if="ownable.lockCommissions">
              {{ ownable.name }}'s Commissions are Locked
            </template>
            <template v-else>
              Override {{ ownable.name }}'s Commission Splits

              <active-save-indicator
                :controller="savingBuffer['overrideCommissionSplits']"
                class="ml-2"
              />
            </template>
          </template>
        </v-checkbox>
      </div>

      <commission-splits
        v-if="overrideCommissionSplits"
        v-model="commissionSplits"
        style="margin-bottom: 50px"
        :advisor-name="ownable.name"
        :disabled="ownable.lockCommissions"
        :update-func="updateApprovedDomainCommissionSplit"
        :delete-func="deleteApprovedDomainCommissionSplit"
        :create-func="createApprovedDomainCommissionSplit"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import CommissionSplits from "@/components/shared/CommissionSplits.vue";

import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  mixins: [ActiveSaveMixin],
  components: { CommissionSplits, ActiveSaveIndicator },
  data() {
    const savingBuffer = this.generateSavingBuffer({
      items: ["overrideCommissionSplits"]
    });
    return {
      savingBuffer
    };
  },
  computed: {
    ...mapState(useApprovedDomainView, ["ownable"]),
    ...mapWritableState(useApprovedDomainView, [
      "commissionSplits",
      "overrideCommissionSplits"
    ])
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    ...mapActions(useApprovedDomainView, [
      "updateApprovedDomain",
      "updateApprovedDomainCommissionSplit",
      "createApprovedDomainCommissionSplit",
      "deleteApprovedDomainCommissionSplit"
    ]),
    updateAttribute(attribute) {
      if (this.ownable.lockCommissions) return;
      this.updateAttributeWrapper(
        () => this.updateApprovedDomain(attribute),
        this.savingBuffer[attribute]
      );
    }
  }
};
</script>
