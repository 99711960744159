<template>
  <div :id="parentName" style="line-height: 0"></div>
</template>

<script>
import { createHighDPICanvas } from "@/util/helpers";
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    height: {
      type: [Number, String],
      required: false,
      default: 0
    },
    width: {
      type: [Number, String],
      required: false,
      default: 0
    },
    scale: {
      type: [Number],
      required: false,
      default: 1
    }
  },
  data: () => ({ timer: null, sharpenTimer: null }),
  computed: {
    parentName() {
      return `${this.id}-parent`;
    }
  },
  mounted() {
    this.instantiate();
    this.$emit("ready");
  },
  watch: {
    height() {
      this.debounceAndRedraw();
    },
    width() {
      this.debounceAndRedraw();
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    debounceAndRedraw() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.instantiate();
        this.$emit("redraw");
      }, 10);
    },
    instantiate() {
      const existingEl = document.getElementById(this.id);
      if (existingEl) existingEl.remove();

      const div = document.getElementById(this.parentName);
      const canvas = createHighDPICanvas(this.width, this.height);
      canvas.id = this.id;
      const ctx = canvas.getContext("2d");
      if (this.scale) ctx.scale(this.scale, this.scale);
      const events = ["mousedown", "mousemove", "mouseup", "mouseout", "click"];
      events.forEach(e => {
        if (this.$listeners[e])
          canvas.addEventListener(e, v => this.$emit(e, v));
      });

      div.appendChild(canvas);
    }
  }
};
</script>
