<template>
  <v-card>
    <v-row class="ma-0 pa-3" justify="center" align="center">
      <v-img src="@/assets/img/Redtail.svg" max-height="100" contain />
    </v-row>
    <v-card-title>Connect to Redtail </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="credentials.username"
        label="Redtail Username"
        outlined
        dense
        :disabled="loading"
      />
      <v-text-field
        v-model="credentials.password"
        label="Redtail Password"
        type="password"
        outlined
        dense
        :disabled="loading"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        :loading="loading"
        @click="connectToRedtail"
      >
        Connect
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { ref } from "vue";
import { createRedtailIntegration } from "@/api/user-identities.service.js";

import { parseErrorMessage } from "@/util/helpers";

import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import { useUserStore } from "@/stores/user";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const user = useUserStore();

const credentials = ref({
  username: "",
  password: ""
});

const loading = ref(false);

async function connectToRedtail() {
  try {
    loading.value = true;
    await createRedtailIntegration(
      credentials.value.username,
      credentials.value.password
    );
    snackbar.showSuccessSnackbar({ message: "Connected to Redtail" });
    user.integrations.redtail.enabled = true;
    user.integrations.redtail.createdAt = new Date();
    dialog.closeDialog();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
</script>
