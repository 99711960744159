<template>
  <v-data-table
    data-testid="questionnaires-table"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :loading="table.loading.value"
    :search="search"
  >
    <template #top>
      <v-row class="ma-0 px-3" align="center">
        <h1 class="text-h5">Impaired Risk Questionnaires</h1>
        <v-spacer />
        <v-text-field
          v-model="search"
          data-testid="questionnaires-table-search"
          data-lpignore="true"
          outlined
          dense
          hide-details
          label="Search"
          prepend-inner-icon="$mdi-magnify"
        />
        <v-btn icon data-testid="questionnaires-table-refresh" @click="getData">
          <v-icon>$mdi-refresh</v-icon>
        </v-btn>
      </v-row>
    </template>
    <template #[`item.actions`]="{ item }">
      <v-btn
        icon
        data-testid="download"
        v-bind="downloadDocument(item.additional)"
      >
        <v-icon color="primary">$mdi-download</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script setup>
import { downloadFileAsLink, parseErrorMessage } from "@/util/helpers";
import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";
import { getDocumentUrl } from "@/api/documents.service";
import { getQuestionnaires } from "@/api/questionnaire.service";
import { useTable } from "@/composables/table.composable";
import { ref } from "vue";
import { useVuetify } from "@/composables/compatible.composables";
import { useSnackbarStore } from "@/stores/snackbar";

const search = ref("");
const snackbar = useSnackbarStore();

const vuetify = useVuetify();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Impairment",
      value: "questionnaire",
      map: "name",
      sortFilterMap: "questionnaires.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: ""
    })
  ],
  getData: getQuestionnaires,
  options: new TableOptions(["questionnaire"], [false])
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function downloadDocument({ uid }) {
  return downloadFileAsLink(getDocumentUrl(uid), vuetify.breakpoint.mdAndDown);
}

getData();
</script>
