<template>
  <v-card flat color="section">
    <v-card-title>Commission Rates</v-card-title>
    <v-card-subtitle>
      Modify commission rates.
      <router-link :to="{ name: 'Products' }" target="_blank">
        View Product Commission Rates.
      </router-link>
    </v-card-subtitle>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <p class="mb-1">Annuity</p>
          <v-select
            v-model="store.commissions.current.annuity"
            data-testid="annuity-rate"
            hide-details
            prepend-inner-icon="$mdi-calendar-clock"
            outlined
            dense
            label="Schedule"
            :items="store.commissions.options.annuity"
            :disabled="disabled"
            :success="Boolean(store.commissions.current.annuity)"
            @change="updateAttribute('annuity')"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.annuity.controller.value"
              />
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="6">
          <p class="mb-1">Disability</p>
          <v-select
            v-model="store.commissions.current.disability"
            hide-details
            prepend-inner-icon="$mdi-calendar-clock"
            data-testid="disability-rate"
            outlined
            dense
            label="Schedule"
            :items="store.commissions.options.disability"
            :success="Boolean(store.commissions.current.disability)"
            :disabled="disabled"
            @change="updateAttribute('disability')"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.disability.controller"
              />
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="6">
          <p class="mb-1">Life</p>
          <v-select
            v-model="store.commissions.current.life"
            hide-details
            prepend-inner-icon="$mdi-calendar-clock"
            outlined
            data-testid="life-rate"
            dense
            label="Schedule"
            :items="store.commissions.options.life"
            :success="Boolean(store.commissions.current.life)"
            :disabled="disabled"
            @change="updateAttribute('life')"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.life.controller"
              />
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="6">
          <p class="mb-1">Linked Benefit</p>
          <v-select
            v-model="store.commissions.current.linkedBenefit"
            hide-details
            prepend-inner-icon="$mdi-calendar-clock"
            outlined
            data-testid="linked-benefit-rate"
            dense
            label="Schedule"
            :items="store.commissions.options.linkedBenefit"
            :success="Boolean(store.commissions.current.linkedBenefit)"
            :disabled="disabled"
            @change="updateAttribute('linkedBenefit')"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.linkedBenefit.controller"
              />
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="6">
          <p class="mb-1">LTC</p>
          <v-select
            v-model="store.commissions.current.ltc"
            hide-details
            data-testid="ltc-rate"
            prepend-inner-icon="$mdi-calendar-clock"
            outlined
            dense
            label="Schedule"
            :items="store.commissions.options.ltc"
            :success="Boolean(store.commissions.current.ltc)"
            :disabled="disabled"
            @change="updateAttribute('ltc')"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.ltc.controller"
              />
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="6">
          <p class="mb-1">Variable</p>
          <v-select
            v-model="store.commissions.current.variableLife"
            prepend-inner-icon="$mdi-calendar-clock"
            outlined
            dense
            data-testid="variable-life-rate"
            label="Schedule"
            hide-details
            :items="store.commissions.options.variableLife"
            :success="Boolean(store.commissions.current.variableLife)"
            :disabled="disabled"
            @change="updateAttribute('variableLife')"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.variableLife.controller"
              />
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { defineProps } from "vue";
import { useActiveSave } from "@/composables/active-save.composable";
import { useSettingsViewStore } from "@/stores/settings-view";

const props = defineProps({
  disabled: Boolean,
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});
let store;

if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else if (props.module === AGENCY_SETTINGS) {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}
const savingBuffer = {
  annuity: useActiveSave(),
  disability: useActiveSave(),
  life: useActiveSave(),
  linkedBenefit: useActiveSave(),
  ltc: useActiveSave(),
  variableLife: useActiveSave()
};
function updateAttribute(schedule) {
  savingBuffer[schedule].update(store.updateCommissionSchedules);
}
</script>
