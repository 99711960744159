<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-data-table
        :items="table.mappedItems.value"
        :headers="table.tableHeaders.value"
        data-testid="signers-table"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
      >
        <template #[`item.actions`]="{ item }">
          <v-tooltip bottom v-if="!item.additional.signedAt">
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                color="accent"
                v-on="on"
                v-bind="attrs"
                data-testid="signer-table-edit-signer"
                @click="editSigner(item)"
              >
                <v-icon>$mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit Signer Email</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.additional.canResendEnvelope">
            <template #activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                color="primary"
                icon
                data-testid="signer-table-resend-envelope"
                @click="resend"
              >
                <v-icon> $mdi-send </v-icon>
              </v-btn>
            </template>
            <span>Resend DocuSign Email to this Signer</span>
          </v-tooltip>
        </template>
        <template #[`item.signedAt`]="{ item }">
          <timestamp-formatter :value="item.signedAt" format="full-date-time" />
        </template>
        <template #[`item.ownerName`]="{ item }">
          <router-link
            v-if="item.additional.routerLink"
            class="text-none"
            :to="item.additional.routerLink"
          >
            {{ item.ownerName }}
          </router-link>
          <span v-else>
            {{ item.ownerName }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import TableHeader from "@/classes/data-table/TableHeader";
import { useEappViewStore } from "@/stores/eapp-view";
import { mapWritableState, mapActions } from "pinia";
export default {
  components: {
    TimestampFormatter
  },
  setup() {
    const headers = [
      new TableHeader({ text: "Name", value: "ownerName", map: "ownerName" }),
      new TableHeader({
        text: "Email",
        value: "ownerEmail",
        map: "ownerEmail"
      }),
      new TableHeader({ text: "Role", value: "role", map: "role" }),
      new TableHeader({
        text: "Access Code",
        value: "accessCode",
        map: "accessCode"
      }),
      new TableHeader({ text: "Status", value: "status", map: "status" }),
      new TableHeader({ text: "Signed", value: "signedAt", map: "signedAt" }),
      new TableHeader({ text: "Actions", value: "actions", map: "actions" })
    ];

    const table = useTable({ headers });

    return { table };
  },
  computed: {
    ...mapWritableState(useEappViewStore, ["signers"])
  },
  created() {
    this.table.items.value = this.signers;
  },
  methods: {
    ...mapActions(useEappViewStore, ["resendEnvelope"]),
    ...mapActions(useSnackbarStore, [
      "showErrorSnackbar",
      "showSuccessSnackbar"
    ]),
    ...mapActions(useDialogStore, ["showDialog"]),
    async editSigner(signer) {
      if (signer.link === "agents") return;

      const res = await this.showDialog({
        component: "SignerEdit",
        $signerEmail: signer.additional.ownerEmail,
        $signerId: signer.additional.signerId
      });

      if (!res?.email) return;
      const index = this.signers.findIndex(
        eAppSigner => eAppSigner.signerId === signer.additional.signerId
      );
      if (index < -1) return;
      this.signers[index].ownerEmail = res.email;
      this.showSuccessSnackbar({
        message: `You have successfully changed the email to ${res.email}. DocuSign has sent a new eSignature email.`,
        timeout: 10000
      });
    },
    async resend() {
      const func = async () => {
        await this.resendEnvelope();
        this.showSuccessSnackbar({
          message: "DocuSign Successfully Resent",
          timeout: 6000
        });
      };

      this.showDialog({
        component: "ConfirmationDialog",
        title: "Resend Envelope?",
        subtitle: "This will dispatch an email",
        func
      });
    }
  }
};
</script>
