<template>
  <v-card v-cloak>
    <close-button @click="closeDialog()" style="z-index: 3" />
    <v-card-title class="px-6 mb-4">
      {{ title || "Upload Document" }}
    </v-card-title>
    <v-card-text>
      <file-drag-and-drop
        v-model="file"
        :multiple="!single"
        :success="fileValidation.success"
        :error-messages="fileValidation.errorMessages"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="text-none mb-3" outlined @click="closeDialog()">
        Cancel
      </v-btn>
      <v-btn
        class="text-none mr-7 mb-3"
        color="primary"
        data-testid="upload-file-confirm"
        :loading="uploadingFile"
        @click="uploadFile"
      >
        Upload
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import CloseButton from "@/dialogs/CloseButton.vue";
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";

import { required } from "vuelidate/lib/validators";

import { uploadUserDocument } from "@/api/upload.service";

import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

export default {
  components: {
    CloseButton,
    FileDragAndDrop
  },
  props: {
    type: String,
    title: String,
    accept: String,
    single: Boolean,
    userId: [Number, String],
    approvedDomainId: [Number, String],
    uploadFunction: { type: Function, required: false, default: null }
  },
  data() {
    return {
      file: null,
      uploadingFile: false
    };
  },
  computed: {
    fileValidation() {
      const success = !this.$v.file.$invalid;
      const errorMessages = [];
      if (!this.$v.file.$dirty) return { success, errorMessages };
      if (!this.$v.file.required) errorMessages.push("Required");
      if (!this.$v.file.validSize)
        errorMessages.push(
          "Please confirm this file has data or try re-uploading the file"
        );
      return { success, errorMessages };
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, [
      "showErrorSnackbar",
      "showSuccessSnackbar"
    ]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    async uploadFile() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      if (this.userId) {
        this.uploadingFile = true;

        const promises = this.file.map(file =>
          uploadUserDocument({
            id: this.userId,
            documentable_id: this.userId,
            documentable_type: this.type,
            file
          })
        );

        Promise.all(promises)
          .then(documents => {
            this.showSuccessSnackbar({ message: "Uploaded Document" });
            this.closeDialog(documents);
          })
          .catch(e => {
            this.showErrorSnackbar({ message: parseErrorMessage(e) });
          })
          .finally(() => {
            this.uploadingFile = false;
          });
      }

      try {
        this.uploadingFile = true;
        const response = await this.uploadFunction({
          file: this.file
        });
        this.closeDialog(response);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
        this.uploadingFile = false;
      }
    }
  },
  validations() {
    return {
      file: {
        required,
        validSize: val => {
          if (Array.isArray(val)) return !val.some(file => file?.size === 0);
          return val?.size > 0;
        }
      }
    };
  }
};
</script>
