<template>
  <v-breadcrumbs
    v-if="crumbs.length > 1 && !fullscreen"
    divider="/"
    :items="crumbs"
    :class="{ 'pa-0': currentMeta?.useBreadcrumbSlot }"
    class="px-1 pt-0 d-print-none"
  >
    <v-breadcrumbs-item
      slot="item"
      slot-scope="{ item }"
      exact
      :to="item.to"
      :class="{ 'grey--text': !item.to }"
      data-testid="breadcrumb-item"
    >
      <v-progress-circular
        indeterminate
        v-if="item.text === 'loading'"
        size="12"
        width="2"
      />
      <template v-else>
        {{ item.text }}
      </template>
    </v-breadcrumbs-item>
  </v-breadcrumbs>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { useRouter } from "@/composables/compatible.composables";
import { computed } from "vue";
import { useUserStore } from "@/stores/user";

const { breadcrumb, fullscreen } = storeToRefs(useInstanceStore());

const router = useRouter();

const currentMeta = computed(() => {
  if (!router) return null;
  return router.currentRoute.meta;
});

const crumbs = computed(() => {
  let breadcrumbs = currentMeta.value?.breadcrumbs;
  if (!currentMeta.value || !breadcrumbs) return [];

  let text = currentMeta.value.breadcrumb;
  if (currentMeta.value.useStateVal) text = breadcrumb.value || "loading";

  if (!text && currentMeta.value.useStateVal !== true) return [];

  let resolvedBreadcrumbs = breadcrumbs;
  if (typeof breadcrumbs === "function") {
    resolvedBreadcrumbs = breadcrumbs(useUserStore());
  }
  return [...resolvedBreadcrumbs, { text }];
});
</script>
