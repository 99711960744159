<template>
  <v-card flat tile color="section">
    <v-card-text> <timeline :items="appointmentTimeline" /> </v-card-text>
  </v-card>
</template>

<script setup>
import Timeline from "@/components/shared/Timeline.vue";
import { storeToRefs } from "pinia";
import { useAppointmentView } from "@/stores/appointment-view";

const { timeline: appointmentTimeline } = storeToRefs(useAppointmentView());
</script>
