<template>
  <chat
    title="Notes"
    flat
    :checkboxes="checkboxes"
    :pusher-id="`private-${id}-ContractParty`"
    :messages="notes"
    :room="room"
    :create-func="sendChatMessage"
    @new-note="newNote"
    @remove-message="removeFromMessages"
  />
</template>

<script setup>
import Chat from "@/components/shared/chat/Chat.vue";

import {
  setNoteFromRequest,
  Note,
  NoteToCreateRequest
} from "@/factories/NoteFactory";

import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";

import { createNote } from "@/api/notes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useContractPartyView } from "@/stores/contract-party-view";

const checkboxes = [
  { label: "Send Email", dataTestid: "email", key: "sendEmail", default: true }
];

const contractPartyView = useContractPartyView();
const user = useUserStore();
const snackbar = useSnackbarStore();
const { notes, id, room } = storeToRefs(contractPartyView);

function initNotes() {
  notes.value.sort((a, b) => a.createdAt - b.createdAt);

  notes.value.unshift({
    note: `Hi ${user.loginable.name}, feel free to make notes here.`,
    ownable: { name: "BackNine" },
    key: Symbol("intro")
  });
}

async function sendChatMessage(note) {
  const newNote = Note({
    documents: note.files,
    email: note.sendEmail ? 1 : 0,
    phoneCall: note.sendPhoneCall,
    note: note.message,
    notableId: id.value,
    notableType: "ContractParty"
  });
  try {
    await createNote(NoteToCreateRequest(newNote));
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  }
}

function removeFromMessages(message) {
  const index = notes.value.findIndex(v => v.id === message.id);
  if (index === -1) return;
  notes.value.splice(index, 1);
}

function newNote({ note: rawNote }) {
  notes.value.push(setNoteFromRequest(rawNote));
}

if (!notes.value.some(n => Symbol.keyFor(Symbol.for(n.key)) === "intro")) {
  initNotes();
}
</script>
