<template>
  <v-card flat tile>
    <v-card-text>
      <v-row class="pa-3">
        <v-spacer />
        <v-btn
          outlined
          rounded
          color="error"
          @click="deleteConfirmation"
          class="text-none"
        >
          <v-icon class="ml-1"> $mdi-delete </v-icon> Delete Personnel
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { deletePersonnel } from "@/api/personnel.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

export default {
  props: {
    personnel: Object
  },

  methods: {
    ...mapActions(useSnackbarStore, [
      "showErrorSnackbar",
      "showSuccessSnackbar"
    ]),
    ...mapActions(useDialogStore, ["showDialog"]),
    deleteConfirmation() {
      this.showDialog({
        component: "DeleteDialog",
        title: `Are you sure you want to permanently delete this personnel, ${this.personnel.firstName} ${this.personnel.lastName}?`,
        subtitle: "This cannot be undone",
        func: () => deletePersonnel(this.$route.params.id)
      })
        .then(res => {
          if (!res?.delete) return;
          this.showSuccessSnackbar({
            message: `${this.personnel.firstName} ${this.personnel.lastName} has been deleted`,
            timeout: 6000
          });
          this.$router.push({ name: "Tables" });
        })
        .catch(error => {
          this.showErrorSnackbar({
            message: `Unable to delete appointment. ${parseErrorMessage(
              error
            )}`,
            duration: -1
          });
        });
    }
  }
};
</script>
