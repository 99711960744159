<template>
  <v-card flat tile>
    <v-row align="center" class="ma-0">
      <v-col>
        <span>
          <a
            data-testid="card-domain"
            :href="`https://${domain}`"
            target="_blank"
          >
            https://{{ domain }}
          </a>
        </span>
        <br />
        Domain
      </v-col>
      <v-col>
        <router-link
          data-testid="card-ownable"
          :to="{
            name: ownable.typeModel === 'Agent' ? 'AgentView' : 'AgencyView',
            params: {
              id: ownable.id
            }
          }"
        >
          {{ ownable.name }}
        </router-link>
        <br />
        Agent
      </v-col>
      <v-spacer />
      <v-col>
        <v-row justify="end" class="ma-0">
          <avatar-edit
            :show-avatar="showAvatar"
            :avatar="avatar"
            :id="id"
            type="ApprovedDomain"
            :deletable="Boolean(approvedDomainAvatar)"
            editable
            @avatar-update="displayAvatar"
            @avatar-delete="hideAvatar"
            @avatar-error="hideAvatar"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import AvatarEdit from "@/components/shared/AvatarEdit.vue";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
export default {
  components: {
    AvatarEdit
  },
  data() {
    return {
      avatar: null,
      showAvatar: true
    };
  },
  created() {
    this.avatar = this.generateAvatarUrl();
  },
  computed: {
    ...mapWritableState(useApprovedDomainView, {
      useOwnersAvatar: "useOwnersAvatar",
      approvedDomainAvatar: "avatar"
    }),
    ...mapState(useApprovedDomainView, {
      assignable: "assignable",
      domain: "domain",
      ownable: "ownable",
      id: "id"
    }),
    cardDetails() {
      return ["Quote & Apply Location", "Agent"];
    }
  },
  watch: {
    useOwnersAvatar() {
      this.$nextTick(() => {
        this.avatar = this.generateAvatarUrl();
      });
    }
  },

  methods: {
    ...mapActions(useApprovedDomainView, ["updateApprovedDomain"]),
    hideAvatar() {
      if (this.useOwnersAvatar) this.updateToCustomAvatar();
      this.showAvatar = false;
    },
    displayAvatar(newUrl) {
      if (this.useOwnersAvatar) this.updateToCustomAvatar();
      else this.approvedDomainAvatar = newUrl;
      this.avatar = this.generateAvatarUrl();
      this.showAvatar = true;
    },
    updateToCustomAvatar() {
      this.useOwnersAvatar = false;
      this.updateApprovedDomain("useOwnersAvatar");
    },
    generateAvatarUrl() {
      const date = new Date();
      let uri = this.approvedDomainAvatar;
      if (this.useOwnersAvatar) uri = this.assignable.avatar_url;
      if (!uri) return null;
      return `${uri}?time=${date.getTime()}`;
    }
  }
};
</script>
