<template>
  <v-autocomplete
    v-model="model"
    multiple
    prepend-inner-icon="$mdi-shape"
    outlined
    dense
    chips
    small-chips
    deletable-chips
    :data-testid="dataTestid"
    :clearable="props.clearable"
    :loading="cache.productSolves.loading"
    :label="props.label"
    :disabled="props.readonly"
    :items="cache.productSolves.items"
    :success="props.success"
    :error-messages="props.errorMessages"
  >
    <template v-if="$slots['append-outer']" #append-outer>
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script setup>
import { useSearchItemsCache } from "@/stores/search-items-cache";
import { basicArrayEquivalence, parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { defineProps, defineEmits, ref, watch } from "vue";
const emit = defineEmits(["input"]);
const props = defineProps({
  readonly: Boolean,
  clearable: Boolean,
  success: Boolean,
  value: {
    type: Array,
    default: () => []
  },
  label: {
    type: String,
    default: ""
  },
  dataTestid: {
    type: String,
    default: "product-solve-search"
  },
  errorMessages: {
    type: Array,
    default: () => []
  }
});

const model = ref(props.value);

const cache = useSearchItemsCache();
const snackbar = useSnackbarStore();
watch(
  () => model.value,
  () => {
    if (basicArrayEquivalence(model.value, props.value || [])) return;
    emit("input", model.value);
  }
);

watch(
  () => props.value,
  () => {
    if (basicArrayEquivalence(model.value, props.value || [])) return;
    model.value = props.value || [];
  }
);

async function getItems() {
  try {
    await cache.getProductSolves();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getItems();
</script>
