<template>
  <v-card class="site-goals" :height="height">
    <v-card-title class="pb-2">
      <v-row class="ma-0 pl-3 pr-1" align="center">
        Your Profile is {{ percent }}% Complete <v-spacer />
        <v-progress-circular
          :value="percent"
          :color="percent === 100 ? 'success' : 'primary'"
          size="50"
          width="4"
          rotate="-90"
        >
          {{ percent }}%
        </v-progress-circular>
      </v-row>
    </v-card-title>
    <v-card-text class="pb-2">
      <v-list class="pa-0">
        <site-goal v-for="goal in visibleGoals" :key="goal.text" :goal="goal" />
      </v-list>
      <div style="position: relative" v-if="goalPageCount > 1">
        <v-pagination
          v-model="currentPage"
          :length="goalPageCount"
          circle
          style="position: absolute; bottom: 0; right: 0; left: 0"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import SiteGoal from "@/components/home/SiteGoal.vue";

const DISPLAYED_GOAL_LENGTH = 6;

export default {
  components: {
    SiteGoal
  },
  props: {
    goals: Array,
    percent: Number,
    height: String
  },
  data() {
    return {
      currentPage: 1
    };
  },
  computed: {
    visibleGoals() {
      const currentGoals = (this.currentPage - 1) * DISPLAYED_GOAL_LENGTH;
      const nextGoals = this.currentPage * DISPLAYED_GOAL_LENGTH;
      return this.goals.slice(currentGoals, nextGoals);
    },
    goalPageCount() {
      return Math.ceil(this.goals.length / DISPLAYED_GOAL_LENGTH);
    }
  }
};
</script>
