<template>
  <v-card v-if="displayedQuestions.length" color="section" tile flat>
    <v-card-text>
      <v-list
        dense
        style="height: 500px; overflow-y: auto; background-color: transparent"
        class="py-0"
      >
        <form-mapping-form-aqls-by-visibility-item
          v-for="question in displayedQuestions"
          :key="question.id"
          :pdf-field-type="question.pdfFieldType"
          :question-id="question.id"
          :current-field-id="currentFieldId"
          :readonly="readonly"
          @click="changeActiveField(question.id)"
          @delete="deleteField(question.id)"
        />
      </v-list>
    </v-card-text>
  </v-card>
  <v-card v-else flat tile color="section">
    <v-card-subtitle>
      <v-row class="pa-3" align="center"> No Questions Found </v-row>
    </v-card-subtitle>
  </v-card>
</template>

<script>
import FormMappingFormAqlsByVisibilityItem from "@/components/form-mapping/FormMappingFormAqlsByVisibilityItem.vue";
import { parseErrorMessage } from "@/util/helpers";
import { useFormMappingStore } from "@/stores/form-mapping";
import { storeToRefs, mapActions } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  components: {
    FormMappingFormAqlsByVisibilityItem
  },
  props: {
    formId: [Number, String],
    readonly: Boolean,
    allQuestions: Boolean
  },
  setup({ formId }) {
    const formStore = useFormMappingStore(formId);
    const { positionallySortedFields, currentFieldId } = storeToRefs(formStore);

    return {
      changeActiveField: formStore.changeActiveField,
      deleteApplicationQuestionLink: formStore.deleteApplicationQuestionLink,
      positionallySortedFields,
      currentFieldId
    };
  },
  computed: {
    displayedQuestions() {
      if (this.allQuestions) return this.positionallySortedFields;
      return this.positionallySortedFields.filter(
        ({ coordinates }) => !coordinates?.length
      );
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    deleteField(fieldId) {
      this.showDialog({
        component: "DeleteDialog",
        title:
          "Are you sure that you want to delete Application Question Link?",
        subtitle: "This cannot be undone.",
        func: () =>
          this.deleteApplicationQuestionLink(fieldId).catch(e => {
            this.showErrorSnackbar({ message: parseErrorMessage(e) });
          })
      });
    }
  }
};
</script>
