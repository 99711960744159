<template>
  <v-card outlined @click="openChangePasswordDialog" class="pt-6 pa-3">
    <div class="w-100 flex-row justify-center">
      <v-icon size="64">{{ mdiLockReset }} </v-icon>
    </div>
    <v-card-title class="justify-start text-h5">Password</v-card-title>
    <v-card-subtitle> &nbsp; </v-card-subtitle>
    <v-card-text class="text-left">
      <div style="max-width: 25em">
        Enhance the security of your password, or consider changing it if it's
        known by someone else.
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn class="text-none" text color="primary">Change Password </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import { useDialogStore } from "@/stores/dialog";
import { mdiLockReset } from "@mdi/js";
import { markRaw } from "vue";

import SecurityChangePasswordDialog from "@/components/settings/security/SecurityChangePasswordDialog.vue";

const dialog = useDialogStore();
function openChangePasswordDialog() {
  dialog.showDialog({
    component: markRaw(SecurityChangePasswordDialog)
  });
}
</script>
