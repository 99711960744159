<template>
  <v-card flat tile>
    <v-row class="ma-0">
      <v-col>
        <strong style="font-size: 1.2rem">
          <router-link
            v-if="contractPartyLink"
            :to="contractPartyLink"
            data-testid="card-insured"
          >
            {{ insuredName }}
          </router-link>
          <span v-else>{{ insuredName }}</span>
        </strong>
        <br />
        Insured
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="ma-0 pa-3 py-1" dense>
      <v-col
        v-for="detail in cardDetails"
        :key="detail.key"
        cols="12"
        md="6"
        lg="3"
        xl="3"
      >
        <component
          :is="detail.component"
          v-bind="detail.props"
          :data-testid="`card-${detail.props.title}`"
          lg="12"
          xl="12"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useQuoteViewStore } from "@/stores/quote-view";
import { computed } from "vue";
import { routerLinkItem, textItem } from "../shared/card-items/card-items";
import { fullStateDict } from "@/data/states";
import {
  currencyFormat,
  lineFormatter,
  timestampFormatter
} from "@/util/helpers";

const quoteViewStore = useQuoteViewStore();
const {
  agent,
  status,
  state,
  mode,
  faceAmount,
  premium,
  termDuration,
  line,
  typeOfFunds,
  concept,
  insuredName,
  insured,
  followUp
} = storeToRefs(quoteViewStore);

const cardDetails = computed(() => {
  const details = [];

  details.push(
    routerLinkItem({
      title: "Agent",
      text: agent.value.name,
      to: agent.value.routerLink
    })
  );

  if (status.value) {
    details.push(textItem({ title: "Status", text: status.value }));
  }

  if (state.value) {
    details.push(
      textItem({
        title: "State",
        text: fullStateDict[state.value]
      })
    );
  }

  if (mode.value) {
    details.push(
      textItem({
        title: "Mode",
        text: mode.value
      })
    );
  }

  if (faceAmount.value) {
    details.push(
      textItem({
        title: "Death Benefit",
        text: currencyFormat(faceAmount.value, 0)
      })
    );
  }

  if (premium.value) {
    details.push(
      textItem({
        title: "Premium",
        text: currencyFormat(premium.value, 2)
      })
    );
  }

  if (termDuration.value) {
    details.push(
      textItem({
        title: "Term Duration",
        text: termDuration.value
      })
    );
  }

  if (line.value) {
    details.push(
      textItem({
        title: "Line of Business",
        text: lineFormatter(line.value)
      })
    );
  }

  if (typeOfFunds.value) {
    details.push(
      textItem({
        title: "Tax Type",
        text: typeOfFunds.value
      })
    );
  }

  if (concept.value) {
    details.push(
      textItem({
        title: "Concept",
        text: `${concept.value} - ${currencyFormat(
          insured.value.qualifiedPlanBalance
        )}`
      })
    );
  }

  if (followUp.value) {
    details.push(
      textItem({
        title: "Follow Up",
        text: timestampFormatter(followUp.value, "sole-day")
      })
    );
  }

  return details;
});

const contractPartyLink = computed(() => {
  switch (insured.value.ownable.type) {
    case "individual":
      return {
        name: "IndividualView",
        params: {
          id: insured.value.ownable.id
        }
      };
    case "entity":
      return {
        name: "EntityView",
        params: {
          id: insured.value.ownable.id
        }
      };
    case "contract_party":
      return {
        name: "ContractPartyView",
        params: {
          id: insured.value.ownable.id
        }
      };
    default:
      return null;
  }
});
</script>
