<template>
  <v-card tile flat>
    <v-row class="px-6 py-3">
      <v-spacer />
      <v-btn
        v-if="statement.document"
        rounded
        color="primary"
        outlined
        class="mr-1 text-none"
        @click="download"
        :loading="downloadingDocument"
      >
        <v-icon> $mdi-download </v-icon> Download
      </v-btn>
      <div v-if="statement.nonImportableDocument" class="mr-1 dual-link-button">
        <v-btn
          rounded
          color="primary"
          outlined
          class="text-none left-button"
          :loading="downloadingNonImportableDocument"
          @click="downloadNonImportableDocument"
        >
          <v-icon> $mdi-download </v-icon> Download PDF Statement
        </v-btn>
        <v-btn
          rounded
          color="primary"
          outlined
          class="text-none right-button"
          @click="uploadNonImportableDocument(true)"
        >
          <v-icon> $mdi-file-replace </v-icon>
        </v-btn>
      </div>
      <v-btn
        v-else
        rounded
        color="primary"
        outlined
        class="mr-1 text-none"
        @click="uploadNonImportableDocument(false)"
      >
        <v-icon> $mdi-upload </v-icon> Upload PDF Statement
      </v-btn>
      <v-btn
        v-if="statement.errorsDocument"
        rounded
        color="primary"
        outlined
        class="mr-1 text-none"
        @click="downloadErrorDocument"
        :loading="downloadingErrorDocument"
      >
        <v-icon> $mdi-download </v-icon> Download Error Document
      </v-btn>
      <v-btn
        rounded
        color="error"
        outlined
        class="text-none"
        @click="handleStatementDelete"
        v-if="statement.deletable"
      >
        <v-icon> $mdi-delete </v-icon> Delete
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
import { downloadFile } from "@/util/helpers";
import { mapActions } from "pinia";
import { deleteStatement, uploadStatementFile } from "@/api/statements.service";
import { getDocumentUrl } from "@/api/documents.service";
import { useDialogStore } from "@/stores/dialog";

export default {
  props: {
    statement: Object
  },
  data() {
    return {
      downloadingNonImportableDocument: false,
      downloadingDocument: false,
      downloadingErrorDocument: false
    };
  },

  methods: {
    ...mapActions(useDialogStore, ["showDialog"]),
    download() {
      return downloadFile(
        getDocumentUrl(this.statement.document.uid),
        this.$vuetify.breakpoint.mdAndDown
      );
    },
    downloadNonImportableDocument() {
      return downloadFile(
        getDocumentUrl(this.statement.nonImportableDocument.uid),
        this.$vuetify.breakpoint.mdAndDown
      );
    },
    downloadErrorDocument() {
      return downloadFile(
        getDocumentUrl(this.statement.errorsDocument.uid),
        this.$vuetify.breakpoint.mdAndDown
      );
    },
    uploadNonImportableDocument(replace = false) {
      this.showDialog({
        component: "DocumentDialog",
        scrollable: true,
        title: replace ? "Replace Statement PDF" : "Add Statement PDF",
        single: true,
        func: async ({ file }) => {
          const res = await uploadStatementFile({
            id: this.statement.id,
            category: "Non Importable Transactions",
            file
          });
          this.statement.nonImportableDocument = res;
        }
      });
    },
    handleStatementDelete() {
      return this.showDialog({
        component: "DeleteDialog",
        func: () => deleteStatement(this.statement.id),
        title: "Are you sure that you want to delete this statement?",
        subtitle: "This cannot be undone"
      }).then(res => {
        if (!res?.delete) return;
        this.$router.push({ name: "BackNineCommissionsStatements" });
      });
    }
  }
};
</script>
<style lang="scss">
.dual-link-button {
  flex-wrap: nowrap;
  .left-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-color: white !important;
    border-right-style: solid;
    border-right-width: 0 !important;
  }

  .right-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    min-width: unset !important;
  }
}
</style>
