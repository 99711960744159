<template>
  <v-card color="section" flat tile>
    <v-card-title>Contracting Forms</v-card-title>
    <v-card-subtitle>Select your forms.</v-card-subtitle>
    <v-alert
      v-if="contractingErrors"
      type="error"
      class="ma-3"
      data-testid="contracting-errors"
    >
      {{ contractingErrors }}
    </v-alert>
    <v-card-text class="pa-0">
      <div class="checkbox-width">
        <v-checkbox
          v-model="selectAllForms"
          data-testid="select-all-forms-checkbox"
          class="mx-4"
          hide-details
          :label="selectAllFormsLabel"
        />
      </div>
      <v-sheet
        v-for="group in groupedForms"
        color="section"
        rounded
        class="ma-1 pa-3"
        :data-testid="group.title"
        :key="group.title"
      >
        <h3>{{ group.title }}</h3>
        <v-row dense>
          <v-col
            v-for="form in group.forms"
            data-testid="form-item"
            cols="12"
            :key="form.id"
          >
            <div class="checkbox-width">
              <v-checkbox
                v-model="includedForms"
                class="mt-0"
                hide-details
                data-testid="include-form-checkbox"
                :value="form.id"
              >
                <template #label>
                  <span v-if="form.tag == 'form'">
                    <strong>{{ form.category }}</strong> -
                    <span class="grey--text">{{ form.name }}</span>
                  </span>
                  <span v-else>
                    <strong>{{ form.name }}</strong> -
                    <span class="grey--text">{{ form.tag }}</span>
                  </span>
                </template>
              </v-checkbox>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
      <v-row class="pa-3 ma-0 mx-2">
        <v-btn
          type="button"
          class="text-none mr-1"
          color="primary"
          outlined
          data-testid="download-forms-button"
          :loading="downloadingForms"
          :disabled="sendingCarrierForms"
          @click="downloadForms"
        >
          <v-icon class="mr-1">$mdi-download</v-icon>Download Forms
        </v-btn>
        <v-btn
          v-if="!contractingErrors"
          type="button"
          class="text-none"
          color="primary"
          outlined
          data-testid="email-forms-button"
          :loading="sendingCarrierForms"
          :disabled="downloadingForms"
          @click="emailFormsToCarrier"
        >
          <v-icon class="mr-1">$mdi-send</v-icon>Email Carrier
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script setup>
import { computed, onBeforeUnmount, ref } from "vue";

import {
  downloadContractingForms,
  emailContractingForms
} from "@/api/appointment.service";
import { getDocumentUrl } from "@/api/documents.service";

import { initSocketForAppointmentContracting } from "@/util/socket-helper-service";
import { downloadFile, parseErrorMessage } from "@/util/helpers";

import { storeToRefs } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useAppointmentView } from "@/stores/appointment-view";
import { useVuetify } from "@/composables/compatible.composables";

const snackbar = useSnackbarStore();
const vuetify = useVuetify();

const { contractingForms, contractingErrors, id, carrier } = storeToRefs(
  useAppointmentView()
);

const includedForms = ref([]);
const downloadingForms = ref(false);
const sendingCarrierForms = ref(false);

const groupedForms = computed(() => {
  const groups = {
    Form: { title: "Form", forms: [] },
    Other: { title: "Other", forms: [] }
  };

  for (let i = 0; i < contractingForms.value.length; i++) {
    const tag = contractingForms.value[i].tag === "form" ? "Form" : "Other";
    groups[tag].forms.push(contractingForms.value[i]);
  }

  return Object.values(groups);
});

const allFormsAreSelected = computed(() =>
  contractingForms.value.every(({ id }) => includedForms.value.includes(id))
);
const selectAllFormsLabel = computed(() =>
  allFormsAreSelected.value ? "Deselect All" : "Select All"
);
const selectAllForms = computed({
  get() {
    return allFormsAreSelected.value;
  },
  set(v) {
    includedForms.value.splice(0, includedForms.value.length);
    if (v) {
      contractingForms.value.forEach(({ id }) => includedForms.value.push(id));
    }
  }
});

onBeforeUnmount(() => {});

async function downloadForms() {
  downloadingForms.value = true;

  const pusher = initSocketForAppointmentContracting(id.value, {
    fetchFormsHandler: () => fetchFormsHandler(pusher),
    documentHandler: id => {
      downloadFile(getDocumentUrl(id), vuetify.breakpoint.mdAndDown);
      downloadingForms.value = false;
      if (pusher) pusher.disconnect();
    },
    errorHandler: e => {
      snackbar.showErrorSnackbar({
        message: parseErrorMessage(e),
        timeout: -1
      });
      downloadingForms.value = false;
      if (pusher) pusher.disconnect();
    }
  });
}

async function fetchFormsHandler(pusher) {
  if (!includedForms.value.length) {
    snackbar.showErrorSnackbar({
      message: "Error, at least one form is required",
      timeout: 5000
    });
    return;
  }

  try {
    await downloadContractingForms(id.value, includedForms.value);
    snackbar.showSuccessSnackbar({
      message: "Please wait 30 seconds for download",
      timeout: 15000
    });
  } catch (e) {
    downloadingForms.value = false;
    snackbar.showErrorSnackbar({
      message: `Error Starting Contracting ${parseErrorMessage(e)}`,
      timeout: 10000
    });
    if (pusher) pusher.disconnect();
  }
}

async function emailFormsToCarrier() {
  if (!includedForms.value.length) {
    snackbar.showErrorSnackbar({
      message: "Error, at least one form is required",
      timeout: 5000
    });
    return;
  }

  sendingCarrierForms.value = true;
  try {
    await emailContractingForms(id.value, includedForms.value);
    snackbar.showSuccessSnackbar({
      message: `Sending contracting forms to ${carrier.value.name}...`,
      timeout: 5000
    });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: `Error Starting Contracting ${parseErrorMessage(e)}`,
      timeout: 10000
    });
  } finally {
    sendingCarrierForms.value = false;
  }
}

function setDefaultForms() {
  includedForms.value.splice(0, includedForms.value.length);
  contractingForms.value.forEach(({ id, default: isDefault }) => {
    if (isDefault) includedForms.value.push(id);
  });
}

setDefaultForms();
</script>
