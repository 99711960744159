import { getHttpClient } from "@/http-client";
const baseUrl = "api/boss/forms";
import { serializeObject } from "@/util/helpers";

import { useInstanceStore } from "@/stores/instance";

export const getPreviewUrl = formId => {
  const instance = useInstanceStore();
  return `${instance.apiUrl}/${baseUrl}/${formId}/preview`;
};

export const fetchForm = async formId => {
  const { data } = await getHttpClient().get(`${baseUrl}/${formId}`);

  return data.form;
};

export const VIRTUAL_FORM_CATEGORY_VALUE = "virtual"; // used for requirements
export const getFormCategories = async () => {
  const { data } = await getHttpClient().get(`${baseUrl}/categories`);
  data.sort((a, b) => a.label.localeCompare(b.label));
  return data;
};

export async function getFormSubmissionMethods() {
  return [
    { label: "Paper", value: "Paper" },
    { label: "eApp", value: "eApp" },
    { label: "Manulife API", value: "manulife_api" }
  ];
}

export async function createForm(form) {
  const { data } = await getHttpClient().post(
    baseUrl,
    await serializeObject(form)
  );

  return data.form.id;
}

export const deleteForm = formId => {
  return getHttpClient().delete(`${baseUrl}/${formId}`);
};

export async function updateForm(formId, body) {
  return getHttpClient().put(
    `${baseUrl}/${formId}`,
    await serializeObject(body)
  );
}

export const pdfUrl = formId => {
  const instance = useInstanceStore();
  return `${instance.apiUrl}/${baseUrl}/${formId}?download`;
};

export const pdfSvgUrl = formId => {
  const instance = useInstanceStore();
  return `${instance.apiUrl}/${baseUrl}/${formId}.svg?download&encode=data-uri`;
};

export const verifyActiveStatus = async formId => {
  const { data } = await getHttpClient().put(`${baseUrl}/${formId}/follow-up`);

  return data.form.follow_up_at;
};

function FormTableItem(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    category: model?.category,
    carrierName: model?.carrierName,
    stateCount: model?.stateCount,
    updatedAt: model?.updatedAt,
    followUpAt: model?.followUpAt,
    status: model?.status
  };
}

function setFormTableItemFromRequest(req = {}) {
  const form = FormTableItem();

  form.id = req?.id;
  form.name = req?.name;
  form.category = req?.category;
  form.carrierName = req?.carrier?.name;
  form.stateCount = req?.states_count;
  form.updatedAt = req?.updated_at;
  form.followUpAt = req?.follow_up_at;
  form.status = req?.status;

  return form;
}

let cancelToken = null;
export async function getForms(params) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!response?.data) return;

  return {
    items: response.data.forms.map(setFormTableItemFromRequest),
    meta: response.data.meta
  };
}

export const getFormPdfUrl = formId => {
  const instance = useInstanceStore();
  return `${instance.apiUrl}/${baseUrl}/${formId}?download`;
};

export const verifyForm = formId => {
  return getHttpClient().put(`${baseUrl}/${formId}/verify`);
};

export const getFormsByName = name => {
  const params = new URLSearchParams();
  params.append("name", name);

  return getHttpClient().get(`${baseUrl}/search`, { params });
};

export const deleteFormPage = (formId, pageNumber) => {
  return getHttpClient().delete(`${baseUrl}/${formId}/pages/${pageNumber}`);
};
