<template>
  <v-card flat>
    <v-card-title class="text-h4 mb-4"> Create Product </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-card-title>Product Details</v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" lg="4" md="6">
                  <v-text-field
                    v-model="product.name"
                    autofocus
                    label="Name"
                    prepend-inner-icon="$mdi-cube-outline"
                    data-lpignore="true"
                    data-testid="product-name"
                    outlined
                    dense
                    :success="nameValidation.success"
                    :error-messages="nameValidation.errorMessages"
                  />
                </v-col>
                <v-col cols="12" lg="4" md="6">
                  <carrier-search
                    v-model="product.carrier"
                    data-testid="product-carrier"
                    :success="carrierValidation.success"
                    :error-messages="carrierValidation.errorMessages"
                  />
                </v-col>
                <v-col cols="12" lg="4" md="6">
                  <v-autocomplete
                    v-model="product.line"
                    label="Line"
                    placeholder="Select Line"
                    prepend-inner-icon="$mdi-cube"
                    data-testid="product-line"
                    auto-select-first
                    outlined
                    dense
                    :items="LINES"
                    :success="lineValidation.success"
                    :error-messages="lineValidation.errorMessages"
                  />
                </v-col>
                <v-col cols="12" lg="4" md="6">
                  <v-autocomplete
                    v-model="product.linePercentage"
                    label="Line Percentage"
                    prepend-inner-icon="$mdi-percent"
                    data-testid="product-line-percentage"
                    auto-select-first
                    outlined
                    dense
                    :disabled="isAnnuity"
                    :items="LINE_PERCENTAGES"
                    :success="linePercentageValidation.success"
                    :error-messages="linePercentageValidation.errorMessages"
                  />
                </v-col>
                <v-col cols="12" lg="4" md="6">
                  <integer-input
                    v-model="product.activeYears"
                    label="Active Years"
                    prepend-inner-icon="$mdi-calendar"
                    data-testid="product-active-years"
                    outlined
                    dense
                    :success="activeYearsValidation.success"
                    :error-messages="activeYearsValidation.errorMessages"
                  />
                </v-col>
                <v-col cols="12" lg="4" md="6">
                  <v-text-field
                    v-model="product.chargeback"
                    label="Chargeback"
                    data-lpignore="true"
                    prepend-inner-icon="$mdi-credit-card"
                    data-testid="product-chargeback"
                    outlined
                    dense
                    :success="Boolean(product.chargeback)"
                  />
                </v-col>
                <v-col v-if="showSurrenderPeriod" cols="12" lg="4" md="6">
                  <integer-input
                    v-model="product.surrenderPeriod"
                    label="Surrender Period"
                    prepend-inner-icon="$mdi-calendar"
                    data-testid="product-surrender-period"
                    outlined
                    dense
                    :success="Boolean(product.surrenderPeriod)"
                  />
                </v-col>
                <v-col v-if="showRollingTarget" cols="12" lg="4" md="6">
                  <v-text-field
                    v-model="product.rollingTarget"
                    label="Rolling Target"
                    data-lpignore="true"
                    prepend-inner-icon="$mdi-sack"
                    data-testid="product-rolling-target"
                    outlined
                    dense
                    :success="Boolean(product.rollingTarget)"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" lg="4" md="6">
                  <div class="checkbox-width">
                    <v-checkbox
                      v-model="product.eDelivery"
                      label="Carrier eDelivery"
                      data-testid="product-e-delivery"
                      class="mt-1"
                      dense
                      :success="product.eDelivery"
                    >
                    </v-checkbox>
                  </div>
                </v-col>
                <v-col v-if="showAgeNearest" cols="12" lg="4" md="6">
                  <div class="checkbox-width">
                    <v-checkbox
                      v-model="product.ageNearest"
                      label="Age Nearest"
                      class="mt-1"
                      data-testid="product-age-nearest"
                      dense
                      :success="Boolean(product.ageNearest)"
                    >
                    </v-checkbox>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card outlined>
            <v-card-title> Product API </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="6">
                  <cacheable-search-list
                    v-model="product.type"
                    label="Type"
                    outlined
                    dense
                    auto-select-first
                    data-testid="product-type"
                    cache-fetch-key="getProductMeta"
                    cache-items-key="productTypes"
                    :success="typeValidation.success"
                    :error-messages="typeValidation.errorMessages"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <cacheable-search-list
                    v-model="product.traits"
                    label="Traits"
                    multiple
                    outlined
                    dense
                    chips
                    small-chips
                    deletable-chips
                    auto-select-first
                    data-testid="product-traits"
                    cache-fetch-key="getProductMeta"
                    cache-items-key="productTraits"
                    :success="traitsValidation.success"
                    :error-messages="traitsValidation.errorMessages"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card outlined>
            <v-card-title>Product Percentages</v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" lg="4" md="6">
                  <decimal-input
                    v-model="product.streetFirst"
                    label="Street First"
                    prepend-inner-icon="$mdi-percent"
                    data-testid="product-street-first"
                    outlined
                    dense
                    :max="200"
                    :decimal-length="4"
                    :success="streetFirstValidation.success"
                    :error-messages="streetFirstValidation.errorMessages"
                  />
                </v-col>
                <v-col cols="12" lg="4" md="6">
                  <decimal-input
                    v-model="product.streetExcess"
                    label="Street Excess"
                    prepend-inner-icon="$mdi-percent"
                    data-testid="product-street-excess"
                    outlined
                    dense
                    :max="100"
                    :decimal-length="4"
                    :success="streetExcessValidation.success"
                    :error-messages="streetExcessValidation.errorMessages"
                  />
                </v-col>
                <v-col cols="12" lg="4" md="6">
                  <decimal-input
                    v-model="product.streetRenewal"
                    label="Street Renewal"
                    prepend-inner-icon="$mdi-percent"
                    data-testid="product-street-renewal"
                    outlined
                    dense
                    :max="100"
                    :decimal-length="4"
                    :success="streetRenewalValidation.success"
                    :error-messages="streetRenewalValidation.errorMessages"
                  />
                </v-col>
                <v-col v-if="showPua" cols="12" lg="4" md="6">
                  <decimal-input
                    v-model="product.streetPua"
                    label="Street PUA"
                    prepend-inner-icon="$mdi-percent"
                    data-testid="product-street-pua"
                    outlined
                    dense
                    :max="100"
                    :decimal-length="4"
                    :success="streetPuaValidation.success"
                    :error-messages="streetPuaValidation.errorMessages"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" lg="4" md="6">
                  <decimal-input
                    v-model="product.totalFirst"
                    label="Total First"
                    prepend-inner-icon="$mdi-percent"
                    data-testid="product-total-first"
                    outlined
                    dense
                    :max="300"
                    :decimal-length="4"
                    :success="totalFirstValidation.success"
                    :error-messages="totalFirstValidation.errorMessages"
                  />
                </v-col>
                <v-col cols="12" lg="4" md="6">
                  <decimal-input
                    v-model="product.totalExcess"
                    label="Total Excess"
                    prepend-inner-icon="$mdi-percent"
                    data-testid="product-total-excess"
                    outlined
                    dense
                    :max="200"
                    :decimal-length="4"
                    :success="totalExcessValidation.success"
                    :error-messages="totalExcessValidation.errorMessages"
                  />
                </v-col>
                <v-col cols="12" lg="4" md="6">
                  <decimal-input
                    v-model="product.totalRenewal"
                    label="Total Renewal"
                    prepend-inner-icon="$mdi-percent"
                    data-testid="product-total-renewal"
                    outlined
                    dense
                    :max="200"
                    :decimal-length="4"
                    :success="totalRenewalValidation.success"
                    :error-messages="totalRenewalValidation.errorMessages"
                  />
                </v-col>
                <v-col v-if="showPua" cols="12" lg="4" md="6">
                  <decimal-input
                    v-model="product.totalPua"
                    label="Total PUA"
                    prepend-inner-icon="$mdi-percent"
                    data-testid="product-total-pua"
                    outlined
                    dense
                    :max="200"
                    :decimal-length="4"
                    :success="totalPuaValidation.success"
                    :error-messages="totalPuaValidation.errorMessages"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="text-none"
        color="primary"
        data-testid="product-create"
        :loading="loading"
        @click="createProduct"
      >
        <v-icon class="mr-1">$mdi-plus</v-icon>
        Create Product
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import IntegerInput from "@/components/shared/IntegerInput.vue";
import DecimalInput from "@/components/shared/DecimalInput.vue";
import CacheableSearchList from "@/components/shared/CacheableSearchList.vue";
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import {
  LINES,
  LINE_PERCENTAGES,
  ProductCreate,
  ProductCreateToCreateRequest
} from "@/factories/ProductFactory";

import { productCreate } from "@/api/products.service";
import { parseErrorMessage, validationComputeV2 } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { computed, ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { useRouter } from "@/composables/compatible.composables";

useHead({
  title: "Create Product"
});

const snackbar = useSnackbarStore();
const loading = ref(false);
const product = ref(ProductCreate());
const router = useRouter();

// Ask Reid
const showPua = computed(() => product.value.type === "whole_life");
const percentValidator = (min, max) => v => v !== null && v <= max && v >= min;
const required = v => Boolean(v);
const v$ = useVuelidate(
  {
    product: {
      name: { required },
      carrier: { id: { required } },
      line: { required },
      linePercentage: { required },
      activeYears: { required },
      streetFirst: { required: percentValidator(0, 200) },
      streetExcess: { required: percentValidator(0, 100) },
      streetRenewal: { required: percentValidator(0, 100) },
      streetPua: {
        required: v => !showPua.value || percentValidator(0, 100)(v)
      },
      type: { required },
      traits: { required: v => v.length >= 1 },
      totalFirst: { required: percentValidator(0, 300) },
      totalExcess: { required: percentValidator(0, 200) },
      totalRenewal: { required: percentValidator(0, 200) },
      totalPua: {
        required: val => !showPua.value || percentValidator(0, 200)(val)
      }
    }
  },
  { product },
  { $scope: false, $autoDirty: true }
);

const validatesRequired = { key: "required", message: "Required" };

const nameValidation = computed(() =>
  validationComputeV2(v$.value.product.name, [validatesRequired])
);
const carrierValidation = computed(() =>
  validationComputeV2(v$.value.product.carrier.id, [validatesRequired])
);
const lineValidation = computed(() =>
  validationComputeV2(v$.value.product.line, [validatesRequired])
);
const linePercentageValidation = computed(() =>
  validationComputeV2(v$.value.product.linePercentage, [validatesRequired])
);
const activeYearsValidation = computed(() =>
  validationComputeV2(v$.value.product.activeYears, [validatesRequired])
);
const streetFirstValidation = computed(() =>
  validationComputeV2(v$.value.product.streetFirst, [validatesRequired])
);
const streetExcessValidation = computed(() =>
  validationComputeV2(v$.value.product.streetExcess, [validatesRequired])
);
const streetRenewalValidation = computed(() =>
  validationComputeV2(v$.value.product.streetRenewal, [validatesRequired])
);
const streetPuaValidation = computed(() =>
  validationComputeV2(v$.value.product.streetPua, [validatesRequired])
);
const totalFirstValidation = computed(() =>
  validationComputeV2(v$.value.product.totalFirst, [validatesRequired])
);
const totalExcessValidation = computed(() =>
  validationComputeV2(v$.value.product.totalExcess, [validatesRequired])
);
const totalRenewalValidation = computed(() =>
  validationComputeV2(v$.value.product.totalRenewal, [validatesRequired])
);
const totalPuaValidation = computed(() =>
  validationComputeV2(v$.value.product.totalPua, [validatesRequired])
);
const typeValidation = computed(() =>
  validationComputeV2(v$.value.product.type, [validatesRequired])
);
const traitsValidation = computed(() =>
  validationComputeV2(v$.value.product.traits, [validatesRequired])
);

const showAgeNearest = computed(() =>
  ["life", "linked_benefit", "variable_life", "ltc"].includes(
    product.value.line
  )
);

const showRollingTarget = computed(() =>
  ["life", "linked_benefit", "variable_life"].includes(product.value.line)
);

const showSurrenderPeriod = computed(() =>
  ["life", "linked_benefit", "variable_life", "annuity"].includes(
    product.value.line
  )
);

const isAnnuity = computed(() => product.value.line === "annuity");

watch(showAgeNearest, () => (product.value.ageNearest = null));
watch(showRollingTarget, () => (product.value.rollingTarget = null));
watch(showSurrenderPeriod, () => (product.value.surrenderPeriod = null));
watch(isAnnuity, v => (product.value.linePercentage = v ? 5 : null));
watch(showPua, () => {
  product.value.streetPua = null;
  product.value.totalPua = null;
});

async function createProduct() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  loading.value = true;
  try {
    const id = await productCreate({
      product: ProductCreateToCreateRequest(product.value)
    });

    if (router?.push) router.push({ name: "ProductView", params: { id } });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  } finally {
    loading.value = false;
  }
}
</script>
