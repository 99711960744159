<template>
  <v-card color="section" tile flat>
    <v-card-text>
      <v-sheet rounded class="pa-3">
        <v-select
          :value="appointmentManager"
          outlined
          dense
          return-object
          persistent-hint
          data-testid="appointment-manager"
          item-text="name"
          label="Appointment Manager"
          prepend-inner-icon="$mdi-account-supervisor"
          hint="Add an appointment manager to the access table below to make them appear in this list."
          :items="appointmentManagers"
          @change="changeAppointmentManager"
        >
          <template #append-outer>
            <active-save-indicator
              :controller="savingBuffer.appointmentManager.controller.value"
            />
          </template>
        </v-select>
      </v-sheet>
    </v-card-text>
    <v-card-text>
      <v-sheet rounded>
        <access
          v-model="room"
          type="appointment"
          data-testid="appointment-access-table"
          button-color="accent"
          button-text="Add Access"
          :checkboxes="checkboxes"
          :carrier-id="carrier.id"
          :readonly="!isGroupTwoPlus"
          :deletable="deletable"
          :update-func="updateAccess"
          :delete-func="removeAccess"
          :add-func="addAccess"
          :name-link="nameLinkGenerator"
        />
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script setup>
import Access from "@/components/shared/Access.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { useActiveSave } from "@/composables/active-save.composable";
import { storeToRefs } from "pinia";
import { useAppointmentView } from "@/stores/appointment-view";
import { computed, ref } from "vue";
import { useUserStore } from "@/stores/user";

const user = useUserStore();
const { isGroupTwoPlus } = storeToRefs(user);

const appointment = useAppointmentView();
const { room, carrier } = storeToRefs(appointment);

const checkboxes = [
  { text: "Advisor Access", value: "advisorAccess" },
  { text: "Advisor Email", value: "email" },
  { text: "Carrier Access", value: "carrierAccess" },
  { text: "Carrier Email", value: "carrierEmail" }
];

const savingBuffer = ref({ appointmentManager: useActiveSave() });

const appointmentManagers = computed(() =>
  room.value.filter(v => v.canBeAppointmentManager)
);
const appointmentManager = computed(() =>
  room.value.find(v => v.appointmentManager)
);

function changeAppointmentManager(v) {
  savingBuffer.value.appointmentManager.update(() =>
    appointment.updateAppointmentManager(v)
  );
}

async function addAccess(access) {
  return appointment.createRoomAccess(access);
}

async function removeAccess(access) {
  return appointment.removeRoomAccess(access.additional);
}

function updateAccess(access, attribute) {
  return appointment.updateRoomAccess(access.additional, attribute);
}

function nameLinkGenerator(access) {
  return access.additional.routerLink;
}

function deletable(access) {
  return !access.additional.owner;
}
</script>
