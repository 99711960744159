<template>
  <div>
    <v-fade-transition mode="out-in">
      <v-row v-if="loading" class="ma-0 pa-3" justify="center" align="center">
        <v-progress-circular indeterminate />
      </v-row>
      <v-row v-else class="ma-0 pa-3" key="loaded" dense>
        <v-col data-testid="todo-description" cols="12">
          <h3 class="text-body-2 grey--text lighten-2">Description</h3>
          <div class="text-body-1">
            {{ todo.description || "No Description" }}
          </div>
        </v-col>
        <v-col data-testid="todo-subjects" cols="12" md="6">
          <h3 class="text-body-2 grey--text lighten-2">Subjects</h3>
          <v-chip-group v-if="todo.subjects.length" column class="ma-n1">
            <v-chip
              v-for="subject in todo.subjects"
              :key="subject.key"
              :to="getRouterLink(subject.type, subject.id)"
              color="primary"
              target="_blank"
              class="ma-1"
              outlined
              small
              label
              exact
            >
              <v-icon small class="mr-1">{{
                getTypeIcon(subject.type)
              }}</v-icon>
              {{ subject.name }}
            </v-chip>
          </v-chip-group>
          <div v-else>No subjects</div>
        </v-col>
        <v-col data-testid="todo-assignees" cols="12" md="6">
          <h3 class="text-body-2 grey--text lighten-2">Assignees</h3>
          <v-chip-group v-if="todo.assignables.length" column class="ma-n1">
            <v-chip
              v-for="assignee in todo.assignables"
              :key="assignee.key"
              :to="getRouterLink(assignee.type, assignee.id)"
              color="primary"
              target="_blank"
              class="ma-1"
              outlined
              small
              label
              exact
            >
              <v-icon small class="mr-1">{{
                getTypeIcon(assignee.type)
              }}</v-icon>
              {{ assignee.name }}
            </v-chip>
          </v-chip-group>
          <div v-if="!todo.assignables.length">No assignables</div>
        </v-col>
        <v-col data-testid="todo-created-on" cols="12" md="6">
          <h3 class="text-body-2 grey--text lighten-2 pr-1">Created on</h3>
          <span class="text-body-1">
            {{ createdAt }}
          </span>
        </v-col>
        <v-col data-testid="todo-created-by" cols="12" md="6">
          <h3 class="text-body-2 grey--text lighten-2 pr-1">Created by</h3>
          <span class="text-body-1">
            {{ todo.ownableName }}
          </span>
        </v-col>
        <v-col data-testid="todo-documents" cols="12">
          <h3 class="text-body-2 grey--text lighten-2 pr-1">
            Related Documents
          </h3>
          <v-chip-group v-if="todo.documents.length" column class="ma-n1">
            <v-chip
              v-for="doc in todo.documents"
              :key="doc.id"
              v-bind="getDownload(doc)"
              color="primary"
              class="ma-1"
              outlined
              small
              label
            >
              <v-icon small class="mr-1">{{ mdiDownload }}</v-icon>
              {{ doc.name }}
            </v-chip>
          </v-chip-group>
          <div v-else>No documents</div>
        </v-col>
      </v-row>
    </v-fade-transition>
  </div>
</template>

<script setup>
import { getTodo } from "@/api/todos.service";
import {
  downloadFileAsLink,
  parseErrorMessage,
  timestampFormatter
} from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { ref, defineProps, set, computed } from "vue";
import { getTypeIcon, getRouterLink } from "@/util/helpers";
import { getDocumentUrl } from "@/api/documents.service";
import { useVuetify } from "@/composables/compatible.composables";
import { mdiDownload } from "@mdi/js";
const props = defineProps({
  id: {
    type: Number,
    required: true
  }
});

const snackbar = useSnackbarStore();
const loading = ref(false);
const todo = ref(null);

const createdAt = computed(() => {
  if (!todo.value) return "";
  return timestampFormatter(todo.value.createdAt, "none", "date-time");
});
async function fetchTodo() {
  try {
    loading.value = true;
    const res = await getTodo(props.id);
    set(todo, "value", res);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

const vuetify = useVuetify();
function getDownload(document) {
  return downloadFileAsLink(
    getDocumentUrl(document.uid),
    vuetify.breakpoint.mdAndDown
  );
}
fetchTodo();
</script>
