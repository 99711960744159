<template>
  <v-card color="section" tile>
    <v-card-text>
      <v-sheet rounded>
        <v-row dense class="ma-0 pa-3">
          <v-col cols="12"> <h3 class="text-h6">Engine</h3> </v-col>
          <v-col cols="12" md="6">
            <v-select
              outlined
              dense
              label="Type"
              v-model="product.type"
              :items="categories.types"
              :success="Boolean(product.type)"
              :loading="loadingCategories"
              clearable
              @change="updateAttribute('type')"
              @click:clear="
                product.type = null;
                updateAttribute('type');
              "
            >
              <template #append-outer>
                <active-save-indicator :controller="savingBuffer['type']" />
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              outlined
              dense
              label="Traits"
              v-model="product.traits"
              :items="categories.traits"
              :success="Boolean(product.traits && product.traits.length)"
              :loading="loadingCategories"
              clearable
              multiple
              @change="updateAttribute('traits')"
              @click:clear="
                product.traits = null;
                updateAttribute('traits');
              "
            >
              <template #append-outer>
                <active-save-indicator :controller="savingBuffer['traits']" />
              </template>
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              label="Form Set"
              v-model="product.formSet"
              :items="formSets"
              :item-text="val => `${val.id} · ${val.name}`"
              item-value="id"
              dense
              outlined
              :disabled="loadingFormSets"
              :success="Boolean(product.formSet)"
              clearable
              @change="updateAttribute('formSet')"
              :loading="loadingFormSets"
            >
              <template #append-outer>
                <active-save-indicator
                  class="ml-3"
                  :controller="savingBuffer['formSet']"
                />
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-divider class="my-3" />
        <v-row dense class="ma-0 pa-3">
          <v-col cols="12">
            <h3 class="text-h6">Visibility Control</h3>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <div class="checkbox-width">
              <v-checkbox
                v-model="product.strifeEnabled"
                :success="Boolean(product.strifeEnabled)"
                @change="updateAttribute('strifeEnabled')"
              >
                <template #label>
                  Q&A Enabled
                  <active-save-indicator
                    class="ml-3"
                    :controller="savingBuffer['strifeEnabled']"
                  />
                </template>
              </v-checkbox>
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="6"
            @click="primaryProduct = !primaryProduct"
          >
            <div class="checkbox-width">
              <v-checkbox
                label="Primary Product"
                :success="Boolean(primaryProduct)"
                readonly
                v-model="primaryProduct"
              >
                <template #label>
                  Primary Product
                  <active-save-indicator
                    class="ml-2"
                    :controller="savingBuffer['primaryProduct']"
                  />
                </template>
              </v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <div class="checkbox-width">
              <v-checkbox
                v-model="product.advisorUseOnly"
                :success="Boolean(product.advisorUseOnly)"
                @change="updateAttribute('advisorUseOnly')"
              >
                <template #label>
                  Advisor Use Only
                  <active-save-indicator
                    class="ml-3"
                    :controller="savingBuffer['advisorUseOnly']"
                  />
                </template>
              </v-checkbox>
            </div>
          </v-col>
        </v-row>
        <v-row dense class="ma-0 pa-3">
          <v-col cols="12" md="6">
            <v-text-field
              data-lpignore="true"
              label="Min Age"
              v-model="product.minAge"
              outlined
              dense
              :success="Boolean(product.minAge) || product.minAge === 0"
              @input="debounceUpdate('minAge')"
            >
              <template #append-outer>
                <active-save-indicator
                  class="ml-3"
                  :controller="savingBuffer['minAge']"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              data-lpignore="true"
              label="Max Age"
              v-model="product.maxAge"
              outlined
              dense
              :success="Boolean(product.maxAge) || product.maxAge === 0"
              @input="debounceUpdate('maxAge')"
            >
              <template #append-outer>
                <active-save-indicator
                  class="ml-3"
                  :controller="savingBuffer['maxAge']"
                />
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="ma-0 pa-3">
          <v-col cols="12" md="6">
            <currency-input
              v-model="product.minDeathBenefit"
              label="Min Death Benefit"
              outlined
              dense
              :success="
                Boolean(product.minDeathBenefit) ||
                product.minDeathBenefit === 0
              "
              @input="debounceUpdate('minDeathBenefit')"
            >
              <template #append-outer>
                <active-save-indicator
                  class="ml-3"
                  :controller="savingBuffer['minDeathBenefit']"
                />
              </template>
            </currency-input>
          </v-col>
          <v-col cols="12" md="6">
            <currency-input
              label="Max Death Benefit"
              v-model="product.maxDeathBenefit"
              outlined
              dense
              :success="
                Boolean(product.maxDeathBenefit) ||
                product.maxDeathBenefit === 0
              "
              @input="debounceUpdate('maxDeathBenefit')"
            >
              <template #append-outer>
                <active-save-indicator
                  class="ml-3"
                  :controller="savingBuffer['maxDeathBenefit']"
                />
              </template>
            </currency-input>
          </v-col>
        </v-row>
        <v-row v-if="isAnnuity" dense class="ma-0 pa-3">
          <v-col cols="12" lg="4" md="6">
            <currency-input
              label="Min Premium"
              v-model="product.minPremium"
              outlined
              dense
              :success="Boolean(product.minPremium) || product.minPremium === 0"
              @input="debounceUpdate('minPremium')"
            >
              <template #append-outer>
                <active-save-indicator
                  class="ml-3"
                  :controller="savingBuffer['minPremium']"
                />
              </template>
            </currency-input>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <currency-input
              label="Max Premium"
              v-model="product.maxPremium"
              outlined
              dense
              :success="Boolean(product.maxPremium) || product.maxPremium === 0"
              @input="debounceUpdate('maxPremium')"
            >
              <template #append-outer>
                <active-save-indicator
                  class="ml-3"
                  :controller="savingBuffer['maxPremium']"
                />
              </template>
            </currency-input>
          </v-col>
        </v-row>
        <v-row dense class="ma-0 pa-3">
          <v-col cols="12">
            <v-autocomplete
              v-model="product.payDuration"
              outlined
              dense
              label="Pay Duration"
              multiple
              deletable-chips
              small-chips
              persistent-hint
              hint="Leave blank to match for all pay durations"
              :items="PAY_DURATIONS"
              :success="Boolean(product.payDuration.length)"
              @input="debounceUpdate('payDuration')"
            >
              <template #append-outer>
                <active-save-indicator
                  class="ml-3"
                  :controller="savingBuffer['payDuration']"
                />
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-divider class="my-3" />
        <v-row dense class="ma-0 pa-3">
          <v-col cols="12">
            <h3 class="text-h6">Step Control</h3>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <v-select
              v-model="product.instantAps"
              label="Human API"
              outlined
              dense
              success
              :items="INSTANT_APS_OPTIONS"
              @change="updateAttribute('instantAps')"
            >
              <template #append-outer>
                <active-save-indicator
                  class="ml-3"
                  :controller="savingBuffer['instantAps']"
                />
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-divider class="my-3" />
        <v-row dense class="ma-0 pa-3">
          <v-col cols="12"> <h3 class="text-h6">Riders</h3> </v-col>
          <v-col cols="12" lg="4" md="6">
            <div class="checkbox-width">
              <v-checkbox
                v-model="product.criticalIllnessRider"
                :success="Boolean(product.criticalIllnessRider)"
                @change="updateAttribute('criticalIllnessRider')"
              >
                <template #label>
                  Critical Illness Rider
                  <active-save-indicator
                    class="ml-3"
                    :controller="savingBuffer['criticalIllnessRider']"
                  />
                </template>
              </v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <div class="checkbox-width">
              <v-checkbox
                v-model="product.chronicIllnessRider"
                :success="Boolean(product.chronicIllnessRider)"
                @change="updateAttribute('chronicIllnessRider')"
              >
                <template #label>
                  Chronic Illness Rider
                  <active-save-indicator
                    class="ml-3"
                    :controller="savingBuffer['chronicIllnessRider']"
                  />
                </template>
              </v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <div class="checkbox-width">
              <v-checkbox
                v-model="product.noCostChronicIllnessRider"
                :success="Boolean(product.noCostChronicIllnessRider)"
                @change="updateAttribute('noCostChronicIllnessRider')"
              >
                <template #label>
                  No Cost Chronic Illness Rider
                  <active-save-indicator
                    class="ml-3"
                    :controller="savingBuffer['noCostChronicIllnessRider']"
                  />
                </template>
              </v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <div class="checkbox-width">
              <v-checkbox
                v-model="product.noCostCriticalIllnessRider"
                :success="Boolean(product.noCostCriticalIllnessRider)"
                @change="updateAttribute('noCostCriticalIllnessRider')"
              >
                <template #label>
                  No Cost Critical Illness Rider
                  <active-save-indicator
                    class="ml-3"
                    :controller="savingBuffer['noCostCriticalIllnessRider']"
                  />
                </template>
              </v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <div class="checkbox-width">
              <v-checkbox
                v-model="product.ltcRider"
                @change="updateAttribute('ltcRider')"
              >
                <template #label>
                  LTC Rider
                  <active-save-indicator
                    class="ml-3"
                    :controller="savingBuffer['ltcRider']"
                  />
                </template>
              </v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <div class="checkbox-width">
              <v-checkbox
                v-model="product.vitalityRider"
                @change="updateAttribute('vitalityRider')"
              >
                <template #label>
                  Vitality Rider
                  <active-save-indicator
                    class="ml-3"
                    :controller="savingBuffer['vitalityRider']"
                  />
                </template>
              </v-checkbox>
            </div>
          </v-col>
        </v-row>
        <v-divider class="my-3" />
        <v-row dense class="ma-0 pa-3">
          <v-col cols="12"> <h3 class="text-h6">Params</h3> </v-col>
          <v-col cols="12" lg="4" md="6">
            <div class="checkbox-width">
              <v-checkbox
                class="mt-1"
                v-model="product.section1035Exchange"
                @change="updateAttribute('section1035Exchange')"
              >
                <template #label>
                  Section 1035 Exchange
                  <active-save-indicator
                    class="ml-3"
                    :controller="savingBuffer['section1035Exchange']"
                  />
                </template>
              </v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <product-solve-search
              v-model="product.solves"
              label="Valid Solves"
              :success="Boolean(product.solves.length)"
              @input="updateAttribute('solves')"
            >
              <template #append-outer>
                <active-save-indicator :controller="savingBuffer.solves" />
              </template>
            </product-solve-search>
          </v-col>
        </v-row>
      </v-sheet>

      <v-sheet rounded class="pa-3 mt-3">
        <h3 class="text-h6">Product Aliases</h3>
        <v-data-table
          :items="items"
          :headers="table.headers"
          class="transparent-data-table"
          :loading="loading"
          show-expand
          item-key="additional.id"
        >
          <template #top>
            <v-row class="ma-0">
              <v-spacer />
              <v-btn icon @click="getData" :disabled="loading">
                <v-icon>$mdi-refresh</v-icon>
              </v-btn>
              <v-btn icon @click="createNewAlias">
                <v-icon>$mdi-plus</v-icon>
              </v-btn>
            </v-row>
          </template>
          <template #[`expanded-item`]="{ item, headers }">
            <td :colspan="headers.length">
              <v-row class="ma-0">
                <v-col cols="12">
                  Included States:
                  {{ listToSentence(item.additional.includedStates) }}
                </v-col>
                <v-col cols="12">
                  <v-divider />
                </v-col>
                <v-col cols="12">
                  Excluded States
                  {{ listToSentence(item.additional.excludedStates) }}
                </v-col>
              </v-row>
            </td>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn icon @click="editAlias(item)" color="accent">
              <v-icon>$mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="destroyAlias(item)"
              :loading="item.additional.tableHelpers.deleting"
              color="error"
            >
              <v-icon>$mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import ProductSolveSearch from "@/components/shared/ProductSolveSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

import Table from "@/classes/data-table/Table";
import TableHeader from "@/classes/data-table/TableHeader";

import {
  productSave,
  getProductAliases,
  deleteAlias
} from "@/api/products.service";

import { parseErrorMessage, listToSentence } from "@/util/helpers";
import { mapActions } from "pinia";
import { getAllFormSets } from "@/api/form-sets.service";
import { fetchCategories, fetchEngines } from "@/api/engines.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
const INSTANT_APS_OPTIONS = [
  {
    text: "Use Carrier Settings",
    value: null
  },
  {
    text: "Supports Human API",
    value: true
  },
  {
    text: "Does Not Support Human API",
    value: false
  }
];

const PAY_DURATIONS = (() => {
  const durations = [
    { text: "Single Pay (1)", value: 1 },
    { text: "Five Pay (5)", value: 5 },
    { text: "Ten Pay (10)", value: 10 },
    {
      text: "Pay to Age 65 (-65)",
      value: -65
    },
    {
      text: "Lifetime (-1)",
      value: -1
    }
  ];

  for (let i = 1; i <= 125; i++) {
    if ([1, 5, 10].includes(i)) continue;
    durations.push({ text: `${i}`, value: i });
  }

  return durations;
})();

export default {
  name: "ProductApi",
  components: { ActiveSaveIndicator, CurrencyInput, ProductSolveSearch },
  mixins: [ActiveSaveMixin],
  props: {
    id: [Number, String],
    product: Object
  },
  data() {
    const headers = [
      new TableHeader({
        text: "Engine",
        value: "engine",
        map: "engine",
        ...TableHeader.IS_FILTERABLE
      }),
      new TableHeader({
        text: "Parameters",
        value: "parameters",
        map: "parameters",
        ...TableHeader.IS_FILTERABLE
      }),
      new TableHeader({
        text: "Actions",
        value: "actions",
        width: "120px"
      })
    ];

    const table = new Table({
      method: () => getProductAliases(this.id),
      headers,
      key: "advisors"
    });

    const savingBuffer = this.generateSavingBuffer({
      items: [
        "engine",
        "type",
        "traits",
        "strifeEnabled",
        "criticalIllnessRider",
        "chronicIllnessRider",
        "noCostChronicIllnessRider",
        "noCostCriticalIllnessRider",
        "advisorUseOnly",
        "ltcRider",
        "vitalityRider",
        "section1035Exchange",
        "formSet",
        "primaryProduct",
        "instantAps",
        "minAge",
        "maxAge",
        "minPremium",
        "maxPremium",
        "minDeathBenefit",
        "maxDeathBenefit",
        "payDuration",
        "solves"
      ]
    });
    return {
      table,
      engines: [],
      formSets: [],
      categories: [],
      loadingEngines: false,
      loadingCategories: false,
      loadingFormSets: false,
      savingBuffer,
      INSTANT_APS_OPTIONS,
      listToSentence,
      PAY_DURATIONS
    };
  },
  created() {
    this.getCategories();
    this.getEngines();
    this.getData();
    this.fetchFormSets();
  },
  computed: {
    primaryProduct: {
      get() {
        return this.product.primaryProduct;
      },
      set(val) {
        this.showDialog({
          component: "ConfirmationDialog",
          title: "Primary Product",
          subtitle:
            "Are you sure? Primary Product determines if a product is shown as a product within BOSS and Quote & Apply."
        }).then(({ confirm }) => {
          if (!confirm) return;
          this.product.primaryProduct = val;
          this.updateAttribute("primaryProduct");
        });
      }
    },
    items() {
      return this.table.tableMap(this.table.rawItems, this.table.headers);
    },
    loading() {
      return this.table.requests > 0;
    },
    isAnnuity() {
      return this.product.line === "annuity";
    }
  },

  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    async fetchFormSets() {
      this.loadingFormSets = true;
      try {
        this.formSets = await getAllFormSets();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loadingFormSets = false;
      }
    },
    debounceUpdate(attribute) {
      if (this.savingBuffer[attribute].timer) {
        clearTimeout(this.savingBuffer[attribute].timer);
      }
      this.savingBuffer[attribute].timer = setTimeout(() => {
        this.updateAttribute(attribute);
      }, 300);
    },
    updateAttribute(attribute) {
      this.updateAttributeWrapper(
        () =>
          productSave(this.product.id, {
            product: this.product.getAttributeRequestValue(attribute)
          }),
        this.savingBuffer[attribute]
      );
    },
    getData() {
      this.table.requests++;
      return this.table
        .getData()
        .then(res => {
          if (!res) return;
          this.table.rawItems = res;
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        })
        .finally(() => this.table.requests--);
    },
    async createNewAlias() {
      const res = await this.showDialog({
        component: "AliasCreateOrEdit",
        $alias: {},
        productId: this.id,
        apiNames: this.apiNames,
        engines: this.engines
      });
      if (res?.alias) this.getData();
    },
    async getCategories() {
      if (this.loadingCategories) return;
      this.loadingCategories = true;
      try {
        this.categories = await fetchCategories();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loadingCategories = false;
      }
    },
    async getEngines() {
      if (this.loadingEngines) return;
      this.loadingEngines = true;
      try {
        this.engines = await fetchEngines();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loadingEngines = false;
      }
    },
    async editAlias(item) {
      const res = await this.showDialog({
        component: "AliasCreateOrEdit",
        $alias: item.additional,
        productId: this.id,
        apiNames: this.apiNames,
        engines: this.engines
      });
      if (!res?.alias) return;
      this.getData();
    },
    async destroyAlias(item) {
      item.additional.tableHelpers.deleting = true;
      try {
        await deleteAlias(this.id, item.additional.id);
        this.getData();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        item.additional.tableHelpers.deleting = false;
      }
    }
  }
};
</script>
