<template>
  <v-card>
    <v-card-text>
      <v-data-table
        item-key="additional.id"
        must-sort
        data-testid="carriers-table"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :loading="table.loading.value"
        :search="search"
        :options="table.options.value"
        @update:options="updateOptions"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5">Carriers</h1>
            <v-spacer />
            <v-text-field
              v-model="search"
              data-testid="search-carriers"
              data-lpignore="true"
              outlined
              dense
              label="Search"
              placeholder="Search Carriers"
              prepend-inner-icon="$mdi-magnify"
              hide-details
            />
          </v-row>
        </template>

        <template #[`item.carrier`]="{ item }">
          <v-icon
            v-if="item.additional.availableInQuoteAndApply"
            data-testid="available-in-quote-and-apply"
            color="accent"
          >
            {{ mdiRocketLaunch }}
          </v-icon>
          <router-link
            :class="{
              'accent--text': item.additional.availableInQuoteAndApply
            }"
            :to="{ name: 'CarrierPage', params: { id: item.additional.id } }"
          >
            {{ item.carrier }}
          </router-link>
        </template>

        <template #[`item.website`]="{ item }">
          <a
            v-if="item.website"
            :href="`https://${item.website}`"
            target="_blank"
          >
            {{ item.website }}
          </a>
          <template v-else> No Available Site </template>
        </template>

        <template #[`item.multipleUplines`]="{ item }">
          <div
            v-for="{ line, value } in item.multipleUplines"
            :key="line"
            class="data-by-line-item"
            :class="{ [`data-by-line-${line}`]: true }"
          >
            <h4 class="data-by-line-title">{{ line }}:</h4>
            <span class="data-by-line-value">{{ value }}</span>
          </div>
        </template>

        <template #[`item.annualization`]="{ item }">
          <div
            v-for="{ line, value } in item.annualization"
            :key="line"
            class="data-by-line-item"
            :class="{ [`data-by-line-${line}`]: true }"
          >
            <h4 class="data-by-line-title">{{ line }}:</h4>
            <span class="data-by-line-value">
              {{ value === "true" ? "Yes" : "No" }}
            </span>
          </div>
        </template>
      </v-data-table>
      <v-icon color="accent"> {{ mdiRocketLaunch }} </v-icon> = Available in
      Quote & Apply
    </v-card-text>
  </v-card>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";

import TableOptions from "@/classes/data-table/TableOptions";
import { getCarriers } from "@/api/carrier.service";
import { useTable } from "@/composables/table.composable";
import { ref } from "vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiRocketLaunch } from "@mdi/js";
const snackbar = useSnackbarStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "name",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Lines",
      value: "lines",
      map: "lines",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Website",
      value: "website",
      map: "website",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Multiple Uplines",
      value: "multipleUplines",
      map: "multipleUplines",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Annualization",
      value: "annualization",
      map: "annualization",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    })
  ],
  options: new TableOptions(["carrier"], [false]),
  shouldIncludeCancelToken: true
});
const search = ref("");

async function getData() {
  try {
    table.items.value = await getCarriers();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

function updateOptions(options) {
  table.options.value = options;
}

getData();
</script>

<style lang="scss">
.data-by-line-item {
  padding-left: 6px;
  margin-bottom: 1px;
}
.data-by-line-title {
  font-weight: 500;
  display: inline;
}
.data-by-line-value {
  padding-left: 6px;
}
.data-by-line-Annuity {
  border-left: 3px solid #1487e2;
}
.data-by-line-Life {
  border-left: 3px solid #28a746;
}
.data-by-line-Disability {
  border-left: 3px solid #f2dd6e;
}
.data-by-line-LTC {
  border-left: 3px solid #d90368;
}
</style>
