<template>
  <v-data-table
    class="transparent-data-table"
    data-testid="advisor-profile-pay-periods-table"
    :loading="table.loading.value"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :disable-pagination="true"
    :hide-default-footer="true"
  >
    <template #[`item.id`]="{ item }">
      <router-link :to="item.additional.routerLink">
        {{ item.id }}
      </router-link>
    </template>

    <template #footer>
      <v-row class="ma-0 pa-3">
        <v-spacer />
        <v-btn
          color="primary"
          class="text-none"
          data-testid="advisor-profile-pay-periods-table-view-all"
          text
          @click="viewAll"
        >
          <v-icon class="mr-1">{{ mdiOpenInApp }} </v-icon> View {{ name }}'s
          Pay Periods
        </v-btn>
      </v-row>
    </template>
  </v-data-table>
</template>

<script setup>
import { mdiOpenInApp } from "@mdi/js";
import TableHeader from "@/classes/data-table/TableHeader";

import {
  currencyFormat,
  parseErrorMessage,
  timestampFormatter
} from "@/util/helpers";

import { useSnackbarStore } from "@/stores/snackbar";
import { storeToRefs } from "pinia";
import { useTableStore } from "@/stores/table";
import { useTable } from "@/composables/table.composable";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { useRouter } from "@/composables/compatible.composables";

const advisorView = useAdvisorProfileView();
const tableStore = useTableStore();
const snackbar = useSnackbarStore();
const router = useRouter();

const { tableFilterData, name, payPeriods } = storeToRefs(advisorView);
const table = useTable({
  headers: [
    new TableHeader({
      text: "ID",
      value: "id",
      map: "id"
    }),
    new TableHeader({
      text: "Paid Date",
      value: "paidDate",
      map: "paidDate"
    }),
    new TableHeader({
      text: "Paid Amount",
      value: "paidAmount",
      map: "paidAmount"
    }),
    new TableHeader({
      text: "Paying To",
      value: "PayingTo",
      map: "payingTo"
    })
  ],
  getData: async () => {
    await advisorView.getRecentPayPeriods();
    return {
      items: payPeriods.value.map(item => ({
        ...item,
        paidAmount: currencyFormat(item.paidAmount),
        paidDate: item.paidDate
          ? timestampFormatter(item.paidDate, null, "basic")
          : null
      }))
    };
  }
});
function viewAll() {
  tableStore.payPeriodTable.filter = {
    paidTo: tableFilterData.value
  };
  router.push({
    name: "Commissions",
    query: { page: "pay-periods" }
  });
}

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getData();
</script>
