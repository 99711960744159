<template>
  <v-autocomplete
    autocomplete="false"
    prepend-inner-icon="$mdi-book-open-page-variant"
    placeholder="Search Vendors"
    label="Vendor"
    outlined
    dense
    v-model="vendor"
    item-text="name"
    return-object
    hide-no-data
    :clearable="clearable"
    :disabled="disabled"
    :search-input.sync="searchText"
    :success="success"
    :error-messages="errorMessages"
    :items="vendors"
    :loading="loading"
    :hide-details="hideDetails"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    ref="searchText"
    @blur="$emit('blur')"
    @change="$emit('change')"
  >
    <template #append-outer v-if="hasAppendOuterSlot">
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import sortBy from "lodash/sortBy";
import { getVendors } from "@/api/vendor.service";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  props: {
    clearable: Boolean,
    success: Boolean,
    errorMessages: Array,
    value: [Object, String],
    lines: {
      required: false,
      type: Array
    },
    disabled: Boolean,
    hideDetails: Boolean
  },
  data() {
    let vendors = [];
    let vendor = null;
    if (this.value && Object.keys(this.value).length && this.value.id) {
      vendors = [this.value];
      vendor = this.value;
    }
    return {
      searchText: "",
      timer: null,
      loading: false,
      vendors,
      vendor
    };
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots["append-outer"];
    }
  },
  created() {
    this.getVendors();
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.vendor = null;
        this.vendors = [];
        return;
      }
      this.vendor = value;
      this.vendors = [value];
    },
    vendor(value) {
      this.$emit("input", value);
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async getVendors() {
      try {
        this.loading = true;
        const vendors = await getVendors();
        this.vendors.splice(0, this.vendors.length);
        this.vendors.push(...sortBy(vendors, "name"));
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    },
    focus() {
      this.$refs.searchText.$refs.input.focus();
    },
    clear() {
      this.vendor = null;
      this.vendors = [];
    }
  }
};
</script>
