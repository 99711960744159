<template>
  <v-autocomplete
    autocomplete="false"
    :placeholder="placeholder"
    :label="label"
    outlined
    item-text="name"
    return-object
    dense
    v-model="model"
    prepend-inner-icon="$mdi-badge-account-outline"
    no-filter
    hide-no-data
    :hint="hint"
    :persistent-hint="persistentHint"
    :hide-details="hideDetails"
    :clearable="clearable"
    :disabled="disabled"
    :search-input.sync="personnelSearchText"
    :success="success"
    :error-messages="errorMessages"
    :items="modelOptions"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
    @change="$emit('change')"
    @click:clear="$emit('input', {})"
  >
    <template v-if="$slots['append-outer']" #append-outer>
      <slot name="append-outer" />
    </template>
    <template v-if="$slots['message']" #message>
      <slot name="message" />
    </template>
  </v-autocomplete>
</template>

<script>
import sortBy from "lodash/sortBy";
import { personnelSearch } from "@/api/search.service";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    carrierId: {
      type: [String, Number],
      required: true
    },
    hint: {
      type: String,
      required: false
    },
    persistentHint: Boolean,
    hideDetails: Boolean,
    clearable: Boolean,
    success: Boolean,
    disabled: Boolean,
    errorMessages: Array,
    value: [Object, String]
  },
  data() {
    let modelOptions = [];
    let model = null;
    if (
      this.value &&
      Object.values(this.value).filter(
        v => Boolean(v) && !(typeof v === "function")
      ).length
    ) {
      modelOptions.push(this.value);
      model = this.value;
    }
    return {
      personnelSearchText: "",
      modelOptions,
      timer: null,
      model,
      loading: false
    };
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.model = null;
        this.modelOptions = [];
        return;
      }
      this.model = value;
      this.modelOptions = [value];
    },
    model(value) {
      this.$emit("input", value);
    },
    personnelSearchText(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (this.modelOptions.some(val => val?.name === value)) {
        return;
      }

      if (this.timer) clearTimeout(this.timer);

      this.timer = setTimeout(this.search, 200);
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async search() {
      this.loading = true;
      try {
        const options = await personnelSearch({
          name: this.personnelSearchText,
          carrierId: this.carrierId
        });
        this.modelOptions = sortBy(options, "name");
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
