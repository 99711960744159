<template>
  <v-row v-if="carrier.name">
    <v-col cols="12" class="pb-0">
      <carrier-card :carrier="carrier" />
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-tabs v-model="activePage">
        <v-tab class="text-none">
          <v-icon class="mr-1">$mdi-file-document-edit</v-icon> General Settings
        </v-tab>

        <v-tab
          v-for="line in lines"
          class="text-none"
          :key="`tab-title-${line.tab.text}`"
        >
          <v-icon class="mr-1"> {{ line.tab.icon }} </v-icon>
          {{ line.tab.text }}
        </v-tab>

        <v-tab class="text-none">
          <v-icon class="mr-1">$mdi-file-document-multiple</v-icon> Documents
        </v-tab>

        <v-tab v-if="isGroupTwoPlus" class="text-none">
          <v-icon class="mr-1">$mdi-currency-usd</v-icon>
          Comp Builders
        </v-tab>
        <v-tab v-if="isGroupFour" class="text-none">
          <v-icon class="mr-1">$mdi-book-alphabet</v-icon> API
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activePage" touchless>
        <v-tab-item>
          <carrier-details :carrier="carrier" />
        </v-tab-item>

        <v-tab-item v-for="line in lines" :key="`tab-item-${line.tab.text}`">
          <carrier-line-details :type="line.type" :carrier="carrier" />
        </v-tab-item>

        <v-tab-item>
          <carrier-documents :carrier="carrier" />
        </v-tab-item>

        <v-tab-item v-if="isGroupTwoPlus" eager>
          <carrier-comp-builders
            :key="carrier.compBuilders.length"
            :carrier="carrier"
          />
        </v-tab-item>

        <v-tab-item v-if="isGroupFour">
          <carrier-api :carrier="carrier" />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
  <v-skeleton-loader v-else type="card" />
</template>
<script>
import CarrierCard from "@/components/carriers/CarrierCard.vue";
import CarrierDetails from "@/components/carriers/CarrierDetails.vue";
import CarrierLineDetails from "@/components/carriers/CarrierLineDetails.vue";
import CarrierDocuments from "@/components/carriers/CarrierDocuments.vue";
import CarrierCompBuilders from "@/components/carriers/CarrierCompBuilders.vue";
import CarrierApi from "@/components/carriers/CarrierApi.vue";

import sortBy from "lodash/sortBy";
import { getCarrierData } from "@/api/carrier.service";
import { useUserStore } from "@/stores/user";
import { mapWritableState, mapState, mapActions } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { useHead } from "@unhead/vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";

const LINES = {
  life: { text: "Life", icon: "$mdi-account-reactivate" },
  disability: { text: "Disability", icon: "$mdi-human-cane" },
  annuity: { text: "Annuity", icon: "$mdi-calendar-lock" },
  ltc: { text: "LTC", icon: "$mdi-bed" }
};

export default {
  components: {
    CarrierCard,
    CarrierDetails,
    CarrierLineDetails,
    CarrierDocuments,
    CarrierCompBuilders,
    CarrierApi
  },
  data() {
    return {
      id: this.$route.params.id,
      carrier: {},
      activePage: 0
    };
  },
  setup() {
    const head = useHead({ title: "Carrier Viewer" });
    return { head };
  },
  created() {
    this.getCarrier();
  },
  destroyed() {
    this.breadcrumb = "";
  },
  computed: {
    ...mapWritableState(useInstanceStore, ["breadcrumb"]),
    ...mapState(useUserStore, ["isGroupTwoPlus", "isGroupFour"]),
    lines() {
      const lines = [];

      if (!this.carrier?.actives) {
        return lines;
      }

      this.carrier.actives.iter.forEach(key => {
        if (!this.carrier.actives[key]) return;
        lines.push({ type: key, tab: LINES[key] });
      });

      return sortBy(lines, line => line.tab.text);
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    getCarrier() {
      getCarrierData(this.id)
        .then(carrier => {
          this.$set(this, "carrier", carrier);
          this.breadcrumb = this.carrier.name;
          this.head.patch({ title: this.carrier.name });
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
          this.$router.replace({ name: "Home" });
        });
    }
  }
};
</script>
