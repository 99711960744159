<template>
  <v-card color="section" tile flat>
    <v-card-text>
      <chat
        title="Personnel Chat"
        :pusher-id="`private-${personnelId}-Personnel`"
        :messages="notes"
        flat
        @new-note="newNote"
        @send-message="sendChatMessage"
        @remove-message="removeFromMessages"
        ref="chat"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import sortBy from "lodash/sortBy";
import Chat from "@/components/shared/chat/Chat.vue";
import NoteFactory from "@/factories/NoteFactory";
import { parseErrorMessage } from "@/util/helpers";
import { mapActions, mapState } from "pinia";
import { createNote } from "@/api/notes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  components: {
    Chat
  },
  props: {
    personnel: Object,
    personnelId: Number
  },
  data() {
    const user = useUserStore();
    const notes = sortBy(this.personnel.notes, "createdAt");
    notes.unshift({
      note: `Hi ${user.loginable.name}, feel free to make notes here.`,
      ownable: { name: "BackNine" }
    });
    return {
      notes
    };
  },
  computed: {
    ...mapState(useUserStore, ["loginable"])
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    removeFromMessages(message) {
      const index = this.personnel.notes.findIndex(
        ({ id }) => id === message.id
      );

      this.personnel.notes.splice(index, 1);
    },

    async sendChatMessage(note) {
      this.$refs.chat.$refs.chatFooter.loading = true;
      const newNote = new NoteFactory({
        documents: note.files,
        email: note.sendEmail ? 1 : 0,
        phoneCall: note.sendPhoneCall,
        note: note.message,
        notableId: this.$route.params.id,
        notableType: "Personnel"
      });

      try {
        await createNote(newNote.toCreateRequest());
        this.$refs.chat.$refs.chatFooter.reset();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
      } finally {
        this.$refs.chat.$refs.chatFooter.loading = false;
      }
    },
    newNote({ note: rawNote }) {
      const note = new NoteFactory();
      note.setFromRequest(rawNote);
      this.notes.push(note);
    }
  }
};
</script>
