<template>
  <v-row :class="{ 'pa-3': $vuetify.breakpoint.mdAndUp }" dense class="pt-0">
    <v-col cols="12" lg="7">
      <commissions-summary-graph style="height: 30rem !important" condensed />
    </v-col>

    <v-col cols="12" lg="5">
      <v-fade-transition mode="out-in">
        <template v-if="loadedGoals">
          <v-card
            v-if="percent !== 100"
            :color="goalsAndWebinarsTabIndex === 0 ? 'accent' : null"
          >
            <v-tabs
              v-model="goalsAndWebinarsTabIndex"
              class="transparent-tab-group"
              :background-color="
                goalsAndWebinarsTabIndex === 0 ? 'accent' : null
              "
            >
              <v-tab class="text-none">
                <v-card-title style="font-weight: 400; font-size: 1.2rem">
                  BackNine Feed
                </v-card-title>
              </v-tab>
              <v-tab class="text-none">
                <v-card-title style="font-weight: 400; font-size: 1.2rem">
                  <v-badge
                    :content="incompleteTaskCount"
                    offset-y="8"
                    color="pink"
                  >
                    Onboarding
                  </v-badge>
                </v-card-title>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="goalsAndWebinarsTabIndex" touchless>
              <v-tab-item>
                <upcoming-webinars height="27rem" in-group />
              </v-tab-item>

              <v-tab-item>
                <site-goals
                  :goals="goals"
                  :percent="percent"
                  in-group
                  height="27rem"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
          <upcoming-webinars v-else height="30rem" />
        </template>
        <v-skeleton-loader v-else type="card" />
      </v-fade-transition>
    </v-col>

    <v-col cols="12">
      <v-fade-transition mode="out-in">
        <performance-statistics
          v-if="loadedStatistics"
          :statistics="statistics"
        />
        <v-skeleton-loader v-else type="card" />
      </v-fade-transition>
    </v-col>
    <v-col cols="12" lg="4" id="v-step-3">
      <v-fade-transition mode="out-in">
        <top-cases v-if="loadedRecents" :cases="cases" />
        <v-skeleton-loader v-else type="card" />
      </v-fade-transition>
    </v-col>
    <v-col cols="12" lg="4">
      <v-fade-transition mode="out-in">
        <top-advisors
          v-if="loadedRecents && advisors.length"
          :advisors="advisors"
        />
        <recent-tasks v-else-if="loadedRecents" :tasks="tasks" />
        <v-skeleton-loader v-else type="card" />
      </v-fade-transition>
    </v-col>

    <v-col cols="12" lg="4">
      <recent-eapps v-if="loadedRecents" :e-apps="eApps" />
      <v-skeleton-loader v-else type="card" />
    </v-col>
    <welcome-tour v-if="readyForTutorial" />
  </v-row>
</template>

<script>
import CommissionsSummaryGraph from "@/components/reports/CommissionsSummaryGraph.vue";
import PerformanceStatistics from "@/components/home/PerformanceStatistics.vue";
import RecentTasks from "@/components/home/RecentTasks.vue";
import TopCases from "@/components/home/TopCases.vue";
import TopAdvisors from "@/components/home/TopAdvisors.vue";
import RecentEapps from "@/components/home/RecentEapps.vue";
import SiteGoals from "@/components/home/SiteGoals.vue";
import UpcomingWebinars from "@/components/home/UpcomingWebinars.vue";
import WelcomeTour from "@/components/WelcomeTour.vue";

import {
  getAdvisorCards,
  getAdvisorGoals,
  getAdvisorRecents
} from "@/api/dashboard.service";
import { parseErrorMessage } from "@/util/helpers";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";

export default {
  name: "HomeView",
  components: {
    UpcomingWebinars,
    CommissionsSummaryGraph,
    PerformanceStatistics,
    RecentTasks,
    TopCases,
    RecentEapps,
    TopAdvisors,
    SiteGoals,
    WelcomeTour
  },
  setup() {
    useHead({ title: "Home" });
  },
  data() {
    return {
      cases: [],
      eApps: [],
      tasks: [],
      advisors: [],
      goals: [],
      statistics: [],
      loadedGoals: false,
      loadedStatistics: false,
      loadedRecents: false,
      goalsAndWebinarsTabIndex: 0
    };
  },
  computed: {
    ...mapState(useUserStore, {
      userId: "id",
      tutorials: "tutorials",
      loginable: "loginable"
    }),
    loaded() {
      return this.loadedGoals && this.loadedStatistics && this.loadedRecents;
    },

    incompleteTaskCount() {
      const total = this.goals.length;
      const totalCompleted = this.goals.filter(v => v.completed).length;
      return total - totalCompleted;
    },
    percent() {
      const total = this.goals.length;
      const totalCompleted = this.goals.filter(v => v.completed).length;
      const percent = totalCompleted / total;
      return Math.trunc(percent * 100);
    },
    readyForTutorial() {
      return (
        this.userId &&
        this.loaded &&
        !this.tutorials?.home &&
        this.$route.name === "Home" &&
        this.$vuetify.breakpoint.lgAndUp &&
        this.loginable.type === "Agent"
      );
    }
  },
  created() {
    this.getAdvisorCards();
    this.getAdvisorGoals();
    this.getAdvisorRecents();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async getAdvisorCards() {
      try {
        const statistics = await getAdvisorCards();
        this.$set(this, "statistics", statistics);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loadedStatistics = true;
      }
    },
    async getAdvisorGoals() {
      try {
        const goals = await getAdvisorGoals();
        this.$set(this, "goals", goals);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loadedGoals = true;
      }
    },
    async getAdvisorRecents() {
      try {
        const { cases, tasks, advisors, eApps } = await getAdvisorRecents();
        this.$set(this, "cases", cases);
        this.$set(this, "tasks", tasks);
        this.$set(this, "advisors", advisors);
        this.$set(this, "eApps", eApps);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loadedRecents = true;
      }
    }
  }
};
</script>
