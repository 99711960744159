<template>
  <v-card flat>
    <v-card-title>
      <v-spacer />
      <v-text-field
        data-lpignore="true"
        v-model="search"
        outlined
        dense
        hide-details
        label="Search"
        prepend-inner-icon="$mdi-magnify"
      />
      <v-btn icon @click="createRateBand" class="ml-3">
        <v-icon>$mdi-plus-circle</v-icon>
      </v-btn>
      <v-btn icon @click="getData">
        <v-icon>$mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :search.sync="search"
        :loading="loading"
      >
        <template #[`item.min`]="{ item }">
          <currency-formatter :value="item.min" />
        </template>
        <template #[`item.max`]="{ item }">
          <currency-formatter :value="item.max" />
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn icon @click="editItem(item.additional)" color="accent">
            <v-icon> $mdi-pencil </v-icon>
          </v-btn>
          <v-btn icon @click="deleteItem(item.additional)" color="error">
            <v-icon> $mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";

import Table from "@/classes/data-table/Table";
import TableHeader from "@/classes/data-table/TableHeader";

import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";

import { deleteRateBand, getRateBands } from "@/api/rate-bands.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useHead } from "@unhead/vue";

export default {
  components: { CurrencyFormatter },
  mixins: [GenericTableMixin],
  setup() {
    useHead({
      title: "Rate Bands"
    });
  },
  data() {
    const headers = [
      new TableHeader({
        text: "Min",
        value: "min",
        map: "min"
      }),
      new TableHeader({
        text: "Max",
        value: "max",
        map: "max"
      }),
      new TableHeader({
        text: "Actions",
        value: "actions"
      })
    ];

    const table = new Table({
      headers,
      method: getRateBands
    });

    return {
      table,
      search: "",
      loading: false
    };
  },
  created() {
    this.getData();
  },
  computed: {
    headers() {
      return this.table.headers;
    },
    items() {
      return this.tableMap(this.table.rawItems, this.table.headers);
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    async getData() {
      try {
        await this.table.getPreMappedData();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      }
    },
    async editItem($rateBand) {
      const res = await this.showDialog({
        component: "RateBandDialog",
        $rateBand
      });
      if (res.id) this.getData();
    },
    async deleteItem(item) {
      const res = await this.showDialog({
        component: "DeleteDialog",
        title: `Are you sure that you want to remove ${item.display}?`,
        subtitle: "This cannot be undone.",
        func: () => deleteRateBand(item.id)
      });
      if (res.delete) this.getData();
    },
    async createRateBand() {
      const res = await this.showDialog({
        component: "RateBandDialog"
      });
      if (res.id) this.getData();
    }
  }
};
</script>
