<template>
  <div>
    <div
      v-for="(stack, index) in dialogStack"
      :key="`${stack.props.component}-${index}`"
    >
      <v-dialog
        :value="index === dialogStack.length - 1"
        :max-width="stack.props.width || '50rem'"
        :max-height="stack.props.maxHeight"
        :persistent="stack.props.persistent"
        :fullscreen="stack.props.fullscreen"
        :style="stack.props.style"
        :scrollable="stack.props.scrollable"
        id="dialog-manager"
        @click:outside="outsideClick(stack)"
      >
        <component
          :is="stack.props.component"
          v-bind="{ ...stack.props, isDialog: true }"
          ref="activeDialog"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { useZendeskStore } from "@/stores/zendesk";

import components from "@/dialogs/index";

export default {
  components,
  watch: {
    dialogEnable(v) {
      if (v) this.hideZendesk();
      else this.showZendesk();
    }
  },
  computed: {
    ...mapState(useDialogStore, ["dialogStack", "show"]),
    dialogConfig() {
      if (!this.dialogStack.length) return {};
      return this.dialogStack[this.dialogStack.length - 1].props;
    },
    dialogEnable: {
      get() {
        return this.show;
      },
      set(val) {
        if (val) {
          throw new Error("Do not open a dialog from the Dialog Manager");
        }
        // Only used when the dialog is closed via escape
        if (this.$refs.activeDialog.escCloseFunc) {
          this.$refs.activeDialog.escCloseFunc();
        }
        this.closeDialog();
      }
    }
  },
  methods: {
    ...mapActions(useZendeskStore, {
      hideZendesk: "hide",
      showZendesk: "show"
    }),
    ...mapActions(useDialogStore, ["closeDialog"]),
    outsideClick(stackItem) {
      if (stackItem.props.persistent) return;
      this.dialogEnable = false;
    }
  }
};
</script>
