<template>
  <v-card flat class="tasks-card">
    <v-card-title>
      <v-row class="ma-0" dense justify="space-between" align="center">
        <slot v-if="$slots.title" name="title" />
        <span>
          <v-btn
            v-if="onClick"
            icon
            class="text-none"
            color="accent"
            data-testid="create-task"
            @click="onClick"
          >
            <v-icon> {{ mdiPlus }} </v-icon>
          </v-btn>
          <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            icon
            class="text-none"
            color="primary"
            @click="$emit('toggle-expand')"
          >
            <v-icon size="16" v-if="expanded"> {{ mdiArrowExpandLeft }}</v-icon>
            <v-icon size="16" v-else> {{ mdiArrowExpandRight }} </v-icon>
          </v-btn>
        </span>
      </v-row>
    </v-card-title>
    <v-card-text class="px-0">
      <v-list>
        <v-list-group
          v-for="{ icon, text, tasks, defaultOpen } in tasksGroupedByCategory"
          class="ma-2"
          :data-testid="`task-list-${text}`"
          :key="text"
          :value="defaultOpen"
        >
          <template #activator>
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title :data-testid="`task-header-${text}`">
                {{ text }}
                <span class="grey--text"> ({{ tasks.length }}) </span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-if="tasks.length">
            <component
              v-for="(task, index) in tasks"
              :is="simple ? SimpleTaskItem : TaskItem"
              :data-testid="`task-item-${index}`"
              :active="task.key === activeTask"
              v-bind="task"
              :key="task.key"
              @click="activeTask = task.key"
              @delete-message="$emit('delete-message', $event)"
            />
          </template>
          <v-list-item v-else class="py-3 px-6">
            <v-list-item-content>
              <v-list-item-title>No {{ type }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script setup>
import SimpleTaskItem from "@/components/shared/tasks/SimpleTaskItem.vue";
import TaskItem from "@/components/shared/tasks/TaskItem.vue";
import { ref, watch, toRefs, defineEmits, defineProps } from "vue";
import { mdiPlus, mdiArrowExpandLeft, mdiArrowExpandRight } from "@mdi/js";

const emit = defineEmits(["input"]);

const props = defineProps({
  expanded: Boolean,
  hideDates: Boolean,
  value: { type: String, required: false, default: null },
  tasksGroupedByCategory: {
    type: Array,
    required: true,
    validator: v => Boolean(v?.length)
  },
  type: {
    type: String,
    required: false,
    default: "Tasks"
  },
  simple: Boolean,
  onClick: { type: Function, required: false, default: null }
});

const { value: valueProp, tasksGroupedByCategory, expanded } = toRefs(props);
const activeTask = ref(props.value);

watch(activeTask, (newV, oldV) => {
  if (newV !== oldV) emit("input", newV);
});

watch(valueProp, v => {
  if (v !== activeTask.value) activeTask.value = v;
});
</script>
