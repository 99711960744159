<template>
  <v-card flat color="section">
    <v-card-text>
      <v-row align="center">
        <v-col cols="12" align="center">
          <div>
            <img
              :src="agency.avatar"
              style="object-fit: contain; max-width: 100px; max-height; 150px"
            />
          </div>
          <v-btn
            text
            class="text-none"
            color="primary"
            data-testid="agency-profile-new-avatar"
            :disabled="props.readonly"
            @click="uploadNewAvatar"
          >
            Upload New Avatar
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="agency.name"
            data-lpignore="true"
            label="Display Name"
            outlined
            dense
            data-testid="agency-display-name"
            prepend-inner-icon="$mdi-account"
            :success="Boolean(agency.name)"
            :error-messages="!agency.name ? ['Required'] : []"
            @input="updateDisplayName('name')"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.name.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { useDialogStore } from "@/stores/dialog";
import { useAgencySettingsStore } from "@/stores/agency-settings";
import { useActiveSave } from "@/composables/active-save.composable";
import { defineProps } from "vue";
import { useSettingsViewStore } from "@/stores/settings-view";
const props = defineProps({
  readonly: Boolean
});

const settings = useSettingsViewStore();
const agency = useAgencySettingsStore(settings.currentAgencyId);
const savingBuffer = {
  name: useActiveSave()
};
const dialog = useDialogStore();

function updateDisplayName() {
  if (!agency.name) return;
  savingBuffer.name.debounceUpdate(agency.updateName);
}
async function uploadNewAvatar() {
  if (props.readonly) return;
  const result = await dialog.showDialog({
    component: "ChangeAvatarDialog",
    type: "Agency",
    id: agency.id,
    checkboxText: null
  });
  if (!result.updated) return;
  agency.avatar = result.newUrl;
}
</script>
