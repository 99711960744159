<template>
  <div v-html="preview" class="article" />
</template>

<script>
const MarkdownIt = require("markdown-it");
const md = new MarkdownIt();
md.use(require("markdown-it-video"), {
  youtube: { width: "100%", height: 390 },
  vimeo: { width: "100%", height: 390 }
});

export default {
  props: {
    article: Object
  },
  data() {
    const content = this.article?.content || "";
    return {
      preview: md.render(content)
    };
  },
  watch: {
    "article.content"() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.preview = md.render(this.article.content);
      }, 500);
    }
  }
};
</script>
