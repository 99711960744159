<template>
  <v-data-table
    data-testid="vendors-table"
    filter-mode="union"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :search.sync="search"
    :loading="table.loading.value"
  >
    <template #top>
      <v-row class="ma-0 px-3" align="center">
        <h1 class="text-h5">Vendors</h1>
        <v-spacer />
        <v-text-field
          v-model="search"
          data-testid="vendors-table-search"
          data-lpignore="true"
          outlined
          dense
          hide-details
          label="Search"
          prepend-inner-icon="$mdi-magnify"
        />
        <v-btn
          icon
          class="ml-3"
          data-testid="vendors-table-create"
          @click="createVendor"
        >
          <v-icon>$mdi-plus-circle</v-icon>
        </v-btn>
        <v-btn icon data-testid="vendors-table-refresh" @click="getData">
          <v-icon>$mdi-refresh</v-icon>
        </v-btn>
      </v-row>
    </template>
    <template #[`item.email`]="{ item }">
      <a v-if="item.email" :href="`mailto:${item.email}`"> {{ item.email }}</a>
    </template>

    <template #[`item.actions`]="{ item }">
      <v-btn icon color="accent" data-testid="edit" @click="editItem(item)">
        <v-icon> $mdi-pencil </v-icon>
      </v-btn>
      <v-btn icon color="error" data-testid="delete" @click="deleteItem(item)">
        <v-icon> $mdi-delete </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import { parseErrorMessage } from "@/util/helpers";
import { getVendors, deleteVendor } from "@/api/vendor.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import { ref } from "vue";

const search = ref("");

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Email",
      value: "email",
      map: "email",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Type",
      value: "formattedType",
      map: "formattedType",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Values",
      value: "values",
      map: "values",
      align: "center",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions"
    })
  ],
  getData: async () => {
    const items = await getVendors();
    return { items };
  }
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

async function editItem(item) {
  const res = await dialog.showDialog({
    component: "EditVendorDialog",
    value: item.additional
  });

  if (res?.saved) getData();
}
async function deleteItem(item) {
  const res = await dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Are you sure that you want to remove ${item.name}?`,
    subtitle: "This cannot be undone.",
    func: () => deleteVendor(item.additional.id)
  });

  if (res?.confirm) getData();
}
async function createVendor() {
  const res = await dialog.showDialog({
    component: "EditVendorDialog"
  });

  if (res?.saved) getData();
}

getData();
</script>
