<template>
  <v-card flat tile width="100%">
    <v-row class="ma-0 px-3">
      <v-spacer />
      <v-btn
        rounded
        class="text-none"
        outlined
        color="primary"
        :to="{
          name: 'ElectronicApplicationView',
          params: { id: party.electronicApplicationId }
        }"
      >
        <v-icon> $mdi-form </v-icon> View Quote & Apply eApp
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "PartyActions",
  props: {
    party: Object
  }
};
</script>
