import { format } from "date-fns";
import { defineStore } from "pinia";
import { useUserStore } from "@/stores/user.js";
import { CastTodoToNotification } from "@/factories/ToDo.js";
import { getTodos } from "@/api/todos.service";

export const useNotificationsStore = defineStore("notifications", {
  state: () => ({
    loadingTodos: false,
    todos: [],
    todoCount: 0,
    todoCancelToken: null,
    loadingTasks: false,
    tasks: [],
    tasksCount: 0
  }),
  getters: {
    loading() {
      return this.loadingTodos || this.loadingTasks;
    },
    items() {
      const items = [...this.todos, ...this.tasks];
      items.sort((a, b) => a.followUp - b.followUp);
      return items;
    },
    total() {
      return this.todoCount + this.tasksCount;
    }
  },
  actions: {
    getRequestParams() {
      const requestParams = new URLSearchParams();
      const todaysDate = new Date();
      const finish = format(todaysDate, "yyyy-MM-dd");

      requestParams.append("sorting[follow_up_at]", "desc");
      requestParams.append("date[follow_up_at][finish]", finish);
      requestParams.append("count", 5);
      requestParams.append("page", 1);
      requestParams.append("complete", "false");

      return requestParams;
    },
    async getDueTodos() {
      try {
        this.loadingTodos = true;

        const user = useUserStore();
        const params = this.getRequestParams();
        params.append("assignee_id", user.loginable.id);
        params.append("assignee_type", user.loginable.type);
        params.append("include_subject_title", "true");
        const { meta, items: todos } = await getTodos(
          params,
          this.todoCancelToken
        );
        this.todoCount = meta.total;
        this.todos.splice(0, this.todos.length);
        todos.forEach(i => this.todos.push(CastTodoToNotification(i)));
      } catch (e) {
        //fail silently
      } finally {
        this.loadingTodos = false;
      }
    },
    getNotifications() {
      this.getDueTodos();
    }
  }
});
