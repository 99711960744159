<template>
  <v-card flat tile color="section">
    <v-card-text class="transparent-tab-item pa-0">
      <div>
        <v-row class="ma-0" align="end">
          <v-tabs
            background-color="section"
            v-model="activeTab"
            style="flex: 0"
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.text + 'tab'"
              class="text-none"
              :data-testid="`parties-tab-${tab.text}`"
            >
              {{ tab.text }}
            </v-tab>
          </v-tabs>
          <v-spacer />
          <v-row class="mb-0 mt-1 mx-3" align="center">
            <v-text-field
              v-model="tabs[activeTab].search.value"
              data-lpignore="true"
              label="Search Parties"
              prepend-inner-icon="$mdi-magnify"
              outlined
              hide-details
              dense
              data-testid="search-parties"
            />
            <v-btn
              v-if="tabs[activeTab].add.canAdd"
              class="text-none ml-3"
              color="accent"
              data-testid="add-party"
              @click="tabs[activeTab].add.addFn"
            >
              <v-icon>$mdi-plus</v-icon> {{ tabs[activeTab].add.addText }}
            </v-btn>
          </v-row>
        </v-row>
      </div>
      <v-tabs-items v-model="activeTab" touchless vertical>
        <v-tab-item v-for="tab in tabs" :key="tab.text + 'body'" eager>
          <component :is="tab.component" :search="tab.search.value" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script setup>
import CaseViewAdvisorsTable from "@/components/cases/case-view/CaseViewAdvisorsTable.vue";
import CaseViewAdvisorsTableInformal from "@/components/cases/case-view/CaseViewAdvisorsTableInformal.vue";
import CaseViewPartiesTable from "@/components/cases/case-view/CaseViewPartiesTable.vue";
import AddPartyDialog from "@/components/cases/case-dialogs/AddPartyDialog.vue";
import AddAdvisorDialog from "@/components/cases/case-dialogs/AddAdvisorDialog.vue";
import AddInformalAdvisorDialog from "@/components/cases/case-dialogs/AddInformalAdvisorDialog.vue";

import { markRaw, ref, watch, defineProps, toRef } from "vue";
import { useCaseViewStore } from "@/stores/case-view";
import { useRouter } from "@/composables/compatible.composables";
import { useDialogStore } from "@/stores/dialog";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";

const props = defineProps({ active: Boolean });
const pageIsActive = toRef(props, "active");
const router = useRouter();

const dialog = useDialogStore();
const user = useUserStore();
const caseView = useCaseViewStore();

const {
  id: caseId,
  contractParties,
  appointmentCases,
  carrier,
  product,
  insured,
  line,
  state
} = storeToRefs(caseView);

const tabs = [
  {
    text: "Parties",
    component: markRaw(CaseViewPartiesTable),
    search: ref(""),
    add: {
      canAdd: user.isGroupTwoPlus,
      addFn: addNewParty,
      addText: "Add Party"
    }
  }
];
if (caseView.LINE.INFORMAL) {
  tabs.push({
    text: "Advisors",
    search: ref(""),
    component: markRaw(CaseViewAdvisorsTableInformal),
    add: {
      canAdd: user.isGroupThreePlus,
      addFn: addNewInformalAdvisor,
      addText: "Add Advisor"
    }
  });
} else {
  tabs.push({
    text: "Advisors",
    search: ref(""),
    component: markRaw(CaseViewAdvisorsTable),
    add: {
      canAdd: user.isGroupThreePlus,
      addFn: addNewAdvisor,
      addText: "Add Advisor"
    }
  });
}

const activeTab = ref(syncQueryToTab());

function syncQueryToTab() {
  if (!router) return 0;
  const tab = router.currentRoute.query.tab;
  const index = tabs.findIndex(t => t.text.toLowerCase() === tab);
  return index === -1 ? 0 : index;
}

function syncTabToQuery() {
  if (!router) return;
  const tab = tabs[activeTab.value].text.toLowerCase();
  if (router.currentRoute.query.tab === tab) return;
  router.replace({
    query: {
      page: router.currentRoute.query.page,
      tab
    }
  });
}

watch(activeTab, syncTabToQuery);
watch(pageIsActive, () => {
  if (pageIsActive.value) {
    syncTabToQuery();
  }
});

async function addNewParty() {
  let insuredAddress;
  if (insured.value.addresses?.length > 0) {
    insuredAddress = insured.value.addresses.find(
      a => a.addressType === "home"
    );
  }

  const result = await dialog.showDialog({
    component: markRaw(AddPartyDialog),
    caseId: caseId.value,
    line: line.value,
    scrollable: true,
    insuredAddress
  });
  if (!result?.party) return;
  contractParties.value.push(result.party);
}

async function addNewAdvisor() {
  const result = await dialog.showDialog({
    component: markRaw(AddAdvisorDialog),
    carrier: carrier.value,
    productId: product.value.id,
    insuredName: insured.value.name,
    caseId: caseId.value,
    line: line.value,
    states: [state.value]
  });
  if (result?.appointmentCase) {
    appointmentCases.value.push(result.appointmentCase);
  }
}

async function addNewInformalAdvisor() {
  return dialog.showDialog({
    component: markRaw(AddInformalAdvisorDialog)
  });
}
</script>
