<template>
  <v-card flat light>
    <v-card-title> Email Signature (BackNine Staff Only) </v-card-title>
    <v-card-subtitle> Copy your standardized email signature </v-card-subtitle>
    <v-card-text :class="{ noselect: !copying }">
      <table
        id="copy"
        class="no-style"
        style="
          font-family: sans-serif;
          background-color: transparent !important;
          mso-line-height-rule: exactly;
          line-height: 0.8;
        "
      >
        <tr>
          <td>--&nbsp;</td>
        </tr>
        <tr>
          <td>
            <table style="border-collapse: collapse">
              <tr>
                <td
                  style="
                    font-weight: bold;
                    font-size: 16px;
                    color: #2a4747;
                    text-align: left;
                  "
                >
                  {{ store.name }}
                </td>
              </tr>
              <tr v-if="title">
                <td style="font-size: 16px; color: #2a4747; text-align: left">
                  {{ title }}
                </td>
              </tr>
              <tr v-if="CALicense">
                <td style="font-size: 16px; color: #2a4747; text-align: left">
                  {{ CALicense }}
                </td>
              </tr>
              <tr>
                <td style="padding-bottom: 12px"></td>
              </tr>
              <tr>
                <td>
                  Email:
                  <a
                    :href="`mailto:${store.email}`"
                    style="
                      font-size: 14px !important;
                      text-align: left;
                      color: #2a4747 !important;
                      text-decoration: none !important;
                    "
                  >
                    {{ store.email }}
                  </a>
                </td>
              </tr>
              <tr v-if="store.phoneWork">
                <td
                  style="
                    font-size: 14px !important;
                    text-align: left;
                    color: #717171;
                  "
                >
                  Office:
                  <a
                    :href="`tel:${store.phoneWork}`"
                    style="color: #2a4747 !important; font-size: 14px"
                    >{{ store.phoneWork }}
                  </a>
                </td>
              </tr>
              <tr v-if="store.phoneMobile">
                <td
                  style="
                    font-size: 14px !important;
                    text-align: left;
                    color: #717171;
                  "
                >
                  Mobile:
                  <a
                    :href="`tel:${store.phoneMobile}`"
                    style="color: #2a4747 !important; font-size: 14px"
                    >{{ store.phoneMobile }}
                  </a>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td height="6"></td>
        </tr>
        <tr>
          <td>
            <table style="border-collapse: collapse">
              <tr>
                <td style="padding: 0">
                  <table style="border-collapse: collapse">
                    <tr>
                      <td width="22">
                        <img :src="logos.b9" style="vertical-align: middle" />
                      </td>
                      <td>
                        <span
                          style="
                            font-size: 18px;
                            color: #2a4747;
                            vertical-align: middle;
                            padding-left: 4px;
                          "
                        >
                          BackNine
                        </span>
                        <span
                          style="
                            font-size: 16px !important;
                            color: #717171 !important;
                            vertical-align: middle;
                          "
                          >Insurance and Financial Services Inc.</span
                        >
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr v-if="backnineCALicense">
                <td
                  style="
                    font-size: 14px !important;
                    text-align: left;
                    color: #717171 !important;
                  "
                >
                  {{ backnineCALicense }}
                </td>
              </tr>
              <tr>
                <td
                  style="
                    font-size: 14px !important;
                    text-align: left;
                    color: #717171 !important;
                  "
                >
                  310 N. Westlake Blvd, Suite 240
                </td>
              </tr>
              <tr>
                <td
                  style="
                    font-size: 14px !important;
                    text-align: left;
                    color: #717171 !important;
                  "
                >
                  Westlake Village, CA 91362
                </td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://back9ins.com"
                    style="color: #2a4747 !important; font-size: 14px"
                  >
                    https://back9ins.com
                  </a>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table>
              <tr>
                <td>
                  <a
                    class="text-decoration-none"
                    href="https://www.facebook.com/pages/Back-Nine-Insurance-Services/233315486699772"
                    target="_blank"
                  >
                    <img :src="logos.facebook" />
                  </a>
                </td>
                <td>
                  <a
                    class="text-decoration-none"
                    href="https://www.instagram.com/back9insurance/?hl=en"
                    target="_blank"
                  >
                    <img :src="logos.instagram" />
                  </a>
                </td>
                <td>
                  <a
                    class="text-decoration-none"
                    href="https://www.linkedin.com/company/backnine-insurance"
                    target="_blank"
                  >
                    <img :src="logos.linkedin" />
                  </a>
                </td>
                <td>
                  <a
                    class="text-decoration-none"
                    href="https://twitter.com/Back9ins"
                    target="_blank"
                  >
                    <img :src="logos.twitter" />
                  </a>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </v-card-text>
    <v-card-actions>
      <v-btn class="text-none" color="primary" @click="copyText">
        <v-icon class="mr-1">$mdi-content-copy</v-icon>
        Copy Email Signature
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { parseErrorMessage, selectImage, toDataURL } from "@/util/helpers";
import { getEmployees } from "@/api/employees.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { defineProps, nextTick, ref } from "vue";
import { useSettingsViewStore } from "@/stores/settings-view";
const props = defineProps({
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});

let store;

if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}

const copying = ref(false);
const title = ref(null);
const CALicense = ref(null);
const backnineCALicense = ref(null);
const logos = ref({
  b9: null,
  linkedin: null,
  facebook: null,
  instagram: null,
  twitter: null
});

const snackbar = useSnackbarStore();
function initLogos() {
  toDataURL(require("@/assets/img/backnines.png"), url => {
    logos.value.b9 = url;
  });
  toDataURL(require("@/assets/img/facebook.png"), url => {
    logos.value.facebook = url;
  });
  toDataURL(require("@/assets/img/linkedin.png"), url => {
    logos.value.linkedin = url;
  });
  toDataURL(require("@/assets/img/twitter.png"), url => {
    logos.value.twitter = url;
  });
  toDataURL(require("@/assets/img/instagram.png"), url => {
    logos.value.instagram = url;
  });
}
async function copyText() {
  copying.value = true;
  await nextTick();
  selectImage(document.getElementById("copy"));

  document.execCommand("copy");
  window.getSelection().removeAllRanges();

  snackbar.showSuccessSnackbar({
    message: "Copied Email Signature, just paste into your email client!"
  });
  copying.value = false;
}
async function getEmployeeData() {
  try {
    const employees = await getEmployees({
      agent_id: store.id
    });
    if (!employees?.length) return;
    const [currentAgent] = employees;
    title.value = currentAgent.title;
    if (currentAgent.caLicenseNumber) {
      CALicense.value = `CA License: ${currentAgent.caLicenseNumber}`;
    }
    if (currentAgent.backnineCALicenseNumber) {
      backnineCALicense.value = `CA License: ${currentAgent.backnineCALicenseNumber}`;
    }
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getEmployeeData();
initLogos();
</script>
