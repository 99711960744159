<template>
  <v-icon> {{ icon }} </v-icon>
</template>

<script>
import isHoliday, { HOLIDAYS } from "@/util/is-holiday";
import { mdiHeartBox, mdiRocketLaunch } from "@mdi/js";
export default {
  data: () => ({ icon: mdiRocketLaunch }),
  created() {
    const holiday = isHoliday();
    if (holiday === HOLIDAYS.VALENTINES) {
      this.icon = mdiHeartBox;
    }
  }
};
</script>
