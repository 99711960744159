<template>
  <v-card flat class="rounded-t-0">
    <v-card-title> Entity Information </v-card-title>
    <v-card-text>
      <v-row dense class="ma-0">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="name"
            label="Entity Name"
            data-lpignore="true"
            outlined
            dense
            data-testid="name"
            :prepend-inner-icon="mdiDomain"
            :success="nameValidation.success"
            :error-messages="nameValidation.errorMessages"
            @input="updateAttribute('name')"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.name.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <tin-input
            v-model="tin"
            data-lpignore="true"
            label="Entity TIN"
            outlined
            dense
            data-testid="tin"
            :prepend-inner-icon="mdiPound"
            :success="tinValidation.success"
            :error-messages="tinValidation.errorMessages"
            @input="updateAttribute('tin')"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.tin.controller.value"
              />
            </template>
          </tin-input>
        </v-col>
        <v-col cols="12" md="4">
          <date-input
            v-model="formationDate"
            label="Entity Date"
            outlined
            dense
            data-testid="formation-date"
            :prepend-inner-icon="mdiCalendar"
            :success="dateValidation.success"
            :error-messages="dateValidation.errorMessages"
            @input="updateAttribute('formationDate')"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.formationDate.controller.value"
              />
            </template>
          </date-input>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="irrevocable"
            label="Revocable"
            placeholder="Choose Revocable"
            outlined
            dense
            auto-select-first
            data-testid="irrevocable"
            :prepend-inner-icon="mdiListStatus"
            :success="irrevocableValidation.success"
            :error-messages="irrevocableValidation.errorMessages"
            :items="ENTITY_IRREVOCABLES"
            @change="updateAttribute('irrevocable')"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.irrevocable.controller.value"
              />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" lg="4">
          <basic-email-input
            v-model="email"
            label="Email"
            outlined
            dense
            data-testid="email"
            :prepend-inner-icon="mdiEmail"
            :success="emailValidation.success"
            :error-messages="emailValidation.errorMessages"
            @valid="setEmailIsValid"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.email.controller.value"
              />
            </template>
          </basic-email-input>
        </v-col>
        <v-col cols="12" md="4">
          <phone-input
            v-model="phoneWork"
            label="Office Phone"
            outlined
            dense
            data-lpignore="true"
            data-testid="phone-work"
            :prepend-inner-icon="mdiDeskphone"
            :success="phoneWorkValidation.success"
            :error-messages="phoneWorkValidation.errorMessages"
            @input="updateAttribute('phoneWork')"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.phoneWork.controller.value"
              />
            </template>
          </phone-input>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-title>Entity Location</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <basic-address-input
            key="contract-party-business-address"
            type="business"
            autofill-label="Business Address"
            autofill-placeholder="Business Address"
            data-testid="business"
            :street-address-model.sync="businessAddress.street_address"
            :street-address-validation="streetAddressValidation"
            :city-model.sync="businessAddress.city"
            :city-validation="cityValidation"
            :state-model.sync="businessAddress.state"
            :state-validation="stateValidation"
            :zip-model.sync="businessAddress.zip"
            :zip-validation="zipValidation"
            @update:street-address-model="updateAttribute('businessStreet')"
            @update:city-model="updateAttribute('businessCity')"
            @update:state-model="updateAttribute('businessState')"
            @update:zip-model="updateAttribute('businessZip')"
            @autofill="handleAutofill"
          >
            <template #append-outer-street-address>
              <active-save-indicator
                :controller="savingBuffer.businessStreet.controller.value"
              />
            </template>
            <template #append-outer-city>
              <active-save-indicator
                :controller="savingBuffer.businessCity.controller.value"
              />
            </template>
            <template #append-outer-state>
              <active-save-indicator
                :controller="savingBuffer.businessState.controller.value"
              />
            </template>
            <template #append-outer-zip>
              <active-save-indicator
                :controller="savingBuffer.businessZip.controller.value"
              />
            </template>
          </basic-address-input>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script setup>
import PhoneInput from "@/components/shared/PhoneInput.vue";
import TinInput from "@/components/shared/TinInput.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import DateInput from "@/components/shared/DateInput.vue";
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";
import { useActiveSave } from "@/composables/active-save.composable";
import useVuelidate from "@vuelidate/core";
import { computedValidation, someTextValidator } from "@/util/helpers";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useContractPartyView } from "@/stores/contract-party-view";
import {
  tin as tinValidator,
  phone as phoneValidator
} from "@/util/vuelidateValidators";
import {
  mdiDomain,
  mdiPound,
  mdiCalendar,
  mdiListStatus,
  mdiEmail,
  mdiDeskphone
} from "@mdi/js";
import { ENTITY_IRREVOCABLES } from "@/factories/ContractParty";
import { STATES } from "@/data/states";

const contractPartyView = useContractPartyView();
const {
  email,
  businessAddress,
  name,
  tin,
  irrevocable,
  formationDate,
  phoneWork
} = storeToRefs(contractPartyView);

const emailIsValid = ref(Boolean(email.value));
const savingBuffer = {
  businessStreet: useActiveSave(),
  businessState: useActiveSave(),
  businessCity: useActiveSave(),
  businessZip: useActiveSave(),
  formationDate: useActiveSave(),
  email: useActiveSave(),
  name: useActiveSave(),
  irrevocable: useActiveSave(),
  tin: useActiveSave(),
  phoneWork: useActiveSave()
};

const v$ = useVuelidate(
  {
    name: { required: v => someTextValidator(true, v, 2) },
    tin: { valid: v => !v || tinValidator(v) },
    email: { valid: v => !v || emailIsValid.value },
    irrevocable: {
      valid: v => v === null || ENTITY_IRREVOCABLES.some(r => r.value === v)
    },
    phoneWork: {
      required: v => !v || phoneValidator(v)
    },
    formationDate: { valid: true },
    businessStreet: { required: v => !v || someTextValidator(true, v, 2) },
    businessCity: { required: v => !v || someTextValidator(true, v, 2) },
    businessState: { required: v => !v || STATES.some(s => s.value === v) },
    businessZip: { required: v => !v || `${v}`.length === 5 }
  },
  {
    name,
    tin,
    email,
    businessStreet: computed(() => businessAddress.value.street_address),
    businessCity: computed(() => businessAddress.value.city),
    businessState: computed(() => businessAddress.value.state),
    businessZip: computed(() => businessAddress.value.zip),
    irrevocable,
    formationDate,
    phoneWork
  },
  { $autoDirty: true, $scope: null }
);

const nameValidation = computedValidation(v$.value.name, {
  required: "Required"
});
const tinValidation = computedValidation(v$.value.tin, {
  valid: "Invalid TIN"
});
const dateValidation = computedValidation(v$.value.formationDate, {
  valid: "Invalid Date"
});
const irrevocableValidation = computedValidation(v$.value.irrevocable, {
  valid: "Invalid Revocable"
});
const streetAddressValidation = computedValidation(v$.value.businessStreet, {
  required: "Required"
});
const cityValidation = computedValidation(v$.value.businessCity, {
  required: "Required"
});
const stateValidation = computedValidation(v$.value.businessState, {
  required: "Required"
});
const zipValidation = computedValidation(v$.value.businessZip, {
  required: "Required"
});
const phoneWorkValidation = computedValidation(v$.value.phoneWork, {
  required: "Invalid Phone"
});
const emailValidation = computedValidation(v$.value.email, {
  valid: "Invalid Email"
});

function handleAutofill({ street_address, city, state, zip }) {
  businessAddress.value.street_address = street_address;
  businessAddress.value.city = city;
  businessAddress.value.state = state;
  businessAddress.value.zip = zip;
  savingBuffer.businessStreet.debounceUpdate(() =>
    contractPartyView.updateEntityAttribute("businessAddress")
  );
}

function setEmailIsValid(isValid) {
  emailIsValid.value = isValid;
  updateAttribute("email");
}

function updateAttribute(attribute) {
  savingBuffer[attribute].debounceUpdate(async () => {
    const isValid = await v$.value[attribute].$validate();
    if (!isValid) return;
    return contractPartyView.updateEntityAttribute(attribute);
  });
}
</script>
