export const ELIMINATION_PERIODS = [
  { text: "0 Days", value: "0 Days" },
  { text: "30 Days", value: "30 Days" },
  { text: "60 Days", value: "60 Days" },
  { text: "90 Days (recommended)", value: "90 Days" },
  { text: "180 Days", value: "180 Days" },
  { text: "1 Year", value: "1 Year" },
  { text: "2 Years", value: "2 Years" },
  { text: "5 Years", value: "5 Years" }
];

export const CASE_STATUS = {
  SUBMITTED: "Submitted",
  APPROVED: "Approved",
  AWAITING_REISSUED_POLICY: "Awaiting Reissued Policy",
  AWAITING_RECONSIDERATION: "Awaiting Reconsideration",
  ISSUED: "Issued",
  AWAITING_DELIVERY_REQUIREMENTS: "Awaiting Delivery Requirements",
  RECEIVED_DELIVERY_REQUIREMENTS: "Received Delivery Requirements",
  ACTIVE: "Active",
  ACTIVE_PENDING_REQUIREMENTS: "Active - Pending Requirements"
};

export const STATUSES = [
  "Entered",
  CASE_STATUS.SUBMITTED,
  CASE_STATUS.APPROVED,
  CASE_STATUS.ISSUED,
  CASE_STATUS.AWAITING_REISSUED_POLICY,
  CASE_STATUS.AWAITING_RECONSIDERATION,
  CASE_STATUS.AWAITING_DELIVERY_REQUIREMENTS,
  CASE_STATUS.RECEIVED_DELIVERY_REQUIREMENTS,
  CASE_STATUS.ACTIVE_PENDING_REQUIREMENTS,
  CASE_STATUS.ACTIVE,
  "Withdrawn",
  "Not Taken",
  "Postponed",
  "Declined",
  "Closed - Not Submitted to Carrier",
  "Closed - Submitted by Another GA",
  "Grace Period",
  "Lapsed",
  "Deceased/Death Claim"
];

export const INFORMAL_STATUSES = [
  "All Offers In",
  "Informal Formalized",
  "Informal Closed",
  "Awaiting Carrier Offers",
  "Gathering Medical Information",
  "Informal Entered"
];

export const modeOptions = [
  { text: "Monthly", value: 12 },
  { text: "Quarterly", value: 4 },
  { text: "Semi-Annual", value: 2 },
  { text: "Annually", value: 1 }
];
export const lineOptions = {
  life: "Life",
  annuity: "Annuity",
  disability: "Disability",
  ltc: "LTC"
};
export const trackingTypes = [{ text: "USPS" }, { text: "FedEx" }];

export const RATINGS_BY_LINE = {
  annuity: [
    "Super Preferred",
    "Preferred NT",
    "Preferred Tobacco",
    "Standard Plus",
    "Standard NT",
    "Standard Tobacco",
    "Table 1 Tobacco",
    "Table 2 Tobacco",
    "Table 3 Tobacco",
    "Table 4 Tobacco",
    "Table 5 Tobacco",
    "Table 6 Tobacco",
    "Table 7 Tobacco",
    "Table 8 Tobacco",
    "Table 9 Tobacco",
    "Table 10 Tobacco",
    "Table 1",
    "Table 2",
    "Table 3",
    "Table 4",
    "Table 5",
    "Table 6",
    "Table 7",
    "Table 8",
    "Table 9",
    "Table 10",
    "Table 11",
    "Table 12",
    "Table 13",
    "Table 14",
    "Table 15",
    "Table 16",
    "Table 17",
    "Table 18",
    "Uninsurable 19",
    "Uninsurable 20",
    "Declined",
    "Postponed"
  ],
  disability: [
    "Super Preferred",
    "Preferred NT",
    "Preferred Tobacco",
    "Standard Plus",
    "Standard NT",
    "Standard Tobacco",
    "Table 1 Tobacco",
    "Table 2 Tobacco",
    "Table 3 Tobacco",
    "Table 4 Tobacco",
    "Table 5 Tobacco",
    "Table 6 Tobacco",
    "Table 7 Tobacco",
    "Table 8 Tobacco",
    "Table 9 Tobacco",
    "Table 10 Tobacco",
    "Table 1",
    "Table 2",
    "Table 3",
    "Table 4",
    "Table 5",
    "Table 6",
    "Table 7",
    "Table 8",
    "Table 9",
    "Table 10",
    "Table 11",
    "Table 12",
    "Table 13",
    "Table 14",
    "Table 15",
    "Table 16",
    "Table 17",
    "Table 18",
    "Uninsurable 19",
    "Uninsurable 20",
    "Declined",
    "Postponed"
  ],
  life: [
    "Super Preferred",
    "Preferred NT",
    "Preferred Tobacco",
    "Standard Plus",
    "Standard NT",
    "Standard Tobacco",
    "Table 1",
    "Table 1 Tobacco",
    "Table 2",
    "Table 2 Tobacco",
    "Table 3",
    "Table 3 Tobacco",
    "Table 4",
    "Table 4 Tobacco",
    "Table 5",
    "Table 5 Tobacco",
    "Table 6",
    "Table 6 Tobacco",
    "Table 7",
    "Table 7 Tobacco",
    "Table 8",
    "Table 8 Tobacco",
    "Table 9",
    "Table 9 Tobacco",
    "Table 10",
    "Table 10 Tobacco",
    "Table 11",
    "Table 12",
    "Table 13",
    "Table 14",
    "Table 15",
    "Table 16",
    "Table 17",
    "Table 18",
    "Uninsurable 19",
    "Uninsurable 20",
    "Level",
    "Level Tobacco",
    "Graded",
    "Guaranteed Issue",
    "Declined",
    "Postponed"
  ],
  linked_benefit: [
    "Super Preferred",
    "Preferred NT",
    "Preferred Tobacco",
    "Standard Plus",
    "Standard NT",
    "Standard Tobacco",
    "Table 1 Tobacco",
    "Table 2 Tobacco",
    "Table 3 Tobacco",
    "Table 4 Tobacco",
    "Table 5 Tobacco",
    "Table 6 Tobacco",
    "Table 7 Tobacco",
    "Table 8 Tobacco",
    "Table 9 Tobacco",
    "Table 10 Tobacco",
    "Table 1",
    "Table 2",
    "Table 3",
    "Table 4",
    "Table 5",
    "Table 6",
    "Table 7",
    "Table 8",
    "Table 9",
    "Table 10",
    "Table 11",
    "Table 12",
    "Table 13",
    "Table 14",
    "Table 15",
    "Table 16",
    "Table 17",
    "Table 18",
    "Uninsurable 19",
    "Uninsurable 20",
    "Declined",
    "Postponed"
  ],
  ltc: ["Preferred", "Select", "Class 1", "Class 2", "Postponed", "Declined"],
  variable_life: [
    "Super Preferred",
    "Preferred NT",
    "Preferred Tobacco",
    "Standard Plus",
    "Standard NT",
    "Standard Tobacco",
    "Table 1 Tobacco",
    "Table 2 Tobacco",
    "Table 3 Tobacco",
    "Table 4 Tobacco",
    "Table 5 Tobacco",
    "Table 6 Tobacco",
    "Table 7 Tobacco",
    "Table 8 Tobacco",
    "Table 9 Tobacco",
    "Table 10 Tobacco",
    "Table 1",
    "Table 2",
    "Table 3",
    "Table 4",
    "Table 5",
    "Table 6",
    "Table 7",
    "Table 8",
    "Table 9",
    "Table 10",
    "Table 11",
    "Table 12",
    "Table 13",
    "Table 14",
    "Table 15",
    "Table 16",
    "Table 17",
    "Table 18",
    "Uninsurable 19",
    "Uninsurable 20",
    "Declined",
    "Postponed"
  ]
};

export const TAX_OPTIONS = [
  { value: "401k", text: "401k" },
  { value: "403b", text: "403b" },
  { value: "412i Plan", text: "412i Plan" },
  { value: "457 Deferred Compensation", text: "457 Deferred Compensation" },
  { value: "Inherited IRA Plan", text: "Inherited IRA Plan" },
  { value: "Inherited Roth IRA Plan", text: "Inherited Roth IRA Plan" },
  { value: "IRA", text: "IRA" },
  { value: "Non-Qualified", text: "Non-Qualified" },
  { value: "Pension Plan", text: "Pension Plan" },
  { value: "Profit Sharing Plan", text: "Profit Sharing Plan" },
  { value: "Roth 401k", text: "Roth 401k" },
  { value: "Roth 403b", text: "Roth 403b" },
  { value: "Roth IRA", text: "Roth IRA" },
  { value: "SEP-IRA", text: "SEP-IRA" },
  { value: "Simple IRA", text: "Simple IRA" }
];

export const OCCUPATION_CLASSES = [
  "6A",
  "6S",
  "5A",
  "5M",
  "4S",
  "4P",
  "4A",
  "4M",
  "3A",
  "3P",
  "3AM",
  "2A",
  "2L",
  "1L",
  "A",
  "B"
];

export const COLA_OPTIONS = [
  { text: "None", value: null },
  { text: "1% Compound", value: "1% Compound" },
  { text: "2% Compound", value: "2% Compound" },
  { text: "3% Compound", value: "3% Compound" },
  { text: "4% Compound", value: "4% Compound" },
  { text: "5% Compound", value: "5% Compound" },
  { text: "6% Compound", value: "6% Compound" }
];

export const CASE_STATUSES = [
  "Entered",
  "Submitted",
  "Approved",
  "Issued",
  "Awaiting Reissued Policy",
  "Awaiting Reconsideration",
  "Awaiting Delivery Requirements",
  "Received Delivery Requirements",
  "Active - Pending Requirements",
  "Active",
  "Withdrawn",
  "Not Taken",
  "Postponed",
  "Declined",
  "Closed - Not Submitted to Carrier",
  "Closed - Submitted by Another GA",
  "Grace Period",
  "Lapsed",
  "Deceased/Death Claim"
];

export const DISABILITY_BENEFIT_PERIODS = [
  "Lump Sum",
  "6 Months",
  "12 Months",
  "18 Months",
  "2 Years",
  "3 Years",
  "4 Years",
  "5 Years",
  "10 Years",
  "To Age 65",
  "To Age 66",
  "To Age 67",
  "To Age 70"
];

export const BILLING_PLANS = ["Direct Bill", "EFT", "List Bill"];
export const MODES = [
  { text: "Monthly", value: 12 },
  { text: "Quarterly", value: 4 },
  { text: "Semi-Annually", value: 2 },
  { text: "Annually", value: 1 }
];

export const BENEFIT_MODES = ["Daily", "Monthly"];
