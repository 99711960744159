<template>
  <v-row dense class="ma-n3">
    <v-slide-y-transition mode="out-in">
      <v-col v-if="!fullscreen || !vuetify.breakpoint.lgAndUp" cols="12">
        <quote-and-apply-hero />
      </v-col>
    </v-slide-y-transition>
    <v-col cols="12">
      <v-tabs
        v-model="activeTab"
        background-color="transparent"
        class="transparent-tab-item"
        @change="handleTabChange"
      >
        <v-tab v-for="table in tables" :key="table.view" class="text-none">
          <v-icon class="mr-1"> {{ table.icon }} </v-icon> {{ table.title }}
        </v-tab>
        <v-spacer />
        <v-btn
          v-if="vuetify.breakpoint.lgAndUp"
          icon
          @click="fullscreen = !fullscreen"
        >
          <v-icon v-if="fullscreen">$mdi-fullscreen-exit</v-icon>
          <v-icon v-else>$mdi-fullscreen</v-icon>
        </v-btn>
      </v-tabs>
      <v-tabs-items v-model="activeTab" touchless>
        <v-tab-item v-for="table in tables" :key="table.view">
          <component :is="table.component" />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>
<script setup>
import QuoteAndApplyHero from "@/components/quote-and-apply/QuoteAndApplyHero.vue";

import ElectronicApplicationsTable from "@/components/electronic-applications/ElectronicApplicationsTable.vue";
import ElectronicApplicationPipeline from "@/components/electronic-applications/ElectronicApplicationPipeline.vue";
import QuoteAndApplyWebsites from "@/components/quote-and-apply/QuoteAndApplyWebsites.vue";

import { useHead } from "@unhead/vue";
import { storeToRefs } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { markRaw, onBeforeUnmount, ref, watch, defineProps, toRef } from "vue";
import { useRouter, useVuetify } from "@/composables/compatible.composables";

const props = defineProps({
  view: {
    type: String,
    required: false,
    default: null
  }
});
useHead({ title: "Quote & Apply" });

const router = useRouter();
const vuetify = useVuetify();
const tables = [
  {
    component: markRaw(ElectronicApplicationsTable),
    view: "list",
    title: "List",
    icon: "$mdi-table"
  },
  {
    component: markRaw(ElectronicApplicationPipeline),
    view: "pipeline",
    title: "Pipeline",
    icon: "$mdi-view-column-outline"
  },
  {
    component: markRaw(QuoteAndApplyWebsites),
    view: "websites",
    title: "Websites",
    icon: "$mdi-web"
  }
];

const currentQueryView = toRef(props, "view");

const activeTab = ref(0);
const { fullscreen } = storeToRefs(useInstanceStore());

onBeforeUnmount(() => (fullscreen.value = false));

function initTab() {
  if (!currentQueryView.value) return;
  const index = tables.findIndex(v => v.view === currentQueryView.value);
  if (index === -1) return;
  activeTab.value = index;
}

function handleTabChange(index) {
  if (currentQueryView.value === tables[index].view) return;
  router.replace({ query: { view: tables[index].view } });
}

watch(
  currentQueryView,
  () => {
    if (!currentQueryView.value) return;
    const index = tables.findIndex(v => v.view === currentQueryView.value);
    if (index === -1 || activeTab.value === index) return;
    activeTab.value = index;
  },
  { immediate: true }
);

initTab();
</script>
