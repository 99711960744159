<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card class="mb-0" rounded flat>
            <v-card-title>Change Agent and Assign To</v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-select
                    data-testid="change-agent"
                    prepend-inner-icon="$mdi-account-tie"
                    outlined
                    dense
                    return-object
                    item-text="name"
                    label="Agent"
                    hint="Click the dropdown to change the agent on this Quote. Add the agent as an access if they do not appear in the list."
                    persistent-hint
                    :value="agent"
                    :success="Boolean(agent?.id)"
                    :disabled="readonly"
                    :items="agentOptions"
                    @input="handleAgentChange"
                  >
                    <template #append-outer>
                      <active-save-indicator :controller="savingBuffer.agent" />
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    data-testid="change-assignable"
                    prepend-inner-icon="$mdi-account-tie"
                    outlined
                    dense
                    return-object
                    item-text="name"
                    label="Assign To"
                    hint="Click the dropdown to change who this Quote is assigned to. Add the desired assignable as access if they do not appear in the list."
                    persistent-hint
                    :value="assignable"
                    :success="Boolean(assignable?.id)"
                    :disabled="readonly"
                    :items="agentOptions"
                    @input="handleAssignableChange"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="savingBuffer.assignable"
                      />
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card flat>
            <v-card-title>Access Table </v-card-title>
            <access
              v-model="opportunities"
              data-testid="access-table"
              type="quote"
              button-color="accent"
              button-text="Add Access"
              :checkboxes="checkboxes"
              :update-func="updateAccess"
              :delete-func="removeAccess"
              :add-func="addAccess"
              :name-link="nameLinkGenerator"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import Access from "@/components/shared/Access.vue";
import {
  addQuoteAccess,
  updateQuoteAccess,
  removeQuoteAccess,
  updateQuote
} from "@/api/quotes.service";
import { useQuoteViewStore } from "@/stores/quote-view";
import { mapWritableState, mapActions, mapState } from "pinia";
export default {
  components: {
    Access,
    ActiveSaveIndicator
  },
  mixins: [ActiveSaveMixin],
  props: {
    readonly: Boolean
  },
  data() {
    const checkboxes = [
      {
        text: "Email",
        value: "email"
      }
    ];
    const savingBuffer = {
      agent: this.activeSaveElementFactory(),
      assignable: this.activeSaveElementFactory()
    };
    return {
      checkboxes,
      savingBuffer
    };
  },
  computed: {
    ...mapState(useQuoteViewStore, ["agent", "id", "assignable"]),
    ...mapWritableState(useQuoteViewStore, ["opportunities"]),
    agentOptions() {
      return this.opportunities.filter(access =>
        access.ownerType?.toLowerCase()?.includes("agent")
      );
    }
  },
  methods: {
    ...mapActions(useQuoteViewStore, ["updateAgent"]),
    handleAgentChange({ id: newAgentId }) {
      const prevAgent = this.agent?.id;
      this.setActiveAgent(newAgentId);
      this.updateAttributeWrapper(
        () =>
          updateQuoteAccess(this.id, newAgentId, { agent: true }).catch(e => {
            if (prevAgent) this.setActiveAgent(prevAgent);
            throw e;
          }),
        this.savingBuffer.agent
      );
    },
    handleAssignableChange({ ownerType, name, ownerId }) {
      this.assignable.id = ownerId;
      this.assignable.name = name;
      this.assignable.type = ownerType;
      this.updateAttributeWrapper(
        () =>
          updateQuote(this.id, {
            quote: {
              assignable_id: this.assignable.id,
              assignable_type: this.assignable.type
            }
          }),
        this.savingBuffer.assignable
      );
    },
    setActiveAgent(currId) {
      if (this.agent?.id) {
        const prevIndex = this.opportunities.findIndex(
          ({ id: i }) => this.agent.id === i
        );
        this.opportunities[prevIndex].agent = false;
      }

      const currIndex = this.opportunities.findIndex(
        ({ id: i }) => currId === i
      );
      this.opportunities[currIndex].agent = true;

      this.updateAgent(this.opportunities[currIndex]);
    },
    async addAccess(access) {
      if (!access) return;
      return addQuoteAccess(this.id, access);
    },
    async updateAccess(access, attribute) {
      return await updateQuoteAccess(this.id, access.additional.id, {
        [attribute]: access[attribute]
      });
    },
    async removeAccess(access) {
      return await removeQuoteAccess(this.id, access.additional.id);
    },
    nameLinkGenerator(access) {
      const typeArr = [
        access.additional.ownable.type?.model,
        access.additional.ownable.type
      ];
      if (typeArr.includes("Agent")) {
        return {
          name: "AgentView",
          params: {
            id: access.additional.ownable.id
          }
        };
      } else if (typeArr.includes("Agency")) {
        return {
          name: "AgencyView",
          params: {
            id: access.additional.ownable.id
          }
        };
      }
      return null;
    }
  }
};
</script>
