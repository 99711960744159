<template>
  <v-card flat>
    <v-row class="ma-0">
      <v-col cols="12">
        <v-row align="center">
          <v-col>
            <h1 class="text-h5 black--text">Your Quote & Apply Websites</h1>
          </v-col>
          <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
          <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : null">
            <v-row class="ma-0">
              <v-text-field
                data-lpignore="true"
                class="mr-3"
                prepend-inner-icon="$mdi-magnify"
                label="Search for a Quote & Apply Website"
                outlined
                dense
                hide-details
                v-model="searchText"
                clearable
              />
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="$emit('toggle-condensed-view')"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon>$mdi-database</v-icon>
                  </v-btn>
                </template>
                <span>Show Expanded View</span>
              </v-tooltip>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-row class="ma-0">
          <template v-for="(item, index) in currentSiteChunk">
            <v-col v-if="item" :key="item.id" cols="12" md="4" lg="3" sm="6">
              <v-card
                v-if="item.id"
                :to="{
                  name: 'ApprovedDomains',
                  params: { id: item.id }
                }"
                min-height="256"
                style="overflow: hidden; position: relative"
                outlined
              >
                <v-card-title>{{ item.domain }}</v-card-title>
                <v-card-text>
                  Advisor: {{ item.advisorName }}<br />
                  Marketing As:
                  {{ item.marketingAsName }}<br />
                  Start Page: <start-page-formatter :value="item.startPage" />
                </v-card-text>
                <div
                  v-if="!item.avatarUrl"
                  style="
                    left: 40px;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    position: absolute;
                    height: 256px;
                    font-size: 3rem;
                    color: rgba(0, 0, 0, 0.06);
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                  "
                >
                  {{ item.domain }}
                </div>
                <div
                  v-else
                  style="
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    position: absolute;
                    height: 256px;
                    font-size: 3rem;
                    color: rgba(0, 0, 0, 0.06);
                    font-weight: 400;
                  "
                >
                  <v-img
                    :src="item.avatarUrl"
                    style="opacity: 0.06"
                    height="100%"
                    width="100%"
                    contain
                  />
                </div>
                <v-card-actions style="position: absolute; bottom: 0; right: 0">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        color="primary"
                        class="text-none"
                        :href="`//${item.domain}`"
                        target="_blank"
                      >
                        <v-icon>$mdi-open-in-new</v-icon>
                      </v-btn>
                    </template>
                    <span>Launch Quote & Apply</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        color="accent"
                        class="text-none"
                        :to="{
                          name: 'ApprovedDomains',
                          params: { id: item.id }
                        }"
                      >
                        <v-icon>$mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <span>Website Settings</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        color="orange"
                        class="text-none"
                        @click.prevent="$emit('code', item)"
                      >
                        <v-icon>$mdi-code-tags</v-icon>
                      </v-btn>
                    </template>
                    <span>View Embedded Code</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        color="error"
                        class="text-none"
                        @click.prevent="() => deleteDomain(item)"
                      >
                        <v-icon>$mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Website</span>
                  </v-tooltip>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col
              v-else
              :key="`loading-${index}`"
              cols="12"
              md="4"
              lg="3"
              sm="6"
            >
              <v-card outlined>
                <v-skeleton-loader type="card" />
              </v-card>
            </v-col>
          </template>
          <v-col cols="12" v-if="siteChunkCount > 1">
            <v-pagination
              v-model="activeSitePage"
              :length="siteChunkCount"
              :total-visible="7"
            />
          </v-col>

          <template
            v-else-if="!chunksLoading.length && !dataCardLoadedElements.length"
          >
            <p v-if="loaded" class="pa-3">No results</p>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import StartPageFormatter from "@/components/shared/formatters/StartPageFormatter.vue";
import cloneDeep from "lodash/cloneDeep";
const CHUNK_SIZE = 20;
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { getApprovedDomains } from "@/api/approved-domain.service";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  name: "DataCardList",
  components: {
    StartPageFormatter
  },
  data() {
    return {
      dataCardLoadedElements: [null, null, null, null],
      chunksLoading: [],
      activeSitePage: 1,
      meta: {},
      timer: null,
      loaded: false,
      searchText: "",
      cancelToken: null
    };
  },
  props: {
    deleteDialog: Function
  },
  computed: {
    chunkSize() {
      if (this.$vuetify.breakpoint.md) {
        return 3;
      } else if (this.$vuetify.breakpoint.sm) {
        return 2;
      } else if (this.$vuetify.breakpoint.xs) {
        return 1;
      } else {
        return 4;
      }
    },
    siteChunkCount() {
      return Math.ceil(this.meta?.total / this.chunkSize);
    },
    currentSiteChunk() {
      if (!this.dataCardLoadedElements) return [];
      const offset = (this.activeSitePage - 1) * this.chunkSize;
      return this.dataCardLoadedElements.slice(offset, offset + this.chunkSize);
    },
    titleText() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return "text-h4";
      } else if (this.$vuetify.breakpoint.md) {
        return "text-h5";
      } else {
        return "text-h6";
      }
    }
  },
  watch: {
    searchText() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.dataCardLoadedElements = [null, null, null, null];
        this.chunksLoading = [];
        this.activeSitePage = 1;
        this.loaded = false;
        this.meta = { total: 4 };
        this.getData();
      }, 300);
    },
    currentSiteChunk: {
      deep: true,
      handler() {
        if (!this.loaded) return;
        const index = this.currentSiteChunk.findIndex(val => !val);
        if (index === -1) return null;
        const itemIndex = this.chunkSize * this.activeSitePage + index - 1;
        const requestedChunk = Math.ceil(itemIndex / CHUNK_SIZE);
        if (this.chunksLoading.includes(requestedChunk)) return;
        if (requestedChunk) this.chunksLoading.push(requestedChunk);

        this.getData(requestedChunk).then(() => {
          const chunkIndex = this.chunksLoading.findIndex(
            val => val === requestedChunk
          );

          this.chunksLoading.splice(chunkIndex, 1);
        });
      }
    }
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    deleteDomain(item) {
      this.deleteDialog(item).then(res => {
        if (!res?.delete) return;
        const index = this.dataCardLoadedElements.findIndex(
          val => val?.id === item.id
        );
        this.dataCardLoadedElements.splice(index, 1);
        const elements = cloneDeep(this.dataCardLoadedElements);
        this.dataCardLoadedElements = [];
        this.dataCardLoadedElements = elements;
        this.meta.total--;

        if (this.currentSiteChunk.length > 0 || this.meta.total == 0) return;
        if (this.activeSitePage === 0) this.activeSitePage++;
        this.activeSitePage--;
      });
    },
    async getData(chunk = 1) {
      const urlSearch = new URLSearchParams();
      urlSearch.append("page", chunk.toString());
      urlSearch.append("count", CHUNK_SIZE.toString());
      urlSearch.append("view", "my");
      if (this.searchText) {
        urlSearch.append("filter[domain]", this.searchText);
      }
      try {
        const { approvedDomains, meta } = await getApprovedDomains(
          urlSearch,
          this.cancelToken
        );
        this.meta = meta;
        if (!this.dataCardLoadedElements.filter(Boolean).length) {
          this.dataCardLoadedElements = new Array(this.meta?.total);
        }

        const newElements = approvedDomains;
        newElements.forEach((element, index) => {
          this.dataCardLoadedElements.splice(
            (chunk - 1) * CHUNK_SIZE + index,
            1,
            element
          );
        });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loaded = true;
      }
    }
  }
};
</script>
