<template>
  <v-autocomplete
    v-model="user"
    autocomplete="false"
    prepend-inner-icon="$mdi-account"
    placeholder="Search Users by Email"
    label="User"
    outlined
    dense
    item-text="email"
    return-object
    no-filter
    persistent-hint
    hint=" "
    :hide-no-data="!includeNew || !searchText || loading || typing"
    :disabled="disabled"
    :search-input.sync="searchText"
    :success="success"
    :error-messages="errorMessages"
    :items="users"
    :loading="loading"
    @blur="$emit('blur')"
  >
    <template v-if="hasAppendOuterSlot" #append-outer>
      <slot name="append-outer" />
    </template>
    <template #no-data>
      <v-list-item
        data-testid="existing-search-create-new-user"
        @click="$emit('create-new', searchText)"
      >
        <v-list-item-content>
          <v-list-item-title> {{ searchText }} </v-list-item-title>
          <v-list-item-subtitle>
            User not found. Create new user?
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template #message="{ message }">
      <v-row class="pa-3">
        {{ message }}
        <v-spacer />
        <slot name="message-inner" />
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script>
import { userSearch } from "@/api/search.service";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  props: {
    success: Boolean,
    errorMessages: Array,
    value: { type: Object, required: false, default: null },
    disabled: Boolean,
    includeNew: Boolean
  },
  data() {
    let users = [];
    let user = null;
    if (this.value && Object.keys(this.value).length && this.value) {
      users = [this.value];
      user = this.value;
    }
    return {
      searchText: "",
      timer: null,
      loading: false,
      typing: false,
      users,
      user
    };
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.user = null;
        this.users = [];
        return;
      }
      this.user = value;
      this.users = [value];
    },
    user(value) {
      this.$emit("input", value);
    },
    searchText(value) {
      if (!value || !Object.keys(value).length) {
        this.typing = false;
        return;
      }
      if (this.users.some(val => val?.email === value)) {
        this.typing = false;
        return;
      }
      this.typing = true;
      this.debounceAndSearch();
    }
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots["append-outer"];
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    debounceAndSearch() {
      if (this.timer) clearTimeout(this.timer);

      this.timer = setTimeout(this.search, 200);
    },
    async search() {
      this.loading = true;
      try {
        this.users = await userSearch(this.searchText);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
        this.typing = false;
      }
    }
  }
};
</script>
