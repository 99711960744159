<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        class="transparent-data-table"
        data-testid="party-table"
      >
        <template #[`item.name`]="{ item }">
          <router-link
            v-if="item.additional.ownable.type === 'individual'"
            :to="{
              name: 'IndividualView',
              params: {
                id: item.additional.ownable.id
              }
            }"
          >
            {{ item.name }}
          </router-link>
          <router-link
            v-else
            :to="{
              name: 'ContractPartyView',
              params: {
                id: item.additional.ownable.id
              }
            }"
          >
            {{ item.name }}
          </router-link>
        </template>
        <template #[`item.birthdate`]="{ item }">
          <timestamp-formatter :value="item.birthdate" parser="sole-day" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";
import Table from "@/classes/data-table/Table";
import TableHeader from "@/classes/data-table/TableHeader";
import { mapState } from "pinia";
import { useQuoteViewStore } from "@/stores/quote-view";

export default {
  mixins: [GenericTableMixin],
  components: { TimestampFormatter },
  data() {
    const quoteView = useQuoteViewStore();
    const headers = [
      new TableHeader({
        text: "Name",
        value: "name",
        map: "ownable.name"
      }),
      new TableHeader({
        text: "Role",
        value: "role",
        map: "role"
      }),
      ["life", "ltc"].includes(quoteView.line)
        ? new TableHeader({
            text: "Underwriting Class",
            value: "underwriting",
            map: "underwritingClass"
          })
        : null,
      new TableHeader({
        text: "Birthdate",
        value: "birthdate",
        map: "ownable.birthdate"
      })
    ].filter(Boolean);
    const table = new Table({
      headers
    });

    return {
      table
    };
  },
  created() {
    this.table.rawItems.push(...this.quoteRoles);
  },
  computed: {
    ...mapState(useQuoteViewStore, ["quoteRoles"]),
    headers() {
      return this.table.headers;
    },
    items() {
      return this.tableMap(this.table.rawItems, this.table.headers);
    }
  }
};
</script>
