export function PayPeriod(model = {}) {
  return {
    id: model?.id,
    paid_amount: model?.paid_amount,
    paid_date_value: model?.paid_date_value,
    status: model?.status,
    eft: Eft(model?.eft),
    ownable: Ownable(model?.ownable),
    billable: Ownable(model?.billable),
    payments: model?.payments || []
  };
}

export function setPayPeriodFromRequest(raw = {}) {
  const payPeriod = PayPeriod();

  payPeriod.id = raw?.id;
  payPeriod.paid_amount = raw?.paid_amount;
  payPeriod.paid_date_value = raw?.paid_date_value;
  payPeriod.status = raw?.status;
  payPeriod.eft = setEftFromRequest(raw?.eft);
  payPeriod.ownable = setOwnableFromRequest(raw?.ownable);
  payPeriod.billable = setOwnableFromRequest(raw?.billable);
  if (Array.isArray(raw?.payments)) {
    payPeriod.payments = raw?.payments.map(payment =>
      setPaymentFromRequest(payment)
    );
  }

  return payPeriod;
}

function Eft(model = {}) {
  return {
    id: model?.id,
    paying_to: model?.paying_to
  };
}

function setEftFromRequest(raw = {}) {
  const eft = Eft();
  eft.id = raw?.id;
  eft.paying_to = raw?.paying_to;
  return eft;
}

function Ownable(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    avatar_url: model?.avatar_url,
    type: model?.type,
    email: model?.email,
    phone_work: model?.phone_work,
    primary_address: PrimaryAddress(model?.primary_address)
  };
}

function setOwnableFromRequest(raw = {}) {
  const ownable = Ownable();

  ownable.id = raw?.id;
  ownable.name = raw?.name;
  ownable.avatar_url = raw?.avatar_url;
  ownable.type = raw?.type?.model;
  ownable.email = raw?.email;
  ownable.phone_work = raw?.phone_work;
  ownable.primary_address = setPrimaryAddressFromRequest(raw?.primary_address);

  return ownable;
}

function PrimaryAddress(model = {}) {
  return {
    id: model?.id,
    created_at: model?.created_at,
    updated_at: model?.updated_at,
    deleted_at: model?.deleted_at,
    street_address: model?.street_address,
    zip: model?.zip,
    city: model?.city,
    state: model?.state,
    country: model?.country,
    address_type: model?.address_type,
    addressable_id: model?.addressable_id,
    addressable_type: model?.addressable_type,
    dtcc: model?.dtcc,
    raw_address: model?.raw_address,
    start_date: model?.start_date,
    extra: model?.extra,
    primary: model?.primary,
    end_date: model?.end_date
  };
}

function setPrimaryAddressFromRequest(raw = {}) {
  const address = PrimaryAddress();

  address.id = raw?.id;
  address.created_at = raw?.created_at;
  address.updated_at = raw?.updated_at;
  address.deleted_at = raw?.deleted_at;
  address.street_address = raw?.street_address;
  address.zip = raw?.zip;
  address.city = raw?.city;
  address.state = raw?.state;
  address.country = raw?.country;
  address.address_type = raw?.address_type;
  address.addressable_id = raw?.addressable_id;
  address.addressable_type = raw?.addressable_type;
  address.dtcc = raw?.dtcc;
  address.raw_address = raw?.raw_address;
  address.start_date = raw?.start_date;
  address.extra = raw?.extra;
  address.primary = raw?.primary;
  address.end_date = raw?.end_date;

  return address;
}

export function Payment(model = {}) {
  let carrier = null;
  if (model?.carrier) carrier = PaymentCarrier(model?.carrier);

  return {
    id: model?.id,
    split_percent: model?.split_percent,
    statement_date: model?.statement_date,
    premium: model?.premium,
    commission_type: model?.commission_type,
    percent: model?.percent,
    dollar: model?.dollar,
    net_retained: model?.net_retained,
    description: model?.description,

    insured: PaymentInsured(model?.insured),
    case: PaymentCase(model?.case),
    advisor: PaymentAdvisor(model?.advisor),
    carrier
  };
}

export function PaymentCarrier(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    avatar_url: model?.avatar_url,
    instant_aps: model?.instant_aps
  };
}

export function setPaymentCarrierFromRequest(raw = {}) {
  const carrier = PaymentCarrier();
  carrier.id = raw?.id;
  carrier.name = raw?.name;
  carrier.avatar_url = raw?.avatar_url;
  carrier.instant_aps = raw?.instant_aps;
  return carrier;
}

export function PaymentAdvisor(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    link: model?.link
  };
}

export function setPaymentAdvisorFromRequest(raw = {}) {
  const advisor = PaymentAdvisor();
  advisor.id = raw?.id;
  advisor.name = raw?.name;
  advisor.link = raw?.link;
  return advisor;
}

export function PaymentCase(model = {}) {
  return {
    id: model?.id,
    line: model?.line,
    policy_number: model?.policy_number
  };
}

export function setPaymentCaseFromRequest(raw = {}) {
  const paymentCase = PaymentCase();
  paymentCase.id = raw?.id;
  paymentCase.line = raw?.line;
  paymentCase.policy_number = raw?.policy_number;
  return paymentCase;
}

export function PaymentInsured(model = {}) {
  return {
    id: model?.id,
    link: model?.link,
    name: model?.name
  };
}

export function setPaymentInsuredFromRequest(raw = {}) {
  const insured = PaymentInsured();
  insured.id = raw?.id;
  insured.link = raw?.link;
  insured.name = raw?.name;
  return insured;
}

export function setPaymentFromRequest(raw = {}) {
  const payment = Payment();

  payment.id = raw?.id;
  payment.split_percent = raw?.split_percent;
  payment.statement_date = raw?.statement_date;
  payment.premium = raw?.premium;
  payment.commission_type = raw?.commission_type;
  payment.percent = raw?.percent;
  payment.dollar = raw?.dollar;
  payment.net_retained = raw?.net_retained;
  payment.description = raw?.description;

  payment.insured = setPaymentInsuredFromRequest(raw?.insured);
  payment.case = setPaymentCaseFromRequest(raw?.case);
  payment.advisor = setPaymentAdvisorFromRequest(raw?.advisor);
  if (raw?.carrier) {
    payment.carrier = setPaymentCarrierFromRequest(raw?.carrier);
  }

  return payment;
}
