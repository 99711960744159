<template>
  <v-data-table
    :search="search"
    :loading="loading"
    :items="table.mappedItems.value"
    :headers="table.tableHeaders.value"
    data-testid="commission-table"
    class="transparent-data-table"
    item-key="key"
  />
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";

import { useTable } from "@/composables/table.composable";

import { defineProps, toRefs, watch } from "vue";
import { storeToRefs } from "pinia";
import { useCaseViewStore } from "@/stores/case-view";
import {
  currencyFormat,
  timestampFormatter,
  formatPercentage
} from "@/util/helpers";

const { commissions } = storeToRefs(useCaseViewStore());

const props = defineProps({
  search: {
    type: [Object, String],
    required: false,
    default: () => null
  },
  loading: Boolean
});

const { loading, search } = toRefs(props);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Advisor",
      value: "advisor",
      map: "payableName",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Assigned Advisor",
      value: "assignedAdvisorName",
      map: "assignedPayableName",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Payor",
      value: "payorName",
      map: "payorName",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Commission",
      value: "commissionType",
      map: "commissionType",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE,
      width: "130px"
    }),
    new TableHeader({
      text: "Percent",
      value: "percent",
      map: "percent",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE,
      width: "100px"
    }),
    new TableHeader({
      text: "Payment Type",
      value: "paymentType",
      map: "paymentType",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE,
      width: "130px"
    }),
    new TableHeader({
      text: "Years",
      value: "years",
      map: "years",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE,
      width: "100px"
    })
  ]
});

watch(loading, () => {
  table.loading.value = loading.value;
});

function setItems() {
  table.items.value.splice(0, table.items.value.length);
  commissions.value.forEach(item => {
    table.items.value.push({
      ...item,
      statementDate: timestampFormatter(item.statementDate, "sole-day"),
      premium: currencyFormat(item.premium, 2),
      commission: currencyFormat(item.premium, 2),
      percent: formatPercentage(item.percent, { decimalLength: 2 }),
      key: item.id
    });
  });
}

watch(commissions, setItems, { immediate: true, deep: true });
</script>
