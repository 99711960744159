<template>
  <v-text-field
    v-model="model"
    v-bind="$attrs"
    v-maska:[options]
    inputmode="numeric"
  >
    <template v-if="$slots.message" #message="{ message }">
      <v-row v-if="message" class="ma-0">
        {{ message }}
        <v-spacer />
        <slot name="message" />
      </v-row>
      <slot v-else name="message" />
    </template>
    <template v-if="$slots.append" #append>
      <slot name="append" />
    </template>
    <template v-if="$slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
    <template v-if="$slots['append-outer']" #append-outer>
      <slot name="append-outer" />
    </template>
  </v-text-field>
</template>

<script setup>
import { defineEmits, defineProps, watch, ref } from "vue";
import { vMaska } from "maska";

const props = defineProps({
  value: {
    type: [Number, String],
    required: false,
    default: null
  },
  allowExtension: Boolean
});

const options = {
  mask: "(###) ###-####"
};
if (props.allowExtension) {
  options.mask = "(###) ###-####x######";
}

const model = ref(props.value || null);

watch(
  () => props.value,
  () => {
    if (props.value === model.value) return;
    model.value = props.value;
  }
);

const emit = defineEmits(["input"]);
const update = v => emit("input", v);

watch(model, () => {
  if (model.value === props.value) return;
  update(model.value);
});
</script>
