<template>
  <v-card flat tile>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :server-items-length="meta.total"
        :loading="loading"
        @update:options="updateOptions"
        must-sort
        :options="options"
        :footer-props="footerProps"
      >
        <template #top>
          <v-row class="ma-0">
            <span class="text-h5"> Height & Weight </span>
            <v-spacer />
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="getData"
                  v-on="on"
                  v-bind="attrs"
                  :disabled="loading"
                >
                  <v-icon> $mdi-refresh </v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
            <v-tooltip bottom v-if="isGroupFour">
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="createBuild"
                  v-on="on"
                  v-bind="attrs"
                  :disabled="loading"
                >
                  <v-icon> $mdi-plus-circle </v-icon>
                </v-btn>
              </template>
              <span>Create Build</span>
            </v-tooltip>
          </v-row>
          <generic-table-filter-header
            :value="filter"
            :headers="headers"
            :loading="loading"
            @update="updateFilter"
          />
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn icon color="accent" @click="editBuild(item)">
            <v-icon>$mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="error" @click="destroyBuild(item)">
            <v-icon>$mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";

import { ratingOptions } from "@/data/filter-options";
import TableHeader from "@/classes/data-table/TableHeader";
import Table from "@/classes/data-table/Table";
import { parseErrorMessage } from "@/util/helpers";
import { mapActions, mapState } from "pinia";
import { deleteBuild, getBuilds } from "@/api/builds.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useHead } from "@unhead/vue";
export default {
  setup() {
    useHead({ title: "Height & Weight" });
  },
  name: "UnderwritingBuilds",
  components: {
    GenericTableFilterHeader
  },
  mixins: [GenericTableMixin],
  data() {
    const user = useUserStore();
    const buildHeaders = [
      new TableHeader({
        text: "Build Chart",
        value: "build",
        map: "build_chart.name",
        ...TableHeader.IS_STRING_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        sortFilterMap: "build_charts.name"
      }),
      new TableHeader({
        text: "Inches",
        value: "inches",
        map: "inches",
        ...TableHeader.IS_STRING_FILTER_TYPE,
        ...TableHeader.IS_SORTABLE,
        ...TableHeader.IS_FILTERABLE,
        sortFilterMap: "builds.inches"
      }),
      new TableHeader({
        text: "Rating",
        value: "rating",
        map: "rating",
        ...TableHeader.IS_SELECT_FILTER_TYPE,
        ...TableHeader.IS_SORTABLE,
        ...TableHeader.IS_FILTERABLE,
        selectableOptions: ratingOptions,
        sortFilterMap: "builds.rating"
      }),
      new TableHeader({
        text: "Gender",
        value: "gender",
        map: "build_chart.gender",
        ...TableHeader.IS_SELECT_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        selectableOptions: ["Male", "Female"],
        sortFilterMap: "build_charts.gender"
      }),
      new TableHeader({
        text: "Min Weight",
        value: "min",
        map: "min_weight",
        ...TableHeader.IS_STRING_FILTER_TYPE
      }),
      new TableHeader({
        text: "Max Weight",
        value: "max",
        map: "max_weight",
        ...TableHeader.IS_STRING_FILTER_TYPE
      }),
      user.isGroupFour
        ? new TableHeader({
            text: "Actions",
            value: "actions",
            map: "actions"
          })
        : null
    ].filter(Boolean);
    const build = new Table({
      text: "Builds",
      method: getBuilds,
      headers: buildHeaders,
      key: "builds"
    });
    return {
      tabs: [build],
      activeTab: 0,
      activeMetaTab: 0
    };
  },
  computed: {
    ...mapState(useUserStore, ["isGroupFour"]),
    footerProps() {
      return {
        pageText: this.pageTextFormatter(
          this.tabs[this.activeTab].options,
          this.tabs[this.activeTab].meta
        ),
        itemsPerPageOptions: [10, 20, 35, 50]
      };
    },
    headers() {
      return this.tabs[this.activeTab].headers;
    },
    loading() {
      return this.tabs[this.activeTab].requests > 0;
    },
    items() {
      return this.tableMap(
        this.tabs[this.activeTab].rawItems,
        this.tabs[this.activeTab].headers
      );
    },
    meta() {
      return this.tabs[this.activeTab].meta;
    },
    sort: {
      get() {
        return this.tabs[this.activeTab].sort;
      },
      set(value) {
        this.tabs[this.activeTab].sort = value;
      }
    },
    options: {
      get() {
        return this.tabs[this.activeTab].options;
      },
      set(value) {
        this.tabs[this.activeTab].options = value;
      }
    },
    filter: {
      get() {
        return this.tabs[this.activeTab].filter;
      },
      set(filter) {
        this.tabs[this.activeTab].filter = filter;
      }
    }
  },
  created() {
    this.getData();
  },

  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    destroyBuild(item) {
      this.showDialog({
        component: "DeleteDialog",
        title: `Are you sure you want to permanently delete this Build?`,
        subtitle: "This cannot be undone",
        func: () => deleteBuild(item.additional.id)
      }).then(res => {
        if (!res?.delete) return;
        this.getData();
      });
    },
    createBuild() {
      this.showDialog({
        component: "AddBuildChartDialog",
        persistent: true,
        scrollable: true
      }).then(res => {
        if (!res?.created) return;
        this.getData();
      });
    },
    editBuild(build) {
      this.showDialog({
        component: "AddBuildChartDialog",
        persistent: true,
        build: build.additional
      }).then(res => {
        if (!res?.created) return;
        this.getData();
      });
    },
    changeTab(value) {
      this.activeTab = value;
    },
    getData() {
      this.tabs[this.activeTab].requests++;
      this.tabs[this.activeTab]
        .getData()
        .then(result => {
          if (!result?.data) return;
          this.tabs[this.activeTab].rawItems =
            result.data[this.tabs[this.activeTab].key];
          this.tabs[this.activeTab].meta = result.data.meta;
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        })
        .finally(() => {
          this.tabs[this.activeTab].requests--;
        });
    },
    updateOptions(options) {
      if (this.optionsEquivalence(options, this.options)) {
        return;
      }
      this.options = options;
      this.getData();
    },
    updateFilter(filter) {
      this.activeMetaTab = 0;
      this.tabs[this.activeTab].resetPage();
      this.filter = filter;
      this.getData();
    },
    clearFilter(filter) {
      this.filter[filter] = undefined;
      this.getData();
    }
  }
};
</script>
