<template>
  <v-data-table
    class="transparent-data-table"
    data-testid="advisor-profile-payments-table"
    :loading="table.loading.value"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :disable-pagination="true"
    :hide-default-footer="true"
  >
    <template #footer>
      <v-row class="ma-0 pa-3">
        <v-spacer />
        <v-btn
          color="primary"
          class="text-none"
          data-testid="advisor-profile-payments-table-view-all"
          text
          @click="viewAll"
        >
          <v-icon class="mr-1"> {{ mdiOpenInApp }} </v-icon> View {{ name }}'s
          Payments
        </v-btn>
      </v-row>
    </template>
  </v-data-table>
</template>

<script setup>
import { mdiOpenInApp } from "@mdi/js";
import TableHeader from "@/classes/data-table/TableHeader";

import {
  currencyFormat,
  parseErrorMessage,
  timestampFormatter
} from "@/util/helpers";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { storeToRefs } from "pinia";
import { useTableStore } from "@/stores/table";
import { useTable } from "@/composables/table.composable";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { useRouter } from "@/composables/compatible.composables";

const advisorView = useAdvisorProfileView();
const tableStore = useTableStore();
const snackbar = useSnackbarStore();
const user = useUserStore();
const router = useRouter();
const { name, payments, tableFilterData } = storeToRefs(advisorView);
const table = useTable({
  headers: [
    new TableHeader({
      text: "Insured",
      value: "insuredName",
      map: "insuredName"
    }),
    new TableHeader({
      text: "Payor",
      value: "payor",
      map: "payorName"
    }),
    new TableHeader({ text: "Premium", value: "premium", map: "premium" }),
    new TableHeader({
      text: "Commission",
      value: "dollar",
      map: "dollar"
    }),
    new TableHeader({
      text: "Initiated",
      value: "createdDate",
      map: "createdDate"
    })
  ],

  getData: async () => {
    await advisorView.getRecentPayments();
    return {
      items: payments.value.map(item => ({
        ...item,
        premium: currencyFormat(item.premium, 0),
        dollar: currencyFormat(item.dollar, 0),
        createdDate: timestampFormatter(item.createdDate, "sole-day")
      }))
    };
  }
});

function viewAll() {
  const paymentFilter = {
    paidTo: tableFilterData.value
  };
  if (user.isGroupFour) {
    paymentFilter.view = "all";
  }

  tableStore.paymentsTable.filter = paymentFilter;
  router.push({
    name: "Commissions",
    query: { page: "payments" }
  });
}

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getData();
</script>
