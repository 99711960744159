<template>
  <!-- TODO: (Vue 3) change to :model-value and @update:model-value  -->
  <v-snackbar
    id="snackbar"
    closable
    data-testid="snackbar"
    :bottom="$vuetify.breakpoint.mdAndUp"
    :value="show"
    :timeout="timeout"
    :color="color"
    @input="handleInput"
  >
    {{ message }}
    <template #action>
      <v-btn
        color="white"
        icon
        data-testid="snackbar-dismiss"
        @click="snackbar.hideSnackbar()"
      >
        <v-icon> $mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";

const snackbar = useSnackbarStore();
const { message, timeout, show, color } = storeToRefs(snackbar);

function handleInput(v) {
  if (v) return;
  snackbar.hideSnackbar();
}
</script>
