<template>
  <v-data-table
    class="transparent-data-table"
    data-testid="advisor-profile-contract-parties-table"
    :loading="table.loading.value"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :disable-pagination="true"
    :hide-default-footer="true"
  >
    <template #[`item.name`]="{ item }">
      <router-link :to="item.additional.routerLink" class="text-none">
        {{ item.name }}
      </router-link>
    </template>

    <template #footer>
      <v-row class="ma-0 pa-3">
        <v-spacer />
        <v-btn
          color="primary"
          class="text-none"
          text
          data-testid="advisor-profile-contract-parties-table-view-all"
          @click="viewAll"
        >
          <v-icon class="mr-1"> {{ mdiOpenInApp }} </v-icon> View {{ name }}'s
          Clients
        </v-btn>
      </v-row>
    </template>
  </v-data-table>
</template>

<script setup>
import { mdiOpenInApp } from "@mdi/js";

import TableHeader from "@/classes/data-table/TableHeader";

import { parseErrorMessage, timestampFormatter } from "@/util/helpers";

import { useSnackbarStore } from "@/stores/snackbar";
import { storeToRefs } from "pinia";
import { useTableStore } from "@/stores/table";
import { useTable } from "@/composables/table.composable";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { useRouter } from "@/composables/compatible.composables";

const router = useRouter();
const snackbar = useSnackbarStore();
const advisorView = useAdvisorProfileView();
const tableStore = useTableStore();

const { contractParties, tableFilterData, name } = storeToRefs(advisorView);

const table = useTable({
  headers: [
    new TableHeader({ text: "Name", value: "name", map: "name" }),
    new TableHeader({ text: "Email", value: "email", map: "email" }),
    new TableHeader({
      text: "Phone",
      value: "primaryPhone",
      map: "primaryPhone"
    }),
    new TableHeader({
      text: "Birthdate",
      value: "birthdate",
      map: "birthdate"
    })
  ],
  getData: async () => {
    await advisorView.getRecentContractParties();
    return {
      items: contractParties.value.map(item => ({
        ...item,
        birthdate: timestampFormatter(item.birthdate, "sole-day")
      }))
    };
  }
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

function viewAll() {
  tableStore.contractPartiesTable.filter = {
    additionalAdvisor: tableFilterData.value
  };

  router.push({ name: "Tables", query: { page: "clients" } });
}

getData();
</script>
