<template>
  <v-card>
    <v-card-title> Add Payment Method </v-card-title>
    <v-card-subtitle>
      Follow these instructions and press "Create" to add a new Payment Method.
    </v-card-subtitle>
    <v-card-text id="eft-container">
      <v-row>
        <v-col cols="12">
          <template v-if="isAgency">
            Please upload a voided <strong>business check</strong> and complete
            the fields below. The check should be in the agency's name (<strong>
              {{ store.name }} </strong
            >), not your personal name. Starter checks are NOT accepted. If you
            don't have a check, upload a letter from your bank which includes
            the agency's name, EIN, routing number, and account number.
          </template>
          <template v-else>
            Please upload a voided <strong>personal check</strong> and complete
            the fields below. The check should be in your name (<strong>{{
              store.name
            }}</strong
            >), not your agency. Starter checks are NOT accepted. If you don't
            have a check, upload a letter from your bank which includes your
            name, social security number, routing number, and account number.
          </template>
        </v-col>
        <v-col cols="12">
          <file-drag-and-drop
            label="Payment Method Upload"
            data-testid="payment-method-file"
            v-model="eft.file"
            :success="fileValidation.success"
            :error-messages="fileValidation.errorMessages"
            :disabled="loading"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="eft.nameOnCheck"
            data-testid="payment-method-name"
            data-lpignore="true"
            :success="nameOnCheckValidation.success"
            :error-messages="nameOnCheckValidation.errorMessages"
            outlined
            dense
            label="Name On Check"
            prepend-inner-icon="$mdi-account"
            :disabled="loading"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="eft.accountType"
            data-testid="payment-method-type"
            :items="ACCOUNT_TYPE_OPTIONS"
            :success="accountTypeValidation.success"
            :error-messages="accountTypeValidation.errorMessages"
            outlined
            dense
            placeholder="Select Account Type"
            prepend-inner-icon="$mdi-cube"
            :disabled="loading"
          />
        </v-col>
        <v-col cols="12" md="6">
          <integer-input
            v-model="eft.routingNumber"
            data-testid="payment-method-routing"
            data-lpignore="true"
            mask="#########"
            :success="routingNumberValidation.success"
            :error-messages="routingNumberValidation.errorMessages"
            outlined
            dense
            label="Routing Number"
            prepend-inner-icon="$mdi-bank"
            :disabled="loading"
          />
        </v-col>
        <v-col cols="12" md="6">
          <integer-input
            v-model="eft.accountNumber"
            data-testid="payment-method-account"
            data-lpignore="true"
            :success="accountNumberValidation.success"
            :error-messages="accountNumberValidation.errorMessages"
            outlined
            dense
            label="Account Number"
            prepend-inner-icon="$mdi-bank"
            mask="#################"
            :disabled="loading"
            hint="Please include leading zeroes"
            persistent-hint
          />
        </v-col>
        <v-col cols="12" v-if="showWarning">
          <v-alert color="warning" class="white--text align-center">
            BackNine will use your new Payment Method for new carrier
            appointments. For existing appointments, please contact the
            insurance carriers directly.
          </v-alert>
        </v-col>
        <!-- Test routing/account combination is 122105278/0000000016 -->
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn outlined @click="dialog.closeDialog()" class="text-none">
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        data-testid="payment-method-create"
        :loading="loading"
        @click="createEft"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";

import { required } from "vuelidate/lib/validators";

import { parseErrorMessage, validationComputeV2 } from "@/util/helpers";

import { ACCOUNT_TYPE_OPTIONS, Eft } from "@/factories/EftFactory";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";

import { computed, defineProps, nextTick, ref } from "vue";
import { useSettingsViewStore } from "@/stores/settings-view";
import useVuelidate from "@vuelidate/core";
import { useVuetify } from "@/composables/compatible.composables";

const props = defineProps({
  hasEfts: Boolean,
  showWarning: Boolean,
  module: {
    default: AGENT_SETTINGS,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const loading = ref(false);

let store;
let isAgency = false;
if (props.module === AGENT_SETTINGS) store = useAgentSettingsStore();
else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
  isAgency = true;
}

const eft = ref(Eft());
eft.value.ownableId = store.id;
eft.value.ownableType = store.type;
eft.value.current = 1;

const rules = {
  eft: {
    file: {
      required,
      validSize: val => {
        return val?.size > 0;
      }
    },
    nameOnCheck: { required },
    accountType: { required },
    routingNumber: {
      required,
      validRoutingNumber: value => {
        if (!value) return false;
        const values = value.split("").map(Number);

        const total =
          3 * (values[0] + values[3] + values[6]) +
          7 * (values[1] + values[4] + values[7]) +
          (values[2] + values[5] + values[8]);

        return total % 10 === 0;
      }
    },
    accountNumber: { required }
  }
};

const state = { eft };

const v$ = useVuelidate(rules, state, { $autoDirty: true, $scope: false });

const fileValidation = computed(() => {
  const model = v$.value.eft.file;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    { key: "validSize", message: "Please Re-upload this file" }
  ]);
});
const nameOnCheckValidation = computed(() => {
  const model = v$.value.eft.nameOnCheck;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const accountTypeValidation = computed(() => {
  const model = v$.value.eft.accountType;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const accountNumberValidation = computed(() => {
  const model = v$.value.eft.accountNumber;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const routingNumberValidation = computed(() => {
  const model = v$.value.eft.routingNumber;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    { key: "validRoutingNumber", message: "Not a valid Routing Number" }
  ]);
});

const vuetify = useVuetify();
async function createEft() {
  const isValid = await v$.value.$validate();
  if (!isValid) {
    nextTick(() => {
      const el = document.getElementsByClassName("v-input error--text")[0];
      vuetify.goTo(el, { container: "#eft-container" });
      snackbar.showErrorSnackbar({ message: "Invalid fields detected" });
    });
    return;
  }

  loading.value = true;
  try {
    await store.createEft(eft.value);
    snackbar.showSuccessSnackbar({ message: "Created Eft", timeout: 5000 });
    dialog.closeDialog();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
    loading.value = false;
  } finally {
    loading.value = false;
  }
}
</script>
