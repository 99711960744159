<template>
  <v-card v-if="showActions" tile flat>
    <v-row class="ma-0 pa-3" justify="end">
      <v-btn
        v-if="showCaseLink"
        outlined
        data-testid="action-view-case"
        class="text-none"
        color="orange"
        rounded
        :to="getRouterLink('Case', quoteCase)"
      >
        <v-icon class="mr-1">$mdi-briefcase</v-icon> View Case
      </v-btn>
      <v-btn
        v-if="showEappLink"
        outlined
        data-testid="action-view-eapp"
        class="text-none"
        color="accent"
        rounded
        :to="{ name: 'ElectronicApplicationView', params: { id: quoteEapp } }"
      >
        <v-icon class="mr-1">$mdi-rocket-launch</v-icon> View eApp
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
import { getRouterLink } from "@/util/helpers";
import { mapState } from "pinia";
import { useQuoteViewStore } from "@/stores/quote-view";
export default {
  data: () => ({ getRouterLink }),
  watch: {
    showActions() {
      this.$emit("update:show", this.showActions);
    }
  },
  created() {
    this.$emit("update:show", this.showActions);
  },
  computed: {
    ...mapState(useQuoteViewStore, {
      quoteEapp: "eapp",
      quoteCase: "case"
    }),
    showActions() {
      return [this.showCaseLink, this.showEappLink].includes(true);
    },
    showEappLink() {
      return Boolean(this.quoteEapp);
    },
    showCaseLink() {
      return Boolean(this.quoteCase);
    }
  }
};
</script>
