export default function BuildChartFactory(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || "",

    setFromRequest(model = {}) {
      this.id = model?.id;
      this.name = model?.name;
    }
  };
}
