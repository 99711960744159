<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-data-table
        data-testid="parties-table"
        class="transparent-data-table"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        :items="parties"
        :headers="table.headers"
        show-expand
      >
        <template #[`item.birthdate`]="{ item }">
          <timestamp-formatter :value="item.birthdate" parser="sole-day" />
        </template>
        <template #[`item.role`]="{ item }">
          <role-formatter :value="item" />
        </template>
        <template #[`item.phone`]="{ item }">
          <a
            :href="`tel:${item.phone}`"
            :data-outbound-type="item.type"
            :data-outbound-id="item.id"
            :data-outbound-number="item.phone"
            >{{ item.phone }}</a
          >
        </template>
        <template #[`item.email`]="{ item }">
          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="py-4">
            <electronic-application-party
              :id="item.id"
              :type="item.type"
              :key="`${item.type}-${item.id}`"
            />
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import RoleFormatter from "@/components/shared/formatters/RoleFormatter.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import Table from "@/classes/data-table/Table";
import ElectronicApplicationParty from "@/components/electronic-applications/ElectronicApplicationParty.vue";
import { mapState } from "pinia";
import { useEappViewStore } from "@/stores/eapp-view";

export default {
  components: { RoleFormatter, TimestampFormatter, ElectronicApplicationParty },
  data() {
    const headers = [
      new TableHeader({
        text: "Name",
        value: "name",
        map: "name"
      }),
      new TableHeader({
        text: "Role",
        value: "role",
        map: "role"
      }),
      new TableHeader({
        text: "Birthdate",
        value: "birthdate",
        map: "birthdate"
      }),
      new TableHeader({
        text: "Email",
        value: "email",
        map: "email"
      }),
      new TableHeader({
        text: "Phone",
        value: "phone",
        map: "phone"
      }),
      new TableHeader({
        text: "Actions",
        value: "data-table-expand",
        width: "125px"
      })
    ];
    const table = new Table({
      headers
    });

    return {
      table
    };
  },
  computed: {
    ...mapState(useEappViewStore, ["parties"])
  }
};
</script>
