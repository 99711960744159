export function CaseCommissionFactory(model) {
  return {
    assignedPayableName: model?.assignedPayableName,
    commissionType: model?.commissionType,
    endYear: model?.endYear,
    payableName: model?.payableName,
    paymentType: model?.paymentType,
    payorName: model?.payorName,
    percent: model?.percent,
    startYear: model?.startYear,
    get years() {
      return `${this.startYear} - ${this.endYear}`;
    },
    setFromRequest(rawModel = {}) {
      this.assignedPayableName = rawModel?.assigned_payable?.name;
      this.commissionType = rawModel?.commission_type;
      this.endYear = rawModel?.end_year;
      this.payableName = rawModel?.payable?.name;
      this.paymentType = rawModel?.payment_type;
      this.payorName = rawModel?.payor?.name;
      this.percent = rawModel?.percent;
      this.startYear = rawModel?.start_year;
    }
  };
}
