<template>
  <v-card>
    <v-card-title> {{ props.title }} </v-card-title>
    <v-card-text class="px-2">
      <to-do-item-view :id="props.id" />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="text-none" outlined @click="dialog.closeDialog()">
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { defineProps } from "vue";
import ToDoItemView from "@/components/todo/ToDoItemView.vue";

import { useDialogStore } from "@/stores/dialog";
const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  title: {
    type: String,
    required: true
  }
});

const dialog = useDialogStore();
</script>
