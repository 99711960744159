import { defineStore } from "pinia";
import { useUserStore } from "@/stores/user";
import { useInstanceStore } from "@/stores/instance";

export const useZendeskStore = defineStore("zendesk", {
  state: () => ({
    loaded: false,
    loading: false,
    isVisible: true,
    settings: {
      errorReporting: false,
      webWidget: {
        color: {
          theme: "#1d7f34",
          launcher: "#1d7f34",
          launcherText: "#FFFFFF",
          button: "#1d7f34",
          resultLists: "#691840",
          header: "#1d7f34",
          articleLinks: "#FF4500"
        },
        offset: {
          horizontal: 0,
          vertical: 0,
          mobile: {
            vertical: "60px",
            horizontal: 0
          }
        }
      }
    }
  }),
  actions: {
    loadZendesk() {
      const user = useUserStore();
      const instance = useInstanceStore();
      if (!user.isGroupOne) return;

      if (this.loading || this.loaded) return;
      this.loading = true;
      const key = "2e1fb9a7-8bfd-4d7a-864c-9c714273db59";
      const url = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
      const script = document.createElement("script");
      script.src = url;
      script.type = "text/javascript";
      script.async = true;
      script.id = "ze-snippet";
      script.nonce = instance.nonce;
      window.zESettings = this.settings;

      script.addEventListener("load", () => {
        this.loaded = true;
        this.loading = false;

        if (!window.zE) return;

        window.zE("webWidget", "identify", {
          name: user.loginable.name,
          email: user.loginable.email
        });

        if (user.loginable.marketing_manager) {
          window.zE("webWidget", "updateSettings", {
            webWidget: {
              contactForm: {
                tags: [
                  user.loginable.marketing_manager.name
                    .replace(/[+]/, "plus")
                    .replace(/[^0-9A-Za-z ]/g, "")
                    .replace(/ /g, "_")
                ]
              }
            }
          });
        }
        window.zE("webWidget", "prefill", {
          name: {
            value: user.loginable.name,
            readOnly: true
          },
          email: {
            value: user.loginable.email,
            readOnly: true
          }
        });

        if (this.isVisible) this.show();
        else this.hide();
      });

      script.addEventListener("error", () => {
        this.loading = false;
        this.loaded = true;
      });

      document.head.appendChild(script);
    },
    changeVisibility(isVisible) {
      const user = useUserStore();
      if (!user.isGroupOne) return;
      this.isVisible = isVisible;

      if (window.zE) window.zE("webWidget", isVisible ? "show" : "hide");
    },
    show() {
      this.changeVisibility(true);
    },
    hide() {
      this.changeVisibility(false);
    },
    updateOffset(vertical, horizontal) {
      const user = useUserStore();
      if (!user.isGroupOne) return;
      this.settings.webWidget.offset.vertical = vertical;
      this.settings.webWidget.offset.horizontal = horizontal;

      if (window.zE)
        window.zE("webWidget", "updateSettings", this.settings.webWidget);
    },
    useMobileOffset() {
      this.updateOffset("60px", "0");
    },
    useDesktopOffset() {
      this.updateOffset("0", "0");
    }
  }
});
