import { getHttpClient } from "@/http-client";
import { setUserTableItemFromRaw, setUserFromRaw } from "@/factories/User";

const baseUrl = "api/boss/users";

export async function getUserDetails(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}/details`);

  return setUserFromRaw(data.user);
}

export function resetUserOTP(id) {
  return getHttpClient().post(`${baseUrl}/${id}/reset-otp`);
}

export function lockUser(id) {
  return getHttpClient().post(`${baseUrl}/${id}/lock`);
}

export function unlockUser(id) {
  return getHttpClient().post(`${baseUrl}/${id}/unlock`);
}

export async function getUsers(urlParams, cancelToken) {
  let params = urlParams;
  if (!params) params = new URLSearchParams();
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const { data } = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  const items = data.users.map(setUserTableItemFromRaw);

  return { items, meta: data.meta };
}

export function createUser(params) {
  return getHttpClient().post(`${baseUrl}`, params);
}

export function updateUser(id, user) {
  return getHttpClient().put(`${baseUrl}/${id}`, { user });
}
export function updateUserDetails(id, user) {
  return getHttpClient().put(`${baseUrl}/${id}/details`, { user });
}
export function sendPasswordReset(id) {
  return getHttpClient().post(`${baseUrl}/${id}/password-reset`);
}
export function resetUserTutorials(id) {
  return getHttpClient().put(`${baseUrl}/${id}`, {
    user: { tutorials: { home: false } }
  });
}

export async function checkUserEmail(email) {
  const { data } = await getHttpClient().post(`${baseUrl}/check-email`, {
    email
  });

  return { provider: data.provider, method: data.method };
}
