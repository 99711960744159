<template>
  <v-card>
    <v-card-title> {{ title }} </v-card-title>
    <v-card-subtitle>
      Vendors are currently utilized when creating new requirements,
      specifically exams and physician statements.
    </v-card-subtitle>
    <v-card-text>
      <v-col cols="12" class="pa-0">
        <v-text-field
          v-model="vendor.name"
          data-testid="vendor-name"
          data-lpignore="true"
          outlined
          dense
          label="Name"
          prepend-inner-icon="$mdi-domain"
          :success="nameValidation.success"
          :error-messages="nameValidation.errorMessages"
        />
        <v-select
          v-model="vendor.type"
          data-testid="vendor-type"
          prepend-inner-icon="$mdi-cube"
          outlined
          dense
          label="Type"
          :items="VENDOR_TYPES"
          :success="typeValidation.success"
          :error-messages="typeValidation.errorMessages"
        />
        <basic-email-input
          v-model="vendor.email"
          data-testid="vendor-email"
          data-lpignore="true"
          outlined
          dense
          label="E-Mail"
          prepend-inner-icon="$mdi-email"
          :success="Boolean(emailValidation.success && vendor.email)"
          :error-messages="emailValidation.errorMessages"
          @valid="validEmail = $event"
        />
        <v-row>
          <v-col cols="12" sm="6">
            <div class="checkbox-width">
              <v-checkbox
                v-model="vendor.default"
                data-testid="vendor-default"
                label="Default"
                :success="Boolean(vendor.default)"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="checkbox-width">
              <v-checkbox
                v-model="vendor.integration"
                data-testid="vendor-integration"
                label="Integration"
                :success="Boolean(vendor.integration)"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="checkbox-width">
              <v-checkbox
                v-model="vendor.available"
                data-testid="vendor-available"
                label="Available"
                :success="Boolean(vendor.available)"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="checkbox-width">
              <v-checkbox
                v-model="vendor.testing"
                data-testid="vendor-testing"
                label="Testing"
                :success="Boolean(vendor.testing)"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="text-none" text @click="dialog.closeDialog()">Cancel</v-btn>
      <v-btn
        class="text-none"
        color="primary"
        data-testid="vendor-save"
        :loading="saving"
        @click="save"
      >
        {{ vendor.id ? "Create" : "Save" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";
import { Vendor, VENDOR_TYPES } from "@/factories/VendorFactory";
import { saveVendor, createVendor } from "@/api/vendor.service";
import { parseErrorMessage, validationComputeV2 } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { ref, defineProps, computed } from "vue";
import useVuelidate from "@vuelidate/core";

const props = defineProps({ value: { type: Object } });

const vendor = ref(Vendor(props.value));
const saving = ref(false);
const validEmail = ref(false);

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const v$ = useVuelidate(
  {
    vendor: {
      name: {
        required: Boolean
      },
      type: {
        required: Boolean
      },
      email: {
        optional: (v, vm, component) => {
          return !v || component._setupProxy.validEmail;
        }
      }
    }
  },
  {
    vendor
  },
  {
    $autoDirty: true,
    $scope: false
  }
);

let title = "Create a new Vendor";
if (vendor.value.id) {
  title = `Edit ${vendor.value.name}`;
}

const nameValidation = computed(() => {
  const model = v$.value.vendor.name;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

const typeValidation = computed(() => {
  const model = v$.value.vendor.type;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

const emailValidation = computed(() => {
  const model = v$.value.vendor.email;
  return validationComputeV2(model, [
    { key: "optional", message: "An email must be valid if provided" }
  ]);
});

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  saving.value = true;

  try {
    if (vendor.value.id) await saveVendor(vendor.value);
    else await createVendor(vendor.value);

    let message = `Successfully created ${vendor.value.name}`;
    if (vendor.value.id) {
      message = `Successfully updated ${vendor.value.name}`;
    }
    snackbar.showSuccessSnackbar({ message });
    dialog.closeDialog({ saved: true });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}
</script>
