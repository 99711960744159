import { RateBand } from "@/factories/ProductFactory";
import { getHttpClient } from "@/http-client";

const baseURL = "/api/boss/rate-bands";

export const getRateBands = async () => {
  const { data } = await getHttpClient().get(baseURL);

  const rateBands = [];
  data.forEach(rawBand => {
    const rateBand = new RateBand();
    rateBand.setFromRequest(rawBand);
    rateBands.push(rateBand);
  });

  return rateBands;
};

export const createRateBand = async rateBand => {
  const { data } = await getHttpClient().post(baseURL, {
    min: rateBand.min,
    max: rateBand.max
  });
  const newRateBand = new RateBand();
  newRateBand.setFromRequest(data);
  return newRateBand;
};

export const updateRateBand = async rateBand => {
  const { data } = await getHttpClient().put(`${baseURL}/${rateBand.id}`, {
    min: rateBand.min,
    max: rateBand.max
  });
  const updatedRateBand = new RateBand();
  updatedRateBand.setFromRequest(data);
  return updatedRateBand;
};

export const deleteRateBand = id => {
  return getHttpClient().delete(`${baseURL}/${id}`);
};
