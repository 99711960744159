<template>
  <v-card>
    <v-card-title>Create a Task</v-card-title>
    <v-card-text>
      <v-row class="my-0">
        <v-col cols="12" :md="showDescriptionColumn ? 6 : 12">
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                v-model="type"
                label="Type"
                outlined
                dense
                success
                data-testid="create-task-type"
                :prepend-inner-icon="mdiCube"
                :items="taskTypes"
              />
            </v-col>
            <v-col v-if="showVendor" cols="12" md="6">
              <v-autocomplete
                v-model="task.vendor"
                item-text="name"
                label="Fulfillment Vendor"
                :prepend-inner-icon="mdiBookOpenPageVariant"
                data-testid="create-task-vendor"
                outlined
                dense
                return-object
                :items="vendors"
                :loading="loadingVendors"
                :success="vendorValidation.success"
                :error-messages="vendorValidation.errorMessages"
              />
            </v-col>
            <v-col v-if="showDoctor" cols="12" md="6">
              <v-text-field
                data-lpignore="true"
                v-model="task.doctor"
                label="Doctor(s)"
                dense
                outlined
                data-testid="create-task-doctor"
                :prepend-inner-icon="mdiDoctor"
                :success="doctorValidation.success"
                :error-messages="doctorValidation.errorMessages"
              />
            </v-col>
            <v-col v-if="showDoctorPhone" cols="12">
              <phone-input
                data-lpignore="true"
                v-model="task.doctorPhone"
                label="Doctor Phone"
                outlined
                dense
                data-testid="create-task-doctor-phone"
                :prepend-inner-icon="mdiPhone"
                :success="doctorPhoneValidation.success"
                :error-messages="doctorPhoneValidation.errorMessages"
              />
            </v-col>
            <v-col v-if="showDoctorAddress" cols="12">
              <basic-address-input
                autofill-label="Doctor Address"
                autofill-placeholder="Doctor Address"
                data-testid="create-task-doctor-address"
                :street-address-model.sync="task.doctorAddress.street_address"
                :state-model.sync="task.doctorAddress.state"
                :city-model.sync="task.doctorAddress.city"
                :zip-model.sync="task.doctorAddress.zip"
                :street-address-validation="streetAddressValidation"
                :state-validation="stateValidation"
                :city-validation="cityValidation"
                :zip-validation="zipValidation"
                @autofill="handleAutofill"
              />
            </v-col>
            <v-col v-if="showAssignee" cols="12" md="6">
              <v-autocomplete
                v-model="task.assignee"
                outlined
                dense
                label="Assigned To"
                item-text="name"
                return-object
                data-testid="create-task-assignee"
                :prepend-inner-icon="mdiAccount"
                :items="assigneeItems"
                :success="assigneeValidation.success"
                :error-messages="assigneeValidation.errorMessages"
              />
            </v-col>
            <v-col v-if="showContractParty" cols="12" md="6">
              <v-autocomplete
                v-model="task.contractParty"
                outlined
                dense
                item-text="name"
                return-object
                label="Contract Party"
                data-testid="create-task-contract-party"
                :prepend-inner-icon="mdiAccount"
                :items="contractPartyItems"
                :success="contractPartyValidation.success"
                :error-messages="contractPartyValidation.errorMessages"
              />
            </v-col>
            <v-col v-if="showOrderNumber" cols="12" md="6">
              <v-text-field
                data-lpignore="true"
                v-model="task.orderNumber"
                outlined
                dense
                label="Order Number"
                data-testid="create-task-order-number"
                :success="orderNumberValidation.success"
                :error-messages="orderNumberValidation.errorMessages"
                :prepend-inner-icon="mdiPound"
              />
            </v-col>
            <v-col v-if="showRelatedDocuments" cols="12">
              <file-drag-and-drop
                v-model="task.relatedDocuments"
                dense
                outlined
                :label="
                  isParameds
                    ? 'HIPAA Authorization(s)'
                    : 'Related Documents (optional)'
                "
                multiple
                data-testid="related-documents"
                :success="Boolean(task.relatedDocuments.length)"
                :error-messages="relatedDocumentsValidation.errorMessages"
              />
            </v-col>

            <!-- Checkboxes -->
            <v-col v-if="showOutstandingTask" cols="12" md="6">
              <div class="checkbox-width">
                <v-checkbox
                  v-model="task.outstanding"
                  label="Outstanding Task"
                  class="mt-1"
                  dense
                  hide-details
                  data-testid="create-task-outstanding"
                  :success="task.outstanding"
                />
              </div>
            </v-col>
            <v-col v-if="showSendEmail" cols="12" md="6">
              <div class="checkbox-width">
                <v-checkbox
                  v-model="task.sendEmail"
                  class="mt-1"
                  dense
                  label="Send E-mail"
                  hide-details
                  data-testid="create-task-send-email"
                  :success="task.sendEmail"
                />
              </div>
            </v-col>
            <v-col v-if="showSendHumanApi" cols="12" md="6">
              <div class="checkbox-width">
                <v-checkbox
                  v-model="task.sendHumanApi"
                  class="mt-1"
                  dense
                  label="Human API"
                  hide-details
                  data-testid="create-task-send-human-api"
                  :success="task.sendHumanApi"
                />
              </div>
            </v-col>
            <v-col v-if="showDeliveryRequirement" cols="12" md="6">
              <div class="checkbox-width">
                <v-checkbox
                  v-model="task.deliveryRequirement"
                  label="Delivery Requirement"
                  dense
                  class="mt-1"
                  hide-details
                  data-testid="create-task-delivery-requirement"
                  :success="task.deliveryRequirement"
                />
              </div>
            </v-col>
            <v-col v-if="showDueDate" cols="12" md="6">
              <date-input
                v-model="task.dueDate"
                outlined
                dense
                label="Follow Up Date"
                data-testid="create-task-due-date"
                :prepend-inner-icon="mdiCalendarClock"
                :success="dueDateValidation.success"
                :error-messages="dueDateValidation.errorMessages"
              />
            </v-col>
            <v-col v-if="showHidden" cols="12" md="6">
              <div class="checkbox-width">
                <v-checkbox
                  v-model="task.hidden"
                  label="Hidden"
                  dense
                  class="mt-1"
                  hide-details
                  data-testid="create-task-hidden"
                  :success="task.hidden"
                />
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="checkbox-width">
                <v-checkbox
                  v-model="task.copyDocumentsToCase"
                  label="Copy Documents to Case"
                  dense
                  class="mt-1"
                  hide-details
                  data-testid="create-task-copy-documents"
                  :success="task.copyDocumentsToCase"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="showDescriptionColumn" cols="12" md="6">
          <v-row dense>
            <v-col v-if="showTemplate" cols="12">
              <v-autocomplete
                v-model="task.template"
                label="Template"
                item-text="name"
                outlined
                dense
                return-object
                clearable
                data-testid="create-task-template"
                :prepend-inner-icon="mdiFileDocument"
                :success="templateValidation.success"
                :error-messages="templateValidation.errorMessages"
                :loading="loadingTemplates"
                :items="templates"
              />
            </v-col>
            <v-col v-if="showDescription" cols="12">
              <v-fade-transition mode="out-in">
                <v-progress-circular
                  v-if="loadingTemplate"
                  key="loading"
                  indeterminate
                />
                <template-editor
                  v-else-if="descriptionIsWysiwyg"
                  v-model="task.description"
                  class="wysiwyg mb-3"
                  data-testid="create-task-html-description"
                  :template-key="task?.template?.name"
                  :key="editorKey"
                  :success="descriptionValidation.success"
                  :error-messages="descriptionValidation.errorMessages"
                />
                <v-textarea
                  v-else
                  v-model="task.description"
                  outlined
                  dense
                  auto-grow
                  key="plaintext"
                  label="Description"
                  placeholder="What needs to be done?"
                  data-testid="create-task-text-description"
                  :prepend-inner-icon="mdiCardText"
                  :success="descriptionValidation.success"
                  :error-messages="descriptionValidation.errorMessages"
                />
              </v-fade-transition>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        color="grey"
        class="text-none"
        @click="dialog.closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        data-testid="create-task-action"
        :loading="loading"
        @click="createRequirement"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import DateInput from "@/components/shared/DateInput.vue";
import TemplateEditor from "@/components/shared/TemplateEditor.vue";
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";

import {
  NewExam,
  NewTask,
  NewPhysicianStatement,
  NewInstantApsToCreateRequest,
  NewTaskToCreateRequest,
  NewExamToCreateRequest,
  NewParamedsToCreateRequest,
  NewPhysicianStatementToCreateRequest
} from "@/factories/Task";

import {
  computedValidation,
  parseErrorMessage,
  someTextValidator
} from "@/util/helpers";
import {
  getCaseRequirementTemplate,
  getCaseRequirementTemplates
} from "@/api/cases.service";

import { getVendors } from "@/api/vendor.service";
import { createHumanApiTask, createTask } from "@/api/tasks.service";
import { createOrder } from "@/api/orders.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { computed, ref, watch, set, defineProps } from "vue";
import useVuelidate from "@vuelidate/core";

import {
  mdiCube,
  mdiBookOpenPageVariant,
  mdiDoctor,
  mdiPhone,
  mdiAccount,
  mdiPound,
  mdiCalendarClock,
  mdiFileDocument,
  mdiCardText
} from "@mdi/js";

const NONE_TEMPLATE = {
  id: "None",
  name: "None",
  templateContents: "",
  deliveryRequirement: false,
  email: true
};

const taskTypes = [
  { value: "Physician Statement", text: "APS" },
  { value: "Exam", text: "Exam" },
  { value: "Task", text: "Task" }
];

const props = defineProps({
  carrier: { type: Object, required: true },
  contractParties: { type: Array, required: true },
  room: { type: Array, required: true },
  timeline: { type: Array, required: true },
  assignOptions: { type: Array, required: true },
  caseId: { type: Number, required: true },
  insured: { type: Object, required: true },
  agent: { type: Object, required: true }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const carrierSupportsInstantAPS = props.carrier.supportsInstantAps;
const caseManager = props.assignOptions.find(a => a.case_manager);

const contractPartyItems = [...props.contractParties];
contractPartyItems.sort((a, b) => a.name.localeCompare(b.name));

const task = ref(NewTask({ caseId: props.caseId }));
const type = ref("Task");
const loading = ref(false);
const templates = ref([]);
const loadingTemplates = ref(false);
const vendors = ref([]);
const loadingVendors = ref(false);
const loadingTemplate = ref(false);

const assigneeItems = computed(() => {
  const items = [...props.assignOptions];

  if (task.value.vendor)
    items.push({
      id: task.value.vendor.id,
      type: "Vendor",
      name: task.value.vendor.name
    });

  items.sort((a, b) => a.name.localeCompare(b.name));
  return items;
});

const editorKey = computed(() => task.value.template?.id || "editor");
const isPhysicianStatement = computed(
  () => type.value === "Physician Statement"
);
const isTask = computed(() => type.value === "Task");
const isExam = computed(() => type.value === "Exam");
const isOrder = computed(() => isExam.value || isPhysicianStatement.value);
const isInstantAps = computed(
  () => task.value.template?.name === "Instant APS"
);
const isParameds = computed(() => task.value.vendor?.name === "Parameds.com");
const showVendor = computed(() => isPhysicianStatement.value || isExam.value);
const showDoctor = computed(
  () => isPhysicianStatement.value || isInstantAps.value
);
const showDoctorPhone = computed(
  () => isPhysicianStatement.value && isParameds.value
);
const showDoctorAddress = computed(
  () => isPhysicianStatement.value && isParameds.value
);
const showAssignee = computed(() => !isInstantAps.value);
const showContractParty = computed(() => isOrder.value || isInstantAps.value);
const showOrderNumber = computed(() => isOrder.value && !isParameds.value);
const showOutstandingTask = computed(() => !isInstantAps.value);
const showSendEmail = computed(() => !isInstantAps.value);
const showSendHumanApi = computed(
  () => isPhysicianStatement.value && carrierSupportsInstantAPS
);
const showDeliveryRequirement = computed(
  () => isTask.value && !isInstantAps.value
);
const showDueDate = computed(
  () => showDeliveryRequirement.value && task.value.deliveryRequirement
);
const showRelatedDocuments = computed(() => !isInstantAps.value);
const showHidden = computed(() => isTask.value);
const showTemplate = computed(() => isTask.value);
const showDescription = computed(
  () => !isInstantAps.value && !isPhysicianStatement.value
);

const descriptionIsWysiwyg = computed(
  () =>
    isTask.value &&
    task.value.template?.id !== "None" &&
    !task.value.template?.isTextOnly
);
const showDescriptionColumn = computed(
  () => showDescription.value || showTemplate.value
);

const v$ = useVuelidate(
  {
    task: {
      template: {
        required: v =>
          !showTemplate.value || templates.value.some(t => t.id === v?.id)
      },
      description: {
        required: v => !showDescription.value || someTextValidator(true, v, 1)
      },
      contractParty: {
        required: v => !showContractParty.value || Boolean(v)
      },
      orderNumber: {
        required: v => !showOrderNumber.value || someTextValidator(true, v, 1)
      },
      assignee: {
        required: v => !showAssignee.value || Boolean(v)
      },
      vendor: {
        required: v => !showVendor.value || Boolean(v)
      },
      deliveryRequirement: {
        required: true
      },
      dueDate: {
        required: val => !showDueDate.value || Boolean(val)
      },
      doctor: {
        required: v => !showDoctor.value || Boolean(v)
      },
      doctorPhone: {
        required: v => !showDoctorPhone.value || Boolean(v),
        validLength: val => {
          if (!showDoctorPhone.value) return true;
          if (!val) return false;
          return val.match(/\d/g).join("").length === 10;
        }
      },
      doctorAddress: {
        street_address: {
          required: v =>
            !showDoctorAddress.value || someTextValidator(true, v, 1)
        },
        city: {
          required: v =>
            !showDoctorAddress.value || someTextValidator(true, v, 1)
        },
        state: {
          required: v =>
            !showDoctorAddress.value || someTextValidator(true, v, 1)
        },
        zip: {
          required: v =>
            !showDoctorAddress.value || someTextValidator(true, v, 1)
        }
      },
      relatedDocuments: {
        required: v => !isParameds.value || Boolean(v?.length),
        validSize: v => v.every(f => f.size > 0)
      }
    }
  },
  {
    task
  },
  { $autoDirty: true, $scope: null }
);

const orderNumberValidation = computedValidation(v$.value.task.orderNumber, {
  required: "Required"
});
const contractPartyValidation = computedValidation(
  v$.value.task.contractParty,
  { required: "Required" }
);
const assigneeValidation = computedValidation(v$.value.task.assignee, {
  required: "Required"
});
const vendorValidation = computedValidation(v$.value.task.vendor, {
  required: "Required"
});
const doctorValidation = computedValidation(v$.value.task.doctor, {
  required: "Required"
});
const templateValidation = computedValidation(v$.value.task.template, {
  required: "Required"
});
const descriptionValidation = computedValidation(v$.value.task.description, {
  required: "Required"
});
const dueDateValidation = computedValidation(v$.value.task.dueDate, {
  required: "Required"
});
const doctorPhoneValidation = computedValidation(v$.value.task.doctorPhone, {
  required: "Required",
  validLength: "Phone numbers must be 10 digits long"
});
const streetAddressValidation = computedValidation(
  v$.value.task.doctorAddress.street_address,
  { required: "Required" }
);
const stateValidation = computedValidation(v$.value.task.doctorAddress.state, {
  required: "Required"
});
const cityValidation = computedValidation(v$.value.task.doctorAddress.city, {
  required: "Required"
});
const zipValidation = computedValidation(v$.value.task.doctorAddress.zip, {
  required: "Required"
});
const relatedDocumentsValidation = computedValidation(
  v$.value.task.relatedDocuments,
  {
    required: "Required",
    validSize:
      "Please confirm these files have data or try re-uploading the files"
  }
);

function handleAutofill({ street_address, state, city, zip }) {
  task.value.doctorAddress.street_address = street_address;
  task.value.doctorAddress.state = state;
  task.value.doctorAddress.city = city;
  task.value.doctorAddress.zip = zip;
}

async function fetchVendors(type) {
  vendors.value.splice(0, vendors.value.length);
  loadingVendors.value = true;
  try {
    vendors.value = await getVendors({
      carrier: props.carrier,
      type,
      available: true
    });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingVendors.value = false;
  }
}

function setTaskDefaults(val) {
  let newTask;
  const args = { ...task.value, sendEmail: true, vendor: null };
  if (val === "Exam") {
    args.contractParty = props.insured;
    args.assignee = caseManager;
    args.vendor = vendors.value.find(v => v.default);
    newTask = NewExam(args);
    fetchVendors("exam");
  } else if (val === "Physician Statement") {
    args.contractParty = props.insured;
    args.assignee = caseManager;
    args.sendHumanApi = carrierSupportsInstantAPS;
    args.vendor = vendors.value.find(v => v.default);
    newTask = NewPhysicianStatement(args);
    fetchVendors("physician_statement");
  } else {
    args.assignee = props.agent;
    args.template = NONE_TEMPLATE;
    newTask = NewTask(args);
  }

  set(task, "value", newTask);
}

function createRequirementRequest() {
  if (isInstantAps.value) {
    return createHumanApiTask(NewInstantApsToCreateRequest(task.value));
  }

  if (isTask.value) {
    return createTask(NewTaskToCreateRequest(task.value));
  }

  if (isExam.value) {
    return createOrder(NewExamToCreateRequest(task.value));
  }

  if (isParameds.value) {
    return createOrder(NewParamedsToCreateRequest(task.value));
  }

  if (isPhysicianStatement.value) {
    return createOrder(NewPhysicianStatementToCreateRequest(task.value));
  }

  throw new Error("Invalid task type");
}

async function createRequirement() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  try {
    loading.value = true;
    const task = await createRequirementRequest();
    dialog.closeDialog({ task });
  } catch (val) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(val),
      timeout: 10000
    });
  } finally {
    loading.value = false;
  }
}

async function getRequirementTemplatesForCase() {
  loadingTemplates.value = true;
  try {
    templates.value.push(...(await getCaseRequirementTemplates(props.caseId)));
    templates.value.push(NONE_TEMPLATE);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingTemplates.value = false;
  }
}

getRequirementTemplatesForCase();

watch(
  type,
  v => {
    setTaskDefaults(v);
    v$.value.$reset();
  },
  { immediate: true }
);

watch(
  () => task.value.template?.id,
  async v => {
    if (!v) return;
    if (isInstantAps.value) task.value.contractParty = props.insured;
    try {
      loadingTemplate.value = true;
      let template;
      if (v === "None") {
        template = { isTextOnly: true, templateContents: "" };
      } else {
        template = await getCaseRequirementTemplate(props.caseId, v);
      }
      task.value.template.isTextOnly = template.isTextOnly;
      task.value.description = template.templateContents;
      task.value.deliveryRequirement = Boolean(template.deliveryRequirement);
      task.value.sendEmail = Boolean(template.email);
    } catch (e) {
      snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    } finally {
      loadingTemplate.value = false;
    }
  }
);

watch(
  () => task.value.vendor,
  v => {
    if (!v || !showAssignee.value) return;
    task.value.assignee = {
      id: v.id,
      type: "Vendor",
      name: v.name
    };
  },
  { deep: true }
);

watch(
  () => task.value.deliveryRequirement,
  v => {
    if (!v) {
      task.value.dueDate = null;
      return;
    }

    const deliveryRequirementsDate = props.timeline.find(
      t => t.key === "delivery_requirements_due_date"
    );
    task.value.dueDate =
      deliveryRequirementsDate?.date || task.value.dueDate || null;
  }
);
</script>
