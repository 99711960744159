<template>
  <agent-search
    v-model="agent"
    prepend-inner-icon="$mdi-account-tie"
    label="Agent"
    outlined
    dense
    data-testid="agent-search"
    item-text="name"
    item-value="id"
    return-object
    :success="agentValidation.success"
    :error-messages="agentValidation.errorMessages"
  />
</template>

<script setup>
import AgentSearch from "@/components/shared/AgentSearch.vue";

import { computedValidation } from "@/util/helpers";
import { useQuoteRequestStore } from "@/stores/quote-request";
import { storeToRefs } from "pinia";
import useVuelidate from "@vuelidate/core";

const quoteRequest = useQuoteRequestStore();
const { agent } = storeToRefs(quoteRequest);

const v$ = useVuelidate(
  { agent: { required: v => Boolean(v) } },
  { agent },
  { $scope: "quote-request" }
);

const agentValidation = computedValidation(v$.value.agent, [
  { key: "required", message: "Required" }
]);
</script>
