const ADVISOR = "advisor";
const AGENT = "agent";
const APPLICATION_QUESTION = "application_question";
const CARRIER = "carrier";
const CONTRACT_PARTY = "contract_party";
const FORM = "form";
const MARKETING_MANAGER = "marketing_manager";
const PAYOR = "payor";
const POLICY = "policy";
const STATEMENT = "statement";

export {
  ADVISOR,
  AGENT,
  APPLICATION_QUESTION,
  CARRIER,
  CONTRACT_PARTY,
  FORM,
  MARKETING_MANAGER,
  PAYOR,
  POLICY,
  STATEMENT
};
