<template>
  <v-card flat>
    <v-card-title> Commissions </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" lg="6" v-if="!isWhiteglove && !isSignUpOrSelectAgent">
          <advisor-search
            v-model="appointmentAssignable"
            class="has-appended-button button-group-input"
            prepend-inner-icon="$mdi-calendar"
            outlined
            dense
            id="commissions-assigned-to"
            label="Commissions Assigned To"
            hint="Determines if the agent's individual code or assignment code will be used. If locked, the Commissions Assigned To won't change even when the agent changes who their commissions are paid to."
            persistent-hint
            data-testid="settings-appointment-assignable"
            :clearable="false"
            :success="Boolean(appointmentAssignable)"
            :disabled="ownable.lockCommissions"
            @input="saveAttribute('appointmentAssignable')"
          >
            <template #append>
              <v-btn
                icon
                style="z-index: 3"
                key="AppointmentAssignableLock"
                data-testid="settings-appointment-assignable-lock"
                :disabled="ownable.lockCommissions"
                @mousedown.stop.prevent="toggleAppointmentAssignableLock"
                @touchstart.stop.prevent="toggleAppointmentAssignableLock"
              >
                <v-icon v-if="appointmentAssignableLock">$mdi-lock</v-icon>
                <v-icon v-else>$mdi-lock-open-variant-outline</v-icon>
              </v-btn>
            </template>
            <template #append-outer>
              <active-save-indicator
                class="ml-2"
                :controller="savingBuffer.appointmentAssignable"
              />
              <active-save-indicator
                class="ml-2"
                :controller="savingBuffer.appointmentAssignableLock"
              />
            </template>
          </advisor-search>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { storeToRefs } from "pinia";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { useActiveSave } from "@/composables/active-save.composable";

const approvedDomain = useApprovedDomainView();
const {
  ownable,
  isWhiteglove,
  isSignUpOrSelectAgent,
  appointmentAssignable,
  appointmentAssignableLock
} = storeToRefs(approvedDomain);

const savingBuffer = {
  appointmentAssignable: useActiveSave(),
  appointmentAssignableLock: useActiveSave()
};

function toggleAppointmentAssignableLock() {
  appointmentAssignableLock.value = !appointmentAssignableLock.value;
  saveAttribute("appointmentAssignableLock");
}

function saveAttribute(savingBufferAttribute, attribute = null) {
  savingBuffer[savingBufferAttribute].update(() =>
    approvedDomain.updateApprovedDomain(attribute || savingBufferAttribute)
  );
}
</script>
