var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-3",attrs:{"elevation":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.series.sections),function(section,index){return _c('v-row',{key:index,staticClass:"ma-0",staticStyle:{"padding-top":"5rem","padding-bottom":"5rem"}},[_c('v-col',{attrs:{"cols":"12","lg":section.url ? 6 : 12}},[_c('v-row',{staticClass:"ma-0 height-full",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"align":"center"}},[_c('div',[_c('h2',{staticClass:"series-title",class:{ 'primary--text': section.primaryTitle },style:({
                    'font-size': _vm.$vuetify.breakpoint.smAndDown
                      ? '28px'
                      : '44px'
                  })},[_vm._v(" "+_vm._s(section.title)+" ")])]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('div',{staticClass:"series-subtitle",style:({
                      'font-size': _vm.$vuetify.breakpoint.smAndDown
                        ? '24px'
                        : '28px'
                    })},[_vm._v(" "+_vm._s(section.subtitle)+" ")]),(section.c2a)?_c('div',{staticClass:"series-c2a",staticStyle:{"margin-top":"20px"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"text-none",style:({
                        'font-size': _vm.$vuetify.breakpoint.smAndDown
                          ? '18px'
                          : '22px'
                      }),attrs:{"color":section.c2a.value.color,"block":"","x-large":""}},'v-btn',section.c2a.value.buttonProps,false),section.c2a.value.buttonListeners),[(section.c2a.value.icon)?_c('v-icon',[_vm._v(" "+_vm._s(section.c2a.value.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(section.c2a.value.title)+" ")],1)],1):_vm._e()])],1)],1)],1)],1),(section.url)?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('vimeo-video',{attrs:{"url":section.url,"id":`series-video-${index}`}})],1):_vm._e()],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }