export function Employee(model = {}) {
  return {
    anniversary: model?.anniversary,
    contractType: model?.contractType,
    department: model?.department,
    firstName: model?.firstName,
    id: model?.id,
    lastName: model?.lastName,
    status: model?.status,
    terminatedDate: model?.terminatedDate,
    title: model?.title,
    reviews: model?.reviews || [],
    managerName: model?.managerName
  };
}

export function setEmployeeFromRequest(rawModel = {}) {
  const employee = Employee();
  employee.anniversary = rawModel?.anniversary;
  employee.contractType = rawModel?.contract_type;
  employee.department = rawModel?.department;
  employee.firstName = rawModel?.first_name;
  employee.id = rawModel?.id;
  employee.lastName = rawModel?.last_name;
  employee.status = rawModel?.status;
  employee.terminatedDate = rawModel?.terminated_date;
  employee.title = rawModel?.title;
  employee.managerName = rawModel?.manager?.name;
  employee.maxCases = rawModel?.max_cases;
  employee.reviews = [];
  rawModel?.employee_reviews?.forEach(
    ({
      review,
      review_date,
      reviewer: {
        id,
        name,
        type: { model }
      }
    }) =>
      employee.reviews.push({
        review,
        reviewDate: review_date,
        reviewerId: id,
        reviewerType: model,
        reviewer: name
      })
  );

  return employee;
}

export function EmployeeReview(model = {}) {
  return {
    review: model?.review || null,
    reviewDate: model?.reviewDate || null,
    reviewerId: model?.reviewerId || null,
    reviewerType: model?.reviewerType || null,
    reviewer: model?.reviewer || null
  };
}

export function setEmployeeReviewFromRequest(raw = {}) {
  const review = EmployeeReview();
  review.review = raw.review;
  review.reviewDate = raw.review_date;
  review.reviewerId = raw.reviewer.id;
  review.reviewerType = raw.reviewer.type.model;
  review.reviewer = raw.reviewer.name;
  return review;
}

export function setRequestFromReview(review = EmployeeReview()) {
  return {
    review_date: review.reviewDate,
    review: review.review
  };
}

export function EmployeeTableItem(model = {}) {
  return {
    id: model?.id || null,
    accessible: model?.accessible || null,
    name: model?.name || null,
    email: model?.email || null,
    phone: model?.phone || null,
    extension: model?.extension || null,
    mobile: model?.mobile || null,
    birthdate: model?.birthdate || null,
    anniversary: model?.anniversary || null,
    schedulingLink: model?.schedulingLink || null,
    jobDescription: model?.jobDescription || null,
    department: model?.department || null,
    lastReviewDate: model?.lastReviewDate || null,
    managerName: model?.managerName || null,
    backnineCALicenseNumber: model?.backnineCALicense || null,
    caLicenseNumber: model?.caLicenseNumber || null,
    title: model?.title || null
  };
}

export function setEmployeeTableItemFromRequest(raw = {}) {
  const employee = EmployeeTableItem();
  employee.id = raw.id;
  employee.accessible = raw.accessible;
  employee.name = `${raw.first_name} ${raw.last_name}`;
  employee.email = raw.email;
  employee.phone = raw.phone_work;
  employee.extension = raw.extension;
  employee.mobile = raw.phone_mobile;
  employee.birthdate = raw.birthdate;
  employee.anniversary = raw.anniversary;
  employee.jobDescription = raw.job_description;
  employee.department = raw.department;
  employee.title = raw.title;
  if (raw.last_review_date) {
    employee.lastReviewDate = raw.last_review_date;
  }
  if (raw.scheduling_link?.length) {
    employee.schedulingLink = raw.scheduling_link[0].url;
  }
  if (raw.manager) {
    employee.managerName = raw.manager.name;
  }
  if (raw.backnine_ca_license) {
    employee.backnineCALicenseNumber = raw.backnine_ca_license.number;
  }
  if (raw.ca_license) {
    employee.caLicenseNumber = raw.ca_license.number;
  }

  return employee;
}
