<template>
  <chat
    title="Chat"
    :pusher-id="`private-${party.id}-${modelType}`"
    :messages="notes"
    ref="chat"
    flat
    @new-note="newNote"
    @remove-message="removeFromMessages"
    @send-message="sendChatMessage"
  />
</template>

<script>
import sortBy from "lodash/sortBy";

import Chat from "@/components/shared/chat/Chat.vue";

import NoteFactory from "@/factories/NoteFactory";
import { parseErrorMessage } from "@/util/helpers";
import { mapActions, mapState } from "pinia";
import { createNote } from "@/api/notes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  components: {
    Chat
  },
  props: {
    party: Object,
    type: String
  },
  data() {
    const user = useUserStore();
    const notes = sortBy(this.party.notes, "createdAt");
    notes.unshift({
      note: `Hi ${user.loginable.name}, feel free to make notes here.`,
      ownable: { name: "BackNine" }
    });
    return {
      notes
    };
  },
  computed: {
    ...mapState(useUserStore, ["loginable"]),
    modelType() {
      return {
        individual: "Individual",
        entity: "Entity"
      }[this.type];
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    removeFromMessages(message) {
      const index = this.parties.notes.findIndex(({ id }) => id === message.id);
      this.notes.splice(index, 1);
    },
    newNote({ note: rawNote }) {
      const note = new NoteFactory();
      note.setFromRequest(rawNote);
      this.notes.push(note);
    },
    async sendChatMessage(note) {
      this.$refs.chat.$refs.chatFooter.loading = true;
      const newNote = new NoteFactory({
        documents: note.files,
        email: note.sendEmail ? 1 : 0,
        phoneCall: Boolean(note.sendPhoneCall),
        note: note.message,
        notableId: this.party.id,
        notableType: this.modelType
      });
      try {
        await createNote(newNote.toCreateRequest());
        this.$refs.chat.$refs.chatFooter.reset();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
      } finally {
        this.$refs.chat.$refs.chatFooter.loading = false;
      }
    }
  }
};
</script>
