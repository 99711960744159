<template>
  <v-menu offset-y :top="text">
    <template #activator="{ on: menu, attrs }">
      <v-tooltip bottom :disabled="text">
        <template #activator="{ on: tooltip }">
          <v-btn
            :icon="!text"
            depressed
            text
            v-bind="[attrs]"
            v-on="{ ...menu, ...tooltip }"
            :style="width ? `width: ${width}` : null"
            :color="color || 'inherit'"
          >
            <v-icon>$mdi-playlist-plus</v-icon>
          </v-btn>
        </template>
        <span>Create Menu </span>
      </v-tooltip>
    </template>
    <v-list dense>
      <v-list-item
        v-for="object in models"
        :key="object.value"
        :to="{ name: object.value }"
      >
        <v-list-item-icon>
          <v-icon> {{ object.icon }} </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ object.text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import sortBy from "lodash/sortBy";
import { useUserStore } from "@/stores/user";
import { PERMISSION } from "@/constants/permissions.constants";

export default {
  props: {
    width: String,
    color: String,
    text: Boolean
  },
  data() {
    let models = [
      {
        text: "Advisor",
        icon: "$mdi-account",
        value: "AdvisorCreate"
      }
    ];

    const userStore = useUserStore();

    if (userStore.isGroupTwoPlus) {
      models.push(
        {
          text: "Appointment",
          icon: "$mdi-calendar",
          value: "AppointmentCreate"
        },
        {
          text: "Case",
          icon: "$mdi-briefcase-variant",
          value: "CaseCreate"
        },
        {
          text: "Informal Inquiry",
          icon: "$mdi-book-information-variant",
          value: "CaseCreateInformal"
        },
        {
          text: "Lead",
          icon: "$mdi-magnet-on",
          value: "LeadCreate"
        },
        {
          text: "Personnel",
          icon: "$mdi-account-circle",
          value: "PersonnelCreate"
        }
      );
    }

    if (userStore.isGroupFour || userStore.permissions[PERMISSION.ACCOUNTING]) {
      models.push({
        text: "Product",
        icon: "$mdi-barcode",
        value: "ProductCreate"
      });
    }

    if (userStore.permissions[PERMISSION.ACCOUNTING]) {
      models.push(
        {
          text: "Payment",
          icon: "$mdi-cash-register",
          value: "PaymentCreate"
        },
        {
          text: "Statement",
          icon: "$mdi-newspaper",
          value: "StatementCreate"
        },
        {
          text: "Transaction",
          icon: "$mdi-point-of-sale",
          value: "TransactionCreate"
        }
      );
    }

    if (userStore.loginable?.show_backnine) {
      models.push(
        {
          text: "Application Request",
          icon: "$mdi-file-document",
          value: "ApplicationRequest"
        },
        {
          text: "Quote Request",
          icon: "$mdi-clipboard-account",
          value: "CreateQuote"
        },
        {
          text: "Client",
          icon: "$mdi-human-greeting",
          value: "ContractPartyCreate"
        },
        {
          text: "Impaired Risk Quote",
          icon: "$mdi-chart-line",
          value: "ImpairedRiskQuoteCreate"
        },
        {
          text: "Submit Case",
          icon: "$mdi-briefcase",
          value: "CaseSubmission"
        }
      );
    }

    return {
      models: sortBy(models, "text")
    };
  }
};
</script>
