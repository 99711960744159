<template>
  <v-fade-transition mode="out-in">
    <v-row v-if="alreadyFilledOut" key="main-form" dense>
      <v-col cols="12">
        <v-text-field
          v-model="streetAddress"
          :autofocus="!streetAddress && autofocus"
          :prepend-inner-icon="mdiSignRealEstate"
          outlined
          dense
          label="Street Address"
          placeholder="Street Address"
          :data-testid="`${dataTestid}-street-address`"
          :success="streetAddressValidation.success"
          :error-messages="streetAddressValidation.errorMessages"
          :disabled="disabled"
          @input="$emit('update:street-address-model', streetAddress)"
        >
          <template #append-outer v-if="$slots['append-outer-street-address']">
            <slot name="append-outer-street-address" />
          </template>
        </v-text-field>
      </v-col>
      <v-col :md="colSize" cols="12">
        <v-text-field
          v-model="city"
          :prepend-inner-icon="mdiCity"
          outlined
          dense
          label="City"
          placeholder="City"
          :data-testid="`${dataTestid}-city`"
          :success="cityValidation.success"
          :error-messages="cityValidation.errorMessages"
          :disabled="disabled"
          @input="$emit('update:city-model', city)"
        >
          <template #append-outer v-if="$slots['append-outer-city']">
            <slot name="append-outer-city" />
          </template>
        </v-text-field>
      </v-col>
      <v-col :md="colSize" cols="12">
        <v-autocomplete
          v-model="state"
          :prepend-inner-icon="mdiHomeGroup"
          label="State"
          placeholder="State"
          outlined
          dense
          auto-select-first
          :data-testid="`${dataTestid}-state`"
          :input-validation="stateValidation"
          :disabled="disabled"
          :items="stateOptions"
          :success="stateValidation.success"
          :error-messages="stateValidation.errorMessages"
          @change="$emit('update:state-model', state)"
        >
          <template #append-outer v-if="$slots['append-outer-state']">
            <slot name="append-outer-state" />
          </template>
        </v-autocomplete>
      </v-col>
      <v-col :md="colSize" cols="12">
        <integer-input
          v-model="zip"
          input-type="integer"
          :prepend-inner-icon="mdiMapMarker"
          label="Zip"
          placeholder="Zip"
          mask="#####"
          outlined
          dense
          :data-testid="`${dataTestid}-zip`"
          :success="zipValidation.success"
          :error-messages="zipValidation.errorMessages"
          :disabled="disabled"
          :dirty="dirty"
          @input="$emit('update:zip-model', zip)"
        >
          <template #append-outer v-if="$slots['append-outer-zip']">
            <slot name="append-outer-zip" />
          </template>
        </integer-input>
      </v-col>
    </v-row>
    <v-row v-else key="autocomplete-form" dense>
      <v-col cols="12">
        <street-geo-input
          data-lpignore="true"
          :data-testid="`${dataTestid}-autofill`"
          persistent-hint
          hint=" "
          :label="autofillLabel"
          :autofocus="autofocus"
          :placeholder="autofillPlaceholder"
          :error-messages="streetAddressFormValidation.errorMessages"
          @input="autofillAddress"
          @switch-to-manual="alreadyFilledOut = true"
          @error="switchToManualEntry"
        >
          <template #message>
            <a
              data-testid="address-manual-entry"
              class="text-primary clickable"
              @click="switchToManualEntry"
            >
              Manual Entry
            </a>
          </template>
        </street-geo-input>
      </v-col>
    </v-row>
  </v-fade-transition>
</template>

<script>
import StreetGeoInput from "@/components/shared/StreetGeoInput.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";

import { states } from "@/data/states";
import {
  mdiCity,
  mdiHomeGroup,
  mdiMapMarker,
  mdiSignRealEstate
} from "@mdi/js";

const numberParam = (required = false) => ({
  type: [String, Number],
  default: null,
  required
});

const objectParam = (required = false) => ({
  type: Object,
  required,
  validator: () => true,
  default: () => ({ success: null, errorMessages: [] })
});

const stringParam = (required = false) => ({
  type: String,
  default: null,
  required,
  validator: () => true
});

export default {
  components: {
    StreetGeoInput,
    IntegerInput
  },
  props: {
    dense: Boolean,
    disabled: Boolean,
    autofocus: Boolean,
    dirty: Boolean,
    abbreviated: Boolean,
    noAutofill: Boolean,
    dataTestid: stringParam(false),

    autofillPlaceholder: stringParam(true),
    autofillLabel: stringParam(true),

    streetAddressModel: stringParam(false),
    streetAddressValidation: objectParam(false),

    cityModel: stringParam(false),
    cityValidation: objectParam(false),

    stateModel: stringParam(false),
    stateValidation: objectParam(false),

    zipModel: numberParam(false),
    zipValidation: objectParam(false)
  },
  emits: [
    "update:street-address-model",
    "update:city-model",
    "update:zip-model",
    "update:state-model",
    "autofill"
  ],
  data() {
    let stateOptions = states.map(val => {
      return { text: val.full, value: val.text };
    });
    if (this.abbreviated) {
      stateOptions = states.map(val => {
        return { text: val.text, value: val.text };
      });
    }
    return {
      alreadyFilledOut: false,
      isMounted: false,
      stateOptions,
      mdiCity,
      mdiHomeGroup,
      mdiMapMarker,
      mdiSignRealEstate,
      updateTimer: null,
      streetAddress: "",
      city: "",
      state: "",
      zip: ""
    };
  },
  computed: {
    colSize() {
      let size = 4;
      if (this.dense) size = 12;
      return size;
    },
    streetAddressFormValidation() {
      if (!this.dirty) return { success: true, errorMessages: [] };
      return this.streetAddressValidation;
    }
  },
  watch: {
    streetAddressModel() {
      // handle outside changes
      this.streetAddress = this.streetAddressModel;
      this.$nextTick(() => (this.alreadyFilledOut = true));
    },
    cityModel() {
      this.city = this.cityModel;
    },
    stateModel() {
      this.state = this.stateModel;
    },
    zipModel() {
      this.zip = this.zipModel;
    }
  },
  created() {
    this.streetAddress = this.streetAddressModel;
    this.city = this.cityModel;
    this.state = this.stateModel;
    this.zip = this.zipModel;
    this.alreadyFilledOut = Boolean(this.streetAddress || this.noAutofill);
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  methods: {
    switchToManualEntry() {
      const attributes = {};

      if (this.streetAddress) {
        attributes.street_address = this.streetAddress;
      }
      if (this.city) {
        attributes.city = this.city;
      }
      if (this.state) {
        attributes.state = this.state;
      }
      if (this.zip) {
        attributes.zip = this.zip;
      }
      if (Object.keys(attributes).length) this.$emit("autofill", attributes);
      this.alreadyFilledOut = true;
    },
    // We need to separate these from normal updates because many fire off
    // and cause some fields to not be saved.
    // this is due to the nature of create or update
    autofillAddress({ street_address, state, zip, city }) {
      this.$emit("autofill", {
        street_address,
        state,
        zip,
        city
      });
      this.$nextTick(() => (this.alreadyFilledOut = true));
    }
  }
};
</script>
