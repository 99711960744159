import { getHttpClient } from "@/http-client";
import { serializeObject } from "@/util/helpers";
import { setPayPeriodFromRequest } from "@/factories/PayPeriodFactory";

const baseUrl = "/api/boss/pay_periods";

export const updatePayPeriodEft = (payPeriodId, eftId) => {
  return getHttpClient().put(`${baseUrl}/${payPeriodId}`, {
    pay_period: {
      eft_id: eftId
    }
  });
};

let cancelToken = null;
export function getPayPeriods(params) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  return getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });
}

export async function getPayPeriodData(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  return setPayPeriodFromRequest(data.pay_period);
}

export async function payPayPeriod(id, memo = null) {
  let body = undefined;
  if (memo) {
    body = await serializeObject({ memo });
  }
  return getHttpClient().post(`${baseUrl}/${id}/pay`, body);
}

export function getPayPeriodCsv(id) {
  return `${baseUrl}/${id}/download`;
}

export function getPayPeriodPdf(id) {
  return `${baseUrl}/${id}/download.pdf`;
}

export async function updatePayPeriod(id, memo) {
  const pay_period = {
    memo: memo
  };

  return getHttpClient().put(
    `${baseUrl}/${id}`,
    await serializeObject({ pay_period })
  );
}
