<template>
  <v-card flat color="section">
    <v-card-title> Entity Information </v-card-title>
    <v-card-text>
      <v-row dense class="ma-0">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="contractParty.name"
            label="Entity Name"
            data-lpignore="true"
            outlined
            dense
            data-testid="name"
            :prepend-inner-icon="mdiDomain"
            :success="nameValidation.success"
            :error-messages="nameValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="4">
          <tin-input
            v-model="contractParty.tin"
            data-lpignore="true"
            label="Entity TIN"
            outlined
            dense
            data-testid="tin"
            :prepend-inner-icon="mdiPound"
            :success="tinValidation.success"
            :error-messages="tinValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="4">
          <date-input
            v-model="contractParty.formationDate"
            label="Entity Date"
            outlined
            dense
            data-testid="formation-date"
            :prepend-inner-icon="mdiCalendar"
            :success="dateValidation.success"
            :error-messages="dateValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="contractParty.irrevocable"
            label="Revocable"
            placeholder="Choose Revocable"
            outlined
            dense
            data-testid="irrevocable"
            :prepend-inner-icon="mdiListStatus"
            :success="irrevocableValidation.success"
            :error-messages="irrevocableValidation.errorMessages"
            :items="ENTITY_IRREVOCABLES"
          />
        </v-col>
        <v-col cols="12" md="4">
          <basic-email-input
            v-model="contractParty.email"
            label="Email"
            outlined
            dense
            data-testid="email"
            :prepend-inner-icon="mdiEmail"
            :success="emailValidation.success"
            :error-messages="emailValidation.errorMessages"
            @valid="setEmailIsValid"
          />
        </v-col>
        <v-col cols="12" md="4">
          <phone-input
            v-model="contractParty.phoneWork"
            label="Office Phone"
            outlined
            dense
            data-lpignore="true"
            data-testid="phone-work"
            :prepend-inner-icon="mdiDeskphone"
            :success="phoneWorkValidation.success"
            :error-messages="phoneWorkValidation.errorMessages"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-title>Entity Location</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <basic-address-input
            key="contract-party-business-address"
            type="business"
            autofill-label="Business Address"
            autofill-placeholder="Business Address"
            data-testid="business"
            :street-address-model.sync="
              contractParty.businessAddress.street_address
            "
            :city-model.sync="contractParty.businessAddress.city"
            :state-model.sync="contractParty.businessAddress.state"
            :zip-model.sync="contractParty.businessAddress.zip"
            :street-address-validation="streetAddressValidation"
            :city-validation="cityValidation"
            :state-validation="stateValidation"
            :zip-validation="zipValidation"
            @autofill="handleAutofill"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script setup>
import PhoneInput from "@/components/shared/PhoneInput.vue";
import TinInput from "@/components/shared/TinInput.vue";
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import DateInput from "@/components/shared/DateInput.vue";
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";
import useVuelidate from "@vuelidate/core";
import { computedValidation, someTextValidator } from "@/util/helpers";
import { ref, defineProps, watch, defineEmits } from "vue";
import {
  tin as tinValidator,
  phone as phoneValidator
} from "@/util/vuelidateValidators";
import { Entity, ENTITY_IRREVOCABLES } from "@/factories/ContractParty";
import {
  mdiDomain,
  mdiPound,
  mdiCalendar,
  mdiListStatus,
  mdiEmail,
  mdiDeskphone
} from "@mdi/js";

const props = defineProps({
  value: {
    type: Object,
    required: true
  }
});
const emit = defineEmits(["input"]);
const contractParty = ref(Entity(props.value));

const emailIsValid = ref(false);

const v$ = useVuelidate(
  {
    contractParty: {
      name: { required: v => someTextValidator(true, v, 2) },
      tin: { valid: v => !v || tinValidator(v) },
      email: { valid: v => !v || emailIsValid.value },
      irrevocable: {
        valid: v => v === null || ENTITY_IRREVOCABLES.some(r => r.value === v)
      },
      formationDate: { valid: true },
      phoneWork: {
        required: v => !v || phoneValidator(v)
      },
      businessAddress: {
        street_address: { required: true },
        city: { required: true },
        state: { required: true },
        zip: { required: true }
      }
    }
  },
  { contractParty },
  { $autoDirty: true, $scope: null }
);

const nameValidation = computedValidation(v$.value.contractParty.name, {
  required: "Required"
});
const tinValidation = computedValidation(v$.value.contractParty.tin, {
  valid: "Invalid TIN"
});
const dateValidation = computedValidation(
  v$.value.contractParty.formationDate,
  {
    valid: "Invalid Date"
  }
);
const irrevocableValidation = computedValidation(
  v$.value.contractParty.irrevocable,
  {
    valid: "Invalid Revocable"
  }
);
const streetAddressValidation = computedValidation(
  v$.value.contractParty.businessAddress.street_address,
  { required: "Required" }
);
const cityValidation = computedValidation(
  v$.value.contractParty.businessAddress.city,
  {
    required: "Required"
  }
);
const stateValidation = computedValidation(
  v$.value.contractParty.businessAddress.state,
  {
    required: "Required"
  }
);
const zipValidation = computedValidation(
  v$.value.contractParty.businessAddress.zip,
  {
    required: "Required"
  }
);

const emailValidation = computedValidation(v$.value.contractParty.email, {
  valid: "Please provide a valid email address"
});

const phoneWorkValidation = computedValidation(
  v$.value.contractParty.phoneWork,
  {
    required: "Invalid Phone"
  }
);

function handleAutofill({ street_address, city, state, zip }) {
  contractParty.value.businessAddress.street_address = street_address;
  contractParty.value.businessAddress.city = city;
  contractParty.value.businessAddress.state = state;
  contractParty.value.businessAddress.zip = zip;
}

function setEmailIsValid(isValid) {
  emailIsValid.value = isValid;
}

watch(contractParty, () => emit("input", contractParty.value), { deep: true });
</script>
