<template>
  <v-card color="section" flat tile>
    <v-card-text>
      <v-row class="ma-0">
        <v-col cols="12">
          <v-text-field
            v-model="search"
            label="Search Carriers"
            prepend-inner-icon="$mdi-magnify"
            dense
            outlined
          />
        </v-col>
        <v-col
          v-for="(business, index) in shownBusinesses"
          cols="12"
          xl="3"
          lg="4"
          md="4"
          sm="6"
          :key="index"
          align="center"
        >
          <v-card
            min-height="22rem"
            flat
            :data-testid="`carrier-${business.text}-group`"
          >
            <v-img
              :src="business.carriers[0].avatar"
              contain
              height="150"
              width="250"
            />
            <v-card-text>
              <v-col
                cols="12"
                v-for="carrier in business.carriers"
                :key="carrier.text"
                class="pa-1"
              >
                <v-switch
                  v-model="carrier.value"
                  value
                  inset
                  class="mt-0"
                  :data-testid="`carrier-${carrier.text}-toggle`"
                  :disabled="savingBuffer[carrier.id].saving"
                  @change="updateCarrierAvailability(carrier)"
                >
                  <template #label>
                    {{ carrier.text }}
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer[carrier.id]"
                    />
                  </template>
                </v-switch>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { mapActions, mapWritableState } from "pinia";

export default {
  mixins: [ActiveSaveMixin],
  components: {
    ActiveSaveIndicator
  },
  data() {
    return {
      businesses: [],
      savingBuffer: {},
      search: ""
    };
  },
  computed: {
    ...mapWritableState(useApprovedDomainView, ["carriers"]),
    shownBusinesses() {
      if (!this.search) return this.businesses;

      return this.businesses.filter(business =>
        business.carriers.some(carrier =>
          carrier.text.toLowerCase().includes(this.search)
        )
      );
    }
  },
  created() {
    this.generateCarrierStructure();
  },
  methods: {
    ...mapActions(useApprovedDomainView, ["updateApprovedDomainCarrier"]),
    generateCarrierStructure() {
      const groups = {};

      // parentId is not the carrier id, rather something else
      this.carriers.forEach(carrier => {
        this.$set(
          this.savingBuffer,
          carrier.id,
          this.activeSaveElementFactory()
        );

        if (carrier.parentId) {
          if (!groups[`p_${carrier.parentId}`]) {
            groups[`p_${carrier.parentId}`] = {
              avatar: null,
              carriers: [],
              text: null
            };
          }
          groups[`p_${carrier.parentId}`].carriers.push(carrier);
        } else {
          groups[`c_${carrier.id}`] = {
            avatar: carrier.avatar,
            carriers: [carrier],
            text: carrier.text
          };
        }
      });

      const cleanedBusinesses = [];

      Object.keys(groups).forEach(key => {
        const carriers = groups[key].carriers;
        carriers.sort((a, b) => a.text.localeCompare(b.text));
        const [{ avatar, text }] = groups[key].carriers;

        cleanedBusinesses.push({ carriers, avatar, text });
      });

      cleanedBusinesses.sort((a, b) => a.text.localeCompare(b.text));

      this.$set(this, "businesses", cleanedBusinesses);
    },
    updateCarrierAvailability(carrier) {
      this.updateAttributeWrapper(
        () => this.updateApprovedDomainCarrier(carrier.id, carrier.value),
        this.savingBuffer[carrier.id]
      );
    }
  }
};
</script>
