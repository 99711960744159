<template>
  <v-data-table
    class="transparent-data-table"
    data-testid="admin-documents-table"
    disable-pagination
    hide-default-footer
    disable-sort
    disable-filtering
    :items="table.mappedItems.value"
    :headers="table.tableHeaders.value"
  >
    <template #top v-if="user.isGroupTwoPlus">
      <v-btn
        class="text-none"
        color="accent"
        data-testid="upload-new-document"
        @click="showUploadDocumentDialog"
      >
        <v-icon> $mdi-plus</v-icon> Upload Document
      </v-btn>
    </template>

    <template #[`item.createdAt`]="{ item }">
      <timestamp-formatter :value="item.createdAt" />
    </template>

    <template #[`item.documentActions`]="{ item }">
      <div class="row">
        <v-btn
          class="text-none"
          color="primary"
          icon
          data-testid="download-document"
          v-bind="downloadDocument(item)"
        >
          <v-icon> $mdi-download </v-icon>
        </v-btn>
        <v-btn
          v-if="user.isGroupTwoPlus"
          class="text-none"
          color="red"
          icon
          data-testid="delete-document"
          :disabled="!item.additional.canDelete"
          @click="deleteRow(item)"
        >
          <v-icon> $mdi-delete </v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";

import { downloadFileAsLink } from "@/util/helpers";

import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import { getDocumentUrl } from "@/api/documents.service";
import { useDialogStore } from "@/stores/dialog";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { useTable } from "@/composables/table.composable";
import { useVuetify } from "@/composables/compatible.composables";

import { setDocumentFromRequest } from "@/factories/DocumentFactory";
import { defineProps } from "vue";
import { useSettingsViewStore } from "@/stores/settings-view";
import { useUserStore } from "@/stores/user";

const props = defineProps({
  readonly: Boolean,
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});
let store;

const user = useUserStore();
if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}
const headers = [
  new TableHeader({ text: "Name", value: "name", map: "name" }),
  new TableHeader({
    text: "Publish Date",
    value: "createdAt",
    map: "createdAt"
  })
];

if (!props.readonly) {
  headers.push(
    new TableHeader({
      text: "Actions",
      value: "documentActions",
      map: ""
    })
  );
}

const table = useTable({
  headers
});

const vuetify = useVuetify();
const dialog = useDialogStore();
async function showUploadDocumentDialog() {
  const documents = await dialog.showDialog({
    component: "UploadDocumentDialog",
    userId: store.id,
    type: store.type
  });
  if (!documents?.length) return;
  documents.forEach(rdoc => {
    const document = setDocumentFromRequest(rdoc);
    store.addRelatedDocument(document);
  });

  updateTable();
}

function downloadDocument(item) {
  return downloadFileAsLink(
    getDocumentUrl(item.additional.uid),
    vuetify.breakpoint.mdAndDown
  );
}

async function deleteRow(item) {
  await dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Remove document ${item.name} from this Advisor?`,
    subtitle: "This cannot be undone",
    func: () => store.deleteRelatedDocument(item.additional.uid)
  });
  updateTable();
}

function updateTable() {
  table.items.value = Object.values(store.relatedDocuments);
}

updateTable();
</script>
