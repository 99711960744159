import { uuidv4, getRouterLink } from "@/util/helpers";
import { setInformalOfferFromRequest } from "@/factories/InformalOffer";

export const IMPAIRED_RISK_QUOTE_COVERAGE_TYPES = [
  { text: "Permanent", value: "Permanent" },
  { text: "Term", value: "Term" },
  { text: "Permanent & Term", value: "Permanent_and_Term" }
];

export const IMPAIRMENTS = [
  "CAD",
  "Diabetes",
  "Non Medical",
  "Other Medical",
  "Psychiatric",
  "Labs",
  "Cancer",
  "Sleep Apnea",
  "Neurologic",
  "Coronary Artery Disease"
];

export const IMPAIRED_RISK_STATUSES = [
  { text: "Outstanding", value: "outstanding" },
  { text: "Completed", value: "completed" }
];

export const IMPAIRMENT_CONCERNS = [
  "APS",
  "Blood Pressure",
  "Build",
  "Cancer",
  "Cannabis",
  "Cholesterol",
  "Coronary Artery Disease",
  "Diabetes",
  "Driving Record",
  "Family History",
  "Financial",
  "Foreign Travel",
  "Labs",
  "Medications",
  "Neurologic",
  "Nicotine",
  "No Response from Agent",
  "No Response from Client",
  "Non Medical",
  "Other",
  "Psychiatric",
  "Sleep Apnea"
];

export const IMPAIRMENT_BASIC_CONCERNS = [
  "Cancer",
  "Coronary Artery Disease",
  "Diabetes",
  "Labs",
  "Neurologic",
  "Non Medical",
  "Other Medical",
  "Psychiatric",
  "Sleep Apnea"
];

export function ImpairedRiskQuote(model = {}) {
  return {
    advisor: model?.advisor || null,
    carriers: model?.carriers || null,
    chronic: model?.chronic || null,
    createdAt: model?.createdAt || null,
    deathBenefit: model?.deathBenefit || null,
    familyHistory: model?.familyHistory || null,
    gender: model?.gender || null,
    height: model?.height || null,
    id: model?.id || null,
    impairments: model?.impairments || null,
    informalOffers: model?.informalOffers || null,
    insured: model?.insured || null,
    offers: model?.offers || [],
    room: model?.room || null,
    status: model?.status || null,
    tobaccoDetails: model?.tobaccoDetails || null,
    tobaccoUse: model?.tobaccoUse || null,
    typeOfInsurance: model?.typeOfInsurance || null,
    weight: model?.weight || null,
    birthdate: model?.birthdate || ""
  };
}

export function setImpairedRiskQuoteFromRequest(raw = {}) {
  const model = ImpairedRiskQuote();
  model.advisor = {
    id: raw?.advisor?.id,
    name: raw?.advisor?.name,
    type: raw?.advisor?.type?.model
  };

  model.carriers = raw?.carriers;
  model.chronic = raw?.chronic;
  model.createdAt = raw?.created_at;
  model.deathBenefit = raw?.death_benefit;
  model.familyHistory = raw?.family_history;
  model.gender = raw?.gender;
  model.height = raw?.height;
  model.id = raw?.id;
  model.birthdate = raw?.birthdate;
  model.insured = raw?.insured;
  model.status = raw?.status;
  model.tobaccoDetails = raw?.tobacco_details;
  model.tobaccoUse = raw?.tobacco_use;
  model.typeOfInsurance = raw?.type_of_insurance;
  model.weight = raw?.weight;

  model.impairments = raw?.impairments?.map(rawImpairment =>
    setImpairmentFromRequest(rawImpairment)
  );

  model.room = raw?.room?.map(rawAccess =>
    setImpairedRiskQuoteRoomAccessFromRequest(rawAccess)
  );

  model.offers = raw?.informal_offers?.map(rawOffer =>
    setInformalOfferFromRequest(rawOffer)
  );

  model.offers.sort((a, b) => a.carrier.name.localeCompare(b.carrier.name));

  return model;
}

export function Impairment(model = {}) {
  return {
    ageAtOnset: model?.ageAtOnset || null,
    description: model?.description || "",
    diagnosis: model?.diagnosis || "",
    id: model?.id || null,
    impairment: model?.impairment || "",
    path: model?.path || null,
    treatment: model?.treatment || ""
  };
}

export function setImpairmentFromRequest(raw = {}) {
  const model = Impairment();
  model.ageAtOnset = raw?.age_at_onset;
  model.description = raw?.description;
  model.diagnosis = raw?.diagnosis;
  model.id = raw?.id;
  model.impairment = raw?.impairment;
  model.path = raw?.path;
  model.treatment = raw?.treatment;
  return model;
}

export function ImpairedRiskQuoteRoomAccess(model = {}) {
  return {
    disabled: model?.disabled || null,
    email: model?.email || null,
    id: model?.id || null,
    owner: model?.owner || null,
    name: model?.name || "",
    ownerId: model?.ownerId || null,
    ownerType: model?.ownerType || null,
    routerLink: model?.routerLink || null
  };
}

export function setImpairedRiskQuoteRoomAccessFromRequest(model = {}) {
  const access = ImpairedRiskQuoteRoomAccess();
  access.disabled = model?.disabled;
  access.email = model?.email;
  access.id = model?.id;
  access.owner = model?.owner;
  access.ownerId = model?.ownable?.id;
  access.name = model?.ownable?.name;
  access.ownerType = model?.ownable?.type?.model;
  if (access.ownerType) {
    access.routerLink = getRouterLink(access.ownerType, access.ownerId);
  }
  return access;
}

export function NewImpairedRiskQuote(model = {}) {
  return {
    carriers: model?.carriers || [],
    coverageType: model?.coverageType || null,
    ltc: model?.ltc || false,
    faceAmount: model?.faceAmount || null,
    advisor: model?.advisor || null,
    name: model?.name || null,
    gender: model?.gender || null,
    state: model?.state || null,
    impairments: model?.impairments || [],
    height: model?.height || null,
    weight: model?.weight || null,
    usage: model?.usage || null,
    usageDetails: model?.usageDetails || null,
    medicalHistory: model?.medicalHistory || null,
    creator: model?.creator || null,
    birthdate: model?.birthdate || null
  };
}

export function NewImpairedRiskQuoteImpairment(model = {}) {
  return {
    uuid: uuidv4(),
    concern: model?.concern || null,
    diagnosis: model?.diagnosis || null,
    age: model?.age || null,
    treatment: model?.treatment || null,
    other: model?.other || null
  };
}

export function setRequestFromNewImpairedRiskQuote(irq) {
  return {
    quick_quote: {
      birthdate: irq.birthdate,
      chronic: irq.ltc,
      death_benefit: irq.faceAmount,
      gender: irq.gender,
      height: irq.height,
      weight: irq.weight,
      insured: irq.name,
      state: irq.state,
      status: "Outstanding",
      tobacco_use: irq.usage,
      tobacco_details: irq.usageDetails,
      type_of_insurance: irq.coverageType,
      ownable_id: irq.advisor.id,
      creator_id: irq.creator.id,
      ownable_type: irq.advisor.type?.model || irq.advisor.type,
      creator_type: irq.creator.type?.model || irq.creator.type,
      family_history: irq.medicalHistory,
      informal_offers_attributes: irq.carriers.map(carrier_id => ({
        carrier_id: carrier_id,
        name: "Quick Quote",
        status: "Submitted",
        prescreens_attributes: [
          {
            ownable_id: carrier_id,
            agent: 0,
            email: 1,
            ownable_type: "Carrier"
          },
          {
            ownable_id: irq.advisor.id,
            ownable_type: irq.advisor.type?.model || irq.advisor.type,
            agent: 1
          },
          {
            ownable_id: irq.creator.id,
            ownable_type: irq.creator.type?.model || irq.creator.type,
            agent: 2
          }
        ]
      })),
      impairments_attributes: irq.impairments.map(impairment => ({
        impairment: impairment.concern,
        description: impairment.other,
        diagnosis: impairment.diagnosis,
        path: impairment.pathologyReport,
        treatment: impairment.treatment,
        age_at_onset: impairment.age
      }))
    }
  };
}
