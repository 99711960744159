<template>
  <v-fade-transition mode="out-in">
    <v-row v-if="loading === true" class="ma-0" key="loading">
      <v-col cols="12">
        <h3>Fetching Client Details</h3>
        <v-progress-linear indeterminate />
      </v-col>
    </v-row>
    <v-card v-else-if="loading === false">
      <v-card-title> More about {{ party.name }} </v-card-title>
      <v-card-text>
        <party-card-items :party="party" :type="type" key="party-info" />
      </v-card-text>
    </v-card>
    <v-alert v-else type="error" key="error"> Unable to fetch party. </v-alert>
  </v-fade-transition>
</template>

<script>
import PartyCardItems from "@/components/parties/PartyCardItems.vue";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { fetchIndividual } from "@/api/individual.service";
import { fetchEntity } from "@/api/entities.service";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  components: {
    PartyCardItems
  },
  props: {
    type: {
      type: String,
      validates: v => ["Individual", "Entity"].includes(v),
      required: true
    },
    id: {
      type: [Number, String],
      required: true
    }
  },
  data: () => ({ party: null, loading: false }),
  created() {
    this.fetchParty();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async fetchParty() {
      this.loading = true;
      try {
        if (this.type === "Individual") {
          this.party = await fetchIndividual(this.id);
        } else {
          this.party = await fetchEntity(this.id);
        }
        this.loading = false;
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
        this.loading = "error";
      }
    }
  }
};
</script>
