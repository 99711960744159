import { render, staticRenderFns } from "./ModelCreateMenu.vue?vue&type=template&id=049a2c71"
import script from "./ModelCreateMenu.vue?vue&type=script&lang=js"
export * from "./ModelCreateMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports