<template>
  <v-data-table
    disable-sort
    disable-pagination
    disable-filtering
    hide-default-footer
    :items="items"
    :headers="electronicApplicationsTab.headers"
  >
    <template #[`item.deathBenefit`]="{ item }">
      <currency-formatter :value="item.deathBenefit" :decimal-length="0" />
    </template>
    <template #[`item.premium`]="{ item }">
      <currency-formatter :value="item.premium" :decimal-length="0" />
      (<mode-formatter :value="item.additional.mode" />)
    </template>
    <template #[`item.createdAt`]="{ item }">
      <timestamp-formatter format="date-time" :value="item.createdAt" />
    </template>

    <template #[`item.electronicApplication`]="{ item }">
      <router-link
        class="text-none"
        :to="{
          name: 'ElectronicApplicationView',
          params: {
            id: item.additional.id
          }
        }"
      >
        {{ item.electronicApplication }}
      </router-link>
    </template>
  </v-data-table>
</template>

<script>
import ModeFormatter from "@/components/shared/formatters/ModeFormatter.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";
import TableHeader from "@/classes/data-table/TableHeader";
import Table from "@/classes/data-table/Table";

export default {
  components: {
    ModeFormatter,
    TimestampFormatter,
    CurrencyFormatter
  },
  mixins: [GenericTableMixin],
  props: {
    party: Object
  },
  data() {
    const headers = [
      new TableHeader({
        text: "eApp",
        value: "electronicApplication",
        map: "id"
      }),
      new TableHeader({
        text: "Insured",
        value: "insured",
        map: "name"
      }),
      new TableHeader({
        text: "Carrier",
        value: "carrier",
        map: "carrier.name"
      }),
      new TableHeader({
        text: "Product",
        value: "product",
        map: "product.name"
      }),
      new TableHeader({
        text: "Premium",
        value: "premium",
        map: "premium"
      }),
      new TableHeader({
        text: "Death Benefit",
        value: "deathBenefit",
        map: "faceAmount"
      }),
      new TableHeader({
        text: "Created",
        value: "createdAt",
        map: "createdAt"
      })
    ];
    const electronicApplicationsTab = new Table({
      text: "Parties",
      headers,
      key: "parties"
    });

    return {
      electronicApplicationsTab
    };
  },
  computed: {
    items() {
      return this.tableMap(
        this.party.electronicApplications,
        this.electronicApplicationsTab.headers
      );
    }
  },
  methods: {
    changeTab(value) {
      this.activeTab = value;
    }
  }
};
</script>
