<template>
  <v-card>
    <v-card-title>
      <v-row class="ma-0" align="center">
        More about {{ item.product.name }}
        <v-spacer />
        <div v-if="user.isGroupFour">
          <v-btn
            outlined
            color="primary"
            class="text-none"
            data-testid="show-raw-values"
            @click="showRawValues = !showRawValues"
          >
            <v-icon v-if="showRawValues" class="mr-1">
              {{ mdiFormatTitle }}
            </v-icon>
            <v-icon v-else class="mr-1"> {{ mdiCodeTags }} </v-icon>
            {{ showRawValues ? "Show Formatted Values" : "Show Raw Values" }}
          </v-btn>
        </div>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row v-for="(group, index) in itemGroups" dense :key="`${index}-group`">
        <v-col
          v-for="({ title, value, raw }, itemIndex) in group"
          cols="6"
          xl="2"
          lg="3"
          md="4"
          sm="6"
          :key="`${itemIndex}-group-item`"
          :data-testid="`quote-history-${title}`"
        >
          <span class="grey--text lighten-2"> {{ title }} </span>
          <br />
          <pre v-if="showRawValues" class="wrap-pre">{{ raw }}</pre>
          <span v-else>{{ value }}</span>
        </v-col>
        <v-col v-if="index < itemGroups.length - 1" cols="12">
          <v-divider />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { mdiCodeTags, mdiFormatTitle } from "@mdi/js";
import {
  formatBoolToText,
  categoryToConstantProduct,
  currencyFormat,
  formatToFullState,
  heightText,
  listToSentence,
  modeFormat,
  payDurationFormat,
  percentageFormat,
  timestampFormatter,
  formatPartnerDiscount,
  formatBenefitPeriod,
  formatFrequency
} from "@/util/helpers";
import { useUserStore } from "@/stores/user";
import { ref, defineProps, computed, toRefs } from "vue";

const props = defineProps({ item: { type: Object, required: true } });
const { item } = toRefs(props);

const user = useUserStore();
const showRawValues = ref(false);

const items = computed(() => {
  const data = [];
  if (item.value.faceAmount) {
    data.push({
      title: "Death Benefit",
      value: currencyFormat(item.value.faceAmount, 0),
      group: "quote",
      raw: item.value.faceAmount
    });
  }
  if (item.value.premium) {
    data.push({
      title: "Premium",
      value: currencyFormat(item.value.premium, 2),
      group: "quote",
      raw: item.value.premium
    });
  }
  if (item.value.targetPremium) {
    data.push({
      title: "Target Premium",
      value: currencyFormat(item.value.targetPremium, 2),
      group: "meta",
      raw: item.value.targetPremium
    });
  }
  if (item.value.createdAt) {
    data.push({
      title: "Created",
      value: timestampFormatter(item.value.createdAt, "none", "date-time"),
      group: "meta",
      raw: item.value.createdAt
    });
  }
  if (showRawValues.value) {
    data.push({
      title: "Quote ID",
      value: item.value.id,
      group: "meta",
      raw: item.value.id
    });
    data.push({
      title: "Carrier ID",
      value: item.value.carrier.id,
      group: "meta",
      raw: item.value.carrier.id
    });
    data.push({
      title: "Product ID",
      value: item.value.product.id,
      group: "meta",
      raw: item.value.product.id
    });
    data.push({
      title: "Illustration ID",
      value: item.value.illustration.id || "N/A",
      group: "meta",
      raw: item.value.illustration.id || "N/A"
    });
  }
  if (item.value.mode) {
    data.push({
      title: "Mode",
      value: modeFormat(item.value.mode, "adverb"),
      group: "quote",
      raw: item.value.mode
    });
  }
  if (item.value.payDuration) {
    data.push({
      title: "Pay Duration",
      value: payDurationFormat(item.value.payDuration),
      group: "quote",
      raw: item.value.payDuration
    });
  }
  if (item.value.solve) {
    data.push({
      title: "Solve",
      value: item.value.solve,
      group: "quote",
      raw: item.value.solve
    });
  }
  if (item.value.state) {
    data.push({
      title: "State",
      value: formatToFullState(item.value.state),
      group: "insured",
      raw: item.value.state
    });
  }
  if (item.value.firstName) {
    data.push({
      title: "First Name",
      value: item.value.firstName,
      group: "insured",
      raw: item.value.firstName
    });
  }
  if (item.value.lastName) {
    data.push({
      title: "Last Name",
      value: item.value.lastName,
      group: "insured",
      raw: item.value.lastName
    });
  }
  if (item.value.gender) {
    data.push({
      title: "Gender",
      value: item.value.gender,
      group: "insured",
      raw: item.value.gender
    });
  }
  if (item.value.birthdate) {
    data.push({
      title: "Birthdate",
      value: timestampFormatter(item.value.birthdate, "sole-day", "basic"),
      group: "insured",
      raw: item.value.birthdate
    });
  }
  if (item.value.health) {
    data.push({
      title: "Health",
      value: item.value.health,
      group: "insured",
      raw: item.value.health
    });
  }
  if (item.value.smoker) {
    data.push({
      title: "Smoker",
      value: item.value.smoker,
      group: "insured",
      raw: item.value.smoker
    });
  }
  if (item.value.smokingUsages.length) {
    const usageTexts = item.value.smokingUsages.map(usage => {
      let usageText = `${formatFrequency(usage.frequency)} ${usage.category}`;
      if (usage.lastUseDate) {
        usageText += `, Last Used: ${timestampFormatter(
          usage.lastUseDate,
          "sole-day",
          "basic"
        )}`;
      }

      return usageText;
    });

    data.push({
      title: "Smoking Usages",
      value: usageTexts.join("\n"),
      group: "insured",
      raw: JSON.stringify(item.value.smokingUsages.map(v => v.raw))
    });
  }
  if (item.value.height) {
    data.push({
      title: "Height",
      value: heightText(item.value.height.toString()),
      group: "insured",
      raw: item.value.height
    });
  }
  if (item.value.weight) {
    data.push({
      title: "Weight",
      value: item.value.weight,
      group: "insured",
      raw: item.value.weight
    });
  }
  if (item.value.validatedRating) {
    data.push({
      title: "Validated Rating",
      value: item.value.validatedRating,
      group: "insured",
      raw: item.value.validatedRating
    });
  }
  if (item.value.product.category) {
    data.push({
      title: "Category",
      value: listToSentence(
        categoryToConstantProduct(item.value.product.category)
      ),
      group: "quote",
      raw: item.value.product.category
    });
  }
  if (item.value.cashValue) {
    data.push({
      title: "Cash Value",
      value: currencyFormat(item.value.cashValue, 0),
      group: "quote",
      raw: item.value.cashValue
    });
  }
  if (item.value.ageValue) {
    data.push({
      title: "Age Value",
      value: item.value.ageValue,
      group: "quote",
      raw: item.value.ageValue
    });
  }
  if (item.value.creditingRate) {
    data.push({
      title: "Crediting Rate",
      value: item.value.creditingRate,
      group: "quote",
      raw: item.value.creditingRate
    });
  }
  if ([true, false].includes(item.value.ltcRider)) {
    data.push({
      title: "LTC Rider",
      value: formatBoolToText(item.value.ltcRider),
      group: "quote",
      raw: item.value.ltcRider
    });
  }
  if (item.value.ltcRiderPercentage) {
    data.push({
      title: "LTC Rider Percentage",
      value: percentageFormat(item.value.ltcRiderPercentage, false, false),
      group: "quote",
      raw: item.value.ltcRiderPercentage
    });
  }
  if (item.value.incomeSolve) {
    data.push({
      title: "Income Solve",
      value: item.value.incomeSolve,
      group: "quote",
      raw: item.value.incomeSolve
    });
  }
  if (item.value.incomeStartAge) {
    data.push({
      title: "Income Start Age",
      value: item.value.incomeStartAge,
      group: "quote",
      raw: item.value.incomeStartAge
    });
  }
  if (item.value.incomeEndAge) {
    data.push({
      title: "Income End Age",
      value: item.value.incomeEndAge,
      group: "quote",
      raw: item.value.incomeEndAge
    });
  }
  if (item.value.lapseProtectionToAge) {
    data.push({
      title: "Lapse Protection to Age",
      value: item.value.lapseProtectionToAge,
      group: "quote",
      raw: item.value.lapseProtectionToAge
    });
  }
  if (item.value.filterChronicIllnessAndLtc) {
    data.push({
      title: "Filter Chronic Illness and LTC",
      value: item.value.filterChronicIllnessAndLtc,
      group: "quote",
      raw: item.value.filterChronicIllnessAndLtc
    });
  }
  if ([true, false].includes(item.value.livingBenefits)) {
    data.push({
      title: "Living Benefits",
      value: formatBoolToText(item.value.livingBenefits),
      group: "quote",
      raw: item.value.livingBenefits
    });
  }
  if ([true, false].includes(item.value.chronicIllnessRider)) {
    data.push({
      title: "Chronic Illness Rider",
      value: formatBoolToText(item.value.chronicIllnessRider),
      group: "quote",
      raw: item.value.chronicIllnessRider
    });
  }
  if (item.value.loanType) {
    data.push({
      title: "Loan Type",
      value: item.value.loanType,
      group: "quote",
      raw: item.value.loanType
    });
  }
  if (item.value.section1035Exchange) {
    data.push({
      title: "Section 1035 Exchange Amount",
      value: currencyFormat(item.value.section1035Exchange, 0),
      group: "quote",
      raw: JSON.stringify(item.value.section1035ExchangeRaw)
    });
  }
  if (item.value.vitality) {
    data.push({
      title: "Vitality Level",
      value: item.value.vitality,
      group: "quote",
      raw: JSON.stringify(item.value.vitalityRaw)
    });
  }
  if ([true, false].includes(item.value.mec)) {
    data.push({
      title: "Mec",
      value: formatBoolToText(item.value.mec),
      group: "quote",
      raw: item.value.mec
    });
  }
  if (item.value.discount) {
    data.push({
      title: "Discount",
      value: item.value.discount,
      group: "quote",
      raw: item.value.discount
    });
  }
  if ([true, false].includes(item.value.returnOfPremiumRider)) {
    data.push({
      title: "Return of Premium Rider",
      value: formatBoolToText(item.value.returnOfPremiumRider),
      group: "quote",
      raw: item.value.returnOfPremiumRider
    });
  }
  if ([true, false].includes(item.value.saveAge)) {
    data.push({
      title: "Save Age",
      value: formatBoolToText(item.value.saveAge),
      group: "quote",
      raw: item.value.saveAge
    });
  }
  if ([true, false].includes(item.value.guaranteedIssue)) {
    data.push({
      title: "Guaranteed Issue",
      value: formatBoolToText(item.value.guaranteedIssue),
      group: "quote",
      raw: item.value.guaranteedIssue
    });
  }
  if (item.value.monthlyBenefit) {
    data.push({
      title: "Monthly Benefit",
      value: currencyFormat(item.value.monthlyBenefit, 0),
      group: "quote",
      raw: item.value.monthlyBenefit
    });
  }
  if (item.value.eliminationPeriod) {
    data.push({
      title: "Elimination Period",
      value: item.value.eliminationPeriod,
      group: "quote",
      raw: item.value.eliminationPeriod
    });
  }
  if (item.value.inflationPercentage) {
    data.push({
      title: "Inflation Percentage",
      value: item.value.inflationPercentage,
      group: "quote",
      raw: item.value.inflationPercentage
    });
  }
  if ([true, false].includes(item.value.jointWaiverOfPremium)) {
    data.push({
      title: "Joint Waiver of Premium",
      value: formatBoolToText(item.value.jointWaiverOfPremium),
      group: "quote",
      raw: item.value.jointWaiverOfPremium
    });
  }
  if ([true, false].includes(item.value.homeHealthCareWaiver)) {
    data.push({
      title: "Home Health Care Waiver of Premium",
      value: formatBoolToText(item.value.homeHealthCareWaiver),
      group: "quote",
      raw: item.value.homeHealthCareWaiver
    });
  }
  if ([true, false].includes(item.value.sharedCare)) {
    data.push({
      title: "Shared Care",
      value: formatBoolToText(item.value.sharedCare),
      group: "quote",
      raw: item.value.sharedCare
    });
  }
  if (item.value.partnerDiscount) {
    data.push({
      title: "Partner Discount",
      value: formatPartnerDiscount(item.value.partnerDiscount),
      group: "quote",
      raw: item.value.partnerDiscount
    });
  }
  if (item.value.benefitPeriod) {
    data.push({
      title: "Benefit Period",
      value: formatBenefitPeriod(item.value.benefitPeriod),
      group: "quote",
      raw: item.value.benefitPeriod
    });
  }

  return data;
});

const itemGroups = computed(() => {
  const groups = {};
  items.value.forEach(item => {
    if (!groups[item.group]) groups[item.group] = [];
    groups[item.group].push(item);
  });
  const groupOrder = ["insured", "quote", "meta"];

  const orderedGroups = [];

  groupOrder.forEach(o => {
    if (groups[o]) {
      groups[o].sort((a, b) => a.title.localeCompare(b.title));
      orderedGroups.push(groups[o]);
    }
  });

  return orderedGroups;
});
</script>

<style lang="scss">
.wrap-pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
