<template>
  <v-col :cols="cols" :xl="xl" :lg="lg" :md="md" :sm="sm" :xs="xs">
    <span class="grey--text lighten-2"> {{ item.title }} </span>
    <br />
    <span v-if="item.filter === 'currency'">
      <currency-formatter :value="item.value" :decimal-length="0" />
    </span>
    <span v-else-if="item.filter === 'parseSoleDay'">
      <timestamp-formatter :value="item.value" parser="sole-day" />
    </span>
    <span v-else-if="item.filter === 'ssn'">
      <sensitive-info-toggle type="ssn" :fetch-func="fetchSensitiveInfo" />
    </span>
    <span v-else-if="item.filter === 'tin'">
      <sensitive-info-toggle type="tin" :fetch-func="fetchSensitiveInfo" />
    </span>
    <span v-else-if="item.to">
      <router-link :to="item.to">{{ item.value }}</router-link>
    </span>
    <span v-else> {{ item.value }} </span>
  </v-col>
</template>

<script>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import SensitiveInfoToggle from "@/components/shared/SensitiveInfoToggle.vue";
import { getSensitiveIndividualInformation } from "@/api/individual.service";
import { getSensitiveEntityInformation } from "@/api/entities.service";
export default {
  components: {
    CurrencyFormatter,
    TimestampFormatter,
    SensitiveInfoToggle
  },
  props: {
    model: Object,
    item: Object,
    xl: {
      default: 2
    },
    lg: {
      default: 3
    },
    md: {
      default: 4
    },
    sm: {
      default: 6
    },
    xs: {
      default: 6
    },
    cols: {
      default: 12
    }
  },
  methods: {
    fetchSensitiveInfo() {
      let func = getSensitiveIndividualInformation;
      if (this.item.filter === "tin") func = getSensitiveEntityInformation;
      return func(this.model.id);
    }
  }
};
</script>
