<template>
  <v-autocomplete
    placeholder="Select a Pricing Plan"
    :label="label"
    outlined
    item-text="name"
    return-object
    dense
    v-model="pricingPlan"
    prepend-inner-icon="$mdi-floor-plan"
    hide-no-data
    :hide-details="hideDetails"
    :clearable="clearable"
    :disabled="disabled"
    :success="success"
    :error-messages="errorMessages"
    :items="pricingPlans"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
    @change="$emit('change')"
    @click:clear="$emit('input', {})"
  >
    <template #append-outer>
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import sortBy from "lodash/sortBy";
import { getAllPricingPlans } from "@/api/boss.service";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  props: {
    hideDetails: Boolean,
    clearable: Boolean,
    success: Boolean,
    errorMessages: Array,
    disabled: Boolean,
    value: [Object, String],
    label: {
      type: String,
      default: "Pricing Plan",
      required: false
    }
  },
  created() {
    this.getAllPricingPlans();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    getAllPricingPlans() {
      this.loading = true;
      getAllPricingPlans()
        .then(res => {
          this.pricingPlans = sortBy(res, "name");
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        });
    },
    clear() {
      this.pricingPlan = null;
      this.pricingPlans = [];
    }
  },
  data() {
    let pricingPlans = [];
    let pricingPlan = null;
    if (this.value) {
      pricingPlan = this.value;
    }
    return {
      pricingPlans,
      pricingPlan,
      loading: false
    };
  },
  watch: {
    value(value) {
      if (value === this.pricingPlan) return;
      this.pricingPlan = value;
    },
    pricingPlan(value) {
      this.$emit("input", value);
    }
  }
};
</script>
