export default function ProductAliasFactory(model = {}) {
  return {
    id: model?.id || 0,
    engine: model?.engine || "",
    parameters: model?.parameters || "",
    includedStates: model?.includedStates || [],
    excludedStates: model?.excludedStates || [],
    setFromRequest(model = {}) {
      this.id = model?.id;
      this.engine = model?.engine;
      this.parameters = JSON.stringify(model?.parameters);
      this.includedStates = [];
      if (model?.included_states?.length) {
        this.includedStates.push(...model.included_states);
      }
      this.excludedStates = [];
      if (model?.excluded_states?.length) {
        this.excludedStates.push(...model.excluded_states);
      }
    },
    toCreateRequest() {
      return {
        engine: this.engine,
        parameters: JSON.parse(this.parameters),
        included_states: this.includedStates || [],
        excluded_states: this.excludedStates || []
      };
    }
  };
}
