<template>
  <v-row dense class="ma-0 pa-0">
    <v-col cols="12" v-if="showSaveButton">
      <v-btn
        block
        @click="save"
        :loading="saving"
        :outlined="!currentFieldHasChanges"
        color="primary"
        class="text-none"
      >
        Save Field {{ currentFieldHasChanges ? "(unsaved changes)" : "" }}
      </v-btn>
    </v-col>
    <v-col cols="12" v-if="showUnlinkButton">
      <v-btn
        block
        @click="referenceField = null"
        color="accent"
        class="text-none"
      >
        Unlink from {{ referenceField }}
      </v-btn>
    </v-col>
    <v-col cols="12" class="mt-3">
      <application-question-search
        v-model="applicationQuestion"
        :autofocus="!applicationQuestion"
        :model="isContracting ? 'Appointment' : 'ElectronicApplication'"
        :model-id="sampleId"
        :disabled="!disabledFields"
        :success="applicationQuestionValidation.success"
        :error-messages="applicationQuestionValidation.errorMessages"
      />
    </v-col>
    <v-col cols="12" v-if="showInsuredIndex" class="mb-3">
      <h3>Insured or Joint Insured</h3>
      <v-chip-group v-model="insuredIndex">
        <v-chip
          v-for="item in INSURED_INDEX_OPTIONS"
          :value="item.value"
          :color="insuredIndex === item.value ? 'primary' : null"
          :key="item.value"
        >
          {{ item.text }}
        </v-chip>
      </v-chip-group>
    </v-col>
    <v-col cols="12" v-if="!isContracting">
      <application-question-search
        v-model="smartApplicationQuestion"
        smart
        clearable
        :model="isContracting ? 'Appointment' : 'ElectronicApplication'"
        :model-id="sampleId"
        :disabled="!disabledFields"
        :success="validSmartApplicationQuestion"
      />
    </v-col>
    <v-col cols="6" v-if="showTiaField">
      <div class="checkbox-width">
        <v-checkbox
          v-model="isTiaField"
          class="mt-1"
          outlined
          dense
          label="TIA Field"
          :disabled="!disabledFields"
          :success="Boolean(isTiaField)"
        />
      </div>
    </v-col>
    <template v-if="showDocusignField">
      <v-col cols="6">
        <div class="checkbox-width">
          <v-checkbox
            v-if="!isTiaField && !isContracting"
            v-model="isDocusignField"
            class="mt-1"
            outlined
            dense
            label="Docusign Field"
            key="docusign"
            :success="Boolean(isDocusignField)"
            :disabled="!disabledFields"
            @change="clearDocusignDependentFields"
          />
        </div>
      </v-col>

      <template v-if="isDocusignField">
        <v-col cols="6">
          <div class="checkbox-width">
            <v-checkbox
              v-model="isDocusignRequired"
              dense
              class="mt-1"
              key="docusign-required"
              label="Docusign Required"
              :disabled="!disabledFields"
              :success="Boolean(isDocusignRequired)"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="docusignRequiredOf"
            prepend-inner-icon="$mdi-asterisk"
            label="Required Of"
            multiple
            outlined
            dense
            :items="DOCUSIGN_REQUIRED_OF"
            :disabled="disabledRequiredOf || readonly"
            :success="Boolean(docusignRequiredOf)"
          />
        </v-col>
      </template>
    </template>
    <v-col cols="6" v-if="showAdditionalForm">
      <div class="checkbox-width">
        <v-checkbox
          v-model="additionalForm"
          label="Supplemental Form"
          prepend-inner-icon="$mdi-text-box-plus"
          dense
          class="mt-1"
          :disabled="!disabledFields"
          :success="Boolean(additionalForm)"
        />
      </div>
    </v-col>
    <v-col v-if="isPdfTextType" cols="6">
      <integer-input
        v-model="comb"
        label="Comb"
        prepend-inner-icon="$mdi-land-rows-vertical"
        outlined
        dense
        :disabled="!disabledFields"
        :success="Boolean(comb)"
        :max="99"
        :min="0"
        hint="Useful for segmented fields such as birthdate, account number, etc"
        persistent-hint
      />
    </v-col>
    <v-col v-if="isPdfTextType" cols="6">
      <div class="checkbox-width">
        <v-checkbox
          v-model="obscureBackground"
          label="Obscure Background"
          prepend-inner-icon="$mdi-square-opacity"
          outlined
          dense
          :disabled="!disabledFields"
          :success="Boolean(obscureBackground)"
          hint="Apply a white background to the input. Typically paired with Comb. Useful for covering symbols"
          persistent-hint
        />
      </div>
    </v-col>
    <template v-if="isVerbatimQuestion">
      <v-col cols="12">
        <v-row dense class="ma-0">
          <v-col
            :cols="showRequiredParentValue ? 6 : 12"
            v-if="!isDocusignField"
          >
            <v-autocomplete
              v-model="parentQuestion"
              prepend-inner-icon="$mdi-google-circles-group"
              label="Parent Question"
              outlined
              dense
              clearable
              item-text="questionSpecificText"
              item-value="id"
              :items="parentFields"
              :disabled="!disabledFields"
              :success="Boolean(parentQuestion)"
            />
          </v-col>
          <v-col cols="6" v-if="showRequiredParentValue">
            <v-select
              v-model="requiredParentValue"
              prepend-inner-icon="$mdi-asterisk"
              label="Required Parent Value"
              outlined
              dense
              :items="parentQuestionOptions"
              :disabled="!disabledFields"
              :success="requiredParentValueValidation.success"
              :error-messages="requiredParentValueValidation.errorMessages"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="!isDocusignField">
        <v-textarea
          v-model="verbatimQuestion"
          prepend-inner-icon="$mdi-help-box"
          label="Verbatim Question"
          outlined
          dense
          :disabled="!disabledFields"
          :success="verbatimQuestionValidation.success"
          :error-messages="verbatimQuestionValidation.errorMessages"
        />
      </v-col>
    </template>
    <v-col cols="12" v-if="showQuoteAndApplyType">
      <v-select
        v-model="quoteAndApplyType"
        prepend-inner-icon="$mdi-wizard-hat"
        outlined
        dense
        label="Quote & Apply Type"
        :items="quoteAndApplyFieldTypes"
        :disabled="!disabledFields"
        :success="quoteAndApplyTypeValidation.success"
        :error-messages="quoteAndApplyTypeValidation.errorMessages"
      />
    </v-col>
    <template v-if="showFieldOptions">
      <v-col cols="12" align="start">
        <form-mapping-form-aql-field-options
          :key="quoteAndApplyType"
          :form-id="formId"
          :readonly="readonlyFieldOptions"
          :readonly-virtual="!disabledFields"
          :show-text="requireFieldOptionsText"
          :success="fieldOptionsValidation.success"
          :error-messages="fieldOptionsValidation.errorMessages"
          @input="currentFieldHasChanges = true"
        />
      </v-col>
      <v-col cols="12" v-if="displayRequiredValue">
        <v-select
          v-model="requiredValue"
          prepend-inner-icon="$mdi-view-list-outline"
          item-text="value"
          label="Required Value"
          outlined
          dense
          clearable
          :disabled="!disabledFields"
          :items="fieldOptions"
          :success="
            requiredValueValidation.success &&
            (Boolean(requiredValue) || requiredValue === 0)
          "
          :error-messages="requiredValueValidation.errorMessages"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import isEqual from "lodash/isEqual";
import { APPLICATION_QUESTION } from "@/constants/search-type.constants";
import { parseErrorMessage, uuidv4 } from "@/util/helpers";

import IntegerInput from "@/components/shared/IntegerInput.vue";
import ApplicationQuestionSearch from "@/components/shared/ApplicationQuestionSearch.vue";
import FormMappingFormAqlFieldOptions from "@/components/form-mapping/FormMappingFormAqlFieldOptions.vue";

import { useFormMappingStore } from "@/stores/form-mapping";
import { storeToRefs, mapActions } from "pinia";

import { ref } from "vue";
import {
  AqlField,
  NOT_ON_PDF_OPTION_TYPE,
  RADIO_OPTION_DIAMETER,
  RADIO_OPTION_TYPE,
  SELECT_OPTION_TYPE,
  DATE_OPTION_TYPE,
  TEXT_OPTION_TYPE,
  textOptions,
  quoteAndApplyFieldTypes,
  docusignRequiredOf as DOCUSIGN_REQUIRED_OF,
  insuredIndexOptions as INSURED_INDEX_OPTIONS,
  CHECKBOX_OPTION_TYPE
} from "@/factories/FormMappingFactory";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  components: {
    ApplicationQuestionSearch,
    FormMappingFormAqlFieldOptions,
    IntegerInput
  },
  props: {
    formId: [String, Number],
    readonly: Boolean,
    isCopyingForm: Boolean,
    sampleId: [Number, String]
  },
  setup({ formId }) {
    const formStore = useFormMappingStore(formId);
    const {
      isContracting,
      activeField,
      fields,
      currentFieldHasChanges,
      currentFieldIsValid,
      currentPage,
      viewport
    } = storeToRefs(formStore);
    return {
      currentPage,
      viewport,
      isContracting,
      activeField,
      fields,
      APPLICATION_QUESTION,
      textOptions,
      currentFieldHasChanges,
      currentFieldIsValid,
      DOCUSIGN_REQUIRED_OF,
      INSURED_INDEX_OPTIONS,
      updateApplicationQuestionLink: formStore.updateApplicationQuestionLink,
      saving: ref(false),
      isMounted: ref(false)
    };
  },
  created() {
    this.$v.$touch();
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  watch: {
    $v: {
      deep: true,
      handler(v) {
        this.currentFieldIsValid = !v.$invalid;
      }
    }
  },
  computed: {
    showDocusignField() {
      return this.pdfFieldType !== NOT_ON_PDF_OPTION_TYPE;
    },
    readonlyFieldOptions() {
      return (
        !this.disabledFields ||
        [RADIO_OPTION_TYPE].includes(this.quoteAndApplyType)
      );
    },
    showInsuredIndex() {
      return !this.isDocusignField && this.isVerbatimQuestion;
    },
    showTiaField() {
      return !this.isDocusignField && !this.isContracting;
    },
    showQuoteAndApplyType() {
      if (this.isDocusignField) return false;
      return this.isVerbatimQuestion && !this.isPdfCheckbox;
    },
    showUnlinkButton() {
      return !this.readonly && Boolean(this.referenceField);
    },
    disabledFields() {
      return !this.readonly && !this.referenceField;
    },
    showSaveButton() {
      return !this.readonly;
    },
    requireFieldOptionsText() {
      return (
        this.isVerbatimQuestion &&
        this.quoteAndApplyType !== "radio" &&
        !this.isDocusignField
      );
    },
    applicationQuestion: {
      get() {
        return this.activeField.applicationQuestion;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged =
          this.activeField.applicationQuestion?.id === val?.id;
        if (isUnchanged) return;
        this.currentFieldHasChanges = true;
        this.$set(this.activeField, "applicationQuestion", val);
        if (val?.application_field_type) {
          this.pdfFieldType = val?.application_field_type;
        }
      }
    },
    question: {
      get() {
        return this.activeField.question;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged = this.activeField.question === val;
        if (isUnchanged) return;
        this.currentFieldHasChanges = true;
        this.$set(this.activeField, "question", val);
      }
    },
    smartApplicationQuestion: {
      get() {
        return this.activeField.smartApplicationQuestion;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged =
          this.activeField.smartApplicationQuestion?.id === val?.id;
        if (isUnchanged) return;
        this.currentFieldHasChanges = true;
        this.$set(this.activeField, "smartApplicationQuestion", val);
      }
    },
    referenceField: {
      get() {
        return this.activeField.referenceField;
      },
      set(value) {
        this.activeField.referenceField = value;
        this.currentFieldHasChanges = true;
      }
    },
    isTiaField: {
      get() {
        return this.activeField.isTiaField;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged = this.activeField.isTiaField === val;
        if (isUnchanged) return;
        this.currentFieldHasChanges = true;
        this.activeField.isTiaField = val;
      }
    },
    isDocusignField: {
      get() {
        return this.activeField.isDocusignField;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged = this.activeField.isDocusignField === val;
        if (isUnchanged) return;
        this.currentFieldHasChanges = true;
        this.activeField.isDocusignField = val;
      }
    },
    isDocusignRequired: {
      get() {
        return this.activeField.isDocusignRequired;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged = this.activeField.isDocusignRequired === val;
        if (isUnchanged) return;
        this.currentFieldHasChanges = true;
        this.activeField.isDocusignRequired = val;
      }
    },
    docusignRequiredOf: {
      get() {
        return this.activeField.docusignRequiredOf;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged = this.activeField.docusignRequiredOf === val;
        if (isUnchanged) return;
        this.currentFieldHasChanges = true;
        this.activeField.docusignRequiredOf = val;
      }
    },
    pdfFieldType: {
      get() {
        return this.activeField.pdfFieldType;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged = this.activeField.pdfFieldType === val;
        if (isUnchanged) return;
        this.pdfFieldTypeConversion(val);
        this.currentFieldHasChanges = true;
        this.activeField.pdfFieldType = val;
      }
    },
    additionalForm: {
      get() {
        return this.activeField.additionalForm;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged = this.activeField.additionalForm === val;
        if (isUnchanged) return;
        this.currentFieldHasChanges = true;
        this.activeField.additionalForm = val;
      }
    },
    fieldOptions() {
      if (!this.activeField.coordinates?.length) return [];
      if (this.activeField.pdfFieldType === TEXT_OPTION_TYPE) {
        const coordinate = this.activeField.coordinates[0];
        return coordinate.options || [];
      }
      return this.activeField.coordinates.map(({ text, value }) => ({
        text,
        value
      }));
    },
    requiredValue: {
      get() {
        return this.activeField.requiredValue;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged = this.activeField.requiredValue === val;
        if (isUnchanged) return;
        this.currentFieldHasChanges = true;
        this.activeField.requiredValue = val;
      }
    },
    obscureBackground: {
      get() {
        return this.activeField.obscureBackground;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged = this.activeField.obscureBackground === val;
        if (isUnchanged) return;
        this.currentFieldHasChanges = true;
        this.activeField.obscureBackground = val;
      }
    },
    comb: {
      get() {
        return this.activeField.comb;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged = this.activeField.comb === val;
        if (isUnchanged) return;
        this.currentFieldHasChanges = true;
        this.activeField.comb = val;
      }
    },
    parentQuestion: {
      get() {
        return this.activeField.parentQuestion;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged = this.activeField.parentQuestion === val;
        if (isUnchanged) return;
        this.currentFieldHasChanges = true;
        this.activeField.parentQuestion = val;
      }
    },
    requiredParentValue: {
      get() {
        return this.activeField.requiredParentValue;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged = isEqual(this.activeField.requiredParentValue, val);
        if (isUnchanged) return;
        this.currentFieldHasChanges = true;
        this.activeField.requiredParentValue = val;
      }
    },
    quoteAndApplyType: {
      get() {
        return this.activeField.quoteAndApplyType;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged = this.activeField.quoteAndApplyType === val;
        if (isUnchanged) return;

        this.currentFieldHasChanges = true;
        if (this.pdfFieldType === NOT_ON_PDF_OPTION_TYPE) {
          const shouldAddVirtual = [
            RADIO_OPTION_TYPE,
            SELECT_OPTION_TYPE,
            CHECKBOX_OPTION_TYPE
          ].includes(val);
          if (shouldAddVirtual) {
            this.activeField.coordinates = [new AqlField({ virtual: true })];
          } else {
            this.activeField.coordinates = [];
          }
        }
        this.activeField.quoteAndApplyType = val;
      }
    },
    verbatimQuestion: {
      get() {
        return this.activeField.verbatimQuestion;
      },
      set(val) {
        if (!this.isMounted) return;
        const isUnchanged = isEqual(this.activeField.verbatimQuestion, val);
        if (isUnchanged) return;
        this.currentFieldHasChanges = true;
        this.activeField.verbatimQuestion = val;
      }
    },
    insuredIndex: {
      get() {
        return this.activeField.insuredIndex;
      },
      set(val) {
        if (!this.isMounted) return;

        const isUnchanged = this.activeField.insuredIndex === val;
        if (isUnchanged) return;

        this.currentFieldHasChanges = true;
        this.activeField.insuredIndex = val;
      }
    },
    applicationQuestionValidation() {
      const success = !this.$v.applicationQuestion.$invalid;
      const errorMessages = [];
      if (!this.$v.applicationQuestion.$dirty)
        return { success, errorMessages };
      if (!this.$v.applicationQuestion.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    fieldOptionsValidation() {
      const success = !this.$v.fieldOptions.$invalid;
      const errorMessages = [];
      if (!this.$v.fieldOptions.$dirty) return { success, errorMessages };
      if (!this.$v.fieldOptions.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    requiredParentValueValidation() {
      const success = !this.$v.requiredParentValue.$invalid;
      const errorMessages = [];
      if (!this.$v.requiredParentValue.$dirty)
        return { success, errorMessages };
      if (!this.$v.requiredParentValue.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    quoteAndApplyTypeValidation() {
      const success = !this.$v.quoteAndApplyType.$invalid;
      const errorMessages = [];
      if (!this.$v.quoteAndApplyType.$dirty) return { success, errorMessages };
      if (!this.$v.quoteAndApplyType.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    verbatimQuestionValidation() {
      const success = !this.$v.verbatimQuestion.$invalid;
      const errorMessages = [];
      if (!this.$v.verbatimQuestion.$dirty) return { success, errorMessages };
      if (!this.$v.verbatimQuestion.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    requiredValueValidation() {
      const success = !this.$v.requiredValue.$invalid;
      const errorMessages = [];
      if (!this.$v.requiredValue.$dirty) return { success, errorMessages };
      if (!this.$v.requiredValue.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    isVerbatimQuestion() {
      return (
        this.activeField?.applicationQuestion?.name === "Verbatim Question"
      );
    },
    showRequiredParentValue() {
      return Boolean(this.parentApplicationQuestionLink);
    },
    validSmartApplicationQuestion() {
      return Boolean(this.activeField?.smartApplicationQuestion?.id);
    },
    disabledRequiredOf() {
      if (!this.activeField.applicationQuestion?.name) return false;

      let requiredOf = "";
      if (this.activeField.applicationQuestion?.requiredOf) {
        requiredOf = JSON.parse(
          this.activeField.applicationQuestion?.requiredOf
        );
      }

      return this.activeField.docusignRequiredOf === requiredOf;
    },
    parentApplicationQuestionLink() {
      return this.fields.by_id[this.parentQuestion];
    },
    parentQuestionOptions() {
      if (!this.parentApplicationQuestionLink) return [];
      if (!this.parentApplicationQuestionLink.coordinates?.length) return [];
      if (
        this.parentApplicationQuestionLink.pdfFieldType === TEXT_OPTION_TYPE
      ) {
        const coordinate = this.parentApplicationQuestionLink.coordinates[0];
        return coordinate.options || [];
      }
      return this.parentApplicationQuestionLink.coordinates.map(
        ({ text, value }) => ({ text, value })
      );
    },
    isPdfCheckbox() {
      return this.pdfFieldType === CHECKBOX_OPTION_TYPE;
    },
    isQnaCheckbox() {
      return this.quoteAndApplyType === CHECKBOX_OPTION_TYPE;
    },
    isPdfRadio() {
      return this.pdfFieldType === RADIO_OPTION_TYPE;
    },
    isQnaRadio() {
      return this.quoteAndApplyType === RADIO_OPTION_TYPE;
    },
    isQnaSelect() {
      return this.quoteAndApplyType === SELECT_OPTION_TYPE;
    },
    isPdfNotType() {
      return this.pdfFieldType === NOT_ON_PDF_OPTION_TYPE;
    },
    isPdfTextType() {
      return this.pdfFieldType === TEXT_OPTION_TYPE;
    },
    showFieldOptions() {
      if (!this.activeField.applicationQuestion?.id) return false;

      const pdfTypeNeedsOptions = this.isPdfRadio || this.isPdfCheckbox;

      const qnaTypeNeedsOptions =
        this.isQnaRadio || this.isQnaCheckbox || this.isQnaSelect;

      if (this.showQuoteAndApplyType && !this.activeField.quoteAndApplyType) {
        return false;
      }
      if (this.isPdfNotType) return qnaTypeNeedsOptions;

      return pdfTypeNeedsOptions || qnaTypeNeedsOptions;
    },
    showAdditionalForm() {
      return (
        this.isPdfNotType &&
        !this.isContracting &&
        !this.activeField.isDocusignField
      );
    },
    quoteAndApplyFieldTypes() {
      if (this.isPdfCheckbox || this.isPdfRadio) {
        return quoteAndApplyFieldTypes.filter(({ value }) =>
          [SELECT_OPTION_TYPE, RADIO_OPTION_TYPE].includes(value)
        );
      }
      if (this.isPdfTextType) {
        return quoteAndApplyFieldTypes.filter(({ value }) =>
          [SELECT_OPTION_TYPE, DATE_OPTION_TYPE, TEXT_OPTION_TYPE].includes(
            value
          )
        );
      }
      return quoteAndApplyFieldTypes;
    },
    parentFields() {
      const parentFields = [];
      this.fields.ids.forEach(id => {
        if (id === this.activeField.id) return;
        parentFields.push(this.fields.by_id[id]);
      });
      return parentFields;
    },
    displayRequiredValue() {
      return !this.isDocusignField && this.isVerbatimQuestion;
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, [
      "showErrorSnackbar",
      "showSuccessSnackbar"
    ]),
    clearDocusignDependentFields() {
      this.docusignRequiredOf = null;
      this.isDocusignRequired = null;
    },
    pdfFieldTypeConversion(newType) {
      const coordCount = this.activeField.coordinates.length;
      const oldType = this.activeField.pdfFieldType;

      // we don't want to override the required yes/no values
      if (this.isQnaCheckbox || this.isQnaRadio) {
        if ([CHECKBOX_OPTION_TYPE, RADIO_OPTION_TYPE].includes(newType)) {
          //we want to give it positional data
          this.activeField.coordinates.forEach((_, index) => {
            this.activeField.coordinates[index].x =
              5 + RADIO_OPTION_DIAMETER * index + 1;
            this.activeField.coordinates[index].y = this.viewport.height - 55;
          });
        } else if (newType === NOT_ON_PDF_OPTION_TYPE) {
          // we want to remove positional data
          this.activeField.coordinates.forEach((_, index) => {
            delete this.activeField.coordinates[index].x;
            delete this.activeField.coordinates[index].y;
          });
        }
        return;
      }

      // yuck...
      if ([CHECKBOX_OPTION_TYPE, RADIO_OPTION_TYPE].includes(newType)) {
        // clear coordinates and create new box coordinate
        this.activeField.coordinates.splice(0, coordCount);
      } else if (newType === TEXT_OPTION_TYPE) {
        this.activeField.coordinates.splice(0, coordCount);
        this.$nextTick(() => {
          this.activeField.coordinates.push({
            page: this.currentPage,
            x: 5,
            y: this.viewport.height - 55,
            width: 100,
            height: 50,
            order: this.activeField.coordinates.length + 1,
            uuid: uuidv4()
          });
        });
      } else if (
        newType === NOT_ON_PDF_OPTION_TYPE &&
        [CHECKBOX_OPTION_TYPE, RADIO_OPTION_TYPE].includes(oldType)
      ) {
        this.activeField.coordinates.forEach((_, index) => {
          delete this.activeField.coordinates[index].x;
          delete this.activeField.coordinates[index].y;
        });
        // clear positional data from coordinates
      } else if (
        newType === NOT_ON_PDF_OPTION_TYPE &&
        oldType === TEXT_OPTION_TYPE
      ) {
        this.activeField.coordinates.splice(0, coordCount);
      }
    },
    fieldOptionsRequirements() {
      const validator = value => {
        if (!value?.length) return false;
        return value.every(val => {
          const hasValue = Boolean(val.value) || val.value === 0;
          const hasText = Boolean(val.text);
          if (this.requireFieldOptionsText) return hasValue && hasText;
          return hasValue;
        });
      };
      if (!this.showFieldOptions) return true;
      let validateCoordinates = this.isPdfRadio || this.isPdfCheckbox;

      if (
        this.activeField.pdfFieldType === NOT_ON_PDF_OPTION_TYPE &&
        (this.isQnaRadio || this.isQnaSelect || this.isQnaCheckbox)
      ) {
        validateCoordinates = true;
      }
      if (validateCoordinates) {
        return validator(this.activeField.coordinates);
      }
      return validator(this.activeField.coordinates[0].options);
    },
    requiredIfNotOnPdfOrIsChild(value, parent) {
      const hasParent = Boolean(parent.parentQuestion);
      return !hasParent || Boolean(value);
    },
    requiredIfVerbatimAndNotDocusign(value, parent) {
      if (!this.showQuoteAndApplyType && !this.isPdfCheckbox) return true;
      if (this.isDocusignField) return true;
      return parent.applicationQuestion?.name === "Verbatim Question"
        ? Boolean(value) || value === 0
        : true;
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.saving = true;
      try {
        await this.updateApplicationQuestionLink(this.activeField);
        this.showSuccessSnackbar({
          message: "Saved Application Question Link"
        });
        this.currentFieldHasChanges = false;
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.saving = false;
      }
    }
  },
  validations() {
    return {
      field: {
        required: true //for access in validator
      },
      isTiaField: {
        required: true //for access in validator
      },
      applicationQuestion: {
        required: val => Boolean(val?.id)
      },
      requiredValue: {
        required: (val, vm) =>
          !vm.isTiaField ||
          !this.displayRequiredValue ||
          Boolean(val) ||
          val === 0
      },
      question: {
        required: false
      },
      fieldOptions: {
        required: this.fieldOptionsRequirements
      },
      parentQuestion: {
        required: true //for access in validator
      },
      requiredParentValue: {
        required: this.requiredIfNotOnPdfOrIsChild
      },
      quoteAndApplyType: {
        required: this.requiredIfVerbatimAndNotDocusign
      },
      verbatimQuestion: {
        required: this.requiredIfVerbatimAndNotDocusign
      }
    };
  }
};
</script>
