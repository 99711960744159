import AddressFactory from "@/factories/AddressFactory";
import CarrierFactory from "@/factories/CarrierFactory";
import NoteFactory from "@/factories/NoteFactory";

export const DEPARTMENTS = [
  "Case Management",
  "Commissions",
  "Customer Service",
  "Licensing",
  "IT",
  "Management",
  "Marketing",
  "New Application Email",
  "Sales",
  "Underwriting"
];

export default function PersonnelFactory(model = {}) {
  let notes = [];
  if (model?.notes?.length) {
    notes = model.notes.map(n => new NoteFactory(n));
  }
  return {
    avatar: model?.avatar || null,
    homeAddress: new AddressFactory({
      ...model?.homeAddress,
      address_type: "home"
    }),
    businessAddress: new AddressFactory({
      address_type: "business",
      ...model?.businessAddress
    }),
    carrier: new CarrierFactory(model?.carrier),
    department: model?.department || null,
    email: model?.email || null,
    firstName: model?.firstName || null,
    lastName: model?.lastName || null,
    notes,
    phoneFax: model?.phoneFax || null,
    phoneMobile: model?.phoneMobile || null,
    phoneWork: model?.phoneWork || null,
    phoneWorkExtension: model?.phoneWorkExtension || null,
    title: model?.title || null,
    id: model?.id || null,

    get name() {
      return this.firstName + " " + this.lastName;
    },

    setFromRequest(model = {}) {
      this.id = model?.id;
      this.avatar = model?.avatar_url;
      this.department = model?.department;
      this.email = model?.email;
      this.firstName = model?.first_name;
      this.lastName = model?.last_name;
      this.phoneFax = model?.phone_fax;
      this.phoneMobile = model?.phone_mobile;
      if (model?.phone_work) {
        const [phoneWork, extension] = model.phone_work.split("x");
        this.phoneWork = phoneWork;
        this.phoneWorkExtension = extension;
      }
      this.title = model?.title;

      this.homeAddress = new AddressFactory();
      this.homeAddress.setFromRequest(model?.addresses?.home);

      this.businessAddress = new AddressFactory();
      this.businessAddress.setFromRequest(model?.addresses?.business);

      this.carrier = new CarrierFactory();
      this.carrier.setFromRequest(model?.carrier);

      this.notes.splice(0, this.notes.length);
      if (model?.notes?.length) {
        model.notes.forEach(rawNote => {
          const note = new NoteFactory();
          note.setFromRequest(rawNote);
          this.notes.push(note);
        });
      }
    },

    generateAttributeMap() {
      let phone_work = this.phoneWork;
      if (this.phoneWorkExtension) {
        phone_work += `x${this.phoneWorkExtension}`;
      }
      return {
        firstName: { first_name: this.firstName || null },
        lastName: { last_name: this.lastName || null },
        email: { email: this.email || null },
        title: { title: this.title || null },
        carrier: { carrier_id: this.carrier.id || null },
        department: { department: this.department || null },

        phoneWork: { phone_work: phone_work || null },
        phoneWorkExtension: { phone_work: phone_work || null },

        phoneMobile: { phone_mobile: this.phoneMobile || null },
        home: {
          address_type: "home",
          id: this.homeAddress.id || null,
          street_address: this.homeAddress.street_address || null,
          city: this.homeAddress.city || null,
          state: this.homeAddress.state || null,
          zip: this.homeAddress.zip || null
        },
        business: {
          address_type: "business",
          id: this.businessAddress.id || null,
          street_address: this.businessAddress.street_address || null,
          city: this.businessAddress.city || null,
          state: this.businessAddress.state || null,
          zip: this.businessAddress.zip || null
        }
      };
    },

    getAttributeRequestValue(attribute) {
      const attributeMap = this.generateAttributeMap();
      let attributes = {};
      attribute.forEach(subAttribute => {
        attributes = { ...attributes, ...attributeMap[subAttribute] };
      });
      return attributes;
    },

    toCreateRequest() {
      return {
        ...this.getAttributeRequestValue([
          "firstName",
          "lastName",
          "email",
          "title",
          "carrier",
          "department",
          "phoneWork",
          "phoneMobile"
        ]),
        addresses_attributes: [
          this.getAttributeRequestValue(["home"]),
          this.getAttributeRequestValue(["business"])
        ]
      };
    }
  };
}
