<template>
  <v-card>
    <v-card-title v-if="editing"> Edit Informal </v-card-title>
    <v-card-title v-else> Add Informal </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <carrier-search
            v-model="informalOffer.carrier"
            data-testid="informal-carrier"
            :disabled="editing"
            :success="carrierValidation.success"
            :error-messages="carrierValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="informalOffer.underwriter"
            prepend-inner-icon="$mdi-heart-pulse"
            outlined
            dense
            auto-select-first
            data-testid="informal-underwriter"
            label="Select Underwriter"
            item-text="name"
            return-object
            :items="underwriters"
            :loading="loadingUnderwriters"
            :success="Boolean(informalOffer.underwriter?.name)"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="informalOffer.name"
            data-lpignore="true"
            prepend-inner-icon="$mdi-pound"
            outlined
            dense
            data-testid="informal-name"
            label="Policy Number"
            :success="nameValidation.success"
            :error-messages="nameValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="informalOffer.offer"
            data-testid="informal-offer"
            data-lpignore="true"
            prepend-inner-icon="$mdi-cube"
            outlined
            dense
            label="Offer"
            :success="Boolean(informalOffer.offer)"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="informalOffer.underwritingRationale"
            prepend-inner-icon="$mdi-clipboard"
            data-testid="informal-underwriting-rationale"
            outlined
            dense
            label="Underwriting Rationale"
            :success="Boolean(informalOffer.underwritingRationale)"
          />
        </v-col>
        <v-col cols="12">
          <div class="checkbox-width">
            <v-checkbox
              v-model="sendEmail"
              class="mt-0"
              data-testid="send-email"
              hide-details
              dense
              label="Send E-mail"
              :success="sendEmail"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        :disabled="loading"
        outlined
        color="grey"
        class="text-none"
        @click="dialog.closeDialog()"
      >
        Close
      </v-btn>
      <v-btn
        v-if="editing"
        :loading="loading"
        color="primary"
        class="text-none"
        data-testid="update-informal"
        @click="updateInformal"
      >
        Update Informal
      </v-btn>
      <v-btn
        v-else
        :loading="loading"
        color="primary"
        class="text-none"
        data-testid="create-informal"
        @click="createInformal"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import CarrierSearch from "@/components/shared/CarrierSearch.vue";

import {
  InformalOffer,
  InformalOfferToRequest
} from "@/factories/InformalOffer";
import { fetchCarrierPersonnel } from "@/api/personnel.service";
import {
  computedValidation,
  parseErrorMessage,
  someTextValidator
} from "@/util/helpers";
import { createInformalOffer, updateInformalOffer } from "@/api/cases.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { ref, watch, defineProps } from "vue";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  offer: {
    type: Object,
    required: false,
    default: () => {}
  },
  caseId: {
    type: [String, Number],
    required: true
  }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const loading = ref(false);
const informalOffer = ref(InformalOffer(props.offer));
const sendEmail = ref(false);
const underwriters = ref([]);
const loadingUnderwriters = ref(false);
const editing = Boolean(props.offer?.id);

const v$ = useVuelidate(
  {
    informalOffer: {
      carrier: { required: v => Boolean(v?.id) },
      name: { required: v => someTextValidator(true, v, 2) }
    }
  },
  {
    informalOffer
  },
  {
    $autoDirty: true,
    $scope: null
  }
);

const carrierValidation = computedValidation(v$.value.informalOffer.carrier, {
  required: "Required"
});

const nameValidation = computedValidation(v$.value.informalOffer.name, {
  required: "Required"
});

watch(() => informalOffer.value.carrier, getCarrierPersonnel, { deep: true });

async function getCarrierPersonnel() {
  loadingUnderwriters.value = true;
  try {
    const personnels = await fetchCarrierPersonnel(
      informalOffer.value.carrier.id
    );
    underwriters.value.push(...personnels);
    underwriters.value.sort((a, b) => a.name.localeCompare(b.name));
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingUnderwriters.value = false;
  }
}
async function updateInformal() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  loading.value = true;
  try {
    const updatedOffer = await updateInformalOffer(
      props.caseId,
      informalOffer.value.id,
      InformalOfferToRequest(informalOffer.value, sendEmail.value)
    );

    dialog.closeDialog({ informalOffer: updatedOffer });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
async function createInformal() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  loading.value = true;
  try {
    const newOffer = await createInformalOffer(
      props.caseId,
      InformalOfferToRequest(informalOffer.value, sendEmail.value)
    );

    dialog.closeDialog({ informalOffer: newOffer });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

if (editing) getCarrierPersonnel();
</script>
