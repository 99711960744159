<template>
  <v-card color="section" tile>
    <v-col cols="12">
      <v-sheet rounded class="pa-3">
        <h3 class="ml-3">
          Analytics
          <a href="https://docs.back9ins.com/#analytics" target="_blank">
            <v-icon color="primary">$mdi-information</v-icon>
          </a>
        </h3>
        <v-row class="pa-3" dense>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="googleAnalyticsId"
              data-lpignore="true"
              prepend-inner-icon="$mdi-chart-areaspline"
              outlined
              dense
              label="Google Analytics ID"
              persistent-hint
              hint=" "
              data-testid="analytics-ga"
              :success="Boolean(googleAnalyticsId)"
              @input="saveAttribute('googleAnalyticsId')"
            >
              <template #message>
                Report Quote & Apply traffic to Google Analytics.
                <a href="https://docs.back9ins.com"> Click for more details</a>
              </template>
              <template #append-outer>
                <active-save-indicator
                  class="ml-2"
                  :controller="savingBuffer.googleAnalyticsId"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="googleTagManagerId"
              data-lpignore="true"
              prepend-inner-icon="$mdi-tag"
              outlined
              dense
              label="Google Tag Manager ID"
              data-testid="analytics-gtm"
              :success="Boolean(googleTagManagerId)"
              @input="saveAttribute('googleTagManagerId')"
            >
              <template #append-outer>
                <active-save-indicator
                  class="ml-2"
                  :controller="savingBuffer.googleTagManagerId"
                />
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
    <approved-domain-statistics />
  </v-card>
</template>

<script>
import ApprovedDomainStatistics from "@/components/approved-domain/ApprovedDomainStatistics.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { mapActions, mapWritableState } from "pinia";
export default {
  components: { ApprovedDomainStatistics, ActiveSaveIndicator },
  mixins: [ActiveSaveMixin],
  data() {
    const savingBuffer = this.generateSavingBuffer({
      items: ["googleTagManagerId", "googleAnalyticsId"]
    });

    return { savingBuffer, isMounted: false };
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  computed: {
    ...mapWritableState(useApprovedDomainView, [
      "googleTagManagerId",
      "googleAnalyticsId"
    ])
  },
  methods: {
    ...mapActions(useApprovedDomainView, ["updateApprovedDomain"]),
    saveAttribute(attribute, timeout = 300) {
      if (!this.isMounted) return;
      if (this.savingBuffer[attribute].timer)
        clearTimeout(this.savingBuffer[attribute].timer);
      this.savingBuffer[attribute].timer = setTimeout(() => {
        this.updateAttributeWrapper(
          () => this.updateApprovedDomain(attribute),
          this.savingBuffer[attribute]
        );
      }, timeout);
    }
  }
};
</script>

<style></style>
