<template>
  <v-data-table
    :headers="headers"
    :items="items"
    style="background-color: transparent"
    :options="tableOptions"
  >
    <template #top>
      <v-row
        class="py-3"
        align="center"
        :class="{ 'px-3': evenAddAccessPadding, 'px-6': !evenAddAccessPadding }"
      >
        <slot name="top" />
        <v-spacer v-if="$slots.top" />
        <advisor-search
          v-if="searchType === 'advisor'"
          v-model="access"
          hide-details
          data-testid="access-advisor-search"
        />
        <audience-search
          v-if="searchType === 'audience'"
          v-model="access"
          hide-details
          :carrierId="carrierId"
          data-testid="access-audience-search"
        />
        <v-btn
          class="text-none ml-2"
          data-testid="access-add-advisor"
          :loading="addingAccess"
          :color="buttonColor"
          @click="addAccess"
        >
          <v-icon>$mdi-plus</v-icon> {{ buttonText }}
        </v-btn>
      </v-row>
    </template>
    <template #[`item.name`]="{ item }">
      <router-link
        v-if="
          (nameLink || item.additional.routerLink) &&
          !item.additional.tableHelpers.deleting &&
          !item.additional.disabled
        "
        :to="item.additional.routerLink || nameLink(item)"
        data-testid="access-name"
      >
        {{ item.name }}
      </router-link>
      <template v-else>
        {{ item.name }}
      </template>
    </template>
    <template
      v-for="checkbox in checkboxes"
      #[`item.${checkbox.value}`]="{ item }"
    >
      <td
        style="width: 4rem !important"
        :key="getSavingBufferKey(item, checkbox.value)"
      >
        <v-checkbox
          v-if="!checkbox.if || checkbox.if(item)"
          v-model="item[checkbox.value]"
          :data-testid="`access-checkbox-${checkbox.text}`"
          :disabled="
            item.additional.tableHelpers.deleting || item.additional.disabled
          "
          @change="updateAttribute(item, checkbox.value)"
        >
          <template #label>
            <active-save-indicator
              :controller="
                savingBuffer[getSavingBufferKey(item, checkbox.value)]
              "
            />
          </template>
        </v-checkbox>
      </td>
    </template>

    <template #[`item.actions`]="{ item }">
      <v-btn
        v-if="
          (!deletable || (deletable && deletable(item))) &&
          !item.additional.disabled
        "
        icon
        data-testid="access-delete"
        :loading="item.additional.tableHelpers.deleting"
        @click="deleteAccess(item)"
      >
        <v-icon color="error"> $mdi-delete </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";

import AudienceSearch from "@/components/shared/AudienceSearch.vue";
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";
import Table from "@/classes/data-table/Table";
import TableHeader from "@/classes/data-table/TableHeader";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import TableOptions from "@/classes/data-table/TableOptions";
import { isRef } from "vue";

export default {
  name: "AccessHelper",
  mixins: [ActiveSaveMixin, GenericTableMixin],
  components: {
    AudienceSearch,
    AdvisorSearch,
    ActiveSaveIndicator
  },
  props: {
    returnAdditional: Boolean,
    value: Array,
    checkboxes: Array,
    originalAccesses: Array,
    type: String,
    updateFunc: Function,
    deleteFunc: Function,
    addFunc: Function,
    nameLink: Function,
    evenAddAccessPadding: Boolean,
    carrierId: {
      required: false,
      type: Number
    },
    buttonColor: {
      required: false,
      type: String,
      default: "accent"
    },
    buttonText: {
      required: false,
      type: String,
      default: "Add Access"
    },
    deletable: Function
  },
  data() {
    let accesses = [];
    if (this.originalAccesses?.length) {
      accesses.push(...cloneDeep(this.originalAccesses));
    }
    if (this.value?.length) {
      if (isRef(this.value)) accesses.push(...cloneDeep(this.value.value));
      else accesses.push(...cloneDeep(this.value));
    }
    const accessesHeaders = [
      new TableHeader({
        text: "Advisor",
        value: "name",
        map: "name",
        ...TableHeader.IS_SORTABLE
      })
    ];
    this.checkboxes.forEach(checkbox => {
      accessesHeaders.push(
        new TableHeader({
          text: checkbox.text,
          value: checkbox.value,
          map: checkbox.value
        })
      );
    });

    accessesHeaders.push(
      new TableHeader({ text: "Actions", value: "actions", map: "actions" })
    );

    const accessTable = new Table({
      text: "Access",
      headers: accessesHeaders,
      rawItems: accesses
    });

    const tableOptions = new TableOptions(["name"], [false]);
    return {
      access: null,
      addingAccess: false,
      accessTable,
      savingBuffer: {},
      accesses,
      tableOptions
    };
  },
  created() {
    this.updateSavingBuffer();
  },
  watch: {
    value: {
      deep: true,
      handler(newVal) {
        const newStr = JSON.stringify(newVal);
        const oldStr = JSON.stringify(this.accesses);
        if (newStr.localeCompare(oldStr) === 0) return;
        this.accesses.splice(0, this.accesses.length);
        this.accesses.push(...cloneDeep(newVal));
      }
    },
    accesses: {
      deep: true,
      handler(val) {
        this.$emit("input", cloneDeep(val));
      }
    }
  },
  computed: {
    searchType() {
      if (["case", "appointment"].includes(this.type)) return "audience";
      return "advisor";
    },
    items() {
      return this.tableMap(this.accessTable.rawItems, this.accessTable.headers);
    },
    headers() {
      return this.accessTable.headers;
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, [
      "showSuccessSnackbar",
      "showErrorSnackbar"
    ]),
    updateSavingBuffer() {
      const savingBuffer = {};
      this.accesses.forEach(access => {
        this.checkboxes.forEach(checkbox => {
          savingBuffer[this.getSavingBufferKey(access, checkbox.value)] =
            this.activeSaveElementFactory();
        });
      });
      this.savingBuffer = savingBuffer;
    },
    async addAccess() {
      if (
        !this.access ||
        this.accesses.some(
          access =>
            `${this.access.id}` === `${access.id}` &&
            this.access.type === access.type
        )
      ) {
        return;
      }
      this.addingAccess = true;
      try {
        const access = await this.addFunc(this.access);
        this.showSuccessSnackbar({
          message: `Successfully added ${this.access.name}`
        });
        this.accesses.push(access);
        this.accesses.sort((a, b) => a.name.localeCompare(b.name));
        this.accessTable.rawItems = this.accesses;
        this.access = null;
        this.updateSavingBuffer();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
      } finally {
        this.addingAccess = false;
      }
    },
    async deleteAccess(item) {
      if (item.additional.disabled) return;
      item.additional.tableHelpers.deleting = true;
      //the func, the whole func, and nothing but the func
      let func = () => this.deleteFunc(item);
      if (this.returnAdditional) func = () => this.deleteFunc(item.additional);
      try {
        await func();
        const index = this.accesses.findIndex(
          ({ id }) => id === item.additional.id
        );
        if (index === -1) return;
        this.accesses.splice(index, 1);
      } catch (e) {
        this.showErrorSnackbar({
          message: parseErrorMessage(e),
          timeout: -1
        });
        console.error(e);
      } finally {
        item.additional.tableHelpers.deleting = false;
      }
    },
    updateAttribute(item, attribute) {
      if (item.additional.disabled) return;
      if (this.returnAdditional) item.additional[attribute] = item[attribute];
      else {
        const accessIndex = this.accesses.findIndex(
          a => a.id === item.additional.id
        );
        this.accesses[accessIndex][attribute] = item[attribute];
      }
      let func = () => this.updateFunc(item, attribute);
      if (this.returnAdditional) func = () => this.updateFunc(item.additional);
      setTimeout(() => {
        this.updateAttributeWrapper(
          func,
          this.savingBuffer[this.getSavingBufferKey(item, attribute)]
        );
      }, 20);
    },
    getSavingBufferKey(item, attribute) {
      const id = item?.additional?.id || item.id;
      return `${id} ${attribute}`;
    }
  }
};
</script>
