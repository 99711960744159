<template>
  <v-card flat tile>
    <v-card-title> Admin </v-card-title>
    <v-card-subtitle>
      <template v-if="props.readonly"> Administrative Settings </template>
      <template v-else> Access, Documents, and Logins </template>
    </v-card-subtitle>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-card flat color="section">
            <v-card-title> Access </v-card-title>
            <v-card-text>
              <admin-connections
                :readonly="props.readonly"
                :module="module"
                :is-viewing-self="isViewingSelf"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card flat color="section">
            <v-card-title> Logins </v-card-title>
            <v-card-text>
              <admin-logins
                :readonly="props.readonly || user.isGroupOne"
                :is-viewing-self="props.isViewingSelf"
                :module="module"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card flat color="section">
            <v-card-title> Documents </v-card-title>
            <v-card-text>
              <admin-documents :readonly="props.readonly" :module="module" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="user.isGroupTwoPlus && isAgent" cols="12">
          <v-card flat color="section">
            <v-card-title> Case Settings </v-card-title>
            <v-card-text>
              <admin-case-settings
                :readonly="props.readonly"
                :module="module"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card
            flat
            color="section"
            :style="`border: 1px solid ${errorColor} !important`"
          >
            <v-card-title> Danger Zone </v-card-title>
            <v-card-text>
              <admin-danger-zone :readonly="props.readonly" :module="module" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script setup>
import AdminDocuments from "@/components/settings/admin/AdminDocuments.vue";
import AdminConnections from "@/components/settings/admin/AdminConnections.vue";
import AdminLogins from "@/components/settings/admin/AdminLogins.vue";
import AdminDangerZone from "@/components/settings/admin/AdminDangerZone.vue";
import AdminCaseSettings from "@/components/settings/admin/AdminCaseSettings.vue";

import { AGENT_SETTINGS } from "@/stores/agent-settings";
import { defineProps, onMounted, defineEmits, computed } from "vue";
import { AGENCY_SETTINGS } from "@/stores/agency-settings";
import { useUserStore } from "@/stores/user";
import { useVuetify } from "@/composables/compatible.composables";
const props = defineProps({
  readonly: Boolean,
  isViewingSelf: Boolean,
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});
const user = useUserStore();

const isAgent = props.module === AGENT_SETTINGS;

const emit = defineEmits(["mounted"]);
onMounted(() => emit("mounted"));
const vuetify = useVuetify();
const errorColor = computed(() => {
  const theme = vuetify.theme.dark ? "dark" : "light";

  return vuetify.theme.themes[theme].error;
});
</script>
