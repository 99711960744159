<template>
  <div>
    <v-tabs v-model="activeTab">
      <v-tab v-for="item in items" :key="item.text + 'title'" class="text-none">
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab" touchless>
      <v-tab-item v-for="item in items" :key="item.text + 'body'">
        <component :is="item.component" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import AdvisorsDashboard from "@/components/advisors/AdvisorTable.vue";
import AppointmentsTable from "@/components/appointments/AppointmentsTable.vue";
import UnderwritingView from "@/views/Underwriting.vue";
import CasesTable from "@/components/cases/CasesTable.vue";
import TasksTable from "@/components/tasks/TasksTable.vue";

export default {
  name: "CaseManagement",
  components: {
    AdvisorsDashboard,
    AppointmentsTable,
    UnderwritingView,
    CasesTable,
    TasksTable
  },
  data() {
    return {
      activeTab: 0,
      items: [
        {
          text: "Advisors",
          component: "AdvisorsDashboard"
        },
        {
          text: "Appointments",
          component: "AppointmentsTable"
        },
        {
          text: "Cases",
          component: "CasesTable"
        },
        {
          text: "Tasks",
          component: "TasksTable"
        },
        {
          text: "Underwriting",
          component: "UnderwritingView"
        }
      ]
    };
  }
};
</script>
