<template>
  <v-card color="section" flat tile>
    <v-card-text>
      <v-data-table
        data-testid="appointment-sub-agents-table"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        class="transparent-data-table"
      >
        <template #[`item.code`]="{ item }">
          <router-link
            class="text-none"
            :to="{
              name: 'AppointmentView',
              params: {
                id: item.additional.appointmentId
              }
            }"
          >
            {{ item.code }}
          </router-link>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import { useTable } from "@/composables/table.composable";
import { storeToRefs } from "pinia";
import { useAppointmentView } from "@/stores/appointment-view";

const { agents } = storeToRefs(useAppointmentView());
const table = useTable({
  headers: [
    new TableHeader({
      text: "Advisor Name",
      value: "name",
      map: "name"
    }),
    new TableHeader({
      text: "Code",
      value: "code",
      map: "code"
    }),
    new TableHeader({
      text: "Comp Builder",
      value: "compBuilder",
      map: "compBuilder"
    }),
    new TableHeader({
      text: "Assignment",
      value: "assignmentName",
      map: "assignmentName"
    })
  ],
  items: agents
});
</script>
