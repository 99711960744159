import { getHttpClient } from "@/http-client";
import { setPayoutFromRequest } from "@/factories/ProductFactory";

const baseUrl = "api/boss/payouts";
export function updatePayout(id, params) {
  return getHttpClient().put(`${baseUrl}/${id}`, params);
}
export async function createPayout(params) {
  const { data } = await getHttpClient().post(baseUrl, params);
  return setPayoutFromRequest(data.payout);
}
export function deletePayout(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}
