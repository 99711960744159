import RoleFactory from "@/factories/RoleFactory";
import AddressFactory from "@/factories/AddressFactory";
export const INDIVIDUAL_INDICATE = "0";
export const ENTITY_INDICATE = "1";
export default function ContractPartyFactory(model = {}) {
  return {
    contractParty: model?.contractParty || null,
    avatar: model?.avatar || null,
    birthdate: model?.birthdate || null,
    name: model?.name || null,
    partyId: model?.partyId || null,
    roles: model?.roles || [],
    type: model?.type || null,
    addresses: model?.addresses || {},
    gender: model?.gender || "",

    setFromRequest(model = {}) {
      this.avatar = model?.avatar_url;
      this.birthdate = model?.birthdate;
      this.id = model?.id;
      this.name = model?.name;
      this.partyId = model?.party_id;
      this.email = model?.email;
      this.gender = model?.gender;
      this.primaryPhone = model?.primary_phone;

      this.type = {
        individual: "Individual",
        entity: "Entity"
      }[model?.type];

      this.roles = [];
      model?.roles?.forEach(rawRole => {
        const role = new RoleFactory();
        role.setFromRequest(rawRole);
        this.roles.push(role);
      });

      if (model?.address) {
        const address = new AddressFactory();
        address.setFromRequest(model?.address);
        this.addresses = [address];
      }
    }
  };
}
