<template>
  <v-card>
    <v-card-title> Edit Max Cases </v-card-title>
    <v-card-subtitle>
      Specify the maximum number of cases a user may have
    </v-card-subtitle>
    <v-card-text>
      <integer-input
        mask="####"
        v-model="maxCases"
        label="Max Cases"
        outlined
        dense
        clearable
        data-testid="max-cases-input"
        :success="maxCasesValidation.success"
        :error-messages="maxCasesValidation.errorMessages"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="text-none" outlined @click="dialog.closeDialog()">
        Cancel
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        data-testid="save-max-cases"
        :loading="loading"
        @click="saveMaxCases"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import IntegerInput from "@/components/shared/IntegerInput.vue";
import useVuelidate from "@vuelidate/core";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { ref, defineProps } from "vue";
import { updateEmployee } from "@/api/employees.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

const props = defineProps({
  employeeId: {
    type: Number,
    required: true
  },
  maxCases: {
    type: Number,
    default: null
  }
});
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const maxCases = ref(props.maxCases);
const loading = ref(false);
const v$ = useVuelidate(
  {
    maxCases: {
      validAmount: v => v === null || (v >= 1 && v <= 2000)
    }
  },
  {
    maxCases
  },
  {
    $autoDirty: true,
    $scope: null
  }
);

const maxCasesValidation = computedValidation(v$.value.maxCases, {
  validAmount: "Must be from 1-2,000 or empty"
});

async function saveMaxCases() {
  try {
    loading.value = true;
    let value = maxCases.value;
    if (value !== null) value = +value;
    await updateEmployee(props.employeeId, { max_cases: value });
    dialog.closeDialog({ maxCases: value });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
</script>
