<template>
  <v-form>
    <v-row dense>
      <v-col cols="12">
        <h3 class="text-h4 mb-3 font-weight-light">Referral Link</h3>
        <p class="text-body-1">
          Looking to invite many agents? Use your referral link!
        </p>

        <v-text-field
          data-lpignore="true"
          class="my-6 has-appended-button"
          outlined
          dense
          readonly
          data-testid="referral-link"
          label="Referral Link"
          :value="referralLink"
          :loading="fetchingReferralCode"
          ref="copy"
          @click="copy"
          hint=" "
          persistent-hint
        >
          <template #append>
            <v-btn text color="primary" class="text-none" @click="copy">
              Copy
            </v-btn>
          </template>
          <template #message="{ message }">
            <v-row class="ma-0">
              {{ message }} <v-spacer />
              <a @click="copyDialog" data-testid="show-embed-code">
                Want to embed a signup page on your website?
              </a>
            </v-row>
          </template>
        </v-text-field>

        <v-fade-transition mode="out-in">
          <v-alert v-if="copied" type="success" outlined>
            Copied your referral link!
          </v-alert>
        </v-fade-transition>

        <p class="text-body-1">
          Our goal is to simplify the selling of insurance and help BackNine
          agents share their excitement and experiences with others. You can
          earn commissions when agents use your referral link to sign up and
          place business.
        </p>
        <p class="text-body-1">
          When agents sign up through your referral link, you will earn the
          difference between your commission level and the agent's commission
          level. As their upline, you can also view their business within BOSS.
        </p>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { parseErrorMessage } from "@/util/helpers";

import { getAgentSummary } from "@/api/agents.service";
import { getAgencySummary } from "@/api/agencies.service";

import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  name: "AgentForm",
  data() {
    return {
      copied: false,
      referralCode: false,
      fetchingReferralCode: null
    };
  },
  created() {
    this.fetchReferralLink();
  },
  computed: {
    ...mapState(useUserStore, {
      isGroupOne: "isGroupOne",
      loginable: "loginable"
    }),
    referralLink() {
      const route = this.$router.resolve({
        name: "ReferralSignUp",
        params: { referralCode: this.referralCode }
      });

      return new URL(route.href, window.location.origin).href;
    },
    htmlSnippet() {
      const connections = [
        {
          parent_type: this.loginable.type,
          parent_id: this.loginable.id,
          hierarchy: 1
        }
      ];
      return (
        `<div id="container-id"></div><script id="strife" src="https://cdn.quoteandapply.io/widget.js?prefill&mode=signup&connections=${JSON.stringify(
          connections
        )}" data-strife-container-id="container-id"></` + "script>"
      );
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    async fetchReferralLink() {
      this.fetchingReferralCode = true;
      try {
        let fetchFunc = getAgentSummary;
        if (this.loginable.type.toLowerCase() === "agency") {
          fetchFunc = getAgencySummary;
        }
        const { advisor } = await fetchFunc(this.loginable.id);

        this.referralCode = advisor.referralCode;
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.fetchingReferralCode = false;
      }
    },
    copyDialog() {
      this.showDialog({
        component: "CopyDialog",
        text: this.htmlSnippet,
        title: "Quote & Apply Snippet",
        subtitle: "Just copy and paste this into your site!"
      });
    },
    copy() {
      this.$refs.copy.$refs.input.select();

      document.execCommand("copy");
      window.getSelection().removeAllRanges();

      this.copied = true;
      if (this.copyTimeout) clearTimeout(this.copyTimeout);
      this.copyTimeout = setTimeout(() => {
        this.copied = false;
      }, 8000);
    }
  }
};
</script>
