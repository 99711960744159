<template>
  <v-card>
    <v-card-title>Edit Category State </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="categoryState.status"
            data-testid="category-state-status"
            label="Status"
            outlined
            dense
            success
            :items="CATEGORY_STATES_STATUS"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        :disabled="saving"
        @click="dialog.closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        data-testid="category-state-save"
        color="primary"
        class="text-none"
        :loading="saving"
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { CATEGORY_STATES_STATUS } from "@/factories/FormMappingFactory";

import { parseErrorMessage } from "@/util/helpers";
import { updateCategoryState } from "@/api/category-states.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { ref, defineProps } from "vue";

const props = defineProps({ value: { type: Object, required: true } });

const categoryState = ref({
  status: null,
  id: null
});

function initializeCategoryState() {
  categoryState.value.id = props.value.id;
  categoryState.value.status = props.value.status;
}

initializeCategoryState();

const saving = ref(false);

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

async function save() {
  saving.value = true;
  try {
    await updateCategoryState(categoryState.value);
    dialog.closeDialog({ saved: true });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}
</script>
