<template>
  <v-card flat>
    <v-card-title class="text-h4">Create a Client</v-card-title>
    <v-card-text>
      <v-row justify="start" align="center" style="gap: 4px" class="ma-0 mb-3">
        <invite-type-card
          v-for="{ title, subtitle, value, icon } in CONTRACT_PARTY_TYPES"
          :key="value"
          :active="contractParty?.type === value"
          :icon="icon"
          :title="title"
          :subtitle="subtitle"
          :data-testid="`contract-party-type-${value}`"
          @click="updateType(value)"
        />
      </v-row>
      <v-fade-transition mode="out-in">
        <contract-party-create-individual
          v-if="isIndividual"
          v-model="contractParty"
          key="individual"
          :validation-scope="validationScope"
        />
        <contract-party-create-entity
          v-else-if="isEntity"
          v-model="contractParty"
          key="entity"
          :validation-scope="validationScope"
        />
      </v-fade-transition>
    </v-card-text>
    <v-fade-transition mode="out-in">
      <v-card-actions v-if="isIndividual || isEntity">
        <v-btn
          color="primary"
          class="text-none"
          data-testid="create-contract-party"
          :loading="saving"
          @click="addContractParty"
        >
          <v-icon class="mr-1"> {{ mdiPlus }} </v-icon> Create Client
        </v-btn>
      </v-card-actions>
    </v-fade-transition>
  </v-card>
</template>

<script setup>
import InviteTypeCard from "@/components/advisors/new-advisor/InviteTypeCard.vue";
import ContractPartyCreateIndividual from "@/components/contract-parties/ContractPartyCreateIndividual.vue";
import ContractPartyCreateEntity from "@/components/contract-parties/ContractPartyCreateEntity.vue";
import {
  Entity,
  EntityToCreateRequest,
  Individual,
  IndividualToCreateRequest
} from "@/factories/ContractParty";
import { createContractParty } from "@/api/contract-party.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";

import { useHead } from "@unhead/vue";
import { mdiAccount, mdiDomain, mdiPlus } from "@mdi/js";
import { computed, ref, set } from "vue";
import useVuelidate from "@vuelidate/core";
import { useRouter } from "@/composables/compatible.composables";
const CONTRACT_PARTY_TYPES = [
  {
    title: "Create an Individual",
    subtitle: "Add a new client which represents a person",
    value: "Individual",
    icon: mdiAccount,
    config: Individual
  },
  {
    title: "Create an Entity",
    subtitle: "Add a new client which represents a business or trust",
    value: "Entity",
    icon: mdiDomain,
    config: Entity
  }
];

useHead({
  title: "Create a Client"
});

const snackbar = useSnackbarStore();
const router = useRouter();
const contractParty = ref({ type: null });
const saving = ref(false);

function updateType(value) {
  const typeDef = CONTRACT_PARTY_TYPES.find(type => type.value === value);
  set(contractParty, "value", typeDef.config());
}

const validationScope = "new-contract-party";
const v$ = useVuelidate(
  { contractParty: { type: { required: v => Boolean(v) } } },
  { contractParty },
  { $scope: validationScope }
);

const isIndividual = computed(() => contractParty.value.type === "Individual");
const isEntity = computed(() => contractParty.value.type === "Entity");

async function addContractParty() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  let body;
  if (isIndividual.value) body = IndividualToCreateRequest(contractParty.value);
  else if (isEntity.value) body = EntityToCreateRequest(contractParty.value);
  else throw "Unsupported type";

  try {
    saving.value = true;
    const { id } = await createContractParty(body);
    if (router) router.push({ name: "ContractPartyView", params: { id } });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}
</script>
