import { getHttpClient } from "@/http-client";
import { serializeObject } from "@/util/helpers";

import {
  setTrainingFromRaw,
  setRawFromTraining
} from "@/factories/AgentSettingsFactory";

const baseUrl = "api/boss/educations";
/**
 * Create a Training based on the Training model
 * @param {Training} training
 * @param {String} type
 * @param {Number} id
 * @returns {Promise<unknown>}
 */
export async function createEducation(training) {
  const { data } = await getHttpClient().post(
    baseUrl,
    await serializeObject({ education: setRawFromTraining(training) })
  );

  return setTrainingFromRaw(data.education);
}

/**
 * Deletes a Training by ID
 * @param {Number} trainingId
 * @returns {PromiseLike<HttpResponse>}
 */
export function deleteEducation(trainingId) {
  return getHttpClient().delete(`${baseUrl}/${trainingId}`);
}
