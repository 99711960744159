export default function HomeRecentAdvisorFactory(model = {}) {
  return {
    id: model?.id || 0,
    lastSubmitted: model?.lastSubmitted || "",
    name: model?.name || "",
    pending: model?.pending || "",
    model: model?.model || "",
    link: model?.link || "",
    yearToDate: model?.yearToDate || 0,
    setFromRequest(model = {}) {
      this.id = model?.id;
      this.lastSubmitted = model?.last_submitted;
      this.name = model?.name;
      this.pending = model?.pending;
      this.model = model?.type?.model;
      this.link = model?.type?.link;
      this.yearToDate = model?.year_to_date;
    }
  };
}
