<template>
  <v-row justify="center">
    <v-card outlined max-width="75rem" width="100%">
      <v-fade-transition mode="out-in">
        <v-row
          v-if="loading"
          align="center"
          justify="center"
          class="ma-0"
          key="loading"
        >
          <v-col cols="12">
            <v-skeleton-loader type="card" />
          </v-col>
          <v-col cols="12">
            <v-skeleton-loader type="table" />
          </v-col>
        </v-row>
        <div v-else key="loaded">
          <pay-period-card />
          <pay-period-actions class="my-3" />
          <pay-period-payments-table />
        </div>
      </v-fade-transition>
    </v-card>
  </v-row>
</template>

<script setup>
import PayPeriodCard from "@/components/pay-period/PayPeriodCard.vue";
import PayPeriodActions from "@/components/pay-period/PayPeriodActions.vue";
import PayPeriodPaymentsTable from "@/components/pay-period/PayPeriodPaymentsTable.vue";
import { parseErrorMessage } from "@/util/helpers";

import { usePayPeriodViewStore } from "@/stores/pay-period-view";
import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";
import { storeToRefs } from "pinia";

import { useHead } from "@unhead/vue";
import { defineProps, onBeforeUnmount, ref } from "vue";
import { useRouter } from "@/composables/compatible.composables";

const props = defineProps({ id: [String, Number] });
const { breadcrumb } = storeToRefs(useInstanceStore());
const snackbar = useSnackbarStore();
const loading = ref(false);
useHead({ title: props.id });
const router = useRouter();

const payPeriodStore = usePayPeriodViewStore();

async function getData() {
  loading.value = true;
  breadcrumb.value = props.id;
  try {
    await payPeriodStore.loadPayPeriod(props.id);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    if (e?.response?.status === 404) router.push({ name: "Home" });
  } finally {
    loading.value = false;
  }
}
onBeforeUnmount(() => (breadcrumb.value = ""));

getData();
</script>
