<template>
  <span>{{ formattedValue }}</span>
</template>

<script setup>
import { numberFormat } from "@/util/helpers";
import { computed, defineProps } from "vue";

const props = defineProps({
  value: {
    type: [String, Number],
    required: true
  }
});

const formattedValue = computed(() => {
  return numberFormat(props.value);
});
</script>
