<template>
  <v-data-table
    must-sort
    item-key="additional.id"
    show-expand
    data-testid="affiliations-table"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :loading="table.loading.value"
    :search="affiliationsTable.search"
    :options="table.options.value"
    :footer-props="footerProps"
  >
    <template #top>
      <v-card class="pa-3 mx-3" flat>
        <v-row class="ma-0" align="center">
          <h1 class="text-h5">Affiliations</h1>
          <v-spacer />
          <v-text-field
            v-model="affiliationsTable.search"
            data-testid="affiliations-table-search"
            data-lpignore="true"
            outlined
            dense
            label="Search"
            prepend-inner-icon="$mdi-magnify"
            hide-details
          />
          <v-btn
            icon
            data-testid="affiliations-table-create"
            @click="showCreateAffiliationDialog"
          >
            <v-icon>$mdi-plus-circle</v-icon>
          </v-btn>
        </v-row>
      </v-card>
    </template>
    <template #[`item.actions`]="{ item }">
      <v-btn
        icon
        color="success"
        data-testid="edit"
        @click="editAffiliation(item.additional)"
      >
        <v-icon>$mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        icon
        color="error"
        data-testid="delete"
        @click="destroyAffiliation(item)"
      >
        <v-icon>$mdi-delete</v-icon>
      </v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="py-4">
        <advisor-table
          :affiliation="{ name: item.additional.name, id: item.additional.id }"
        />
      </td>
    </template>
  </v-data-table>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";

import AdvisorTable from "@/components/advisors/AdvisorTable.vue";
import { deleteAffiliation, getAffiliations } from "@/api/affiliation.service";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { storeToRefs } from "pinia";
import { useTableStore } from "@/stores/table";

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Linked Advisor",
      value: "ownable",
      map: "ownable.name",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Category",
      value: "category",
      map: "category",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions"
    })
  ]
});

const footerProps = {
  itemsPerPageOptions: [10, 20, 35, 50]
};

const dialog = useDialogStore();
const snackbar = useSnackbarStore();

async function getData() {
  try {
    const items = await getAffiliations();
    table.items.value.splice(0, table.items.value.length);
    table.items.value.push(...items);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function showCreateAffiliationDialog() {
  const res = await dialog.showDialog({
    component: "CreateAffiliationDialog"
  });
  if (res?.created) getData();
}

async function editAffiliation(affiliation) {
  const res = await dialog.showDialog({
    component: "CreateAffiliationDialog",
    affiliation
  });
  if (res?.created) getData();
}

async function destroyAffiliation(item) {
  const res = await dialog.showDialog({
    component: "ConfirmationDialog",
    func: () => deleteAffiliation(item.additional.id),
    title: "Are you sure that you want to delete this Affiliation?",
    subtitle: "This cannot be undone and can mess up agents affiliations"
  });
  if (res?.confirm) getData();
}

getData();

const { affiliationsTable } = storeToRefs(useTableStore());
</script>
