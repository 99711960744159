import { Lead } from "@/factories/Lead";
import { destroyLead, getLead, saveLead } from "@/api/leads.service";
import { defineStore } from "pinia";
import { set } from "vue";
import { useUserStore } from "./user";

export const useLeadView = defineStore("lead-view", {
  state: () => Lead(),
  getters: {
    pusherId() {
      return `private-${this.id}-Lead`;
    },
    name() {
      return `${this.firstName} ${this.lastName}`;
    }
  },
  actions: {
    deleteLead() {
      return destroyLead(this.id);
    },
    async initialize(id) {
      const lead = await getLead(id);
      const user = useUserStore();

      lead.notes.sort((a, b) => a.createdAt - b.createdAt);

      lead.notes.unshift({
        note: `Hi ${user.loginable.name}, feel free to make notes here.`,
        ownable: { name: "BackNine" }
      });

      set(this, "$state", lead);
    },
    updateAttribute(attribute) {
      const homeAddressAttribute = body => {
        return {
          addresses_attributes: [
            {
              id: this.homeAddress?.id,
              address_type: "home",
              ...body
            }
          ]
        };
      };
      const businessAddressAttribute = body => {
        return {
          addresses_attributes: [
            {
              id: this.businessAddress?.id,
              address_type: "business",
              ...body
            }
          ]
        };
      };

      const body = {
        marketingManager: {
          marketing_manager_id: this.marketingManager?.marketing_manager_id
        },
        email: { email: this.email },
        firstName: { first_name: this.firstName },
        lastName: { last_name: this.lastName },
        phoneWork: { phone_work: this.phoneWork },
        phoneMobile: { phone_mobile: this.phoneMobile },
        phoneFax: { phone_fax: this.phoneFax },
        birthdate: { birthdate: this.birthdate },
        gender: { gender: this.gender },
        category: { category: this.category },
        followUpDate: { follow_up_date: this.followUpDate },
        subscribed: { subscribed: this.subscribed },
        homeAddress: homeAddressAttribute({
          id: this.homeAddress?.id,
          street_address: this.homeAddress?.street_address,
          city: this.homeAddress?.city,
          state: this.homeAddress?.state,
          zip: this.homeAddress?.zip
        }),
        homeStreet: homeAddressAttribute({
          street_address: this.homeAddress?.street_address
        }),
        homeCity: homeAddressAttribute({
          city: this.homeAddress?.city
        }),
        homeState: homeAddressAttribute({
          state: this.homeAddress?.state
        }),
        homeZip: homeAddressAttribute({
          zip: this.homeAddress?.zip
        }),
        businessAddress: businessAddressAttribute({
          id: this.businessAddress?.id,
          street_address: this.businessAddress?.street_address,
          city: this.businessAddress?.city,
          state: this.businessAddress?.state,
          zip: this.businessAddress?.zip
        }),
        businessStreet: businessAddressAttribute({
          street_address: this.businessAddress?.street_address
        }),
        businessCity: businessAddressAttribute({
          city: this.businessAddress?.city
        }),
        businessState: businessAddressAttribute({
          state: this.businessAddress?.state
        }),
        businessZip: businessAddressAttribute({
          zip: this.businessAddress?.zip
        })
      }[attribute];

      return saveLead(this.id, body);
    }
  }
});
