<template>
  <v-col class="pa-0">
    <system-alert />
    <v-card
      flat
      width="100%"
      v-if="$vuetify.breakpoint.smAndDown && !inSettings && !inPhoneCall"
    >
      <v-row class="ma-0 pa-3" align="center" justify="center">
        <app-logo large />
      </v-row>
    </v-card>
    <app-header @toggle-drawer="toggleDrawer" />
    <sidebar v-model="showDrawer" />
    <v-col>
      <app-breadcrumbs
        v-if="!$route.meta?.useBreadcrumbSlot"
        data-testid="breadcrumbs"
        :key="$route.path"
      />
      <v-fade-transition mode="out-in">
        <div :key="$route.path">
          <slot />
        </div>
      </v-fade-transition>
    </v-col>
  </v-col>
</template>

<script>
import SystemAlert from "@/components/SystemAlert.vue";
import Sidebar from "@/components/Sidebar.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppLogo from "@/components/AppLogo.vue";
import { useHead } from "@unhead/vue";
export default {
  name: "DashboardView",
  components: {
    Sidebar,
    AppHeader,
    SystemAlert,
    AppBreadcrumbs,
    AppLogo
  },
  setup() {
    useHead({ titleTemplate: "%s | BOSS" });
  },
  data() {
    return {
      showDrawer: false
    };
  },
  computed: {
    inPhoneCall() {
      return ["PhoneCall"].includes(this.$route.name);
    },
    inSettings() {
      return [
        "AdvisorEdit",
        "AgentEdit",
        "AgencyEdit",
        "LoggedInUserSettings"
      ].includes(this.$route.name);
    }
  },
  methods: {
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    togglePhoneCall() {
      this.showSitePhoneCall = !this.showSitePhoneCall;
    }
  }
};
</script>
