import { getHttpClient } from "@/http-client";
import {
  ApplicationQuestionLinkFactory,
  setAqlFromRequest
} from "@/factories/FormMappingFactory";
const baseUrl = "/api/boss/application_question_links";

export const updateApplicationQuestionLink = async (id, body) => {
  const { data } = await getHttpClient().put(`${baseUrl}/${id}`, {
    application_question_link: body
  });
  const aql = new ApplicationQuestionLinkFactory();
  setAqlFromRequest(aql, data);
  return aql;
};

export const createApplicationQuestionLink = async ({ form_id, body }) => {
  const { data } = await getHttpClient().post(baseUrl, {
    application_question_link: { ...body, form_id }
  });

  const aql = new ApplicationQuestionLinkFactory();
  setAqlFromRequest(aql, data);
  return aql;
};

export const deleteApplicationQuestionLink = id => {
  return getHttpClient().delete(`${baseUrl}/${id}`);
};
