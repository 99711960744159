<template>
  <v-card elevation="8" class="pa-3">
    <v-row>
      <v-col cols="12">
        <v-row
          v-for="(section, index) in series.sections"
          :key="index"
          class="ma-0"
          style="padding-top: 5rem; padding-bottom: 5rem"
        >
          <v-col cols="12" :lg="section.url ? 6 : 12">
            <v-row class="ma-0 height-full" justify="center" align="center">
              <v-col align="center">
                <div>
                  <h2
                    class="series-title"
                    :class="{ 'primary--text': section.primaryTitle }"
                    :style="{
                      'font-size': $vuetify.breakpoint.smAndDown
                        ? '28px'
                        : '44px'
                    }"
                  >
                    {{ section.title }}
                  </h2>
                </div>
                <v-row justify="center">
                  <v-col>
                    <div
                      class="series-subtitle"
                      :style="{
                        'font-size': $vuetify.breakpoint.smAndDown
                          ? '24px'
                          : '28px'
                      }"
                    >
                      {{ section.subtitle }}
                    </div>
                    <div
                      v-if="section.c2a"
                      class="series-c2a"
                      style="margin-top: 20px"
                    >
                      <v-btn
                        v-on="section.c2a.value.buttonListeners"
                        v-bind="section.c2a.value.buttonProps"
                        :color="section.c2a.value.color"
                        block
                        x-large
                        class="text-none"
                        :style="{
                          'font-size': $vuetify.breakpoint.smAndDown
                            ? '18px'
                            : '22px'
                        }"
                      >
                        <v-icon v-if="section.c2a.value.icon">
                          {{ section.c2a.value.icon }}
                        </v-icon>
                        {{ section.c2a.value.title }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="section.url" cols="12" lg="6">
            <vimeo-video :url="section.url" :id="`series-video-${index}`" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {
  AVAILABLE_SERIES,
  useWalkthroughComposable
} from "@/composables/series-composable";
import { useInstanceStore } from "@/stores/instance";
import VimeoVideo from "@/components/shared/VimeoVideo.vue";
import { useHead } from "@unhead/vue";

export default {
  props: {
    id: String
  },
  components: {
    VimeoVideo
  },
  data: () => ({ loading: false }),
  setup() {
    return {
      [AVAILABLE_SERIES.WALKTHROUGH]: useWalkthroughComposable()
    };
  },
  computed: {
    series() {
      return { [AVAILABLE_SERIES.WALKTHROUGH]: this.walkthrough }[this.id];
    }
  },
  mounted() {
    const instance = useInstanceStore();
    instance.breadcrumb = this.series.title;
    useHead({ title: this.series.title });
  },
  beforeDestroy() {
    const instance = useInstanceStore();
    instance.breadcrumb = "";
  }
};
</script>

<style lang="scss">
.series-title {
  line-height: 1.2;
  text-align: center;
  padding-bottom: 20px;
}
.series-subtitle {
  max-width: 750px;
  text-align: center;
  line-height: 1.2;
  font-weight: 300;
}

.series-c2a {
  max-width: 400px;
  width: 100%;
}
</style>
