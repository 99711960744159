<template>
  <v-card>
    <v-card-title>Change Pay Period EFT </v-card-title>
    <v-card-text>
      <v-autocomplete
        label="Eft"
        v-model="eft"
        outlined
        dense
        data-testid="pay-period-eft"
        :success="eftValidation.success"
        :error-messages="eftValidation.errorMessages"
        :items="efts"
        :loading="loading"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="text-none"
        outlined
        :disabled="saving"
        @click="dialog.closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        class="text-none"
        outlined
        data-testid="save-pay-period-eft"
        :loading="saving"
        @click="saveEft"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { getEfts } from "@/api/boss.service";
import { updatePayPeriodEft } from "@/api/pay-periods.service";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { defineProps, ref } from "vue";
import useVuelidate from "@vuelidate/core";
const props = defineProps({
  payPeriodId: Number,
  advisorId: Number,
  advisorType: {
    type: String,
    validator: v => ["agency", "agent"].includes(v.toLowerCase()),
    required: true
  },
  value: Number
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const eft = ref(props.value);
const efts = ref([]);
const saving = ref(false);
const loading = ref(false);

const v$ = useVuelidate(
  {
    eft: { required: v => Boolean(v) }
  },
  { eft },
  { $scope: false, $autoDirty: true }
);

const eftValidation = computedValidation(v$.value.eft, [
  { key: "required", message: "Required" }
]);
async function fetchEfts() {
  loading.value = true;
  try {
    const advisorEfts = await getEfts(
      props.advisorId,
      props.advisorType.toLowerCase()
    );
    efts.value.splice(0, efts.value.length);
    efts.value.push(...advisorEfts);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

async function saveEft() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  saving.value = true;
  try {
    await updatePayPeriodEft(props.payPeriodId, eft.value);

    const activeEft = efts.value.find(v => v.value === eft.value);
    snackbar.showSuccessSnackbar({
      message: "Successfully updated pay period EFT"
    });
    dialog.closeDialog({ eft: activeEft });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}

fetchEfts();
</script>
