export default function HomeRecentRequirementFactory(model = {}) {
  return {
    caseId: model?.caseId || 0,
    description: model?.description || "",
    id: model?.id || 0,
    insuredName: model?.insuredName || "",
    type: model?.type || "",
    updatedAt: model?.updatedAt || "",
    setFromRequest(model = {}) {
      this.caseId = model?.case_id;
      this.description = model?.description;
      this.id = model?.id;
      this.insuredName = model?.insured_name;
      this.type = model?.type;
      this.updatedAt = model?.updated_at;
    }
  };
}
