<template>
  <v-autocomplete
    autocomplete="false"
    prepend-inner-icon="$mdi-calendar"
    placeholder="Search Appointments"
    label="Appointment"
    outlined
    dense
    v-model="appointment"
    return-object
    no-filter
    hide-no-data
    :item-text="itemText"
    :clearable="clearable"
    :disabled="disabled"
    :search-input.sync="appointmentSearchText"
    :success="success"
    :error-messages="errorMessages"
    :items="appointments"
    :loading="loading"
    :hide-details="hideDetails"
    ref="appointmentSearch"
    @blur="$emit('blur')"
    @change="$emit('change')"
  >
    <template #append-outer v-if="hasAppendOuterSlot">
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import { searchAppointments } from "@/api/appointment.service";
import sortBy from "lodash/sortBy";
export default {
  name: "AppointmentSearch",
  props: {
    clearable: Boolean,
    success: Boolean,
    errorMessages: Array,
    value: [Object, String],
    appointmentId: [String, Number],
    disabled: Boolean,
    hideDetails: Boolean
  },
  data() {
    let appointments = [];
    let appointment = null;
    if (this.value && Object.keys(this.value).length && this.value.id) {
      appointments = [this.value];
      appointment = this.value;
    }
    return {
      appointmentSearchText: "",
      appointmentTimer: null,
      loading: false,
      appointments,
      appointment
    };
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots["append-outer"];
    }
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.appointment = null;
        this.appointments = [];
        return;
      }
      this.appointment = value;
      this.appointments = [value];
    },
    appointment(value) {
      this.$emit("input", value);
    },

    appointmentSearchText(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (
        this.appointments.some(
          val => val.owner.name + " - " + val.name === value
        )
      ) {
        return;
      }

      if (this.appointmentTimer) {
        clearTimeout(this.appointmentTimer);
      }

      this.appointmentTimer = setTimeout(() => {
        this.loading = true;
        searchAppointments(this.appointmentId, value)
          .then(response => {
            this.appointments = sortBy(response.data.appointments, "name");
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  },
  methods: {
    focus() {
      this.$refs.appointmentSearch.$refs.input.focus();
    },
    itemText(value) {
      return `${value.owner.name} · ${value.name} · Status: ${value.status} `;
    }
  }
};
</script>
