const templates = [
  { text: "Business Life Insurance", value: "business_life_insurance" },
  {
    text: "Final Expense Life Insurance",
    value: "final_expense_life_insurance"
  },
  {
    text: "Get a Head Start on your Future Retirement",
    value: "get_a_head_start_on_your_future_retirement"
  },
  {
    text: "Life Insurance Policy Review",
    value: "life_insurance_policy_review"
  },
  {
    text: "Life with Long Term Care Insurance",
    value: "life_with_long_term_care_insurance"
  },
  {
    text: "New Mortgage, New Life Insurance",
    value: "new_mortgage_new_life_insurance"
  },
  { text: "No Exam Life Insurance", value: "no_exam_life_insurance" }
];

export { templates };
