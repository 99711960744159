import { getHttpClient } from "@/http-client";
import { QueuedTransactionFactory } from "@/factories/QueuedTransactionFactory";
const baseURL = "/api/boss/queued-transactions";

export async function getQueuedTransactions(params) {
  params.append("pending", "true");
  params.append("with_count", "true");
  const { data } = await getHttpClient().get(baseURL, {
    params
  });

  const transactions = data.transactions.map(rawQueuedTransaction => {
    const queuedTransaction = new QueuedTransactionFactory();
    queuedTransaction.setFromRequest(rawQueuedTransaction);
    return queuedTransaction;
  });

  return {
    transactions,
    meta: data.meta
  };
}

export function toggleIgnoreQueuedTransaction(id, ignore) {
  return getHttpClient().put(`${baseURL}/${id}`, { ignore });
}
