<template>
  <v-row justify="start" class="ma-0">
    <div :id="props.canvasParentId">
      <canvas :id="canvasId" />
      <slot name="explanation-text" />
    </div>
  </v-row>
</template>

<script setup>
import QRCode from "qrcode";
import { watch, defineProps, onMounted, toRefs } from "vue";
const props = defineProps({
  buttonLink: {
    type: String,
    required: true
  },
  canvasParentId: {
    type: String,
    required: true
  },
  scale: {
    type: Number,
    required: true
  }
});

const { buttonLink, canvasParentId, scale } = toRefs(props);

const canvasId = `${canvasParentId.value}-canvas`;
const DEFAULT_PX_PER_MODULE = 3;
function generateQrCode() {
  const canvas = document.getElementById(canvasId);
  QRCode.toCanvas(
    canvas,
    buttonLink.value,
    { scale: scale.value * DEFAULT_PX_PER_MODULE, margin: 0 },
    error => {
      if (error) console.error(error);
    }
  );
}

watch([buttonLink, scale], generateQrCode);
onMounted(generateQrCode);
</script>
