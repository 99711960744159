<template>
  <v-card flat tile color="section" class="pt-3">
    <access
      v-model="room"
      type="impaired-risk-quote"
      data-testid="impaired-risk-access-table"
      button-color="accent"
      button-text="Add Access"
      :checkboxes="checkboxes"
      :update-func="update"
      :delete-func="deleteAccess"
      :add-func="addAccess"
    />
  </v-card>
</template>

<script setup>
import Access from "@/components/shared/Access.vue";

import { useImpairedRiskQuoteView } from "@/stores/impaired-risk-quote-view";
import { useDialogStore } from "@/stores/dialog";
import { storeToRefs } from "pinia";

const impairedRiskQuote = useImpairedRiskQuoteView();
const dialog = useDialogStore();
const { room } = storeToRefs(impairedRiskQuote);

const checkboxes = [{ text: "Email", value: "email" }];
async function update(access, attribute) {
  return impairedRiskQuote.updateAccess(access.additional.id, attribute);
}

function deleteAccess(access) {
  return dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Are you sure that you want remove access?",
    subtitle: "Please confirm your intent",
    func: () => impairedRiskQuote.deleteAccess(access.additional.id)
  });
}

function addAccess(advisor) {
  return impairedRiskQuote.createAccess(advisor);
}
</script>
