<template>
  <v-card color="section" flat tile>
    <v-card-title>Documents</v-card-title>
    <v-card-text>
      <v-row class="ma-0">
        <p v-if="!documents.length">No Documents Found</p>
        <v-col
          v-for="group in categoryGroups"
          :key="group.text"
          cols="12"
          lg="3"
          data-testid="document-group"
        >
          <v-col cols="12">
            <h2>
              {{ group.text }}
              <v-btn
                data-testid="document-edit"
                v-if="group.to"
                icon
                color="accent"
                :to="group.to"
              >
                <v-icon>$mdi-cog</v-icon>
              </v-btn>
            </h2>
          </v-col>
          <v-col
            v-for="doc in group.documents"
            data-testid="document"
            :key="doc.id"
            cols="12"
          >
            <a v-bind="downloadDocument(doc)"> {{ doc.name }} </a>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { downloadFileAsLink } from "@/util/helpers";
import { getDocumentUrl } from "@/api/documents.service";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useAppointmentView } from "@/stores/appointment-view";
import { useVuetify } from "@/composables/compatible.composables";

const { documents, ownable } = storeToRefs(useAppointmentView());

const categoryGroups = computed(() => {
  let advisor = { id: ownable.value.id, type: ownable.value.type };

  if (ownable.value.signerId) {
    advisor = { id: ownable.value.signerId, type: ownable.value.signerType };
  }

  const advisorSettingsLink = page => ({
    name: advisor.type === "Agent" ? "AgentEdit" : "AgencyEdit",
    params: {
      id: advisor.id
    },
    query: { page }
  });

  const categories = {};

  documents.value.forEach(d => {
    const category = d.category || "Other";
    if (!categories[category]) {
      let to = null;
      if (category === "Eo") to = advisorSettingsLink("eo");
      else if (category === "Eft") to = advisorSettingsLink("commissions");

      categories[category] = {
        text: category,
        to,
        documents: []
      };
    }

    categories[category].documents.push(d);
  });

  const categoriesAsList = Object.values(categories);
  categoriesAsList.sort((a, b) => {
    if (a.documents.length === b.documents.length) {
      return a.text.localeCompare(b.text);
    }
    return a.documents.length - b.documents.length;
  });

  return categories;
});

const vuetify = useVuetify();

function downloadDocument({ uid }) {
  return downloadFileAsLink(getDocumentUrl(uid), vuetify.breakpoint.mdAndDown);
}
</script>
