<template>
  <v-data-table
    class="transparent-data-table"
    data-testid="contract-party-cases-table"
    :search.sync="search"
    :items="table.mappedItems.value"
    :headers="table.tableHeaders.value"
  >
    <template #top>
      <v-row class="ma-0" align="center">
        <v-card-title> Cases </v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          :prepend-inner-icon="mdiMagnify"
          outlined
          dense
          hide-details
          placeholder="Type to Search"
          style="max-width: 30rem"
        />
      </v-row>
    </template>
    <template #[`item.policyNumber`]="{ item }">
      <router-link :to="getRouterLink('Case', item.additional.caseId)">
        {{ item.policyNumber }}
      </router-link>
    </template>
  </v-data-table>
</template>

<script setup>
import { mdiMagnify } from "@mdi/js";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";
import {
  currencyFormat,
  getRouterLink,
  lineFormatter,
  modeFormat
} from "@/util/helpers";
import { useTable } from "@/composables/table.composable";
import { useContractPartyView } from "@/stores/contract-party-view";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";

const contractPartyView = useContractPartyView();

const { cases } = storeToRefs(contractPartyView);

const table = useTable({
  options: new TableOptions(["insured"], [false]),
  headers: [
    new TableHeader({
      text: "Insured",
      value: "insured",
      map: "insured",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Policy Number",
      value: "policyNumber",
      map: "policyNumber",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "carrier",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Product",
      value: "product",
      map: "product",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Line",
      value: "line",
      map: "line",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Premium",
      value: "premium",
      map: "premium",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Mode",
      value: "mode",
      map: "mode",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Benefit",
      value: "benefitAmount",
      map: "benefitAmount",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    })
  ]
});

const search = ref("");

watch(
  cases,
  () => {
    table.items.value.splice(0, table.items.value.length);
    cases.value.forEach(t => {
      table.items.value.push({
        ...t,
        premium: currencyFormat(t.premium, 0),
        benefitAmount: currencyFormat(t.benefitAmount, 0),
        mode: modeFormat(t.mode),
        line: lineFormatter(t.line)
      });
    });
  },
  { immediate: true }
);
</script>
