<template>
  <v-card hover @click="$emit('click')">
    <v-card-text class="pa-2">
      <v-row class="ma-0" align="end" justify="center">
        <v-fade-transition mode="out-in">
          <v-progress-circular
            v-if="loading"
            indeterminate
            size="24"
            width="2"
          />
          <h2
            v-else
            class="text-right"
            :class="{
              'error--text': !isSuccessful,
              'success--text': isSuccessful
            }"
          >
            <animated-number
              :value="count"
              :format-value="numberFormat"
              :duration="1000"
              :round="1"
            />
          </h2>
        </v-fade-transition>
        <div class="ml-1">
          <span>
            Past Due {{ count === 1 ? props.singular : props.plural }}
          </span>
        </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import AnimatedNumber from "@/components/shared/AnimatedNumber.vue";
import { getRequirements } from "@/api/requirements.service";
import { computed, defineProps, ref, toRefs, watch } from "vue";
import { numberFormat, parseErrorMessage } from "@/util/helpers";
import { format } from "date-fns";
import { useSnackbarStore } from "@/stores/snackbar";
const props = defineProps({
  singular: {
    type: String,
    required: true
  },
  plural: {
    type: String,
    required: true
  },
  type: {
    type: String,
    required: true
  },
  global: Boolean,
  refreshedAt: {
    type: String,
    required: false,
    default: ""
  }
});

const { global, refreshedAt } = toRefs(props);

const count = ref(0);
const loading = ref(false);
const isSuccessful = computed(() => count.value === 0);
const snackbar = useSnackbarStore();

let cancelToken = null;
async function getData() {
  const params = new URLSearchParams();
  params.append("view", global.value ? "all" : "my");
  params.append("page", 1);
  params.append("count", 1);
  params.append("type", props.type);
  params.append("date[follow_up_at][finish]", format(new Date(), "yyyy-MM-dd"));
  try {
    loading.value = true;
    const response = await getRequirements(params, cancelToken);
    if (!response) return;
    count.value = response.meta.total;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

watch([global, refreshedAt], getData);

getData();
</script>
