export function QueuedTransactionFactory({
  amount,
  caseId,
  commissionType,
  errorMessage,
  id,
  isIgnored,
  payorId,
  payorName,
  payorType,
  policyNumber,
  premium,
  rawModel,
  rawData,
  statementId,
  transactorId,
  transactorName,
  transactorType
} = {}) {
  return {
    amount,
    caseId,
    commissionType,
    errorMessage,
    id,
    isIgnored,
    payorId,
    payorName,
    payorType,
    policyNumber,
    premium,
    rawModel,
    rawData,
    statementId,
    transactorId,
    transactorName,
    transactorType,
    get key() {
      return [
        this.id,
        this.caseId,
        this.premium,
        this.amount,
        this.commissionType,
        this.isIgnored
      ].join("-");
    },
    setFromRequest(rawModel = {}) {
      this.amount = rawModel?.amount;
      this.caseId = rawModel?.case?.id;
      this.caseId = rawModel?.case?.id;
      this.commissionType = rawModel?.commission_type;
      this.errorMessage = rawModel?.error_message;
      this.id = rawModel?.id;
      this.isIgnored = rawModel?.ignore;
      this.payorId = rawModel?.statement?.payor?.id;
      this.payorName = rawModel?.statement?.payor?.name;
      this.payorType = rawModel?.statement?.payor?.type;
      this.policyNumber = rawModel?.policy_number;
      this.premium = rawModel?.premium;
      this.rawModel = rawModel;
      this.rawData = rawModel?.data;
      this.statementId = rawModel?.statement?.id;
      this.transactorId = rawModel?.transactor?.id;
      this.transactorName = rawModel?.transactor?.name;
      this.transactorType = rawModel?.transactor?.type;
      if (!this.caseId && !this.errorMessage)
        this.errorMessage = "Invalid Policy # (Unable to locate case)";
    }
  };
}
