export const states = [
  {
    text: "AL",
    full: "Alabama"
  },
  {
    text: "AK",
    full: "Alaska"
  },
  {
    text: "AZ",
    full: "Arizona"
  },
  {
    text: "AR",
    full: "Arkansas"
  },
  {
    text: "CA",
    full: "California"
  },
  {
    text: "CO",
    full: "Colorado"
  },
  {
    text: "CT",
    full: "Connecticut"
  },
  {
    text: "DE",
    full: "Delaware"
  },
  {
    text: "DC",
    full: "Washington, D.C."
  },
  {
    text: "FL",
    full: "Florida"
  },
  {
    text: "GA",
    full: "Georgia"
  },
  {
    text: "GU",
    full: "Guam"
  },
  {
    text: "HI",
    full: "Hawaii"
  },
  {
    text: "ID",
    full: "Idaho"
  },
  {
    text: "IL",
    full: "Illinois"
  },
  {
    text: "IN",
    full: "Indiana"
  },
  {
    text: "IA",
    full: "Iowa"
  },
  {
    text: "KS",
    full: "Kansas"
  },
  {
    text: "KY",
    full: "Kentucky"
  },
  {
    text: "LA",
    full: "Louisiana"
  },
  {
    text: "ME",
    full: "Maine"
  },
  {
    text: "MD",
    full: "Maryland"
  },
  {
    text: "MA",
    full: "Massachusetts"
  },
  {
    text: "MI",
    full: "Michigan"
  },
  {
    text: "MN",
    full: "Minnesota"
  },
  {
    text: "MS",
    full: "Mississippi"
  },
  {
    text: "MO",
    full: "Missouri"
  },
  {
    text: "MT",
    full: "Montana"
  },
  {
    text: "NE",
    full: "Nebraska"
  },
  {
    text: "NV",
    full: "Nevada"
  },
  {
    text: "NH",
    full: "New Hampshire"
  },
  {
    text: "NJ",
    full: "New Jersey"
  },
  {
    text: "NM",
    full: "New Mexico"
  },
  {
    text: "NY",
    full: "New York"
  },
  {
    text: "NC",
    full: "North Carolina"
  },
  {
    text: "ND",
    full: "North Dakota"
  },
  {
    text: "OH",
    full: "Ohio"
  },
  {
    text: "OK",
    full: "Oklahoma"
  },
  {
    text: "OR",
    full: "Oregon"
  },
  {
    text: "PA",
    full: "Pennsylvania"
  },
  {
    text: "PR",
    full: "Puerto Rico"
  },
  {
    text: "RI",
    full: "Rhode Island"
  },
  {
    text: "SC",
    full: "South Carolina"
  },
  {
    text: "SD",
    full: "South Dakota"
  },
  {
    text: "TN",
    full: "Tennessee"
  },
  {
    text: "TX",
    full: "Texas"
  },
  {
    text: "UT",
    full: "Utah"
  },
  {
    text: "VT",
    full: "Vermont"
  },
  {
    text: "VA",
    full: "Virginia"
  },
  {
    text: "WA",
    full: "Washington"
  },
  {
    text: "WV",
    full: "West Virginia"
  },
  {
    text: "WI",
    full: "Wisconsin"
  },
  {
    text: "WY",
    full: "Wyoming"
  }
];

export const stateIds = [
  { value: 2, text: "Alabama" },
  { value: 3, text: "Alaska" },
  { value: 4, text: "Arizona" },
  { value: 5, text: "Arkansas" },
  { value: 1, text: "California" },
  { value: 6, text: "Colorado" },
  { value: 7, text: "Connecticut" },
  { value: 8, text: "Delaware" },
  { value: 9, text: "Washington D.C." },
  { value: 10, text: "Florida" },
  { value: 11, text: "Georgia" },
  { value: 53, text: "Guam" },
  { value: 12, text: "Hawaii" },
  { value: 13, text: "Idaho" },
  { value: 14, text: "Illinois" },
  { value: 15, text: "Indiana" },
  { value: 16, text: "Iowa" },
  { value: 17, text: "Kansas" },
  { value: 18, text: "Kentucky" },
  { value: 19, text: "Louisiana" },
  { value: 20, text: "Maine" },
  { value: 21, text: "Maryland" },
  { value: 22, text: "Massachusetts" },
  { value: 23, text: "Michigan" },
  { value: 24, text: "Minnesota" },
  { value: 25, text: "Mississippi" },
  { value: 26, text: "Missouri" },
  { value: 27, text: "Montana" },
  { value: 28, text: "Nebraska" },
  { value: 29, text: "Nevada" },
  { value: 30, text: "New Hampshire" },
  { value: 31, text: "New Jersey" },
  { value: 32, text: "New Mexico" },
  { value: 33, text: "New York" },
  { value: 34, text: "North Carolina" },
  { value: 35, text: "North Dakota" },
  { value: 36, text: "Ohio" },
  { value: 37, text: "Oklahoma" },
  { value: 38, text: "Oregon" },
  { value: 39, text: "Pennsylvania" },
  { value: 41, text: "Rhode Island" },
  { value: 42, text: "South Carolina" },
  { value: 43, text: "South Dakota" },
  { value: 44, text: "Tennessee" },
  { value: 45, text: "Texas" },
  { value: 46, text: "Utah" },
  { value: 47, text: "Vermont" },
  { value: 48, text: "Virginia" },
  { value: 49, text: "Washington" },
  { value: 50, text: "West Virginia" },
  { value: 51, text: "Wisconsin" },
  { value: 52, text: "Wyoming" }
];

export const STATES = [
  {
    value: "AL",
    text: "Alabama"
  },
  {
    value: "AK",
    text: "Alaska"
  },
  {
    value: "AZ",
    text: "Arizona"
  },
  {
    value: "AR",
    text: "Arkansas"
  },
  {
    value: "CA",
    text: "California"
  },
  {
    value: "CO",
    text: "Colorado"
  },
  {
    value: "CT",
    text: "Connecticut"
  },
  {
    value: "DE",
    text: "Delaware"
  },
  {
    value: "DC",
    text: "Washington, D.C."
  },
  {
    value: "FL",
    text: "Florida"
  },
  {
    value: "GA",
    text: "Georgia"
  },
  {
    value: "GU",
    text: "Guam"
  },
  {
    value: "HI",
    text: "Hawaii"
  },
  {
    value: "ID",
    text: "Idaho"
  },
  {
    value: "IL",
    text: "Illinois"
  },
  {
    value: "IN",
    text: "Indiana"
  },
  {
    value: "IA",
    text: "Iowa"
  },
  {
    value: "KS",
    text: "Kansas"
  },
  {
    value: "KY",
    text: "Kentucky"
  },
  {
    value: "LA",
    text: "Louisiana"
  },
  {
    value: "ME",
    text: "Maine"
  },
  {
    value: "MD",
    text: "Maryland"
  },
  {
    value: "MA",
    text: "Massachusetts"
  },
  {
    value: "MI",
    text: "Michigan"
  },
  {
    value: "MN",
    text: "Minnesota"
  },
  {
    value: "MS",
    text: "Mississippi"
  },
  {
    value: "MO",
    text: "Missouri"
  },
  {
    value: "MT",
    text: "Montana"
  },
  {
    value: "NE",
    text: "Nebraska"
  },
  {
    value: "NV",
    text: "Nevada"
  },
  {
    value: "NH",
    text: "New Hampshire"
  },
  {
    value: "NJ",
    text: "New Jersey"
  },
  {
    value: "NM",
    text: "New Mexico"
  },
  {
    value: "NY",
    text: "New York"
  },
  {
    value: "NC",
    text: "North Carolina"
  },
  {
    value: "ND",
    text: "North Dakota"
  },
  {
    value: "OH",
    text: "Ohio"
  },
  {
    value: "OK",
    text: "Oklahoma"
  },
  {
    value: "OR",
    text: "Oregon"
  },
  {
    value: "PA",
    text: "Pennsylvania"
  },
  {
    value: "PR",
    text: "Puerto Rico"
  },
  {
    value: "RI",
    text: "Rhode Island"
  },
  {
    value: "SC",
    text: "South Carolina"
  },
  {
    value: "SD",
    text: "South Dakota"
  },
  {
    value: "TN",
    text: "Tennessee"
  },
  {
    value: "TX",
    text: "Texas"
  },
  {
    value: "UT",
    text: "Utah"
  },
  {
    value: "VT",
    text: "Vermont"
  },
  {
    value: "VA",
    text: "Virginia"
  },
  {
    value: "WA",
    text: "Washington"
  },
  {
    value: "WV",
    text: "West Virginia"
  },
  {
    value: "WI",
    text: "Wisconsin"
  },
  {
    value: "WY",
    text: "Wyoming"
  }
];

const fullStateDict = {};
states.forEach(({ text, full }) => (fullStateDict[text] = full));
export { fullStateDict };
