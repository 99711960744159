<template>
  <v-card>
    <v-sheet color="section" rounded class="pa-3 mb-2 pb-0">
      <v-tabs show-arrows v-model="activeTab" background-color="section">
        <v-tab v-for="tab in tabs" :key="tab.text" class="text-none">
          {{ tab.text }}
        </v-tab>
      </v-tabs>
    </v-sheet>
    <v-card-text :class="`inforce-font-size-${fontSizeOffset}`">
      <v-row class="pa-3 py-3" align="center">
        <h2>{{ activeTabTitle }}</h2>
        <span v-if="isRefreshing" class="grey--text ml-3">
          Last Refreshed {{ lastTimeRefreshed }}
        </span>
        <v-spacer />
        <!-- Open in fullscreen dialog -->
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              :disabled="fontSizeOffset >= 7"
              @click="incrementFontSizeOffset(true)"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon>$mdi-format-font-size-increase</v-icon>
            </v-btn>
          </template>
          Increase Font Size
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              :disabled="fontSizeOffset <= 0"
              @click="incrementFontSizeOffset(false)"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon>$mdi-format-font-size-decrease</v-icon>
            </v-btn>
          </template>
          Decrease Font Size
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-if="isRefreshing"
              v-on="on"
              v-bind="attrs"
              @click="toggleDataRefresh(false)"
            >
              <v-icon>$mdi-repeat-off</v-icon>
            </v-btn>
            <v-btn
              icon
              v-else
              v-on="on"
              v-bind="attrs"
              @click="toggleDataRefresh(false)"
            >
              <v-icon>$mdi-repeat</v-icon>
            </v-btn>
          </template>
          {{
            isRefreshing ? "Disable Data Refreshing" : "Enable Data Refreshing"
          }}
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-if="isCyclingThroughTables"
              v-on="on"
              v-bind="attrs"
              @click="toggleTableCycling(false)"
            >
              <v-icon>$mdi-stop</v-icon>
            </v-btn>
            <v-btn
              icon
              v-else
              v-on="on"
              v-bind="attrs"
              @click="toggleTableCycling(false)"
            >
              <v-icon>$mdi-play</v-icon>
            </v-btn>
          </template>
          {{
            isCyclingThroughTables
              ? "Disable Automatic Table Cycling"
              : "Enable Automatic Table Cycling"
          }}
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              @click="fullscreen = !fullscreen"
            >
              <v-icon v-if="fullscreen">$mdi-fullscreen-exit</v-icon>
              <v-icon v-else>$mdi-fullscreen</v-icon>
            </v-btn>
          </template>
          {{ fullscreen ? "Exit Fullscreen" : "Enter Fullscreen" }}
        </v-tooltip>
      </v-row>
      <v-data-table
        :key="lastTimeRefreshed"
        :items="items"
        :headers="headers"
        class="inforce-table"
        :loading="loading"
        @update:options="updateOptions"
        :options="options"
        no-data-text="Unable to load results"
      >
        <template #[`item.production`]="{ item }">
          <currency-formatter :value="item.production" :decimal-length="0" />
        </template>
        <template #[`item.goal`]="{ item }">
          <percentage-formatter
            :value="item.goal"
            floor
            is-decimal
            :decimal-length="0"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import Table from "@/classes/data-table/Table";
import TableOptions from "@/classes/data-table/TableOptions";

import format from "date-fns/format";

import { getScorecardReport } from "@/api/reports.service";
import { mapWritableState } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { useHead } from "@unhead/vue";

const FONT_SIZE_SCALER = 1;

export default {
  setup() {
    useHead({ title: "Scorecard" });
  },
  components: {
    CurrencyFormatter,
    PercentageFormatter
  },
  mixins: [GenericTableMixin],
  data() {
    const headers = [
      new TableHeader({
        text: "Name",
        value: "name",
        map: "name",
        ...TableHeader.IS_SORTABLE
      }),
      new TableHeader({
        text: "Cases",
        value: "inforce_cases",
        map: "inforce_cases",
        ...TableHeader.IS_SORTABLE
      }),
      new TableHeader({
        text: "Production",
        value: "production",
        map: "production",
        ...TableHeader.IS_SORTABLE
      }),
      new TableHeader({
        text: "Goal",
        value: "goal",
        map: "goal",
        ...TableHeader.IS_SORTABLE
      })
    ];
    const todaysTable = new Table({
      text: "Today",
      options: new TableOptions(["production"], [true]),
      headers,
      key: "day"
    });
    const weeksTable = new Table({
      text: "Last 7 Days",
      options: new TableOptions(["production"], [true]),
      headers,
      key: "week"
    });
    const monthsTable = {
      text: "Last 30 Days",
      options: new TableOptions(["production"], [true]),
      headers,
      key: "month"
    };
    const yearsTable = new Table({
      text: new Date().getFullYear(),
      options: new TableOptions(["production"], [true]),
      headers,
      key: "year"
    });
    const lastYearsTable = new Table({
      text: new Date().getFullYear() - 1,
      options: new TableOptions(["production"], [true]),
      headers,
      key: "last_year"
    });
    const pendingHeaders = [
      new TableHeader({
        text: "Name",
        value: "name",
        map: "name",
        ...TableHeader.IS_SORTABLE
      }),
      new TableHeader({
        text: "Cases",
        value: "pending_cases",
        map: "pending_cases",
        ...TableHeader.IS_SORTABLE
      }),
      new TableHeader({
        text: "Production",
        value: "production",
        map: "production",
        ...TableHeader.IS_SORTABLE
      })
    ];
    const pendingTable = new Table({
      text: "Pending Production",
      options: new TableOptions(["production"], [true]),
      headers: pendingHeaders,
      key: "pending"
    });

    return {
      tabs: [
        todaysTable,
        weeksTable,
        monthsTable,
        lastYearsTable,
        yearsTable,
        pendingTable
      ],
      lastTimeRefreshed: null,
      activeTab: 0,
      rawData: {},
      fontSizeOffset: 0,
      isCyclingThroughTables: false,
      isRefreshing: false,
      refreshInterval: null,
      cyclingInterval: null,
      loading: false,
      firstCreated: false
    };
  },
  computed: {
    ...mapWritableState(useInstanceStore, ["fullscreen"]),
    activeTabTitle() {
      return this.tabs[this.activeTab].text;
    },
    items() {
      if (!this.rawData[this.tabs[this.activeTab].key]) {
        return [];
      }
      return Object.values(this.rawData[this.tabs[this.activeTab].key]);
    },
    headers() {
      return this.tabs[this.activeTab].headers;
    },
    options: {
      get() {
        return this.tabs[this.activeTab].options;
      },
      set(value) {
        this.tabs[this.activeTab].options = value;
      }
    }
  },
  created() {
    if (!this.firstCreated) {
      this.firstCreated = true;
      this.getInforce();
    }
    this.handleIntervalTransfer();
  },
  beforeDestroy() {
    this.clearIntervals();
    this.fullscreen = false;
  },
  methods: {
    updateOptions(options) {
      this.options = options;
    },
    handleIntervalTransfer() {
      if (this.isCyclingThroughTables) {
        this.enableTableCycling();
      }
      if (this.isRefreshing) {
        this.enableDataRefresh();
      }
    },
    clearIntervals() {
      this.toggleDataRefresh(true);
      this.toggleTableCycling(true);
    },
    getInforce() {
      this.lastTimeRefreshed = format(new Date(), "h:mmaa");
      this.loading = true;
      getScorecardReport()
        .then(result => {
          if (result) this.rawData = result;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    incrementFontSizeOffset(positive = true) {
      this.fontSizeOffset += (positive ? 1 : -1) * FONT_SIZE_SCALER;
    },
    toggleDataRefresh(force = false) {
      if (this.isRefreshing || force) {
        this.disableDataRefresh();
        return;
      }
      this.enableDataRefresh();
    },
    disableDataRefresh() {
      clearInterval(this.refreshInterval);
      this.isRefreshing = false;
    },
    enableDataRefresh() {
      this.isRefreshing = true;
      this.refreshInterval = setInterval(this.getInforce, 900000);
    },
    toggleTableCycling(force = false) {
      if (this.isCyclingThroughTables || force) {
        this.disableTableCycling();
        return;
      }

      this.enableTableCycling();
    },
    enableTableCycling() {
      this.isCyclingThroughTables = true;
      this.refreshInterval = setInterval(this.cycleThroughTables, 10000);
    },
    disableTableCycling() {
      clearInterval(this.cyclingInterval);
      this.isCyclingThroughTables = false;
    },
    cycleThroughTables() {
      this.activeTab = (this.activeTab + 1) % this.tabs.length;
    }
  }
};
</script>
