import { getHttpClient } from "@/http-client";
const baseUrl = "api/boss/search";

export function advisorSearch(advisorName, { employee = false } = {}) {
  const body = {
    search: advisorName
  };
  if (employee) body.employee = true;
  return getHttpClient().post(`${baseUrl}/advisors`, body);
}

export function simpleAdvisorSearch(advisorName) {
  return getHttpClient().post(`${baseUrl}/advisors_simple`, {
    search: advisorName
  });
}

export function simpleAdvisorPaymentSearch(advisorName) {
  return getHttpClient().post(`${baseUrl}/advisors_payments_simple`, {
    search: advisorName
  });
}

export function agentSearch(search, assignable = false) {
  const body = {
    search
  };

  if (assignable) body.assignable = true;
  return getHttpClient().post(`${baseUrl}/agents`, body);
}

function CompBuilderFactory(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    street: model?.street,
    setFromRequest(rmodel = {}) {
      this.id = rmodel.id;
      this.name = rmodel.name;
      this.street = rmodel.street;
    }
  };
}

export async function compBuilderSearch(id) {
  const { data } = await getHttpClient().post(`${baseUrl}/comp_builders`, {
    carrier_id: id
  });

  const compBuilders = [];
  data.comp_builders.forEach(rCompBuilder => {
    const compBuilder = CompBuilderFactory();
    compBuilder.setFromRequest(rCompBuilder);
    compBuilders.push(compBuilder);
  });

  return compBuilders;
}

export async function productSearch(id, line = null) {
  const params = new URLSearchParams();
  params.append("carrier", id);
  params.append("primary_product", "true");
  if (line) {
    line.forEach(line => {
      params.append("lines[]", line);
    });
  }
  const { data } = await getHttpClient().get(`${baseUrl}/products`, { params });
  const p = data.products;
  p.sort((a, b) => a.name.localeCompare(b.name));
  return p;
}

export function marketingManagerSearch(name) {
  return getHttpClient().post(`${baseUrl}/mms`, {
    search: name
  });
}

export function searchCaseManagers(name) {
  const params = new URLSearchParams();
  params.append("name", name);
  return getHttpClient().get(`${baseUrl}/search_case_managers_simple`, {
    params
  });
}

export async function userSearch(email) {
  const { data } = await getHttpClient().post(`${baseUrl}/users`, {
    email: email
  });

  const users = [...data.users];
  users.sort((a, b) => a.email.localeCompare(b.email));
  return users;
}

export async function policyNumberSearch(value) {
  const { data } = await getHttpClient().post(`${baseUrl}/cases`, {
    policy_number: value,
    with_carrier_payor: true
  });

  return data.cases;
}

export async function simplePolicyNumberSearch(value) {
  const { data } = await getHttpClient().post(`${baseUrl}/cases_simple`, {
    policy_number: value,
    with_carrier_payor: true
  });
  return data.cases;
}

export function payorSearch(search) {
  return getHttpClient().post(`${baseUrl}/payors`, {
    search: search
  });
}

export async function audienceSearch(carrier_id, name) {
  const params = new URLSearchParams();
  params.append("carrier_id", carrier_id);
  params.append("name", name);
  const { data } = await getHttpClient().get(`${baseUrl}/audience`, { params });
  return data.audience.map(setAudienceItemFromResponse);
}

export function advisorAppointmentSearch(name, carrierId) {
  const params = new URLSearchParams();
  params.append("carrier_id", carrierId);
  params.append("search", name);
  return getHttpClient().get(`${baseUrl}/advisors_custom_assignable`, {
    params
  });
}

export async function personnelSearch({ name, carrierId }) {
  const body = { name: undefined, carrier: undefined };
  if (name) body.name = name;
  if (carrierId) body.carrier = carrierId;
  const { data } = await getHttpClient().post(`${baseUrl}/personnel`, body);
  return data.personnel;
}

function AudienceItem(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    type: model?.type,
    email: model?.email
  };
}

function setAudienceItemFromResponse(raw = {}) {
  const audienceItem = AudienceItem();
  audienceItem.id = raw.id;
  audienceItem.name = raw.name;
  audienceItem.type = raw.type;
  audienceItem.email = raw.email;
  return audienceItem;
}
