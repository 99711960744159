<template>
  <v-row dense>
    <v-col cols="12">
      <v-divider />
    </v-col>

    <v-col cols="12">
      <v-card flat tile>
        <v-card-title class="text-h6">
          {{ typeLabel }} General Information
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12">
              <v-text-field
                data-lpignore="true"
                :disabled="isGroupOne"
                prepend-inner-icon="$mdi-calendar-month"
                outlined
                dense
                :label="`${typeLabel} Website`"
                v-model="lineDetails.website"
                :success="Boolean(lineDetails.website)"
                @input="updateAttribute('website')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['website']"
                  />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                :disabled="isGroupOne"
                :success="Boolean(lineDetails.details)"
                outlined
                dense
                prepend-inner-icon="$mdi-text"
                label="Notes"
                v-model="lineDetails.details"
                auto-grow
                @input="updateAttribute('details')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['details']"
                  />
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-divider />
    </v-col>

    <v-col cols="12">
      <v-card flat tile>
        <v-card-title class="text-h6">
          {{ typeLabel }} New Business
        </v-card-title>
        <v-card-subtitle
          v-if="
            lineDetails.newApplicationPersonnel ||
            lineDetails.newBusinessPersonnel
          "
        >
          <v-row dense>
            <v-col v-if="lineDetails.newApplicationPersonnel" cols="12" md="4">
              <span class="grey--text lighten-2">New Application Email</span>
              <br />
              <a :href="`mailto:${lineDetails.newApplicationPersonnel.email}`">
                {{ lineDetails.newApplicationPersonnel.email }}
              </a>
            </v-col>
            <v-col v-if="lineDetails.newBusinessPersonnel" cols="12" md="4">
              <span class="grey--text lighten-2">New Business Email</span>
              <br />
              <a :href="`mailto:${lineDetails.newBusinessPersonnel.email}`">
                {{ lineDetails.newBusinessPersonnel.email }}
              </a>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="6">
              <personnel-search
                v-model="lineDetails.newApplicationPersonnel"
                clearable
                :disabled="!isGroupThreePlus"
                :label="`${typeLabel} New Application Personnel`"
                :placeholder="`Search ${typeLabel} New Application Personnel`"
                :carrier-id="carrier.id"
                :success="
                  Boolean(
                    lineDetails.newApplicationPersonnel &&
                      lineDetails.newApplicationPersonnel.id
                  )
                "
                @input="updateAttribute('newApplicationPersonnel')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['newApplicationPersonnel']"
                  />
                </template>
              </personnel-search>
            </v-col>
            <v-col cols="12" md="6">
              <personnel-search
                v-model="lineDetails.newBusinessPersonnel"
                clearable
                :disabled="!isGroupThreePlus"
                :label="`${typeLabel} New Business Personnel`"
                :placeholder="`Search ${typeLabel} New Business Personnel`"
                :carrier-id="carrier.id"
                :success="
                  Boolean(
                    lineDetails.newBusinessPersonnel &&
                      lineDetails.newBusinessPersonnel.id
                  )
                "
                @input="updateAttribute('newBusinessPersonnel')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['newBusinessPersonnel']"
                  />
                </template>
              </personnel-search>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                data-lpignore="true"
                :disabled="isGroupOne"
                prepend-inner-icon="$mdi-deskphone"
                outlined
                dense
                :label="`${typeLabel} New Business Phone`"
                v-model="lineDetails.newBusinessPhone"
                :success="Boolean(lineDetails.newBusinessPhone)"
                @input="updatePhoneAttribute('newBusinessPhone')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['newBusinessPhone']"
                  />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                data-lpignore="true"
                :disabled="isGroupOne"
                prepend-inner-icon="$mdi-deskphone"
                outlined
                dense
                :label="`${typeLabel} New Business Fax`"
                v-model="lineDetails.newBusinessFax"
                :success="Boolean(lineDetails.newBusinessFax)"
                @input="updatePhoneAttribute('newBusinessFax')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['newBusinessFax']"
                  />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <basic-address-input
                autofill-placeholder="New Business Address"
                autofill-label="New Business Address"
                :disabled="isGroupOne"
                :street-address-model.sync="
                  lineDetails.addresses.new_business.street_address
                "
                :street-address-validation="streetAddressValidation"
                @update:street-address-model="
                  updateAddressAttribute('address-newBusiness-street_address')
                "
                :city-model.sync="lineDetails.addresses.new_business.city"
                :city-validation="cityValidation"
                @update:city-model="
                  updateAddressAttribute('address-newBusiness-city')
                "
                :state-model.sync="lineDetails.addresses.new_business.state"
                :state-validation="stateValidation"
                @update:state-model="
                  updateAddressAttribute('address-newBusiness-state')
                "
                :zip-model.sync="lineDetails.addresses.new_business.zip"
                :zip-validation="zipValidation"
                @update:zip-model="
                  updateAddressAttribute('address-newBusiness-zip')
                "
                @autofill="handleAutofill"
              >
                <template #append-outer-street-address>
                  <active-save-indicator
                    :controller="
                      savingBuffer['address-newBusiness-street_address']
                    "
                  />
                </template>
                <template #append-outer-city>
                  <active-save-indicator
                    :controller="savingBuffer['address-newBusiness-city']"
                  />
                </template>
                <template #append-outer-state>
                  <active-save-indicator
                    :controller="savingBuffer['address-newBusiness-state']"
                  />
                </template>
                <template #append-outer-zip>
                  <active-save-indicator
                    :controller="savingBuffer['address-newBusiness-zip']"
                  />
                </template>
              </basic-address-input>
            </v-col>
            <v-col cols="12">
              <v-textarea
                :disabled="isGroupOne"
                v-model="lineDetails.eSignature"
                :success="Boolean(lineDetails.eSignature)"
                outlined
                dense
                prepend-inner-icon="$mdi-draw"
                @input="updateAttribute('eSignature')"
              >
                <template #label>
                  {{ typeLabel }} eSignature
                  <active-save-indicator
                    class="ml-2"
                    :controller="savingBuffer['eSignature']"
                  />
                </template>
              </v-textarea>
            </v-col>
            <v-col cols="12" md="4">
              <div class="checkbox-width">
                <v-checkbox
                  v-model="autoSubmit"
                  :disabled="!isGroupThreePlus"
                  :success="Boolean(lineDetails.autoSubmit)"
                  class="mt-1"
                  :key="autoSubmitKey"
                >
                  <template #label>
                    Auto Submit
                    <active-save-indicator
                      :controller="savingBuffer.autoSubmit"
                    />
                  </template>
                </v-checkbox>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="checkbox-width">
                <v-checkbox
                  :disabled="isGroupOne"
                  class="mt-1"
                  outlined
                  true-value="Yes"
                  false-value="No"
                  v-model="lineDetails.requiresOriginal"
                  success
                  @change="updateAttribute('requiresOriginal')"
                >
                  <template #label>
                    {{ typeLabel }} Requires Original Application
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer['requiresOriginal']"
                    />
                  </template>
                </v-checkbox>
              </div>
            </v-col>

            <v-col cols="12" md="4">
              <div class="checkbox-width">
                <v-checkbox
                  :disabled="!isGroupThreePlus"
                  class="mt-1"
                  v-model="lineDetails.eDelivery"
                  success
                  @change="updateAttribute('eDelivery')"
                >
                  <template #label>
                    {{ typeLabel }} eDelivery
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer['eDelivery']"
                    />
                  </template>
                </v-checkbox>
              </div>
            </v-col>

            <v-col cols="12" md="4">
              <div class="checkbox-width">
                <v-checkbox
                  :disabled="!isGroupThreePlus"
                  class="mt-1"
                  v-model="lineDetails.gaCanOrderAps"
                  success
                  @change="updateAttribute('gaCanOrderAps')"
                >
                  <template #label>
                    BackNine can order APS for {{ typeLabel }}
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer['gaCanOrderAps']"
                    />
                  </template>
                </v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-divider />
    </v-col>

    <v-col cols="12">
      <v-card flat tile>
        <v-card-title class="text-h6">
          {{ typeLabel }} Customer Service
        </v-card-title>
        <v-card-subtitle v-if="lineDetails.customerServicePersonnel">
          <v-row dense>
            <v-col cols="12" md="4">
              <span class="grey--text lighten-2">New Application Email</span>
              <br />
              <a :href="`mailto:${lineDetails.customerServicePersonnel.email}`">
                {{ lineDetails.customerServicePersonnel.email }}
              </a>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="4">
              <personnel-search
                v-model="lineDetails.customerServicePersonnel"
                clearable
                :disabled="!isGroupThreePlus"
                :label="`${typeLabel} Customer Service Personnel`"
                :placeholder="`Search ${typeLabel} Customer Service Personnel`"
                :carrier-id="carrier.id"
                :success="
                  Boolean(
                    lineDetails.customerServicePersonnel &&
                      lineDetails.customerServicePersonnel.id
                  )
                "
                @input="updateAttribute('customerServicePersonnel')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['customerServicePersonnel']"
                  />
                </template>
              </personnel-search>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                data-lpignore="true"
                :disabled="isGroupOne"
                prepend-inner-icon="$mdi-monitor-cellphone"
                outlined
                dense
                :label="`${typeLabel} Customer Service Desk`"
                v-model="lineDetails.customerServicePhone"
                :success="Boolean(lineDetails.customerServicePhone)"
                @input="updatePhoneAttribute('customerServicePhone')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['customerServicePhone']"
                  />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-divider />
    </v-col>

    <v-col cols="12">
      <v-card flat tile>
        <v-card-title class="text-h6">
          {{ typeLabel }} Commissions and Licensing
        </v-card-title>
        <v-card-subtitle v-if="lineDetails.licensingPersonnel">
          <v-row dense>
            <v-col cols="12" md="4">
              <span class="grey--text lighten-2">Licensing Email</span>
              <br />
              <a :href="`mailto:${lineDetails.licensingPersonnel.email}`">
                {{ lineDetails.licensingPersonnel.email }}
              </a>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                data-lpignore="true"
                :disabled="isGroupOne"
                prepend-inner-icon="$mdi-card-account-phone"
                outlined
                dense
                :label="`${typeLabel} Licensing Phone`"
                v-model="lineDetails.licensingPhone"
                :success="Boolean(lineDetails.licensingPhone)"
                @input="updatePhoneAttribute('licensingPhone')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['licensingPhone']"
                  />
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <personnel-search
                v-model="lineDetails.licensingPersonnel"
                :disabled="!isGroupThreePlus"
                clearable
                :label="`${typeLabel} Licensing Personnel`"
                :placeholder="`Search ${typeLabel} Licensing Personnel`"
                :carrier-id="carrier.id"
                @input="updateAttribute('licensingPersonnel')"
                :success="
                  Boolean(
                    lineDetails.licensingPersonnel &&
                      lineDetails.licensingPersonnel.id
                  )
                "
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['licensingPersonnel']"
                  />
                </template>
              </personnel-search>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                data-lpignore="true"
                :disabled="isGroupOne"
                prepend-inner-icon="$mdi-calendar-month"
                outlined
                dense
                :label="`${typeLabel} Multiple Appointments`"
                v-model="lineDetails.multipleAppointments"
                :success="Boolean(lineDetails.multipleAppointments)"
                @input="updateAttribute('multipleAppointments')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['multipleAppointments']"
                  />
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <div class="checkbox-width">
                <v-checkbox
                  :disabled="!isGroupThreePlus"
                  class="mt-1"
                  hide-details
                  v-model="lineDetails.annualization"
                  success
                  @change="updateAttribute('annualization')"
                  true-value="true"
                  false-value="false"
                >
                  <template #label>
                    {{ typeLabel }} Annualization
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer['annualization']"
                    />
                  </template>
                </v-checkbox>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                data-lpignore="true"
                :disabled="!lineDetails.annualization || isGroupOne"
                prepend-inner-icon="$mdi-account-details"
                outlined
                dense
                :label="`${typeLabel} Annualization Details`"
                v-model="lineDetails.annualizationDetails"
                :success="Boolean(lineDetails.annualizationDetails)"
                @input="updateAttribute('annualizationDetails')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['annualizationDetails']"
                  />
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <div class="checkbox-width">
                <v-checkbox
                  :disabled="!isGroupThreePlus"
                  class="mt-1"
                  v-model="lineDetails.paysFullOverrideDirect"
                  success
                  @change="updateAttribute('paysFullOverrideDirect')"
                >
                  <template #label>
                    {{ typeLabel }} Pays Full Override Direct
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer['paysFullOverrideDirect']"
                    />
                  </template>
                </v-checkbox>
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                :disabled="!isGroupThreePlus"
                prepend-inner-icon="$mdi-clock"
                outlined
                dense
                :label="`${typeLabel} BackNine Payment Schedule`"
                v-model="lineDetails.paymentSchedule"
                :success="Boolean(lineDetails.paymentSchedule)"
                item-value="text"
                :items="[{ text: 'Standard' }, { text: 'Express' }]"
                @change="updateAttribute('paymentSchedule')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['paymentSchedule']"
                  />
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" v-if="isAnnuity">
              <v-textarea
                :disabled="isGroupOne"
                prepend-inner-icon="$mdi-school"
                outlined
                dense
                :label="`${typeLabel} Product Training `"
                v-model="lineDetails.annuityProductTraining"
                :success="Boolean(lineDetails.annuityProductTraining)"
                @input="updateAttribute('annuityProductTraining')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['annuityProductTraining']"
                  />
                </template>
              </v-textarea>
            </v-col>

            <v-col cols="12" v-if="!isDisability && !isLTC">
              <v-textarea
                :disabled="isGroupOne"
                prepend-inner-icon="$mdi-school"
                outlined
                dense
                :label="`${typeLabel} Reg 187 Training`"
                v-model="lineDetails.reg187Training"
                :success="Boolean(lineDetails.reg187Training)"
                @input="updateAttribute('reg187Training')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['reg187Training']"
                  />
                </template>
              </v-textarea>
            </v-col>

            <v-col cols="12">
              <v-textarea
                data-lpignore="true"
                :disabled="isGroupOne"
                prepend-inner-icon="$mdi-file-document-outline"
                outlined
                dense
                :label="`${typeLabel} Licensing Instructions `"
                v-model="lineDetails.licensingInstructions"
                :success="Boolean(lineDetails.licensingInstructions)"
                @input="updateAttribute('licensingInstructions')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['licensingInstructions']"
                  />
                </template>
              </v-textarea>
            </v-col>

            <v-col cols="12">
              <h3>
                Pre-Appointment States
                <active-save-indicator
                  v-if="!isGroupThreePlus"
                  :controller="savingBuffer['preappointmentStates']"
                />
              </h3>
              <template v-if="!isGroupThreePlus">
                <p v-if="lineDetails.preappointmentStates.length === 1">
                  {{ preappointmentStatesSentence }} is {{ carrier.name }}'s
                  only preappointment state.
                </p>
                <p v-else-if="lineDetails.preappointmentStates.length > 1">
                  Preappointment States for {{ carrier.name }} are
                  {{ preappointmentStatesSentence }}
                </p>
                <p v-else>
                  {{ carrier.name }} has no
                  {{ typeLabel.toLowerCase() }} pre-appointment states.
                </p>
              </template>
              <v-chip-group
                v-else
                column
                multiple
                v-model="lineDetails.preappointmentStates"
                @change="updateAttribute('preappointmentStates')"
              >
                <v-chip
                  v-for="state in states"
                  :key="state.text"
                  :class="{
                    primary: inPreAppointmentStates(state.text)
                  }"
                  :value="state.text"
                >
                  {{ state.full }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-divider />
    </v-col>

    <v-col cols="12">
      <v-card flat tile>
        <v-card-title class="text-h6">
          Additional {{ typeLabel }} Information
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                data-lpignore="true"
                :disabled="isGroupOne"
                prepend-inner-icon="$mdi-cellphone-basic"
                outlined
                dense
                :label="`${typeLabel} Sales Desk`"
                v-model="lineDetails.salesDeskPhone"
                :success="Boolean(lineDetails.salesDeskPhone)"
                @input="updatePhoneAttribute('salesDeskPhone')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['salesDeskPhone']"
                  />
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6" @click="showChangeQuickQuoteEmailDialog">
              <v-text-field
                v-model="lineDetails.quickQuoteEmail"
                readonly
                prepend-inner-icon="$mdi-mail"
                outlined
                dense
                :label="`${typeLabel} Quick Quote Email`"
                :disabled="isGroupOne"
                :success="Boolean(lineDetails.quickQuoteEmail)"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import PersonnelSearch from "@/components/shared/PersonnelSearch.vue";

import { fullStateDict, states } from "@/data/states";
import { updateCarrierAddress, updateCarrier } from "@/api/carrier.service";
import { listToSentence } from "@/util/helpers";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { CarrierLineEmailsFactory } from "@/factories/VerboseCarrierFactory";

const LINES = {
  life: "Life",
  disability: "Disability",
  annuity: "Annuity",
  ltc: "LTC"
};
export default {
  components: {
    ActiveSaveIndicator,
    BasicAddressInput,
    PersonnelSearch
  },
  mixins: [ActiveSaveMixin],
  props: {
    carrier: Object,
    type: String
  },
  data() {
    const line = this.type;
    const phoneKey = `${line}Phone`;

    const addresses = {};
    this.carrier.addresses
      .filter(address => address.addressType.includes(line))
      .forEach(address => {
        const key = address.addressType.replace(`${line},`, "");
        addresses[key] = address;
      });
    const lineDetails = {
      details: this.carrier[line].details,
      licensingPhone: this.carrier[phoneKey].licensing,
      licensingInstructions: this.carrier.sendContracting[line],
      multipleAppointments: this.carrier.multipleAppointments[line],
      autoSubmit: this.carrier.autoSubmit[line],
      gaCanOrderAps: this.carrier.gaCanOrderAps[line],

      licensingPersonnel: this.carrier.personnel[line].licensing,
      newApplicationPersonnel: this.carrier.personnel[line].newApplication,
      newBusinessPersonnel: this.carrier.personnel[line].newBusiness,
      customerServicePersonnel: this.carrier.personnel[line].customerService,

      quickQuoteEmail: this.carrier.emails[line].quickQuote,
      newBusinessPhone: this.carrier[phoneKey].newBusiness,
      newBusinessFax: this.carrier[phoneKey].newBusinessFax,
      salesDeskPhone: this.carrier[phoneKey].salesDesk,
      customerServicePhone: this.carrier[phoneKey].customerService,
      requiresOriginal: this.carrier.requiresOriginalApplication[line],
      annualization: this.carrier.annualization[line],
      annualizationDetails: this.carrier.annualizationDetails[line],
      eDelivery: this.carrier.eDelivery[line],
      paysFullOverrideDirect: this.carrier.paysFullOverride[line],
      paymentSchedule: this.carrier.paymentSchedule[line],
      addresses,
      eSignature: this.carrier.eSignature[line],
      preappointmentStates: this.carrier.preappointmentStates[line] || [],
      website: this.carrier.websites[line] || ""
    };
    if (LINES[line] !== LINES.disability && LINES[line] !== LINES.ltc) {
      lineDetails.reg187Training = this.carrier.reg187[line];
    }

    if (LINES[line] === LINES.annuity) {
      lineDetails.annuityProductTraining = this.carrier.training;
    }

    const savingBuffer = {};
    Object.keys(lineDetails).forEach(key => {
      if (key === "addresses") {
        Object.keys(lineDetails[key]).forEach(addressKey => {
          let feType = addressKey;
          if (feType === "new_business") {
            feType = "newBusiness";
          }
          savingBuffer[`address-${feType}-street_address`] =
            this.activeSaveElementFactory();
          savingBuffer[`address-${feType}-state`] =
            this.activeSaveElementFactory();
          savingBuffer[`address-${feType}-city`] =
            this.activeSaveElementFactory();
          savingBuffer[`address-${feType}-zip`] =
            this.activeSaveElementFactory();
        });
        return;
      }
      savingBuffer[key] = this.activeSaveElementFactory();
    });
    return {
      lineDetails,
      savingBuffer,
      states,
      autoSubmitKey: new Date().getTime(),
      quickQuoteEmailIsValid: null
    };
  },
  computed: {
    ...mapState(useUserStore, ["isGroupOne", "isGroupThreePlus"]),
    streetAddressValidation() {
      let success = null;
      if (this.lineDetails.addresses.new_business.street_address)
        success = true;

      return { success, errorMessages: [] };
    },
    cityValidation() {
      let success = null;
      if (this.lineDetails.addresses.new_business.city) success = true;

      return { success, errorMessages: [] };
    },
    stateValidation() {
      let success = null;
      if (this.lineDetails.addresses.new_business.state) success = true;

      return { success, errorMessages: [] };
    },
    zipValidation() {
      let success = null;
      if (this.lineDetails.addresses.new_business.zip) success = true;

      return { success, errorMessages: [] };
    },
    autoSubmit: {
      get() {
        return this.lineDetails.autoSubmit;
      },
      set(v) {
        const pretext = v ? "Enable" : "Disable";
        this.showDialog({
          component: "ConfirmationDialog",
          title: `${pretext} ${this.carrier.name} Auto Submission`,
          subtitle: "Please confirm your intent to change auto submission."
        }).then(res => {
          if (!res.confirm) {
            this.autoSubmitKey = new Date().getTime();
            return;
          }
          this.lineDetails.autoSubmit = v;
          this.updateAttribute("autoSubmit");
          this.autoSubmitKey = new Date().getTime();
        });
      }
    },
    typeLabel() {
      return LINES[this.type];
    },
    isDisability() {
      return LINES[this.type] === LINES.disability;
    },
    isAnnuity() {
      return LINES[this.type] === LINES.annuity;
    },
    isLTC() {
      return LINES[this.type] === LINES.ltc;
    },
    preappointmentStatesSentence() {
      const states = this.lineDetails.preappointmentStates.map(
        val => fullStateDict[val]
      );
      return listToSentence(states);
    }
  },
  methods: {
    ...mapActions(useDialogStore, ["showDialog"]),
    showChangeQuickQuoteEmailDialog() {
      if (this.isGroupOne) return;
      this.showDialog({
        component: "ChangeEmailDialog",
        clearable: true,
        value: this.lineDetails.quickQuoteEmail || "",
        storeFunc: v => (this.lineDetails.quickQuoteEmail = v),
        saveFunc: () => this.updateAttribute("quickQuoteEmail")
      });
    },
    updateAttribute(attribute) {
      if (this.isGroupOne) return;
      if (this.savingBuffer[attribute].timer)
        clearTimeout(this.savingBuffer[attribute].timer);
      this.savingBuffer[attribute].timer = setTimeout(() => {
        this.updateAttributeWrapper(
          () =>
            updateCarrier(this.carrier.id, {
              carrier: this.attributeToRequestData(attribute)
            }),
          this.savingBuffer[attribute]
        );
      }, 300);
    },
    handleAutofill({ city, state, street_address, zip }) {
      this.lineDetails.addresses.new_business.city = city;
      this.lineDetails.addresses.new_business.state = state;
      this.lineDetails.addresses.new_business.street_address = street_address;
      this.lineDetails.addresses.new_business.zip = zip;
      return this.saveAddress();
    },
    saveAddress() {
      const addressType = `${this.type},new_business`;
      return updateCarrierAddress(this.carrier.id, {
        address_type: addressType,
        city: this.lineDetails.addresses.new_business.city,
        state: this.lineDetails.addresses.new_business.state,
        street_address: this.lineDetails.addresses.new_business.street_address,
        zip: this.lineDetails.addresses.new_business.zip
      });
    },
    updateAddressAttribute(attribute) {
      if (this.isGroupOne) {
        return;
      }
      if (this.savingBuffer[attribute].timer)
        clearTimeout(this.savingBuffer[attribute].timer);
      this.savingBuffer[attribute].timer = setTimeout(() => {
        this.updateAttributeWrapper(
          () => this.saveAddress(),
          this.savingBuffer[attribute]
        );
      }, 300);
    },
    updatePhoneAttribute(attribute) {
      if (this.isGroupOne) {
        return;
      }
      const phoneKey = `${this.type}_phone`;
      const phoneNumbers = {
        customer_service: this.lineDetails.customerServicePhone,
        licensing: this.lineDetails.licensingPhone,
        licensing_fax: this.carrier[phoneKey]?.licensingFax,
        new_business: this.lineDetails.newBusinessPhone,
        new_business_fax: this.lineDetails.newBusinessFax,
        sales_desk: this.lineDetails.salesDeskPhone
      };

      if (this.savingBuffer[attribute].timer)
        clearTimeout(this.savingBuffer[attribute].timer);

      this.savingBuffer[attribute].timer = setTimeout(() => {
        this.updateAttributeWrapper(
          () =>
            updateCarrier(this.carrier.id, {
              carrier: { [phoneKey]: JSON.stringify(phoneNumbers) }
            }),
          this.savingBuffer[attribute]
        );
      }, 300);
    },
    attributeToRequestData(attribute) {
      switch (attribute) {
        case "eSignature": {
          const e_signature = this.carrier.eSignature;
          e_signature[this.type] = this.lineDetails.eSignature;
          return { e_signature };
        }
        case "licensingInstructions": {
          const send_contracting = this.carrier.sendContracting;
          send_contracting[this.type] = this.lineDetails.licensingInstructions;
          return { send_contracting };
        }
        case "customerServicePersonnel":
          return {
            [`customer_service_${this.type}_id`]:
              this.lineDetails.customerServicePersonnel?.id || null
          };
        case "licensingPersonnel":
          return {
            [`licensing_${this.type}_id`]:
              this.lineDetails.licensingPersonnel?.id || null
          };
        case "newApplicationPersonnel":
          return {
            [`new_app_${this.type}_id`]:
              this.lineDetails.newApplicationPersonnel?.id || null
          };
        case "newBusinessPersonnel":
          return {
            [`new_business_${this.type}_id`]:
              this.lineDetails.newBusinessPersonnel?.id || null
          };
        case "multipleAppointments": {
          const multipleAppointments = this.carrier.multipleAppointments;
          multipleAppointments[this.type] =
            this.lineDetails.multipleAppointments;
          return { multiple_appointments: multipleAppointments };
        }
        case "quickQuoteEmail": {
          const email = CarrierLineEmailsFactory(this.carrier.emails);
          email[this.type].quickQuote = this.lineDetails.quickQuoteEmail;
          return { emails: email.toUpdateRequest() };
        }
        case "requiresOriginal": {
          const requiresOriginal = this.carrier.requiresOriginalApplication;
          requiresOriginal[this.type] = this.lineDetails.requiresOriginal;
          return { requires_original_application: requiresOriginal };
        }
        case "eDelivery": {
          const eDelivery = this.carrier.eDelivery;
          eDelivery[this.type] = this.lineDetails.eDelivery;
          return { e_delivery: eDelivery };
        }
        case "gaCanOrderAps": {
          const gaCanOrderAps = this.carrier.gaCanOrderAps;
          gaCanOrderAps[this.type] = this.lineDetails.gaCanOrderAps;
          return { e_delivery: gaCanOrderAps };
        }
        case "annualization": {
          const annualization = this.carrier.annualization;
          annualization[this.type] = this.lineDetails.annualization;
          return { annualization: annualization };
        }
        case "annualizationDetails": {
          const annualizationDetails = this.carrier.annualizationDetails;
          annualizationDetails[this.type] =
            this.lineDetails.annualizationDetails;
          return { annualization_details: annualizationDetails };
        }
        case "website": {
          const websites = this.carrier.websites;
          websites[this.type] = this.lineDetails.website;
          return { websites };
        }
        case "paysFullOverrideDirect": {
          const paysFullOverrideDirect = this.carrier.paysFullOverride;
          paysFullOverrideDirect[this.type] =
            this.lineDetails.paysFullOverrideDirect;
          return { pays_full_override: paysFullOverrideDirect };
        }
        case "paymentSchedule": {
          const paymentSchedule = this.carrier.paymentSchedule;
          paymentSchedule[this.type] = this.lineDetails.paymentSchedule;
          return { payment_schedule: paymentSchedule };
        }
        case "reg187Training":
          if (!this.isDisability && !this.isLTC) {
            const reg187Training = this.carrier.reg187;
            reg187Training[this.type] = this.lineDetails.reg187Training;
            return { reg_187: reg187Training };
          }
          break;
        case "annuityProductTraining":
          if (this.isAnnuity) {
            const annuityTraining = this.lineDetails.annuityProductTraining;
            return { product_training: annuityTraining };
          }
          break;
        case "preappointmentStates": {
          const preappointmentStates = this.carrier.preappointmentStates;
          preappointmentStates[this.type] =
            this.lineDetails.preappointmentStates;
          // eslint-disable-next-line
          const { iter, ...preappointment_states } = preappointmentStates;
          return { preappointment_states };
        }
        case "details":
          return { [`${this.type}_details`]: this.lineDetails.details };
        case "autoSubmit": {
          const autoSubmit = this.carrier.autoSubmit;
          autoSubmit[this.type] = this.lineDetails.autoSubmit;
          return { auto_submit: autoSubmit };
        }
      }
    },
    inPreAppointmentStates(state) {
      if (!this.lineDetails.preappointmentStates) return false;
      return this.lineDetails.preappointmentStates.includes(state);
    }
  }
};
</script>
