const INFLATION_ITEMS = [
  { text: "None", value: null },
  { text: "Deferred", value: "Deferred" },
  { text: "1% Compound", value: "1% Compound" },
  { text: "2% Compound", value: "2% Compound" },
  { text: "3% Simple", value: "3% Simple" },
  { text: "3% Compound", value: "3% Compound" },
  { text: "3% Compound for 20 Years", value: "3% Compound for 20 Years" },
  { text: "4% Compound", value: "4% Compound" },
  { text: "5% Compound", value: "5% Compound" },
  { text: "5% Compound for 20 Years", value: "5% Compound for 20 Years" },
  { text: "5% Compound Limit 2X", value: "5% Compound Limit 2X" },
  { text: "5% Simple", value: "5% Simple" },
  { text: "5% Step Rated", value: "5% Step Rated" },
  { text: "6% Compound", value: "6% Compound" },
  { text: "CPI", value: "CPI" },
  { text: "US Medical Care Inflation", value: "US Medical Care Inflation" }
];

const discountItems = [
  { text: "None", value: null },
  { text: "Association", value: "Association" },
  { text: "Employer", value: "Employer" },
  { text: "Producer", value: "Producer" }
];

const BENEFIT_PERIODS = [
  { text: "1 Year" },
  { text: "2 Years" },
  { text: "3 Years" },
  { text: "4 Years" },
  { text: "5 Years" },
  { text: "6 Years" },
  { text: "7 Years" },
  { text: "8 Years" },
  { text: "9 Years" },
  { text: "10 Years" },
  { text: "Max" }
];

const monthlyBenefitSolveItems = [{ text: "Max" }, { text: "Specify Amount" }];

const DISABILITY_BENEFIT_PERIODS = [
  { text: "Lump Sum", value: "Lump Sum" },
  { text: "1 Year", value: "1 Year" },
  { text: "18 Months", value: "18 Months" },
  { text: "2 Years", value: "2 Years" },
  { text: "5 Years", value: "5 Years" },
  { text: "10 Years", value: "10 Years" },
  { text: "To Age 65", value: "To Age 65" },
  { text: "To Age 67 (recommended)", value: "To Age 67" },
  { text: "To Age 70", value: "To Age 70" }
];

const disabilityTypeItems = [
  { text: "Business Overhead" },
  { text: "Buy Sell" },
  { text: "Individual Disability" },
  { text: "Key Person" }
];

const tobaccoUseItems = [
  { text: "Non-Tobacco", value: false },
  { text: "Tobacco Use", value: true }
];

export const maritalStatusItems = ["Married", "Single", "Domestic Parter"];

export {
  INFLATION_ITEMS,
  discountItems,
  BENEFIT_PERIODS,
  monthlyBenefitSolveItems,
  DISABILITY_BENEFIT_PERIODS,
  disabilityTypeItems,
  tobaccoUseItems
};
