import { getHttpClient } from "@/http-client";
const baseUrl = "api/boss/builds";

let cancelToken = null;
export function getBuilds(params) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  return getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });
}

export function deleteBuild(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}

export function createBuild({
  build_chart_id,
  inches,
  rating,
  min_weight,
  max_weight
}) {
  return getHttpClient().post(baseUrl, {
    build: {
      build_chart_id,
      inches,
      rating,
      min_weight,
      max_weight
    }
  });
}

export function updateBuild(
  build_id,
  { build_chart_id, inches, rating, min_weight, max_weight }
) {
  return getHttpClient().put(`${baseUrl}/${build_id}`, {
    build: {
      build_chart_id,
      inches,
      rating,
      min_weight,
      max_weight
    }
  });
}
