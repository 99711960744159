<template>
  <v-autocomplete
    outlined
    dense
    hide-no-data
    auto-select-first
    return-object
    ref="formSetSearchText"
    autocomplete="false"
    prepend-inner-icon="$mdi-folder-multiple"
    placeholder="Search Form Sets"
    label="Form Set"
    v-model="formSet"
    v-on="$listeners"
    :clearable="clearable"
    :disabled="disabled"
    :success="success"
    :error-messages="errorMessages"
    :items="formSets"
    :loading="loading"
    :hide-details="hideDetails"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    :item-text="val => `${val.id} · ${val.name}`"
  >
    <template #append-outer v-if="hasAppendOuterSlot">
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import sortBy from "lodash/sortBy";
import { getAllFormSets } from "@/api/form-sets.service";
import { parseErrorMessage } from "@/util/helpers";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  props: {
    clearable: Boolean,
    success: Boolean,
    errorMessages: Array,
    value: [Object, String],
    disabled: Boolean,
    hideDetails: Boolean
  },
  data() {
    let formSets = [];
    let formSet = this.value || null;
    return {
      loading: false,
      formSets,
      formSet
    };
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots["append-outer"];
    }
  },
  created() {
    this.getFormSets();
  },
  watch: {
    value(value) {
      if (this.formSet?.id === value?.id) return;
      this.$set(this, "formSet", value);
    },
    formSet(value) {
      this.$emit("input", value);
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    focus() {
      this.$refs.formSetSearchText.$refs.input.focus();
    },
    clear() {
      this.formSet = null;
    },
    async getFormSets() {
      this.loading = true;
      try {
        const formSets = await getAllFormSets();
        this.formSets = sortBy(formSets, "id");
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
