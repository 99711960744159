<template>
  <v-autocomplete
    autocomplete="false"
    outlined
    dense
    return-object
    v-model="form"
    item-text="name"
    placeholder="Search Forms"
    prepend-inner-icon="$mdi-file-document"
    no-filter
    hide-no-data
    v-on="$listeners"
    :label="label"
    :search-input.sync="formSearchText"
    :success="success"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    :items="forms"
    :loading="loading"
    :disabled="disabled"
    :clearable="clearable"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
  >
    <template #append-outer v-if="hasAppendOuterSlot">
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import sortBy from "lodash/sortBy";
import { getFormsByName } from "@/api/forms.service";

export default {
  props: {
    label: String,
    success: Boolean,
    errorMessages: Array,
    disabled: Boolean,
    hideDetails: Boolean,
    clearable: Boolean,
    value: [String, Object],
    currentFormId: [Number, String]
  },
  data() {
    let forms = [];
    let form = null;
    if (this.value && Object.keys(this.value).length && this.value.id) {
      forms = [this.value];
      form = this.value;
    }
    return {
      formSearchText: "",
      formTimer: null,
      loading: false,
      forms,
      form
    };
  },
  computed: {
    hasAppendOuterSlot() {
      return Boolean(this.$slots["append-outer"]);
    }
  },
  watch: {
    value(value) {
      if (value === this.form || value?.id === this.form?.id) return;
      if (!value || !Object.keys(value).length) {
        this.form = null;
        this.forms = [];
        return;
      }
      this.form = value;
      this.forms = [value];
    },
    form(value) {
      this.$emit("input", { id: value?.id, name: value?.name });
    },
    formSearchText(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }

      if (this.forms.some(val => val?.name === value)) {
        return;
      }

      if (this.formTimer) {
        clearTimeout(this.formTimer);
      }

      this.formTimer = setTimeout(() => {
        this.loading = true;
        getFormsByName(value)
          .then(response => {
            this.forms = sortBy(response.data.forms, "name").filter(
              val => +val.id !== +this.currentFormId
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  }
};
</script>
