export default function ActiveSaveFactory(
  config = {
    saving: false,
    saved: false,
    error: false,
    errorMessage: "",
    timer: null,
    promise: null,
    group: "",
    mutationTimer: null
  }
) {
  return {
    saveQueue: [],
    saving: config.saving,
    saved: config.saved,
    error: config.error,
    errorMessage: config.errorMessage,
    timer: config.timer,
    promise: config.promise,
    needsUpdate: false,
    group: config.group,
    mutationTimer: config.mutationTimer
  };
}
