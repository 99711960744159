<template>
  <v-row dense>
    <v-col cols="12">
      <v-text-field
        data-lpignore="true"
        v-model="compBuilder.name"
        label="Name"
        outlined
        dense
        :success="nameValidation.success"
        :error-messages="nameValidation.errorMessages"
        @input="updateName"
      >
        <template #append-outer>
          <active-save-indicator
            class="mx-1"
            :controller="savingBuffer['name']"
          />
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <div class="checkbox-width">
        <v-checkbox
          v-model="compBuilder.street"
          class="mt-0"
          dense
          :success="compBuilder.street"
          @change="updateStreet"
        >
          <template #label>
            Street
            <active-save-indicator
              class="mx-1"
              :controller="savingBuffer['street']"
            />
          </template>
        </v-checkbox>
      </div>
    </v-col>
    <v-col cols="12" md="6">
      <div class="checkbox-width">
        <v-checkbox
          v-model="compBuilder.noPay"
          class="mt-0"
          dense
          :success="compBuilder.noPay"
          @change="updateNoPay"
        >
          <template #label>
            No Pay
            <active-save-indicator
              class="mx-1"
              :controller="savingBuffer['noPay']"
            />
          </template>
        </v-checkbox>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import CompBuilderFactory from "@/factories/CompBuilderFactory";
import { updateCompBuilder } from "@/api/comp-builders.service";

export default {
  mixins: [ActiveSaveMixin],
  components: {
    ActiveSaveIndicator
  },
  props: {
    value: Object
  },
  data() {
    const savingBuffer = this.generateSavingBuffer({
      items: ["name", "street", "noPay"]
    });

    const compBuilder = new CompBuilderFactory(this.value);

    return {
      savingBuffer,
      compBuilder,
      editing: Boolean(compBuilder.id)
    };
  },
  watch: {
    compBuilder: {
      deep: true,
      handler(value) {
        this.$emit("input", value);
      }
    }
  },
  computed: {
    nameValidation() {
      const success = !this.$v.compBuilder.name.$invalid;
      const errorMessages = [];
      if (!this.$v.compBuilder.name.$dirty) return { success, errorMessages };
      if (!this.$v.compBuilder.name.required) errorMessages.push("Required");
      return { success, errorMessages };
    }
  },
  validations: {
    compBuilder: {
      name: { required: Boolean }
    }
  },
  methods: {
    valid() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    updateName() {
      if (!this.editing) return;

      const func = () =>
        updateCompBuilder(this.compBuilder.id, {
          comp_builder: this.compBuilder.getAttributeRequestValue("name")
        });
      this.genericUpdate(func, "name");
    },
    updateStreet() {
      if (!this.editing) return;
      const func = () =>
        updateCompBuilder(this.compBuilder.id, {
          comp_builder: this.compBuilder.getAttributeRequestValue("street")
        });
      this.genericUpdate(func, "street");
    },
    updateNoPay() {
      if (!this.editing) return;
      const func = () =>
        updateCompBuilder(this.compBuilder.id, {
          comp_builder: this.compBuilder.getAttributeRequestValue("noPay")
        });
      this.genericUpdate(func, "noPay");
    }
  }
};
</script>
