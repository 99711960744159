<template>
  <!-- desktop view -->
  <v-navigation-drawer
    v-if="$vuetify.breakpoint.lgAndUp"
    key="lg-nav"
    :app="!fullscreen"
    v-show="!fullscreen"
    color="sidebar"
    class="d-print-none"
    :mini-variant="isMini"
    :expand-on-hover="isMini"
  >
    <v-col align="center">
      <app-logo />
    </v-col>
    <sidebar-items />
  </v-navigation-drawer>
  <!-- mobile view -->
  <v-navigation-drawer
    v-else
    key="mobile-nav"
    v-model="showDrawer"
    temporary
    app
    class="d-print-none"
    :right="$vuetify.breakpoint.smAndDown"
  >
    <user-menu sidebar />
    <sidebar-items bottom-margin />
  </v-navigation-drawer>
</template>

<script>
import AppLogo from "@/components/AppLogo.vue";

import SidebarItems from "@/components/SidebarItems.vue";
import UserMenu from "@/components/UserMenu.vue";
import { mapState } from "pinia";
import { useInstanceStore } from "@/stores/instance";
export default {
  name: "SitewideSidebar",
  components: {
    UserMenu,
    SidebarItems,
    AppLogo
  },
  props: {
    value: Boolean
  },
  data() {
    return {
      showDrawer: this.value
    };
  },
  watch: {
    value(value) {
      this.showDrawer = value;
    },
    menu() {
      this.$emit("input", this.showDrawer);
    }
  },
  computed: {
    ...mapState(useInstanceStore, ["fullscreen"]),
    isMini() {
      return this.$route.name === "FormMappingEditor";
    }
  }
};
</script>
