export default function Role(model = {}) {
  return {
    role: model?.role || "",
    relationship: model?.relationship || "",
    beneficiaryQualifier: model?.beneficiaryQualifier || "",
    beneficiaryAmount: model?.beneficiaryAmount || "",
    underwritingRationale: model?.underwritingRationale || "",
    ratingApproved: model?.ratingApproved || "",
    ratingApplied: model?.ratingApplied || "",
    id: model?.id || null,
    caseId: model?.caseId || null,
    occupationClass: model?.occupationClass || null,
    setFromRequest(model = {}) {
      this.role = model?.role;
      this.relationship = model?.relationship;
      this.beneficiaryQualifier = model?.beneficiary_qualifier;
      this.beneficiaryAmount = +model?.beneficiary_amount;
      this.underwritingRationale = model?.underwriting_rationale;
      this.ratingApproved = model?.approval_rating;
      this.ratingApplied = model?.rating_applied;
      this.id = model?.id;
      this.occupationClass = model?.occupation_class;
    },

    fromTable(model = {}) {
      this.role = model?.role;
      this.relationship = model?.relationship;
      this.beneficiaryQualifier = model?.beneficiaryQualifier;
      this.beneficiaryAmount = model?.beneficiary_amount;
      this.underwritingRationale = model?.underwritingRationale;
      this.ratingApproved = model?.ratingApproved;
      this.ratingApplied = model?.ratingApplied;
      this.id = model?.id;
      this.occupationClass = model?.occupationClass;
    },

    generateAttributeMap() {
      return {
        ratingApplied: { rating_applied: this.ratingApplied },
        ratingApproved: { approval_rating: this.ratingApproved },
        underwritingRationale: {
          underwriting_rationale: this.underwritingRationale
        },
        relationship: { relationship: this.relationship },
        occupationClass: { occupation_class: this.occupationClass }
      };
    },

    getAttributeRequestValue(attribute) {
      const attributeMap = this.generateAttributeMap();
      return attributeMap[attribute];
    },

    toCreateRequest(caseId = null, includeQualifierData = false) {
      const body = {
        case_id: caseId || this.caseId,
        contract_party_id: this.party?.value,
        role: this.role,
        relationship: this.relationship
      };

      if (!includeQualifierData) return body;
      body.beneficiary_qualifier = this.beneficiaryQualifier;
      body.beneficiary_amount = +this.beneficiaryAmount;

      return body;
    }
  };
}

export const ENTITY_RELATIONSHIPS = ["Creditor", "Employer", "Trust", "Other"];

export const INDIVIDUAL_RELATIONSHIPS = [
  "Brother",
  "Business Partner",
  "Brother-in-law",
  "Daughter",
  "Domestic Partner",
  "Ex-Spouse",
  "Father",
  "Father-in-law",
  "Fiance",
  "Friend",
  "Granddaughter",
  "Grandfather",
  "Grandmother",
  "Grandson",
  "Husband",
  "Mother",
  "Mother-in-law",
  "Other",
  "Relative",
  "Sister",
  "Sister-in-law",
  "Son",
  "Spouse",
  "Stepfather",
  "Stepmother",
  "Wife"
];

export const ABSOLUTE_ASSIGNEE = "Absolute Assignee";
export const ANNUITANT = "Annuitant";
export const COLLATERAL_ASSIGNEE = "Collateral Assignee";
export const CONTINGENT_ANNUITANT_INSURED = "Contingent Annuitant/Insured";
export const CONTINGENT_BENEFICIARY = "Contingent Beneficiary";
export const CONTINGENT_OWNER = "Contingent Owner";
export const CUSTODIAN_FOR_UTMA = "Custodian for UTMA";
export const INSURED = "Insured";
export const JOINT_ANNUITANT = "Joint Annuitant";
export const JOINT_INSURED = "Joint Insured";
export const JOINT_OWNER = "Joint Owner";
export const OWNER = "Owner";
export const PAYOR = "Payor";
export const PRIMARY_BENEFICIARY = "Primary Beneficiary";
export const TRUSTEE = "Trustee";

export const MUTUALLY_EXCLUSIVE_ROLES = {
  [CONTINGENT_BENEFICIARY]: [PRIMARY_BENEFICIARY, INSURED, JOINT_INSURED],
  [PRIMARY_BENEFICIARY]: [CONTINGENT_BENEFICIARY, INSURED, JOINT_INSURED],
  [JOINT_INSURED]: [INSURED, CONTINGENT_BENEFICIARY, PRIMARY_BENEFICIARY],
  [INSURED]: [JOINT_INSURED, CONTINGENT_BENEFICIARY, PRIMARY_BENEFICIARY],
  [ANNUITANT]: [JOINT_ANNUITANT, CONTINGENT_BENEFICIARY, PRIMARY_BENEFICIARY],
  [OWNER]: [JOINT_OWNER],
  [JOINT_OWNER]: [OWNER]
};

export const ROLES_BY_LINE = {
  annuity: [
    ANNUITANT,
    OWNER,
    PRIMARY_BENEFICIARY,
    CONTINGENT_BENEFICIARY,
    JOINT_ANNUITANT,
    JOINT_OWNER,
    ABSOLUTE_ASSIGNEE,
    COLLATERAL_ASSIGNEE,
    CONTINGENT_ANNUITANT_INSURED,
    CONTINGENT_OWNER,
    CUSTODIAN_FOR_UTMA,
    PAYOR,
    TRUSTEE
  ],
  disability: [
    INSURED,
    OWNER,
    PRIMARY_BENEFICIARY,
    CONTINGENT_BENEFICIARY,
    PAYOR
  ],
  life: [
    INSURED,
    OWNER,
    PRIMARY_BENEFICIARY,
    CONTINGENT_BENEFICIARY,
    JOINT_INSURED,
    JOINT_OWNER,
    ABSOLUTE_ASSIGNEE,
    COLLATERAL_ASSIGNEE,
    CONTINGENT_OWNER,
    CUSTODIAN_FOR_UTMA,
    PAYOR,
    TRUSTEE
  ],
  linked_benefit: [
    INSURED,
    OWNER,
    PRIMARY_BENEFICIARY,
    CONTINGENT_BENEFICIARY,
    JOINT_INSURED,
    JOINT_OWNER,
    ABSOLUTE_ASSIGNEE,
    COLLATERAL_ASSIGNEE,
    CONTINGENT_OWNER,
    CUSTODIAN_FOR_UTMA,
    PAYOR,
    TRUSTEE
  ],
  ltc: [
    INSURED,
    OWNER,
    PRIMARY_BENEFICIARY,
    CONTINGENT_BENEFICIARY,
    JOINT_INSURED,
    PAYOR
  ],
  variable_life: [
    INSURED,
    OWNER,
    PRIMARY_BENEFICIARY,
    CONTINGENT_BENEFICIARY,
    ABSOLUTE_ASSIGNEE,
    COLLATERAL_ASSIGNEE,
    CONTINGENT_OWNER,
    CUSTODIAN_FOR_UTMA,
    JOINT_INSURED,
    PAYOR,
    JOINT_OWNER,
    TRUSTEE
  ],
  informal: [INSURED, JOINT_INSURED]
};
