<template>
  <v-card>
    <v-card-title class="pa-3 justify-space-between">
      <div>View {{ activeTabText }}</div>
      <div v-if="simulated">
        <v-btn
          @click="resetCommissions"
          class="text-none mr-1"
          color="error"
          outlined
        >
          <v-icon> $mdi-rotate-left </v-icon> Reset Commissions
        </v-btn>
        <v-btn @click="addCommission" class="text-none" color="accent" outlined>
          <v-icon> $mdi-plus </v-icon> Add Commission
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="activeTab">
        <v-tab class="text-none">Transactions </v-tab>
        <v-tab class="text-none"> Parties </v-tab>
        <v-tab
          class="text-none"
          :class="{ 'error--text': commissionsHasErrors }"
        >
          <v-icon v-if="commissionsHasErrors" color="error" class="mr-1">
            $mdi-information
          </v-icon>
          Simulated Commissions
        </v-tab>
        <v-tab class="text-none"> Simulated Payments </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab" touchless>
        <v-tab-item>
          <v-card-subtitle v-if="loading && !loaded">
            Loading Transaction Data
            <v-progress-circular class="ml-2" indeterminate />
          </v-card-subtitle>
          <v-card-subtitle v-else-if="!loaded">
            Add a Policy Number and Commission Type to see Transaction Data
          </v-card-subtitle>
          <transactions-table
            v-else
            :is-dialog="isDialog"
            :loading="loading"
            v-on="$listeners"
            :transactions="transactions"
          />
        </v-tab-item>
        <v-tab-item>
          <v-card-subtitle v-if="loading && !loaded">
            Loading Party Data
            <v-progress-circular class="ml-2" indeterminate />
          </v-card-subtitle>
          <v-card-subtitle v-else-if="!loaded">
            Add a Policy Number and Commission Type to see Party Data
          </v-card-subtitle>
          <parties-table v-else :parties="parties" :loading="loading" />
        </v-tab-item>
        <v-tab-item>
          <v-card-subtitle v-if="simulating && !simulated">
            Simulating Commission Data
            <v-progress-circular class="ml-2" indeterminate />
          </v-card-subtitle>
          <v-card-subtitle v-else-if="!simulated">
            Add a Policy Number, Commission Type, Statement, and Premium to see
            Payment Data
          </v-card-subtitle>
          <commissions-table
            v-else
            v-on="$listeners"
            :loading="simulating"
            :commissions="commissions"
            :policy="policy"
          />
        </v-tab-item>
        <v-tab-item>
          <v-card-subtitle v-if="simulating && !simulated">
            Simulating Payment Data
            <v-progress-circular class="ml-2" indeterminate />
          </v-card-subtitle>
          <v-card-subtitle v-else-if="!simulated">
            Add a Policy Number, Commission Type, Statement, and Premium to see
            Payment Data
          </v-card-subtitle>
          <transaction-payments-table
            v-else
            class="mt-2"
            v-on="$listeners"
            :loading="simulating"
            :payments="payments"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import TransactionsTable from "@/components/commissions/TransactionsTable.vue";
import CommissionsTable from "@/components/commissions/CommissionsTable.vue";
import TransactionPaymentsTable from "@/components/commissions/TransactionPaymentsTable.vue";
import PartiesTable from "@/components/commissions/PartiesTable.vue";
import { createCommission } from "@/api/commissions.service";
import { mapActions } from "pinia";
import { useDialogStore } from "@/stores/dialog";
export default {
  components: {
    TransactionsTable,
    CommissionsTable,
    TransactionPaymentsTable,
    PartiesTable
  },
  props: {
    isDialog: Boolean,
    transactions: Array,
    commissions: Array,
    payments: Array,
    parties: Array,
    policy: Object,
    loading: Boolean,
    loaded: Boolean,
    simulating: Boolean,
    simulated: Boolean
  },
  data: () => ({ activeTab: 0 }),
  computed: {
    commissionsHasErrors() {
      return this.commissions.some(v => Boolean(v.errors));
    },
    activeTabText() {
      return [
        "Transactions",
        "Parties",
        "Simulated Commissions",
        "Simulated Payments"
      ][this.activeTab];
    }
  },
  methods: {
    ...mapActions(useDialogStore, ["showDialog"]),
    resetCommissions() {
      this.$emit("reset");
    },
    addCommission() {
      this.showDialog({
        component: "CommissionDialog",
        policy: this.policy,
        func: item =>
          createCommission({ commission: item }).then(() => {
            this.$emit("fetch");
          })
      });
    }
  }
};
</script>
