import { CitizenshipProfile } from "@/factories/ProductFactory";
import { getHttpClient } from "@/http-client";

const baseUrl = "api/boss/citizenship_profiles";
export async function getAllCitizenshipProfiles() {
  const { data } = await getHttpClient().get(`${baseUrl}/all`);

  const citizenshipProfiles = [];
  data.citizenship_profiles.forEach(rawProfile => {
    const profile = new CitizenshipProfile();
    profile.setFromRequest(rawProfile);
    citizenshipProfiles.push(profile);
  });

  return citizenshipProfiles;
}
