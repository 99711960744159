<template>
  <v-data-table :items="items" :headers="headers">
    <template #top>
      <v-btn @click="add" class="text-none ma-3" color="accent">
        Add Product Citizenship Profile
      </v-btn>
    </template>
    <template #[`item.actions`]="{ item }">
      <v-btn
        icon
        color="error"
        @click="remove(item)"
        :loading="item.additional.tableHelpers.deleting"
      >
        <v-icon>$mdi-delete</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";
import TableHeader from "@/classes/data-table/TableHeader";
import Table from "@/classes/data-table/Table";

import { removeProductCitizenshipProfile } from "@/api/product-citizenship-profile.service";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  mixins: [GenericTableMixin],
  props: {
    product: Object
  },
  data() {
    const headers = [
      new TableHeader({
        text: "Name",
        value: "name",
        map: "citizenshipProfile.name"
      }),
      new TableHeader({ text: "Actions", value: "actions", map: "actions" })
    ];
    const table = new Table({
      rawItems: this.product.productCitizenshipProfiles,
      headers
    });
    return {
      table
    };
  },
  computed: {
    headers() {
      return this.table.headers;
    },
    items() {
      return this.tableMap(this.table.rawItems, this.table.headers);
    }
  },

  methods: {
    ...mapActions(useSnackbarStore, ["showSuccessSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    async remove(item) {
      this.showDialog({
        component: "DeleteDialog",
        title: "Remove Product Citizenship Profile",
        subtitle: "This cannot be undone",
        func: async () => {
          await removeProductCitizenshipProfile(item.additional.id);
          const index = this.table.rawItems.findIndex(
            val => val.id === item.additional.id
          );
          this.table.rawItems.splice(index, 1);

          this.showSuccessSnackbar({
            message: "Product Citizenship has been deleted",
            timeout: 6000
          });
        }
      });
    },
    add() {
      this.showDialog({
        component: "AddProductCitizenshipProfileDialog",
        citizenshipProfiles: this.citizenshipProfileOptions,
        product: this.product
      }).then(result => {
        if (!result.productCitizenshipProfile) {
          return;
        }
        this.table.rawItems.push(result.productCitizenshipProfile);
        this.showSuccessSnackbar({
          message: "Temporary Citizenship has been added",
          timeout: 6000
        });
      });
    }
  }
};
</script>
