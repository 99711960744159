import { getHttpClient } from "@/http-client";

const baseUrl = "api/boss/product_smoker_charts";
export const addProductSmokerChart = params => {
  return getHttpClient().post(baseUrl, params);
};

export const removeProductSmokerChart = id => {
  return getHttpClient().delete(`${baseUrl}/${id}`);
};
