<template>
  <span> {{ formatValue(Number(animatedValue)) }}</span>
</template>
<script>
import anime from "animejs";
export default {
  props: {
    value: {
      type: [Number, String],
      default: "0",
      required: true
    },
    formatValue: {
      type: Function,
      default: value => value
    },
    easing: {
      type: String,
      default: "linear"
    },
    duration: {
      type: Number,
      default: 1000
    },
    delay: {
      type: Number,
      default: 0
    },
    round: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      animatedValue: 0
    };
  },
  watch: {
    value(value) {
      this.animateValue(value);
    }
  },
  mounted() {
    this.animateValue(this.value);
  },
  methods: {
    animateValue(value) {
      const { begin, easing, duration, complete, update, run, delay, round } =
        this;
      anime({
        targets: this,
        animatedValue: value,
        duration,
        easing,
        update,
        begin,
        complete,
        run,
        delay,
        round
      });
    }
  }
};
</script>
