var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":"","color":_vm.isDialog ? null : 'transparent'}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{staticClass:"pa-3",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('transaction-create-form',{ref:"create-form",attrs:{"fetching-policy":_vm.fetchingPolicy,"statement":_vm.transaction.statement,"policy":_vm.transaction.policy,"commission-type":_vm.transaction.commissionType,"premium":_vm.transaction.premium,"override":_vm.transaction.override,"memo":_vm.transaction.memo,"preserve-field-values":_vm.preserveFieldValues,"loading":_vm.loading,"editing":_vm.editing,"error-message":_vm.errorMessage,"show-back-nine-writing-agent-hint":_vm.showBackNineWritingAgentHint},on:{"update:statement":function($event){return _vm.$set(_vm.transaction, "statement", $event)},"update:policy":function($event){return _vm.$set(_vm.transaction, "policy", $event)},"update:commissionType":function($event){return _vm.$set(_vm.transaction, "commissionType", $event)},"update:commission-type":function($event){return _vm.$set(_vm.transaction, "commissionType", $event)},"update:premium":function($event){return _vm.$set(_vm.transaction, "premium", $event)},"update:override":function($event){return _vm.$set(_vm.transaction, "override", $event)},"update:memo":function($event){return _vm.$set(_vm.transaction, "memo", $event)},"save":_vm.createTransaction}}),_c('transaction-helper-table',{staticClass:"mt-2",attrs:{"is-dialog":_vm.isDialog,"loading":_vm.loadingCaseTransactions,"loaded":_vm.loadedCaseTransactions,"simulating":_vm.simulatingTransaction,"simulated":_vm.simulatedTransaction,"transactions":_vm.transactions,"commissions":_vm.commissions,"parties":_vm.parties,"policy":_vm.policy,"payments":_vm.payments},on:{"reset":_vm.resetCommissions,"fetch":_vm.refresh}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[(
            _vm.policy &&
            _vm.policy.id &&
            _vm.statement &&
            _vm.statement.id &&
            _vm.transaction.commissionType
          )?_c('transaction-case-data',{key:`${_vm.policy.id}-${_vm.statement.id}`,attrs:{"loading":_vm.simulatingTransaction,"final-override":_vm.finalOverride,"backnine-forecast":_vm.backnineForecast,"total-forecast":_vm.totalForecast,"case-id":_vm.policy.id,"policy-number":_vm.policy.policy_number,"commission-type":_vm.transaction.commissionType,"statement":_vm.statement},on:{"refresh":_vm.refresh}}):_vm._e()],1)],1)],1),(_vm.isDialog)?_c('v-card-actions',{staticClass:"px-3 pt-0"},[_c('v-btn',{staticClass:"text-none",attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Close ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }