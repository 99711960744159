<template>
  <v-card flat tile>
    <v-card-title> Employment History </v-card-title>
    <v-card-subtitle>
      Starting with your current employer, please enter your employment history
      for the last 5 years.
    </v-card-subtitle>
    <v-card-text>
      <v-row dense>
        <v-col
          cols="12"
          v-for="occupationId in agent.occupationOrder"
          :key="occupationId"
        >
          <employment-history-record
            :data-testid="`employment-history-${occupationId}`"
            :occupation="agent.occupations[occupationId]"
            :readonly="props.readonly"
            @edit="editOccupation(occupationId)"
            @delete="destroyOccupation(occupationId)"
          />
        </v-col>
        <v-col cols="12">
          <v-alert
            v-if="agent.missingFiveYearsOfEmployment"
            type="warning"
            class="my-2"
            prominent
            dense
            data-testid="missing-employment-alert"
          >
            {{ missingText }}
          </v-alert>
        </v-col>
        <v-col v-if="!props.readonly" cols="12">
          <v-btn
            class="text-none"
            color="accent"
            data-testid="add-employment-history"
            @click="addAdditionalOccupation"
          >
            <v-icon class="mr-1"> $mdi-plus </v-icon>
            Add Employment Record
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script setup>
import EmploymentHistoryRecord from "@/components/settings/employment/EmploymentHistoryRecord.vue";

import { Occupation } from "@/factories/AgentSettingsFactory";
import { useDialogStore } from "@/stores/dialog";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { watch, defineProps, computed, onMounted, defineEmits } from "vue";
const props = defineProps({ readonly: Boolean });

const agent = useAgentSettingsStore();

watch(
  () => agent.missingFiveYearsOfEmployment,
  () => validate()
);

const missingText = computed(() => {
  let text = "We're missing some employment history for the last five years.";
  if (!props.readonly)
    text += ` Please provide where ${agent.name} has worked for the last five years.`;
  return text;
});

const dialog = useDialogStore();
async function addAdditionalOccupation() {
  const res = await dialog.showDialog({
    component: "EmploymentHistoryDialog",
    scrollable: true,
    persistent: true,
    currentOccupation: new Occupation({
      employeeId: agent.id,
      employeeType: "Agent"
    })
  });
  if (!res?.occupation) return;
  agent.addOccupation(res.occupation);
  validate();
}
async function editOccupation(occupationId) {
  const res = await dialog.showDialog({
    component: "EmploymentHistoryDialog",
    scrollable: true,
    currentOccupation: new Occupation({
      ...agent.occupations[occupationId]
    })
  });
  if (!res?.occupation) return;
  agent.addOccupation(res.occupation);
  validate();
}
function destroyOccupation(occupationId) {
  dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Delete Occupation History Record",
    subtitle: "Are you sure that you want to delete this occupation record?",
    func: async () => {
      await agent.removeOccupation(occupationId);
      validate();
    }
  });
}

function validate() {
  const valid = !agent.missingFiveYearsOfEmployment;
  agent.setValidPage({ valid, page: "employment" });
}

validate();

const emit = defineEmits(["mounted"]);
onMounted(() => emit("mounted"));
</script>
