<template>
  <v-card>
    <v-card-text>
      <v-data-table
        must-sort
        data-testid="tasks-table"
        item-key="additional.itemKey"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :server-items-length="table.meta.value.total"
        :loading="table.loading.value"
        :options="table.options.value"
        :footer-props="table.footerProps.value"
        @update:options="updateOptions"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5" style="width: 130px">{{ viewText }}</h1>
            <v-spacer />
            <generic-table-stats v-if="user.isGroupTwoPlus" :stats="stats" end>
              <past-due-tasks-indicator
                v-for="type in TASK_TYPES"
                :key="type.value"
                :singular="type.text"
                :plural="type.plural"
                :type="type.value"
                :global="globalSearch"
                :refreshed-at="refreshedAt"
                :data-testid="`tasks-table-${type.value}-indicator`"
                class="ma-1"
                @click="changeViewTo(type.value)"
              />
            </generic-table-stats>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  data-testid="tasks-table-refresh"
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="refresh"
                >
                  <v-icon>$mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
          <generic-table-filter-header
            :show-view-filter="user.isGroupTwoPlus"
            :value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>
        <template #[`item.insured`]="{ item }">
          <router-link :to="caseLink(item.additional)" class="truncate">
            {{ item.insured }}
          </router-link>
        </template>
        <template #[`item.description`]="{ item }">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                class="truncate text-decoration-underline cursor-help"
              >
                {{ item.description }}
              </div>
            </template>
            <span>{{ item.description }}</span>
          </v-tooltip>
        </template>
        <template #[`item.last`]="{ item }">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                class="truncate text-decoration-underline cursor-help"
              >
                {{ item.last }}
              </div>
            </template>
            <span>{{ item.last }}</span>
          </v-tooltip>
        </template>
        <template #[`item.doctor`]="{ item }">
          <div class="truncate">{{ item.doctor }}</div>
        </template>
        <template #[`item.requirements`]="{ item }">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs" class="truncate">
                {{ item.requirements }}
              </div>
            </template>
            <span>{{ item.requirements }}</span>
          </v-tooltip>
        </template>
        <template #[`item.carrier`]="{ item }">
          <div class="truncate">{{ item.carrier }}</div>
        </template>
        <template #[`item.case`]="{ item }">
          <div class="truncate">{{ item.case }}</div>
        </template>
        <template #[`item.follow`]="{ item }">
          <div>
            <until-time-formatter :value="item.follow" format="hours" />
          </div>
        </template>
        <template #[`item.next`]="{ item }">
          <div><until-time-formatter :value="item.next" format="hours" /></div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import PastDueTasksIndicator from "@/components/tasks/PastDueTasksIndicator.vue";
import UntilTimeFormatter from "@/components/shared/formatters/UntilTimeFormatter.vue";
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";
import GenericTableStats from "@/components/shared/data-table/GenericTableStats.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import { parseErrorMessage } from "@/util/helpers";
import { getRequirements } from "@/api/requirements.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { computed, ref } from "vue";

useHead({ title: "Tasks" });
const user = useUserStore();
const snackbar = useSnackbarStore();

const TASK_TYPES = [
  { text: "Task", value: "task", plural: "Tasks" },
  { text: "Exam", value: "exam", plural: "Exams" },
  { text: "APS", value: "aps", plural: "APS" },
  user.isGroupTwoPlus
    ? { text: "Phone Call", value: "phone_call", plural: "Phone Calls" }
    : null
].filter(Boolean);

const table = getTable();

const refreshedAt = ref(null);

const stats = computed(() => {
  const stats = [];
  const score = { text: "Score", icon: "$mdi-pound" };
  const statsObj = table.stats.value;
  if (!statsObj) {
    return [{ ...score, type: "loading" }];
  }
  Object.keys(statsObj).forEach(stat => {
    if (stat.indexOf("score") > -1) {
      stats.push({ ...score, value: statsObj[stat], type: "percent" });
      return;
    }
  });
  return stats;
});

const viewText = computed(
  () =>
    TASK_TYPES.find(({ value }) => value === table.filter.value.type)?.plural
);

const globalSearch = computed(() => {
  if (!user.isGroupTwoPlus) return false;
  return table.filter.value.view === "all";
});

function updateOptions(options) {
  if (table.optionsEquivalence(options)) return;
  table.handleCustomSorts(options);
  getData();
}

function updateFilter(filter) {
  if (filter.type === "phone_call") {
    table.options.value.sortBy = ["next"];
    table.options.value.sortDesc = [false];
  } else {
    table.options.value.sortBy = ["follow"];
    table.options.value.sortDesc = [false];
  }
  table.resetPage();
  table.filter.value = filter;
  getData();
}

function changeViewTo(type) {
  updateFilter({ ...table.filter.value, type });
}

async function getData() {
  try {
    const additionalFilter = {};
    if (!user.isGroupTwoPlus) additionalFilter.view = "my";
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      color: "error"
    });
  }
}

function getTable() {
  const headers = [
    new TableHeader({
      text: "Type",
      value: "type",
      map: "type",
      order: 2,
      sortFilterMap: "type",
      selectableOptions: TASK_TYPES,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_MANDATORY,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Case Manager",
      value: "case",
      order: 3,
      map: "case_manager_name",
      sortFilterMap: [{ key: "case_manager_id", value: "id" }],
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_CASE_MANAGER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Policy #",
      value: "policy",
      map: "policy_number",
      sortFilterMap: "policy_number",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Insured",
      value: "insured",
      map: "insured_name",
      sortFilterMap: "contract_party_name",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "carrier_name",
      sortKey: "carrier",
      sortFilterMap: [{ key: "carrier_id", value: "id" }],
      ...TableHeader.IS_CARRIER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Description",
      value: "description",
      map: "description",
      sortFilterMap: "description",
      showIf: ({ type }) => ["task"].includes(type),
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Last Note",
      value: "last",
      map: "last_note",
      sortFilterMap: "note",
      showIf: ({ type }) => ["exam", "aps", "task"].includes(type)
    }),
    new TableHeader({
      text: "Follow Up",
      value: "follow",
      map: "follow_up_date",
      sortFilterMap: "follow_up",
      showIf: ({ type }) => ["task", "exam", "aps"].includes(type),
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Order #",
      value: "order",
      map: "order_number",
      sortFilterMap: "order_number",
      showIf: ({ type }) => ["exam"].includes(type),
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Requirements",
      value: "requirements",
      map: "requirements",
      sortFilterMap: "requirements",
      showIf: ({ type }) => ["exam"].includes(type),
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Assignable",
      value: "assignable",
      map: "",
      sortFilterMap: [
        { value: "id", key: "assignable_id" },
        { value: "type", key: "assignable_type" }
      ],
      order: 4,
      showIf: ({ type }) => ["task", "exam", "aps"].includes(type),
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Doctor",
      value: "doctor",
      map: "doctor",
      sortFilterMap: "doctor",
      showIf: ({ type }) => ["aps"].includes(type)
    }),
    new TableHeader({
      text: "Next Call At",
      value: "next",
      map: "next_call_at",
      sortFilterMap: "next_call_at",
      showIf: ({ type }) => ["phone_call"].includes(type),
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Vendor",
      value: "vendor",
      map: "vendor",
      sortFilterMap: [{ value: "id", key: "vendor_id" }],
      showIf: ({ type }) => ["exam", "aps"].includes(type),
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_VENDOR_SEARCH_TYPE,
      ...TableHeader.IS_FILTERABLE
    })
  ];

  const filter = { type: "task" };
  if (!user.loginable.is_case_manager) {
    filter.assignable = {
      id: user.loginable.id,
      type: user.loginable.type,
      name: user.loginable.name
    };
  }

  if (user.isGroupTwoPlus) {
    headers.unshift(
      new TableHeader({
        text: "View",
        value: "view",
        map: "view",
        selectableOptions: [
          { text: "All Tasks", value: "all" },
          { text: "My Tasks", value: "my" }
        ],
        sortFilterMap: "view",
        order: 1,
        ...TableHeader.IS_SELECT_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_ADDITIONAL,
        ...TableHeader.IS_QUERY_FILTER,
        ...TableHeader.IS_MANDATORY
      })
    );
    filter.view = "my";
  }

  return useTable({
    getData: getRequirements,
    headers,
    shouldIncludeCancelToken: true,
    filter,
    options: new TableOptions(["follow"], [false])
  });
}

function refresh() {
  refreshedAt.value = new Date().getTime();
  getData();
}

function caseLink(item) {
  let query = null;
  if (table.filter.value.type !== "phone_call") {
    let type = TASK_TYPES.find(
      ({ value }) => value === table.filter.value.type
    );
    let taskType = type.text;
    if (type.value === "aps") taskType = "PhysicianStatement";

    query = { task: `${taskType}-${item.id}` };
  }
  return {
    name: "CaseView",
    params: { id: item.case_id },
    query
  };
}

getData();
</script>
