<template>
  <v-row justify="center" class="ma-0">
    <v-col cols="12" class="article-container section">
      <div class="article" v-if="article.name">
        <h1>{{ article.name }}</h1>
        <v-row class="ma-0">
          <v-avatar> <v-img :src="article.avatar" /> </v-avatar>
          <v-col class="py-0">
            <h4>{{ article.author }}</h4>
            <p class="grey--text subtitle">
              {{ article.createdAt.toLocaleDateString() }} ·
              {{ article.timeToRead }} min read
            </p>
          </v-col>
          <v-col align="end" class="py-0">
            <v-btn
              icon
              :to="{
                name: 'HelpCenterArticleEditor',
                params: { id }
              }"
              color="success"
              v-if="isGroupTwoPlus"
            >
              <v-icon>$mdi-pencil</v-icon>
            </v-btn>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  :href="publicLink"
                  color="primary"
                  target="_blank"
                >
                  <v-icon>$mdi-link-variant</v-icon>
                </v-btn>
              </template>
              <span v-if="isGroupTwoPlus">Public Link</span>
              <span v-else>View Public Article</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <viewer :article="article" />
      </div>
      <v-skeleton-loader type="article" v-else />
    </v-col>
  </v-row>
</template>

<script>
import Viewer from "@/components/help-center/HelpCenterViewer.vue";
import { getArticleById } from "@/api/help-desk.service";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/user";
import { useInstanceStore } from "@/stores/instance";
import { useDialogStore } from "@/stores/dialog";
import { useHead } from "@unhead/vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  props: {
    id: [String, Number]
  },
  components: {
    Viewer
  },
  data() {
    return {
      article: {}
    };
  },
  created() {
    this.getArticle();
  },
  destroyed() {
    this.breadcrumb = "";
    this.closeDialog();
  },
  computed: {
    ...mapWritableState(useInstanceStore, ["breadcrumb"]),
    ...mapState(useUserStore, ["isGroupTwoPlus"]),
    publicLink() {
      return `${process.env.VUE_APP_HELP_CENTER}/a/${this.article.link}`;
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    getArticle() {
      getArticleById(this.id)
        .then(article => {
          this.article = article;
          this.breadcrumb = article.name;
          useHead({ title: article.name });
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
          this.$router.replace({ name: "Home" });
        });
    }
  }
};
</script>
