import VerboseCarrierFactory from "@/factories/VerboseCarrierFactory";
import { getHttpClient } from "@/http-client";
import { setCarrierTableItemFromRaw } from "@/factories/CarrierFactory";
const baseUrl = "api/boss/carriers";

export const getCarrierData = id => {
  return new Promise((resolve, reject) => {
    getHttpClient()
      .get(`${baseUrl}/${id}`)
      .then(res => {
        const verboseCarrier = new VerboseCarrierFactory();
        verboseCarrier.setFromRequest(res?.data?.carrier);
        resolve(verboseCarrier);
      })
      .catch(reject);
  });
};

export const uploadCarrierAvatar = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return getHttpClient().put(`${baseUrl}/${id}/avatar`, formData);
};

export async function uploadCarrierDocument({ id, file, category }) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("category", category);
  const res = await getHttpClient().post(
    `${baseUrl}/${id}/documents`,
    formData
  );
  return res.data;
}

export const updateCarrierAddress = (carrierId, address) => {
  return getHttpClient().put(`${baseUrl}/${carrierId}/address`, address);
};

export async function getCarriers(
  params = new URLSearchParams(),
  cancelToken = null
) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });
  if (!response?.data) return;

  const carriers = response.data.carriers.map(c =>
    setCarrierTableItemFromRaw(c)
  );

  carriers.sort((a, b) => a.name.localeCompare(b.name));

  return carriers;
}

export function updateCarrier(id, body) {
  return getHttpClient().put(`${baseUrl}/${id}`, body);
}

export function searchCarriers(search) {
  const params = new URLSearchParams();
  params.append("name", search);
  return getHttpClient().get(`${baseUrl}/search`, { params });
}
