function BacknineCaseCommission(model = {}) {
  return {
    carrierName: model?.carrierName || null,
    policyNumber: model?.policyNumber || null,
    income: model?.income || null,
    expenses: model?.expenses || null,
    profit: model?.profit || null,
    inforceDate: model?.inforceDate || null,
    marketer: model?.marketer || null
  };
}

export function setBacknineCaseCommissionFromRequest(raw = {}) {
  const item = BacknineCaseCommission();
  if (!raw) return item;

  item.carrierName = raw.carrier?.name;
  item.policyNumber = raw.policy_number;
  item.income = raw.income;
  item.expenses = raw.expenses;
  item.profit = raw.profit;
  item.inforceDate = raw.inforce_date;
  item.marketer = raw.marketer;

  return item;
}

function ProductCommision(model = {}) {
  return {
    carrierName: model?.carrierName || null,
    excess: model?.excess || null,
    firstYear: model?.firstYear || null,
    id: model?.id || null,
    name: model?.name || null,
    qaEnabled: model?.qaEnabled || null,
    renewal: model?.renewal || null
  };
}

export function setProductCommissionFromRequest(raw = {}) {
  const item = ProductCommision();
  if (!raw) return item;

  item.carrierName = raw.carrier?.name;
  item.excess = raw.excess;
  item.firstYear = raw.first_year;
  item.name = raw.name;
  item.id = raw.id;
  item.qaEnabled = raw.qa_enabled;
  item.renewal = raw.renewal;

  return item;
}
