import { listToSentence } from "@/util/helpers";

export const VENDOR_TYPES = [
  { text: "Physician Statement", value: "physician_statement" },
  { text: "Exam", value: "exam" }
];

export function Vendor(model = {}) {
  return {
    id: model?.id || null,
    email: model?.email || null,
    available: model?.available || false,
    default: model?.default || false,
    integration: model?.integration || false,
    name: model?.name || "",
    values: model?.values || "",
    testing: model?.testing || false,
    type: model?.type || "",
    formattedType: model?.formattedType || ""
  };
}

export function setVendorFromRequest(req = {}) {
  const vendor = Vendor();

  vendor.id = req?.id;
  vendor.email = req?.email;
  vendor.default = req?.default;
  vendor.available = req?.available;
  vendor.integration = req?.integration;
  vendor.name = req?.name;
  vendor.testing = req?.testing;
  vendor.type = req?.type;

  const values = [];
  if (vendor.available) values.push("Available");
  if (vendor.default) values.push("Default");
  if (vendor.integration) values.push("Integration");
  if (vendor.testing) values.push("Testing");
  vendor.values = listToSentence(values);

  if (vendor.type) {
    vendor.formattedType = VENDOR_TYPES.find(
      ({ value }) => value === vendor.type
    )?.text;
  }

  return vendor;
}

export function setRequestFromVendor(vendor = {}) {
  return {
    name: vendor.name,
    email: vendor.email,
    type: vendor.type,
    default: vendor.default,
    integration: vendor.integration,
    available: vendor.available,
    testing: vendor.testing
  };
}
