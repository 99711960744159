<template>
  <div>
    <high-dpi-canvas
      v-if="window.height"
      :id="`field-editor-canvas-${formId}`"
      :height="window.height"
      :width="window.width"
      :scale="scale"
      @mousedown="handleMouseDown"
      @mousemove="handleMouseMove"
      @mouseup="handleMouseUp"
      @mouseout="handleMouseOut"
      @redraw="drawFields"
      @ready="instantiate"
    />
    <form-mapping-form-aql-pdf-menu
      :can-create-rectangle="!rectangles.length"
      :pdf-type="activeField.pdfFieldType"
      :visible="rectangleIsVisible"
      :all-visible="allVisible"
      :rectangle.sync="rectangle"
      :all-radio-values-visible="showAllRadioValues"
      @toggle-visibility="toggleVisibility"
      @toggle-other-visibility="$emit('toggle-other-visibility')"
      @toggle-radio-value-visibility="toggleRadioValueVisibility"
    />
  </div>
</template>

<script>
import {
  CHECKBOX_OPTION_TYPE,
  CHECKBOX_RADII,
  CHECKBOX_SIDE_LENGTH,
  RADIO_OPTION_RADIUS
} from "@/factories/FormMappingFactory";
import {
  clickedWithinBoundingBox,
  drawCheckbox,
  drawCircle,
  drawRectangle,
  parseErrorMessage
} from "@/util/helpers";
const SOFT_OFFSET = 4;
import { ref } from "vue";
import { useFormMappingStore } from "@/stores/form-mapping";
import { mapActions, storeToRefs } from "pinia";

import FormMappingFormAqlPdfMenu from "@/components/form-mapping/FormMappingFormAqlPdfMenu.vue";
import HighDpiCanvas from "@/components/shared/HighDpiCanvas.vue";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  props: {
    readonly: Boolean,
    formId: [Number, String],
    allVisible: Boolean,
    currentPage: [Number, String]
  },
  components: {
    FormMappingFormAqlPdfMenu,
    HighDpiCanvas
  },
  setup({ formId }) {
    const formStore = useFormMappingStore(formId);

    const {
      status,
      currentCoordinateIndex,
      activeField,
      currentFieldHasChanges,
      currentFieldIsValid,
      viewport,
      window,
      scale
    } = storeToRefs(formStore);
    return {
      status,
      currentCoordinateIndex,
      activeField,
      currentFieldIsValid,
      currentFieldHasChanges,
      viewport,
      window,
      scale,
      updateApplicationQuestionLink: formStore.updateApplicationQuestionLink,
      showAllRadioValues: ref(true),
      isDragging: ref(false),
      isDown: ref(false),
      savingTimer: ref(false),
      startX: ref(null),
      startY: ref(null),
      moveOffsetX: ref(null),
      moveOffsetY: ref(null),
      rectangleIsVisible: ref(true),
      alignment: ref({
        n: false,
        s: false,
        e: false,
        w: false,
        center: false
      })
    };
  },
  mounted() {
    if (!this.rectangles.length) return;
    this.orderFieldOptions();
  },
  watch: {
    "activeField.comb"() {
      this.clearScreen();
      this.drawFields();
    },
    "activeField.obscureBackground"() {
      this.clearScreen();
      this.drawFields();
    },
    currentPage() {
      this.clearScreen();
      this.drawFields();
    },
    currentCoordinateIndex() {
      this.clearScreen();
      this.drawFields();
    },
    virtualRectangles() {
      this.orderFieldOptions();
      this.clearScreen();
      this.drawFields();
    },
    rectangleCount() {
      this.orderFieldOptions();
      this.clearScreen();
      this.drawFields();
    },
    cursor(v) {
      const canvas = this.getCurrentCanvas();
      canvas.style.cursor = v;
    }
  },
  computed: {
    currentFieldOnPage() {
      if (!this.rectangles.length) return;
      return this.activeField.coordinates.some(
        ({ page }) => page === this.currentPage
      );
    },
    virtualRectangles() {
      return this.rectangles.filter(({ virtual }) => virtual);
    },
    rectangleCount() {
      return this.rectangles.length;
    },
    rectangles() {
      if (!this.activeField?.coordinates) return [];
      return this.activeField.coordinates.map(
        ({ y, isFieldOption, height, ...optionValues }) => {
          let rectY = this.viewport.height - y;
          if (!isFieldOption) rectY -= height;
          return {
            isFieldOption,
            y: rectY,
            height,
            ...optionValues
          };
        }
      );
    },
    cursor() {
      let resize = "";
      if (this.alignment.n) resize += "n";
      if (this.alignment.s) resize += "s";
      if (this.alignment.e) resize += "e";
      if (this.alignment.w) resize += "w";
      if (resize) return `${resize}-resize`;
      else if (this.alignment.center && this.isDown) return "none";
      else if (this.alignment.center) return "grab";
      return "default";
    },
    hasNoActions() {
      return ["default", "crosshair"].includes(this.cursor);
    },
    isMoving() {
      return (
        !this.alignment.n &&
        !this.alignment.s &&
        !this.alignment.e &&
        !this.alignment.w &&
        this.isDown &&
        this.alignment.center
      );
    },
    rectangle: {
      get() {
        return this.rectangles[this.currentCoordinateIndex];
      },
      set({ x, y, height, width }) {
        if (this.currentCoordinateIndex === null) return;

        let rectX = x,
          rectY = y,
          rectWidth = width,
          rectHeight = height;
        if (width < 0) {
          rectWidth = Math.abs(width);
          rectX = Math.abs(width + x);
        }
        if (height < 0) {
          rectHeight = Math.abs(height);
          rectY = Math.abs(height + y);
        }

        this.activeField.coordinates[this.currentCoordinateIndex].x = rectX;
        rectY = this.viewport.height - rectY;
        if (!this.rectangle.isFieldOption) {
          rectY -= rectHeight;
          this.activeField.coordinates[this.currentCoordinateIndex].height =
            rectHeight;
          this.activeField.coordinates[this.currentCoordinateIndex].width =
            rectWidth;
        }
        this.activeField.coordinates[this.currentCoordinateIndex].y = rectY;

        this.currentFieldHasChanges = true;
      }
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, [
      "showSuccessSnackbar",
      "showErrorSnackbar"
    ]),
    instantiate() {
      this.drawFields();
      this.setAlignment();
    },
    debounceAndSave() {
      if (this.savingTimer) clearTimeout(this.savingTimer);
      this.savingTimer = setTimeout(this.saveField, 500);
    },
    orderFieldOptions() {
      const options = [...this.activeField.coordinates];
      options.sort((a, b) => {
        let similarY = Math.abs(a.y - b.y) < 10;
        if (similarY) return a.x - b.x > 0 ? 1 : -1;
        return a.y - b.y < 0 ? 1 : -1;
      });

      options.forEach((option, index) => {
        const cIndex = this.activeField.coordinates.findIndex(
          ({ uuid }) => option.uuid === uuid
        );
        this.activeField.coordinates[cIndex].order = index + 1;
      });
    },
    async saveField() {
      if (!this.currentFieldIsValid) return;
      if (!this.currentFieldHasChanges) return;
      try {
        this.status = "Complete";
        await this.updateApplicationQuestionLink(this.activeField);
        this.showSuccessSnackbar({
          message: "Saved field positions"
        });
        this.currentFieldHasChanges = false;
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.status = "Incomplete";
      }
    },
    toggleVisibility() {
      this.rectangleIsVisible = !this.rectangleIsVisible;

      if (this.rectangleIsVisible) this.drawFields();
      else this.clearScreen();
    },
    toggleRadioValueVisibility() {
      this.showAllRadioValues = !this.showAllRadioValues;
      this.clearScreen();
      this.drawFields();
    },
    getCurrentCanvas() {
      return document.getElementById(`field-editor-canvas-${this.formId}`);
    },
    drawFields() {
      if (!this.rectangles?.length) return;
      if (!this.currentFieldOnPage) return;
      const canvas = this.getCurrentCanvas();
      if (!canvas) return;
      const ctx = canvas.getContext("2d");

      this.rectangles.forEach(
        (
          { height, width, x, y, isFieldOption, virtual, text, value },
          index
        ) => {
          const isActive = index === this.currentCoordinateIndex;
          const strokeColor = isActive ? "#1487e2" : "#0a4e84";

          const textOrValue = text || value;

          if (this.activeField.pdfFieldType === CHECKBOX_OPTION_TYPE) {
            if (virtual) return;
            let checkboxText = null;
            if (this.showAllRadioValues || isActive) {
              checkboxText = textOrValue;
            }

            drawCheckbox(ctx, {
              x,
              y,
              strokeColor,
              sideLength: CHECKBOX_SIDE_LENGTH,
              isDashed: isActive,
              radii: CHECKBOX_RADII,
              text: checkboxText
            });
          } else if (isFieldOption) {
            if (virtual) return;

            let radioText = null;
            if (this.showAllRadioValues || isActive) {
              radioText = textOrValue;
            }

            drawCircle(ctx, {
              x,
              y,
              strokeColor,
              isDashed: isActive,
              radius: RADIO_OPTION_RADIUS,
              text: radioText
            });
          } else {
            let fillColor;
            if (this.activeField.obscureBackground) fillColor = "#ffffff";
            drawRectangle(ctx, {
              height,
              width,
              x,
              y,
              comb: this.activeField.comb,
              fillColor,
              strokeColor,
              isDashed: isActive
            });
          }
        }
      );
    },
    handleMouseUp(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDown = false;
      if (!this.isDragging) return;
      this.isDragging = false;
      if (!this.rectangle) return;
      this.orderFieldOptions();
      this.debounceAndSave();
    },
    handleMouseOut(e) {
      e.preventDefault();
      e.stopPropagation();

      // the drag is over, clear the dragging flag
      this.isDown = false;
      this.isDragging = false;
    },
    handleMouseDown(e) {
      e.preventDefault();
      e.stopPropagation();

      // const previousIndex = this.currentCoordinateIndex;
      const { x: currentX, y: currentY } = this.getRelativeMousePosition(e);
      const index = this.rectangles.findIndex(rect =>
        clickedWithinBoundingBox({
          mouseX: currentX,
          mouseY: currentY,
          rectX: rect.x,
          rectY: rect.y,
          rectWidth: Math.abs(rect.width),
          rectHeight: Math.abs(rect.height),
          isCircle: rect.isFieldOption,
          radius: RADIO_OPTION_RADIUS
        })
      );
      if (index > -1 && !this.readonly) {
        this.currentCoordinateIndex = index;
        this.startX = this.rectangle.x;
        this.startY = this.rectangle.y;
        this.isDown = true;
      } else {
        this.clearAlignment();
        if (this.allVisible) this.$emit("click", e);
        this.isDown = false;
      }

      this.clearScreen();
      this.drawFields();
      if (this.isMoving) {
        this.moveOffsetX = this.startX - currentX;
        this.moveOffsetY = this.startY - currentY;
      } else {
        if (this.alignment.n) this.setNAlignedStart();
        else if (this.alignment.s) this.setSAlignedStart();
        if (this.alignment.e) this.setEAlignedStart();
        else if (this.alignment.w) this.setWAlignedStart();
      }
    },
    setNAlignedStart() {
      this.startY = this.rectangle.y;
      if (this.rectangle.height > 0) this.startY += this.rectangle.height;
    },
    setSAlignedStart() {
      this.startY = this.rectangle.y;
      if (this.rectangle.height < 0) this.startY += this.rectangle.height;
    },
    setEAlignedStart() {
      this.startX = this.rectangle.x;
      if (this.rectangle.width < 0) this.startX += this.rectangle.width;
    },
    setWAlignedStart() {
      this.startX = this.rectangle.x;
      if (this.rectangle.width >= 0) this.startX += this.rectangle.width;
    },
    getRelativeMousePosition(e) {
      const yRatio = this.viewport.height / this.window.height;
      const xRatio = this.viewport.width / this.window.width;
      const y = yRatio * e.offsetY;
      const x = xRatio * e.offsetX;

      return { x, y };
    },
    handleMouseMove(e) {
      e.preventDefault();
      e.stopPropagation();
      if (this.readonly) return;
      const { x: startX, y: startY } = this.getRelativeMousePosition(e);
      if (!this.isDown) {
        if (this.rectangle) this.setAlignment(startX, startY);
        return;
      }
      this.isDragging = true;

      let width = startX - this.startX;
      let height = startY - this.startY;

      if (this.isMoving) {
        this.startX = startX + this.moveOffsetX;
        this.startY = startY + this.moveOffsetY;
        width = this.rectangle.width;
        height = this.rectangle.height;
      } else {
        const nsAligned = this.alignment.n || this.alignment.s;
        const ewAligned = this.alignment.e || this.alignment.w;
        if (nsAligned && !ewAligned) width = this.rectangle.width;
        if (ewAligned && !nsAligned) height = this.rectangle.height;
      }

      this.clearScreen();
      this.rectangle = {
        x: Math.trunc(this.startX),
        y: Math.trunc(this.startY),
        width: Math.trunc(width),
        height: Math.trunc(height)
      };
      this.drawFields();
    },
    setAlignment(x, y) {
      if (!this.rectangles?.length) return;
      if (!this.rectangle?.y) return;
      let n = this.rectangle.y,
        s = this.rectangle.y + this.rectangle.height,
        e = this.rectangle.x + this.rectangle.width,
        w = this.rectangle.x;

      if (this.rectangle.height < 0) {
        n = this.rectangle.y + this.rectangle.height;
        s = this.rectangle.y;
      }

      if (this.rectangle.width < 0) {
        e = this.rectangle.x;
        w = this.rectangle.x + this.rectangle.width;
      }

      const inRange = (start, direction) =>
        start >= direction - SOFT_OFFSET && start <= direction + SOFT_OFFSET;

      const inBetween = (start, top, bottom) =>
        (start <= bottom || inRange(start, bottom)) &&
        (start >= top || inRange(start, top));

      this.alignment.n =
        inRange(y, n) && inBetween(x, w, e) && !this.rectangle.isFieldOption;
      this.alignment.s =
        inRange(y, s) && inBetween(x, w, e) && !this.rectangle.isFieldOption;
      this.alignment.e =
        inRange(x, e) && inBetween(y, n, s) && !this.rectangle.isFieldOption;
      this.alignment.w =
        inRange(x, w) && inBetween(y, n, s) && !this.rectangle.isFieldOption;

      this.alignment.center = clickedWithinBoundingBox({
        mouseX: x,
        mouseY: y,
        rectX: this.rectangle.x,
        rectY: this.rectangle.y,
        rectWidth: this.rectangle.width,
        rectHeight: this.rectangle.height,
        radius: RADIO_OPTION_RADIUS,
        isCircle: this.rectangle.isFieldOption
      });
    },
    clearAlignment() {
      this.alignment.n = false;
      this.alignment.s = false;
      this.alignment.e = false;
      this.alignment.w = false;
      this.alignment.center = false;
    },
    clearScreen() {
      const canvas = this.getCurrentCanvas();
      if (!canvas) return;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  }
};
</script>
