<template>
  <div class="white-space-pre-wrap">
    <template v-for="(t, index) in splitText">
      <a v-if="t.isLink" :key="index" :href="t.text" target="_blank">{{
        t.text
      }}</a>
      <span :key="index" v-else>{{ t.text }}</span>
    </template>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps({
  text: {
    type: String,
    required: true
  }
});

const urlRegex = new RegExp(/(https:\/\/[^\s"]+)/g);

const splitText = props.text
  .split(urlRegex)
  .map(t => ({ isLink: urlRegex.test(t), text: t }));
</script>
