import PersonnelFactory from "@/factories/PersonnelFactory";
import { getHttpClient } from "@/http-client";
const baseUrl = "api/boss/personnels";

export const uploadPersonnelAvatar = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return getHttpClient().put(`${baseUrl}/${id}/avatar`, formData);
};

export const getAllPersonnel = async () => {
  const params = new URLSearchParams();

  const { data } = await getHttpClient().get(baseUrl, { params });
  return data.personnels;
};

export const createPersonnel = params => {
  return getHttpClient().post(baseUrl, params);
};

export const getPersonnel = async id => {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);

  const personnel = new PersonnelFactory();
  personnel.setFromRequest(data.personnel);
  return personnel;
};

export const deletePersonnel = id => {
  return getHttpClient().delete(`${baseUrl}/${id}`);
};

export const updatePersonnel = (id, params) => {
  return getHttpClient().put(`${baseUrl}/${id}`, params);
};
export const fetchCarrierPersonnel = async carrierId => {
  const params = new URLSearchParams();
  params.append("carrier", carrierId);
  const { data } = await getHttpClient().get(`${baseUrl}`, {
    params
  });

  return data.personnel;
};
