import { PayPeriod } from "@/factories/PayPeriodFactory";
import {
  getPayPeriodPdf,
  getPayPeriodCsv,
  getPayPeriodData
} from "@/api/pay-periods.service";
import { defineStore } from "pinia";
import { set } from "vue";

export const usePayPeriodViewStore = defineStore("pay-period", {
  state: () => PayPeriod(),
  getters: {
    payPeriodPdfUrl() {
      return getPayPeriodPdf(this.id);
    },
    payPeriodCsvUrl() {
      return getPayPeriodCsv(this.id);
    }
  },
  actions: {
    async loadPayPeriod(id) {
      this.$reset();
      const payPeriod = await getPayPeriodData(id);
      set(this, "$state", payPeriod);
    }
  }
});
