<template>
  <v-card>
    <v-card-title>Marketing Emails</v-card-title>
    <v-card-subtitle
      >Choose a template and send an email or generate HTML to send emails from
      your own email program. The box
      {{ $vuetify.breakpoint.mdAndDown ? " below " : " to the right of " }} the
      form will be updated with a preview of the final result.</v-card-subtitle
    >
    <v-row class="ma-0">
      <v-col cols="12" md="6">
        <v-card-text>
          <v-form @submit.prevent="createMarketing">
            <v-select
              prepend-inner-icon="$mdi-file"
              outlined
              label="Template"
              :items="templates"
              return-object
              v-model="selectedTemplate"
              @change="getTemplate"
              :success="selectedTemplateValidation.success"
              :error-messages="selectedTemplateValidation.errorMessages"
            />
            <v-text-field
              data-lpignore="true"
              prepend-inner-icon="$mdi-text"
              outlined
              label="Subject"
              v-model="subject"
              :placeholder="subjectText"
              :success="subjectTextValidation.success"
              :error-messages="subjectTextValidation.errorMessages"
            />
            <v-combobox
              v-model="chips"
              chips
              clearable
              label="Emails (up to 100)"
              multiple
              hint="Press Enter to Add"
              persistent-hint
              prepend-inner-icon="$mdi-email-multiple"
              outlined
              :search-input.sync="search"
              :success="chipsValidation.success"
              :error-messages="chipsValidation.errorMessages"
            >
              <template #selection="{ attrs, item, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click:close="remove(item)"
                >
                  <strong> {{ item }} </strong>
                </v-chip>
              </template>
            </v-combobox>

            <v-btn
              type="submit"
              color="primary"
              class="text-none"
              :loading="creatingMarketing"
              >Submit</v-btn
            >
          </v-form>
        </v-card-text>
      </v-col>
      <v-col cols="12" md="6" class="pa-6" v-if="iframeSrc">
        <v-card outlined style="line-height: 0">
          <v-row class="ma-0" align="center">
            <div>
              <v-card-title>
                {{ subjectText }}
              </v-card-title>
              <v-card-subtitle>
                <template v-if="activeTab === 0">
                  A preview of your selected template will appear below.
                </template>
                <template v-else>
                  The HTML for your selected template will appear below.
                </template>
              </v-card-subtitle>
            </div>
            <v-spacer />
            <div class="pa-3">
              <v-btn
                class="text-none"
                text
                color="primary"
                :loading="fetchingHtml"
                @click="previewHtml"
              >
                <v-icon>$mdi-file-code</v-icon>
                View Html
              </v-btn>
            </div>
          </v-row>
          <iframe
            v-if="iframeSrc"
            v-show="activeTab === 0"
            style="height: 40rem; border: 0; width: 100%"
            class="pa-0"
            :src="iframeSrc"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { templates } from "@/data/marketing-data";
import isEqual from "date-fns/isEqual";
import { required } from "vuelidate/lib/validators";
import {
  sendMarketingTemplate,
  getMarketingTemplate,
  getMarketingTemplateHtml
} from "@/api/marketing.service";
import { parseErrorMessage } from "@/util/helpers";
import { useHead } from "@unhead/vue";

import { mapState, mapActions } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";
import { useDialogStore } from "@/stores/dialog";

const tabs = [
  { text: "Preview", icon: "$mdi-file-find" },
  { text: "HTML", icon: "$mdi-file-code" }
];
export default {
  name: "MarketingView",
  setup() {
    useHead({ title: "Marketing" });
  },
  data() {
    return {
      templates,
      tabs,
      selectedTemplate: null,
      activeTemplate: null,
      chips: [],
      subject: null,
      creatingMarketing: false,
      activeTab: 0,
      search: "",
      searchTimer: null,
      iframeSrc: null,
      fetchingHtml: false
    };
  },
  watch: {
    search(v) {
      this.debounceUserInput(v);
    },
    selectedTemplate(newVal, oldVal) {
      if (isEqual(newVal, oldVal)) {
        return;
      }
      this.activeTemplate = null;
    }
  },
  computed: {
    ...mapState(useInstanceStore, ["nonce"]),
    activeTemplateHtml() {
      return this.activeTemplate?.html;
    },
    subjectText() {
      return this.selectedTemplate?.text;
    },
    chipsValidation() {
      const success = !this.$v.chips.$invalid;
      const errorMessages = [];
      if (!this.$v.chips.$dirty) return { success, errorMessages };
      if (!this.$v.chips.required)
        errorMessages.push("Required, Press Enter to add an email");
      return { success, errorMessages };
    },
    subjectTextValidation() {
      const success = !this.$v.subjectText.$invalid;
      const errorMessages = [];
      if (!this.$v.subjectText.$dirty) return { success, errorMessages };
      if (!this.$v.subjectText.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    selectedTemplateValidation() {
      const success = !this.$v.selectedTemplate.$invalid;
      const errorMessages = [];
      if (!this.$v.selectedTemplate.$dirty) return { success, errorMessages };
      if (!this.$v.selectedTemplate.required) errorMessages.push("Required");
      return { success, errorMessages };
    }
  },

  methods: {
    ...mapActions(useDialogStore, ["showDialog"]),
    ...mapActions(useSnackbarStore, [
      "showSuccessSnackbar",
      "showErrorSnackbar"
    ]),
    debounceUserInput(v) {
      if (this.searchTimer) clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        if (!v) return;
        const delimiters = [",", ";", "|"];
        delimiters.forEach(delimiter => {
          const split = v.split(delimiter);
          if (split.length <= 1) return;
          const nonEmptyEmails = split.filter(Boolean);
          this.chips.push(...nonEmptyEmails);
          this.search = "";
        });
      }, 200);
    },
    copyHtml() {
      this.$refs.html.$refs.input.select();
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      this.showSuccessSnackbar({
        message: "Successfully Copied HTML to your Clipboard"
      });
    },
    activeTabChanged(val) {
      this.activeTab = val;
    },
    getTemplate() {
      if (!this.selectedTemplate) {
        return;
      }
      this.activeTab = 0;
      this.subject = this.subjectText;
      const title = this.selectedTemplate.text;
      const type = this.selectedTemplate.value;
      this.iframeSrc = getMarketingTemplate(title, type);
    },
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
    },
    createMarketing() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.creatingMarketing = true;
      const emails = this.chips.join();
      const title = this.subject || this.subjectText;
      const type = this.selectedTemplate.value;
      sendMarketingTemplate(emails, type, title)
        .then(() => {
          this.showSuccessSnackbar({
            message: "Successfuly Sent Marketing Emails",
            timeout: 5000
          });
          this.selectedTemplate = null;
          this.chips = [];
          this.$v.$reset();
        })
        .catch(e => {
          this.showErrorSnackbar({ messsage: parseErrorMessage(e) });
        })
        .finally(() => {
          this.creatingMarketing = false;
        });
    },
    async previewHtml() {
      const title = this.selectedTemplate.text;
      const type = this.selectedTemplate.value;
      try {
        this.fetchingHtml = true;
        const templateHtml = await getMarketingTemplateHtml(title, type);
        this.showDialog({
          component: "CopyDialog",
          text: templateHtml,
          title,
          scrollable: true
        });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.fetchingHtml = false;
      }
    }
  },
  validations: {
    chips: { required },
    subjectText: { required },
    selectedTemplate: { required }
  }
};
</script>
