<template>
  <v-card flat tile>
    <v-card-text>
      <v-data-table
        must-sort
        data-testid="build-charts-table"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :loading="table.loading.value"
        :options="table.options.value"
        :search="search"
        :footer-props="footerProps"
      >
        <template #top>
          <v-row class="ma-0">
            <span class="text-h5"> Builds </span>
            <v-spacer />
            <v-text-field
              data-lpignore="true"
              label="Search"
              prepend-inner-icon="$mdi-magnify"
              v-model="search"
              outlined
              hide-details
              dense
              data-testid="build-charts-table-search"
            />
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  data-testid="build-charts-table-refresh"
                  :disabled="table.loading.value"
                  @click="getData"
                >
                  <v-icon> $mdi-refresh </v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
        </template>
        <template #[`item.actions`]="{ item }">
          <template v-if="isGroupFour">
            <v-btn
              icon
              color="accent"
              data-testid="edit-chart"
              @click="editBuildChart(item.additional)"
            >
              <v-icon>$mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              color="orange"
              @click="toggleCompleteness(item.additional)"
            >
              <v-icon v-if="item.additional.complete" data-testid="is-complete">
                $mdi-check-decagram
              </v-icon>
              <v-icon v-else data-testid="is-incomplete"
                >$mdi-decagram-outline</v-icon
              >
            </v-btn>
          </template>
          <v-btn
            v-if="item.additional.documentUrl"
            icon
            color="primary"
            data-testid="download-document"
            :href="item.additional.documentUrl"
          >
            <v-icon>$mdi-download</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";
import {
  buildChartsIndex,
  updateBuildChart,
  uploadBuildChartDocument
} from "@/api/build-charts.service";
import { useUserStore } from "@/stores/user";
import { getDocumentUrl } from "@/api/documents.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { computed, ref } from "vue";
import { useDialogStore } from "@/stores/dialog";
import TableOptions from "@/classes/data-table/TableOptions";

useHead({ title: "Builds" });

const search = ref("");

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const user = useUserStore();
const { isGroupFour } = storeToRefs(user);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      sortFilterMap: "name",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Gender",
      value: "gender",
      map: "gender",
      sortFilterMap: "gender",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    })
  ],
  options: new TableOptions(["name"], [false]),
  getData: async () => {
    const items = await buildChartsIndex();
    const mapped = items.map(v => ({
      ...v,
      documentUrl: v.documentUid ? getDocumentUrl(v.documentUid) : null
    }));
    return { items: mapped };
  }
});

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(
      table.options.value,
      table.items.value.length
    ),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

async function toggleCompleteness({ id, name, complete }) {
  const toggledValue = !complete;

  const res = await dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Mark ${name} as ${toggledValue ? "Complete" : "Incomplete"}`,
    subtitle: "Please confirm that you are intentionally making this change",
    showErrorMessage: true,
    func: () => updateBuildChart(id, toggledValue)
  });
  if (res?.confirm) getData();
}

function editBuildChart({ id }) {
  if (!isGroupFour.value) return;
  dialog
    .showDialog({
      component: "UploadDocumentDialog",
      uploadFunction: ({ file }) => uploadBuildChartDocument(id, file),
      single: true
    })
    .then(document => {
      if (!document) return;
      this.getData();
    });
}

getData();
</script>
