<template>
  <v-data-table
    class="transparent-data-table"
    data-testid="revenue-table"
    item-key="additional.key"
    filter-mode="union"
    :custom-filter="handleCustomFilter"
    :custom-sort="handleSort"
    :loading="loading"
    :search="search"
    :items="table.mappedItems.value"
    :headers="table.tableHeaders.value"
  >
    <template #[`item.fullPayPeriodText`]="{ item }">
      <router-link
        v-if="item.fullPayPeriodText"
        :to="{
          name: 'PayPeriodView',
          params: { id: item.additional.payPeriodId }
        }"
      >
        {{ item.fullPayPeriodText }}
      </router-link>
    </template>
    <template #[`item.fullStatementText`]="{ item }">
      <router-link
        v-if="item.fullStatementText"
        :to="{
          name: 'StatementView',
          params: { id: item.additional.statementId }
        }"
      >
        {{ item.fullStatementText }}
      </router-link>
    </template>
    <template #[`item.fullCommissionPercentText`]="{ item }">
      {{ item.additional.commissionPercentText }}
      <br />
      <span class="grey--text"> ({{ item.additional.paymentType }}) </span>
    </template>
    <template #[`item.fullCommissionText`]="{ item }">
      {{ item.additional.commissionText }}
      <br />
      <span class="grey--text"> ({{ item.additional.type }}) </span>
    </template>
  </v-data-table>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";

import { useUserStore } from "@/stores/user";

import { defineProps, toRefs, watch } from "vue";
import { useTable } from "@/composables/table.composable";
import {
  currencyFormat,
  formatPercentage,
  timestampFormatter
} from "@/util/helpers";
import parse from "date-fns/parse";
import { storeToRefs } from "pinia";
import { useCaseViewStore } from "@/stores/case-view";

const props = defineProps({
  search: {
    type: [Object, String],
    required: false,
    default: () => null
  },
  loading: Boolean
});

const { search, loading } = toRefs(props);

const { revenue } = storeToRefs(useCaseViewStore());

const user = useUserStore();

const table = useTable({ headers: getHeaders() });

function getHeaders() {
  const headers = [];
  headers.push(
    new TableHeader({
      text: "BackNine Statement",
      value: "fullPayPeriodText",
      map: "fullPayPeriodText",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    })
  );
  if (user.isGroupFour) {
    headers.push(
      new TableHeader({
        text: "Carrier Statement",
        value: "fullStatementText",
        map: "fullStatementText",
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_SORTABLE
      })
    );
  }

  headers.push(
    new TableHeader({
      text: "Paid To",
      value: "paidTo",
      map: "paidTo",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Payor",
      value: "payor",
      map: "payor",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),

    new TableHeader({
      text: "Statement Date",
      value: "statementDateText",
      map: "statementDateText",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Split",
      value: "splitText",
      map: "splitText",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE,
      width: "80px"
    }),
    new TableHeader({
      text: "Premium",
      value: "premiumText",
      map: "premiumText",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Commission Percent",
      value: "fullCommissionPercentText",
      map: "fullCommissionPercentText",
      width: "120px",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Commission",
      value: "fullCommissionText",
      map: "fullCommissionText",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    })
  );

  return headers;
}

function setItems() {
  table.items.value.splice(0, table.items.value.length);

  revenue.value.forEach(item => {
    if (item.statementDate) {
      item.statementDateText = timestampFormatter(
        item.statementDate,
        "sole-day",
        "basic"
      );
    }

    if (item.payPeriodId) {
      item.fullPayPeriodText = `#${item.payPeriodId}`;
    }

    if (item.statementId) {
      item.fullStatementText = `#${item.statementId}`;
    }

    if (item.premium || item.premium === 0) {
      item.premiumText = currencyFormat(item.premium, 2);
    }

    if (item.split || item.split === 0) {
      item.splitText = formatPercentage(item.split, {
        isDecimal: true,
        decimalLength: 0
      });
    }

    if (item.commission || item.commission === 0) {
      item.commissionText = currencyFormat(item.commission, 2);
      item.fullCommissionText = `${item.commissionText} (${item.type})`;
    }

    if (item.commissionPercent || item.commissionPercent === 0) {
      item.commissionPercentText = formatPercentage(item.commissionPercent, {
        isDecimal: true
      });
      item.fullCommissionPercentText = `${item.commissionPercentText} (${item.paymentType})`;
    }

    table.items.value.push(item);
  });
}

function handleCustomFilter(value, search, item) {
  const loweredSearch = search.toLowerCase();
  if (
    filterCommissionPercent(loweredSearch, item.additional) ||
    filterCommission(loweredSearch, item.additional)
  ) {
    return true;
  }

  return Object.values(item).some(v => {
    if (typeof v !== "string") return false;
    return v.toLowerCase().indexOf(loweredSearch) !== -1;
  });
}

function filterCommissionPercent(loweredSearch, row) {
  let loweredColumnValue = "";
  if (row.commissionPercentText) {
    loweredColumnValue = row.commissionPercentText.toLowerCase();
  }

  let loweredPaymentType = "";
  if (row.paymentType) {
    loweredPaymentType = row.paymentType.toLowerCase();
  }
  return (
    loweredColumnValue.indexOf(loweredSearch) !== -1 ||
    loweredPaymentType.indexOf(loweredSearch) !== -1
  );
}

function filterCommission(loweredSearch, row) {
  let loweredColumnValue = "";
  if (row.commissionText) {
    loweredColumnValue = row.commissionText.toLowerCase();
  }

  let loweredType = "";
  if (row.type) {
    loweredType = row.type.toLowerCase();
  }

  return (
    loweredColumnValue.indexOf(loweredSearch) !== -1 ||
    loweredType.indexOf(loweredSearch) !== -1
  );
}

function handleSort(items, sortBy, sortDesc) {
  const unsorted = [...items];
  const [col] = sortBy;
  if (!col) return unsorted;
  const [desc] = sortDesc;

  const getOrder = (a, b) => (desc ? { b: a, a: b } : { a, b });

  if (col === "statementDateText") {
    unsorted.sort((a, b) => {
      const { a: first, b: second } = getOrder(a, b);
      return (
        parse(first.statementDateText, "MM/dd/yyyy", new Date()).getTime() -
        parse(second.statementDateText, "MM/dd/yyyy", new Date())
      );
    });
  } else {
    unsorted.sort((a, b) => {
      const { a: first, b: second } = getOrder(a, b);
      return `${first[col]}`.localeCompare(`${second[col]}`);
    });
  }

  return unsorted;
}

watch(revenue, setItems, { immediate: true, deep: true });
</script>
