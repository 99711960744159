export function AdvisorTableItemFactory(model = {}) {
  return {
    model: model?.model || "",
    createdAt: model?.createdAt || "",
    email: model?.email || "",
    id: model?.id || 0,
    marketingManagerName: model?.marketingManagerName || "",
    name: model?.name || "",
    phone: model?.phone || "",
    state: model?.state || "",
    ytd: model?.ytd || 0,
    pending: model?.pending || 0,
    lastSubmit: model?.lastSubmit || null,
    setFromRequest(model = {}) {
      this.link = model?.connection_type?.link;
      this.model = model?.connection_type?.model;
      this.createdAt = model?.loginable?.created_at;
      this.email = model?.loginable?.email;
      this.id = model?.loginable?.id;
      this.marketingManagerName = model?.loginable?.marketing_manager?.name;
      this.name = model?.loginable?.name;
      this.phone = model?.loginable?.phone;
      this.state = model?.loginable?.state;
      this.ytd = model?.loginable?.stats?.ytd;
      this.pending = model?.loginable?.stats?.pending;
      this.lastSubmit = model?.loginable?.stats?.last_submit;
    }
  };
}
