<template>
  <span>{{ formattedValue }}</span>
</template>

<script setup>
import { startPageItems } from "@/data/filter-options";

import { computed, defineProps } from "vue";

const props = defineProps({
  value: {
    type: String,
    required: true
  }
});

const formattedValue = computed(() => {
  const startPage = startPageItems.find(item => item.value === props.value);
  return startPage?.text;
});
</script>
