export default function SmokerChartFactory(model = {}) {
  return {
    id: model?.id,
    name: "",
    carrierName: "",
    setFromRequest(model = {}) {
      this.id = model?.id;
      this.name = model?.name;
      this.carrierName = model?.carrier?.name;
    }
  };
}
