<template>
  <v-card class="pa-3">
    <v-row class="ma-0">
      <v-col cols="12">
        <help-center-videos />
      </v-col>
      <v-col cols="12">
        <help-center-articles />
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import HelpCenterVideos from "@/components/help-center/HelpCenterVideos.vue";
import HelpCenterArticles from "@/components/help-center/HelpCenterArticles.vue";
import { useHead } from "@unhead/vue";

useHead({ title: "Help Center" });
</script>
