import { getHttpClient } from "@/http-client";
const baseUrl = "api/boss/approved_domains";

import {
  ApprovedDomainTableItem,
  setApprovedDomainFromRequest,
  setApprovedDomainAccessFromRequest,
  setQuestionFromRequest,
  setApprovedDomainIntegrationFromRaw
} from "@/factories/ApprovedDomain";
import { setCommissionSplitFromRequest } from "@/factories/CommissionSplitFactory";

import {
  convertFileToBase64,
  serializeObject,
  parseErrorMessage
} from "@/util/helpers";

export const getApprovedDomain = async id => {
  const { data } = await getHttpClient().get(`/${baseUrl}/${id}`);
  return setApprovedDomainFromRequest(data.approved_domain);
};

export const getApprovedDomains = async (params, cancelToken) => {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const { data } = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });
  const approvedDomains = [];
  data?.approved_domains?.forEach(rawApprovedDomain => {
    const approvedDomain = new ApprovedDomainTableItem();
    approvedDomain.setFromRequest(rawApprovedDomain);
    approvedDomains.push(approvedDomain);
  });

  const meta = data?.meta || {};

  return { approvedDomains, meta };
};

export const uploadApprovedDomainAvatar = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return getHttpClient().put(`${baseUrl}/${id}/avatar`, formData);
};

export const deleteApprovedDomainAvatar = id => {
  return getHttpClient().delete(`${baseUrl}/${id}/avatar`);
};

export const archiveApprovedDomain = id => {
  return getHttpClient().put(`${baseUrl}/${id}/archive`);
};

export const unarchiveApprovedDomain = id => {
  return getHttpClient().put(`${baseUrl}/${id}/unarchive`);
};

export const createApprovedDomain = async ({
  domain,
  custom_domain_id,
  greeting,
  avatar
}) => {
  const approved_domain = {
    domain,
    custom_domain_id,
    greeting,
    avatar
  };

  if (avatar) {
    const avatarBase64 = await convertFileToBase64(avatar);
    approved_domain.avatar = avatarBase64.contents;
  }

  const { data } = await getHttpClient().post(baseUrl, {
    approved_domain
  });

  return data.approved_domain;
};

export function updateApprovedDomain(id, params) {
  return getHttpClient().put(`${baseUrl}/${id}`, {
    approved_domain: params
  });
}

export function addApprovedDomainCarrier(id, carrierId) {
  return getHttpClient().post(`${baseUrl}/${id}/carriers/${carrierId}`);
}

export function removeApprovedDomainCarrier(id, carrierId) {
  return getHttpClient().delete(`${baseUrl}/${id}/carriers/${carrierId}`);
}

export async function createDomainAccess(domainId, body) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${domainId}/accesses`,
    body
  );

  return setApprovedDomainAccessFromRequest(data.approved_domain_access);
}

export function deleteDomainAccess(domainId, accessId) {
  return getHttpClient().delete(`${baseUrl}/${domainId}/accesses/${accessId}`);
}

export function updateDomainAccess(domainId, accessId, body) {
  return getHttpClient().put(
    `${baseUrl}/${domainId}/accesses/${accessId}`,
    body
  );
}

export async function createApprovedDomainCommissionSplit(
  domainId,
  commission_split
) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${domainId}/commission_splits`,
    { commission_split }
  );

  return setCommissionSplitFromRequest(data.commission_split);
}

export async function updateApprovedDomainCommissionSplit(
  domainId,
  commissionSplitId,
  commission_split
) {
  const { data } = await getHttpClient().put(
    `${baseUrl}/${domainId}/commission_splits/${commissionSplitId}`,
    { commission_split }
  );

  return setCommissionSplitFromRequest(data.commission_split);
}

export function deleteApprovedDomainCommissionSplit(
  domainId,
  commissionSplitId
) {
  return getHttpClient().delete(
    `${baseUrl}/${domainId}/commission_splits/${commissionSplitId}`
  );
}

export async function searchApprovedDomains(search) {
  const params = new URLSearchParams();
  params.append("search", search);
  const { data } = await getHttpClient().get(`${baseUrl}/search`, {
    params
  });
  return data;
}

export async function createApprovedDomainQuestion(approvedDomainId, body) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${approvedDomainId}/questions`,
    { approved_domain_question: body }
  );
  return setQuestionFromRequest(data.approved_domain_question);
}

export async function updateApprovedDomainQuestion(
  approvedDomainId,
  questionId,
  body
) {
  const { data } = await getHttpClient().put(
    `${baseUrl}/${approvedDomainId}/questions/${questionId}`,
    { approved_domain_question: body }
  );
  return setQuestionFromRequest(data.approved_domain_question);
}

export async function deleteApprovedDomainQuestion(
  approvedDomainId,
  questionId
) {
  return getHttpClient().delete(
    `${baseUrl}/${approvedDomainId}/questions/${questionId}`
  );
}

export async function updateApprovedDomainHeroImage(approvedDomainId, file) {
  const body = await serializeObject({ file });
  const { data } = await getHttpClient().put(
    `${baseUrl}/${approvedDomainId}/hero-image`,
    body
  );
  return data.url;
}

export async function deleteApprovedDomainHeroImage(approvedDomainId) {
  const { data } = await getHttpClient().delete(
    `${baseUrl}/${approvedDomainId}/hero-image`
  );
  return data.url;
}

export async function createApprovedDomainIntegration(
  approvedDomainId,
  approvedDomainIntegration
) {
  try {
    const { data } = await getHttpClient().post(
      `${baseUrl}/${approvedDomainId}/integrations`,
      {
        approved_domain_integration: approvedDomainIntegration
      }
    );

    return setApprovedDomainIntegrationFromRaw(
      data.approved_domain_integration
    );
  } catch (e) {
    throw parseErrorMessage(e);
  }
}

export async function updateApprovedDomainIntegration(
  approvedDomainId,
  integrationId,
  approvedDomainIntegration
) {
  try {
    const { data } = await getHttpClient().put(
      `${baseUrl}/${approvedDomainId}/integrations/${integrationId}`,
      {
        approved_domain_integration: approvedDomainIntegration
      }
    );

    return setApprovedDomainIntegrationFromRaw(
      data.approved_domain_integration
    );
  } catch (e) {
    throw parseErrorMessage(e);
  }
}

export async function deleteApprovedDomainIntegration(
  approvedDomainId,
  integrationId
) {
  try {
    await getHttpClient().delete(
      `${baseUrl}/${approvedDomainId}/integrations/${integrationId}`
    );
    return setApprovedDomainIntegrationFromRaw({});
  } catch (e) {
    throw parseErrorMessage(e);
  }
}
