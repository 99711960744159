<template>
  <v-text-field
    v-model="npn"
    data-lpignore="true"
    prepend-inner-icon="$mdi-pound"
    label="NPN"
    outlined
    type="text"
    inputmode="numeric"
    class="no-tick has-append-outer-button pr-0"
    dense
    :loading="loading"
    :disabled="disabled"
    :error-messages="errorMessages"
    :success="success"
    persistent-hint
    hint=" "
    @input="handleTyping"
  >
    <template #message="{ message }" v-if="!disabled">
      <v-row class="pa-3">
        {{ message }}
        <v-spacer />
        <slot v-if="$slots.message" name="message" />
        <a href="https://www.nipr.com/PacNpnSearch.htm" target="_blank">
          Don't know your NPN?
        </a>
      </v-row>
    </template>
    <template #append v-if="hasAppendedSlot">
      <slot name="append" />
    </template>
    <template #append-outer v-if="hasAppendOuterSlot">
      <slot name="append-outer" />
    </template>
  </v-text-field>
</template>

<script>
import { validateNpn } from "@/api/nipr.service";
import { parseErrorMessage } from "@/util/helpers";
export default {
  props: {
    validate: Boolean,
    success: Boolean,
    errorMessages: Array,
    value: [String, Number],
    disabled: Boolean
  },
  data() {
    return {
      npn: this.value || null,
      niprTimer: null,
      isTyping: false,
      typingTimer: null,
      loading: false
    };
  },
  defineEmits: ["input", "valid", "error-message"],
  watch: {
    value() {
      if (this.value === this.npn) return;
      this.npn = this.value;
    }
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots["append-outer"];
    },
    hasAppendedSlot() {
      return !!this.$slots["append"];
    }
  },
  methods: {
    handleTyping() {
      this.$emit("input", this.npn);

      if (!this.validate) return;

      this.isTyping = true;
      this.$emit("valid", null);
      if (this.typingTimer) clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        this.isTyping = false;
      }, 500);

      this.debounceAndValidate();
    },
    debounceAndValidate() {
      if (this.niprTimer) clearTimeout(this.niprTimer);
      this.niprTimer = setTimeout(this.validateNpn, 1000);
    },
    async validateNpn() {
      this.$emit("error-message", "");
      if (!this.npn) {
        this.$emit("valid", false);
        return;
      }
      const params = {
        npn: this.npn
      };

      try {
        this.loading = true;
        const { data } = await validateNpn(params);
        this.loading = false;
        if (data?.valid === true) {
          this.$emit("valid", true);
          return;
        }

        this.$emit("valid", false);
        this.$emit("error-message", data?.error);
      } catch (e) {
        this.$emit("valid", false);
        this.$emit("error-message", parseErrorMessage(e));
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
