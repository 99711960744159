<template>
  <v-autocomplete
    v-model="agent"
    autocomplete="false"
    prepend-inner-icon="$mdi-account"
    outlined
    dense
    return-object
    no-filter
    hide-no-data
    auto-select-first
    :hint="$slots.message ? ' ' : null"
    :persistent-hint="Boolean($slots.message)"
    :item-text="itemTextGenerator"
    :clearable="clearable"
    :success="success"
    :error-messages="errorMessages"
    :items="agents"
    :disabled="disabled"
    :placeholder="placeholder"
    :label="label"
    :search-input.sync="agentSearchText"
    :loading="loading"
    :hide-details="hideDetails"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    ref="agentSearchText"
    @blur="$emit('blur')"
    @click:clear="$emit('input', {})"
    @change="$emit('change')"
  >
    <template #message="{ message }">
      <v-row class="ma-0">
        {{ message }}
        <v-spacer />
        <slot name="message" />
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script>
import { agentSearch } from "@/api/search.service";
import sortBy from "lodash/sortBy";
import { appointmentManagerSearch } from "@/api/agents.service";
export default {
  props: {
    includeAssignable: Boolean,
    appointmentManager: Boolean,
    success: Boolean,
    errorMessages: Array,
    disabled: Boolean,
    placeholder: String,
    label: String,
    value: [Object, String],
    hideDetails: Boolean,
    clearable: Boolean,
    betterReturn: Boolean
  },
  data() {
    let agent = null;
    let agents = [];
    if (this.value?.id) {
      agents.push(this.value);
      agent = this.value;
    }
    return {
      agents,
      agent,
      agentSearchText: null,
      agentTimer: null,
      loading: false
    };
  },
  watch: {
    agent(value) {
      if (!value?.id && !this.clearable) return;
      if (value === this.value) return;
      this.$emit("input", value);
    },
    value(value) {
      let agent = null;
      let agents = [];
      if (value && value?.name) {
        agent = value;
        agents = [value];
      } else {
        this.agentSearchText = null;
      }
      this.$set(this, "agent", agent);
      this.$set(this, "agents", agents);
    },
    agentSearchText(value) {
      if (!value) {
        return;
      }
      if (this.agents.some(val => this.itemTextGenerator(val) === value)) {
        return;
      }
      if (this.agentTimer) {
        clearTimeout(this.agentTimer);
      }
      this.agentTimer = setTimeout(() => {
        this.loading = true;
        let func = v => agentSearch(v, this.includeAssignable);
        if (this.appointmentManager) {
          func = appointmentManagerSearch;
        }
        return func(value)
          .then(response => {
            let agents = sortBy(response.data.agents, "name");
            if (this.betterReturn) {
              agents = agents.map(a => ({
                ...a,
                type: a?.type?.model || a?.type
              }));
            }
            this.agents = agents;
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  },
  methods: {
    focus() {
      this.$refs.agentSearchText.$refs.input.focus();
    },
    itemTextGenerator(val) {
      if (!val?.email) return val?.name;
      return `${val.name} · ${val.email}`;
    },
    clear() {
      this.agent = null;
      this.agents = [];
    }
  }
};
</script>
