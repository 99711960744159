<template>
  <v-card>
    <v-card-title> {{ title }} Integration </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="integration.clientId"
        v-bind="licenseKeyValidation"
        data-testid="integration-client-id"
        prepend-inner-icon="$mdi-pound"
        :label="props.keyName"
        outlined
        dense
      />
      <phone-input
        v-if="props.showPhone"
        v-model="integration.phone"
        :success="Boolean(phoneValidation.success && integration.phone)"
        :error-messages="phoneValidation.errorMessages"
        data-testid="integration-phone"
        prepend-inner-icon="$mdi-phone"
        label="Toll Free Number"
        outlined
        dense
      />
      <v-switch
        v-model="integration.enabled"
        data-testid="integration-enable"
        class="mt-0"
        label="Enable Integration"
        hide-details
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="integration.id"
        data-testid="integration-update"
        class="text-none"
        color="primary"
        :loading="saving"
        @click="updateIntegration"
      >
        Update Integration
      </v-btn>

      <v-btn
        v-else
        class="text-none"
        color="primary"
        data-testid="integration-create"
        :loading="saving"
        @click="createIntegration"
      >
        Create Integration
      </v-btn>
      <v-btn class="text-none" outlined @click="dialog.closeDialog()">
        Cancel
      </v-btn>
      <template v-if="integration.id">
        <v-spacer />
        <v-btn
          class="text-none"
          data-testid="integration-delete"
          color="error"
          @click="deleteIntegrationConfirm"
        >
          Delete Integration
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import PhoneInput from "@/components/shared/PhoneInput.vue";
import { ApprovedDomainIntegration } from "@/factories/ApprovedDomain";
import { computedValidation } from "@/util/helpers";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import useVuelidate from "@vuelidate/core";
import { defineProps, ref } from "vue";
const props = defineProps({
  value: {
    type: Object,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  keyName: {
    type: String,
    required: true
  },
  showPhone: Boolean
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const approvedDomain = useApprovedDomainView();

const saving = ref(false);

const integration = ref(ApprovedDomainIntegration(props.value));

const v$ = useVuelidate(
  {
    integration: {
      clientId: {
        required: Boolean
      },
      phone: {
        validPhoneNumber: value => !value || value?.length === 14
      }
    }
  },
  {
    integration
  },
  {
    $autoDirty: true,
    $scope: false
  }
);

const licenseKeyValidation = computedValidation(v$.value.integration.clientId, {
  required: "Required"
});

const phoneValidation = computedValidation(v$.value.integration.phone, {
  validPhoneNumber: v => !v || v.length === 14
});

async function updateIntegration() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  try {
    saving.value = true;
    const res = await approvedDomain.updateIntegration(integration.value);
    dialog.closeDialog({ integration: res });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: e });
  } finally {
    saving.value = false;
  }
}
async function createIntegration() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  try {
    saving.value = true;
    const res = await approvedDomain.createIntegration(integration.value);
    dialog.closeDialog({ integration: res });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: e });
  } finally {
    saving.value = false;
  }
}
async function deleteIntegrationConfirm() {
  dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Delete Integration",
    subtitle: "Are you sure you want to delete this integration?",
    func: deleteIntegration
  });
}

async function deleteIntegration() {
  try {
    const res = await approvedDomain.deleteIntegration(integration.value);
    dialog.closeAll({ integration: res });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: e });
  }
}
</script>
