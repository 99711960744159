<template>
  <v-card>
    <v-card-text>
      <v-expansion-panels class="mb-3">
        <v-expansion-panel>
          <v-expansion-panel-header> Category States </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <form-mapping-category-states-table />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <form-mapping-forms-table />
    </v-card-text>
  </v-card>
</template>

<script setup>
import FormMappingCategoryStatesTable from "@/components/form-mapping/FormMappingCategoryStatesTable.vue";
import FormMappingFormsTable from "@/components/form-mapping/FormMappingFormsTable.vue";
import { useHead } from "@unhead/vue";

useHead({ title: "Forms" });
</script>
