<template>
  <v-card flat tile>
    <v-card-text class="pb-0">
      <v-alert v-if="test" outlined type="warning">
        This eApp was created in test mode</v-alert
      >
      <v-row class="ma-0" dense align="center">
        <v-col
          cols="12"
          md="6"
          order="2"
          order-md="1"
          data-testid="card-insured"
          :align="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
        >
          <strong style="font-size: 1.2rem">
            {{ insuredName }}
          </strong>
          <br />
          Insured
        </v-col>
        <v-spacer />
        <v-col v-if="product.id" cols="12" md="6" order="1" order-md="2">
          <v-row justify-md="end" justify="center" class="px-3 pb-3">
            <carrier-image
              data-testid="card-carrier-avatar"
              :image="carrier.avatarUrl"
              :id="carrier.id"
            />
          </v-row>
        </v-col>
      </v-row>
      <v-divider />
      <template v-for="(detailGroup, index) in detailGroups">
        <v-row class="ma-0" :key="index">
          <v-col
            v-for="{ component, props, key, on } in detailGroup"
            :key="key"
            cols="12"
            xl="3"
            lg="3"
            md="4"
            sm="6"
            xs="6"
          >
            <component
              :is="component"
              v-bind="props"
              v-on="on"
              :data-testid="`card-${key}`"
            />
          </v-col>
        </v-row>
        <v-divider :key="index + 'divider'" />
      </template>
      <template v-if="nextStep">
        <v-row class="ma-0">
          <v-col cols="12">
            <v-alert
              color="primary"
              elevation="2"
              type="info"
              dense
              data-testid="card-next-step"
            >
              Next Step: {{ nextStep }}
            </v-alert>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script setup>
import CarrierImage from "@/components/shared/CarrierImage.vue";
import CardItemTooltip from "@/components/shared/card-items/CardItemTooltip.vue";
import CardItemRouterLink from "@/components/shared/card-items/CardItemRouterLink.vue";
import CardItemText from "@/components/shared/card-items/CardItemText.vue";

import {
  yearsAndMonths,
  timestampFormatter,
  currencyFormat,
  formatToFullState,
  formatPercentage,
  modeFormat,
  formatFrequency,
  getRouterLink
} from "@/util/helpers";
import { useEappViewStore } from "@/stores/eapp-view";
import { storeToRefs } from "pinia";
import { ref, watchEffect } from "vue";
import { useDialogStore } from "@/stores/dialog";
import parseISO from "date-fns/parseISO";

const {
  nextStep,
  carrier,
  insured,
  jointInsured,
  test,
  latestQuotes,
  agent,
  approvedDomain,
  product,
  status,
  isCompleted,
  namedStep,
  selectedType,
  policyNumber,
  hasPhysician,
  humanApiInvitedAt,
  tia,
  orderNumber,
  examDate,
  examAddress,
  createdAt,
  referredBy,
  followUpAt,
  id,
  followUpIsScheduled
} = storeToRefs(useEappViewStore());

const detailGroups = ref([]);

const isLTC = latestQuotes.value.insured.productCategory === "LTC";
const jointLtc = latestQuotes.value.insured.partnerDiscount === "both" && isLTC;
const insuredName = jointLtc
  ? `${insured.value.name} and ${jointInsured.value.name}`
  : insured.value.name;

function generateDetailGroups() {
  detailGroups.value.splice(0, detailGroups.value.length);
  const insuredQuote = latestQuotes.value.insured;
  const jointInsuredQuote = latestQuotes.value.jointInsured;

  const isLinkedBenefit = insuredQuote.productCategory === "Linked Benefit";

  let payDuration = insuredQuote.payDuration;
  if (payDuration === -1) payDuration = "Lifetime";
  else if (payDuration === -65) payDuration = "Pay to Age 65";

  const metaDetailItems = [];
  const productDetailItems = [];
  const insuredDetailItems = [];
  metaDetailItems.push({
    component: CardItemRouterLink,
    key: "Agent",
    props: {
      title: "Agent",
      text: agent.value.name,
      to: getRouterLink("Agent", agent.value.id)
    }
  });

  if (approvedDomain.value?.id) {
    if (approvedDomain.value?.isArchived) {
      metaDetailItems.push({
        component: CardItemTooltip,
        key: "Website",
        props: {
          title: "Website",
          text: approvedDomain.value.domain,
          tooltipText: `${approvedDomain.value.domain} is a deleted Quote & Apply site`
        }
      });
    } else {
      metaDetailItems.push({
        component: CardItemRouterLink,
        key: "Website",
        props: {
          title: "Website",
          text: approvedDomain.value.domain,
          to: getRouterLink("ApprovedDomain", approvedDomain.value?.id)
        }
      });
    }
  }

  if (status.value) {
    metaDetailItems.push({
      component: CardItemText,
      key: "Status",
      props: {
        title: "Status",
        text: status.value
      }
    });
  }

  if (!isCompleted.value) {
    metaDetailItems.push({
      component: CardItemText,
      key: "Current Step",
      props: {
        title: "Current Step",
        text: namedStep.value
      }
    });
  }

  if (insuredQuote.targetPremium) {
    metaDetailItems.push({
      component: CardItemText,
      key: "Target Premium",
      props: {
        title: "Target Premium",
        text: currencyFormat(insuredQuote.targetPremium)
      }
    });
  }

  if (createdAt.value) {
    metaDetailItems.push({
      component: CardItemText,
      key: "Date Created",
      props: {
        title: "Date Created",
        text: timestampFormatter(createdAt.value, "none", "basic")
      }
    });
  }

  if (referredBy.value) {
    metaDetailItems.push({
      component: CardItemText,
      key: "Referred By",
      props: {
        title: "Referred By",
        text: referredBy.value
      }
    });
  }

  // make editable
  let followUpText = "N/A";
  if (followUpAt.value) {
    try {
      followUpText = timestampFormatter(
        followUpAt.value,
        null,
        "full-date-time"
      );
    } catch (e) {
      // do nothing
    }
  }

  metaDetailItems.push({
    component: CardItemText,
    key: "Follow Up",
    props: {
      title: followUpIsScheduled.value ? "Appointment" : "Follow Up",
      text: followUpText,
      clickable: true
    },
    on: {
      click: async () => {
        let date;

        date = parseISO(followUpAt.value);
        if (date.toString() === "Invalid Date") date = null;

        const dialog = useDialogStore();
        const res = await dialog.showDialog({
          component: "LeadContactedDialog",
          eAppId: id.value,
          title: "Change Follow Up",
          date,
          followUpIsScheduled: Boolean(followUpIsScheduled.value)
        });
        if (!res?.id) return;
        followUpAt.value = res.followUp;
        followUpIsScheduled.value = res.followUpIsScheduled;
      }
    }
  });

  if (product.value?.id) {
    productDetailItems.push({
      component: CardItemRouterLink,
      key: "Product",
      props: {
        title: "Product",
        text: product.value.name,
        to: getRouterLink("Product", product.value.id)
      }
    });
  }

  if (carrier.value?.name) {
    productDetailItems.push({
      component: CardItemRouterLink,
      key: "Carrier",
      props: {
        title: "Carrier",
        text: carrier.value.name,
        to: getRouterLink("Carrier", carrier.value.id)
      }
    });
  }

  if (insuredQuote.state) {
    productDetailItems.push({
      component: CardItemText,
      key: "State",
      props: {
        title: "State",
        text: formatToFullState(insuredQuote.state)
      }
    });
  }

  if (insuredQuote.productCategory) {
    productDetailItems.push({
      component: CardItemText,
      key: "Product Category",
      props: {
        title: "Product Category",
        text: insuredQuote.productCategory
      }
    });
  }

  if (payDuration) {
    productDetailItems.push({
      component: CardItemText,
      key: "Pay Duration",
      props: {
        title: "Pay Duration",
        text: payDuration.toString()
      }
    });
  }

  if (
    !["aiul", "ul", "piul", "wholeLife"].includes(selectedType.value) &&
    !isLTC &&
    insuredQuote.solve
  ) {
    productDetailItems.push({
      component: CardItemText,
      key: "Solve",
      props: {
        title: "Solve",
        text: insuredQuote.solve
      }
    });
  }

  if (insuredQuote.ltcRider && !isLTC) {
    productDetailItems.push({
      component: CardItemText,
      key: "LTC Rider",
      props: {
        title: "LTC Rider",
        text: "Yes"
      }
    });
  }

  if (insuredQuote.chronicIllnessRider && !isLTC) {
    productDetailItems.push({
      component: CardItemText,
      key: "Chronic Rider",
      props: {
        title: "Chronic Rider",
        text: "Yes"
      }
    });
  }

  if (insuredQuote.ltcRider && !isLTC) {
    productDetailItems.push({
      component: CardItemText,
      key: "Chronic/LTC Rider Percentage",
      props: {
        title: "Chronic/LTC Rider Percentage",
        text: formatPercentage(insuredQuote.ltcRiderPercentage, {
          decimalLength: 2
        })
      }
    });
  }

  if (insuredQuote.faceAmount && !isLTC) {
    productDetailItems.push({
      component: CardItemText,
      key: "Death Benefit",
      props: {
        title: "Death Benefit",
        text: currencyFormat(insuredQuote.faceAmount, 0)
      }
    });
  }

  if ((isLTC || isLinkedBenefit) && insuredQuote.monthlyBenefit) {
    productDetailItems.push({
      component: CardItemText,
      key: "Monthly Benefit",
      title: "Monthly Benefit",
      props: {
        title: "Monthly Benefit",
        text: currencyFormat(insuredQuote.monthlyBenefit, 0)
      }
    });
  }

  if ((isLTC || isLinkedBenefit) && insuredQuote.benefitPeriod) {
    productDetailItems.push({
      component: CardItemText,
      key: "Benefit Period",
      props: {
        title: "Benefit Period",
        text: yearsAndMonths(insuredQuote.benefitPeriod)
      }
    });
  }

  if (isLTC && insuredQuote.eliminationPeriod) {
    productDetailItems.push({
      component: CardItemText,
      key: "Elimination Period",
      props: {
        title: "Elimination Period",
        text: `${insuredQuote.eliminationPeriod} Days`
      }
    });
  }

  if (isLTC) {
    productDetailItems.push({
      component: CardItemText,
      key: "Home Health Care Waiver of Premium",
      props: {
        title: "Home Health Care Waiver of Premium",
        text: insuredQuote.homeHealthCareWaiver ? "Yes" : "No"
      }
    });
  }

  if (isLTC && jointLtc) {
    productDetailItems.push({
      component: CardItemText,
      key: "Shared Care",
      props: {
        title: "Shared Care",
        text: insuredQuote.sharedCare ? "Yes" : "No"
      }
    });
  }

  if (isLTC && jointLtc) {
    productDetailItems.push({
      component: CardItemText,
      key: "Joint Waiver of Premium",
      props: {
        title: "Joint Waiver of Premium",
        text: insuredQuote.jointWaiverOfPremium ? "Yes" : "No"
      }
    });
  }

  if (isLTC && insuredQuote.cashBenefitPercentage) {
    productDetailItems.push({
      component: CardItemText,
      key: "Cash Benefit Percentage",
      props: {
        title: "Cash Benefit Percentage",
        text: formatPercentage(insuredQuote.cashBenefitPercentage, {
          isDecimal: true,
          decimalLength: 2
        })
      }
    });
  }

  if ((isLTC || isLinkedBenefit) && insuredQuote.inflationPercentage) {
    productDetailItems.push({
      component: CardItemText,
      key: "Inflation Rate Percentage",
      props: {
        title: "Inflation Rate Percentage",
        text: insuredQuote.inflationPercentage || "None"
      }
    });
  }

  if (product.value.multiplePolicies && jointInsuredQuote?.premium) {
    const formatter = (name, quote) =>
      `${name}'s ${currencyFormat(quote.premium, 2)} (${modeFormat(
        quote.mode,
        "adverb"
      )})`;
    productDetailItems.push({
      component: CardItemText,
      key: "Joint Premium",
      props: {
        title: "Premium",
        text: `${formatter(insured.value.name, insuredQuote)}\n${formatter(
          jointInsured.value.name,
          jointInsuredQuote
        )}`
      }
    });
  } else if (insuredQuote.premium) {
    productDetailItems.push({
      component: CardItemText,
      key: "Premium",
      props: {
        title: "Premium",
        text: `${currencyFormat(insuredQuote.premium)} (${modeFormat(
          insuredQuote.mode,
          "adverb"
        )})`
      }
    });
  }

  if (insuredQuote.section1035Exchange.enabled && !isLTC) {
    productDetailItems.push({
      component: CardItemText,
      key: "Section 1035 Exchange",
      props: {
        title: "Section 1035 Exchange",
        text: currencyFormat(insuredQuote.section1035Exchange.amount)
      }
    });
  }

  if (policyNumber.value) {
    productDetailItems.push({
      component: CardItemText,
      key: "Policy Number",
      props: {
        title: "Policy Number",
        text: policyNumber.value
      }
    });
  }

  if ([true, false].includes(hasPhysician.value)) {
    productDetailItems.push({
      component: CardItemText,
      key: "Using Human API",
      props: {
        title: "Using Human API",
        text: hasPhysician.value ? "Yes" : "No"
      }
    });
  }

  if (humanApiInvitedAt.value) {
    productDetailItems.push({
      component: CardItemText,
      key: "Human API Invited At",
      props: {
        title: "Human API Invited At",
        text: timestampFormatter(humanApiInvitedAt.value, "none", "date-time")
      }
    });
  }

  if ([true, false].includes(tia.value) && !isLTC) {
    productDetailItems.push({
      component: CardItemText,
      key: "Temporary Insurance",
      props: {
        title: "Temporary Insurance",
        text: tia.value ? "Yes" : "No"
      }
    });
  }

  if (orderNumber.value) {
    productDetailItems.push({
      component: CardItemText,
      key: "Exam Number",
      props: {
        title: "Exam Number",
        text: orderNumber.value
      }
    });
  }

  if (examDate.value) {
    productDetailItems.push({
      component: CardItemText,
      key: "Exam Date",
      props: {
        title: "Exam Date",
        text: timestampFormatter(examDate.value, "none", "date-time")
      }
    });
  }

  if (!jointLtc && insured.value.birthdate) {
    insuredDetailItems.push({
      component: CardItemText,
      key: "Birthdate",
      props: {
        title: "Birthdate",
        text: timestampFormatter(insured.value.birthdate, "sole-day")
      }
    });
  }

  if (jointLtc) {
    const birthdateFormat = insured =>
      `${timestampFormatter(insured.birthdate, "sole-day")} (${insured.name})`;
    insuredDetailItems.push({
      component: CardItemText,
      key: "Joint Birthdate",
      props: {
        title: "Birthdate",
        text: `${birthdateFormat(insured.value)}\n${birthdateFormat(
          jointInsured.value
        )}`
      }
    });
  }

  if (insuredQuote.rateClass && !jointLtc) {
    insuredDetailItems.push({
      component: CardItemText,
      key: "Initial Rating",
      props: {
        title: "Initial Rating",
        text: insuredQuote.rateClass
      }
    });
  } else if (jointLtc) {
    insuredDetailItems.push({
      component: CardItemText,
      key: "Joint Initial Rating",
      props: {
        title: "Initial Rating",
        text: `${insuredQuote.rateClass} (${insured.value.name})\n${jointInsuredQuote.rateClass} (${jointInsured.value.name})`
      }
    });
  }

  if (insuredQuote.heightText && insuredQuote.weight && !jointLtc) {
    insuredDetailItems.push({
      component: CardItemText,
      key: "Build",
      props: {
        title: "Build",
        text: `${insuredQuote.heightText} ${insuredQuote.weight}lbs\n${insuredQuote.buildText}`
      }
    });
  } else if (
    jointInsuredQuote?.heightText &&
    jointInsuredQuote?.weight &&
    insuredQuote.heightText &&
    insuredQuote.weight &&
    jointLtc
  ) {
    const text = [
      `${insuredQuote.heightText} ${insuredQuote.weight}lbs (${insured.value.name})`,
      insuredQuote.buildText,
      `${jointInsuredQuote.heightText} ${jointInsuredQuote.weight}lbs (${jointInsured.value.name})`,
      jointInsuredQuote.buildText
    ].join("\n");

    insuredDetailItems.push({
      component: CardItemText,
      key: "Joint Build",
      props: {
        title: "Build",
        text
      }
    });
  }

  if (jointLtc) {
    insuredDetailItems.push({
      component: CardItemText,
      key: "Joint Gender",
      props: {
        title: "Joint Gender",
        text: `${insuredQuote.gender} (${insured.value.name})\n${jointInsuredQuote.gender} (${jointInsured.value.name})`
      }
    });
  } else if (insuredQuote.gender) {
    insuredDetailItems.push({
      component: CardItemText,
      key: "Gender",
      props: {
        title: "Gender",
        text: insuredQuote.gender
      }
    });
  }

  const formatSmoker = (quote, name) => {
    const text = [];
    if (name) text.push(`${quote.smoker} (${name})`);
    else text.push(quote.smoker);
    if (quote.smoker !== "Never") {
      const addLastUseDate = quote.smoker === "Previously";
      quote.usages.forEach(s => {
        let t = `${s.category} ${formatFrequency(s.frequency)}`;
        if (addLastUseDate) {
          t += ` - Last: ${timestampFormatter(s.lastUseDate, "sole-day")}`;
        }
        text.push(t);
      });
    }
    return text.join("\n");
  };

  if (insuredQuote.smoker && !jointLtc) {
    insuredDetailItems.push({
      component: CardItemText,
      key: "Smoking Status",
      props: {
        title: "Smoking Status",
        text: formatSmoker(insuredQuote)
      }
    });
  } else if (insuredQuote.smoker && jointInsuredQuote?.smoker && jointLtc) {
    insuredDetailItems.push({
      component: CardItemText,
      key: "Joint Smoking Status",
      props: {
        title: "Smoking Status",
        text: [
          formatSmoker(insuredQuote, insured.value.name),
          formatSmoker(jointInsuredQuote, jointInsured.value.name)
        ].join("\n")
      }
    });
  }

  if (examAddress.value) {
    insuredDetailItems.push({
      component: CardItemText,
      key: "Exam Address",
      props: {
        title: "Exam Address",
        text: examAddress.value
      }
    });
  }
  detailGroups.value.push(
    ...[metaDetailItems, productDetailItems, insuredDetailItems].filter(
      v => v.length
    )
  );
}

watchEffect(generateDetailGroups, { flush: "post" });
</script>
