import { getHttpClient } from "@/http-client";
import { ApplicationQuestionFactory } from "@/factories/FormMappingFactory";
import { setAppQuestionFromRequest } from "@/factories/FormMappingFactory";
const baseUrl = "api/boss/application_questions";
/**
 * @param name
 * @param smart
 * @returns {Promise<ApplicationQuestion[] | Error>}
 */
export const getApplicationQuestionsByName = async (
  name,
  smart = false,
  modelType = null,
  modelId = null
) => {
  const params = new URLSearchParams();
  params.append("name", name);
  params.append("model", modelType);
  if (smart === true) {
    params.append("smart", "true");
  } else if (smart === false) {
    params.append("smart", "false");
  }
  if (modelId) {
    params.append("model_id", modelId);
  }
  const { data } = await getHttpClient().get(`${baseUrl}/search`, {
    params
  });

  return data.questions.map(rawApplicationQuestion => {
    const applicationQuestion = new ApplicationQuestionFactory();
    setAppQuestionFromRequest(applicationQuestion, rawApplicationQuestion);
    return applicationQuestion;
  });
};
