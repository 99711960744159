<template>
  <v-card>
    <v-card-title> {{ editing ? "Edit" : "Add" }} Payout</v-card-title>
    <v-card-text>
      <v-select
        v-model="payout.compBuilder"
        return-object
        outlined
        dense
        data-testid="comp-builder-select"
        prepend-inner-icon="$mdi-wrench"
        item-text="name"
        label="Comp Builder"
        :items="compBuilders"
        :success="compBuilderValidation.success"
        :error-messages="compBuilderValidation.errorMessages"
        :loading="fetchingCompBuilders"
      />
      <v-select
        v-model="payout.payor"
        outlined
        dense
        return-object
        data-testid="payor-select"
        label="Payor"
        prepend-inner-icon="$mdi-bank"
        item-text="name"
        :success="payorValidation.success"
        :error-messages="payorValidation.errorMessages"
        :items="payors"
      />
      <v-select
        v-model="payout.commissionType"
        outlined
        dense
        data-testid="commission-type-select"
        label="Commission Type"
        prepend-inner-icon="$mdi-cube"
        :items="commissionTypes"
        :success="commissionTypeValidation.success"
        :error-messages="commissionTypeValidation.errorMessages"
      />
      <v-text-field
        v-model.number="payout.percent"
        outlined
        dense
        data-testid="percent-input"
        data-lpignore="true"
        prepend-inner-icon="$mdi-percent"
        label="Percent"
        :success="percentValidation.success"
        :error-messages="percentValidation.errorMessages"
      />
      <v-text-field
        v-model.number="payout.startYear"
        outlined
        dense
        data-testid="start-year-input"
        label="Start Year"
        data-lpignore="true"
        prepend-inner-icon="$mdi-calendar-start"
        :success="startYearValidation.success"
        :error-messages="startYearValidation.errorMessages"
      />
      <v-text-field
        v-model.number="payout.endYear"
        outlined
        dense
        data-testid="end-year-input"
        data-lpignore="true"
        prepend-inner-icon="$mdi-calendar-end"
        label="End Year"
        :success="endYearValidation.success"
        :error-messages="endYearValidation.errorMessages"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        color="grey"
        class="text-none"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        data-testid="save"
        class="text-none"
        color="primary"
        :loading="loading"
        @click="save"
      >
        {{ editing ? "Save" : "Create" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import {
  Payout,
  PayoutToUpdateRequest,
  PayoutToCreateRequest
} from "@/factories/ProductFactory";
import { parseErrorMessage, validationComputeV2 } from "@/util/helpers";
import { compBuilderSearch } from "@/api/search.service";
import { createPayout, updatePayout } from "@/api/payouts.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { computed, defineProps, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { commissionTypes } from "@/data/transaction-data";

const sortedCommissionTypes = [...commissionTypes];
sortedCommissionTypes.sort((a, b) => a.localeCompare(b));

const props = defineProps({
  value: { type: Object, required: false, default: () => {} },
  payors: { type: Array, required: true },
  productId: { type: Number, required: true },
  carrierId: { type: Number, required: true }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const payout = ref(Payout(props.value));
const compBuilders = ref([]);
const fetchingCompBuilders = ref(false);
const loading = ref(false);
const editing = Boolean(payout.value.id);

const v$ = useVuelidate(
  {
    payout: {
      compBuilder: { required: v => Boolean(v?.id) },
      payor: { required: v => Boolean(v?.id) },
      commissionType: { required: v => Boolean(v) },
      percent: { required: v => Boolean(v) },
      startYear: { required: v => Boolean(v) },
      endYear: { required: v => Boolean(v) }
    }
  },
  { payout },
  { $scope: false, $autoDirty: false }
);

const compBuilderValidation = computed(() => {
  return validationComputeV2(v$.value.payout.compBuilder, [
    { key: "required", message: "Required" }
  ]);
});
const payorValidation = computed(() => {
  return validationComputeV2(v$.value.payout.payor, [
    { key: "required", message: "Required" }
  ]);
});
const commissionTypeValidation = computed(() => {
  return validationComputeV2(v$.value.payout.commissionType, [
    { key: "required", message: "Required" }
  ]);
});
const percentValidation = computed(() => {
  return validationComputeV2(v$.value.payout.percent, [
    { key: "required", message: "Required" }
  ]);
});
const startYearValidation = computed(() => {
  return validationComputeV2(v$.value.payout.startYear, [
    { key: "required", message: "Required" }
  ]);
});
const endYearValidation = computed(() => {
  return validationComputeV2(v$.value.payout.endYear, [
    { key: "required", message: "Required" }
  ]);
});

async function save() {
  if (loading.value) return;
  loading.value = true;

  const isValid = await v$.value.$validate();
  if (!isValid) return;

  try {
    await (editing ? update() : create());
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}

async function update() {
  await updatePayout(payout.value.id, PayoutToUpdateRequest(payout.value));
  snackbar.showSuccessSnackbar({
    message: "Payout has been updated",
    timeout: 6000
  });
  dialog.closeDialog({ payout: payout.value });
}

async function create() {
  const params = PayoutToCreateRequest(payout.value, props.productId);
  const newPayout = await createPayout(params);
  dialog.closeDialog({ payout: newPayout });
}

async function getCompBuilders() {
  fetchingCompBuilders.value = true;
  try {
    compBuilders.value = await compBuilderSearch(props.carrierId);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    fetchingCompBuilders.value = false;
  }
}

getCompBuilders();
</script>
