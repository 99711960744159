<template>
  <v-card v-if="policy && !loading">
    <v-btn
      style="position: absolute; top: 0; right: 0; z-index: 10"
      icon
      @click="$emit('refresh')"
    >
      <v-icon>$mdi-refresh</v-icon>
    </v-btn>
    <div class="py-3">
      <v-img :src="avatar" contain height="100" />
    </div>
    <v-divider />
    <v-card-text class="px-0">
      <v-row dense class="ma-0">
        <template v-for="(item, index) in items">
          <v-col
            v-if="item.title === 'divider'"
            cols="12"
            class="px-0"
            :key="`${index}-divider`"
          >
            <v-divider />
          </v-col>
          <transaction-case-item
            v-else
            class="px-3"
            :key="`${item.text}-${index}`"
            :item="item"
          />
        </template>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card v-else>
    <v-card-subtitle v-if="loading">
      Loading Policy <v-progress-circular class="ml-2" indeterminate />
    </v-card-subtitle>
    <v-card-subtitle v-else>
      Add a Policy Number to see Policy data here
    </v-card-subtitle>
  </v-card>
</template>

<script>
import TransactionCaseItem from "@/components/commissions/TransactionCaseItem.vue";
import { searchCaseTransactions } from "@/api/transactions.service";
import {
  currencyFormat,
  getRouterLink,
  modeFormat,
  parseErrorMessage,
  timestampFormatter
} from "@/util/helpers";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  components: { TransactionCaseItem },
  props: {
    backnineForecast: Number,
    totalForecast: Number,
    caseId: Number,
    policyNumber: String,
    commissionType: String,
    finalOverride: Number,
    statement: Object
  },
  data: () => ({ policy: {}, loading: false }),
  computed: {
    avatar() {
      if (!this.policy?.id) return null;
      return this.policy.product.carrier.avatar_url;
    },
    items() {
      return [
        ...this.overrideItems,
        this.overrideItems.length ? { title: "divider" } : null,
        ...this.caseItems,
        { title: "divider" },
        ...this.currencyItems
      ].filter(Boolean);
    },
    additionalCaseItems() {
      if (!this.policy?.id) return [];
      const additionalItems = [];
      if (this.commissionType.includes("First Year")) {
        additionalItems.push({
          icon: "$mdi-sack",
          subtitle: "First Year Total Commission",
          title: `${(this.policy.product.total_first * 100).toFixed(2)}%`,
          type: null
        });
      }

      if (this.commissionType.includes("Renewal")) {
        additionalItems.push({
          icon: "$mdi-sack",
          subtitle: "Renewal Total Commission",
          title: `${(this.policy.product.total_renewal * 100).toFixed(2)}%`,
          type: null
        });
      }

      if (this.commissionType.includes("Excess")) {
        additionalItems.push({
          icon: "$mdi-sack",
          subtitle: "Excess Total Commission",
          title: `${(this.policy.product.total_excess * 100).toFixed(2)}%`,
          type: null
        });
      }

      if (this.commissionType.includes("PUA")) {
        additionalItems.push({
          icon: "$mdi-sack",
          subtitle: "PUA Total Commission",
          title: `${(this.policy.product.total_pua * 100).toFixed(2)}%`,
          type: null
        });
      }

      if (this.commissionType.includes("Term Rider")) {
        additionalItems.push({
          icon: "$mdi-sack",
          subtitle: "Term Rider Total Commission",
          title: `${(this.policy.product.total_term_rider * 100).toFixed(2)}%`,
          type: null
        });
      }

      if (this.commissionType.includes("Bonus")) {
        additionalItems.push({
          icon: "$mdi-sack",
          subtitle: "Bonus Total Commission",
          title: `${(this.policy.product.total_bonus * 100).toFixed(2)}%`,
          type: null
        });
      }

      if (this.policy.target_premium) {
        additionalItems.push({
          icon: "$mdi-target",
          subtitle: "Target Premium",
          title: this.policy.target_premium,
          type: "currency"
        });
      }

      if (this.policy.policy_date) {
        additionalItems.push({
          icon: "$mdi-calendar",
          subtitle: "Policy Date",
          title: this.policy.policy_date,
          type: "parseSoleDay"
        });
      } else if (this.policy) {
        additionalItems.push({
          icon: "$mdi-calendar",
          subtitle: "Policy Date",
          title: "Must Add Policy Date",
          type: null
        });
      }

      return additionalItems;
    },
    caseItems() {
      if (!this.policy.id) {
        return [];
      }

      const agents = this.policy.appointment_cases
        .filter(val => {
          return [val.advisor.type?.model, val.advisor.type].includes("Agent");
        })
        .map(val => ({
          title: val.advisor.name,
          link: {
            name: "AgentView",
            params: { id: val.advisor.id }
          }
        }));

      return [
        {
          subtitle: "Statement",
          title: `${this.statement.payor.name} ${timestampFormatter(
            this.statement.date || this.statement.statement_date,
            "sole-day"
          )}`,
          link: {
            name: "StatementView",
            params: { id: this.statement.id }
          }
        },
        {
          subtitle: "Case",
          title: this.policy.policy_number,
          type: null,
          link: getRouterLink("Case", this.policy.id)
        },
        {
          subtitle: "Product",
          title: this.policy.product.name,
          type: null,
          link: {
            name: "ProductView",
            params: { id: this.policy.product.id }
          }
        },
        {
          subtitle: agents.length > 1 ? "Agents" : "Agent",
          title: agents
        },
        ...this.additionalCaseItems
      ].filter(Boolean);
    },
    currencyItems() {
      if (!this.policy?.id) return [];

      return [
        {
          icon: "$mdi-currency-usd",
          subtitle: "Premium",
          title: `${currencyFormat(this.policy.premium)} (${modeFormat(
            this.policy.mode
          )})`,
          type: null
        },
        {
          icon: "$mdi-currency-usd",
          subtitle: "Total First Year Premium",
          title: this.policy.first_year,
          type: "currency"
        }
      ];
    },
    overrideItems() {
      if (!this.policy?.id) return [];
      const showTotalForecast = this.totalForecast || this.totalForecast === 0;
      const showFinalOverride = this.finalOverride || this.finalOverride === 0;
      const items = [];
      if (showTotalForecast)
        items.push({
          icon: "$mdi-radar",
          subtitle: "Total Expected Override",
          title: this.totalForecast,
          type: "currency"
        });
      if (showFinalOverride)
        items.push({
          icon: "$mdi-currency-usd",
          subtitle: "Total Assumed Override",
          title: this.finalOverride,
          type: "currency",
          highlight: true
        });

      return items;
    }
  },
  created() {
    this.getRelevantCaseData();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async getRelevantCaseData() {
      this.loading = true;
      try {
        const cases = await searchCaseTransactions(null, this.caseId);
        const currentCase = cases.find(
          v => v.policy_number === this.policyNumber
        );
        this.$set(this, "policy", currentCase);
      } catch (e) {
        this.showErrorSnackbar(parseErrorMessage(e));
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
