<template>
  <chat
    title="Comp Builder Chat"
    flat
    color="transparent"
    ref="chat"
    :id="compBuilder.id"
    :pusher-id="`private-${compBuilder.id}-CompBuilder`"
    :messages="messages"
    @remove-message="removeFromMessages"
    @new-note="newNote"
    @send-message="sendChatMessage"
  />
</template>

<script>
import NoteFactory from "@/factories/NoteFactory";
import Chat from "@/components/shared/chat/Chat.vue";
import sortBy from "lodash/sortBy";
import { parseErrorMessage } from "@/util/helpers";
import { mapActions, mapState } from "pinia";
import { createNote } from "@/api/notes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  components: {
    Chat
  },
  props: {
    compBuilder: Object
  },
  data() {
    const user = useUserStore();
    const messages = sortBy(this.compBuilder.notes, "createdAt");
    messages.unshift({
      note: `Hi ${user.loginable.name}, feel free to make notes here.`,
      ownable: { name: "BackNine" }
    });

    return {
      messages
    };
  },
  computed: {
    ...mapState(useUserStore, ["loginable"])
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    removeFromMessages(message) {
      const index = this.messages.findIndex(({ id }) => id === message.id);

      this.messages.splice(index, 1);
    },

    async sendChatMessage(note) {
      this.$refs.chat.$refs.chatFooter.loading = true;
      const newNote = new NoteFactory({
        documents: note.files,
        email: note.sendEmail ? 1 : 0,
        phoneCall: note.sendPhoneCall,
        note: note.message,
        notableId: this.compBuilder.id,
        notableType: "CompBuilder"
      });
      try {
        await createNote(newNote.toCreateRequest());
        this.$refs.chat.$refs.chatFooter.reset();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
      } finally {
        this.$refs.chat.$refs.chatFooter.loading = false;
      }
    },
    newNote({ note: rawNote }) {
      const note = new NoteFactory();
      note.setFromRequest(rawNote);
      this.messages.push(note);
    }
  }
};
</script>
