import { setCustomDomainFromRequest } from "@/factories/ApprovedDomain";

export default function AdvisorElectronicApplicationSettingsFactory(
  model = {}
) {
  return {
    id: model?.id || 0,
    customDomains: model?.customDomains || [],
    setFromRequest(model = {}) {
      this.id = model?.id;
      this.customDomains = [];

      model?.custom_domains?.forEach(rawDomain => {
        this.customDomains.push(setCustomDomainFromRequest(rawDomain));
      });
    }
  };
}
