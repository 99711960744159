<template>
  <v-card color="section" flat tile>
    <v-card-text>
      <v-row class="ma-0">
        <template v-if="!isGroupOne">
          <v-col cols="12">
            <v-textarea
              :success="Boolean(carrier.details)"
              outlined
              dense
              prepend-inner-icon="$mdi-text"
              label="Notes"
              v-model="carrier.details"
              auto-grow
              @input="updateAttribute('details')"
            >
              <template #append-outer>
                <active-save-indicator :controller="savingBuffer.details" />
              </template>
            </v-textarea>
          </v-col>

          <v-col cols="12">
            <v-select
              :disabled="!isGroupThreePlus"
              :success="Boolean(carrier.addressee)"
              prepend-inner-icon="$mdi-account"
              outlined
              dense
              label="Default Addressee"
              v-model="carrier.addressee"
              @change="updateAttribute('addressee')"
              :items="[
                { text: 'Agent', value: 'Agent' },
                { text: 'General Agent', value: 'General Agent' }
              ]"
            >
              <template #append-outer>
                <active-save-indicator :controller="savingBuffer.addressee" />
              </template>
            </v-select>
          </v-col>
        </template>

        <v-col cols="12" v-if="isGroupThreePlus">
          <h3 class="mb-3">
            <v-row class="ma-0" align="center">
              Quoting States
              <v-btn
                icon
                @click="editableQuotingStates = !editableQuotingStates"
              >
                <v-icon>{{
                  editableQuotingStates ? mdiLock : mdiPencil
                }}</v-icon>
              </v-btn>
              <active-save-indicator :controller="savingBuffer.quotingStates" />
            </v-row>
          </h3>
          <v-chip-group
            column
            multiple
            v-model="carrier.quotingStates"
            @change="updateAttribute('quotingStates')"
          >
            <v-chip
              v-for="state in states"
              :key="state.text"
              :class="{
                primary: inQuotingStates(state.text)
              }"
              :value="state.text"
              :disabled="!editableQuotingStates"
            >
              {{ state.full }}
            </v-chip>
          </v-chip-group>
        </v-col>
        <v-col v-else>
          Approved for quoting in {{ carrier.quotingStates.join(", ") }}
        </v-col>
        <v-col cols="12" lg="3" md="4">
          <div class="checkbox-width">
            <v-checkbox
              :disabled="!isGroupThreePlus"
              v-model="carrier.supportsInstantAps"
              @change="updateAttribute('supportsInstantAps')"
            >
              <template #label>
                Supports Human API
                <active-save-indicator
                  :controller="savingBuffer.supportsInstantAps"
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" lg="3" md="4">
          <div class="checkbox-width">
            <v-checkbox
              :disabled="!isGroupThreePlus"
              v-model="carrier.autoSubmitContracting"
              @change="updateAttribute('autoSubmitContracting')"
            >
              <template #label>
                Auto Submit Contracting
                <active-save-indicator
                  :controller="savingBuffer.autoSubmitContracting"
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" lg="3" md="4">
          <div class="checkbox-width">
            <v-checkbox
              :disabled="!isGroupThreePlus"
              v-model="carrier.holdForAppointment"
              @change="updateAttribute('holdForAppointment')"
            >
              <template #label>
                Hold for Appointment
                <active-save-indicator
                  :controller="savingBuffer.holdForAppointment"
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col v-if="isGroupThreePlus" cols="12" lg="3" md="4">
          <div class="checkbox-width">
            <v-checkbox
              v-model="carrier.emailUnderwriter"
              @change="updateAttribute('emailUnderwriter')"
            >
              <template #label>
                Email Underwriter
                <active-save-indicator
                  :controller="savingBuffer.emailUnderwriter"
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col v-if="isGroupThreePlus" cols="12" lg="3" md="4">
          <div class="checkbox-width">
            <v-checkbox
              v-model="carrier.emailEo"
              @change="updateAttribute('emailEo')"
            >
              <template #label>
                Email E&O
                <active-save-indicator :controller="savingBuffer.emailEo" />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" v-if="!isGroupOne">
          <v-textarea
            :disabled="!isGroupThreePlus"
            prepend-inner-icon="$mdi-subtitles"
            outlined
            dense
            label="Quote & Apply Disclosure"
            v-model="carrier.strifeDisclosure"
            :success="Boolean(carrier.strifeDisclosure)"
            @input="updateAttribute('strifeDisclosure')"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.strifeDisclosure"
              />
            </template>
          </v-textarea>
        </v-col>
        <v-col cols="12" md="6" v-if="!isGroupOne">
          <v-textarea
            :disabled="!isGroupThreePlus"
            :success="Boolean(carrier.eftCommissionSchedule)"
            outlined
            dense
            prepend-inner-icon="$mdi-text"
            :label="`${carrier.name}'s EFT Commission Schedule`"
            v-model="carrier.eftCommissionSchedule"
            auto-grow
            @input="updateAttribute('eftCommissionSchedule')"
          >
            <template #append>
              <active-save-indicator
                :controller="savingBuffer.eftCommissionSchedule"
              />
            </template>
          </v-textarea>
        </v-col>
        <v-col cols="12" md="6" v-if="!isGroupOne">
          <v-textarea
            :disabled="isGroupOne"
            :success="Boolean(carrier.checkCommissionSchedule)"
            outlined
            dense
            prepend-inner-icon="$mdi-text"
            :label="`${carrier.name}'s Check Commission Schedule`"
            v-model="carrier.checkCommissionSchedule"
            auto-grow
            @input="updateAttribute('checkCommissionSchedule')"
          >
            <template #append>
              <active-save-indicator
                :controller="savingBuffer.checkCommissionSchedule"
              />
            </template>
          </v-textarea>
        </v-col>
        <v-col v-if="isGroupThreePlus" cols="12">
          <agent-search
            v-model="carrier.defaultAppointmentManager"
            label="Default Appointment Manager"
            :success="
              Boolean(
                carrier.defaultAppointmentManager &&
                  carrier.defaultAppointmentManager.id
              )
            "
            @input="updateAttribute('defaultAppointmentManager')"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer['defaultAppointmentManager']"
              />
            </template>
          </agent-search>
        </v-col>
        <template v-if="isGroupFour">
          <v-col cols="12">
            <h3>Parameds Integration Settings</h3>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="carrier.paramedsCode"
              data-lpignore="true"
              label="Code"
              outlined
              dense
              :prepend-inner-icon="mdiPound"
              clearable
              maxlength="10"
              :success="Boolean(carrier.paramedsCode)"
              @input="updateAttribute('paramedsCode')"
            >
              <template #append-outer>
                <active-save-indicator
                  :controller="savingBuffer['paramedsCode']"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              data-lpignore="true"
              label="Account Number"
              outlined
              dense
              :prepend-inner-icon="mdiPound"
              clearable
              maxlength="10"
              v-model="carrier.paramedsAccountNumber"
              :success="Boolean(carrier.paramedsAccountNumber)"
              @input="updateAttribute('paramedsAccountNumber')"
            >
              <template #append-outer>
                <active-save-indicator
                  :controller="savingBuffer['paramedsAccountNumber']"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <h3>NAIC</h3>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="carrier.naic"
              data-lpignore="true"
              label="NAIC Code"
              outlined
              dense
              clearable
              maxlength="10"
              :prepend-inner-icon="mdiPound"
              :success="Boolean(carrier.naic)"
              @input="updateAttribute('naic')"
            >
              <template #append-outer>
                <active-save-indicator :controller="savingBuffer['naic']" />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="carrier.amBestRating"
              data-lpignore="true"
              label="AM Best Rating"
              outlined
              dense
              clearable
              maxlength="10"
              :prepend-inner-icon="mdiMedal"
              :success="Boolean(carrier.amBestRating)"
              @input="updateAttribute('amBestRating')"
            >
              <template #append-outer>
                <active-save-indicator
                  :controller="savingBuffer['amBestRating']"
                />
              </template>
            </v-text-field>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import AgentSearch from "@/components/shared/AgentSearch.vue";
import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { states } from "@/data/states";
import { updateCarrier } from "@/api/carrier.service";
import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { mdiMedal, mdiPound, mdiPencil, mdiLock } from "@mdi/js";
export default {
  mixins: [ActiveSaveMixin],
  components: {
    ActiveSaveIndicator,
    AgentSearch
  },
  props: {
    carrier: Object
  },
  data() {
    const savingBuffer = this.generateSavingBuffer({
      items: [
        "naic",
        "addressee",
        "strifeDisclosure",
        "details",
        "quotingStates",
        "supportsInstantAps",
        "autoSubmitContracting",
        "holdForAppointment",
        "emailUnderwriter",
        "emailEo",
        "paramedsAccountNumber",
        "paramedsCode",
        "eftCommissionSchedule",
        "checkCommissionSchedule",
        "defaultAppointmentManager",
        "amBestRating",
        "naic"
      ]
    });
    return {
      savingBuffer,
      states,
      isMounted: false,
      mdiMedal,
      mdiPound,
      mdiPencil,
      mdiLock,
      editableQuotingStates: false
    };
  },
  computed: {
    ...mapState(useUserStore, ["isGroupOne", "isGroupThreePlus", "isGroupFour"])
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  methods: {
    updateAttribute(attribute) {
      if (!this.isMounted) return;
      if (this.isGroupOne) return;
      if (this.savingBuffer[attribute].timer)
        clearTimeout(this.savingBuffer[attribute].timer);
      this.savingBuffer[attribute].timer = setTimeout(() => {
        this.updateAttributeWrapper(
          () =>
            updateCarrier(this.carrier.id, {
              carrier: this.carrier.getAttributeRequestValue(attribute)
            }),
          this.savingBuffer[attribute]
        );
      }, 300);
    },
    inQuotingStates(state) {
      if (!this.carrier.quotingStates) return false;
      return this.carrier.quotingStates.includes(state);
    }
  }
};
</script>
