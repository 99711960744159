<template>
  <v-card color="section" tile flat>
    <v-card-text>
      <v-data-table
        data-testid="appointment-cases-table"
        :headers="table.headers.value"
        :items="table.mappedItems.value"
        class="transparent-data-table"
      >
        <template #[`item.insuredName`]="{ item }">
          <router-link
            class="text-none"
            :to="getRouterLink('Case', item.additional.id)"
          >
            {{ item.insuredName }}
          </router-link>
        </template>
        <template #[`item.fyPremium`]="{ item }">
          <currency-formatter :value="item.fyPremium" />
        </template>
        <template #[`item.created`]="{ item }">
          <timestamp-formatter format="date-time" :value="item.created" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";

import TableHeader from "@/classes/data-table/TableHeader";

import { useTable } from "@/composables/table.composable";
import { storeToRefs } from "pinia";
import { useAppointmentView } from "@/stores/appointment-view";
import { getRouterLink } from "@/util/helpers";
const { cases } = storeToRefs(useAppointmentView());

const sortedAppointmentCases = cases.value.sort(
  (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Insured",
      value: "insuredName",
      map: "insuredName"
    }),
    new TableHeader({
      text: "Policy #",
      value: "policyNumber",
      map: "policyNumber"
    }),
    new TableHeader({
      text: "Case Status",
      value: "status",
      map: "status"
    }),
    new TableHeader({
      text: "FY Premium",
      value: "fyPremium",
      map: "fyPremium"
    }),
    new TableHeader({
      text: "Line",
      value: "line",
      map: "lineText"
    }),
    new TableHeader({
      text: "Created",
      value: "created",
      map: "created"
    })
  ],
  items: sortedAppointmentCases
});
</script>
