<template>
  <v-col v-if="loading" cols="12" class="pa-0">
    <v-skeleton-loader type="card" />
    <v-skeleton-loader type="card" />
  </v-col>
  <v-col v-else cols="12" class="pa-0">
    <contract-party-view-card>
      <contract-party-view-actions />
    </contract-party-view-card>
    <v-card tile flat>
      <v-tabs v-model="activeTab" show-arrows>
        <v-tab
          v-for="tab in tabs"
          class="text-none"
          :key="tab.page"
          :data-testid="`tab-${tab.title}`"
        >
          <v-icon class="pr-1">{{ tab.icon }}</v-icon>
          <v-badge v-if="tab.badgeProps" v-bind="tab.badgeProps">
            {{ tab.title }}
          </v-badge>
          <template v-else>{{ tab.title }}</template>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab" touchless>
        <v-tab-item
          v-for="(tab, index) in tabs"
          :key="tab.page + 'component'"
          :eager="tab.eager"
        >
          <component
            :is="tab.component"
            :active="activeTab === index"
            class="section"
            v-bind="tab.props || {}"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-col>
</template>

<script setup>
import ContractPartyViewCard from "@/components/contract-parties/contract-party-view/ContractPartyViewCard.vue";
import ContractPartyViewActions from "@/components/contract-parties/contract-party-view/ContractPartyViewActions.vue";
import ContractPartyViewAccess from "@/components/contract-parties/contract-party-view/ContractPartyViewAccess.vue";
import ContractPartyViewDetailsIndividual from "@/components/contract-parties/contract-party-view/ContractPartyViewDetailsIndividual.vue";
import ContractPartyViewDetailsEntity from "@/components/contract-parties/contract-party-view/ContractPartyViewDetailsEntity.vue";
import ContractPartyViewCases from "@/components/contract-parties/contract-party-view/ContractPartyViewCases.vue";
import ContractPartyViewChat from "@/components/contract-parties/contract-party-view/ContractPartyViewChat.vue";
import ContractPartyViewToDoList from "@/components/contract-parties/contract-party-view/ContractPartyViewToDoList.vue";

import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";
import { storeToRefs } from "pinia";
import { useHead } from "@unhead/vue";
import {
  mdiChartBar,
  mdiCheck,
  mdiFileDocumentEdit,
  mdiMessage,
  mdiShieldAccount
} from "@mdi/js";
import {
  markRaw,
  onBeforeUnmount,
  defineProps,
  ref,
  watch,
  computed,
  toRef
} from "vue";
import { parseErrorMessage } from "@/util/helpers";
import { useRouter } from "@/composables/compatible.composables";
import { useContractPartyView } from "@/stores/contract-party-view";

const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  page: {
    type: String,
    required: false,
    default: null
  },
  highlightTodo: {
    type: Number,
    required: false,
    default: null
  }
});
const highlightTodo = toRef(props, "highlightTodo");

const contractPartyView = useContractPartyView();
contractPartyView.$reset();
const router = useRouter();
const snackbar = useSnackbarStore();
const instance = useInstanceStore();
const { breadcrumb } = storeToRefs(instance);
const head = useHead({ title: "Client Viewer" });
const { displayName, isIndividual } = storeToRefs(contractPartyView);

const activeTab = ref(0);
const loading = ref(false);

const tabs = computed(() => {
  return [
    {
      icon: mdiMessage,
      title: "Notes",
      page: "notes",
      eager: true,
      component: markRaw(ContractPartyViewChat)
    },
    {
      icon: mdiCheck,
      title: "To-Do",
      page: "to-do",
      component: markRaw(ContractPartyViewToDoList),
      props: {
        highlightTodo: highlightTodo.value
      }
    },
    {
      icon: mdiChartBar,
      title: "Cases",
      page: "cases",
      component: markRaw(ContractPartyViewCases)
    },
    {
      icon: mdiFileDocumentEdit,
      title: "Details",
      page: "details",
      default: true,
      component: markRaw(
        isIndividual.value
          ? ContractPartyViewDetailsIndividual
          : ContractPartyViewDetailsEntity
      )
    },
    {
      icon: mdiShieldAccount,
      title: "Access",
      page: "access",
      component: markRaw(ContractPartyViewAccess)
    }
  ];
});

async function getContractPartyInfo() {
  try {
    loading.value = true;
    await contractPartyView.initialize(props.id);
    breadcrumb.value = displayName.value;
    head.patch({ title: displayName.value });

    const tempPage = tabs.value.findIndex(v => v.page === props.page);
    if (tempPage > -1) activeTab.value = tempPage;
    else activeTab.value = tabs.value.findIndex(v => v.default) || 0;
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: 6000
    });
    router.push({ name: "Home" });
  } finally {
    loading.value = false;
  }
}

function syncQueryToTab() {
  if (!router) return;
  let page = tabs.value[activeTab.value].page;
  if (props.page === page || !page) return;
  router.replace({ query: { page } });
}

getContractPartyInfo();

watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  syncQueryToTab();
});

onBeforeUnmount(() => (breadcrumb.value = ""));
</script>
