<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-data-table
        data-testid="medical-sources-table"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        :items="items"
        :headers="headers"
      >
        <template #[`item.created_at`]="{ item }">
          <timestamp-formatter :value="item.created_at" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import { useEappViewStore } from "@/stores/eapp-view";
import { mapState } from "pinia";
export default {
  components: {
    TimestampFormatter
  },
  data() {
    const headers = [
      { text: "Name", value: "name" },
      { text: "Status", value: "status" },
      { text: "Created", value: "created_at" }
    ];

    return {
      headers
    };
  },
  computed: {
    ...mapState(useEappViewStore, ["insured"]),
    items() {
      return this.insured?.medicalSources;
    }
  },
  methods: {
    changeTab(value) {
      this.activeTab = value;
    }
  }
};
</script>
