<template>
  <v-data-table :items="items" :headers="headers">
    <template #top>
      <v-btn @click="add" class="text-none ma-3" color="accent">
        Add Temporary Insurance Profile
      </v-btn>
    </template>

    <template #[`item.maxBindAgeInDays`]="{ item }">
      <number-formatter :value="item.maxBindAgeInDays / 365" />
    </template>

    <template #[`item.minBindAgeInDays`]="{ item }">
      <number-formatter :value="item.minBindAgeInDays" />
    </template>

    <template #[`item.maxFaceAmount`]="{ item }">
      <currency-formatter :value="item.maxFaceAmount" :decimal-length="0" />
    </template>

    <template #[`item.maxDeathBenefitWithExistingCoverage`]="{ item }">
      <currency-formatter
        :value="item.maxDeathBenefitWithExistingCoverage"
        :decimal-length="0"
      />
    </template>

    <template #[`item.maxDeathBenefit`]="{ item }">
      <currency-formatter :value="item.maxDeathBenefit" :decimal-length="0" />
    </template>

    <template #[`item.maxDeathBenefitToBind`]="{ item }">
      <currency-formatter
        :value="item.maxDeathBenefitToBind"
        :decimal-length="0"
      />
    </template>

    <template #[`item.actions`]="{ item }">
      <v-btn @click="remove(item)" class="text-none" icon>
        <v-icon color="error">$mdi-delete</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import NumberFormatter from "@/components/shared/formatters/NumberFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";

import Table from "@/classes/data-table/Table";
import TableHeader from "@/classes/data-table/TableHeader";

import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";

import { getAllTemporaryInsuranceProfiles } from "@/api/temporary-insurance-profile.service";
import { removeTemporaryInsuranceProfile } from "@/api/products.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

export default {
  props: {
    product: Object
  },
  components: {
    CurrencyFormatter,
    NumberFormatter
  },
  mixins: [GenericTableMixin],
  data() {
    const headers = [
      new TableHeader({
        text: "Carrier",
        value: "carrier",
        map: "name"
      }),
      new TableHeader({
        text: "Days of Coverage",
        value: "daysOfCoverage",
        map: "daysOfCoverage"
      }),
      new TableHeader({
        text: "Max Bind Age (Years)",
        value: "maxBindAge",
        map: "maxBindAgeInDays"
      }),
      new TableHeader({
        text: "Min Bind Age (Days)",
        value: "minBindAge",
        map: "minBindAgeInDays"
      }),
      new TableHeader({
        text: "Max Death Benefit",
        value: "maxDeathBenefit",
        map: "maxFaceAmount"
      }),
      new TableHeader({
        text: "Max Death Benefit w/ Existing Coverage",
        value: "maxDeathBenefitWithExistingCoverage",
        map: "maxFaceAmountIfExistingCoverageFromSameCarrier"
      }),
      new TableHeader({
        text: "Max Death Benefit To Bind",
        value: "maxDeathBenefitToBind",
        map: "maxFaceAmountToBind"
      }),
      new TableHeader({
        text: "Actions",
        value: "actions",
        map: "actions"
      })
    ];

    const table = new Table({
      rawItems: this.product.temporaryInsuranceProfileProducts,
      headers
    });
    return {
      temporaryInsuranceProfileOptions: [],
      table
    };
  },
  computed: {
    headers() {
      return this.table.headers;
    },
    items() {
      return this.tableMap(this.table.rawItems, this.table.headers);
    }
  },
  created() {
    getAllTemporaryInsuranceProfiles(this.product.carrier.name).then(
      response => {
        this.temporaryInsuranceProfileOptions = response;
      }
    );
  },
  methods: {
    ...mapActions(useSnackbarStore, [
      "showSuccessSnackbar",
      "showErrorSnackbar"
    ]),
    ...mapActions(useDialogStore, ["showDialog"]),
    async remove(profile) {
      try {
        await removeTemporaryInsuranceProfile(
          this.product.id,
          profile.additional.id
        );
        const index = this.table.rawItems.findIndex(
          item => item.id === profile.additional.id
        );
        this.table.rawItems.splice(index, 1);

        this.showSuccessSnackbar({
          message: "Product Temporary Insurance Profile has been deleted",
          timeout: 6000
        });
      } catch (e) {
        this.showErrorSnackbar({
          message: parseErrorMessage(e),
          timeout: -1
        });
      }
    },
    async add() {
      const result = await this.showDialog({
        component: "AddTemporaryInsuranceProfileDialog",
        temporaryInsuranceProfiles: this.temporaryInsuranceProfileOptions,
        product: this.product
      });
      if (!result?.id) return;
      this.table.rawItems.push(result);
      this.showSuccessSnackbar({
        message: "Temporary Insurance Profile has been added",
        timeout: 6000
      });
    }
  }
};
</script>
