<template>
  <v-list-item
    link
    :class="{ 'primary white--text': isActive }"
    @click="emit('click')"
  >
    <v-list-item-avatar>
      <v-icon :class="{ 'white--text': isActive }">
        {{ icon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title :class="{ 'white--text': isActive }">
        {{ questionId }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="!readonly">
      <v-btn
        icon
        :color="isActive ? 'error lighten-1' : 'error'"
        @click="emit('delete')"
        @click.native.stop
      >
        <v-icon> $mdi-delete </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
const props = defineProps({
  questionId: String,
  currentFieldId: String,
  pdfFieldType: String,
  readonly: Boolean
});
const emit = defineEmits(["click", "delete"]);

const isActive = computed(() => props.questionId === props.currentFieldId);
const icon = computed(() => {
  const icons = {
    text: "$mdi-text",
    text_output_verbatim_question: "$mdi-export",
    radio: "$mdi-radiobox-marked",
    signature: "$mdi-draw",
    date_signed: "$mdi-calendar",
    initials: "$mdi-signature-freehand",
    not_on_pdf: "$mdi-eye-off"
  };

  const icon = icons[props.pdfFieldType];
  return icon || "$mdi-progress-question";
});
</script>
