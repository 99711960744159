<template>
  <v-row class="section mx-0 py-3">
    <v-col cols="12">
      <v-card class="mb-0" rounded flat>
        <v-card-title>Change Agent</v-card-title>
        <v-card-subtitle>
          {{ agentHint }}
        </v-card-subtitle>
        <v-card-text>
          <v-select
            v-model="owner"
            style="max-width: 35rem"
            prepend-inner-icon="$mdi-account-tie"
            success
            outlined
            dense
            return-object
            item-text="name"
            label="Agent"
            data-testid="accesses-agent"
            :disabled="!allowCommissionChange"
            :items="agentOptions"
            @change="updateOwnerAttribute"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.owner.controller.value"
              />
            </template>
          </v-select>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <electronic-application-commission-assignment />
    </v-col>
    <v-col cols="12">
      <v-card class="mt-0" flat>
        <v-card-title>Add or Remove Accesses</v-card-title>
        <v-card-text>
          <access
            v-model="electronicApplicationAccesses"
            type="e-app"
            data-testid="eapp-access-table"
            button-color="accent"
            button-text="Add Access"
            :checkboxes="checkboxes"
            :delete-func="deleteAccess"
            :add-func="addAccess"
            :update-func="updateAccess"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Access from "@/components/shared/Access.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ElectronicApplicationCommissionAssignment from "@/components/electronic-applications/ElectronicApplicationCommissionAssignment.vue";

import { mapActions, mapState, mapWritableState } from "pinia";

import { useSnackbarStore } from "@/stores/snackbar";
import { useEappViewStore } from "@/stores/eapp-view";
import { useActiveSave } from "@/composables/active-save.composable";
export default {
  components: {
    Access,
    ActiveSaveIndicator,
    ElectronicApplicationCommissionAssignment
  },
  setup() {
    const savingBuffer = {
      owner: useActiveSave()
    };
    return {
      savingBuffer
    };
  },
  data() {
    return {
      checkboxes: [
        {
          text: "Email",
          value: "email"
        },
        {
          text: "Case Access",
          value: "additionalCaseAccess"
        }
      ]
    };
  },
  computed: {
    ...mapState(useEappViewStore, ["allowCommissionChange"]),
    ...mapWritableState(useEappViewStore, [
      "electronicApplicationAccesses",
      "owner"
    ]),
    agentOptions() {
      return this.electronicApplicationAccesses.filter(access =>
        access.ownerType?.toLowerCase()?.includes("agent")
      );
    },
    agentHint() {
      if (this.allowCommissionChange) {
        return "Click the dropdown to change the agent on this eApp. Add the agent as an access if they do not appear in the list.";
      }
      return 'You cannot change the agent because the DocuSign has been generated. Click "Edit eApp" to change the agent.';
    }
  },

  methods: {
    ...mapActions(useEappViewStore, ["updateOwner"]),
    ...mapActions(useSnackbarStore, [
      "showSuccessSnackbar",
      "showErrorSnackbar"
    ]),
    updateOwnerAttribute() {
      this.savingBuffer.owner.update(this.updateOwner);
    },
    ...mapActions(useEappViewStore, [
      "deleteEappAccess",
      "updateEappAccess",
      "createEappAccess"
    ]),
    deleteAccess(access) {
      return this.deleteEappAccess(access.additional.id);
    },
    addAccess(advisor) {
      return this.createEappAccess(advisor);
    },
    updateAccess(access, attribute) {
      return this.updateEappAccess(access.additional.id, attribute);
    },

    nameLinkGenerator(access) {
      return access.additional.routerLink;
    }
  }
};
</script>
