<template>
  <v-data-table
    must-sort
    data-testid="appointments-table"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :server-items-length="table.meta.value.total"
    :loading="table.loading.value"
    :options="table.options.value"
    :footer-props="footerProps"
    @update:options="updateOptions"
  >
    <template #top>
      <v-row class="ma-0 pa-3" align="center">
        <h1 class="text-h5">Appointments</h1>
        <generic-table-stats
          data-testid="appointments-table-stats"
          :stats="stats"
          end
        />
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              data-testid="appointments-table-email"
              icon
              v-on="on"
              v-bind="attrs"
              @click="confirmAndEmailData"
            >
              <v-icon>$mdi-file-export</v-icon>
            </v-btn>
          </template>
          <span>Email Data</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              data-testid="appointments-table-refresh-data"
              icon
              v-on="on"
              v-bind="attrs"
              @click="getData"
            >
              <v-icon>$mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-row>
      <v-divider />
      <generic-table-filter-header
        :header-props="{
          class: 'mt-0 pa-3',
          rounded: false
        }"
        :show-view-filter="user.isGroupTwoPlus"
        :value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
      <v-divider />
    </template>

    <template #[`item.name`]="{ item }">
      <td class="truncate">
        <router-link
          class="text-none"
          :to="{
            name: 'AppointmentView',
            params: { id: item.additional.id }
          }"
        >
          {{ item.name }}
        </router-link>
      </td>
    </template>

    <template #[`item.created`]="{ item }">
      <timestamp-formatter :value="item.created" />
    </template>

    <template #[`item.followUp`]="{ item }">
      <timestamp-formatter :value="item.followUp" parser="sole-day" />
    </template>

    <template #[`item.lastNote`]="{ item }">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <td v-bind="attrs" v-on="on" class="truncate">
            {{ item.lastNote }}
          </td>
        </template>
        <span> {{ item.lastNote }} </span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";
import GenericTableStats from "@/components/shared/data-table/GenericTableStats.vue";

import {
  lineFilterOptions,
  pendingAppointmentStatuses,
  inactiveAppointmentStatuses,
  activeAppointmentStatuses
} from "@/data/filter-options";
import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";
import { parseErrorMessage } from "@/util/helpers";
import { getAppointments, emailAppointments } from "@/api/appointment.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { storeToRefs } from "pinia";
import { useTableStore } from "@/stores/table";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { computed } from "vue";

const GENERAL_APPOINTMENT_STATUES = [
  { text: "Entered", value: "entered" },
  { text: "Pending", value: "pending" },
  { text: "Active", value: "active" },
  { text: "Inactive", value: "inactive" }
];
useHead({ title: "Appointments" });

const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const { appointmentsTable } = storeToRefs(useTableStore());

const table = useTable({
  headers: getTableHeaders(),
  getData: getAppointments,
  emailData: emailAppointments,
  shouldIncludeCancelToken: true
});

if (user.isGroupTwoPlus) {
  table.filter.value.view = "my";
}
if (appointmentsTable.value.filter) {
  Object.keys(appointmentsTable.value.filter).forEach(key => {
    if (!table.headers.value.find(h => h.value === key)) return;
    table.filter.value[key] = appointmentsTable.value.filter[key];
  });
}

if (Object.keys(appointmentsTable.value.options).length) {
  table.options.value = appointmentsTable.value.options;
} else {
  table.options.value = new TableOptions(["followUp"], [true]);
}

const stats = computed(() => {
  const stats = [];
  const score = { text: "Score", icon: "$mdi-counter" };
  const statsObj = table.stats.value;
  if (!statsObj) return [{ ...score, type: "loading" }];

  Object.keys(statsObj).forEach(stat => {
    if (stat.indexOf("score") > -1) {
      stats.push({ ...score, value: statsObj[stat], type: "percent" });
      return;
    }
  });
  return stats;
});

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(table.options.value, table.meta.value),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

function getTableHeaders() {
  const VIEW_OPTIONS = user.isGroupTwoPlus
    ? [
        { text: "All Appointments", value: "all" },
        { text: "GA Appointments", value: "ga" },
        { text: "My Appointments", value: "my" }
      ]
    : [];
  const headers = [
    new TableHeader({
      text: "Code",
      value: "name",
      map: "name",
      sortFilterMap: "appointments.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "carrierName",
      sortFilterMap: [{ key: "carrier_id", value: "id" }],
      sortKey: "carrier",
      order: 4,
      ...TableHeader.IS_CARRIER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      sortFilterMap: "general_status",
      order: 2,
      selectableOptions: GENERAL_APPOINTMENT_STATUES,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Advisor",
      value: "advisor",
      map: "ownableName",
      sortable: false,
      sortFilterMap: [
        { key: "advisor_id", value: "id" },
        { key: "advisor_type", value: "type" }
      ],
      order: 3,
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Line",
      value: "line",
      map: "linesOfAuthority",
      selectableOptions: lineFilterOptions,
      sortFilterMap: "lines_of_authority",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Created",
      value: "created",
      map: "createdAt",
      sortFilterMap: "appointments.created_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Follow Up",
      value: "followUp",
      map: "followUpDate",
      sortFilterMap: "follow_up_date",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Last Note",
      value: "lastNote",
      map: "lastNote"
    }),
    new TableHeader({
      text: "Shared With",
      value: "additionalAdvisor",
      map: "additional_advisor",
      sortFilterMap: [
        { key: "shared_with_id", value: "id" },
        { key: "shared_with_type", value: "type" }
      ],
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Specific Status",
      value: "specificStatus",
      map: "specificStatus",
      selectableOptionsFn: specificStatusFunc,
      sortFilterMap: "specific_status",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_MULTI_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL
    })
  ];

  if (user.isGroupTwoPlus) {
    headers.unshift(
      new TableHeader({
        text: "View",
        value: "view",
        map: "view",
        selectableOptions: VIEW_OPTIONS,
        sortFilterMap: "view",
        order: 1,
        ...TableHeader.IS_ADDITIONAL,
        ...TableHeader.IS_QUERY_FILTER,
        ...TableHeader.IS_SELECT_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_MANDATORY
      })
    );
    headers.push(
      new TableHeader({
        text: "Comp Builder",
        value: "compBuilderName",
        map: "compBuilderName",
        sortFilterMap: "comp_builder_name",
        ...TableHeader.IS_QUERY_FILTER,
        ...TableHeader.IS_STRING_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_ADDITIONAL
      })
    );
  }
  return headers;
}

function specificStatusFunc(filters) {
  const dict = {
    entered: [],
    active: activeAppointmentStatuses,
    pending: pendingAppointmentStatuses,
    inactive: inactiveAppointmentStatuses
  };

  return dict[filters.status];
}
function confirmAndEmailData() {
  dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Email Data?",
    subtitle: "Please confirm you would like this data emailed to you.",
    func: emailData
  });
}
async function emailData() {
  try {
    const additionalFilter = {};
    if (!user.isGroupTwoPlus) additionalFilter.view = "my";
    table.emailData(additionalFilter);
    snackbar.showSuccessSnackbar({
      message: `You will receive a copy of this data in your inbox shortly.`,
      timeout: 6000
    });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(error),
      timeout: -1
    });
  }
}

async function getData() {
  const additionalFilter = {};
  if (!user.isGroupTwoPlus) additionalFilter.view = "my";
  try {
    await table.getData(additionalFilter);
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateOptions(newOptions) {
  if (table.optionsEquivalence(newOptions)) return;
  table.options.value = newOptions;
  appointmentsTable.value.options = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  appointmentsTable.value.filter = filter;
  table.resetPage();
  getData();
}

getData();
</script>
