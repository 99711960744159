<template>
  <v-expansion-panels accordion>
    <v-expansion-panel>
      <v-expansion-panel-header>Employees</v-expansion-panel-header>
      <v-expansion-panel-content>
        <employees-table />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>Org Chart</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card>
          <v-img :src="orgChart" />
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import EmployeesTable from "@/components/employees/EmployeesTable.vue";
const orgChart = require("@/assets/img/org-chart.png");
</script>
