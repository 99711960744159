<template>
  <v-card tile flat v-if="hasActions">
    <v-card-text>
      <v-row class="mx-3">
        <v-spacer />
        <v-btn
          v-if="displaySendContractingStartedEmailButton"
          color="accent"
          class="text-none mr-1"
          outlined
          rounded
          data-testid="contracting-started-button"
          :disabled="isSubmitted"
          :loading="sendingContractingStartedEmail"
          @click="sendContractingStartedEmail"
        >
          <template v-if="isSubmitted">
            <v-icon class="mr-1">$mdi-mailbox </v-icon>
            Contracting Started Email Sent
          </template>
          <template v-else>
            <v-icon class="mr-1"> $mdi-email-send </v-icon> Send Contracting
            Started Email
          </template>
        </v-btn>
        <v-btn
          v-if="isGroupTwoPlus"
          outlined
          color="error"
          rounded
          class="text-none"
          data-testid="delete-appointment"
          @click="destroyAppointmentConfirmation"
        >
          <v-icon class="mr-1"> $mdi-delete </v-icon>
          Delete Appointment
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useAppointmentView } from "@/stores/appointment-view";

import { computed, ref } from "vue";
import { useRouter } from "@/composables/compatible.composables";

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const appointment = useAppointmentView();
const { isGroupTwoPlus } = storeToRefs(useUserStore());

const { status, processWorkerNotTriggeredReason, submitted } =
  storeToRefs(appointment);

const sendingContractingStartedEmail = ref(false);

const displaySendContractingStartedEmailButton = computed(
  () =>
    isGroupTwoPlus.value &&
    status.value === "Entered" &&
    processWorkerNotTriggeredReason.value !== ""
);

const isSubmitted = computed(() => submitted.value === 1);
const hasActions = computed(
  () => displaySendContractingStartedEmailButton.value || isGroupTwoPlus.value
);

async function sendContractingStartedEmail() {
  if (sendingContractingStartedEmail.value || submitted.value === 1) return;
  sendingContractingStartedEmail.value = true;

  try {
    await appointment.sendContractingStartedEmail();
    submitted.value = 1;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    sendingContractingStartedEmail.value = false;
  }
}
function destroyAppointmentConfirmation() {
  return dialog.showDialog({
    component: "ConfirmationDialog",
    func: destroyAppointment,
    title: "Are you sure that you want to delete this appointment?",
    subtitle: "This cannot be undone"
  });
}

const router = useRouter();

async function destroyAppointment() {
  try {
    await appointment.destroyAppointment();
    snackbar.showSuccessSnackbar({
      message: "Successfully Deleted Appointment",
      timeout: -1
    });
    if (router) router.push({ name: "AppointmentsTable" });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: `Unable to delete appointment. ${parseErrorMessage(error)}`,
      timeout: -1,
      color: "error"
    });
  }
}
</script>
