<template>
  <v-card>
    <v-card-title> Create Rate Band </v-card-title>
    <v-card-text>
      <currency-input
        include-decimals
        label="Min"
        v-model="rateBand.min"
        outlined
        dense
        :success="minValidation.success"
        :error-messages="minValidation.errorMessages"
      />
      <currency-input
        include-decimals
        label="Max"
        v-model="rateBand.max"
        outlined
        dense
        :success="maxValidation.success"
        :error-messages="maxValidation.errorMessages"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="text-none"
        @click="closeDialog()"
        outlined
        :disabled="loading"
      >
        Cancel
      </v-btn>
      <v-btn
        v-if="editing"
        class="text-none"
        color="primary"
        :loading="loading"
        @click="saveBand"
      >
        Save
      </v-btn>
      <v-btn
        v-else
        class="text-none"
        color="primary"
        :loading="loading"
        @click="createBand"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

import { createRateBand, updateRateBand } from "@/api/rate-bands.service";
import { RateBand } from "@/factories/ProductFactory";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  components: {
    CurrencyInput
  },
  props: {
    $rateBand: {
      required: false,
      type: Object,
      default: () => {}
    }
  },
  data() {
    const rateBand = new RateBand(this.$rateBand);
    const editing = Boolean(rateBand.id);
    return {
      rateBand,
      editing,
      loading: false
    };
  },
  computed: {
    minValidation() {
      const success = !this.$v.rateBand.min.$invalid;
      const errorMessages = [];
      if (!this.$v.rateBand.min.$dirty) {
        return { success, errorMessages };
      } else if (!this.$v.rateBand.min.atLeastZero) {
        errorMessages.push("Must be at least 0");
      }
      return { success, errorMessages };
    },
    maxValidation() {
      const success = !this.$v.rateBand.max.$invalid;
      const errorMessages = [];
      if (!this.$v.rateBand.max.$dirty) {
        return { success, errorMessages };
      } else if (!this.$v.rateBand.max.greaterThanMin) {
        errorMessages.push(`Must be greater than ${this.rateBand.min}`);
      }
      return { success, errorMessages };
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    async saveBand() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      try {
        const updatedBand = await updateRateBand(this.rateBand);
        this.closeDialog(updatedBand);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    },
    async createBand() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      try {
        const newRateBand = await createRateBand(this.rateBand);
        this.closeDialog(newRateBand);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    }
  },
  validations: {
    rateBand: {
      min: {
        atLeastZero: v => v >= 0
      },
      max: {
        greaterThanMin: (v, vm) => v >= vm.min
      }
    }
  }
};
</script>
