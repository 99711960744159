<template>
  <v-card>
    <v-card-text>
      <v-data-table
        must-sort
        data-testid="products-table"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :server-items-length="table.meta.value.total"
        :loading="table.loading.value"
        :options="table.options.value"
        :footer-props="footerProps"
        @update:options="updateOptions"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5">Products</h1>
            <v-spacer />
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  data-testid="products-table-refresh"
                  @click="getData"
                >
                  <v-icon>$mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
          <generic-table-filter-header
            :value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>
        <template #[`item.firstYear`]="{ item }">
          <percentage-formatter
            :value="item.firstYear"
            :decimal-length="null"
          />
        </template>
        <template #[`item.renewal`]="{ item }">
          <percentage-formatter :value="item.renewal" :decimal-length="null" />
        </template>
        <template #[`item.excess`]="{ item }">
          <percentage-formatter :value="item.excess" :decimal-length="null" />
        </template>
        <template #[`item.product`]="{ item }">
          <router-link
            class="text-none"
            :class="{ 'accent--text': item.additional.qaEnabled }"
            :to="{
              name: 'ProductView',
              params: {
                id: item.additional.id
              }
            }"
          >
            <v-icon v-if="item.additional.qaEnabled" color="accent">
              $mdi-rocket-launch
            </v-icon>
            {{ item.product }}
          </router-link>
        </template>
      </v-data-table>
      <v-icon color="accent"> $mdi-rocket-launch </v-icon> = Quote & Apply
      Product
    </v-card-text>
  </v-card>
</template>

<script setup>
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import { LINES } from "@/factories/ProductFactory";

import { parseErrorMessage } from "@/util/helpers";
import { getCommissions } from "@/api/commissions.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { computed } from "vue";
import { PERMISSION } from "@/constants/permissions.constants";

useHead({ title: "Products" });

const table = useTable({
  headers: [
    new TableHeader({
      text: "Product",
      value: "product",
      map: "name",
      sortFilterMap: "products.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrierName",
      map: "carrierName",
      sortFilterMap: "carriers.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "First Year Up To Target",
      value: "firstYear",
      map: "firstYear"
    }),
    new TableHeader({
      text: "Renewal",
      value: "renewal",
      map: "renewal",
      showIf: ({ line }) =>
        ["disability", "life", "ltc", "variable_life"].includes(line)
    }),
    new TableHeader({
      text: "Excess of Target",
      value: "excess",
      map: "excess",
      showIf: ({ line }) => ["variable_life", "life"].includes(line)
    }),
    new TableHeader({
      text: "Line",
      value: "line",
      map: "line",
      sortFilterMap: "line",
      selectableOptions: LINES,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_MANDATORY,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Quote & Apply Product",
      value: "qaEnabled",
      sortFilterMap: "qa_enabled",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_CHECKBOX_TYPE,
      ...TableHeader.IS_ADDITIONAL
    })
  ],
  getData: getCommissions,
  options: new TableOptions(["carrierName", "product"], [false, false])
});

const filter = {
  line: "life"
};

const user = useUserStore();
if (user.permissions[PERMISSION.ACCOUNTING]) {
  table.headers.value.push(
    new TableHeader({
      text: "Primary Product",
      value: "primaryProduct",
      sortFilterMap: "primary_product",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_CHECKBOX_TYPE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.SEND_FALSE
    })
  );
  filter.primaryProduct = true;
}

table.filter.value = filter;

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(table.options.value, table.meta.value),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateOptions(newOptions) {
  if (table.optionsEquivalence(newOptions)) return;
  table.options.value = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}

getData();
</script>
