<template>
  <div>
    <v-row align="start" class="ma-0">
      <v-col class="pa-0">
        <v-card outlined class="pa-3">
          <v-list-item-title data-testid="message-title">
            <v-row class="ma-0" align="center">
              <v-list-item-avatar style="align-self: start" class="ma-0">
                <v-img
                  data-testid="avatar-url"
                  :data-src="message.ownable.avatarUrl"
                  :src="message.ownable.avatarUrl"
                />
              </v-list-item-avatar>
              <div class="mx-2">
                {{ message.ownable.name }}
              </div>
              <div
                v-if="message.inbound && message.inbound.via"
                class="text-caption grey--text font-weight-medium"
              >
                via {{ message.inbound.via }}
              </div>
              <timestamp-formatter
                class="text-caption grey--text font-weight-medium"
                format="date-time"
                :value="message.created_at || message.createdAt"
              />
              <chat-message-email-indicator
                v-if="message.email || message.carrierEmail"
                :full-email="message.fullEmail"
                :message-id="message.id"
              />
              <template v-if="deletable">
                <v-spacer />
                <v-btn
                  x-small
                  icon
                  data-testid="delete-message"
                  @click="deleteMessage"
                >
                  <v-icon color="error" small>
                    {{ mdiDelete }}
                  </v-icon>
                </v-btn>
              </template>
            </v-row>
          </v-list-item-title>
          <div class="mx-n3 my-3">
            <v-divider />
          </div>
          <v-list-item-subtitle
            v-if="message.safe_html"
            data-testid="message-text"
          >
            <div class="wrap-text" v-html="sanitize(message.note)"></div>
          </v-list-item-subtitle>
          <v-list-item-subtitle
            class="wrap-text"
            v-else
            data-testid="message-text"
          >
            <include-links-in-text :text="message.note" />
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <a
              v-for="(document, index) in message.documents"
              class="ma-1"
              :data-testid="`message-document-${index}`"
              :key="document.uid"
              v-bind="download(document.uid)"
            >
              {{ document.name }}
              <span class="grey--text">({{ document.fileSize }})</span>
              <v-icon color="primary"> {{ mdiDownload }} </v-icon>
            </a>
          </v-list-item-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import IncludeLinksInText from "@/components/shared/IncludeLinksInText.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import ChatMessageEmailIndicator from "@/components/shared/chat/ChatMessageEmailIndicator.vue";
import { downloadFileAsLink } from "@/util/helpers";
import sanitize from "@/html-sanitizer";
import { getDocumentUrl } from "@/api/documents.service";
import { defineProps, defineEmits } from "vue";
import { useVuetify } from "@/composables/compatible.composables";

import { mdiDelete, mdiDownload } from "@mdi/js";
import { useUserStore } from "@/stores/user";

const emit = defineEmits(["delete-message"]);
const props = defineProps({
  message: {
    type: Object,
    required: true
  }
});
const user = useUserStore();
const vuetify = useVuetify();

function download(uid) {
  return downloadFileAsLink(getDocumentUrl(uid), vuetify.breakpoint.mdAndDown);
}

function deleteMessage() {
  emit("delete-message", props.message);
}

function isDeletable() {
  if (!props.message.id) return false;
  const sameId = props.message.ownable.id === user.loginable.id;
  const sameType = props.message.ownable.type === user.loginable.type;
  if (sameId && sameType) return true;
  return user.isGroupTwoPlus;
}

const deletable = isDeletable();
</script>
