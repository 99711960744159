<template>
  <v-row dense align="start" justify="center" class="ma-0">
    <v-col
      cols="12"
      :md="vertical ? 12 : 6"
      :order="editorOrder"
      style="height: 100%; width: 100%"
      :class="{ 'pl-0': !vertical, 'px-0': vertical }"
    >
      <template v-if="leftTabs.length > 1">
        <v-tabs
          background-color="section"
          :color="Boolean(isCopiedForm) ? 'accent' : 'primary'"
          show-arrows
        >
          <v-tab v-for="tab in leftTabs" :key="tab.title" class="text-none">
            {{ tab.title }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab" touchless>
          <v-tab-item v-for="tab in leftTabs" :key="tab.slot">
            <slot :name="tab.slot" />
          </v-tab-item>
        </v-tabs-items>
      </template>
      <slot v-else :name="leftTabs[0].slot" />
    </v-col>
    <v-col
      :order="detailsOrder"
      cols="12"
      :md="vertical ? 12 : 6"
      :style="{ height: vertical ? null : '100%' }"
      style="width: 100%"
      :class="{ 'pr-0': !vertical, 'px-0': vertical }"
    >
      <slot name="right" />
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    hasQuestions: Boolean,
    isCopiedForm: Boolean,
    vertical: Boolean
  },
  data: () => ({ activeTab: 0 }),
  computed: {
    leftTabs() {
      const tabs = [{ title: "Question Editor", slot: "editor" }];
      if (this.$slots["questions-not-on-pdf"]) {
        tabs.push({ title: "Not on PDF", slot: "questions-not-on-pdf" });
      }

      return tabs;
    },
    editorOrder() {
      return this.vertical ? 2 : 1;
    },
    detailsOrder() {
      return this.vertical ? 1 : 2;
    }
  }
};
</script>
