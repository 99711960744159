import { setCommissionSplitFromRequest } from "@/factories/CommissionSplitFactory";
import { categoryToConstantProduct } from "@/util/helpers";

export const toHtmlSnippet = domainKey => {
  return (
    `<div id="container-id"></div><script id="strife" src="https://cdn.quoteandapply.io/widget.js" data-strife-key="${domainKey}" data-strife-container-id="container-id"></` +
    "script>"
  );
};

export const COLOR_DEFAULTS = {
  LIGHT: {
    PRIMARY: "#1871c9",
    ACCENT: "#28a746"
  },
  DARK: {
    PRIMARY: "#4188d9",
    ACCENT: "#28a746"
  }
};

export const INTEGRATIONS = {
  ZOHO_CHAT: "Zoho SalesIQ",
  COBROWSE_IO: "Cobrowse.io",
  ZENDESK_CLASSIC: "Zendesk Classic"
};

export default function ApprovedDomain(model = {}) {
  return {
    accesses: model?.accesses || [],
    accidentalDeathDefault: model?.accidentalDeathDefault || null,
    activeFirstInput: model?.activeFirstInput || null,
    appointmentAssignable: model?.appointmentAssignable || null,
    appointmentAssignableLock: model?.appointmentAssignableLock || null,
    archived: model?.archived || false,
    assignable: model?.assignable || null,
    assignableLock: model?.assignableLock || null,
    avatar: model?.avatar || null,
    billable: model?.billable || null,
    captureEmail: model?.captureEmail || null,
    capturePhone: model?.capturePhone || null,
    carriers: model?.carriers || null,
    chat: model?.chat || false,
    colors: model?.colors || {
      light: {
        primary: COLOR_DEFAULTS.LIGHT.PRIMARY,
        accent: COLOR_DEFAULTS.LIGHT.ACCENT
      },
      dark: {
        primary: COLOR_DEFAULTS.DARK.PRIMARY,
        accent: COLOR_DEFAULTS.DARK.ACCENT
      }
    },
    commissionSplits: model?.commissionSplits || [],
    customDomain: model?.customDomain || {},
    customSchedulingLink: model?.customSchedulingLink || "",
    defaultMode: model?.defaultMode || null,
    disclosure: model?.disclosure || null,
    domain: model?.domain || null,
    domainKey: model?.domainKey || null,
    emailAgent: model?.emailAgent || null,
    embedLocation: model?.embedLocation || null,
    finalExpenseDefault: model?.finalExpenseDefault || null,
    googleAnalyticsId: model?.googleAnalyticsId || null,
    googleTagManagerId: model?.googleTagManagerId || null,
    greeting: model?.greeting || null,
    heroImageUrl: model?.heroImageUrl || null,
    hideAdditionalServices: model?.hideAdditionalServices || false,
    hideFromSearchEngines: model?.hideFromSearchEngines || null,
    hideSelectRoundRobinAgent: model?.hideSelectRoundRobinAgent || false,
    hideTestModeBanner: model?.hideTestModeBanner || false,
    id: model?.id || null,
    informationCapture: model?.informationCapture || null,
    introEmail: model?.introEmail || null,
    integrations: {
      zohoChat: ApprovedDomainIntegration({
        name: INTEGRATIONS.ZOHO_CHAT,
        ...model?.integrations?.zohoChat
      }),
      cobrowseIo: ApprovedDomainIntegration({
        name: INTEGRATIONS.COBROWSE_IO,
        ...model?.integrations?.cobrowseIo
      }),
      zendeskClassic: ApprovedDomainIntegration({
        name: INTEGRATIONS.ZENDESK_CLASSIC,
        ...model?.integrations?.zendeskClassic
      })
    },
    limitExam: model?.limitExam || null,
    marketAsAgency: model?.marketAsAgency || null,
    marketAsLock: model?.marketAsLock || null,
    marketingAs: model?.marketingAs || null,
    menuOptions: model?.menuOptions || null,
    overrideCommissionSplits: model?.overrideCommissionSplits || false,
    overridePhone: model?.overridePhone || true,
    overrideState: model?.overrideState || null,
    ownable: model?.ownable || null,
    permanentDefault: model?.permanentDefault || null,
    phoneNumber: model?.phoneNumber || null,
    pricingPlan: model?.pricingPlan || null,
    pricingPlanOptions: model?.pricingPlanOptions || [],
    productTypes: model?.productTypes || null,
    products: model?.products || [],
    referText: model?.referText || null,
    referToDomain: model?.referToDomain || {},
    sendReferText: model?.sendReferText || false,
    skipRoundRobinLicenseCheck: model?.skipRoundRobinLicenseCheck || false,
    startPage: model?.startPage || null,
    statistics: model?.statistics || null,
    test: model?.test || null,
    theme: model?.theme,
    useOwnersAvatar: model?.useOwnersAvatar || false,
    useOwnersSchedulingLink: model?.useOwnersSchedulingLink || false,
    questions: model?.questions || []
  };
}

export function setApprovedDomainFromRequest(model = {}) {
  const approvedDomain = ApprovedDomain();
  approvedDomain.domain = model.domain;
  approvedDomain.avatar = model.avatar_url;
  approvedDomain.id = model.id;
  approvedDomain.domainKey = model?.domain_key;
  approvedDomain.overridePhone = model?.override_phone;
  approvedDomain.limitExam = model?.limit_exam;
  approvedDomain.useOwnersSchedulingLink = model?.use_owners_scheduling_link;
  approvedDomain.customSchedulingLink = model?.scheduling_link;
  approvedDomain.archived = model?.archived;

  approvedDomain.embedLocation = model?.embed_location;
  approvedDomain.pricingPlanOptions = model.pricing_plan_options;
  approvedDomain.theme = model?.theme;
  approvedDomain.defaultMode = model?.default_mode;
  approvedDomain.useOwnersAvatar = model.use_owners_avatar;
  approvedDomain.greeting = model.greeting;
  approvedDomain.introEmail = model.client_email_text;
  approvedDomain.disclosure = model.disclosure;
  approvedDomain.marketingAs = model.market_as_to;
  approvedDomain.chat = model?.chat;
  approvedDomain.marketAsLock = model.market_as_lock;
  approvedDomain.informationCapture = model.information_capture;
  approvedDomain.phoneNumber = model.phone;
  approvedDomain.marketAsAgency = model.market_as_agency;
  approvedDomain.emailAgent = model.email;
  approvedDomain.accidentalDeathDefault =
    model.default_slider_values.accidental_death;
  approvedDomain.finalExpenseDefault = model.default_slider_values.fex;
  approvedDomain.permanentDefault = model.default_slider_values.permanent;
  approvedDomain.googleAnalyticsId = model.google_analytics_id;
  approvedDomain.googleTagManagerId = model.google_tag_manager_id;
  approvedDomain.startPage = model.start_page;
  approvedDomain.menuOptions = model.menu_options;
  approvedDomain.overrideState = model.override_state;
  approvedDomain.test = model.test;
  approvedDomain.hideTestModeBanner = model?.hide_test_mode_banner;
  approvedDomain.activeFirstInput = model.active_first_input;
  approvedDomain.assignable = model.assign_to;
  approvedDomain.assignableLock = model.assignable_lock;
  approvedDomain.appointmentAssignable = model.appointment_assign_to;
  approvedDomain.appointmentAssignableLock = model.appointment_assignment_lock;
  approvedDomain.pricingPlan = model.pricing_plan_id;
  approvedDomain.billable = model.bill_to;
  approvedDomain.hideFromSearchEngines = model.disallow_crawlers;
  approvedDomain.hideSelectRoundRobinAgent =
    model.hide_select_round_robin_agent;
  approvedDomain.overrideCommissionSplits = model?.override_commission_splits;
  approvedDomain.referToDomain = model?.refer_to_approved_domain;
  approvedDomain.heroImageUrl = model?.hero_image_url;
  approvedDomain.hideAdditionalServices = model?.hide_additional_services;

  approvedDomain.referText = model?.refer_text;
  approvedDomain.sendReferText = Boolean(model?.send_refer_text);

  approvedDomain.productTypes = Object.keys(model.product_types).filter(
    key => model.product_types[key]
  );
  approvedDomain.captureEmail = [
    "Require Email",
    "Prompt and Require"
  ].includes(model.email_capture);
  approvedDomain.capturePhone = [
    "Require Phone",
    "Prompt and Require"
  ].includes(model.email_capture);

  approvedDomain.ownable = setApprovedDomainOwnableFromRequest(model.ownable);

  approvedDomain.skipRoundRobinLicenseCheck = Boolean(
    model.skip_round_robin_license_check
  );
  approvedDomain.statistics = {
    weekly: new ApprovedDomainStatistics({
      text: "Weekly",
      applicationCount: model.weekly_application_count,
      leadCount: model.weekly_lead_count,
      viewCount: model.weekly_view_count
    }),
    monthly: new ApprovedDomainStatistics({
      text: "Monthly",
      applicationCount: model.monthly_application_count,
      leadCount: model.monthly_lead_count,
      viewCount: model.monthly_view_count
    }),
    all: new ApprovedDomainStatistics({
      text: "All",
      applicationCount: model.application_count,
      leadCount: model.lead_count,
      viewCount: model.view_count
    })
  };

  approvedDomain.carriers = [];
  model?.carriers?.map(rawCarrier => {
    const carrier = setApprovedDomainCarrierFromRequest(rawCarrier);
    approvedDomain.carriers.push(carrier);
  });

  approvedDomain.accesses = [];
  model?.approved_domain_accesses?.map(rawAccess => {
    const access = setApprovedDomainAccessFromRequest(rawAccess);
    approvedDomain.accesses.push(access);
  });

  approvedDomain.commissionSplits = [];
  model?.commission_splits?.forEach(rawSplit => {
    const split = setCommissionSplitFromRequest(rawSplit);
    approvedDomain.commissionSplits.push(split);
  });

  approvedDomain.questions = [];
  model?.approved_domain_questions?.forEach(raw => {
    const question = setQuestionFromRequest(raw);
    if (question) approvedDomain.questions.push(question);
  });

  approvedDomain.customDomain = setCustomDomainFromRequest(
    model?.custom_domain
  );

  if (model?.color_primary) {
    approvedDomain.colors.light.primary = model.color_primary;
  }
  if (model?.color_accent) {
    approvedDomain.colors.light.accent = model.color_accent;
  }
  if (model?.color_dark_primary) {
    approvedDomain.colors.dark.primary = model.color_dark_primary;
  }
  if (model?.color_dark_accent) {
    approvedDomain.colors.dark.accent = model.color_dark_accent;
  }

  approvedDomain.products = [];
  if (model?.products) {
    model.products.forEach(p => {
      approvedDomain.products.push(setApprovedDomainProductFromRequest(p));
    });
    approvedDomain.products.sort((a, b) => a.name.localeCompare(b.name));
  }

  if (Array.isArray(model?.approved_domain_integrations)) {
    const savedIntegrations = model.approved_domain_integrations.reduce(
      (acc, value) => {
        acc[value.name] = value;
        return acc;
      },
      {}
    );

    const availableIntegrations = [
      ["zohoChat", INTEGRATIONS.ZOHO_CHAT],
      ["cobrowseIo", INTEGRATIONS.COBROWSE_IO],
      ["zendeskClassic", INTEGRATIONS.ZENDESK_CLASSIC]
    ];

    availableIntegrations.forEach(([key, name]) => {
      const integration = savedIntegrations[name];
      if (!integration) return;
      approvedDomain.integrations[key] =
        setApprovedDomainIntegrationFromRaw(integration);
      approvedDomain.integrations[key].name = name;
    });
  }

  return approvedDomain;
}

export function ApprovedDomainTableItem(model = {}) {
  return {
    advisorName: model?.advisorName || "",
    advisorType: model?.advisorType || "",
    advisorId: model?.advisorId || 0,
    avatarUrl: model?.avatarUrl || "",
    customDomainId: model?.customDomainId || 0,
    default: model?.default || false,
    domain: model?.domain || "",
    domainKey: model?.domainKey || "",
    marketingAsName: model?.marketingAsName || "",
    id: model?.id || 0,
    appointmentAssignmentOwnableName:
      model?.appointmentAssignmentOwnableName || "",
    appointmentAssignmentOwnableType:
      model?.appointmentAssignmentOwnableType || "",
    appointmentAssignmentOwnableId: model?.appointmentAssignmentOwnableId || 0,
    scheme: model?.scheme || "",
    startPage: model?.startPage || "",
    get htmlSnippet() {
      return toHtmlSnippet(this.domainKey);
    },
    setFromRequest(model = {}) {
      this.advisorName = model?.advisor?.name;
      this.advisorType = model?.advisor?.type;
      this.advisorId = model?.advisor?.id;

      this.avatarUrl = model?.avatar_url;
      this.customDomainId = model?.custom_domain_id;
      this.default = model?.default;
      this.domain = model?.domain;
      this.domainKey = model?.domain_key;
      this.id = model?.id;

      this.appointmentAssignmentOwnableName =
        model?.appointment_assignment_ownable?.name;
      this.appointmentAssignmentOwnableType =
        model?.appointment_assignment_ownable?.type;
      this.appointmentAssignmentOwnableId =
        model?.appointment_assignment_ownable?.id;

      this.marketingAsName = model?.marketing_as?.name;
      this.scheme = model?.scheme;
      this.startPage = model?.start_page;
    }
  };
}

function CustomDomain(model = {}) {
  return {
    id: model?.id || null,
    domain: model?.domain || null
  };
}

export function setCustomDomainFromRequest(model = {}) {
  const customDomain = CustomDomain();
  customDomain.id = model?.id || null;
  customDomain.domain = model?.domain || null;
  return customDomain;
}

function ApprovedDomainOwnable(model = {}) {
  return {
    id: model?.id || null,
    insuranceLicenses: model?.insuranceLicenses || null,
    name: model?.name || null,
    typeLink: model?.typeLink || null,
    typeModel: model?.typeModel || null,
    lockCommissions: model?.lockCommissions || false
  };
}

function setApprovedDomainOwnableFromRequest(model = {}) {
  const ownable = ApprovedDomainOwnable();
  ownable.id = model.id || null;
  ownable.insuranceLicenses = model.insurance_licenses || null;
  ownable.name = model.name || null;
  ownable.typeLink = model.type.link || null;
  ownable.typeModel = model.type.model || null;
  ownable.lockCommissions = model?.commission_lock || false;
  return ownable;
}

function ApprovedDomainCarrier(model = {}) {
  return {
    text: model?.text || null,
    avatar: model?.avatar || null,
    value: model?.value || null,
    id: model?.id || null,
    parentId: model?.parentId || null,
    carrierId: model?.carrierId || null
  };
}

function setApprovedDomainCarrierFromRequest(model = {}) {
  const carrier = ApprovedDomainCarrier();
  carrier.text = model.name || null;
  carrier.avatar = model.avatar_url || null;
  carrier.value = Boolean(model.enabled);
  carrier.id = model.id || null;
  carrier.parentId = model.parent_carrier?.id;
  return carrier;
}

function ApprovedDomainStatistics(model = {}) {
  return {
    text: model?.text || null,
    applicationCount: model?.applicationCount || 0,
    leadCount: model?.leadCount || 0,
    viewCount: model?.viewCount || 0
  };
}

function GradientLifeTheme() {
  return {
    id: "gradient-life",
    name: "Gradient Life",
    img: require("@/assets/img/domain-themes/gradient-life.jpg")
  };
}

function LifeEssentialsTheme() {
  return {
    id: "life-essentials",
    name: "Life Essentials",
    img: require("@/assets/img/domain-themes/life-essentials.jpg")
  };
}

function LTCRiderTheme() {
  return {
    id: "ltc-rider",
    name: "LTC Rider",
    img: require("@/assets/img/domain-themes/ltc-rider.jpg")
  };
}

function BasicTheme() {
  return {
    id: null,
    name: "Basic",
    img: require("@/assets/img/domain-themes/Default.jpg")
  };
}

export const APPROVED_DOMAIN_THEMES = {
  BASIC: BasicTheme,
  GRADIENT_LIFE: GradientLifeTheme,
  LIFE_ESSENTIALS: LifeEssentialsTheme,
  LTC: LTCRiderTheme
};

function ApprovedDomainAccess(model = {}) {
  return {
    id: model?.id || 0,
    ownableId: model?.ownableId || null,
    name: model?.name || null,
    email: model?.email || true,
    roundRobin: model?.roundRobin || false,
    additionalCaseAccess: model?.additionalCaseAccess || true,
    type: model?.type || null,
    link: model?.link || null
  };
}

export function setApprovedDomainAccessFromRequest(model = {}) {
  const access = ApprovedDomainAccess();
  access.id = model.id || 0;
  access.advisor = model.advisor;
  access.name = model.advisor_name;
  access.email = model.email === false ? false : true;
  access.roundRobin = model.round_robin || false;
  access.additionalCaseAccess =
    model.additional_case_access === false ? false : true;
  access.type = model?.ownable_type;
  access.ownableId = model?.ownable_id;
  return access;
}

function ApprovedDomainProduct(model = {}) {
  return {
    id: model?.id || 0,
    name: model?.name || "",
    categories: model?.categories || [],
    carrierName: model?.carrierName || "",
    carrierAvatar: model?.carrierAvatar || ""
  };
}

function setApprovedDomainProductFromRequest(model = {}) {
  const product = ApprovedDomainProduct();
  product.id = model?.id;
  product.name = model?.name;
  product.categories = categoryToConstantProduct(model?.category);
  product.carrierName = model?.carrier?.name;
  return product;
}

export function ApprovedDomainSearchItem(model = {}) {
  return {
    archivedAt: model?.archivedAt || null,
    default: model?.default || null,
    domain: model?.domain || null,
    id: model?.id || null
  };
}

export function setApprovedDomainSearchItemFromRequest(raw = {}) {
  const item = ApprovedDomainSearchItem();

  item.archivedAt = raw?.archived_at;
  item.default = Boolean(raw?.default);
  item.domain = raw?.domain;
  item.id = raw?.id;

  return item;
}

export const APPROVED_DOMAIN_QUESTION_TYPES = {
  SELECT: "select",
  TEXT: "text"
};

export function TextQuestion(model = {}) {
  return {
    get icon() {
      return "$mdi-form-textbox";
    },
    get inputType() {
      return APPROVED_DOMAIN_QUESTION_TYPES.TEXT;
    },
    id: model?.id || null,
    question: model?.question || "",
    required: Boolean(model?.required)
  };
}

function setTextQuestionFromRequest(raw = {}) {
  const q = TextQuestion();
  q.id = raw.id;
  q.question = raw.question;
  q.required = Boolean(raw.required);
  return q;
}

export function SelectQuestion(model = {}) {
  let selectOptions = [];
  if (model?.selectOptions) {
    selectOptions = model.selectOptions.map(SelectOption);
  }

  return {
    get icon() {
      return "$mdi-form-select";
    },
    get inputType() {
      return APPROVED_DOMAIN_QUESTION_TYPES.SELECT;
    },
    id: model?.id || null,
    question: model?.question || "",
    required: Boolean(model?.required),
    selectOptions
  };
}

export function SelectOption(model = {}) {
  return {
    text: model?.text || "",
    value: model?.value || "",
    key: model?.key || Symbol()
  };
}

function setSelectOptionFromRequest(model = {}) {
  const option = SelectOption();
  option.text = model.text;
  option.value = model.value;
  return option;
}

function setSelectQuestionFromRequest(raw = {}) {
  const q = SelectQuestion();
  q.id = raw.id;
  q.question = raw.question;
  q.required = Boolean(raw.required);
  q.selectOptions = raw.select_options.map(setSelectOptionFromRequest);
  return q;
}

export function setQuestionFromRequest(raw = {}) {
  switch (raw?.input_type) {
    case APPROVED_DOMAIN_QUESTION_TYPES.SELECT:
      return setSelectQuestionFromRequest(raw);
    case APPROVED_DOMAIN_QUESTION_TYPES.TEXT:
      return setTextQuestionFromRequest(raw);
  }
}

// Helper function to return the full data type where needed
export function ApprovedDomainQuestion(question) {
  switch (question?.inputType) {
    case APPROVED_DOMAIN_QUESTION_TYPES.SELECT:
      return SelectQuestion(question);
    case APPROVED_DOMAIN_QUESTION_TYPES.TEXT:
      return TextQuestion(question);
  }
}

export function setRawFromApprovedDomainQuestion(question) {
  switch (question.inputType) {
    case APPROVED_DOMAIN_QUESTION_TYPES.SELECT:
      return setRawFromSelectQuestion(question);
    case APPROVED_DOMAIN_QUESTION_TYPES.TEXT:
      return setRawFromTextQuestion(question);
  }
}

function setRawFromSelectQuestion(question) {
  return {
    input_type: APPROVED_DOMAIN_QUESTION_TYPES.SELECT,
    question: question.question,
    required: question.required,
    select_options: question.selectOptions.map(({ text, value }) => ({
      text,
      value
    }))
  };
}

function setRawFromTextQuestion(question) {
  return {
    input_type: APPROVED_DOMAIN_QUESTION_TYPES.TEXT,
    question: question.question,
    required: question.required
  };
}

export function ApprovedDomainIntegration(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || null,
    createdAt: model?.createdAt || null,
    enabled: model?.enabled === false ? false : true,
    clientId: model?.clientId || null,
    phone: model?.phone || null
  };
}

export function setApprovedDomainIntegrationFromRaw(raw = {}) {
  const integration = ApprovedDomainIntegration();
  if (!raw?.id) return integration;
  integration.id = raw.id;
  integration.name = raw.name;
  integration.createdAt = raw.created_at;
  integration.enabled = raw.enabled;
  integration.clientId = raw.client_id;
  integration.phone = raw.phone;
  return integration;
}
