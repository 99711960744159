<template>
  <v-col class="mb-10">
    <v-row dense>
      <v-col cols="12" class="pb-0">
        <product-card v-if="product" :product="product" />
        <v-skeleton-loader type="card" v-else />
      </v-col>
      <v-col cols="12" class="pt-0">
        <component
          v-if="product && tabs.length === 1"
          no-padding
          :is="tabs[0].component"
          :product="product"
          :id="id"
          :readonly="!isGroupFour"
        />
        <v-card v-else-if="product" flat tile class="pt-6">
          <v-tabs v-model="activeTab" show-arrows class="no-bottom-radius">
            <v-tab
              v-for="tab in tabs"
              :key="'header' + tab.text"
              class="text-none"
            >
              <v-icon class="pr-1"> {{ tab.icon }} </v-icon>
              {{ tab.text }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab" touchless>
            <v-tab-item v-for="tab in tabs" :key="'body' + tab.text">
              <product-details
                v-if="tab.component === 'ProductCreate'"
                v-model="product"
                editing
                :key="'body' + tab.text"
              />
              <component
                v-else
                :is="tab.component"
                :product="product"
                :id="id"
                :readonly="!isGroupFour"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <v-skeleton-loader v-else type="card" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import ProductCard from "@/components/products/ProductCard.vue";
import ProductCommissionDetails from "@/components/products/ProductCommissionDetails.vue";
import ProductBuildCharts from "@/components/products/ProductBuildCharts.vue";
import ProductSmokerCharts from "@/components/products/ProductSmokerCharts.vue";
import ProductTemporaryInsuranceProfiles from "@/components/products/ProductTemporaryInsuranceProfiles.vue";
import ProductCitizenshipProfiles from "@/components/products/ProductCitizenshipProfiles.vue";
import ProductVersions from "@/components/products/ProductVersions.vue";
import ProductDetails from "@/components/products/ProductDetails.vue";
import ProductApi from "@/components/products/ProductApi.vue";
import ProductMygaRates from "@/components/products/ProductMygaRates.vue";

import { productFind } from "@/api/products.service";

import { mapWritableState, mapState, mapActions } from "pinia";
import { useUserStore } from "@/stores/user";
import { useInstanceStore } from "@/stores/instance";
import { useHead } from "@unhead/vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  props: {
    id: [Number, String]
  },
  components: {
    ProductCard,
    ProductCommissionDetails,
    ProductBuildCharts,
    ProductSmokerCharts,
    ProductVersions,
    ProductCitizenshipProfiles,
    ProductTemporaryInsuranceProfiles,
    ProductDetails,
    ProductApi,
    ProductMygaRates
  },
  data() {
    return {
      product: null,
      activeTab: 0
    };
  },
  created() {
    this.getProduct();
  },
  destroyed() {
    this.breadcrumb = "";
  },
  computed: {
    ...mapWritableState(useInstanceStore, ["breadcrumb"]),
    ...mapState(useUserStore, ["isGroupFour"]),
    isAnnuity() {
      return this.product?.line === "annuity";
    },
    showTemporaryInsuranceProfile() {
      return ["linked_benefit", "life", "variable_life"].includes(
        this.product?.line
      );
    },
    isMyga() {
      return this.product.planType === "MYGA";
    },
    tabs() {
      const tabs = [];
      if (this.isGroupFour) {
        tabs.push({
          icon: "$mdi-file-document-edit",
          text: "Product Details",
          component: "ProductCreate"
        });
      }

      tabs.push({
        icon: "$mdi-google-circles-extended",
        text: "Commission Details",
        component: "ProductCommissionDetails"
      });

      if (this.isMyga) {
        tabs.push({
          icon: "$mdi-chart-areaspline",
          text: "MYGA Rates",
          component: "ProductMygaRates"
        });
      }
      if (this.isGroupFour && !this.isAnnuity) {
        tabs.push({
          icon: "$mdi-hand-saw",
          text: "Builds",
          component: "ProductBuildCharts"
        });
      }
      if (this.isGroupFour) {
        tabs.push({
          icon: "$mdi-smoking",
          text: "Smoker Charts",
          component: "ProductSmokerCharts"
        });
      }
      if (this.showTemporaryInsuranceProfile && this.isGroupFour) {
        tabs.push({
          icon: "$mdi-exclamation",
          text: "Temporary Insurance Profiles",
          component: "ProductTemporaryInsuranceProfiles"
        });
      }
      if (this.isGroupFour && !this.isAnnuity) {
        tabs.push({
          icon: "$mdi-flag",
          text: "Citizenship Profiles",
          component: "ProductCitizenshipProfiles"
        });
      }
      if (this.isGroupFour) {
        tabs.push({
          icon: "$mdi-ab-testing",
          text: "Versions",
          component: "ProductVersions"
        });
        tabs.push({
          icon: "$mdi-book-alphabet",
          text: "API",
          component: "ProductApi"
        });
      }

      return tabs;
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    getProduct() {
      productFind(this.id)
        .then(product => {
          this.product = product;
          this.breadcrumb = product.name;
          useHead({ title: product.name });
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
          this.$router.replace({ name: "Home" });
        });
    }
  }
};
</script>
