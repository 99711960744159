<template>
  <v-list-item :to="goal.to" :href="href" :target="href ? '_blank' : null">
    <v-list-item-title class="pl-0"> {{ goal.text }} </v-list-item-title>
    <v-list-item-action>
      <v-icon :color="goal.completed ? 'primary' : null" :aria-label="a11yText">
        {{ goal.completed ? mdiCheckboxMarked : mdiCheckboxBlankOutline }}
      </v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script setup>
import { defineProps } from "pinia";
import { useUserStore } from "@/stores/user";
import { computed } from "vue";
import { mdiCheckboxMarked, mdiCheckboxBlankOutline } from "@mdi/js";

const props = defineProps({
  goal: {
    type: Object,
    required: true
  }
});

const user = useUserStore();
const href = computed(() => {
  let href = props.goal.href;
  if (typeof props.goal.href === "function") {
    href = props.goal.href(user.loginable);
  }
  return href;
});

const a11yText = computed(() => {
  return `${props.goal.text} ${
    props.goal.completed ? "is complete" : "is incomplete"
  }`;
});
</script>
