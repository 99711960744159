<template>
  <v-card>
    <v-card-title> {{ title }} </v-card-title>
    <v-card-subtitle> When should you follow up? </v-card-subtitle>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <date-input
            v-model="date"
            label="Date"
            data-testid="edit-follow-up-date"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="time"
            outlined
            dense
            label="Time"
            :menu-props="{ offsetY: true, auto: true }"
            :items="availableTimes"
            data-testid="edit-follow-up-time"
          />
        </v-col>
        <v-col cols="12" md="6">
          <div class="checkbox-width">
            <v-checkbox
              v-model="followUpIsScheduled"
              outlined
              dense
              label="Appointment set?"
              hint="Mark this if the appointment is scheduled"
              persistent-hint
              data-testid="is-appointment"
              :menu-props="{ offsetY: true, auto: true }"
              :items="availableTimes"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        outlined
        class="text-none"
        data-testid="cancel-button"
        @click="dialog.closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        @click="save"
        :loading="saving"
        data-testid="edit-follow-up-save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import format from "date-fns/format";
import roundToNearestMinutes from "date-fns/roundToNearestMinutes";
import parse from "date-fns/parse";

import DateInput from "@/components/shared/DateInput.vue";
import { updateEapp } from "@/api/electronic-application.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { defineProps, ref } from "vue";

const availableTimes = [];
for (let i = 0; i < 25; i++) {
  const hour = (i % 12) + 1;
  const ampm = i < 11 ? "AM" : "PM";
  availableTimes.push(`${hour}:00${ampm}`);
  availableTimes.push(`${hour}:15${ampm}`);
  availableTimes.push(`${hour}:30${ampm}`);
  availableTimes.push(`${hour}:45${ampm}`);
}

const props = defineProps({
  eAppId: {
    type: Number,
    required: true
  },
  title: {
    type: String,
    default: "Lead Contacted!"
  },
  date: {
    type: Date,
    default: null
  },
  followUpIsScheduled: Boolean
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const saving = ref(false);
const followUpIsScheduled = ref(props.followUpIsScheduled);

let initialDate;
if (props.date) {
  initialDate = new Date(props.date);
} else {
  initialDate = new Date().setDate(new Date().getDate() + 2);
}

const roundedDate = roundToNearestMinutes(initialDate, { nearestTo: 15 });
const date = ref(format(roundedDate, "yyyy-MM-dd"));
const time = ref(format(roundedDate, "h:mma"));

async function save() {
  saving.value = true;
  const followUp = parse(
    `${date.value} ${time.value}`,
    "yyyy-MM-dd h:mma",
    new Date()
  );

  const followUpStr = followUp.toISOString();

  try {
    const leadStatus = "Active";
    const body = {
      follow_up_at: followUpStr,
      follow_up_at_is_appointment: followUpIsScheduled.value
    };
    if (!props.value) body.lead_status = "Active";
    await updateEapp(props.eAppId, body);
    dialog.closeDialog({
      id: props.eAppId,
      leadStatus,
      followUp: followUpStr,
      followUpIsScheduled: followUpIsScheduled.value
    });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}
</script>
