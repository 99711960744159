import Rollbar from "rollbar";

const rollbar = new Rollbar({
  accessToken: "4381bc80f34843768eb417fdd1b62b54",
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.NODE_ENV === "production",
  source_map_enabled: true,
  environment: process.env.NODE_ENV,
  verbose: process.env.NODE_ENV !== "production",
  payload: {
    client: {
      javascript: {
        code_version: "1.0",
        source_map_enabled: true,
        environment: process.env.NODE_ENV
      }
    }
  }
});

export function setActiveRollbarUser({
  id = null,
  username = null,
  email = null
} = {}) {
  if (!rollbar?.configure) return;
  rollbar.configure({
    payload: {
      person: {
        id,
        username,
        email
      }
    }
  });
}

export function logError(message) {
  if (rollbar.error) rollbar.error(message);
  return console.error(message);
}

export function logWarning(message) {
  if (rollbar.warning) rollbar.warning(message);
  else console.warn(message);
}

export function logInfo(message) {
  if (rollbar.info) rollbar.info(message);
  else console.info(message);
}
