import { getHttpClient } from "@/http-client";
const baseUrl = "api/boss/temporary_insurance_profiles";
export async function getAllTemporaryInsuranceProfiles(name) {
  const params = new URLSearchParams();
  params.append("carrier_name", name);
  const { data } = await getHttpClient().get(`${baseUrl}/all`, {
    params
  });
  return data.temporary_insurance_profiles;
}
