export default function PayorFactory(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || null,
    payableType: model?.payableType || null,
    avatar: model?.avatar || null,
    setFromRequest(model = {}) {
      this.id = model?.id;
      this.name = model?.name;
      this.payableType = model?.payable_type;
      this.avatar = model?.avatar_url;
    }
  };
}
