<template>
  <v-card>
    <v-card-title> Change Email </v-card-title>
    <v-divider class="mb-3" />

    <v-card-text>
      <basic-email-input
        v-model="email"
        label="Email"
        required
        dense
        autofocus
        data-testid="change-email-input"
        :clearable="clearable"
        :success="Boolean(email) && emailIsValid !== false"
        :error-messages="emailIsValid === false ? ['Invalid Email'] : []"
        @valid="emailIsValid = $event"
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn class="text-none" outlined @click="closeDialog"> Cancel </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        :loading="loading"
        data-testid="change-email-save"
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

export default {
  components: {
    BasicEmailInput
  },
  props: {
    value: {
      type: String,
      required: true
    },
    storeFunc: {
      type: Function,
      required: true
    },
    saveFunc: {
      type: Function,
      required: true
    },
    clearable: Boolean
  },
  data() {
    return {
      email: this.value,
      loading: false,
      emailIsValid: null
    };
  },
  methods: {
    ...mapActions(useSnackbarStore, [
      "showErrorSnackbar",
      "showSuccessSnackbar"
    ]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    async save() {
      if (this.email === this.value) {
        this.showSuccessSnackbar({
          message: "Email is unchanged",
          timeout: 10000
        });
        this.closeDialog({ email: this.email });
        return;
      }
      if ((!this.email && !this.clearable) || !this.emailIsValid) return;
      this.loading = true;
      try {
        //gross...
        this.storeFunc(this.email);
        const res = this.saveFunc();
        this.storeFunc(this.value);
        const saveRes = await res;
        this.storeFunc(this.email);
        if (saveRes?.data?.message) {
          this.showSuccessSnackbar({
            message: saveRes.data.message,
            timeout: 10000
          });
        }
        this.closeDialog({ email: this.email });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
