<template>
  <v-card flat>
    <v-card-title>Access</v-card-title>
    <v-card-text>
      <access
        v-model="accesses"
        even-add-access-padding
        data-testid="access-table"
        type="approved-domain"
        button-color="accent"
        button-text="Add Access"
        :checkboxes="checkboxes"
        :update-func="updateAccess"
        :delete-func="deleteAccess"
        :add-func="addAccess"
        :name-link="nameLinkGenerator"
      />
    </v-card-text>
    <v-col class="pt-0">
      <p class="mb-0 text-caption grey--text">
        1. Email will email the advisor when notes are made on the Electronic
        Application.
      </p>
      <p class="mb-0 text-caption grey--text">
        2. Add to Case will add the advisor to the case once the Electronic
        Application is complete.
      </p>
      <p class="mb-0 text-caption grey--text">
        3. Round Robin will randomly add one advisor to the Electronic
        Application and make them the agent.
        <a @click="showRoundRobinArticle"> Read about Round Robin. </a>
      </p>
    </v-col>
  </v-card>
</template>

<script>
import Access from "@/components/shared/Access.vue";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { ARTICLES } from "@/data/articles";
import { mapActions, mapWritableState } from "pinia";
import { useDialogStore } from "@/stores/dialog";
const isAgent = val => {
  return val?.additional?.type === "Agent";
};
export default {
  components: {
    Access
  },
  data() {
    const checkboxes = [
      { text: "Email¹", value: "email" },
      { text: "Additional Case Access²", value: "additionalCaseAccess" },
      { text: "Round Robin³", value: "roundRobin", if: isAgent }
    ];
    return { checkboxes };
  },
  computed: {
    ...mapWritableState(useApprovedDomainView, ["accesses"])
  },
  methods: {
    ...mapActions(useApprovedDomainView, [
      "deleteApprovedDomainAccess",
      "updateApprovedDomainAccess",
      "createApprovedDomainAccess"
    ]),
    ...mapActions(useDialogStore, ["showDialog"]),
    async deleteAccess(access) {
      return this.deleteApprovedDomainAccess(access.additional.id);
    },
    async addAccess(advisor) {
      return this.createApprovedDomainAccess(advisor);
    },
    updateAccess(access, attribute) {
      return this.updateApprovedDomainAccess(access.additional.id, attribute);
    },

    nameLinkGenerator(access) {
      return {
        name: access.additional.type === "Agency" ? "AgencyView" : "AgentView",
        params: {
          id: access.additional.ownableId
        }
      };
    },
    showRoundRobinArticle() {
      this.showDialog({
        component: "HelpCenterReader",
        id: ARTICLES.ROUND_ROBIN
      });
    }
  }
};
</script>
