import { getHttpClient } from "@/http-client";

const baseUrl = "api/boss/requirements";
export async function getRequirements(params, cancelToken) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });
  if (!response) return;

  const { requirements, meta } = response.data;

  const stats = {
    score: meta?.score
  };

  const items = requirements.map(i => ({
    ...i,
    itemKey: `${params.get("type")}-${i.id}`
  }));

  return { items, meta, stats };
}
