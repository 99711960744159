<template>
  <v-card flat tile>
    <v-card-title> Security & Applications </v-card-title>
    <v-card-subtitle>
      Manage your Password, Multi-Factor Authentication (MFA), and Application
      Access.
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <security-boss-access />
        </v-col>
        <v-col cols="12">
          <security-sso />
        </v-col>
        <v-col cols="12">
          <security-application-access />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <slot />
    </v-card-actions>
  </v-card>
</template>

<script setup>
import SecurityBossAccess from "@/components/settings/security/SecurityBossAccess.vue";
import SecuritySso from "@/components/settings/security/SecuritySso.vue";
import SecurityApplicationAccess from "@/components/settings/security/SecurityApplicationAccess.vue";

import { defineEmits, onMounted } from "vue";
const emit = defineEmits(["mounted"]);
onMounted(() => emit("mounted"));
</script>
