<template>
  <v-col :cols="cols" :xl="xl" :lg="lg" :md="md" :sm="sm" :xs="xs">
    <v-sheet :color="item.highlight ? 'section' : null" rounded class="pa-1">
      <span class="grey--text"> {{ item.subtitle }} </span>
      <br />
      <template v-if="titleIsArray">
        <span v-for="(item, index) in item.title" :key="index">
          <template v-if="item.type === 'currency'">
            <currency-formatter :value="item.title" />
          </template>
          <template v-else-if="item.type === 'parseSoleDay'">
            <timestamp-formatter :value="item.title" parser="sole-day" />
          </template>
          <template v-else-if="item.link">
            <router-link :to="item.link"> {{ item.title }} </router-link>
          </template>
          <template v-else> {{ item.title }} </template>
          <br />
        </span>
      </template>
      <span v-else>
        <template v-if="item.type === 'currency'">
          <currency-formatter :value="item.title" />
        </template>
        <template v-else-if="item.type === 'parseSoleDay'">
          <timestamp-formatter :value="item.title" parser="sole-day" />
        </template>
        <template v-else-if="item.link">
          <router-link :to="item.link"> {{ item.title }} </router-link>
        </template>
        <template v-else> {{ item.title }} </template>
      </span>
    </v-sheet>
  </v-col>
</template>

<script>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
export default {
  name: "TransactionCaseItem",
  components: {
    CurrencyFormatter,
    TimestampFormatter
  },
  props: {
    highlight: Boolean,
    item: Object,
    xl: {
      default: 6
    },
    lg: {
      default: 12
    },
    md: {
      default: 4
    },
    sm: {
      default: 6
    },
    xs: {
      default: 12
    },
    cols: {
      default: 12
    }
  },
  computed: {
    titleIsArray() {
      return Array.isArray(this.item.title);
    }
  }
};
</script>
