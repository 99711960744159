<template>
  <v-card color="section" flat tile>
    <v-card-title>
      Comp Builders
      <v-spacer />
      <v-btn @click="addCompBuilder" class="text-none" color="accent">
        <v-icon>$mdi-plus</v-icon> Add Comp Builder
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        show-expand
        item-key="additional.id"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
      >
        <template #[`item.street`]="{ item }">
          <v-icon v-if="item.street" color="success">
            {{ mdiCheckCircle }}
          </v-icon>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="py-4">
            <v-row dense>
              <v-col cols="12" md="4">
                <v-card height="100%" outlined>
                  <v-card-title> Comp Builder Details </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <comp-builder-form v-model="item.additional" class="my-2" />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="8">
                <v-card outlined>
                  <carrier-comp-builder-notes
                    :key="item.additional.id + 'notes'"
                    :comp-builder="item.additional"
                  />
                </v-card>
              </v-col>
            </v-row>
            <v-row class="ma-0 mt-3">
              <v-spacer />
              <v-btn
                outlined
                color="error"
                class="text-none"
                @click="destroyCompBuilder(item.additional.id)"
              >
                <v-icon>$mdi-delete</v-icon>
                Delete Comp Builder
              </v-btn>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import CompBuilderForm from "@/components/carriers/CompBuilderForm.vue";
import CarrierCompBuilderNotes from "@/components/carriers/CarrierCompBuilderNotes.vue";
import { deleteCompBuilder } from "@/api/comp-builders.service";
import { useDialogStore } from "@/stores/dialog";
import { toRef, defineProps } from "vue";
import { useTable } from "@/composables/table.composable";
import TableHeader from "@/classes/data-table/TableHeader";
import { mdiCheckCircle } from "@mdi/js";

const props = defineProps({
  carrier: {
    type: Object,
    required: true
  }
});

const carrier = toRef(props, "carrier");

const table = useTable({
  items: carrier.value.compBuilders,
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name"
    }),
    new TableHeader({
      text: "Street",
      value: "street",
      map: "street"
    })
  ]
});

const dialog = useDialogStore();
function addCompBuilder() {
  dialog
    .showDialog({
      component: "CarrierCompBuilderCreate",
      carrier: { id: carrier.value.id, name: carrier.value.name }
    })
    .then(({ compBuilder }) => {
      if (!compBuilder) return;
      carrier.value.compBuilders.push(compBuilder);
    });
}
function destroyCompBuilder(id) {
  dialog
    .showDialog({
      component: "DeleteDialog",
      func: () => deleteCompBuilder(id),
      title: "Are you sure that you want to delete this CompBuilder?",
      subtitle: "This cannot be undone"
    })
    .then(res => {
      if (!res.delete) return;
      const index = carrier.value.compBuilders.findIndex(val => val.id === id);
      carrier.value.compBuilders.splice(index, 1);
    });
}
</script>
