import { currencyFormat, numberFormat } from "@/util/helpers";

const displayFormatter = (v, isCurrency) =>
  isCurrency ? currencyFormat(v) : numberFormat(v);
const numberFilters = [
  {
    text: "Greater than or equal to",
    value: ">=",
    displayMap: (min, _, isCurrency) =>
      `Greater than or equal to ${displayFormatter(min, isCurrency)}`
  },
  {
    text: "Less than or equal to",
    value: "<=",
    displayMap: (_, max, isCurrency) =>
      `Less than or equal to ${displayFormatter(max, isCurrency)}`
  },
  {
    text: "Exactly equal to",
    value: "=",
    displayMap: (min, max, isCurrency) =>
      `Exactly equal to ${displayFormatter(min, isCurrency)}`
  },
  {
    text: "Custom Range",
    value: "range",
    displayMap: (min, max, isCurrency) =>
      `${displayFormatter(min, isCurrency)} - ${displayFormatter(
        max,
        isCurrency
      )}`
  }
];

const isNumberFilterType = type => numberFilters.some(f => f.value === type);

export { numberFilters, isNumberFilterType };
