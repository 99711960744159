import { getHttpClient } from "@/http-client";

const baseUrl = "api/boss/product_citizenship_profiles";
export async function addProductCitizenshipProfile(params) {
  const { data } = await getHttpClient().post(baseUrl, params);
  return data.product_citizenship_profile.id;
}
export function removeProductCitizenshipProfile(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}
