<template>
  <v-col class="pa-0 ma-0">
    <v-row class="pa-3" dense>
      <v-col :md="editing ? 3 : 12" cols="12">
        <form-categories-search
          v-model="form.category"
          data-testid="form-category"
          label="Category"
          :readonly="readonly"
          :success="categoryValidation.success"
          :error-messages="categoryValidation.errorMessages"
        >
          <template v-if="editing" #append-outer>
            <active-save-indicator
              :controller="savingBuffer.category.controller.value"
            />
          </template>
        </form-categories-search>
      </v-col>
      <v-col v-if="requiresCarrier" :md="editing ? 3 : 6" cols="12">
        <carrier-search
          v-model="form.carrier"
          data-testid="form-carrier"
          :disabled="
            readonly || Boolean(props.formId) || Boolean(form.advisor?.id)
          "
          :success="carrierValidation.success"
          :error-messages="carrierValidation.errorMessages"
        />
      </v-col>
      <v-col v-if="requiresAdvisor" :md="editing ? 3 : 6" cols="12">
        <advisor-search
          v-model="form.advisor"
          data-testid="form-advisor"
          :clearable="!props.formId"
          :disabled="readonly || Boolean(form.carrier?.id)"
          :success="advisorValidation.success"
          :error-messages="advisorValidation.errorMessages"
          @input="updateFormAdvisor"
        />
      </v-col>
      <v-col v-if="editing" cols="12">
        <v-text-field
          v-model.lazy="form.name"
          placeholder="Leave blank to default to the file name"
          data-lpignore="true"
          data-testid="form-name"
          outlined
          dense
          class="mt-1"
          label="Form name"
          :disabled="readonly"
          :success="Boolean(form.name)"
        >
          <template v-if="editing" #append-outer>
            <active-save-indicator
              :controller="savingBuffer.name.controller.value"
            />
          </template>
        </v-text-field>
      </v-col>
      <v-col v-if="!readonly" cols="12">
        <file-drag-and-drop
          v-model="form.form"
          data-testid="form-document"
          :success="fileValidation.success"
          :error-messages="fileValidation.errorMessages"
          accept=".pdf"
          class="has-append-outer-button-no-top"
        >
          <template #append-outer v-if="editing">
            <v-btn
              color="primary"
              class="text-none"
              style="height: 60px"
              data-testid="upload-form"
              :loading="uploadingForm"
              @click="handleFormUpload"
            >
              Upload New Version
            </v-btn>
          </template>
        </file-drag-and-drop>
      </v-col>

      <v-col v-if="requiresSubmissionMethod" cols="12">
        <form-submission-methods-search
          v-model="form.submissionMethod"
          data-testid="form-submission-method"
          label="Submission Method"
          outlined
          :disabled="readonly"
          :success="submissionMethodValidation.success"
          :error-messages="submissionMethodValidation.errorMessages"
          dense
        >
          <template v-if="editing" #append-outer>
            <active-save-indicator
              :controller="savingBuffer.submissionMethod.controller.value"
            />
          </template>
        </form-submission-methods-search>
      </v-col>
      <v-col v-if="requiresFormSets" cols="12">
        <v-row class="ma-0" align="center">
          <v-autocomplete
            label="Form Sets"
            data-testid="form-sets"
            outlined
            dense
            return-object
            :key="formSetAutocompleteKey"
            :items="allFormSets"
            :item-text="val => `${val.id} · ${val.name}`"
            item-value="id"
            :error-messages="formSetValidation.errorMessages"
            @input="addFormSet"
          />
        </v-row>
        <template v-if="form.formSets.length > 0">
          <h3 class="my-3">Active Form Sets</h3>
          <v-list :key="form.formSets.length">
            <v-list-item
              class="px-0"
              v-for="formSet in form.formSets"
              :key="formSet.id"
              data-testid="form-set"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn
                    icon
                    :loading="formSet.deleting"
                    color="error"
                    class="mr-3"
                    @click="deleteFormSet(formSet)"
                  >
                    <v-icon>{{ mdiDelete }} </v-icon>
                  </v-btn>
                  {{ formSet.id }} · {{ formSet.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-col>
    </v-row>
  </v-col>
</template>

<script setup>
import { parseErrorMessage, computedValidation } from "@/util/helpers";
import { useFormMappingStore } from "@/stores/form-mapping";
import { mdiDelete } from "@mdi/js";

import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import FormCategoriesSearch from "@/components/shared/FormCategoriesSearch.vue";
import FormSubmissionMethodsSearch from "@/components/shared/FormSubmissionMethodsSearch.vue";

import { VIRTUAL_FORM_CATEGORY_VALUE } from "@/api/forms.service";
import { getAllFormSets } from "@/api/form-sets.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useActiveSave } from "@/composables/active-save.composable";
import {
  ref,
  set,
  watch,
  defineProps,
  defineEmits,
  nextTick,
  computed
} from "vue";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  editing: Boolean,
  readonly: Boolean,
  isContracting: Boolean,
  advisor: { type: Object, required: false, default: null },
  name: { type: String, required: false, default: null },
  category: { type: String, required: false, default: null },
  carrier: { type: Object, required: false, default: null },
  formSets: { type: Array, required: false, default: null },
  submissionMethod: String,
  validationScope: {
    type: String,
    required: false,
    default: null
  },
  formId: [Number, String]
});

const emit = defineEmits([
  "update:category",
  "update:carrier",
  "update:advisor",
  "update:is-contracting",
  "update:form-sets",
  "update:name",
  "update:submission-method"
]);

const snackbar = useSnackbarStore();

const savingBuffer = {
  category: useActiveSave(),
  carrier: useActiveSave(),
  advisor: useActiveSave(),
  name: useActiveSave(),
  isContracting: useActiveSave(),
  submissionMethod: useActiveSave()
};

const form = ref({
  category: props.category || null,
  carrier: props.carrier || null,
  advisor: props.advisor || null,
  isContracting: props.isContracting || false,
  form: props.form || null,
  formSets: props.formSets || [],
  name: props.name || null,
  submissionMethod: props.submissionMethod || null
});

const allFormSets = ref([]);
const addingNewFormSet = ref(false);
const uploadingForm = ref(false);

const requiresSubmissionMethod = computed(() =>
  Boolean(
    form.value.carrier?.id ||
      form.value.category === VIRTUAL_FORM_CATEGORY_VALUE
  )
);
const requiresAdvisor = computed(() =>
  Boolean(!form.value.carrier?.id && !props.formId)
);
const requiresCarrier = computed(() =>
  Boolean(!form.value.advisor?.id && !props.formId)
);
const requiresFormSets = computed(() => form.value.carrier?.id);

const v$ = useVuelidate(
  {
    form: {
      form: {
        required: Boolean,
        validSize: val => val?.size > 0
      },
      category: {
        required: Boolean
      },
      carrier: {
        required: v => !requiresCarrier.value || Boolean(v?.id)
      },
      advisor: {
        required: v => !requiresAdvisor.value || Boolean(v?.id)
      },
      formSets: {
        required: v => !requiresFormSets.value || v.length > 0
      },
      submissionMethod: {
        required: v => !requiresSubmissionMethod.value || Boolean(v)
      }
    }
  },
  {
    form
  },
  { $autoDirty: true, $scope: props.validationScope }
);

const categoryValidation = computedValidation(v$.value.form.category, {
  required: "Required"
});

const carrierValidation = computedValidation(v$.value.form.carrier, {
  required: "Required"
});

const advisorValidation = computedValidation(v$.value.form.advisor, {
  required: "Required"
});

const submissionMethodValidation = computedValidation(
  v$.value.form.submissionMethod,
  {
    required: "Required"
  }
);

const formSetValidation = computedValidation(v$.value.form.formSets, {
  required: "Required"
});

const fileValidation = computedValidation(v$.value.form.form, {
  required: "Required",
  validSize: "Please reupload this file"
});

async function initFormSets() {
  try {
    const res = await getAllFormSets(form.value.carrier.id);
    allFormSets.value.splice(0, allFormSets.value.length);
    allFormSets.value.push(...res);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    allFormSets.value.splice(0, allFormSets.value.length);
  }
}

function fetchFormSets() {
  if (!form.value.carrier?.id) return;
  set(form.value, "formSets", []);
  initFormSets();
}

function updateFormName() {
  if (!props.editing) return;
  const formStore = useFormMappingStore(props.formId);
  emit("update:name", form.value.name);
  savingBuffer.name.debounceUpdate(
    () => formStore.updateFormName(form.value.name),
    500
  );
}
function updateFormCategory() {
  if (!props.editing) return;
  const formStore = useFormMappingStore(props.formId);
  emit("update:category", form.value.category);
  savingBuffer.category.debounceUpdate(
    () => formStore.updateFormCategory(form.value.category),
    500
  );
}
function updateSubmissionMethod() {
  if (!props.editing) return;
  const formStore = useFormMappingStore(props.formId);
  emit("update:submission-method", form.value.submissionMethod);
  savingBuffer.submissionMethod.debounceUpdate(
    () => formStore.updateFormSubmissionMethod(form.value.submissionMethod),
    500
  );
}
async function updateFormAdvisor() {
  if (!props.editing) return;
  const formStore = useFormMappingStore(props.formId);
  emit("update:advisor", form.value.advisor);
  savingBuffer.advisor.update(() =>
    formStore.updateFormAdvisor(form.value.advisor)
  );
}
async function handleFormUpload() {
  if (!props.editing) return;
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  uploadingForm.value = true;
  try {
    const formStore = useFormMappingStore(props.formId);
    await formStore.updateFormPdf(form.value.form);
    location.reload();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    uploadingForm.value = false;
  }
}

const formSetAutocompleteKey = ref(new Date().getTime());
async function addFormSet(formSet) {
  addingNewFormSet.value = true;
  if (props.editing) {
    try {
      const formStore = useFormMappingStore(props.formId);
      await formStore.addNewFormSet(formSet.id);
    } catch (e) {
      formSetAutocompleteKey.value = new Date().getTime();
      snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
      return;
    }
  }

  form.value.formSets.push({
    ...formSet,
    deleting: false
  });
  nextTick(() => {
    formSetAutocompleteKey.value = new Date().getTime();
  });

  addingNewFormSet.value = false;
}

async function deleteFormSet(formSet) {
  formSet.deleting = true;
  if (props.editing) {
    try {
      const formStore = useFormMappingStore(props.formId);
      await formStore.removeFormSet(formSet.id);
    } catch (e) {
      snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
      return;
    }
  }

  const index = form.value.formSets.findIndex(val => val.id === formSet.id);
  if (index === -1) return;
  form.value.formSets.splice(index, 1);

  formSet.deleting = false;
}

watch(requiresSubmissionMethod, v => {
  if (!v) form.value.submissionMethod = null;
  else if (!props.editing) form.value.submissionMethod = "Paper";
});
watch(() => form.value.name, updateFormName);
watch(() => form.value.category, updateFormCategory);
watch(() => form.value.carrier, fetchFormSets);
watch(() => form.value.submissionMethod, updateSubmissionMethod);
watch(
  form,
  () => {
    if (props.editing) return;
    emit("update:category", form.value.category);
    emit("update:carrier", form.value.carrier);
    emit("update:advisor", form.value.advisor);
    emit("update:is-contracting", form.value.isContracting);
    emit("update:form-sets", form.value.formSets);
    emit("update:form", form.value.form);
    emit("update:name", form.value.name);
    emit("update:submission-method", form.value.submissionMethod);
  },
  { deep: true }
);

if (form.value.carrier?.id) initFormSets();
</script>
