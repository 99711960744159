<template>
  <v-card>
    <v-card-title v-if="type === 'document'">Add a Document</v-card-title>
    <v-card-title v-else-if="isLinkDialog">Add a Link</v-card-title>
    <v-card-title v-else>Add an Image</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            data-lpignore="true"
            v-if="showHref"
            :label="isLinkDialog ? 'URL' : 'Image URL'"
            prepend-inner-icon="$mdi-link"
            outlined
            dense
            persistent-hint
            hint=" "
            v-model="href"
          >
            <template #message="{ message }" v-if="!isLinkDialog">
              <v-row class="ma-0">
                {{ message }}
                <v-spacer />
                <a @click="showHref = false" v-if="!uploadingImage"
                  >Need to Upload an Image?</a
                >
              </v-row>
            </template>
          </v-text-field>
          <file-drag-and-drop
            v-else
            v-model="file"
            :loading="uploadingImage"
            :accept="uploadTypes"
            @input="uploadImageToS3"
            :error-messages="invalidFile"
          >
            <template #message>
              <a @click="showHref = true">
                <template v-if="type === 'document'">
                  Already have the File URL?
                </template>
                <template v-else> Already have the Image URL? </template>
              </a>
            </template>
          </file-drag-and-drop>
        </v-col>
        <v-col cols="12">
          <v-text-field
            data-lpignore="true"
            :label="isLinkDialog ? 'Text' : 'Alt Text'"
            outlined
            dense
            v-model="text"
            :prepend-inner-icon="isLinkDialog ? '$mdi-text' : '$mdi-image-text'"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        @click="closeDialog"
        class="text-none"
        :disabled="uploadingImage"
      >
        Close
      </v-btn>
      <v-btn
        @click="insertHref"
        class="text-none"
        color="primary"
        :disabled="uploadingImage"
      >
        Insert Link
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  name: "CreatorImageDialog",
  components: { FileDragAndDrop },
  props: {
    type: {
      type: String,
      validator: v => ["image", "link", "document"].includes(v),
      default: "link"
    },
    articleImageUploadFunc: Function
  },
  data() {
    return {
      text: "",
      href: "",
      file: null,
      showHref: this.type === "link",
      uploadingImage: false,
      timer: null
    };
  },
  computed: {
    invalidFile() {
      if (this.file?.size === 0)
        return [
          "Please confirm this file has data or try Re-uploading this file"
        ];
      return [];
    },
    isLinkDialog() {
      return ["document", "link"].includes(this.type);
    },
    uploadTypes() {
      return this.type === "image"
        ? ".apng, .avif, .gif, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp"
        : "";
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    insertHref() {
      if (!this.text || !this.href) return;
      let suffix = "";
      if (!this.isLinkDialog) suffix = "!";
      this.closeDialog({
        markdown: `${suffix}[${this.text}](${this.href})`
      });
    },

    uploadImageToS3() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.showHref || !this.file || this.invalidFile.length) return;
        this.uploadingImage = true;
        this.articleImageUploadFunc(this.file)
          .then(res => {
            this.file = null;
            this.href = res;
            this.showHref = true;
          })
          .catch(e => {
            this.showErrorSnackbar({
              message: "Failed to Upload a Document" + parseErrorMessage(e)
            });
          })
          .finally(() => {
            this.uploadingImage = false;
          });
      }, 20);
    }
  }
};
</script>
