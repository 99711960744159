import AddressFactory from "@/factories/AddressFactory";
import CompBuilderFactory from "@/factories/CompBuilderFactory";

export const CARRIER_DOCUMENT_CATEGORIES = [
  "Advisor Product Guide",
  "Carrier Contact List",
  "Consumer Product Guide",
  "Underwriting Guide",
  "Other",
  "Group 2+ Access"
];

export default function VerboseCarrier(model = {}) {
  return {
    addressee: model?.addressee || "",
    addresses: model?.addresses || [],
    autoSubmitContracting: model?.autoSubmitContracting || false,
    avatar: model?.avatar || "",
    checkCommissionSchedule: model?.checkCommissionSchedule || "",
    compBuilders: model?.compBuilders || [],
    details: model?.details || "",
    documents: model?.documents || [],
    eftCommissionSchedule: model?.eftCommissionSchedule || false,
    emailUnderwriter: model?.emailUnderwriter || false,
    emailEo: model?.emailEo || false,
    holdForAppointment: model?.holdForAppointment || false,
    id: model?.id || null,
    naic: model?.naic || null,
    amBestRating: model?.amBestRating || null,
    name: model?.name || "",
    paramedsAccountNumber: model?.paramedsAccountNumber || null,
    paramedsCode: model?.paramedsCode || null,
    quotingStates: model?.quotingStates || [],
    requiresWetSignature: model?.requires_wet_signature,
    strifeApproved: Boolean(model?.strifeApproved),
    strifeDisclosure: model?.strifeDisclosure || "",
    supportsInstantAps: model?.supportsInstantAps || false,
    training: model?.training || "",

    lifePhone: new CarrierPhoneNumbersFactory(model?.lifePhone),
    disabilityPhone: new CarrierPhoneNumbersFactory(model?.disabilityPhone),
    ltcPhone: new CarrierPhoneNumbersFactory(model?.ltcPhone),
    annuityPhone: new CarrierPhoneNumbersFactory(model?.annuityPhone),

    defaultAppointmentManager: new AppointmentManager(
      model?.defaultAppointmentManager
    ),
    emails: new CarrierLineEmailsFactory(model?.emails),
    personnel: new CarrierPersonnelByLineFactory(model?.personnel),
    websites: new CarrierWebsitesFactory(model?.websites),

    annuity: new LineDetailsFactory(model?.annuity),
    disability: new LineDetailsFactory(model?.disability),
    life: new LineDetailsFactory(model?.life),
    ltc: new LineDetailsFactory(model?.ltc),

    actives: new CarrierDatumByLineFactory(model?.actives),
    annualization: new CarrierDatumByLineFactory(model?.annualization),
    annualizationDetails: new CarrierDatumByLineFactory(
      model?.annualizationDetails
    ),
    autoSubmit: new CarrierDatumByLineFactory(model?.autoSubmit),
    dtcc: new CarrierDatumByLineFactory(model?.dtcc),
    eDelivery: new CarrierDatumByLineFactory(model?.eDelivery),
    eSignature: new CarrierDatumByLineFactory(model?.eSignature),
    gaCanOrderAps: new CarrierDatumByLineFactory(model?.gaCanOrderAps),
    multipleAppointments: new CarrierDatumByLineFactory(
      model?.multipleAppointments
    ),
    paymentSchedule: new CarrierDatumByLineFactory(model?.paymentSchedule),
    paysFullOverride: new CarrierDatumByLineFactory(model?.paysFullOverride),
    preappointmentStates: new CarrierDatumByLineFactory(
      model?.preappointmentStates
    ),
    reg187: new CarrierDatumByLineFactory(model?.reg187),
    requiresOriginalApplication: new CarrierDatumByLineFactory(
      model?.requiresOriginalApplication
    ),
    sendContracting: new CarrierDatumByLineFactory(model?.sendContracting),

    get type() {
      return "Carrier";
    },

    setFromRequest(model = {}) {
      this.id = model?.id;
      this.name = model?.name;
      this.documents = model?.documents;
      this.supportsInstantAps = model?.instant_aps;
      this.autoSubmitContracting = model?.auto_submit_contracting;
      this.holdForAppointment = model?.hold_for_appointment;
      this.emailUnderwriter = model?.email_underwriter;
      this.emailEo = model?.email_eo;
      this.training = model?.training;
      this.avatar = model?.avatar_url;
      this.strifeDisclosure = model?.strife_disclosure;
      this.addressee = model?.addressee;
      this.naic = model?.naic;
      this.amBestRating = model?.am_best_rating;
      this.details = model?.details;
      this.paramedsAccountNumber = model?.parameds_acct_num;
      this.paramedsCode = model?.parameds_code;

      this.quotingStates = model?.states?.map(val => val.name);
      this.eftCommissionSchedule = model?.eft_commission_schedule;
      this.checkCommissionSchedule = model?.check_commission_schedule;

      this.strifeApproved = model?.strife_approved;
      this.requiresWetSignature = model?.requires_wet_signature;

      this.quotingStates.sort((a, b) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        if (a.toLowerCase() > b.toLowerCase()) return 1;
        return 0;
      });

      this.defaultAppointmentManager = new AppointmentManager();
      this.defaultAppointmentManager.setFromRequest(
        model?.default_appointment_manager
      );

      this.life = setLineDetailsFromRequest(model?.life);

      this.annuity = setLineDetailsFromRequest(model?.annuity);

      this.ltc = setLineDetailsFromRequest(model?.ltc);

      this.disability = setLineDetailsFromRequest(model?.disability);

      this.websites = setCarrierWebsitesFromRequest(model?.websites);

      this.lifePhone = setCarrierPhoneNumbersFromRequest(model?.life_phone);

      this.disabilityPhone = setCarrierPhoneNumbersFromRequest(
        model?.disability_phone
      );

      this.ltcPhone = setCarrierPhoneNumbersFromRequest(model?.ltc_phone);

      this.annuityPhone = setCarrierPhoneNumbersFromRequest(
        model?.annuity_phone
      );

      this.actives = setCarrierDatumByLineFromRequest(model?.actives);
      this.annualization = setCarrierDatumByLineFromRequest(
        model?.annualization
      );
      this.annualizationDetails = setCarrierDatumByLineFromRequest(
        model?.annualization_details
      );
      this.autoSubmit = setCarrierDatumByLineFromRequest(model?.auto_submit);
      this.dtcc = setCarrierDatumByLineFromRequest(model?.dtcc);
      this.eDelivery = setCarrierDatumByLineFromRequest(model?.e_delivery);
      this.emails.setFromRequest(model?.emails);
      this.eSignature = setCarrierDatumByLineFromRequest(model?.e_signature);
      this.gaCanOrderAps = setCarrierDatumByLineFromRequest(
        model?.ga_can_order_aps
      );
      this.multipleAppointments = setCarrierDatumByLineFromRequest(
        model?.multiple_appointments
      );
      this.paymentSchedule = setCarrierDatumByLineFromRequest(
        model?.payment_schedule
      );
      this.paysFullOverride = setCarrierDatumByLineFromRequest(
        model?.pays_full_override
      );
      this.preappointmentStates = setCarrierDatumByLineFromRequest(
        model?.preappointment_states
      );
      this.reg187 = setCarrierDatumByLineFromRequest(model?.reg_187);
      this.requiresOriginalApplication = setCarrierDatumByLineFromRequest(
        model?.requires_original_application
      );
      this.sendContracting = setCarrierDatumByLineFromRequest(
        model?.send_contracting
      );

      this.personnel = new CarrierPersonnelByLineFactory();
      this.personnel.setFromRequest(model);

      this.addresses = [];
      this.actives.iter.forEach(line => {
        const addressType = `${line},new_business`;

        const rawAddress = model?.addresses?.find(
          address => address.address_type === addressType
        );
        const address = new AddressFactory({ addressType });
        if (rawAddress) address.setFromRequest(rawAddress);
        this.addresses.push(address);
      });

      this.compBuilders = model?.comp_builders.map(rawCompBuilder => {
        const compBuilder = new CompBuilderFactory();
        compBuilder.setFromRequest(rawCompBuilder);
        return compBuilder;
      });
    },
    generateAttributeMap() {
      return {
        emailUnderwriter: {
          email_underwriter: this.emailUnderwriter
        },
        emailEo: {
          email_eo: this.emailEo
        },
        autoSubmitContracting: {
          auto_submit_contracting: this.autoSubmitContracting
        },
        holdForAppointment: {
          hold_for_appointment: this.holdForAppointment
        },
        defaultAppointmentManager: {
          default_appointment_manager_id: this.defaultAppointmentManager?.id
        },
        eftCommissionSchedule: {
          eft_commission_schedule: this.eftCommissionSchedule
        },
        checkCommissionSchedule: {
          check_commission_schedule: this.checkCommissionSchedule
        },
        naic: { naic: this.naic },
        amBestRating: { am_best_rating: this.amBestRating },
        addressee: { addressee: this.addressee },
        strifeDisclosure: { strife_disclosure: this.strifeDisclosure },
        details: { details: this.details },
        preappointmentStates: {
          preappointment_states: this.preappointmentStates
        },
        quotingStates: {
          states: this.quotingStates.map(val => ({ name: val }))
        },
        supportsInstantAps: { instant_aps: this.supportsInstantAps },
        paramedsCode: { parameds_code: this.paramedsCode },
        paramedsAccountNumber: {
          parameds_acct_num: +this.paramedsAccountNumber
        },
        strifeApproved: {
          strife_approved: Boolean(this.strifeApproved)
        },
        requiresWetSignature: {
          requires_wet_signature: Boolean(this.requiresWetSignature)
        }
      };
    },
    getAttributeRequestValue(attribute) {
      const attributeMap = this.generateAttributeMap();
      return attributeMap[attribute];
    }
  };
}

function LineDetailsFactory(model = {}) {
  return {
    details: model?.details || ""
  };
}

function setLineDetailsFromRequest(model = {}) {
  const lineDetails = LineDetailsFactory();
  let datum = model;
  if (typeof model === "string") {
    datum = JSON.parse(model);
  }

  lineDetails.details = datum?.details;

  return lineDetails;
}

function CarrierDatumByLineFactory(model = {}) {
  return {
    annuity: model?.annuity || "",
    disability: model?.disability || "",
    life: model?.life || "",
    ltc: model?.ltc || "",
    get iter() {
      return ["annuity", "disability", "ltc", "life"];
    }
  };
}

function setCarrierDatumByLineFromRequest(model = {}) {
  const carrierDatum = CarrierDatumByLineFactory();
  let datum = model;
  if (typeof model === "string") {
    datum = JSON.parse(model);
  }

  carrierDatum.annuity = datum?.annuity;
  carrierDatum.disability = datum?.disability;
  carrierDatum.life = datum?.life;
  carrierDatum.ltc = datum?.ltc;

  return carrierDatum;
}

function CarrierWebsitesFactory(model = {}) {
  return {
    main: model?.main || null,
    annuity: model?.annuity || null,
    disability: model?.disability || null,
    life: model?.life || null,
    ltc: model?.ltc || null
  };
}

function setCarrierWebsitesFromRequest(model = {}) {
  const carrierWebsites = CarrierWebsitesFactory();
  let datum = model;
  if (typeof model === "string") {
    datum = JSON.parse(model);
  }

  carrierWebsites.main = datum?.main;
  carrierWebsites.annuity = datum?.annuity;
  carrierWebsites.disability = datum?.disability;
  carrierWebsites.life = datum?.life;
  carrierWebsites.ltc = datum?.ltc;

  return carrierWebsites;
}

function CarrierPhoneNumbersFactory(model = {}) {
  return {
    newBusiness: model?.newBusiness || null,
    licensing: model?.licensing || null,
    salesDesk: model?.salesDesk || null,
    newBusinessFax: model?.newBusinessFax || null,
    licensingFax: model?.licensingFax || null,
    customerService: model?.customerService || null
  };
}

function setCarrierPhoneNumbersFromRequest(model = {}) {
  const carrierPhoneNumbers = CarrierPhoneNumbersFactory();
  let datum = model;
  if (typeof model === "string") {
    datum = JSON.parse(model);
  }

  carrierPhoneNumbers.newBusiness = datum?.new_business;
  carrierPhoneNumbers.licensing = datum?.licensing;
  carrierPhoneNumbers.salesDesk = datum?.sales_desk;
  carrierPhoneNumbers.newBusinessFax = datum?.new_business_fax;
  carrierPhoneNumbers.licensingFax = datum?.licensing_fax;
  carrierPhoneNumbers.customerService = datum?.customer_service;

  return carrierPhoneNumbers;
}

function CarrierPersonnelByLineFactory(model = {}) {
  return {
    annuity: model?.annuity || new CarrierEmailsFactory(),
    disability: model?.disability || new CarrierEmailsFactory(),
    life: model?.life || new CarrierEmailsFactory(),
    ltc: model?.ltc || new CarrierEmailsFactory(),
    setFromRequest(model = {}) {
      const lines = ["annuity", "life", "disability", "ltc"];

      lines.forEach(line => {
        this[line] = new CarrierLinePersonnelFactory();
        this[line].setFromRequest({
          licensing: model[`licensing_${line}`],
          new_business: model[`new_business_${line}`],
          new_application: model[`new_app_${line}`],
          customer_service: model[`customer_service_${line}`]
        });
      });
    }
  };
}

function CarrierLinePersonnelFactory(model = {}) {
  return {
    licensing: model?.licensing || null,
    newBusiness: model?.newBusiness || null,
    newApplication: model?.newApplication || null,
    customerService: model?.customerService || null,
    setFromRequest(model = {}) {
      this.licensing = model?.licensing;
      this.newBusiness = model?.new_business;
      this.newApplication = model?.new_application;
      this.customerService = model?.customer_service;
    }
  };
}

export function CarrierLineEmailsFactory(model = {}) {
  return {
    annuity: new CarrierEmailsFactory(model?.annuity),
    disability: new CarrierEmailsFactory(model?.disability),
    life: new CarrierEmailsFactory(model?.life),
    ltc: new CarrierEmailsFactory(model?.ltc),
    setFromRequest(model = {}) {
      let datum = model;
      if (typeof model === "string") datum = JSON.parse(model);
      this.disability.setFromRequest(datum?.disability);
      this.life.setFromRequest(datum?.life);
      this.annuity.setFromRequest(datum?.annuity);
      this.ltc.setFromRequest(datum?.ltc);
    },

    toUpdateRequest() {
      return {
        annuity: this.annuity.toUpdateRequest(),
        disability: this.disability.toUpdateRequest(),
        life: this.life.toUpdateRequest(),
        ltc: this.ltc.toUpdateRequest()
      };
    }
  };
}

function CarrierEmailsFactory(model = {}) {
  return {
    quickQuote: model?.quickQuote || null,
    setFromRequest(model = {}) {
      let datum = model;
      if (typeof model === "string") {
        datum = JSON.parse(model);
      }

      this.quickQuote = datum?.quick_quote || datum?.quickQuote;
    },
    toUpdateRequest() {
      return {
        quick_quote: this.quickQuote || null
      };
    }
  };
}

function AppointmentManager(model = {}) {
  return {
    name: model?.name,
    id: model?.id,
    setFromRequest(rawModel = {}) {
      this.name = rawModel?.name;
      this.id = rawModel?.id;
    }
  };
}
