import range from "lodash/range";

const minHeight = 50;
const maxHeight = 83;
const heightsRaw = range(minHeight, maxHeight + 1);

const heightToString = function (value) {
  const inchesInFoot = 12;

  const quotient = Math.floor(value / inchesInFoot);
  const remainder = value % inchesInFoot;

  return `${quotient}' ${remainder}"`;
};

const heights = heightsRaw.map(value => {
  if (!value) {
    return {
      text: "",
      value
    };
  }
  return {
    text: heightToString(value),
    value
  };
});
export const HEIGHTS = heights;
