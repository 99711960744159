<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-row justify="center" class="ma-0 mt-3">
        <div class="width-full">
          <to-do-list-view
            :subject="{
              id: impairedRiskQuoteView.id,
              type: 'QuickQuote',
              name: 'This Quote'
            }"
            :highlight-todo="highlightTodo"
          />
        </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ToDoListView from "@/components/todo/ToDoListView.vue";
import { useImpairedRiskQuoteView } from "@/stores/impaired-risk-quote-view";

import { defineProps, toRef } from "vue";
const props = defineProps({
  highlightTodo: {
    type: Number,
    required: false,
    default: null
  }
});

const highlightTodo = toRef(props, "highlightTodo");

const impairedRiskQuoteView = useImpairedRiskQuoteView();
</script>
