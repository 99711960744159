<script>
import ActiveSaveFactory from "@/factories/ActiveSaveFactory";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  methods: {
    ...mapActions(useSnackbarStore, [
      "showErrorSnackbar",
      "showSuccessSnackbar"
    ]),
    activeSaveElementFactory(config) {
      return ActiveSaveFactory(config);
    },
    updateAttributeWrapper(func, element = null) {
      element.needsUpdate;
      if (!element) return func();
      element.saving = true;
      element.error = false;
      element.saved = false;
      element.promise = func()
        .then(() => {
          element.saving = false;
          element.saved = true;
          setTimeout(() => {
            element.saved = false;
          }, 250);
        })
        .catch(e => {
          element.saving = false;
          element.error = true;
          element.errorMessage = parseErrorMessage(e);

          this.showErrorSnackbar({
            message: `${element.errorMessage}`,
            timeout: -1
          });
        });

      return element.promise;
    },
    generateSavingBuffer({
      items = [],
      addressItems = [],
      driversLicenseItems = []
    }) {
      const buffer = {};
      items.forEach(item => {
        buffer[item] = this.activeSaveElementFactory();
      });
      addressItems.forEach(item => {
        buffer[`${item}-street_address`] = this.activeSaveElementFactory();
        buffer[`${item}-city`] = this.activeSaveElementFactory();
        buffer[`${item}-zip`] = this.activeSaveElementFactory();
        buffer[`${item}-state`] = this.activeSaveElementFactory();
      });
      driversLicenseItems.forEach(item => {
        buffer[`${item}-number`] = this.activeSaveElementFactory();
        buffer[`${item}-state`] = this.activeSaveElementFactory();
      });
      return buffer;
    },
    genericUpdate(func, key) {
      if (this.savingBuffer[key].timer)
        clearTimeout(this.savingBuffer[key].timer);

      this.savingBuffer[key].timer = setTimeout(() => {
        this.updateAttributeWrapper(func, this.savingBuffer[key]);
      }, 500);
    },
    mutationDebounce(func, key) {
      if (this.savingBuffer[key].mutationTimer)
        clearTimeout(this.savingBuffer[key].mutationTimer);

      this.savingBuffer[key].mutationTimer = setTimeout(func, 500);
    }
  }
};
</script>
