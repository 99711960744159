<template>
  <v-card
    flat
    color="section"
    :style="{ paddingBottom: table.items.value.length < 5 ? '10rem' : null }"
  >
    <v-card-text>
      <v-data-table
        class="transparent-data-table"
        data-testid="backnine-statement-transactions-table"
        must-sort
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :server-items-length="table.meta.value.total"
        :options="table.options.value"
        :footer-props="footerProps"
        :loading="table.loading.value"
        @update:options="updateOptions"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5">Transactions</h1>
            <v-spacer />
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  data-testid="backnine-statement-transactions-table-refresh"
                  @click="getData"
                >
                  <v-icon>$mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
          <generic-table-filter-header
            :value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>
        <template #[`item.policyNumber`]="{ item }">
          <router-link :to="getRouterLink('Case', item.additional.case.id)">
            {{ item.policyNumber }}
          </router-link>
        </template>
        <template #[`item.amount`]="{ item }">
          <currency-formatter :value="item.amount" />
        </template>
        <template #[`item.override`]="{ item }">
          <currency-formatter :value="item.override" />
        </template>
        <template #[`item.directCommission`]="{ item }">
          <currency-formatter :value="item.directCommission" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import { commissionTypes } from "@/data/filter-options";
import { parseErrorMessage, getRouterLink } from "@/util/helpers";

import { getTransactionsRelatedToStatement } from "@/api/statements.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTable } from "@/composables/table.composable";
import { computed, defineProps } from "vue";

const props = defineProps({ id: { type: [Number, String], required: true } });

const table = useTable({
  headers: [
    new TableHeader({
      text: "Transaction ID",
      value: "id",
      map: "id",
      sortFilterMap: "transaction_id",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Policy Number",
      value: "policyNumber",
      map: "case.policyNumber",
      sortFilterMap: "cases.stripped_policy_number",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Commission Type",
      value: "commissionType",
      map: "commissionType",
      selectableOptions: commissionTypes,
      sortFilterMap: "transactions.commission_type",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Amount",
      value: "amount",
      map: "amount",
      sortFilterMap: "transactions.amount",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Override",
      value: "override",
      map: "override",
      sortFilterMap: "transactions.override",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Direct Commission",
      value: "directCommission",
      map: "directCommission",
      sortFilterMap: "transactions.direct_commission",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    })
  ],
  getData: v => getTransactionsRelatedToStatement(props.id, v),
  options: new TableOptions(["policyNumber"], [true])
});

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(table.options.value, table.meta.value),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateOptions(newOptions) {
  if (table.optionsEquivalence(newOptions)) return;
  table.options.value = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}

getData();
</script>
