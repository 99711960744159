<template>
  <v-autocomplete
    autocomplete="false"
    prepend-inner-icon="$mdi-checkbook"
    :placeholder="placeholder"
    label="Statement"
    outlined
    dense
    v-model="statement"
    return-object
    no-filter
    hide-no-data
    auto-select-first
    autofocus
    :search-input.sync="search"
    :success="success"
    :error-messages="errorMessages"
    :items="statements"
    :loading="loading"
    :disabled="disabled"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
  />
</template>

<script>
import sortBy from "lodash/sortBy";
import { statementSearch } from "@/api/statements.service";
import { currencyFormat, timestampFormatter } from "@/util/helpers";

export default {
  props: {
    success: Boolean,
    errorMessages: Array,
    value: Object,
    disabled: Boolean,
    carrierId: [String, Number],
    autofocus: Boolean,
    placeholder: {
      default: "Search Statements",
      required: false,
      type: String
    }
  },
  data() {
    let statements = [];
    let statement = null;
    if (this.value && Object.keys(this.value).length) {
      statements = [
        { text: this.convertStatementToText(this.value), ...this.value }
      ];
      statement = {
        text: this.convertStatementToText(this.value),
        ...this.value
      };
    }
    return {
      search: "",
      statementTimer: null,
      loading: false,
      statements,
      statement
    };
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.statements = [];
        this.statement = null;
      }
      if (this.statement?.id === value?.id) return;
      this.statement = { text: this.convertStatementToText(value), ...value };
      this.statements = [
        { text: this.convertStatementToText(value), ...value }
      ];
    },
    statement(value) {
      this.$emit("input", value);
    },
    search(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }

      if (
        this.statements.some(
          val => this.convertStatementToText(val) === value
        ) &&
        this.statements.length === 1
      ) {
        return;
      }

      if (this.statementTimer) {
        clearTimeout(this.statementTimer);
      }

      this.statementTimer = setTimeout(() => {
        this.loading = true;
        statementSearch(value, this.carrierId)
          .then(response => {
            const statements = response.map(row => {
              return {
                text: this.convertStatementToText(row),
                ...row
              };
            });
            this.statements = sortBy(statements, "statement_date").reverse();
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  },
  methods: {
    convertStatementToText(row) {
      if (!row?.payor?.name) return "";
      return [
        row.payor.name,
        timestampFormatter(row.statement_date, "sole-day"),
        row.amount ? currencyFormat(row.amount) : null
      ]
        .filter(Boolean)
        .join(" - ");
    }
  }
};
</script>
