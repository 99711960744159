import { BOSS_VIDEOS, QUOTE_AND_APPLY_VIDEOS } from "@/data/videos";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useUserStore } from "@/stores/user";
import { computed, ref } from "vue";

export const AVAILABLE_SERIES = {
  WALKTHROUGH: "walkthrough"
};

function C2A(model = {}) {
  let buttonListeners = {};
  if (model?.buttonListeners) {
    buttonListeners = { ...model.buttonListeners };
  }
  return {
    title: model?.title,
    icon: model?.icon,
    color: model?.color,
    buttonProps: {
      href: model?.buttonProps?.href,
      to: model?.buttonProps?.to,
      target: model?.buttonProps?.target,
      loading: model?.buttonProps?.loading,
      disabled: model?.buttonProps?.disabled
    },
    buttonListeners
  };
}

export const useWalkthroughComposable = () => {
  const user = useUserStore();
  const snackbar = useSnackbarStore();
  const loading = ref(false);
  const isCompleted = computed(() => user.tutorials.series_walkthrough);

  const walkthroughQnaC2a = computed(() => {
    if (user.loginable.approved_domain_url) {
      return C2A({
        title: "Start an eApp",
        color: "primary",
        buttonProps: {
          href: user.loginable.approved_domain_url,
          target: "_blank"
        }
      });
    }
    return C2A({
      title: "Create a Quote & Apply™ Website",
      color: "primary",
      buttonProps: {
        to: { name: "QuoteAndApply" },
        target: "_blank"
      }
    });
  });

  const completedC2a = computed(() => {
    if (user.tutorials.series_walkthrough) {
      return C2A({
        title: "Series Completed",
        icon: "$mdi-checkbox-marked-circle-outline",
        color: "accent",
        buttonProps: {
          disabled: true
        }
      });
    }
    return C2A({
      title: "Mark as Complete",
      color: "accent",
      buttonProps: {
        loading: loading.value
      },
      buttonListeners: { click: completeTutorial }
    });
  });

  const contractingC2a = computed(() => {
    return C2A({
      title: "View Contracting",
      color: "primary",
      buttonProps: {
        to: { name: "LoggedInUserSettings" },
        target: "_blank"
      }
    });
  });

  async function completeTutorial() {
    try {
      loading.value = true;
      user.completeTutorial("series_walkthrough");
      await user.saveTutorials();
    } catch (e) {
      snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    } finally {
      loading.value = false;
    }
  }

  return {
    listId: AVAILABLE_SERIES.WALKTHROUGH,
    listTitle: "BOSS and Quote & Apply™ Walkthrough",
    listSubtitle:
      "Learn about BOSS, Contracting, and the Quote & Apply™ Application Process",
    title: "Learn how to use BOSS",
    isCompleted,
    sections: [
      {
        title: "Learn how to use BOSS",
        subtitle:
          "After watching 3 short videos you'll learn how BOSS functions, what tooling is available, how to complete contracting, how to get appointed, and how to complete applications with Quote & Apply™"
      },
      {
        url: BOSS_VIDEOS.WELCOME.url,
        title: "1. Welcome to BOSS",
        subtitle:
          "Learn about BOSS and its available tools such as marketing and Quote & Apply™."
      },
      {
        url: BOSS_VIDEOS.CONTRACTING.url,
        title: "2. BOSS Contracting",
        subtitle:
          "Learn how to remove account limitations and complete contracting. Then, you can complete applications with with Quote & Apply™.",
        c2a: contractingC2a
      },
      {
        url: QUOTE_AND_APPLY_VIDEOS.EAPPLICATION_PROCESS.url,
        title: "3. Quote & Apply™ eApplication Process",
        subtitle:
          "Watch the Quote & Apply™ eApp process and learn how to use it for yourself.",
        c2a: walkthroughQnaC2a
      },
      {
        primaryTitle: true,
        title: "Congratulations!",
        subtitle: "You are now ready to use BOSS.",
        c2a: completedC2a
      }
    ]
  };
};
