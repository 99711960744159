import NoteFactory from "@/factories/NoteFactory";
import AddressFactory from "@/factories/AddressFactory";

import { INDIVIDUAL_INDICATE } from "@/factories/ContractPartyFactory";
import { Address, setRawFromAddress } from "@/factories/AddressFactory";
import {
  CONTINGENT_BENEFICIARY,
  INSURED,
  JOINT_INSURED,
  PRIMARY_BENEFICIARY
} from "@/factories/RoleFactory";

export function Individual(model = {}) {
  return {
    addresses: {
      home: new AddressFactory({ addressType: "home" }),
      business: new AddressFactory({ addressType: "business" })
    },
    address: new AddressFactory(model?.address),
    assets: model?.assets || null,
    avatarUrl: model?.avatarUrl || null,
    bankruptcyDischargeDate: model?.bankruptcyDischargeDate || null,
    bankruptcyType: model?.bankruptcyType || null,
    birthLocation: model?.birthLocation || null,
    birthdate: model?.birthdate || null,
    countryOfCitizenship: model?.countryOfCitizenship || null,
    driversLicense: model?.driversLicense || null,
    electronicApplicationId: model?.electronicApplicationId || null,
    email: model?.email || null,
    gender: model?.gender || null,
    id: model?.id || null,
    income: model?.income || null,
    lastBuild: model?.lastBuild || null,
    liabilities: model?.liabilities || null,
    maritalStatus: model?.maritalStatus || null,
    name: model?.name || null,
    networth: model?.networth || null,
    notes: model?.notes || null,
    occupation: model?.occupation || null,
    partyId: model?.partyId || null,
    primaryPhone: model?.primaryPhone || null,
    phoneMobile: model?.phoneMobile || null,
    usEntryDate: model?.usEntryDate || null,
    visaType: model?.visaType || null,
    role: model?.role || null,
    firstName: model?.firstName || null,
    lastName: model?.lastName || null,
    middleName: model?.middleName || null
  };
}

export function setIndividualFromRequest(model = {}) {
  const individual = Individual();
  individual.addresses = {
    home: model?.addresses?.home,
    business: model?.addresses?.business
  };
  individual.assets = model?.assets;
  individual.avatarUrl = model?.avatar_url;
  individual.bankruptcyDischargeDate = model?.bankruptcy_discharge_date;
  individual.bankruptcyType = model?.bankruptcy_type;
  individual.birthLocation = model?.birth_location;
  individual.birthdate = model?.birthdate;
  individual.countryOfCitizenship = model?.country_of_citizenship;
  individual.driversLicense = model?.drivers_license;
  individual.electronicApplicationId = model?.electronic_application_id;
  individual.email = model?.email;
  individual.gender = model?.gender;
  individual.id = model?.id;
  individual.income = model?.income;
  individual.lastBuild = model?.last_build;
  individual.liabilities = model?.liabilities;
  individual.maritalStatus = model?.marital_status;
  individual.name = model?.name;
  individual.firstName = model?.first_name;
  individual.lastName = model?.last_name;
  individual.middleName = model?.middle_name;
  individual.networth = model?.networth;
  individual.occupation = model?.occupation;
  individual.partyId = model?.party_id;
  individual.primaryPhone = model?.primary_phone;
  individual.usEntryDate = model?.us_entry_date;
  individual.visaType = model?.visa_type;

  individual.notes = [];
  model?.notes?.forEach(rawNote => {
    const note = new NoteFactory();
    note.setFromRequest(rawNote);
    individual.notes.push(note);
  });
  return individual;
}

export function NewCaseIndividual(model = {}) {
  return {
    homeAddress: Address({ addressType: "home" }),
    netWorth: model?.netWorth || null,
    occupation: model?.occupation || null,
    dlState: model?.dlState || null,
    dlNumber: model?.dlNumber || null,
    phoneMobile: model?.phoneMobile || null,
    income: model?.income || null,
    birthdate: model?.birthdate || null,
    ssn: model?.ssn || null,
    gender: model?.gender || null,
    email: model?.email || null,
    roles: model?.roles || [],
    beneficiaryAmount: model?.beneficiaryAmount || null,
    relationship: model?.relationship || null,
    firstName: model?.firstName || null,
    lastName: model?.lastName || null,
    middleName: model?.middleName || null
  };
}

export function NewCaseIndividualToCreateRequest(individual) {
  const address = Address(individual.homeAddress);
  return {
    addresses_attributes: [setRawFromAddress(address)],
    birthdate: individual.birthdate,
    drivers_license_attributes: {
      state: individual.dlState,
      number: individual.dlNumber
    },
    phone_mobile: individual.phoneMobile,
    email: individual.email,
    first_name: individual.firstName,
    income: parseInt(individual.income),
    last_name: individual.lastName,
    middle_name: individual.middleName,
    natural_non_natural_indicate: INDIVIDUAL_INDICATE,
    networth: parseInt(individual.netWorth),
    occupation: individual.occupation || " ",
    roles_attributes: individual.roles.map(role => {
      const attributes = {
        role
      };

      if ([PRIMARY_BENEFICIARY, CONTINGENT_BENEFICIARY].includes(role)) {
        attributes.beneficiary_amount = +(
          individual.beneficiaryAmount / 100
        ).toFixed(2);
        attributes.beneficiary_qualifier = "Percent";
      }
      if (![INSURED, JOINT_INSURED].includes(role)) {
        attributes.relationship = individual.relationship;
      }

      return attributes;
    }),
    gender: individual.gender,
    party_id: individual.ssn
  };
}
