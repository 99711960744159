<template>
  <div class="parent" ref="parent" v-chatscroll>
    <chat-message
      v-for="(message, index) in messages"
      class="message"
      data-testid="chat-message"
      :message="message"
      :key="`${index}-${message.id}`"
      @remove-message="removeMessage"
    />
  </div>
</template>
<script>
import ChatMessage from "@/components/shared/chat/ChatMessage.vue";
import chatscroll from "@/directives/chatscroll";
export default {
  components: {
    ChatMessage
  },
  props: {
    messages: Array
  },
  directives: {
    chatscroll
  },
  methods: {
    removeMessage(message) {
      this.$emit("remove-message", message);
    }
  }
};
</script>
