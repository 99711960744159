import { useUserStore, AUTH_STEPS } from "@/stores/user";
import { authenticateAndSetDetails } from "@/api/auth.service";

const authStepGuard = async (expectedStep, to, from, next) => {
  if (process.env.VUE_APP_COMPONENT_TESTING) return next();
  let query = to.query;
  query.redirect = to.fullPath;
  if (query.redirect === "/") delete query.redirect;
  try {
    await authenticateAndSetDetails();
    const user = useUserStore();
    const nextAuthStep = user.nextAuthStep();
    if (nextAuthStep === expectedStep) return next();
    next({ name: nextAuthStep, query });
  } catch (e) {
    const userStore = useUserStore();
    userStore.logoutUser();

    next({ name: "SignIn", query });
  }
};

export const authGuard = (to, from, next) =>
  authStepGuard(AUTH_STEPS.COMPLETED, to, from, next);

export const onboardingGuard = (from, to, next) =>
  authStepGuard(AUTH_STEPS.ONBOARDING_SIGNUP, to, from, next);

export const twoFactorEnrollmentRequiredGuard = (from, to, next) =>
  authStepGuard(AUTH_STEPS.TWO_FACTOR_ENROLLMENT_REQUIRED, to, from, next);

export const twoFactorConfirmationRequiredGuard = (from, to, next) =>
  authStepGuard(AUTH_STEPS.TWO_FACTOR_CONFIRMATION, to, from, next);
