<template>
  <v-data-table
    class="transparent-data-table"
    data-testid="case-parties-advisors-table"
    :items="table.mappedItems.value"
    :headers="table.tableHeaders.value"
    :search="search"
  >
    <template #[`item.name`]="{ item }">
      <dual-link-button
        v-if="item.additional.advisorProfileLink?.name"
        :left-link="item.additional.advisorProfileLink"
        left-icon="$mdi-account-tie"
        :left-text="item.name"
        :left-tooltip="`View ${item.name}'s Profile`"
        :right-link="item.additional.advisorSettingsLink"
        :show-right-link="
          Boolean(
            user.isGroupThreePlus && item.additional.advisorSettingsLink?.name
          )
        "
        right-icon="$mdi-cogs"
        :right-tooltip="`View ${item.name}'s Settings`"
      />
      <template v-else>
        {{ item.name }}
      </template>
    </template>
    <template #[`item.ssn`]="{ item }">
      <sensitive-info-toggle
        :type="item.additional.ssnOrTin"
        :fetch-func="() => fetchSensitiveInfo(item.additional)"
      />
    </template>
    <template #[`item.code`]="{ item }">
      <v-tooltip v-if="item.additional.appointmentRouterLink" top>
        <template #activator="{ on, attrs }">
          <v-btn
            :to="item.additional.appointmentRouterLink"
            v-on="on"
            v-bind="attrs"
            depressed
            color="primary"
            small
            class="text-none px-2"
          >
            <v-icon small class="mr-1"> $mdi-calendar </v-icon>
            <div class="truncate-200">
              {{ item.code }}
            </div>
          </v-btn>
        </template>
        <span>View {{ item.code }} </span>
      </v-tooltip>
      <template v-else>
        {{ item.code }}
      </template>
    </template>
    <template #[`item.upline`]="{ item }">
      <v-tooltip v-if="item.additional.appointmentAssignmentRouterLink" top>
        <template #activator="{ on, attrs }">
          <v-btn
            :to="item.additional.appointmentAssignmentRouterLink"
            v-on="on"
            v-bind="attrs"
            depressed
            color="primary"
            small
            class="text-none px-2"
          >
            <v-icon small class="mr-1"> $mdi-calendar </v-icon>
            <div class="truncate-200">
              {{ item.upline }}
            </div>
          </v-btn>
        </template>
        <span>View {{ item.upline }} </span>
      </v-tooltip>
      <template v-else>
        {{ item.upline }}
      </template>
    </template>
    <template #[`item.splitPercent`]="{ item }">
      {{ item.splitPercent }}%
    </template>
    <template #[`item.phoneWork`]="{ item }">
      <a
        :href="`tel:${item.phoneWork}`"
        :data-outbound-type="item.additional.advisorType"
        :data-outbound-id="item.additional.advisorId"
        :data-outbound-number="item.phoneWork"
        >{{ item.phoneWork }}</a
      >
    </template>
    <template #[`item.actions`]="{ item }">
      <v-row class="ma-0">
        <v-btn icon data-testid="edit" @click="editAdvisor(item.additional)">
          <v-icon color="success">$mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          icon
          color="error"
          data-testid="delete"
          @click="deleteRow(item.additional)"
        >
          <v-icon> $mdi-delete </v-icon>
        </v-btn>
      </v-row>
    </template>
  </v-data-table>
</template>

<script setup>
import DualLinkButton from "@/components/shared/DualLinkButton.vue";
import SensitiveInfoToggle from "@/components/shared/SensitiveInfoToggle.vue";
import EditAdvisorDialog from "@/components/cases/case-dialogs/EditAdvisorDialog.vue";

import TableHeader from "@/classes/data-table/TableHeader";

import { getAgentSsn } from "@/api/agents.service";
import { getAgencyTin } from "@/api/agencies.service";
import { destroyAppointmentCase } from "@/api/appointment-cases.service";

import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import { set, defineProps, toRef, watch, markRaw } from "vue";
import { storeToRefs } from "pinia";
import { useCaseViewStore } from "@/stores/case-view";

const props = defineProps({
  search: {
    type: String,
    required: false,
    default: ""
  }
});

const search = toRef(props, "search");

const { appointmentCases } = storeToRefs(useCaseViewStore());

const user = useUserStore();
const dialog = useDialogStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "advisorName",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Split %",
      value: "splitPercent",
      map: "splitPercent",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Code",
      value: "code",
      map: "appointmentCode",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Phone",
      value: "phoneWork",
      map: "advisorPhone",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "SSN",
      value: "ssn",
      map: "advisor.ssn"
    }),
    new TableHeader({
      text: "Upline",
      value: "upline",
      map: "assignmentName",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Manager",
      value: "manager",
      map: "marketingManagerName",
      ...TableHeader.IS_FILTERABLE
    })
  ],
  items: appointmentCases.value
});

if (user.isGroupThreePlus) {
  table.headers.value.push(
    new TableHeader({
      text: "Actions",
      value: "actions",
      width: "104px"
    })
  );
}

function fetchSensitiveInfo(appointmentCase) {
  if (appointmentCase.advisorType === "Agency") {
    return getAgencyTin(appointmentCase.advisorId);
  }
  return getAgentSsn(appointmentCase.advisorId);
}

async function editAdvisor(appointmentCase) {
  const result = await dialog.showDialog({
    component: markRaw(EditAdvisorDialog),
    value: appointmentCase
  });

  if (!result?.appointmentCase) return;
  const { appointmentCase: ac } = result;
  const index = appointmentCases.value.findIndex(r => {
    return ac.appointmentCaseId === r.appointmentCaseId;
  });
  if (index === -1) return;

  // Reactive set?
  set(appointmentCases.value, index, ac);
  const tableIndex = table.items.value.findIndex(
    r => ac.appointmentCaseId === r.appointmentCaseId
  );
  if (tableIndex === -1) return;
  set(table.items.value, tableIndex, ac);
}

async function deleteRow(appointmentCase) {
  const id = appointmentCase.appointmentCaseId;
  const res = await dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Remove ${appointmentCase.advisorName} from this Case?`,
    subtitle: "This cannot be undone",
    func: () => destroyAppointmentCase(id)
  });

  if (!res?.confirm) return;

  const index = appointmentCases.value.findIndex(
    v => v.appointmentCaseId === id
  );
  if (index === -1) return;
  appointmentCases.value.splice(index, 1);
}

watch(appointmentCases, () => {
  table.items.value.splice(
    0,
    table.items.value.length,
    ...appointmentCases.value
  );
});
</script>
