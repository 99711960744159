<template>
  <div>
    <v-tabs v-model="activeTab" show-arrows background-color="section">
      <v-tab
        v-for="tab in tabs"
        v-model="activeTab"
        :data-testid="`tab-${tab.title}`"
        :key="tab.title"
        class="text-none"
        >{{ tab.title }}</v-tab
      >
    </v-tabs>

    <v-tabs-items v-model="activeTab" touchless>
      <v-tab-item
        v-for="(tab, index) in tabs"
        :key="tab.title + index"
        class="section"
      >
        <v-row class="ma-0">
          <v-col
            v-for="row in tab.values"
            :key="row.label"
            data-testid="metric"
            cols="12"
          >
            <v-row class="ma-0">
              <v-col class="pa-0">
                <span> {{ row.label }} </span>
              </v-col>
              <v-col align="end" class="pa-0">
                <span data-testid="metric-goal" class="bold">
                  {{ row.maxText }}
                </span>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-progress-linear
                height="1rem"
                :value="calculatePercentage(row)"
                color="accent"
              >
                <span data-testid="metric-value">
                  {{ row.value }}
                </span>
              </v-progress-linear>
            </v-row>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script setup>
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { storeToRefs } from "pinia";

const advisorView = useAdvisorProfileView();
const { goals } = storeToRefs(advisorView);

import { currencyFormat } from "@/util/helpers";
import { computed, ref } from "vue";

const activeTab = ref(1);

function calculatePercentage(row) {
  if (row.value != 0) {
    let percentage = (row.value / row.maxValue) * 100;
    if (percentage > 100) {
      return 100;
    }
    return percentage;
  }
  return 0;
}

const ytdInforceData = computed(() => {
  const yearToDatePlacedCasesGoal = Math.round(
    goals.value.yearToDatePlacedCases * 1.3
  );

  return [
    // YTD
    {
      label: "Cases",
      minValue: 0,
      maxValue: yearToDatePlacedCasesGoal,
      minText: "0",
      maxText: yearToDatePlacedCasesGoal.toString(),
      value: goals.value.yearToDatePlacedCases
    },
    {
      label: "Production Credit",
      minValue: 0,
      maxValue: 150000,
      minText: "$0",
      maxText: "$150,000",
      value: currencyFormat(
        Math.round(goals.value.activeAllProductionCredit),
        0
      )
    },
    {
      label: "Life Target Premium",
      minValue: 0,
      maxValue: 60000,
      minText: "$0",
      maxText: "$60,000",
      value: currencyFormat(
        Math.round(goals.value.activeLifeProductionCredit),
        0
      )
    },
    {
      label: "Annuity Premium",
      minValue: 0,
      maxValue: 800000,

      minText: "$0",
      maxText: "$800,000",
      value: currencyFormat(
        Math.round(goals.value.activeAnnuityProductionCredit),
        0
      )
    },
    {
      label: "Long Term Care Premium",
      minValue: 0,
      maxValue: 25000,

      minText: "$0",
      maxText: "$25,000",
      value: currencyFormat(
        Math.round(goals.value.activeLtcProductionCredit),
        0
      )
    },
    {
      label: "Disability Premium",
      minValue: 0,
      maxValue: 25000,

      minText: "$0",
      maxText: "$25,000",
      value: currencyFormat(
        Math.round(goals.value.activeDisabilityProductionCredit),
        0
      )
    }
  ];
});

const pendingData = computed(() => {
  const pendingCasesGoal = Math.round(goals.value.pendingCases * 1.3);
  return [
    // Pending
    {
      label: "Cases",
      minValue: 0,
      maxValue: pendingCasesGoal,
      minText: "0",
      maxText: pendingCasesGoal.toString(),
      value: goals.value.pendingCases
    },
    {
      label: "Production Credit",
      minValue: 0,
      maxValue: 150000,
      minText: "$0",
      maxText: "$150,000",
      value: currencyFormat(
        Math.round(goals.value.pendingAllProductionCredit),
        0
      )
    },
    {
      label: "Life Target Premium",
      minValue: 0,
      maxValue: 60000,
      minText: "$0",
      maxText: "$60,000",
      value: currencyFormat(
        Math.round(goals.value.pendingLifeProductionCredit),
        0
      )
    },
    {
      label: "Annuity Premium",
      minValue: 0,
      maxValue: 800000,
      minText: "$0",
      maxText: "$800,000",
      value: currencyFormat(
        Math.round(goals.value.pendingAnnuityProductionCredit),
        0
      )
    },
    {
      label: "Long Term Care Premium",
      minValue: 0,
      maxValue: 25000,
      minText: "$0",
      maxText: "$25,000",
      value: currencyFormat(
        Math.round(goals.value.pendingLtcProductionCredit),
        0
      )
    },
    {
      label: "Disability Premium",
      minValue: 0,
      maxValue: 25000,
      minText: "$0",
      maxText: "$25,000",
      value: currencyFormat(
        Math.round(goals.value.pendingDisabilityProductionCredit),
        0
      )
    }
  ];
});

const allTimeData = computed(() => {
  const placedCasesGoal = Math.round(goals.value.placedCases * 1.3);
  return [
    {
      label: "Inforce Cases",
      minValue: 0,
      maxValue: placedCasesGoal,
      minText: "0",
      maxText: placedCasesGoal.toString(),
      value: Math.round(goals.value.placedCases)
    },
    {
      label: "Placement Ratio",
      minValue: 0,
      maxValue: 100,
      minText: "0%",
      maxText: "100%",
      value: goals.value.placementRatio
    }
  ];
});

const tabs = computed(() => {
  return [
    {
      title: "All Time",
      values: allTimeData.value
    },
    {
      title: "Pending",
      values: pendingData.value
    },
    {
      title: "YTD Inforce",
      values: ytdInforceData.value
    }
  ];
});
</script>
