<template>
  <v-card>
    <v-card-title> Add Advisor </v-card-title>
    <v-card-text>
      <advisor-search
        v-model="advisor"
        label="Advisor"
        autofocus
        data-testid="advisor-search"
        :success="advisorValidation.success"
        :error-messages="advisorValidation.errorMessages"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn outlined class="text-none" @click="dialog.closeDialog()">
        Cancel
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        data-testid="add-informal-advisor"
        :loading="saving"
        @click="addAdvisor"
      >
        Add
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { useCaseViewStore } from "@/stores/case-view";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import useVuelidate from "@vuelidate/core";
import { ref } from "vue";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();

const caseView = useCaseViewStore();

const advisor = ref(null);
const saving = ref(false);
const v$ = useVuelidate(
  {
    advisor: {
      required: v => Boolean(v?.id)
    }
  },
  {
    advisor
  },
  {
    $autoDirty: true,
    $scope: null
  }
);

const advisorValidation = computedValidation(v$.value.advisor, {
  required: "Required"
});

async function addAdvisor() {
  const isValid = v$.value.$validate();
  if (!isValid) return;

  const exists = caseView.room.some(
    i => i.type === advisor.value.type && i.id === advisor.value.id
  );

  if (exists) return addExistingAdvisor();
  return addNewAdvisor();
}

async function addNewAdvisor() {
  try {
    saving.value = true;
    const access = await caseView.createAccess(advisor.value, { agent: true });
    caseView.room.push(access);
    dialog.closeDialog();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}

function getAdvisorIndex() {
  return caseView.room.findIndex(
    i => i.type === advisor.value.type && i.id === advisor.value.id
  );
}

async function addExistingAdvisor() {
  let index = getAdvisorIndex();
  const existing = caseView.room[index];
  try {
    saving.value = true;
    caseView.room[index].agent = true;
    await caseView.updateAccess(existing.caseAccessId, "agent");
    dialog.closeDialog();
  } catch (e) {
    // refresh index in case of change
    index = getAdvisorIndex();
    caseView.room[index].agent = false;
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}
</script>
