export const advisorEmailOptions = {
  appointments: "Appointments",
  cases: "Cases Under $5,000 of Production Credit",
  high_premium_cases: "Cases Over $5,000 of Production Credit",
  case_summaries: "Case Summaries",
  iul: "Indexed Products",
  marketing: "Marketing",
  pay_periods: "Payments",
  quick_quotes: "Quick Quotes",
  electronic_applications: "Quote & Apply eApps",
  quotes: "Quotes",
  quote_summaries: "Quote Summaries"
};

export const LEGAL_STRUCTURES = [
  "C Corporation",
  "General Partnership",
  "LLC",
  "LLP",
  "S Corporation",
  "Sole Proprietor"
];

export const genders = ["Male", "Female"];

export const licenseStatusItems = [
  {
    text: "I am not securities licensed",
    value: false
  },
  {
    text: "I am securities licensed",
    value: true
  }
];

export const commissionPaidToItems = [
  {
    text: "Myself, I am not Assigning Commissions",
    value: "Self"
  },
  {
    text: "A Licensed Agency",
    value: "Agency"
  },
  {
    text: "A Licensed Agent",
    value: "Agent"
  }
];

export const ADVISOR_CATEGORIES = [
  "Affinity Group",
  "Association",
  "Bank",
  "Credit Union",
  "IMO - Life",
  "IMO - P&C",
  "Integration Partner - Employee Benefits",
  "Integration Partner - Financial Services",
  "Integration Partner - P&C",
  "Lender",
  "Multi Credit Union CUSO",
  "Organization with over 5,000 accounts"
];
