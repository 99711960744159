<template>
  <v-form>
    <v-row dense>
      <v-col cols="12" md="4" sm="6">
        <invite-type-card
          title="Invite an Assistant"
          icon="$mdi-account-network"
          data-testid="invite-assistant"
          :subtitle="assistantSubtext"
          :active="isAssistant"
          @click="type = 'assistant'"
        />
      </v-col>

      <v-col cols="12" md="4" sm="6">
        <invite-type-card
          :title="downlineTitle"
          icon="$mdi-account-supervisor"
          data-testid="invite-agent"
          :subtitle="downlineSubtext"
          :active="isDownline"
          @click="type = 'downline'"
        />
      </v-col>

      <v-col cols="12" md="4">
        <invite-type-card
          title="Invite Many Downline Agents"
          icon="$mdi-account-multiple-plus"
          data-testid="invite-referring"
          subtitle="Use a public sign-up link for mass signups"
          :active="isReferring"
          @click="type = 'referring'"
        />
      </v-col>
      <v-col v-if="type" cols="12" class="my-6">
        <v-divider />
      </v-col>
      <v-col v-if="type" cols="12">
        <v-fade-transition mode="out-in">
          <invite-assistant key="assistant" v-if="isAssistant" />
          <invite-agent key="agent" v-else-if="isDownline" />
          <invite-many
            key="refer"
            v-else-if="isReferring"
            @select-type="type = $event"
          />
        </v-fade-transition>
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup>
import InviteTypeCard from "@/components/advisors/new-advisor/InviteTypeCard.vue";
import InviteAssistant from "@/components/advisors/new-advisor/InviteAssistant.vue";
import InviteMany from "@/components/advisors/new-advisor/InviteMany.vue";
import InviteAgent from "@/components/advisors/new-advisor/InviteAgent.vue";
import { useUserStore } from "@/stores/user";
import { computed, shallowRef } from "vue";
const type = shallowRef(null);

const isAssistant = computed(() => type.value === "assistant");
const isReferring = computed(() => type.value === "referring");
const isDownline = computed(() => type.value === "downline");

const user = useUserStore();

const assistantSubtext = computed(() =>
  user.isGroupOne
    ? "Create an account that can access your eapps, Cases, Appointments, etc."
    : "Create an account with access to an agent"
);

const downlineTitle = computed(() =>
  user.isGroupOne ? "Invite a Downline Agent" : "Invite an Agent"
);
const downlineSubtext = computed(() =>
  user.isGroupOne
    ? "Create an account that you have access to and receive the difference between your commission level and theirs"
    : "Create a new agent"
);
</script>
