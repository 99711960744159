<template>
  <v-card v-cloak>
    <close-button @click="dialog.closeDialog()" style="z-index: 3" />
    <v-card-title class="px-6 mb-4">
      {{ title }}
    </v-card-title>
    <v-card-text class="py-3">
      <v-text-field
        v-if="documentId"
        v-model="name"
        label="Name"
        outlined
        dense
        data-testid="document-name"
        :success="nameValidation.success"
        :error-messages="nameValidation.errorMessages"
      />
      <file-drag-and-drop
        v-if="!documentId"
        v-model="file"
        data-testid="file"
        :multiple="multiple"
        :success="fileValidation.success"
        :error-messages="fileValidation.errorMessages"
      />
      <v-select
        v-if="categories.length"
        v-model="category"
        class="mt-4"
        label="Category"
        outlined
        dense
        data-testid="category"
        :items="categories"
        :success="categoryValidation.success"
        :error-messages="categoryValidation.errorMessages"
      />
      <v-alert
        v-if="showCategoryWarning"
        type="warning"
        data-testid="multiple-alert"
      >
        All files will be registered as {{ categoryText }}, please ensure this
        is correct.
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="text-none" outlined @click="dialog.closeDialog()">
        Cancel
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        data-testid="upload-file-confirm"
        :loading="saving"
        @click="uploadFile"
      >
        {{ props.documentId ? "Save" : "Upload" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import CloseButton from "@/dialogs/CloseButton.vue";
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";

import { parseErrorMessage, validationComputeV2 } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

import { computed, defineProps, ref } from "vue";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  title: String,
  accept: String,
  multiple: Boolean,
  categories: {
    type: Array,
    default: () => []
  },
  documentId: {
    type: [Number, String],
    required: false,
    default: 0
  },
  documentCategoryValue: {
    type: String,
    required: false,
    default: ""
  },
  documentNameValue: {
    type: String,
    required: false,
    default: ""
  },
  func: {
    type: Function,
    required: true
  }
});

const name = ref("");
const category = ref(undefined);
const file = ref(null);
const saving = ref(false);
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const v$ = useVuelidate(
  {
    name: {
      required: (v, vm) => Boolean(!vm.documentId || v)
    },
    file: {
      required: (v, vm) => Boolean(vm.documentId || v),
      validSize: (v, vm) => {
        if (vm.documentId) return true;
        if (Array.isArray(v)) return !v.some(file => file?.size === 0);
        return v?.size > 0;
      }
    },
    category: {
      required: (v, vm) => {
        if (!vm.categories.length) return true;
        const compareValues = Object.keys(vm.categories[0]).includes("value");
        return vm.categories.some(c => {
          if (compareValues) return c.value === v;
          return c === v;
        });
      }
    }
  },
  {
    categories: props.categories,
    documentId: props.documentId,
    name,
    file,
    category
  }
);

const categoryText = computed(() => {
  if (!props.categories.length) return;
  return props.categories.find(c => {
    if (typeof c === "string") return c === category.value;
    return c.value === category.value;
  })?.text;
});

const showCategoryWarning = computed(() => {
  if (!props.multiple || category.value === undefined) return false;
  if (!Array.isArray(file.value)) return false;
  return file.value.length > 1;
});

const nameValidation = computed(() => {
  return validationComputeV2(v$.value.name, [
    { key: "required", message: "Required" }
  ]);
});

const fileValidation = computed(() => {
  return validationComputeV2(v$.value.file, [
    { key: "required", message: "Required" }
  ]);
});

const categoryValidation = computed(() => {
  return validationComputeV2(v$.value.category, [
    { key: "required", message: "Required" }
  ]);
});

async function uploadFile() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  try {
    saving.value = true;
    await props.func({
      category: category.value,
      file: file.value,
      name: name.value
    });
    dialog.closeDialog();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}

function initialize() {
  name.value = props.documentNameValue;
  category.value = props.documentCategoryValue;
}

if (props.documentId) initialize();
</script>
