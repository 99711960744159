<template>
  <v-row class="ma-0" justify="center">
    <v-col
      cols="12"
      justify="center"
      align="center"
      style="position: relative; overflow: auto"
      :id="parentCanvasId"
    >
      <high-dpi-canvas
        v-if="loaded"
        :height="height"
        :width="parentWidth"
        :id="canvasId"
        :style="{ filter: $vuetify.theme.dark ? 'invert(1)' : 'invert(0)' }"
        @redraw="paintCurrentPage"
        @ready="paintCurrentPage"
      />

      <v-col
        v-if="$slots['edit-field-overlay']"
        style="width: 100%; position: absolute; z-index: 2; inset: 0"
      >
        <slot name="edit-field-overlay" />
      </v-col>
      <v-col style="width: 100%; position: absolute; z-index: 1; inset: 0">
        <slot name="pdf-overlay" />
      </v-col>
    </v-col>
    <v-col cols="12">
      <v-row class="ma-0">
        <v-btn
          @click="changePage(-1)"
          class="text-none mr-1"
          small
          :disabled="!canGoPrevious"
        >
          Previous Page
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="canDelete"
          class="text-none mr-1"
          small
          color="error"
          @click="$emit('delete-page')"
        >
          Delete Page
        </v-btn>
        <v-spacer />
        <v-btn
          @click="changePage(1)"
          class="text-none"
          small
          :disabled="!canGoNext"
        >
          Next Page
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import HighDpiCanvas from "@/components/shared/HighDpiCanvas.vue";
import { mapActions, storeToRefs } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useFormMappingStore } from "@/stores/form-mapping";
import { useSnackbarStore } from "@/stores/snackbar";
import { getHttpClient } from "@/http-client";
export default {
  name: "PdfViewer",
  components: {
    HighDpiCanvas
  },
  props: {
    formId: [String, Number],
    canDelete: Boolean
  },
  setup({ formId }) {
    const formStore = useFormMappingStore(formId);
    const { pdfSvgUrl, currentPage, window, viewport } = storeToRefs(formStore);
    return { pdfSvgUrl, currentPage, window, viewport };
  },
  data() {
    return {
      loaded: false,
      pages: {},
      resizerRef: null,
      resizeTimer: null,
      parentWidth: 0,
      isMounted: false
    };
  },
  mouted() {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  computed: {
    canvasId() {
      return `pdf-viewer-${this.formId}`;
    },
    parentCanvasId() {
      return `parent-${this.canvasId}`;
    },
    canGoNext() {
      return this.currentPage < Object.keys(this.pages).length;
    },
    canGoPrevious() {
      return this.currentPage > 1;
    },
    scale() {
      return +(this.parentWidth / this.viewport.width).toFixed(2);
    },
    height() {
      return this.viewport.height * this.scale;
    }
  },
  watch: {
    currentPage() {
      if (!this.isMounted) return;
      this.updatePage();
    }
  },
  mounted() {
    this.$nextTick(() => (this.isMounted = true));
    this.fetchPdf();
    this.instantiateResizeObserver();
  },
  beforeDestroy() {
    if (this.resizerRef?.disconnect) this.resizerRef.disconnect();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    changePage(offset) {
      this.currentPage += offset;
      this.updatePage();
    },
    updatePage() {
      this.calcCurrentViewport();
      this.paintCurrentPage();
    },
    getCurrentCanvas() {
      return document.getElementById(this.canvasId);
    },
    async fetchPdf() {
      try {
        const { data } = await getHttpClient().get(this.pdfSvgUrl);
        for (let i = 0; i < data.length; i++) {
          const image = await this.initPage(data[i]);
          this.$set(this.pages, i + 1, {
            image,
            dimensions: {
              width: image.width,
              height: image.height
            }
          });
        }
        this.loaded = true;
        this.calcCurrentViewport();
        this.$emit("page-count", Object.keys(this.pages).length);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      }
    },
    initPage(page) {
      return new Promise(resolve => {
        const image = new Image();
        image.onload = () => {
          resolve(image);
        };
        image.src = page;
      });
    },
    calcCurrentViewport() {
      const { height, width } = this.pages[this.currentPage].dimensions;
      const ptToPxConversion = 0.75;
      this.viewport.width = width * ptToPxConversion;
      this.viewport.height = height * ptToPxConversion;
      this.$emit("viewport-dimensions", this.viewport);
    },
    paintCurrentPage() {
      const { image } = this.pages[this.currentPage];
      const canvas = this.getCurrentCanvas();
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, this.parentWidth, this.height);
    },
    instantiateResizeObserver() {
      const el = document.getElementById(this.parentCanvasId);
      this.resizerRef = new ResizeObserver(this.handleWindowDimensions);
      this.resizerRef.observe(el);
    },
    handleWindowDimensions() {
      const el = document.getElementById(this.parentCanvasId);
      this.parentWidth = el.clientWidth;
      this.window.width = this.parentWidth;
      this.window.height = this.height;
    }
  }
};
</script>

<style lang="scss">
.svg-container {
  line-height: 0;
  canvas {
    border: 1px solid lightgrey;
    width: 100%;
    height: unset;
  }
}
</style>
