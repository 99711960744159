<template>
  <v-card flat tile>
    <v-card-title>E&O</v-card-title>
    <v-card-subtitle>Modify your Errors and Omissions Policy</v-card-subtitle>
    <v-card-text>
      <v-row dense>
        <v-col v-if="agent?.id" cols="12">
          <advisor-eo
            data-testid="agent-eo"
            :readonly="props.readonly"
            :name="agent.name"
            :eo="agent.eo"
            :destroy="agent.destroyEo"
            @validate="validate"
          />
        </v-col>
        <v-col v-if="agency?.id" cols="12">
          <v-card flat color="section">
            <advisor-eo
              data-testid="agency-eo"
              :readonly="props.readonly"
              :name="agency.name"
              :eo="agency.eo"
              :destroy="agency.destroyEo"
              @validate="validate"
            />
          </v-card>
        </v-col>
        <v-col v-if="!readonly" cols="12">
          <v-btn
            color="accent"
            class="text-none"
            data-testid="add-new-eo"
            @click="showAddNewEo"
          >
            <v-icon class="mr-1">$mdi-plus</v-icon> Add New E&O
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script setup>
import AdvisorEo from "@/components/settings/eo/AdvisorEo.vue";

import { useDialogStore } from "@/stores/dialog";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { ref, defineProps, watch, computed, onMounted, defineEmits } from "vue";
import { useSettingsViewStore } from "@/stores/settings-view";
import { storeToRefs } from "pinia";
const props = defineProps({
  readonly: Boolean,
  module: {
    type: String,
    required: true,
    validator: v => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(v)
  }
});

const agent = ref(null);
const agency = ref(null);
if (props.module === AGENT_SETTINGS) {
  agent.value = useAgentSettingsStore();

  const { assignment } = storeToRefs(agent.value);

  const setAgency = (initialize = false) => {
    const isSigner =
      assignment.value.type === "Agency" &&
      agent.value.agencies[assignment.value.id];
    if (isSigner) {
      agency.value = useAgencySettingsStore(assignment.value.id);
    } else {
      agency.value = null;
    }
    if (!initialize) validate();
  };

  watch(
    () => assignment.value.id,
    () => setAgency()
  );
  setAgency(true);
} else {
  const settings = useSettingsViewStore();
  agency.value = useAgencySettingsStore(settings.currentAgencyId);
}

const validAgentEo = computed(() => {
  if (!agent.value?.id) return true;
  return Boolean(agent.value.eo?.id && agent.value.eo?.expired === false);
});

const validAgencyEo = computed(() => {
  if (!agency.value?.id) return true;
  return Boolean(agency.value?.eo?.id && agency.value?.eo?.expired === false);
});

function validate() {
  const valid = validAgencyEo.value && validAgentEo.value;
  if (agency.value?.id) agency.value.setValidPage({ page: "agency-eo", valid });
  if (agent.value?.id) agent.value.setValidPage({ page: "eo", valid });
}

function showAddNewEo() {
  if (props.module === AGENT_SETTINGS) showAgentAddNewEo();
  else showAgencyAddNewEo();
}

const dialog = useDialogStore();

async function showAgentAddNewEo() {
  let agencyAssignment;
  if (agent.value.assignment?.type === "Agency") {
    agencyAssignment = agent.value.assignment;
  }

  const policyHolderOptions = [
    {
      text: agent.value.name,
      type: "Agent",
      id: agent.value.id,
      selectedByDefault: true
    }
  ];
  Object.values(agent.value.agencies).forEach(signingAgency => {
    let selectedByDefault = false;
    if (agencyAssignment?.id) {
      selectedByDefault = agencyAssignment.id === signingAgency.id;
    }
    policyHolderOptions.push({
      text: signingAgency.name,
      type: signingAgency.type,
      id: signingAgency.id,
      selectedByDefault
    });
  });

  const res = await dialog.showDialog({
    component: "AdvisorEoDialog",
    scrollable: true,
    policyHolderOptions,
    agencyAssignment
  });

  if (res.created) emit("refresh");
}

async function showAgencyAddNewEo() {
  const policyHolderOptions = [
    {
      text: agency.value.name,
      id: agency.value.id,
      type: "Agency",
      selectedByDefault: true
    }
  ];

  Object.values(agency.value.connections).forEach(option => {
    if (option.signer) {
      policyHolderOptions.push({
        text: option.connectedAdvisorName,
        type: option.connectedAdvisorType,
        id: option.connectedAdvisorId,
        selectedByDefault: true
      });
    }
  });

  const res = await dialog.showDialog({
    component: "AdvisorEoDialog",
    scrollable: true,
    policyHolderOptions
  });

  if (res.created) emit("refresh");
}

watch(validAgencyEo, () => validate());
watch(validAgentEo, () => validate());

validate();

const emit = defineEmits(["mounted", "refresh"]);
onMounted(() => emit("mounted"));
</script>
