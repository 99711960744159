<template>
  <v-data-table :items="items" :headers="headers">
    <template #top>
      <v-btn @click="addSmokerChart" class="text-none ma-3" color="accent">
        <v-icon>$mdi-plus</v-icon>
        Add Smoker Chart
      </v-btn>
    </template>

    <template #[`item.actions`]="{ item }">
      <v-btn
        @click="remove(item)"
        class="text-none"
        icon
        :loading="item.additional.tableHelpers.deleting"
      >
        <v-icon color="error">$mdi-delete</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";
import TableHeader from "@/classes/data-table/TableHeader";
import Table from "@/classes/data-table/Table";
import { removeProductSmokerChart } from "@/api/product-smoker-chart.service";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  props: {
    product: Object
  },
  mixins: [GenericTableMixin],
  data() {
    const headers = [
      new TableHeader({
        text: "Smoker Chart",
        value: "smokerChart",
        map: "smokerChart.name",
        ...TableHeader.IS_QUERY_FILTER
      }),
      new TableHeader({
        text: "Actions",
        value: "actions",
        map: "actions"
      })
    ];

    const table = new Table({
      rawItems: this.product.productSmokerCharts,
      headers
    });
    return {
      table
    };
  },
  computed: {
    headers() {
      return this.table.headers;
    },
    items() {
      return this.tableMap(this.table.rawItems, this.table.headers);
    }
  },

  methods: {
    ...mapActions(useSnackbarStore, ["showSuccessSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    remove(item) {
      item.additional.tableHelpers.deleting = true;
      removeProductSmokerChart(item.additional.id)
        .then(() => {
          const index = this.table.rawItems.findIndex(
            val => val.id === item.additional.id
          );
          this.table.rawItems.splice(index, 1);
          this.showSuccessSnackbar({
            message: "Product Smoker Chart has been deleted",
            timeout: 6000
          });
        })
        .finally(() => {
          item.additional.tableHelpers.deleting = false;
        });
    },
    addSmokerChart() {
      this.showDialog({
        component: "AddSmokerChartDialog",
        product: this.product
      }).then(result => {
        if (!result.productSmokerChart) {
          return;
        }
        this.table.rawItems.push(result.productSmokerChart);
        this.showSuccessSnackbar({
          message: "Smoker Chart has been added",
          timeout: 6000
        });
      });
    }
  }
};
</script>
