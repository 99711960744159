<template>
  <v-card flat>
    <v-card-title> Commissions Paid To </v-card-title>
    <v-card-subtitle> Who gets paid for this eApp? </v-card-subtitle>
    <v-card-text>
      <v-checkbox
        v-model="overrideCommissionsPaidTo"
        class="mt-1"
        data-testid="override-commissions-paid-to"
        :disabled="disabled || saving"
        @change="handleChange"
      >
        <template #label>
          <div>
            Override Default Commissions. Based on its website, this eApp is
            assigning commissions to
            {{ approvedDomain.appointmentAssignmentOwnable.name }}.
            <router-link
              :to="{
                name: 'ApprovedDomains',
                params: {
                  id: approvedDomain.id
                },
                query: {
                  'scroll-to': 'commissions-assigned-to',
                  page: 'commissions'
                }
              }"
            >
              Change Default Commissions
            </router-link>
          </div>
        </template>
      </v-checkbox>
      <v-fade-transition mode="out-in">
        <advisor-search
          v-if="overrideCommissionsPaidTo"
          v-model="commissionsPaidTo"
          label="Commissions Assigned To"
          data-testid="advisor-assigned-commissions"
          :clearable="false"
          :success="Boolean(commissionsPaidTo?.id)"
          :disabled="disabled || saving"
          style="max-width: 35rem"
          @input="setCommissionsPaidTo"
        >
          <template #append-outer>
            <active-save-indicator
              :controller="savingBuffer.commissionsPaidTo.controller.value"
            />
          </template>
        </advisor-search>
      </v-fade-transition>
    </v-card-text>
  </v-card>
</template>

<script>
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { mapActions, mapState, mapWritableState } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useEappViewStore } from "@/stores/eapp-view";
import { useActiveSave } from "@/composables/active-save.composable";
export default {
  components: {
    AdvisorSearch,
    ActiveSaveIndicator
  },
  setup() {
    const savingBuffer = {
      commissionsPaidTo: useActiveSave()
    };

    return {
      savingBuffer
    };
  },
  data: () => ({
    overrideCommissionsPaidTo: false,
    isMounted: false,
    saving: false
  }),
  mounted() {
    this.$nextTick(() => (this.isMounted = true));
  },
  computed: {
    ...mapState(useEappViewStore, ["approvedDomain", "allowCommissionChange"]),
    ...mapWritableState(useEappViewStore, ["commissionsPaidTo"]),
    disabled() {
      return !this.allowCommissionChange;
    }
  },
  created() {
    this.overrideCommissionsPaidTo = Boolean(this.commissionsPaidTo?.id);
  },
  methods: {
    ...mapActions(useEappViewStore, ["updateEapp"]),
    ...mapActions(useSnackbarStore, ["showSuccessSnackbar"]),
    async setCommissionsPaidTo() {
      this.saving = true;

      await this.savingBuffer.commissionsPaidTo.update(() =>
        this.updateEapp("commissionsPaidTo")
      );

      if (this.savingBuffer.commissionsPaidTo.controller.value.error)
        return false;
      else {
        let message = `Successfully reverted commissions to ${this.approvedDomain.appointmentAssignmentOwnable.name}`;
        if (this.commissionsPaidTo.name) {
          message = `Successfully assigned commissions to ${this.commissionsPaidTo.name}`;
        }
        this.showSuccessSnackbar({ message, timeout: 10000 });
      }

      this.saving = false;
      return true;
    },
    async handleChange() {
      if (!this.overrideCommissionsPaidTo && this.commissionsPaidTo?.id) {
        this.commissionsPaidTo.id = null;
        this.commissionsPaidTo.type = null;
        this.commissionsPaidTo.name = null;

        const res = await this.setCommissionsPaidTo();

        if (!res) this.overrideCommissionsPaidTo = true;
      }
    }
  }
};
</script>
