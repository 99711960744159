<template>
  <v-row dense>
    <v-col cols="12">
      <h2 class="font-weight-light">Create a new Quote</h2>
    </v-col>
    <v-col cols="12">
      <dashboard />
    </v-col>
    <v-col cols="12" class="pt-5">
      <h2 class="font-weight-light">View an Existing Quote</h2>
    </v-col>
    <v-col cols="12">
      <v-card class="pb-3">
        <v-row class="ma-0 pa-3" align="center">
          <v-tabs style="width: unset" v-model="value" show-arrows>
            <template v-for="tab in tabs">
              <v-tab
                v-if="tab.to"
                :to="tab.to"
                class="text-none"
                :key="tab.title"
              >
                <v-icon class="inherit-color">{{ tab.icon }}</v-icon>
                {{ tab.title }}
              </v-tab>
              <v-tab v-else class="text-none" :key="tab.title">
                <v-icon class="inherit-color">{{ tab.icon }}</v-icon>
                {{ tab.title }}
              </v-tab>
            </template>
          </v-tabs>
          <generic-table-stats
            v-if="tabs[value].stats"
            :stats="tabs[value].stats"
            end
          />
          <v-btn
            v-if="activeTab.createRoute"
            icon
            :to="{ name: activeTab.createRoute }"
          >
            <v-icon>$mdi-plus-circle</v-icon>
          </v-btn>
          <v-btn
            v-if="activeTab.emailData"
            icon
            @click="activeTab.emailData"
            :loading="activeTab.emailingData"
          >
            <v-icon>$mdi-file-export</v-icon>
          </v-btn>
          <v-btn icon @click="activeTab.getData()">
            <v-icon>$mdi-refresh</v-icon>
          </v-btn>
        </v-row>

        <v-tabs-items v-model="value" touchless>
          <template v-for="tab in tabs">
            <v-tab-item v-if="tab.component" :key="tab.component">
              <component
                :is="tab.component"
                simplified-header
                @get-data="val => (tab.getData = val)"
                @email-data="val => (tab.emailData = val)"
                @stats="val => (tab.stats = val)"
              />
            </v-tab-item>
          </template>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Dashboard from "@/components/quotes/QuotingOptions.vue";
import QuotesTable from "@/components/quotes/QuotesTable.vue";
import ImpairedRiskQuotesTable from "@/components/impaired-risk-quote/ImpairedRiskQuotesTable.vue";
import GenericTableStats from "@/components/shared/data-table/GenericTableStats.vue";
import { useHead } from "@unhead/vue";
export default {
  setup() {
    useHead({ title: "Quoting" });
  },
  name: "QuotingView",
  components: {
    ImpairedRiskQuotesTable,
    Dashboard,
    QuotesTable,
    GenericTableStats
  },
  data() {
    return {
      value: 0,
      tabs: [
        {
          title: "Quote Requests",
          component: "QuotesTable",
          createRoute: "CreateQuote",
          getData: () => {},
          stats: [],
          icon: "$mdi-calculator"
        },
        {
          title: "Impaired Risk Quotes",
          component: "ImpairedRiskQuotesTable",
          createRoute: "ImpairedRiskQuoteCreate",
          getData: () => {},
          stats: [],
          icon: "$mdi-stethoscope"
        },
        {
          title: "Quote & Apply eApps",
          to: { name: "QuoteAndApply", query: { view: "list" } },
          icon: "$mdi-rocket-launch"
        }
      ]
    };
  },
  computed: {
    activeTab() {
      return this.tabs[this.value];
    }
  }
};
</script>
