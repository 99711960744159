export const INFORMAL_STATUSES = [
  "Informal Entered",
  "Gathering Medical Information",
  "Awaiting Carrier Offers",
  "All Offers In",
  "Informal Formalized",
  "Informal Closed"
];
export default function InformalInquiry(model = {}) {
  return {
    agent: model?.agent,
    additionalAgents: model?.additionalAgents || {},
    carriers: model?.carriers || [],
    status: model?.status || null,
    faceAmount: model?.faceAmount || null,
    state: model?.state || null,
    party: model?.party || null,

    toCreateRequest() {
      return {
        case: {
          face_amount: this.faceAmount,
          line: "informal",
          party_type: "SSN",
          policy_number: "Informal",
          product_id: 389,
          state: this.state,
          status: this.status,
          case_accesses_attributes: [
            {
              agent: 1,
              ownable_id: this.agent.id,
              ownable_type: this.agent.type
            },
            ...Object.keys(this.additionalAgents).map(key => ({
              agent: 1,
              ownable_id: this.additionalAgents[key].id,
              ownable_type: this.additionalAgents[key].type
            }))
          ],
          informal_offers_attributes: this.carriers.map(id => ({
            name: "Pending",
            carrier_id: id,
            status: this.status
          })),
          roles_attributes: [
            {
              contract_party_id: this.party.id,
              role: "Insured"
            }
          ]
        }
      };
    }
  };
}
