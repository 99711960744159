<template>
  <div>
    <h2 class="mb-3 font-weight-light">Guides, Tutorials, and News</h2>

    <v-row class="ma-0" align="center">
      <v-text-field
        v-model="searchText"
        data-lpignore="true"
        placeholder="Search for an article"
        hide-details
        prepend-inner-icon="$mdi-magnify"
        rounded
        outlined
        flat
        data-testid="article-search"
        :loading="loadingArticles"
        @input="debouncedArticleSearch"
      />
      <v-btn icon color="accent" class="mx-1" @click="copyDialog">
        <v-icon>$mdi-share</v-icon>
      </v-btn>
      <v-btn
        v-if="user.isGroupTwoPlus"
        icon
        color="primary"
        class="mx-1"
        data-testid="create-article-button"
        @click="showArticleCreateForm"
      >
        <v-icon>
          {{ mdiPlus }}
        </v-icon>
      </v-btn>
    </v-row>
    <v-row dense v-if="articles.length" align="center">
      <v-col
        v-for="(article, index) in articles"
        cols="12"
        :data-testid="`article-${article.id}`"
        :key="`${article.name}-${index}`"
      >
        <v-card class="ma-1 pa-2" :to="article.routerLink" hover>
          <v-row class="ma-0" align="center">
            <div class="px-3">
              <v-icon size="large"> $mdi-file-document</v-icon>
            </div>
            <div>
              <v-card-title class="video-title pb-0">
                {{ article.name }}
              </v-card-title>
              <v-card-subtitle class="pb-0 mt-0">
                <h4 class="inline-block" style="font-weight: 400">
                  {{ article.author }}
                </h4>
                ·
                <p class="grey--text inline-block subtitle mb-0">
                  {{ article.createdStr }}
                </p>
              </v-card-subtitle>
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <div v-else-if="!articles.length && !loadingArticles">
      No articles found.
    </div>
    <v-skeleton-loader v-else type="article@4" />
  </div>
</template>

<script setup>
import HelpCenterCreateNewArticleDialog from "@/components/help-center/HelpCenterCreateNewArticleDialog.vue";

import { getArticles } from "@/api/help-desk.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiPlus } from "@mdi/js";
import { computed, markRaw, ref } from "vue";
import { parseErrorMessage } from "@/util/helpers";
import { useRouter } from "@/composables/compatible.composables";

const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const router = useRouter();

const articles = ref([]);
const loadingArticles = ref(false);
const searchText = ref("");

const currentQuery = computed(() => {
  if (!router) return null;
  return router.currentRoute.query?.q;
});

function copyDialog() {
  dialog.showDialog({
    component: "CopyDialog",
    text: window.location.href,
    title: "Share this Help Center Search",
    subtitle: "As easy as copy and send (paste)!"
  });
}
async function fetchArticles() {
  loadingArticles.value = true;
  try {
    const newArticles = await getArticles({
      limit: 10,
      searchText: searchText.value
    });
    articles.value.splice(0, articles.value.length);

    articles.value.push(...mapArticles(newArticles));
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  } finally {
    loadingArticles.value = false;
  }
}

function mapArticles(newArticles) {
  return newArticles.map(article => {
    let routeName = "HelpCenterArticleViewer";
    let prefix = "";
    if (!article.published && user.isGroupTwoPlus) {
      routeName = "HelpCenterArticleEditor";
      prefix = "(Draft) ";
    }
    const routerLink = { name: routeName, params: { id: article.id } };

    return {
      ...article,
      name: `${prefix}${article.name}`,
      routerLink,
      createdStr: article.createdAt.toLocaleDateString()
    };
  });
}

let timer;
function debouncedArticleSearch() {
  if (timer) clearTimeout(timer);
  timer = setTimeout(() => {
    updateQuery();
    fetchArticles();
  }, 500);
}

function setSearchTextFromQuery() {
  if (!currentQuery.value) return;
  searchText.value = router.currentRoute.query.q;
}

function updateQuery() {
  if (!router) return;
  if (currentQuery.value === searchText.value) return;

  let query = {};
  if (searchText.value) query.q = searchText.value;
  router.replace({ query });
}

function showArticleCreateForm() {
  dialog.showDialog({
    component: markRaw(HelpCenterCreateNewArticleDialog),
    title: "Create a New Article"
  });
}

setSearchTextFromQuery();
fetchArticles();
</script>
