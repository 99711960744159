<template>
  <v-col :cols="cols" :xl="xl" :lg="lg" :md="md" :sm="sm" :xs="xs">
    <template v-if="item === 'Statement Date'">
      <span class="grey--text lighten-2"> Statement Date </span>
      <br />
      <span>
        <timestamp-formatter :value="model.statementDate" parser="sole-day" />
      </span>
    </template>
    <template v-else-if="item === 'Statement Type'">
      <span class="grey--text lighten-2"> Statement Type </span>
      <br />
      <span>
        {{ model.statementType }}
      </span>
    </template>
    <template v-else-if="item === 'Premium'">
      <span class="grey--text lighten-2"> Stated Premium </span>
      <br />
      <span>
        <currency-formatter :value="model.premium" />
      </span>
    </template>
    <template v-else-if="item === 'Premium Sum'">
      <span class="grey--text lighten-2"> Payments Premium Sum </span>
      <br />
      <span>
        <currency-formatter :value="model.premiumSum" />
      </span>
    </template>
    <template v-else-if="item === 'Commission'">
      <span class="grey--text lighten-2"> Stated Commission </span>
      <br />
      <span>
        <currency-formatter :value="model.amount" />
      </span>
    </template>
    <template v-else-if="item === 'Commission Sum'">
      <span class="grey--text lighten-2"> Payments Commission Sum </span>
      <br />
      <span>
        <currency-formatter :value="model.commissionSum" />
      </span>
    </template>
    <template v-else-if="item === 'Status'">
      <span class="grey--text lighten-2"> Status </span>
      <br />
      <span>
        {{ model.status }}
      </span>
    </template>
  </v-col>
</template>

<script>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
export default {
  components: {
    CurrencyFormatter,
    TimestampFormatter
  },
  props: {
    item: String,
    model: Object,
    xl: {
      default: 2
    },
    lg: {
      default: 3
    },
    md: {
      default: 4
    },
    sm: {
      default: 6
    },
    xs: {
      default: 6
    },
    cols: {
      default: 12
    }
  }
};
</script>
