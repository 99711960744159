<template>
  <v-card>
    <v-card-title data-tesid="dialog-title">
      {{ editing ? "Update" : "Add" }} Address History Record
    </v-card-title>
    <v-card-subtitle>
      <template v-if="editing">
        Make any necessary changes to this address history record and then press
        "Save".
      </template>
      <template v-else>
        Enter information about a current or previous address history and then
        press "Create"
      </template>
    </v-card-subtitle>
    <v-card-text id="address-container">
      <v-row dense class="my-0">
        <template v-if="!excludeDates">
          <v-col cols="12" md="6">
            <date-input
              v-model="address.startDate"
              label="When Did You Start Living at This Home Address"
              data-testid="start-date"
              outlined
              dense
              autofocus
              clearable
              :success="startDateValidation.success"
              :error-messages="startDateValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-input
              v-model="address.endDate"
              label="End Date (Leave Blank if Current)"
              data-testid="end-date"
              outlined
              dense
              clearable
              :success="endDateValidation.success && Boolean(address.endDate)"
              :error-messages="endDateValidation.errorMessages"
            />
          </v-col>
        </template>
        <v-col cols="12">
          <basic-address-input
            autofill-label="Physical Address"
            autofill-placeholder="Physical Address"
            data-testid="address-item"
            :street-address-model.sync="address.street_address"
            :state-model.sync="address.state"
            :city-model.sync="address.city"
            :zip-model.sync="address.zip"
            :street-address-validation="streetValidation"
            :state-validation="stateValidation"
            :city-validation="cityValidation"
            :zip-validation="zipValidation"
            @autofill="handleAutofill"
          />
        </v-col>
      </v-row>
      <v-checkbox
        v-if="previousAddress"
        v-model="willUpdatePreviousAddress"
        data-testid="checkbox-update-previous"
        success
        hide-details
      >
        <template #label>
          <template v-if="address.startDate">
            Did you move out of {{ previousAddress }} on
            <timestamp-formatter :value="address.startDate" class="ml-1" />?
          </template>
          <template v-else>
            Did you move out of {{ previousAddress }}?
          </template>
        </template>
      </v-checkbox>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        :disabled="loading"
        @click="closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        :loading="loading"
        data-testid="save-address"
        @click="save"
      >
        <template v-if="editing"> Save </template>
        <template v-else> Create </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import DateInput from "@/components/shared/DateInput.vue";

import { required } from "vuelidate/lib/validators";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";

import { setRawFromAddress, Address } from "@/factories/AddressFactory";

import addMonths from "date-fns/addMonths";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import subYears from "date-fns/subYears";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { isSameDay } from "date-fns";

const easyParse = v => {
  try {
    const parsed = parse(v, "yyyy-MM-dd", new Date());
    if (!isValid(parsed)) return null;
    return parsed;
  } catch (e) {
    return null;
  }
};

export default {
  components: {
    DateInput,
    BasicAddressInput,
    TimestampFormatter
  },
  props: {
    excludeDates: Boolean, // used by business address
    minStartDate: String,
    newAddress: {
      type: Object,
      required: true
    },
    previousAddress: {
      type: String,
      required: false,
      default: ""
    },
    previousAddressId: {
      type: Number,
      required: false,
      default: 0
    },
    updateFunc: Function,
    createFunc: Function
  },
  data() {
    return {
      willUpdatePreviousAddress: Boolean(
        this.previousAddressId && !this.newAddress.id
      ),
      address: Address(this.newAddress),
      editing: Boolean(this.newAddress.id),
      loading: false
    };
  },
  computed: {
    actualMinStartDate() {
      let minStartDate = subYears(new Date(), 150);
      if (this.minStartDate) {
        minStartDate = easyParse(this.minStartDate, "yyyy-MM-dd", new Date());
      }
      return minStartDate;
    },
    startDateValidation() {
      const model = this.$v.address.startDate;
      const success = !model.$invalid;
      const errorMessages = [];
      if (!model.$dirty) return { success, errorMessages };
      else if (!model.required) errorMessages.push("Required");
      else if (!model.minStartDate) {
        const date = format(this.actualMinStartDate, "MM/dd/yyyy");
        errorMessages.push(`Cannot be before ${date}.`);
      } else if (!model.beforeEndDate) {
        const date = format(easyParse(this.address.endDate), "MM/dd/yyyy");
        errorMessages.push(`Must be before ${date}`);
      }
      return { success, errorMessages };
    },
    endDateValidation() {
      const model = this.$v.address.endDate;
      const success = !model.$invalid;
      const errorMessages = [];
      if (!model.$dirty) return { success, errorMessages };
      else if (!model.atMost6Months) {
        errorMessages.push("Cannot be more than 6 months in the future.");
      }
      return { success, errorMessages };
    },
    streetValidation() {
      const model = this.$v.address.street_address;
      const success = !model.$invalid;
      const errorMessages = [];
      if (!model.$dirty) return { success, errorMessages };
      if (!model.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    stateValidation() {
      const model = this.$v.address.state;
      const success = !model.$invalid;
      const errorMessages = [];
      if (!model.$dirty) return { success, errorMessages };
      else if (!model.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    cityValidation() {
      const model = this.$v.address.city;
      const success = !model.$invalid;
      const errorMessages = [];
      if (!model.$dirty) return { success, errorMessages };
      else if (!model.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    zipValidation() {
      const model = this.$v.address.zip;
      const success = !model.$invalid;
      const errorMessages = [];
      if (!model.$dirty) return { success, errorMessages };
      if (!model.validLength) errorMessages.push("Required");
      return { success, errorMessages };
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    handleAutofill({ street_address, state, city, zip }) {
      this.address.street_address = street_address;
      this.address.state = state;
      this.address.city = city;
      this.address.zip = zip;
    },
    goToInvalid() {
      const els = document.getElementsByClassName("v-input error--text");
      if (!els?.length) return;
      this.$vuetify.goTo(els[0], {
        container: "#address-container"
      });
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          this.goToInvalid();
        });
        return;
      }
      this.loading = true;
      try {
        let func = this.createAddress;
        if (this.editing) func = this.updateAddress;
        if (this.willUpdatePreviousAddress) await this.updatePreviousAddress();
        const newAddress = await func();
        this.closeDialog({
          address: newAddress,
          updatedPreviousAddress: this.willUpdatePreviousAddress
        });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    },
    createAddress() {
      return this.createFunc(setRawFromAddress(this.address));
    },
    updateAddress() {
      return this.updateFunc(this.address.id, setRawFromAddress(this.address));
    },
    updatePreviousAddress() {
      return this.updateFunc(this.previousAddressId, {
        end_date: this.address.startDate
      });
    }
  },

  validations() {
    return {
      address: {
        startDate: {
          required: v => this.excludeDates || Boolean(easyParse(v)),
          minStartDate: v => {
            // start date cannot be before the addressable's birthdate if present
            // If addressable's birthdate is blank, start date cannot be more than 150 years ago
            if (this.excludeDates) return true;

            const startDate = easyParse(v, "yyyy-MM-dd", new Date());
            if (!startDate) return false;
            return (
              isAfter(startDate, this.actualMinStartDate) ||
              isSameDay(startDate, this.actualMinStartDate)
            );
          },
          beforeEndDate: (v, vm) => {
            // start date cannot be after end date

            if (this.excludeDates) return true;
            if (!vm.endDate) return true;
            const startDate = easyParse(v, "yyyy-MM-dd", new Date());
            const endDate = easyParse(vm.endDate, "yyyy-MM-dd", new Date());
            if (!endDate || !startDate) return false;
            return isBefore(startDate, endDate);
          }
        },
        endDate: {
          atMost6Months: v => {
            // end date cannot be more than 6 months from now
            if (this.excludeDates) return true;
            if (!v) return true;
            const endDate = easyParse(v, "yyyy-MM-dd", new Date());
            if (!endDate) return false;
            return isBefore(endDate, addMonths(new Date(), 6));
          }
        }, //optional
        street_address: { required },
        state: { required },
        city: { required },
        zip: { validLength: v => `${v}`.length === 5 }
      }
    };
  }
};
</script>
