<template>
  <v-autocomplete
    autocomplete="false"
    outlined
    dense
    return-object
    v-model="question"
    item-text="name"
    no-filter
    hide-no-data
    :clearable="clearable"
    :autofocus="autofocus"
    :placeholder="placeholder"
    :prepend-inner-icon="icon"
    :label="label"
    :search-input.sync="questionSearch"
    :success="success"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    :items="questions"
    :loading="loading"
    :disabled="disabled"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
    @change="$emit('change')"
  >
    <template #append-outer v-if="hasAppendOuterSlot">
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import { getApplicationQuestionsByName } from "@/api/application-question.service";

export default {
  props: {
    success: Boolean,
    errorMessages: Array,
    disabled: Boolean,
    hideDetails: Boolean,
    value: [String, Object],
    smart: Boolean,
    model: String,
    modelId: [String, Number],
    autofocus: Boolean,
    clearable: Boolean
  },
  data() {
    let questions = [];
    let question = null;
    if (this.value && Object.keys(this.value).length && this.value.id) {
      questions = [this.value];
      question = this.value;
    }
    return {
      questionSearch: "",
      questionTimer: null,
      loading: false,
      questions,
      question
    };
  },
  computed: {
    hasAppendOuterSlot() {
      return Boolean(this.$slots["append-outer"]);
    },
    label() {
      return `${this.smart ? "Smart" : ""} Application Question`;
    },
    icon() {
      return this.smart
        ? "$mdi-head-question-outline"
        : "$mdi-comment-question";
    },
    placeholder() {
      return `Search${this.smart ? " Smart" : ""} Application Questions`;
    }
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length || !value?.id) {
        this.question = null;
        this.questions = [];
        return;
      }
      this.question = value;
      this.questions = [value];
    },
    question(value) {
      this.$emit("input", value);
    },
    questionSearch(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }

      if (
        this.questions.some(val => val?.name === value) &&
        this.questions.length === 1
      ) {
        return;
      }

      if (this.questionTimer) {
        clearTimeout(this.questionTimer);
      }

      this.questionTimer = setTimeout(() => {
        this.loading = true;
        getApplicationQuestionsByName(
          value,
          this.smart,
          this.model,
          this.modelId
        )
          .then(questions => (this.questions = questions))
          .finally(() => (this.loading = false));
      }, 200);
    }
  }
};
</script>
