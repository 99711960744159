<template>
  <v-row class="ma-0" align="center">
    <v-col cols="12">
      <v-row>
        <v-spacer />
        <v-btn class="text-none" text color="primary" @click="disableAllEmails">
          Disable All Emails
        </v-btn>
      </v-row>
    </v-col>
    <v-col cols="12" md="6">
      <h3>Appointments</h3>
    </v-col>
    <v-col cols="12" md="6">
      <v-row class="ma-0" align="center" justify-md="end">
        <v-checkbox
          dense
          label="Accessible"
          :input-value="appointments"
          class="mt-0"
          hide-details
          @change="updateAppointments($event)"
        />
        <v-checkbox
          dense
          class="mt-0 ml-6"
          label="Email Notifications"
          :disabled="!appointments"
          :input-value="appointmentsEmails"
          hide-details
          @change="updateAppointmentEmails($event)"
        />
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0">
      <connection-dialog-helper
        resource="Appointments"
        :connection-name="connectionName"
        :advisor-name="advisorName"
        :resource-access="appointments"
        :resource-email-notifications="appointmentsEmails"
      />
    </v-col>
    <v-col cols="12" md="6"> <h3>Cases</h3> </v-col>
    <v-col cols="12" md="6">
      <v-row class="ma-0" align="center" justify-md="end">
        <v-checkbox
          dense
          label="Accessible"
          :input-value="cases"
          class="mt-0"
          hide-details
          @change="updateCases($event)"
        />
        <v-checkbox
          dense
          label="Email Notifications"
          :disabled="!cases"
          :input-value="casesEmails"
          class="mt-0 ml-6"
          hide-details
          @change="updateCaseEmails($event)"
        />
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0">
      <connection-dialog-helper
        resource="Cases"
        :connection-name="connectionName"
        :advisor-name="advisorName"
        :resource-access="cases"
        :resource-email-notifications="casesEmails"
      />
    </v-col>
    <v-col cols="12" md="6">
      <h3>Clients</h3>
    </v-col>
    <v-col cols="12" md="6">
      <v-row class="ma-0" align="center" justify-md="end">
        <v-checkbox
          dense
          class="mt-0"
          label="Accessible"
          :input-value="contractParties"
          hide-details
          @change="updateContractParties($event)"
        />
        <v-checkbox
          dense
          class="mt-0 ml-6"
          label="Email Notifications"
          :disabled="!contractParties"
          :input-value="contractPartiesEmails"
          hide-details
          @change="updateContractPartyEmails($event)"
        />
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0">
      <connection-dialog-helper
        resource="Clients"
        :connection-name="connectionName"
        :advisor-name="advisorName"
        :resource-access="contractParties"
        :resource-email-notifications="contractPartiesEmails"
      />
    </v-col>
    <v-col cols="12" md="6"> <h3>eApps</h3> </v-col>
    <v-col cols="12" md="6">
      <v-row class="ma-0" align="center" justify-md="end">
        <v-checkbox
          dense
          class="mt-0"
          label="Accessible"
          :input-value="eapps"
          hide-details
          @change="updateEapps($event)"
        />
        <v-checkbox
          dense
          class="mt-0 ml-6"
          label="Email Notifications"
          :disabled="!eapps"
          :input-value="eappsEmails"
          hide-details
          @change="updateEappEmails($event)"
        />
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0">
      <connection-dialog-helper
        resource="eApps"
        :connection-name="connectionName"
        :advisor-name="advisorName"
        :resource-access="eapps"
        :resource-email-notifications="eappsEmails"
      />
    </v-col>
    <v-col cols="12" md="6"> <h3>Payments</h3> </v-col>
    <v-col cols="12" md="6">
      <v-row class="ma-0" align="center" justify-md="end">
        <v-checkbox
          dense
          label="Accessible"
          :input-value="payments"
          class="mt-0"
          hide-details
          @change="updatePayments($event)"
        />
        <v-checkbox
          dense
          class="mt-0 ml-6"
          label="Email Notifications"
          :disabled="!payments"
          :input-value="paymentsEmails"
          hide-details
          @change="updatePaymentEmails($event)"
        />
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0">
      <connection-dialog-helper
        resource="Payments"
        :connection-name="connectionName"
        :advisor-name="advisorName"
        :resource-access="payments"
        :resource-email-notifications="paymentsEmails"
      />
    </v-col>

    <v-col cols="12" md="6"> <h3>Quick Quotes</h3> </v-col>
    <v-col cols="12" md="6">
      <v-row class="ma-0" align="center" justify-md="end">
        <v-checkbox
          dense
          label="Accessible"
          :input-value="quickQuotes"
          class="mt-0"
          hide-details
          @change="updateQuickQuotes($event)"
        />
        <v-checkbox
          dense
          class="mt-0 ml-6"
          label="Email Notifications"
          :disabled="!quickQuotes"
          :input-value="quickQuotesEmails"
          hide-details
          @change="updateQuickQuoteEmails($event)"
        />
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0">
      <connection-dialog-helper
        resource="Quick Quotes"
        :connection-name="connectionName"
        :advisor-name="advisorName"
        :resource-access="quickQuotes"
        :resource-email-notifications="quickQuotesEmails"
      />
    </v-col>
    <v-col cols="12" md="6"> <h3>Quotes</h3> </v-col>
    <v-col cols="12" md="6">
      <v-row class="ma-0" align="center" justify-md="end">
        <v-checkbox
          dense
          class="mt-0"
          label="Accessible"
          :input-value="quotes"
          hide-details
          @change="updateQuotes($event)"
        />
        <v-checkbox
          dense
          class="mt-0 ml-6"
          label="Email Notifications"
          :disabled="!quotes"
          :input-value="quotesEmails"
          hide-details
          @change="updateQuoteEmails($event)"
        />
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0">
      <connection-dialog-helper
        resource="Quotes"
        :connection-name="connectionName"
        :advisor-name="advisorName"
        :resource-access="quotes"
        :resource-email-notifications="quotesEmails"
      />
    </v-col>
  </v-row>
</template>

<script>
import ConnectionDialogHelper from "@/dialogs/ConnectionDialogHelper.vue";
export default {
  components: {
    ConnectionDialogHelper
  },
  props: {
    advisorName: String,
    connectionName: String,

    appointments: Boolean,
    appointmentsEmails: Boolean,

    cases: Boolean,
    casesEmails: Boolean,

    contractParties: Boolean,
    contractPartiesEmails: Boolean,

    eapps: Boolean,
    eappsEmails: Boolean,

    payments: Boolean,
    paymentsEmails: Boolean,

    quickQuotes: Boolean,
    quickQuotesEmails: Boolean,

    quotes: Boolean,
    quotesEmails: Boolean
  },
  watch: {
    appointments(v) {
      if (!v) this.updateAppointmentEmails(false);
    },
    cases(v) {
      if (!v) this.updateCaseEmails(false);
    },
    contractParties(v) {
      if (!v) this.updateContractPartyEmails(false);
    },
    eapps(v) {
      if (!v) this.updateEappEmails(false);
    },
    payments(v) {
      if (!v) this.updatePaymentEmails(false);
    },
    quickQuotes(v) {
      if (!v) this.updateQuickQuoteEmails(false);
    },
    quotes(v) {
      if (!v) this.updateQuoteEmails(false);
    }
  },
  methods: {
    updateAppointments(v) {
      this.$emit("update:appointments", v);
    },
    updateAppointmentEmails(v) {
      this.$emit("update:appointments-emails", v);
    },
    updateCases(v) {
      this.$emit("update:cases", v);
    },
    updateCaseEmails(v) {
      this.$emit("update:cases-emails", v);
    },
    updateContractParties(v) {
      this.$emit("update:contract-parties", v);
    },
    updateContractPartyEmails(v) {
      this.$emit("update:contract-parties-emails", v);
    },
    updateEapps(v) {
      this.$emit("update:eapps", v);
    },
    updateEappEmails(v) {
      this.$emit("update:eapps-emails", v);
    },
    updatePayments(v) {
      this.$emit("update:payments", v);
    },
    updatePaymentEmails(v) {
      this.$emit("update:payments-emails", v);
    },
    updateQuickQuotes(v) {
      this.$emit("update:quick-quotes", v);
    },
    updateQuickQuoteEmails(v) {
      this.$emit("update:quick-quotes-emails", v);
    },
    updateQuotes(v) {
      this.$emit("update:quotes", v);
    },
    updateQuoteEmails(v) {
      this.$emit("update:quotes-emails", v);
    },
    disableAllEmails() {
      this.updateAppointmentEmails(false);
      this.updateCaseEmails(false);
      this.updateContractPartyEmails(false);
      this.updateEappEmails(false);
      this.updatePaymentEmails(false);
      this.updateQuickQuoteEmails(false);
      this.updateQuoteEmails(false);
    }
  }
};
</script>
