<template>
  <v-row class="ma-0" dense>
    <v-col cols="12">
      <h1>Create an Informal Inquiry</h1>
    </v-col>

    <v-col cols="12">
      <v-card flat>
        <v-card-title> Agents </v-card-title>
        <v-card-text>
          <v-row class="ma-0" dense>
            <v-col cols="12">
              <agent-search
                v-model="informal.agent"
                better-return
                label="Agent"
                data-testid="informal-agent"
                :clearable="false"
                :success="agentValidation.success"
                :error-messages="agentValidation.errorMessages"
              />

              <v-row
                v-for="(agent, agentUuid) in informal.additionalAgents"
                :key="agentUuid"
                dense
                align="center"
                class="ma-0 mb-6"
              >
                <agent-search
                  v-model="informal.additionalAgents[agentUuid]"
                  better-return
                  success
                  label="Agent"
                  :data-testid="`informal-agent-${agent.name}`"
                  :clearable="false"
                  hide-details
                />
                <v-btn
                  depressed
                  :data-testid="`informal-agent-${agent.name}-delete`"
                  class="text-none"
                  icon
                  @click="deleteAgent(agentUuid)"
                >
                  <v-icon color="error">$mdi-delete</v-icon>
                </v-btn>
              </v-row>

              <v-row
                v-if="informal.agent?.id"
                class="ma-0"
                style="gap: 9px"
                dense
              >
                <agent-search
                  better-return
                  :key="agentKey"
                  label="Additional Agent"
                  data-testid="informal-new-agent"
                  @input="addAgent"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card flat>
        <v-card-title>Contract Party</v-card-title>
        <v-card-text>
          <v-row class="ma-0" dense>
            <v-col cols="12" md="6">
              <party-search
                v-model="informal.party"
                outlined
                dense
                hint=" "
                persistent-hint
                data-testid="informal-party"
                :success="partyValidation.success"
                :error-messages="partyValidation.errorMessages"
              >
                <template #message>
                  <a
                    @click="createContractParty"
                    class="text-none"
                    data-testid="informal-create-party"
                  >
                    Can't find your contract party?
                  </a>
                </template>
              </party-search>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="informal.status"
                prepend-inner-icon="$mdi-list-status"
                outlined
                dense
                placeholder="Select Status"
                label="Status"
                data-testid="informal-status"
                :items="STATUSES"
                :success="statusValidation.success"
                :error-messages="statusValidation.errorMessages"
              />
            </v-col>
            <v-col cols="12" md="6">
              <currency-input
                v-model="informal.faceAmount"
                prepend-inner-icon="$mdi-currency-usd"
                outlined
                dense
                label="Death Benefit"
                data-testid="informal-face-amount"
                :success="faceAmountValidation.success"
                :error-messages="faceAmountValidation.errorMessages"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="informal.state"
                prepend-inner-icon="$mdi-sign-real-estate"
                outlined
                dense
                placeholder="Select State"
                label="State"
                data-testid="informal-state"
                :items="STATES"
                :success="stateValidation.success"
                :error-messages="stateValidation.errorMessages"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card flat>
        <v-card-title>
          <v-row dense align="center" class="ma-0">
            Carriers
            <v-spacer />
            <v-btn
              class="text-none"
              color="accent"
              data-testid="informal-select-qna-carriers"
              @click="selectAllQuoteAndApplyCarriers"
            >
              <quote-and-apply-icon class="mr-1" />
              Enable All Quote & Apply Carriers
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-chip-group
            v-if="carriers.length"
            v-model="informal.carriers"
            multiple
            column
            color="primary"
          >
            <template v-for="({ name, id }, index) in carriers">
              <v-chip
                :key="`item-${index}`"
                :value="id"
                :data-testid="`carrier-${name}`"
              >
                {{ name }}
              </v-chip>
            </template>
          </v-chip-group>
          <template v-else>
            <p style="text-align: center">Loading Carriers</p>
            <v-progress-linear indeterminate />
          </template>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-btn
        color="primary"
        class="text-none"
        data-testid="informal-create"
        :loading="loading"
        @click="save"
      >
        <v-icon class="mr-1">$mdi-plus</v-icon>
        Create Informal
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import QuoteAndApplyIcon from "@/components/shared/QuoteAndApplyIcon.vue";
import AgentSearch from "@/components/shared/AgentSearch.vue";
import PartySearch from "@/components/shared/ContractPartySearch.vue";

import { required } from "vuelidate/lib/validators";

import { states as STATES } from "@/data/states";
import {
  getRouterLink,
  parseErrorMessage,
  uuidv4,
  validationCompute
} from "@/util/helpers";
import { getCarriers } from "@/api/carrier.service";
import { createCase } from "@/api/cases.service";
import { mapActions } from "pinia";
import InformalInquiryFactory, {
  INFORMAL_STATUSES
} from "@/factories/InformalInquiryFactory";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useHead } from "@unhead/vue";
import NewPartyDialog from "@/components/cases/case-dialogs/NewPartyDialog.vue";
import { markRaw } from "vue";

export default {
  setup() {
    useHead({ title: "Create Informal Case" });
  },
  components: {
    AgentSearch,
    PartySearch,
    QuoteAndApplyIcon,
    CurrencyInput
  },
  data() {
    return {
      informal: new InformalInquiryFactory(),
      STATUSES: INFORMAL_STATUSES,
      STATES,
      carriers: [],
      loading: false,
      quoteAndApplyCarriers: false,
      agentKey: null,
      carrierCancelToken: null
    };
  },
  computed: {
    agentValidation() {
      const model = this.$v.informal.agent;
      return validationCompute(model, [
        { key: "required", message: "Required" }
      ]);
    },
    statusValidation() {
      const model = this.$v.informal.status;
      return validationCompute(model, [
        { key: "required", message: "Required" }
      ]);
    },
    faceAmountValidation() {
      const model = this.$v.informal.faceAmount;
      return validationCompute(model, [
        { key: "required", message: "Required" }
      ]);
    },
    stateValidation() {
      const model = this.$v.informal.state;
      return validationCompute(model, [
        { key: "required", message: "Required" }
      ]);
    },
    partyValidation() {
      const model = this.$v.informal.party;
      return validationCompute(model, [
        { key: "required", message: "Required" }
      ]);
    }
  },
  created() {
    this.getCarriers();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    selectAllQuoteAndApplyCarriers() {
      this.informal.carriers.splice(0, this.informal.carriers.length);
      this.carriers.forEach(carrier => {
        if (carrier.strifeApproved) this.informal.carriers.push(carrier.id);
      });
    },
    async getCarriers() {
      try {
        const params = new URLSearchParams();
        params.append("informal", "true");
        const carriers = await getCarriers(params, this.carrierCancelToken);
        this.carriers.push(
          ...carriers.map(({ name, id, availableInQuoteAndApply }) => ({
            name,
            id,
            strifeApproved: availableInQuoteAndApply
          }))
        );
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      }
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        const id = await createCase(this.informal.toCreateRequest());
        this.$router.push(getRouterLink("Case", id));
      } catch (e) {
        this.showErrorSnackbar({
          message: parseErrorMessage(e),
          duration: 5000
        });
      } finally {
        this.loading = false;
      }
    },
    deleteAgent(uuid) {
      this.$delete(this.informal.additionalAgents, uuid);
    },
    addAgent(value) {
      if (!value) return;
      this.$set(this.informal.additionalAgents, uuidv4(), value);
      this.agentKey = uuidv4();
    },
    async createContractParty() {
      const result = await this.showDialog({
        component: markRaw(NewPartyDialog),
        hideRole: true,
        hideNetWorth: true,
        line: "life",
        scrollable: true,
        onlyType: "Individual"
      });

      if (!result.party) return;
      this.informal.party = result.party;
    }
  },
  validations: {
    informal: {
      agent: {
        required
      },
      status: {
        required
      },
      faceAmount: {
        required
      },
      state: {
        required
      },
      party: {
        required
      }
    }
  }
};
</script>
