//this file will be deprecated
import { isDateType } from "@/constants/date-filter.constants";
import get from "lodash/get";
import omit from "lodash/omit";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { numberFormat } from "@/util/helpers";
import TableHeader from "@/classes/data-table/TableHeader";

export default {
  methods: {
    handleCustomSorts(options, oldOptions) {
      if (!options.sortBy.length) return options;
      options.sortBy.forEach((option, index) => {
        const header = this.headers.find(header => option === header.value);
        if (!header?.invertedSort) return options;
        const oldOptionsIndex = oldOptions.sortBy.findIndex(
          value => value === option
        );
        if (oldOptionsIndex > -1) return;
        options.sortDesc[index] = true;
      });

      return options;
    },
    tableOptionsSort(options, sortFilterMap) {
      let sort = {};
      const sortBy = options.sortBy;
      const sortDesc = options.sortDesc;
      if (!sortBy?.length) {
        return sort;
      }
      sortBy.forEach((val, index) => {
        sort[sortFilterMap[val]] = sortDesc[index] ? "desc" : "asc";
      });
      return sort;
    },
    tableFilterObject(activeFilter, sortFilterMap) {
      let filter = {};

      const filterKeys = Object.keys(activeFilter);
      if (!filterKeys?.length) {
        return filter;
      }
      filterKeys.forEach(val => {
        if (!activeFilter[val]) {
          return;
        }

        if (
          typeof activeFilter[val] !== "object" ||
          Array.isArray(activeFilter[val])
        ) {
          filter[sortFilterMap[val]] = activeFilter[val];
          return;
        }

        if (isDateType(activeFilter[val].type)) {
          if (!activeFilter[val].value.start) {
            return;
          }
          const options = omit(activeFilter[val].value, ["type"]);

          filter[sortFilterMap[val]] = options;
        }

        if (
          ["additional_advisor", "advisor", "doing_business_as"].includes(val)
        ) {
          filter[sortFilterMap[val]] = activeFilter[val];
        }
      });
      return filter;
    },
    tableMap(
      data,
      headers,
      tableHelpers = { downloading: false, deleting: false, loading: false }
    ) {
      if (!data || !headers) return [];
      return data?.map((value, index) => {
        const rowData = {};
        headers.forEach(header => {
          if (header.filterType === TableHeader.DATE_TYPE) {
            const val = get(value, header.map);
            rowData[header.value] = val;
            return;
          }
          if (Array.isArray(header.map)) {
            const newVal = [];
            header.map.forEach(piece => {
              newVal.push(get(value, piece));
            });
            rowData[header.value] = newVal.join(" ");
            return;
          }
          if (typeof header.map === "function") {
            rowData[header.value] = header.map(value);
            return;
          }
          rowData[header.value] = get(value, header.map);
        });
        rowData.key = index + JSON.stringify(rowData);
        rowData.additional = {
          ...value,
          tableHelpers: cloneDeep(tableHelpers)
        };
        return rowData;
      });
    },
    pageTextFormatter(tableOptions, meta) {
      if (!meta || !meta?.total) return "No Results";
      const total = meta?.total || meta;
      const currentMinValue =
        (tableOptions.page - 1) * tableOptions.itemsPerPage + 1;
      const currentMaxValue = Math.min(
        total,
        tableOptions.page * tableOptions.itemsPerPage
      );

      return `${numberFormat(currentMinValue)}-${numberFormat(
        currentMaxValue
      )} of ${numberFormat(total)}`;
    },
    optionsEquivalence(oldOptions, newOptions) {
      const newValues = pick(newOptions, [
        "itemsPerPage",
        "page",
        "sortBy",
        "sortDesc"
      ]);
      const origValues = pick(oldOptions, [
        "itemsPerPage",
        "page",
        "sortBy",
        "sortDesc"
      ]);
      return isEqual(newValues, origValues);
    }
  }
};
