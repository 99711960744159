import ProductAliasFactory from "@/factories/ProductAliasFactory";
import {
  VerboseProductFactory,
  TemporaryInsuranceProfile,
  MYGARate
} from "@/factories/ProductFactory";
import { serializeObject } from "@/util/helpers";

import { getHttpClient } from "@/http-client";

const baseUrl = "/api/boss/products";

export async function uploadConsumerProductGuide(id, file) {
  return getHttpClient().post(
    `${baseUrl}/${id}/documents`,
    await serializeObject({
      file,
      category: "Consumer Product Guide"
    })
  );
}

export async function productCreate(params) {
  const { data } = await getHttpClient().post(`${baseUrl}`, params);

  return data.product.id;
}
export const productSave = (id, params) => {
  return getHttpClient().put(`${baseUrl}/${id}`, params);
};

export const productFind = async id => {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  const product = new VerboseProductFactory();
  product.setFromRequest(data.product);
  return product;
};

export async function productSearch(id, line = null) {
  const params = new URLSearchParams();
  params.append("carrier", id);
  params.append("primary_product", "true");
  if (line) {
    line.forEach(line => {
      params.append("lines[]", line);
    });
  }
  const { data } = await getHttpClient().get(baseUrl, { params });
  return data.products.map(p => ({ name: p.name, id: p.id, line: p.line }));
}

export const productSimpleSearch = name => {
  const params = new URLSearchParams();
  params.append("name", name);
  return getHttpClient().get(`${baseUrl}/search_simple`, {
    params
  });
};

let productAliasCancelToken = null;
export const getProductAliases = async productId => {
  if (productAliasCancelToken !== null) {
    productAliasCancelToken.cancel("Operation canceled due to new request.");
  }
  productAliasCancelToken = getHttpClient().CancelToken.source();
  const { data } = await getHttpClient().get(
    `${baseUrl}/${productId}/aliases`,
    {
      cancelToken: productAliasCancelToken.token
    }
  );

  const aliases = data.map(rawAlias => {
    const alias = new ProductAliasFactory();
    alias.setFromRequest(rawAlias);
    return alias;
  });
  return aliases;
};

export const createAlias = (productId, body) => {
  return getHttpClient().post(`${baseUrl}/${productId}/aliases`, body);
};

export const deleteAlias = (productId, aliasId) => {
  return getHttpClient().delete(`${baseUrl}/${productId}/aliases/${aliasId}`);
};

export const updateAlias = (productId, aliasId, body) => {
  return getHttpClient().put(
    `${baseUrl}/${productId}/aliases/${aliasId}`,
    body
  );
};

export const createTemporaryInsuranceProfile = async (productId, body) => {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${productId}/temporary-insurance-profiles`,
    body
  );
  const profile = new TemporaryInsuranceProfile();
  profile.setFromRequest(data);
  return profile;
};

export const removeTemporaryInsuranceProfile = (productId, id) => {
  return getHttpClient().delete(
    `${baseUrl}/${productId}/temporary-insurance-profiles/${id}`
  );
};

export const getMYGARates = async productId => {
  const { data } = await getHttpClient().get(
    `${baseUrl}/${productId}/myga-rates`
  );

  const rates = [];
  data.map(rawRate => {
    const rate = new MYGARate();
    rate.setFromRequest(rawRate);
    rate.productId = productId;
    rates.push(rate);
  });
  rates.sort((a, b) => (a.year - b.year > 0 ? 1 : -1));
  return rates;
};

/**
 * @param {MYGARate}
 */
export const createMYGARate = async rate => {
  await getHttpClient().post(`${baseUrl}/${rate.productId}/myga-rates`, {
    year: +rate.year,
    rate: +(+rate.rate / 100).toFixed(4),
    surrender_charge: +(+rate.surrenderCharge / 100).toFixed(2),
    withdrawal_allowance: rate.withdrawalAllowance,
    rate_band_id: rate.rateBand.id
  });

  return rate;
};

/**
 * @param {MYGARate}
 */
export const updateMYGARate = async rate => {
  await getHttpClient().put(
    `${baseUrl}/${rate.productId}/myga-rates/${rate.id}`,
    {
      year: +rate.year,
      rate: +(+rate.rate / 100).toFixed(4),
      surrender_charge: +(+rate.surrenderCharge / 100).toFixed(2),
      withdrawal_allowance: rate.withdrawalAllowance,
      rate_band_id: rate.rateBand.id
    }
  );

  return rate;
};

export const deleteMYGARate = rate => {
  return getHttpClient().delete(
    `${baseUrl}/${rate.productId}/myga-rates/${rate.id}`
  );
};
