<template>
  <v-card flat color="section" tile>
    <v-card-text>
      <v-data-table
        :items="table.mappedItems"
        :headers="table.headers"
        :loading="table.loading"
        class="transparent-data-table"
      >
        <template #top v-if="isGroupFour">
          <v-btn @click="addMygaRate" class="text-none my-3" color="primary">
            <v-icon> $mdi-plus</v-icon> Add Rate
          </v-btn>
        </template>

        <template #[`item.rate`]="{ item }">
          <percentage-formatter :value="item.rate" is-decimal />
        </template>

        <template #[`item.surrenderCharge`]="{ item }">
          <percentage-formatter :value="item.surrenderCharge" is-decimal />
        </template>

        <template #[`item.rateBand`]="{ item }">
          {{ item.additional.rateBand.display }}
        </template>

        <template #[`item.actions`]="{ item }">
          <v-btn @click="editRate(item.additional)" icon>
            <v-icon color="primary">$mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="deleteRate(item.additional)" icon>
            <v-icon color="red">$mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";

import Table from "@/classes/data-table/Table";
import TableHeader from "@/classes/data-table/TableHeader";

import { getMYGARates, deleteMYGARate } from "@/api/products.service";
import { parseErrorMessage } from "@/util/helpers";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

export default {
  components: {
    PercentageFormatter
  },
  props: {
    id: [String, Number]
  },
  data() {
    const user = useUserStore();
    const shouldHaveActionsColumn = user.isGroupFour;

    const headers = [
      new TableHeader({
        text: "Year",
        value: "year",
        map: "year"
      }),
      new TableHeader({
        text: "Rate",
        value: "rate",
        map: "rate"
      }),
      new TableHeader({
        text: "Surrender Charge",
        value: "surrenderCharge",
        map: "surrenderCharge"
      }),
      new TableHeader({
        text: "Withdrawal Allowance",
        value: "withdrawalAllowance",
        map: "withdrawalAllowance"
      }),
      new TableHeader({
        text: "Rate Band",
        value: "rateBand",
        map: "rateBand"
      })
    ];

    if (shouldHaveActionsColumn) {
      headers.push(
        new TableHeader({
          text: "Actions",
          value: "actions",
          map: "actions"
        })
      );
    }

    const table = new Table({
      text: "Commission Details",
      headers,
      method: () => getMYGARates(this.id)
    });

    return {
      table
    };
  },
  computed: {
    ...mapState(useUserStore, ["isGroupFour"])
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    async addMygaRate() {
      const res = await this.showDialog({
        component: "MygaRateDialog",
        id: +this.id,
        scrollable: true
      });
      if (!res?.refresh) return;
      this.getData();
    },
    async editRate($rate) {
      const res = await this.showDialog({
        component: "MygaRateDialog",
        id: +this.id,
        scrollable: true,
        $rate: {
          ...$rate,
          rate: +($rate.rate * 100),
          surrenderCharge: +($rate.surrenderCharge * 100)
        }
      });
      if (!res?.refresh) return;
      this.getData();
    },
    async deleteRate(rate) {
      const res = await this.showDialog({
        component: "ConfirmationDialog",
        title: "Delete MYGA Rate",
        subtitle: "This cannot be undone",
        confirmText: "Delete Rate",
        func: () => deleteMYGARate(rate)
      });
      if (res?.confirm) this.getData();
    },
    async getData() {
      try {
        await this.table.getPreMappedData();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      }
    }
  }
};
</script>
