<template>
  <v-card elevation="8">
    <v-card-text>
      <v-data-table
        item-key="key"
        mobile-breakpoint="1090"
        show-expand
        :loading="table.loading"
        :headers="headers"
        :items="items"
        :server-items-length="table.meta.total"
        :options="options"
        :footer-props="footerProps"
        @update:options="updateOptions"
      >
        <template #top>
          <v-row class="ma-0">
            <h4 class="text-h5">Pending Transactions</h4>
            <v-spacer />
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn icon @click="getData" v-on="on" v-bind="attrs">
                  <v-icon>$mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh </span>
            </v-tooltip>
            <v-col cols="12" class="pa-0">
              <generic-table-filter-header
                :value="filter"
                :headers="allHeaders"
                :loading="table.loading"
                @update="updateFilter"
              />
            </v-col>
          </v-row>
        </template>
        <template #[`item.transactionDetails`]="{ item }">
          <v-col class="pa-1">
            <div>{{ item.additional.commissionType }}</div>
            <div>
              Premium: <currency-formatter :value="item.additional.premium" />
            </div>
            <div>
              Override: <currency-formatter :value="item.additional.amount" />
            </div>
          </v-col>
        </template>
        <template #[`item.statementId`]="{ item }">
          <v-tooltip v-if="item.statementId" top>
            <template #activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs" style="white-space: nowrap">
                <v-btn
                  outlined
                  color="primary"
                  class="text-none"
                  :to="{
                    name: 'StatementView',
                    params: {
                      id: item.statementId
                    },
                    query: {
                      page: 'pending-transactions'
                    }
                  }"
                  style="
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-right-width: 1px;
                  "
                >
                  <v-icon left>$mdi-calendar</v-icon>
                  <span class="truncate-200">
                    #{{ item.statementId }} -
                    {{ item.additional.payorName }}
                  </span>
                </v-btn>
                <v-btn
                  outlined
                  color="primary"
                  :to="{
                    name: 'StatementView',
                    params: {
                      id: item.statementId
                    },
                    query: {
                      page: 'pending-transactions'
                    }
                  }"
                  target="_blank"
                  style="
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-left-width: 1px;
                    min-width: unset !important;
                    padding: 6px !important;
                  "
                >
                  <v-icon>$mdi-open-in-new</v-icon>
                </v-btn>
              </div>
            </template>
            <span>
              View #{{ item.statementId }} - {{ item.additional.payorName }}
            </span>
          </v-tooltip>
        </template>
        <template #[`item.policyNumber`]="{ item }">
          <v-tooltip v-if="item.additional.caseId" top>
            <template #activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs" style="white-space: nowrap">
                <v-btn
                  color="accent"
                  outlined
                  :to="getRouterLink('Case', item.additional.caseId)"
                  style="
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-right-width: 1px;
                  "
                >
                  <v-icon left>$mdi-briefcase</v-icon>
                  {{ item.policyNumber }}
                </v-btn>
                <v-btn
                  outlined
                  color="accent"
                  :to="getRouterLink('Case', item.additional.caseId)"
                  target="_blank"
                  style="
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-left-width: 1px;
                    min-width: unset !important;
                    padding: 6px !important;
                  "
                >
                  <v-icon>$mdi-open-in-new</v-icon>
                </v-btn>
              </div>
            </template>
            <span>View Case</span>
          </v-tooltip>
          <v-tooltip v-else-if="item.policyNumber" top>
            <template #activator="{ on, attrs }">
              <v-btn
                label
                class="text-none"
                outlined
                v-on="on"
                v-bind="attrs"
                color="warning"
                @click="editQueuedTransaction(item)"
              >
                <v-icon left>$mdi-help-circle-outline</v-icon>
                {{ item.policyNumber }}
              </v-btn>
            </template>
            <span>Unable to locate case, click to edit</span>
          </v-tooltip>
          <v-tooltip v-else top>
            <template #activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                outlined
                label
                color="error"
                class="text-none"
                @click="editQueuedTransaction(item)"
              >
                <v-icon left>$mdi-help-circle-outline</v-icon> No Policy #
              </v-btn>
            </template>
            <span>No policy # Click to edit</span>
          </v-tooltip>
        </template>
        <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
          <v-row class="ma-0 flex-nowrap" dense>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="editQueuedTransaction(item)"
                  color="accent"
                  outlined
                  v-on="on"
                  v-bind="attrs"
                  class="mr-1"
                >
                  <v-icon>$mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Transaction </span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  outlined
                  v-on="on"
                  v-bind="attrs"
                  class="mr-1"
                  @click="toggleIgnoreQueuedTransaction(item.additional)"
                >
                  <v-icon v-if="item.additional.isIgnored">$mdi-eye</v-icon>
                  <v-icon v-else>$mdi-eye-off</v-icon>
                </v-btn>
              </template>
              <span v-if="item.additional.isIgnored">
                Don't Ignore Transaction
              </span>
              <span v-else>Ignore Transaction </span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  outlined
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  @click="expand(!isExpanded)"
                >
                  <v-icon v-if="isExpanded"> $mdi-chevron-up </v-icon>
                  <v-icon v-else>$mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <span>Show Raw Data </span>
            </v-tooltip>
          </v-row>
        </template>
        <template #[`item.errorMessage`]="{ item }">
          {{ item.errorMessage }}
        </template>
        <template #expanded-item="{ item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col
                v-for="(value, key) in item.additional.rawData"
                :key="key"
                cols="4"
                md="3"
                lg="2"
              >
                <span class="grey--text lighten-2"> {{ key }} </span>
                <br />
                <span> {{ value }} </span>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";

import {
  getQueuedTransactions,
  toggleIgnoreQueuedTransaction
} from "@/api/queued-transactions.service";

import Table from "@/classes/data-table/Table";
import TableHeader from "@/classes/data-table/TableHeader";

import { mapActions } from "pinia";
import { parseErrorMessage, getRouterLink } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useHead } from "@unhead/vue";
export default {
  setup() {
    useHead({
      title: "Pending Transactions"
    });
  },
  components: {
    CurrencyFormatter,
    GenericTableFilterHeader
  },
  mixins: [GenericTableMixin],
  props: {
    statementId: [String, Number]
  },
  data() {
    const headers = [
      this.statementId
        ? null
        : new TableHeader({
            text: "Statement #",
            value: "statementId",
            map: "statementId",
            sortFilterMap: "statement_id",
            ...TableHeader.IS_FILTERABLE,
            ...TableHeader.IS_SORTABLE,
            ...TableHeader.IS_QUERY_FILTER,
            width: "250px"
          }),
      new TableHeader({
        text: "Policy #",
        value: "policyNumber",
        map: "policyNumber",
        sortFilterMap: "policy_number",
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_SORTABLE,
        ...TableHeader.IS_QUERY_FILTER,
        width: "250px"
      }),
      new TableHeader({
        text: "Transaction Details",
        value: "transactionDetails",
        width: "250px"
      }),
      new TableHeader({
        text: "Error Message",
        value: "errorMessage",
        map: "errorMessage"
      }),
      new TableHeader({
        text: "",
        value: "data-table-expand",
        width: "125px"
      }),
      new TableHeader({
        text: "Include Ignored Transactions",
        value: "ignored",
        sortFilterMap: "ignore",
        ...TableHeader.IS_ADDITIONAL,
        ...TableHeader.IS_CHECKBOX_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_QUERY_FILTER,
        ...TableHeader.SEND_FALSE
      })
    ].filter(Boolean);

    const filter = {
      ignored: false
    };

    const table = new Table({
      method: params => {
        if (this.statementId) params.append("statement_id", this.statementId);
        return getQueuedTransactions(params);
      },
      headers,
      key: "advisors",
      filter
    });

    return {
      table,
      loading: false,
      getRouterLink
    };
  },
  created() {
    this.getData(true);
  },
  computed: {
    allHeaders() {
      return this.table.headers.filter(v => {
        if (!v.visible) return true;
        return v.showIf(this.filter);
      });
    },
    items() {
      return this.table.mappedItems;
    },
    headers() {
      return this.table.headers.filter(val => !val.isAdditional);
    },
    filter: {
      get() {
        return this.table.filter;
      },
      set(val) {
        this.table.filter = val;
      }
    },
    options: {
      get() {
        return this.table.options;
      },
      set(value) {
        this.table.options = value;
      }
    },
    footerProps() {
      return {
        pageText: this.pageTextFormatter(this.table.options, this.table.meta),
        itemsPerPageOptions: [10, 20, 35, 50]
      };
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    async getData(emitTotal) {
      this.table
        .getData()
        .then(({ transactions, meta }) => {
          this.table.rawItems = transactions;
          this.table.meta = meta;
          if (emitTotal) this.$emit("total", meta.total);
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        });
    },
    editQueuedTransaction({ additional: item }) {
      this.showDialog({
        component: "TransactionCreate",
        ...item,
        width: "90%",
        scrollable: true,
        isDialog: true,
        editing: true,
        persistent: true
      }).then(res => {
        if (res?.updated) this.getData();
      });
    },
    toggleIgnoreQueuedTransaction({ id, isIgnored }) {
      let title = "Ignore this transaction?";
      let subtitle =
        "You can find this transaction by including ignored transactions.";

      if (isIgnored) {
        title = "Don't Ignore this Transaction?";
        subtitle =
          "You can find this transaction without including ignored transactions.";
      }

      this.showDialog({
        component: "ConfirmationDialog",
        title,
        subtitle,
        func: () => toggleIgnoreQueuedTransaction(id, !isIgnored)
      }).then(res => {
        if (res?.confirm) this.getData();
      });
    },
    updateOptions(options) {
      if (this.optionsEquivalence(options, this.options)) {
        return;
      }
      this.options = options;
      this.getData();
    },
    updateFilter(filter) {
      this.table.resetPage();
      this.filter = filter;
      this.getData();
    }
  }
};
</script>
