import { getHttpClient } from "@/http-client";
const baseUrl = "api/boss/upload";
export async function uploadUserDocument(params) {
  let formData = new FormData();
  formData.append("file", params.file);
  formData.append("documentable_type", params.documentable_type);
  formData.append("documentable_id", params.documentable_id);

  const { data } = await getHttpClient().post(`${baseUrl}/user`, formData);

  return data.document;
}
