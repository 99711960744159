import { ADVISOR_CATEGORIES } from "@/data/advisor-data";
import { Address } from "@/factories/AddressFactory";
import { setNoteFromRequest } from "./NoteFactory";
import { setAddressFromRaw } from "./AddressFactory";
export const LEAD_CATEGORIES = ["A", "B", "C", ...ADVISOR_CATEGORIES];

export function Lead(model = {}) {
  return {
    id: model?.id,
    homeAddress: Address(model?.homeAddress),
    businessAddress: Address(model?.businessAddress),
    birthdate: model?.birthdate,
    category: model?.category,
    description: model?.description,
    email: model?.email,
    firstName: model?.firstName,
    followUpDate: model?.followUpDate,
    gender: model?.gender,
    lastName: model?.lastName,
    leadScore: model?.leadScore,
    marketingManager: MarketingManger(model?.marketingManager),
    notes: model?.notes,
    phoneFax: model?.phoneFax,
    phoneMobile: model?.phoneMobile,
    phoneWork: model?.phoneWork,
    subscribed: model?.subscribed,
    title: model?.title
  };
}

export function setLeadFromRequest(raw = {}) {
  const model = Lead();
  model.id = raw?.id;
  model.birthdate = raw?.birthdate;
  model.category = raw?.category;
  model.description = raw?.description;
  model.email = raw?.email;
  model.firstName = raw?.first_name;
  model.followUpDate = raw?.follow_up_date;
  model.gender = raw?.gender;
  model.lastName = raw?.last_name;
  model.modelScore = raw?.model_score;
  model.phoneFax = raw?.phone_fax;
  model.phoneMobile = raw?.phone_mobile;
  model.phoneWork = raw?.phone_work;
  model.subscribed = raw?.subscribed;
  model.title = raw?.title;

  model.homeAddress = setAddressFromRaw(raw?.addresses?.home);
  model.businessAddress = setAddressFromRaw(raw?.addresses?.business);

  model.marketingManager = setMarketingManagerFromRequest(
    raw?.marketing_manager
  );

  model.notes = raw?.notes?.map(rawNote => setNoteFromRequest(rawNote));
  return model;
}

export function NewLead(model = {}) {
  return {
    id: model?.id,
    homeAddress: Address(model?.homeAddress),
    businessAddress: Address(model?.businessAddress),
    birthdate: model?.birthdate,
    category: model?.category,
    description: model?.description,
    email: model?.email,
    firstName: model?.firstName,
    followUpDate: model?.followUpDate,
    gender: model?.gender,
    lastName: model?.lastName,
    leadScore: model?.leadScore,
    marketingManager: MarketingManger(model?.marketingManager),
    notes: model?.notes,
    phoneFax: model?.phoneFax,
    phoneMobile: model?.phoneMobile,
    phoneWork: model?.phoneWork,
    subscribed: model?.subscribed,
    title: model?.title
  };
}

export function NewLeadToCreateRequest(model) {
  return {
    marketing_manager_id: model.marketingManager?.marketing_manager_id || null,
    email: model.email || null,
    first_name: model.firstName || null,
    last_name: model.lastName || null,
    phone_work: model.phoneWork || null,
    phone_mobile: model.phoneMobile || null,
    phone_fax: model.phoneFax || null,
    birthdate: model.birthdate || null,
    gender: model.gender || null,
    category: model.category || null,
    follow_up_date: model.followUpDate || null,
    subscribed: Boolean(model.subscribed),
    addresses_attributes: [
      {
        address_type: "home",
        street_address: model.homeAddress?.street_address || null,
        city: model.homeAddress?.city || null,
        state: model.homeAddress?.state || null,
        zip: model.homeAddress?.zip || null
      },
      {
        address_type: "business",
        street_address: model.businessAddress?.street_address || null,
        city: model.businessAddress?.city || null,
        state: model.businessAddress?.state || null,
        zip: model.businessAddress?.zip || null
      }
    ]
  };
}

function MarketingManger(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    primaryMarketingMangerName: model?.primaryMarketingMangerName
  };
}

function setMarketingManagerFromRequest(rawModel = {}) {
  const marketingManager = MarketingManger();
  marketingManager.id = rawModel?.marketing_manager_id || rawModel?.id;
  marketingManager.name = rawModel?.name;
  marketingManager.primaryManagerName =
    rawModel?.primary_marketing_manager_name;
  return marketingManager;
}
