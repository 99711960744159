<template>
  <v-data-table :items="items" :headers="headers">
    <template #[`item.change`]="{ item }">
      <div v-for="(change, index) in changeText(item)" :key="index">
        <tt class="primary--text pr-1">{{ change.name }}:</tt>
        <tt class="error--text">{{ change.oldChange }}</tt>
        <tt class="px-1">→</tt>
        <tt class="success--text">{{ change.newChange }}</tt>
      </div>
    </template>
    <template #[`item.date`]="{ item }">
      <timestamp-formatter :value="item.date" />
    </template>
  </v-data-table>
</template>

<script>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
export default {
  components: { TimestampFormatter },
  props: {
    product: Object
  },
  data() {
    const items = this.product.versions.map(row => {
      return {
        date: row.created_at,
        user: row.whodunnit_name,
        change: JSON.stringify(row.changeset)
      };
    });

    items.sort((a, b) => new Date(b.date) - new Date(a.date));
    return {
      items,
      headers: [
        { text: "Date", value: "date" },
        { text: "User", value: "user" },
        { text: "Change", value: "change" }
      ]
    };
  },
  methods: {
    changeText(item) {
      try {
        const text = JSON.parse(item.change);
        const changes = [];
        Object.keys(text).forEach(key => {
          const [oldChange, newChange] = text[key];

          changes.push({
            name: key,
            oldChange,
            newChange
          });
        });

        return changes;
      } catch (e) {
        return "Failed to parse";
      }
    }
  }
};
</script>
