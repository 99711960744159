import CarrierFactory from "@/factories/CarrierFactory";
import CompBuilderFactory from "@/factories/CompBuilderFactory";
import ProductSmokerChartFactory from "@/factories/ProductSmokerChartFactory";
import PayorFactory from "@/factories/PayorFactory";

import { currencyFormat } from "@/util/helpers";

export const LINES = [
  { text: "Annuity", value: "annuity" },
  { text: "Disability", value: "disability" },
  { text: "Linked Benefit", value: "linked_benefit" },
  { text: "Life", value: "life" },
  { text: "LTC", value: "ltc" },
  { text: "Variable Life", value: "variable_life" }
];

export const LINE_PERCENTAGES = [5, 50, 100];

export function VerboseProductFactory(model = {}) {
  return {
    activeYears: model?.activeYears || null,
    ageNearest: model?.ageNearest || null,
    carrier: model?.carrier || new CarrierFactory(model?.carrier),
    documents: model?.documents || [],
    chargeback: model?.chargeback || null,
    conversion: model?.conversion || null,
    firstYearCommission: model?.firstYearCommission || null,
    id: model?.id || null,
    line: model?.line || null,
    linePercentage: model?.linePercentage || null,
    ltcRider: model?.ltcRider || null,
    vitalityRider: model?.vitalityRider || null,
    criticalIllnessRider: model?.criticalIllnessRider || null,
    chronicIllnessRider: model?.chronicIllnessRider || null,
    noCostChronicIllnessRider: model?.noCostChronicIllnessRider || null,
    noCostCriticalIllnessRider: model?.noCostCriticalIllnessRider || null,
    advisorUseOnly: model?.advisorUseOnly || null,
    guides: model?.guides || [],
    advisorGuide: model?.advisorGuide || null,
    consumerGuide: model?.consumerGuide || null,
    underwritingGuide: model?.underwritingGuide || null,
    maxPayout: model?.maxPayout || null,
    formSet: model?.formSet || [],
    name: model?.name || null,
    notes: model?.notes || [],
    payouts: model?.payouts || [],
    planType: model?.planType || null,
    primaryProduct: model?.primaryProduct || null,
    productBuildCharts: model?.productBuildCharts || [],
    productCitizenshipProfiles: model?.productCitizenshipProfiles || [],
    payorOptions: model?.payorOptions || [],
    eDelivery: model?.eDelivery || null,
    productSmokerCharts: model?.productSmokerCharts || [],
    rollingTarget: model?.rollingTarget || null,
    section1035Exchange: model?.section1035Exchange || null,
    schedules: model?.schedules || new Schedules(model?.schedules),
    streetExcess: model?.streetExcess || null,
    streetFirst: model?.streetFirst || null,
    streetPua: model?.streetPua || null,
    streetRenewal: model?.streetRenewal || null,
    totalExcess: model?.totalExcess || null,
    totalFirst: model?.totalFirst || null,
    totalRenewal: model?.totalRenewal || null,
    totalPua: model?.totalPua || null,
    surrenderPeriod: model?.surrenderPeriod || null,
    temporaryInsuranceProfileProducts:
      model?.temporaryInsuranceProfileProducts || [],
    versions: model?.versions || [],
    compBuilders: model?.compBuilders || [],
    engines: model?.engines || null,
    type: model?.type || null,
    traits: model?.traits || null,
    strifeEnabled: model?.strifeEnabled || null,
    parameters: model?.parameters || "",
    showConversion: model?.showConversion || false,
    instantAps: model?.instantAps || false,
    minAge: model?.minAge || null,
    maxAge: model?.maxAge || null,
    minDeathBenefit: model?.minDeathBenefit || null,
    maxDeathBenefit: model?.maxDeathBenefit || null,
    minPremium: model?.minPremium || null,
    maxPremium: model?.maxPremium || null,
    mygaRates: model?.mygaRates || [],
    payDuration: model?.payDuration || [],
    trainingInstructions: model?.trainingInstructions || null,
    solves: model?.solves || [],
    setFromRequest(model = {}) {
      const percentOrNull = (val, fractionDigits = 2) => {
        if (val === null) return null;
        return (Number(val) * 100).toFixed(fractionDigits) || null;
      };
      const numberOrNull = val => {
        if (val || val === 0) return val;
        return null;
      };
      this.payorOptions = model?.payor_options?.map(val => ({
        id: val.id,
        name: val.name,
        payableType: val.payableType
      }));
      this.trainingInstructions = model?.training?.instructions;
      this.minAge = model?.min_age;
      this.maxAge = model?.max_age;
      this.instantAps = model?.instant_aps;
      this.activeYears = model?.active_years;
      this.ageNearest = model?.age_nearest;
      this.eDelivery = model?.carrier_e_delivery;
      this.chargeback = model?.chargeback;
      this.showConversion = model?.show_conversion;
      this.conversion = model?.conversion;
      this.firstYearCommission = model?.first_year_commission;
      this.id = model?.id;
      this.line = model?.line;
      this.linePercentage = +percentOrNull(model?.line_percentage, 0);
      this.ltcRider = model?.ltc_rider;
      this.vitalityRider = model?.vitality_rider;
      this.criticalIllnessRider = model?.critical_illness_rider;
      this.chronicIllnessRider = model?.chronic_illness_rider;
      this.noCostChronicIllnessRider = model?.no_cost_chronic_illness_rider;
      this.noCostCriticalIllnessRider = model?.no_cost_critical_illness_rider;
      this.advisorUseOnly = model?.advisor_use_only;
      this.maxPayout = model?.max_payout;
      this.name = model?.name;
      this.documents.push(...(model?.documents || []));
      this.rollingTarget = model?.rolling_target;
      this.streetExcess = percentOrNull(model?.street_excess);
      this.streetFirst = percentOrNull(model?.street_first);
      this.streetPua = percentOrNull(model?.street_pua);
      this.streetRenewal = percentOrNull(model?.street_renewal);
      this.totalExcess = percentOrNull(model?.total_excess);
      this.totalFirst = percentOrNull(model?.total_first);
      this.totalPua = percentOrNull(model?.total_pua);
      this.totalRenewal = percentOrNull(model?.total_renewal);
      this.formSet = model?.form_set?.id;
      this.surrenderPeriod = model?.surrender_period;
      this.versions = model?.versions;
      this.engine = model?.engine;
      this.type = model?.type;
      this.traits = model?.traits;
      this.section1035Exchange = model?.section_1035_exchange;
      this.strifeEnabled = model?.strife_enabled;
      this.planType = model?.plan_type;
      this.primaryProduct = model?.primary_product;
      this.minPremium = numberOrNull(model?.min_premium);
      this.maxPremium = numberOrNull(model?.max_premium);
      this.minDeathBenefit = numberOrNull(model?.min_death_benefit);
      this.maxDeathBenefit = numberOrNull(model?.max_death_benefit);
      if (Array.isArray(model?.solves)) {
        this.solves = model.solves;
      }
      if (Array.isArray(model?.pay_duration)) {
        this.payDuration = model.pay_duration;
      }

      this.carrier = new CarrierFactory();
      this.carrier.setFromRequest(model?.carrier);

      this.schedules = new Schedules();
      this.schedules.setFromRequest(model?.schedules);

      this.payouts = [];
      model?.payouts?.forEach(rawPayout => {
        this.payouts.push(setPayoutFromRequest(rawPayout));
      });

      this.productBuildCharts = [];
      model?.product_build_charts?.forEach(rawProductBuildChart => {
        const productBuildChart = new ProductBuildChart();
        productBuildChart.setFromRequest(rawProductBuildChart);
        this.productBuildCharts.push(productBuildChart);
      });

      this.productCitizenshipProfiles = [];
      model?.product_citizenship_profiles?.forEach(rawCitizenshipProfile => {
        const citizenshipProfile = new ProductCitizenshipProfile();
        citizenshipProfile.setFromRequest(rawCitizenshipProfile);
        this.productCitizenshipProfiles.push(citizenshipProfile);
      });

      this.productSmokerCharts = [];
      model?.product_smoker_charts?.forEach(rawProductSmokerChart => {
        const productSmokerChart = new ProductSmokerChartFactory();
        productSmokerChart.setFromRequest(rawProductSmokerChart);
        this.productSmokerCharts.push(productSmokerChart);
      });

      this.guides = [];
      model?.guides?.forEach(rawGuide => {
        const guide = new ProductAdvisorGuide();
        guide.setFromRequest(rawGuide);
        this.guides.push(guide);
      });

      let usingGuides = Boolean(this.guides.length);

      if (model?.advisor_guide) {
        this.advisorGuide = model.advisor_guide.id;
        if (!usingGuides) {
          const advisorGuide = new ProductAdvisorGuide();
          advisorGuide.setFromRequest({
            name: "Advisor Guide",
            ...model.advisor_guide
          });

          this.guides.push(advisorGuide);
        }
      }
      if (model?.consumer_guide) {
        this.consumerGuide = model.consumer_guide.id;
        if (!usingGuides) {
          const advisorGuide = new ProductAdvisorGuide();
          advisorGuide.setFromRequest({
            name: "Consumer Guide",
            ...model.consumer_guide
          });
        }
      }
      if (model?.underwriting_guide) {
        this.underwritingGuide = model.underwriting_guide.id;
        if (!usingGuides) {
          this.guides.push({
            name: "Underwriting Guide",
            category: model.underwriting_guide.category,
            uid: model.underwriting_guide.uid,
            id: model.underwriting_guide.id
          });
        }
      }

      this.temporaryInsuranceProfileProducts = [];
      model?.temporary_insurance_profile_products?.forEach(
        ({ temporary_insurance_profile }) => {
          const profile = new TemporaryInsuranceProfile();
          profile.setFromRequest(temporary_insurance_profile);
          this.temporaryInsuranceProfileProducts.push(profile);
        }
      );

      this.compBuilders = [];
      model?.carrier?.comp_builder?.forEach(rawCompBuilder => {
        const compBuilder = new CompBuilderFactory();
        compBuilder.setFromRequest(rawCompBuilder);
        this.compBuilders.push(compBuilder);
      });
    },

    generateAttributeMap() {
      const toFrac = val => Number(val) / 100;
      return {
        conversion: { conversion: this.conversion },
        formSet: { form_set_id: this.formSet },
        activeYears: { active_years: parseInt(this.activeYears) },
        ageNearest: { age_nearest: this.ageNearest?.toString() },
        carrier: { carrier_id: this.carrier.id },
        eDelivery: { carrier_e_delivery: this.eDelivery?.toString() },
        chargeback: { chargeback: this.chargeback },
        advisorUseOnly: { advisor_use_only: this.advisorUseOnly },
        criticalIllnessRider: {
          critical_illness_rider: this.criticalIllnessRider
        },
        chronicIllnessRider: {
          chronic_illness_rider: this.chronicIllnessRider
        },
        noCostChronicIllnessRider: {
          no_cost_chronic_illness_rider: this.noCostChronicIllnessRider
        },
        noCostCriticalIllnessRider: {
          no_cost_critical_illness_rider: this.noCostCriticalIllnessRider
        },
        line: { line: this.line?.toLowerCase() },
        linePercentage: {
          line_percentage: toFrac(this.linePercentage)?.toString()
        },
        ltcRider: { ltc_rider: this.ltcRider },
        vitalityRider: { vitality_rider: this.vitalityRider },
        name: { name: this.name },
        planType: { plan_type: this.planType },
        primaryProduct: { primary_product: this.primaryProduct ? "1" : "0" },
        rollingTarget: { rolling_target: this.rollingTarget },
        section1035Exchange: {
          section_1035_exchange: this.section1035Exchange
        },
        solves: { solves: this.solves },
        streetExcess: { street_excess: toFrac(this.streetExcess) },
        streetFirst: { street_first: toFrac(this.streetFirst) },
        streetRenewal: { street_renewal: toFrac(this.streetRenewal) },
        streetPua: { street_pua: toFrac(this.streetPua) },
        surrenderPeriod: { surrender_period: parseInt(this.surrenderPeriod) },
        totalExcess: { total_excess: toFrac(this.totalExcess) },
        totalFirst: { total_first: toFrac(this.totalFirst) },
        totalRenewal: { total_renewal: toFrac(this.totalRenewal) },
        totalPua: { total_pua: toFrac(this.totalPua) },
        strifeEnabled: { strife_enabled: this.strifeEnabled },
        type: { type: this.type },
        traits: { traits: this.traits },
        instantAps: { instant_aps: this.instantAps },
        advisorGuide: { advisor_guide_id: this.advisorGuide },
        consumerGuide: { consumer_guide_id: this.consumerGuide },
        underwritingGuide: { underwriting_guide_id: this.underwritingGuide },
        minAge: { min_age: this.minAge },
        maxAge: { max_age: this.maxAge },
        minPremium: { min_premium: this.minPremium },
        maxPremium: { max_premium: this.maxPremium },
        minDeathBenefit: { min_death_benefit: this.minDeathBenefit },
        maxDeathBenefit: { max_death_benefit: this.maxDeathBenefit },
        payDuration: { pay_duration: this.payDuration }
      };
    },
    getAttributeRequestValue(attribute) {
      const attributeMap = this.generateAttributeMap();
      return attributeMap[attribute];
    }
  };
}

export function Schedules(model = {}) {
  return {
    onyx: model?.onyx || null,
    diamond: model?.diamond || null,
    gold: model?.gold || null,
    platinum: model?.platinum || null,
    silver: model?.silver || null,
    bronze: model?.bronze || null,

    setFromRequest(model = {}) {
      this.onyx = model?.onyx;
      this.diamond = model?.diamond;
      this.gold = model?.gold;
      this.platinum = model?.platinum;
      this.silver = model?.silver;
      this.bronze = model?.bronze;
    }
  };
}

export function BuildChart(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || "",
    setFromRequest(model = {}) {
      this.id = model?.id;
      this.name = model?.name;
    }
  };
}

export function ProductBuildChart(model = {}) {
  return {
    buildChart: new BuildChart(model?.buildChart),
    id: model?.id || null,
    setFromRequest(model = {}) {
      this.buildChart = new BuildChart();
      this.buildChart.setFromRequest(model?.build_chart);
      this.id = model?.id;
    },
    toCreateRequest(productId) {
      return {
        product_build_chart: {
          build_chart_id: this.buildChart.id,
          product_id: productId
        }
      };
    }
  };
}

export function CitizenshipProfile(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || null,
    setFromRequest(model = {}) {
      this.id = model?.id;
      this.name = model?.name;
    }
  };
}
export function ProductCitizenshipProfile(model = {}) {
  return {
    id: model?.id || null,
    citizenshipProfile: model?.citizenshipProfile || {},
    setFromRequest(model = {}) {
      this.id = model?.id;
      this.citizenshipProfile = new CitizenshipProfile();
      this.citizenshipProfile.setFromRequest(model?.citizenship_profile);
    },
    toCreateRequest(productId) {
      return {
        product_citizenship_profile: {
          citizenship_profile_id: this.citizenshipProfile.id,
          product_id: productId
        }
      };
    }
  };
}

export function TemporaryInsuranceProfile(model = {}) {
  return {
    complete: model?.complete || false,
    daysOfCoverage: model?.daysOfCoverage || null,
    id: model?.id || null,
    maxBindAgeInDays: model?.maxBindAgeInDays || null,
    maxFaceAmount: model?.maxFaceAmount || null,
    maxFaceAmountIfExistingCoverageFromSameCarrier:
      model?.maxFaceAmountIfExistingCoverageFromSameCarrier || null,
    minBindAgeInDays: model?.minBindAgeInDays || null,
    name: model?.name || null,
    maxFaceAmountToBind: model?.maxFaceAmountToBind || null,
    setFromRequest(model = {}) {
      this.complete = model?.complete;
      this.daysOfCoverage = model?.days_of_coverage;
      this.id = model?.id;
      this.maxBindAgeInDays = model?.max_bind_age_in_days;
      this.maxFaceAmount = model?.max_face_amount;
      this.maxFaceAmountIfExistingCoverageFromSameCarrier =
        model?.max_face_amount_if_existing_coverage_from_same_carrier;
      this.minBindAgeInDays = model?.min_bind_age_in_days;
      this.maxFaceAmountToBind = model?.max_face_amount_to_bind;
      this.name = model?.name;
    }
  };
}

export function ProductAdvisorGuide(model = {}) {
  return {
    category: model?.category,
    uid: model?.uid,
    id: model?.id,
    name: model?.name,
    setFromRequest(model = {}) {
      this.category = model?.category;
      this.uid = model?.uid;
      this.id = model?.id;
      this.name = model?.name;
    }
  };
}

export const MYGA_WITHDRAWAL_ALLOWANCES = ["None", "Interest", "5%", "10%"];
export function MYGARate(model = {}) {
  return {
    id: model?.id,
    year: model?.year,
    rate: model?.rate,
    surrenderCharge: model?.surrenderCharge,
    withdrawalAllowance: model?.withdrawalAllowance,
    rateBand: new RateBand(model?.rateBand),
    productId: model?.productId,
    setFromRequest(rawModel = {}) {
      this.id = rawModel?.id;
      this.year = rawModel?.year;
      this.rate = rawModel?.rate;
      this.surrenderCharge = rawModel?.surrender_charge;
      this.withdrawalAllowance = rawModel?.withdrawal_allowance;
      this.productId = rawModel?.product_id;

      this.rateBand = new RateBand();
      this.rateBand.setFromRequest(rawModel?.rate_band);
    }
  };
}

export function RateBand(model = {}) {
  return {
    id: model?.id,
    min: model?.min,
    max: model?.max,
    get display() {
      if (this.min && this.max) {
        return `${currencyFormat(this.min)} - ${currencyFormat(this.max)}`;
      } else if (this.min) {
        return `> ${currencyFormat(this.min)}`;
      } else if (this.max) {
        return `< ${currencyFormat(this.max)}`;
      }
      return "";
    },
    setFromRequest(rawModel = {}) {
      this.id = rawModel?.id;
      this.min = rawModel?.min;
      this.max = rawModel?.max;
      return this;
    }
  };
}

export function Payout(model = {}) {
  return {
    id: model?.id || null,
    payor: new PayorFactory(model?.payor),
    endYear: model?.endYear || null,
    percent: model?.percent || null,
    startYear: model?.startYear || null,
    compBuilder: model?.compBuilder || {},
    paymentType: model?.paymentType || null,
    commissionType: model?.commissionType || ""
  };
}

export function setPayoutFromRequest(model = {}) {
  const payout = Payout();
  payout.commissionType = model?.commission_type;
  payout.compBuilder = new CompBuilderFactory();
  payout.compBuilder.setFromRequest(model?.comp_builder);
  payout.endYear = model?.end_year;
  payout.percent = model?.percent;
  payout.startYear = model?.start_year;
  payout.id = model?.id;

  payout.payor = new PayorFactory();
  payout.payor.setFromRequest(model?.payor);
  return payout;
}

export function PayoutToCreateRequest(payout, productId) {
  return {
    payout: {
      commission_type: payout.commissionType,
      comp_builder_id: payout.compBuilder.id,
      end_year: payout.endYear,
      start_year: payout.startYear,
      payor_id: payout.payor.id,
      percent: Number(payout.percent) / 100,
      product_id: productId
    }
  };
}

export function PayoutToUpdateRequest(payout) {
  return {
    payout: {
      commission_type: payout.commissionType,
      comp_builder_id: payout.compBuilder.id,
      end_year: payout.endYear,
      start_year: payout.startYear,
      payor_id: payout.payor.id,
      percent: Number(payout.percent) / 100
    }
  };
}

export function ProductCreateToCreateRequest(product) {
  const toDecimal = v => +(Number(v || 0) / 100).toFixed(6);
  return {
    active_years: +product.activeYears,
    age_nearest: Boolean(product.ageNearest),
    backnine_policy_fee: 125,
    carrier_e_delivery: Boolean(product.eDelivery),
    carrier_id: product.carrier.id,
    chargeback: product.chargeback,
    line: product.line.toLowerCase(),
    line_percentage: toDecimal(product.linePercentage),
    name: product.name,
    primary_product: Boolean(product.primaryProduct),
    rolling_target: product.rollingTarget,
    street_excess: toDecimal(product.streetExcess),
    street_first: toDecimal(product.streetFirst),
    street_renewal: toDecimal(product.streetRenewal),
    street_pua: toDecimal(product.streetPua),
    total_excess: toDecimal(product.totalExcess),
    total_first: toDecimal(product.totalFirst),
    total_renewal: toDecimal(product.totalRenewal),
    total_pua: toDecimal(product.totalPua),
    traits: product.traits,
    type: product.type,
    surrender_period: +product.surrenderPeriod
  };
}

export function ProductCreate() {
  return {
    activeYears: null,
    ageNearest: null,
    policyFee: null,
    eDelivery: null,
    carrier: null,
    chargeback: null,
    line: null,
    linePercentage: null,
    name: null,
    primaryProduct: null,
    rollingTarget: null,
    streetExcess: null,
    streetFirst: null,
    streetRenewal: null,
    streetPua: null,
    totalExcess: null,
    totalFirst: null,
    totalRenewal: null,
    totalPua: null,
    surrenderPeriod: null
  };
}
