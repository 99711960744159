<template>
  <v-form>
    <v-row dense>
      <v-col cols="12">
        <h3 class="text-h4 mb-3 font-weight-light">Invite an Assistant</h3>
        <p class="text-body-1">
          {{ assistantSubtext }}
        </p>
      </v-col>
      <v-col cols="12">
        <basic-email-input
          v-model="advisor.email"
          data-testid="assistant-email"
          autofocus
          required
          prepend-inner-icon="$mdi-email"
          outlined
          dense
          label="E-Mail Address"
          :disabled="loading"
          :success="emailValidation.success"
          :error-messages="emailValidation.errorMessages"
          @valid="emailIsValid = $event"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="advisor.firstName"
          data-testid="assistant-first-name"
          data-lpignore="true"
          prepend-inner-icon="$mdi-account"
          outlined
          dense
          label="First Name"
          :disabled="loading"
          :success="firstNameValidation.success"
          :error-messages="firstNameValidation.errorMessages"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="advisor.lastName"
          data-lpignore="true"
          data-testid="assistant-last-name"
          prepend-inner-icon="$mdi-account-multiple"
          label="Last Name"
          outlined
          dense
          :disabled="loading"
          :success="lastNameValidation.success"
          :error-messages="lastNameValidation.errorMessages"
        />
      </v-col>

      <v-col v-if="!isGroupOne" cols="12">
        <advisor-search
          v-model="advisor.ownable"
          data-testid="assistant-advisor"
          label="Assisting which Advisor?"
          persistent-hint
          hint="Whose assistant is this?"
          :disabled="loading"
          :success="ownableValidation.success"
          :error-messages="ownableValidation.errorMessages"
          @input="fetchSignerInfo"
        />
      </v-col>

      <v-fade-transition mode="out-in">
        <v-col v-if="signerRelationships.length" cols="12">
          <v-card flat>
            <v-card-title class="pl-0">
              Give {{ loweredConnectionName }} access to these advisors
            </v-card-title>

            <v-chip-group v-model="additionalAccessTo" multiple>
              <v-chip
                v-for="{ key, name } in signerRelationships"
                :key="key"
                :value="key"
                :color="additionalAccessTo.includes(key) ? 'primary' : null"
              >
                {{ name }}
              </v-chip>
            </v-chip-group>
          </v-card>
        </v-col>
      </v-fade-transition>

      <v-col v-if="advisorName" cols="12">
        <v-card flat>
          <v-card-title class="pl-0"> Access Settings </v-card-title>
          <v-card-subtitle class="pl-0">
            Define {{ loweredConnectionName }}'s access to
            {{ advisorName }}
          </v-card-subtitle>
          <v-card-text class="pa-0">
            <v-row dense>
              <v-fade-transition mode="out-in">
                <v-col v-if="advisorName" cols="12" class="pa-0 ma-n2">
                  <connection-settings
                    :advisor-name="advisorName"
                    :connection-name="connectionName"
                    :appointments.sync="advisor.permissions.appointments"
                    :appointments-emails.sync="
                      advisor.permissions.appointmentsEmails
                    "
                    :cases.sync="advisor.permissions.cases"
                    :cases-emails.sync="advisor.permissions.casesEmails"
                    :contract-parties.sync="advisor.permissions.contractParties"
                    :contract-parties-emails.sync="
                      advisor.permissions.contractPartiesEmails
                    "
                    :eapps.sync="advisor.permissions.eapps"
                    :eapps-emails.sync="advisor.permissions.eappsEmails"
                    :payments.sync="advisor.permissions.payments"
                    :payments-emails.sync="advisor.permissions.paymentsEmails"
                    :quick-quotes.sync="advisor.permissions.quickQuotes"
                    :quick-quotes-emails.sync="
                      advisor.permissions.quickQuotesEmails
                    "
                    :quotes.sync="advisor.permissions.quotes"
                    :quotes-emails.sync="advisor.permissions.quotesEmails"
                  />
                </v-col>
              </v-fade-transition>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="advisorName" cols="12" class="mt-5">
        <v-divider />
      </v-col>
      <v-col cols="12" class="mt-3">
        <p style="font-size: 16px; font-weight: bold">
          Subscribe to marketing emails?
        </p>
        <v-radio-group
          v-model="advisor.marketing"
          row
          data-testid="assistant-marketing"
          :disabled="loading"
          :success="marketingValidation.success"
          :error-messages="marketingValidation.errorMessages"
        >
          <v-radio :value="true" label="Yes" />
          <v-radio :value="false" label="No" />
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          class="text-none"
          data-testid="assistant-create"
          :loading="loading"
          @click="createAssistant"
        >
          <v-icon class="mr-1"> $mdi-plus </v-icon> Create Assistant
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { getSignerRelationships } from "@/api/advisors.service";
import { listToSentence, parseErrorMessage } from "@/util/helpers";
import ConnectionSettings from "@/components/shared/ConnectionSettings.vue";
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";

import { mapActions, mapState } from "pinia";
import { inviteAssistant } from "@/api/invites.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  name: "AgentForm",
  components: {
    ConnectionSettings,
    AdvisorSearch,
    BasicEmailInput
  },
  data() {
    return {
      emailIsValid: null,
      advisor: {
        firstName: null,
        lastName: null,
        email: null,
        additionalAccessTo: [],
        ownable: {},
        permissions: {
          appointments: true,
          appointmentsEmails: true,
          cases: true,
          casesEmails: true,
          contractParties: true,
          contractPartiesEmails: true,
          eapps: true,
          eappsEmails: true,
          payments: true,
          paymentsEmails: true,
          quickQuotes: true,
          quickQuotesEmails: true,
          quotes: true,
          quotesEmails: true
        }
      },
      signerRelationships: [],
      loading: false,
      type: null
    };
  },
  created() {
    if (this.isGroupOne) this.fetchSignerInfo();
  },
  watch: {
    "advisor.assignment"(val) {
      if (!val) this.advisor.lockCommissions = false;
    }
  },
  computed: {
    ...mapState(useUserStore, {
      isGroupOne: "isGroupOne",
      loginable: "loginable"
    }),
    additionalAccessTo: {
      get() {
        return this.advisor.additionalAccessTo.map(({ key }) => key);
      },
      set(v) {
        if (!v) {
          this.advisor.additionalAccessTo.splice(
            0,
            this.advisor.additionalAccessTo
          );
          return;
        }

        const advisors = [];
        v.forEach(key => {
          const advisor = this.signerRelationships.find(
            ({ key: k }) => key === k
          );
          advisors.push(advisor);
        });

        this.$set(this.advisor, "additionalAccessTo", advisors);
      }
    },
    assistantSubtext() {
      if (this.isGroupOne)
        return "Create an account that can access your eapps, Cases, Appointments, etc.";
      return "Create an account with access to an agent";
    },
    advisorName() {
      //is group one this.currentLogin else this.advisor.ownable
      const access = [];
      if (this.isGroupOne) {
        access.push(this.loginable.name);
      } else {
        access.push(this.advisor.ownable.name);
      }
      access.push(...this.advisor.additionalAccessTo.map(v => v.name));
      return listToSentence(access);
    },
    connectionName() {
      return (
        [this.advisor.firstName, this.advisor.lastName]
          .filter(Boolean)
          .join(" ") || "The Assistant"
      );
    },
    loweredConnectionName() {
      if (this.connectionName === "The Assistant") return "the assistant";
      return this.connectionName;
    },
    ownableValidation() {
      const success =
        !this.$v.advisor.ownable.$invalid && Boolean(this.advisor?.ownable?.id);
      const errorMessages = [];
      if (!this.$v.advisor.ownable.$dirty) return { success, errorMessages };
      else if (!this.$v.advisor.ownable.required)
        errorMessages.push("Required");
      return { success, errorMessages };
    },
    emailValidation() {
      const success = !this.$v.advisor.email.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.email.$dirty) return { success, errorMessages };
      if (!this.$v.advisor.email.required) {
        errorMessages.push("Please provide a valid email address");
      }
      if (!this.$v.advisor.email.isValid) {
        errorMessages.push("Email is invalid");
      }
      return { success, errorMessages };
    },
    firstNameValidation() {
      const success = !this.$v.advisor.firstName.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.firstName.$dirty) return { success, errorMessages };
      if (!this.$v.advisor.firstName.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    lastNameValidation() {
      const success = !this.$v.advisor.lastName.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.lastName.$dirty) return { success, errorMessages };
      if (!this.$v.advisor.lastName.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    marketingValidation() {
      const success = !this.$v.advisor.marketing.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.marketing.$dirty) return { success, errorMessages };
      if (!this.$v.advisor.marketing.required) errorMessages.push("Required");
      return { success, errorMessages };
    }
  },
  validations() {
    return {
      advisor: {
        email: {
          required: Boolean,
          isValid: () => this.emailIsValid === true
        },
        ownable: {
          required: val => this.isGroupOne || Boolean(val?.id)
        },
        firstName: {
          required: val => Boolean(val)
        },
        lastName: {
          required: val => Boolean(val)
        },
        marketing: {
          required: val => [true, false].includes(val)
        }
      }
    };
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    async fetchSignerInfo() {
      try {
        this.signerRelationships.splice(0, this.signerRelationships.length);
        this.advisor.additionalAccessTo.splice(
          0,
          this.advisor.additionalAccessTo.length
        );
        let relationships;
        if (this.isGroupOne) {
          relationships = await this.fetchSignerInfoGroupOne();
        } else {
          relationships = await this.fetchSignerInfoGroupTwoPlus();
        }

        const relationshipsWithKeys = relationships.map(
          ({ id, type, ...attrs }) => ({
            id,
            type,
            ...attrs,
            key: `${type}-${id}`
          })
        );

        this.signerRelationships.push(...relationshipsWithKeys);

        this.advisor.additionalAccessTo.push(...this.signerRelationships);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      }
    },
    async fetchSignerInfoGroupOne() {
      return await getSignerRelationships();
    },
    async fetchSignerInfoGroupTwoPlus() {
      const ownableId = this.advisor.ownable?.id;
      const ownableType = this.advisor.ownable?.type;
      if (!ownableId || !ownableType) return [];
      return await getSignerRelationships(ownableId, ownableType);
    },
    async createAssistant() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;

        const body = {
          email: this.advisor.email,
          subscribe_to_marketing_emails: this.advisor.marketing,
          first_name: undefined,
          last_name: undefined,
          marketing_manager_id: undefined,
          npn: undefined,
          assign_commissions: undefined,
          lock_commissions: undefined,
          type: undefined,
          permissions: undefined,
          access_to: undefined,
          ownable_id: undefined,
          ownable_type: undefined
        };

        body.first_name = this.advisor.firstName;
        body.last_name = this.advisor.lastName;

        const permissions = this.advisor.permissions;
        body.permissions = {
          appointments: permissions.appointments,
          appointments_emails: permissions.appointmentsEmails,
          cases: permissions.cases,
          cases_emails: permissions.casesEmails,
          contract_parties: permissions.contractParties,
          contract_parties_emails: permissions.contractPartiesEmails,
          electronic_applications: permissions.eapps,
          electronic_applications_emails: permissions.eappsEmails,
          payments: permissions.payments,
          payments_emails: permissions.paymentsEmails,
          quick_quotes: permissions.quickQuotes,
          quick_quotes_emails: permissions.quickQuotesEmails,
          quotes: permissions.quotes,
          quotes_emails: permissions.quotesEmails
        };

        body.access_to = [];

        const advisors = this.advisor.additionalAccessTo.map(
          ({ id, type }) => ({
            id,
            type
          })
        );

        body.access_to.push(...advisors);

        if (!this.isGroupOne) {
          body.ownable_id = this.advisor.ownable.id;
          body.ownable_type = this.advisor.ownable.type;
          body.marketing_manager_id = this.advisor.ownable.marketing_manager_id;
        }

        if (this.isGroupOne) {
          body.access_to.push({
            id: this.loginable.id,
            type: this.loginable.type
          });
        } else {
          body.access_to.push({
            id: this.advisor.ownable.id,
            type: this.advisor.ownable.type
          });
        }

        const id = await inviteAssistant(body);
        this.$router.push({
          name: "AgentView",
          params: {
            id
          }
        });
      } catch (error) {
        this.showErrorSnackbar({
          message: parseErrorMessage(error),
          timeout: -1
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
