var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"px-4 py-2 my-2",attrs:{"justify":_vm.sentByMe ? 'end' : 'start'}},[_c('v-col',{staticClass:"py-0 px-2",attrs:{"cols":"12"}},[(_vm.sentByMe)?_c('h5',{staticClass:"pl-3 pb-0",staticStyle:{"font-weight":"400","text-align":"right"}},[_c('timestamp-formatter',{attrs:{"format":"date-time","value":_vm.message.createdAt}}),(_vm.message.isTemplate)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" $mdi-file-document ")]):_vm._e(),(_vm.message.email || _vm.message.carrierEmail)?_c('chat-message-email-indicator',{attrs:{"full-email":_vm.message.fullEmail,"message-id":_vm.message.id}}):_vm._e(),(_vm.canDelete)?_c('v-btn',{attrs:{"color":"red","x-small":"","icon":"","data-testid":"delete-message","loading":_vm.loading},on:{"click":_vm.destroyConfirmation}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" $mdi-delete ")])],1):_vm._e()],1):_c('div',[_c('h5',{staticClass:"pb-0",staticStyle:{"font-weight":"400"}},[(_vm.message.ownable)?[_vm._v(" "+_vm._s(_vm.message.ownable.name)+" "),_c('timestamp-formatter',{attrs:{"format":"date-time","value":_vm.message.createdAt}})]:_vm._e(),(_vm.message.email || _vm.message.carrierEmail)?_c('chat-message-email-indicator',{attrs:{"full-email":_vm.message.fullEmail,"message-id":_vm.message.id}}):_vm._e(),(_vm.canDelete)?_c('v-btn',{attrs:{"color":"red","icon":"","x-small":"","data-testid":"delete-message","loading":_vm.loading},on:{"click":_vm.destroyConfirmation}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" $mdi-delete ")])],1):_vm._e()],2),(_vm.message.inboundEmail)?_c('h5',{staticClass:"pb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" Replied from "+_vm._s(_vm.message.inboundEmail)+" ")]):_vm._e()])]),_c('div',{staticClass:"px-2",staticStyle:{"display":"flex","flex-direction":"column"},style:({
      width: _vm.usingIframe ? '100%' : null,
      maxWidth: _vm.maxWidth
    })},[_c('div',{staticStyle:{"width":"100%"}},[_c('v-card',{staticClass:"pa-3 chat-message-card",class:{
          'sent-by-me': _vm.sentByMe,
          'bottom-right-point': _vm.sentByMe,
          'sent-by-other': !_vm.sentByMe,
          'bottom-left-point': !_vm.sentByMe
        },attrs:{"color":_vm.sentByMe ? 'primary' : 'secondary'}},[(_vm.usingIframe)?[_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"position":"absolute","top":"15px","right":"15px"},attrs:{"icon":""},on:{"click":_vm.showIframeFullscreen}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" $mdi-arrow-expand-all ")])],1)]}}],null,false,2623840772)},[_c('span',[_vm._v("View Fullscreen")])]),_c('div',{staticClass:"frame-wrap"},[_c('template-renderer',{staticClass:"frame",attrs:{"value":_vm.message.html || _vm.message.note}})],1)],1)]:(_vm.message.isTemplate)?_c('div',{staticClass:"mb-0 template-message",domProps:{"innerHTML":_vm._s(_vm.sanitize(_vm.message.note))}}):(_vm.message.html)?_c('div',{staticClass:"mb-0 plain-message",domProps:{"innerHTML":_vm._s(_vm.sanitize(_vm.message.html))}}):(_vm.message.note)?_c('include-links-in-text',{staticClass:"mb-0 plain-message",attrs:{"text":_vm.message.note}}):_vm._e(),_vm._l((_vm.message.documents),function(document,index){return _c('a',{key:index,staticClass:"message-link text-decoration-underline clickable ma-1",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.download(document)}}},[_vm._v(" "+_vm._s(document.name)+" "),(document.fileSize)?_c('span',[_vm._v("("+_vm._s(document.fileSize)+")")]):_vm._e()])})],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }