<template>
  <v-select
    v-if="filterType === TableHeader.SELECT_TYPE"
    v-model="model"
    attach
    hide-details
    outlined
    dense
    :data-testid="text"
    :menu-props="{ bottom: true, offsetY: true }"
    :items="items"
    :label="text"
    :clearable="!isMandatory"
    :prepend-inner-icon="icon"
    ref="filterRef"
  />
  <v-select
    v-else-if="filterType === TableHeader.MULTI_SELECT_TYPE"
    v-model="model"
    attach
    hide-details
    outlined
    dense
    multiple
    :data-testid="text"
    :menu-props="{ bottom: true, offsetY: true }"
    :items="items"
    :label="text"
    :prepend-inner-icon="icon"
    ref="filterRef"
  />
  <v-autocomplete
    v-else-if="filterType === TableHeader.AUTOCOMPLETE_TYPE"
    v-model="model"
    attach
    hide-details
    outlined
    dense
    :data-testid="text"
    :menu-props="{ bottom: true, offsetY: true }"
    :items="items"
    :label="text"
    :clearable="!isMandatory"
    :prepend-inner-icon="icon"
    ref="filterRef"
  />
  <div
    v-else-if="filterType === TableHeader.CHECKBOX_TYPE"
    class="checkbox-width"
  >
    <v-checkbox
      v-model="model"
      class="mt-0"
      hide-details
      dense
      :true-value="checkboxValues.true"
      :false-value="checkboxValues.false"
      :data-testid="text"
      :label="text"
      ref="filterRef"
    />
  </div>

  <payor-search
    v-else-if="filterType === TableHeader.PAYOR_SEARCH_TYPE"
    v-model="model"
    outlined
    dense
    hide-details
    :data-testid="text"
    placeholder=""
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <contract-party-search
    v-else-if="filterType === TableHeader.CONTRACT_PARTY_SEARCH_TYPE"
    v-model="model"
    outlined
    dense
    hide-details
    placeholder=""
    simple
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <form-set-search
    v-else-if="filterType === TableHeader.FORM_SET_SEARCH_TYPE"
    v-model="model"
    outlined
    dense
    hide-details
    placeholder=""
    simple
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <policy-search
    v-else-if="filterType === TableHeader.POLICY_SEARCH_TYPE"
    v-model="model"
    outlined
    dense
    hide-details
    placeholder=""
    simple
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <advisor-search
    v-else-if="filterType === TableHeader.ADVISOR_SEARCH_TYPE"
    v-model="model"
    outlined
    dense
    hide-details
    placeholder=""
    simple
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <marketing-manager-search
    v-else-if="filterType === TableHeader.MARKETING_MANAGER_SEARCH_TYPE"
    v-model="model"
    outlined
    dense
    hide-details
    placeholder=""
    simple
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <case-manager-search
    v-else-if="filterType === TableHeader.CASE_MANAGER_SEARCH_TYPE"
    v-model="model"
    outlined
    dense
    hide-details
    placeholder=""
    simple
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <advisor-search
    v-else-if="filterType === TableHeader.ADVISOR_PAYMENT_SEARCH_TYPE"
    v-model="model"
    outlined
    dense
    hide-details
    placeholder=""
    payment
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <carrier-search
    v-else-if="filterType === TableHeader.CARRIER_SEARCH_TYPE"
    v-model="model"
    outlined
    dense
    hide-details
    placeholder=""
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <agent-search
    v-else-if="filterType === TableHeader.AGENT_SEARCH_TYPE"
    v-model="model"
    outlined
    dense
    hide-details
    placeholder=""
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <product-search
    v-else-if="filterType === TableHeader.PRODUCT_SEARCH_TYPE"
    v-model="model"
    outlined
    dense
    hide-details
    placeholder=""
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <pricing-plan-search
    v-else-if="filterType === TableHeader.PRICING_PLAN_SEARCH_TYPE"
    v-model="model"
    outlined
    dense
    hide-details
    placeholder=""
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <form-categories-search
    v-else-if="filterType === TableHeader.FORM_CATEGORY"
    :full.sync="model"
    outlined
    dense
    hide-details
    placeholder=""
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <vendor-search
    v-else-if="filterType === TableHeader.VENDOR_SEARCH_TYPE"
    v-model="model"
    outlined
    dense
    hide-details
    placeholder=""
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <affiliation-search
    v-else-if="filterType === TableHeader.AFFILIATION_SEARCH_TYPE"
    v-model="model"
    outlined
    dense
    hide-details
    placeholder=""
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <sitewide-search
    v-else-if="filterType === TableHeader.GLOBAL_SEARCH_FILTER_TYPE"
    v-model="model"
    outlined
    dense
    hide-details
    placeholder=""
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
  />
  <v-text-field
    v-else-if="filterType === TableHeader.STRING_TYPE"
    v-model="model"
    data-lpignore="true"
    hide-details
    outlined
    dense
    :data-testid="text"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
    :prepend-inner-icon="icon"
  />
  <v-text-field
    v-else-if="filterType === TableHeader.NUMBER_TYPE"
    v-model="model"
    data-lpignore="true"
    hide-details
    outlined
    dense
    :data-testid="text"
    inputmode="numeric"
    :clearable="!isMandatory"
    :label="text"
    ref="filterRef"
    :prepend-inner-icon="icon"
  />
  <date-filter-picker
    v-else-if="filterType === TableHeader.DATE_TYPE"
    v-model="model"
    :data-testid="text"
    :color="color"
    :header-text="text"
    :future-date-values="futureDateValues"
    ref="filterRef"
  />
  <number-filter-picker
    v-else-if="filterType === TableHeader.NUMBER_RANGE"
    v-model="model"
    :data-testid="text"
    :color="color"
    ref="filterRef"
    :label="text"
    :is-currency="isCurrency"
  />
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";

import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import PayorSearch from "@/components/shared/PayorSearch.vue";
import PolicySearch from "@/components/shared/PolicySearch.vue";
import ContractPartySearch from "@/components/shared/ContractPartySearch.vue";
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import AgentSearch from "@/components/shared/AgentSearch.vue";
import ProductSearch from "@/components/shared/ProductSearch.vue";
import CaseManagerSearch from "@/components/shared/CaseManagerSearch.vue";
import MarketingManagerSearch from "@/components/shared/MarketingManagerSearch.vue";
import FormSetSearch from "@/components/shared/FormSetSearch.vue";
import DateFilterPicker from "@/components/shared/DateFilterPicker.vue";
import PricingPlanSearch from "@/components/shared/PricingPlanSearch.vue";
import VendorSearch from "@/components/shared/VendorSearch.vue";
import AffiliationSearch from "@/components/shared/AffiliationSearch.vue";
import NumberFilterPicker from "@/components/shared/NumberFilterPicker.vue";
import FormCategoriesSearch from "@/components/shared/FormCategoriesSearch.vue";
import SitewideSearch from "@/components/shared/SitewideSearch.vue";

import {
  defineProps,
  defineEmits,
  ref,
  toRefs,
  watch,
  defineExpose,
  computed
} from "vue";
const props = defineProps({
  filterType: { type: String, required: true },
  items: { type: Array, required: false, default: () => [] },
  text: { type: String, required: true },
  isCurrency: Boolean,
  value: {
    type: [String, Number, Object, Boolean, Array],
    required: false,
    default: null
  },
  isMandatory: Boolean,
  icon: { type: [String, Object], required: false, default: null },
  futureDateValues: Boolean,
  color: {
    type: String,
    default: "section"
  },
  checkboxValues: {
    type: Object,
    required: false,
    default: () => ({ true: true, false: false })
  }
});

const emit = defineEmits(["update-filter"]);

const {
  filterType,
  items,
  text,
  isCurrency,
  value,
  isMandatory,
  icon,
  futureDateValues,
  color
} = toRefs(props);

const model = ref(null);

watch(
  value,
  (newV, oldV) => {
    if (valuesAreEqual(newV, oldV)) return;
    model.value = JSON.parse(JSON.stringify(newV));
  },
  { immediate: true, deep: true }
);

watch(
  model,
  (newV, oldV) => {
    if (valuesAreEqual(newV, oldV)) return;
    emit("input", JSON.parse(JSON.stringify(newV)));
    if (isDebouncedInput.value) debounceAndUpdateFilter();
    else updateFilter();
  },
  { deep: true }
);

const isDebouncedInput = computed(() =>
  [
    TableHeader.MULTI_SELECT_TYPE,
    TableHeader.STRING_TYPE,
    TableHeader.NUMBER_TYPE,
    TableHeader.DATE_TYPE,
    TableHeader.NUMBER_RANGE
  ].includes(filterType.value)
);
function updateFilter() {
  emit("update-filter");
}

let timeout;
function debounceAndUpdateFilter() {
  if (timeout) clearTimeout(timeout);
  timeout = setTimeout(() => updateFilter(), 500);
}

function valuesAreEqual(newV, oldV) {
  let current = newV;
  let previous = oldV;

  const isArray = Array.isArray(current) || Array.isArray(previous);
  const isObject = typeof current === "object" || typeof previous === "object";
  const requiresJSONCheck = isArray || isObject;

  if (requiresJSONCheck) {
    current = JSON.stringify(current);
    previous = JSON.stringify(previous);
  }

  return current === previous;
}

const filterRef = ref(null); // template ref

function focus() {
  if (filterRef.value?.focus) filterRef.value.focus();
  else filterRef.value.$el.querySelector("input").focus();
}

function clear() {
  if (filterRef.value?.reset) filterRef.value.reset();
  else model.value = null;
}
defineExpose({ focus, clear });
</script>
