import { render, staticRenderFns } from "./BackNineStatementCardItem.vue?vue&type=template&id=2943784f"
import script from "./BackNineStatementCardItem.vue?vue&type=script&lang=js"
export * from "./BackNineStatementCardItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports