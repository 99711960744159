<template>
  <v-card :color="color" height="100%" width="100%" class="white--text">
    <div style="height: 100%; display: flex; align-items: center">
      <v-icon large style="position: absolute; top: 2.5rem; right: 0.5rem">
        {{ icon }}
      </v-icon>
      <v-progress-circular
        v-if="type === 'loading'"
        indeterminate
        class="ml-3"
      />
      <v-col v-else :align="left ? 'start' : 'center'">
        <h1 v-if="type === 'number'">
          <animated-number
            :key="text"
            :value="value"
            :format-value="number"
            :duration="1000"
            :round="1"
          />
        </h1>
        <h1 v-else-if="type === 'dollar'">
          <animated-number
            :key="text"
            :value="value"
            :format-value="wholeCurrencyFormatter"
            :duration="1000"
            :round="1"
          />
        </h1>
        <h1 v-else-if="type === 'percent'">
          <animated-number
            :key="text"
            :value="value"
            :format-value="percentage"
            :duration="1000"
            :round="1"
          />
        </h1>
        <h1 v-else-if="type === 'fractionalPercent'">
          <animated-number
            :key="text"
            :value="value"
            :format-value="val => percentage(val, true)"
            :duration="1000"
            :round="1"
          />
        </h1>
        <h1 v-else>
          <animated-number
            :key="text"
            :value="value"
            :duration="1000"
            :round="1"
          />
        </h1>
        <h5 style="font-weight: regular">{{ text }}</h5>
      </v-col>
    </div>
    <v-btn
      v-if="hint"
      style="position: absolute; bottom: 0; right: 0"
      icon
      @click.prevent="$emit('hint')"
    >
      <v-icon>$mdi-information</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import AnimatedNumber from "@/components/shared/AnimatedNumber.vue";
import { currencyFormat, numberFormat, percentageFormat } from "@/util/helpers";

export default {
  components: {
    AnimatedNumber
  },
  props: {
    type: String,
    text: String,
    value: [String, Number],
    hint: Boolean,
    color: {
      required: false,
      default: "primary",
      type: String
    },
    left: {
      required: false,
      default: false,
      type: Boolean
    },
    icon: {
      required: false,
      default: "",
      type: String
    }
  },
  data() {
    return {
      number: numberFormat,
      percentage: percentageFormat,
      wholeCurrencyFormatter: v => currencyFormat(v, 0)
    };
  }
};
</script>
