<template>
  <v-autocomplete
    v-bind="$attrs"
    v-model="model"
    :data-testid="props.dataTestid"
    :loading="cache[props.cacheItemsKey].loading"
    :items="cache[props.cacheItemsKey].items"
  >
    <template v-if="$slots['append-outer']" #append-outer>
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script setup>
import { useSearchItemsCache } from "@/stores/search-items-cache";
import { basicArrayEquivalence, parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { defineProps, defineEmits, ref, watch } from "vue";
const emit = defineEmits(["input"]);
const props = defineProps({
  cacheFetchKey: { type: String, required: true },
  cacheItemsKey: { type: String, required: true },
  dataTestid: { type: String, required: false },
  value: {
    type: [Array, String, Number],
    default: () => []
  }
});

const model = ref(props.value);

const cache = useSearchItemsCache();
const snackbar = useSnackbarStore();
watch(
  () => model.value,
  () => {
    if (!isEqual(model.value, props.value)) emit("input", model.value);
  }
);

watch(
  () => props.value,
  () => {
    if (!isEqual(model.value, props.value)) emit("input", model.value);
    model.value = props.value;
  }
);

function isEqual(newValue, oldValue) {
  if (Array.isArray(newValue) || Array.isArray(oldValue)) {
    if (basicArrayEquivalence(newValue || [], oldValue || [])) return true;
  } else if (newValue === oldValue) return true;
  return false;
}

async function getItems() {
  try {
    await cache[props.cacheFetchKey]();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getItems();
</script>
