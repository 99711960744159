export function CaseTotalFactory(model = {}) {
  return {
    payableName: model?.payableName,
    amount: model?.amount,
    paymentTypes: model?.paymentTypes || [],
    get types() {
      return this.paymentTypes?.join(", ");
    },
    setFromRequest(rawModel = {}) {
      this.payableName = rawModel?.payable?.name;
      this.amount = rawModel?.amount;
      this.paymentTypes = rawModel?.payment_types || [];
    }
  };
}
