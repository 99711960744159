<template>
  <v-card flat tile>
    <v-row class="ma-0" align="center">
      <v-col>
        <strong style="font-size: 1.2rem">{{ statement.payor.name }}</strong>
      </v-col>
      <v-col>
        <v-row justify="end" class="ma-0">
          <avatar-edit :avatar="statement.payor.avatar" :editable="false" />
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="ma-0">
      <back-nine-statement-card-item
        v-for="detail in cardDetails"
        :key="detail"
        :item="detail"
        :model="statement"
      />
    </v-row>
  </v-card>
</template>

<script>
import AvatarEdit from "@/components/shared/AvatarEdit.vue";
import BackNineStatementCardItem from "@/components/commissions/back-nine-statement/BackNineStatementCardItem.vue";

export default {
  components: {
    BackNineStatementCardItem,
    AvatarEdit
  },
  props: {
    statement: Object
  },
  computed: {
    cardDetails() {
      return [
        "Statement Date",
        "Statement Type",
        "Premium",
        "Premium Sum",
        "Commission",
        "Commission Sum",
        "Status"
      ];
    }
  }
};
</script>
