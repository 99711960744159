<template>
  <v-row :justify="sentByMe ? 'end' : 'start'" class="px-4 py-2 my-2">
    <v-col cols="12" class="py-0 px-2">
      <h5
        v-if="sentByMe"
        style="font-weight: 400; text-align: right"
        class="pl-3 pb-0"
      >
        <timestamp-formatter format="date-time" :value="message.createdAt" />
        <v-icon v-if="message.isTemplate" small color="primary">
          $mdi-file-document
        </v-icon>
        <chat-message-email-indicator
          v-if="message.email || message.carrierEmail"
          :full-email="message.fullEmail"
          :message-id="message.id"
        />
        <v-btn
          v-if="canDelete"
          color="red"
          x-small
          icon
          data-testid="delete-message"
          :loading="loading"
          @click="destroyConfirmation"
        >
          <v-icon small> $mdi-delete </v-icon>
        </v-btn>
      </h5>
      <div v-else>
        <h5 style="font-weight: 400" class="pb-0">
          <template v-if="message.ownable">
            {{ message.ownable.name }}
            <timestamp-formatter
              format="date-time"
              :value="message.createdAt"
            />
          </template>
          <chat-message-email-indicator
            v-if="message.email || message.carrierEmail"
            :full-email="message.fullEmail"
            :message-id="message.id"
          />
          <v-btn
            v-if="canDelete"
            color="red"
            icon
            x-small
            data-testid="delete-message"
            :loading="loading"
            @click="destroyConfirmation"
          >
            <v-icon small> $mdi-delete </v-icon>
          </v-btn>
        </h5>
        <h5 v-if="message.inboundEmail" class="pb-0" style="font-weight: 400">
          Replied from {{ message.inboundEmail }}
        </h5>
      </div>
    </v-col>
    <div
      style="display: flex; flex-direction: column"
      :style="{
        width: usingIframe ? '100%' : null,
        maxWidth: maxWidth
      }"
      class="px-2"
    >
      <div style="width: 100%">
        <v-card
          class="pa-3 chat-message-card"
          :color="sentByMe ? 'primary' : 'secondary'"
          :class="{
            'sent-by-me': sentByMe,
            'bottom-right-point': sentByMe,
            'sent-by-other': !sentByMe,
            'bottom-left-point': !sentByMe
          }"
        >
          <template v-if="usingIframe">
            <div>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    style="position: absolute; top: 15px; right: 15px"
                    @click="showIframeFullscreen"
                  >
                    <v-icon color="black"> $mdi-arrow-expand-all </v-icon>
                  </v-btn>
                </template>
                <span>View Fullscreen</span>
              </v-tooltip>
              <div class="frame-wrap">
                <template-renderer
                  class="frame"
                  :value="message.html || message.note"
                />
              </div>
            </div>
          </template>
          <div
            class="mb-0 template-message"
            v-else-if="message.isTemplate"
            v-html="sanitize(message.note)"
          />
          <div
            class="mb-0 plain-message"
            v-else-if="message.html"
            v-html="sanitize(message.html)"
          />
          <include-links-in-text
            v-else-if="message.note"
            :text="message.note"
            class="mb-0 plain-message"
          />
          <a
            v-for="(document, index) in message.documents"
            :key="index"
            class="message-link text-decoration-underline clickable ma-1"
            style="cursor: pointer"
            @click="download(document)"
          >
            {{ document.name }}
            <span v-if="document.fileSize">({{ document.fileSize }})</span>
          </a>
        </v-card>
      </div>
    </div>
  </v-row>
</template>

<script>
import TemplateRenderer from "@/components/shared/TemplateRenderer.vue";
import IncludeLinksInText from "@/components/shared/IncludeLinksInText.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import ChatMessageEmailIndicator from "@/components/shared/chat/ChatMessageEmailIndicator.vue";

import { downloadFile, parseErrorMessage } from "@/util/helpers";
import { mapActions, mapState } from "pinia";
import sanitize from "@/html-sanitizer";
import { getDocumentUrl } from "@/api/documents.service";
import { deleteNote } from "@/api/notes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

export default {
  components: {
    TemplateRenderer,
    TimestampFormatter,
    ChatMessageEmailIndicator,
    IncludeLinksInText
  },
  props: {
    message: Object
  },
  data() {
    return {
      loading: false,
      sanitize
    };
  },
  computed: {
    ...mapState(useUserStore, {
      isGroupTwoPlus: "isGroupTwoPlus",
      loginableId: s => s.loginable.id,
      loginableType: s => s.loginable.type
    }),
    usingIframe() {
      return this.message.isTemplate;
    },
    maxWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "95%";
      }
      return this.usingIframe ? "50%" : "70%";
    },
    sentByMe() {
      const sameId = this.message?.ownable?.id === this.loginableId;
      const sameType = this.message?.ownable?.type === this.loginableType;
      return sameId && sameType;
    },
    canDelete() {
      if (!this.message.id) return false;
      if (this.sentByMe) return true;
      return this.isGroupTwoPlus;
    }
  },
  beforeDestroy() {
    this.closeDialog();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog", "closeDialog"]),
    destroyConfirmation() {
      this.showDialog({
        component: "ConfirmationDialog",
        title: "Delete Note",
        subtitle: "Delete this note?",
        func: this.destroy
      });
    },
    async destroy() {
      this.loading = true;
      try {
        await deleteNote(this.message.id);
        this.$emit("remove-message", this.message);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    },
    download(document) {
      downloadFile(
        getDocumentUrl(document.uid),
        this.$vuetify.breakpoint.mdAndDown
      );
    },
    showIframeFullscreen() {
      this.showDialog({
        component: "ChatTemplateViewer",
        message: this.message,
        width: "100%"
      });
    }
  }
};
</script>
