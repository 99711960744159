<template>
  <v-card>
    <v-card-title>{{ alias.id ? "Edit" : "Create" }} Alias</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-select
            label="Engine"
            v-model="alias.engine"
            dense
            outlined
            :items="engines"
            item-text="name"
            item-value="id"
            clearable
            :success="Boolean(alias.engine)"
            :disabled="saving"
          />
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="alias.includedStates"
            auto-select-first
            label="Included States"
            multiple
            dense
            outlined
            clearable
            :disabled="saving"
            :items="allStates"
            :success="includedStatesValidation.success"
            :error-messages="includedStatesValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="alias.excludedStates"
            label="Excluded States"
            auto-select-first
            multiple
            dense
            outlined
            clearable
            :items="allStates"
            :success="excludedStatesValidation.success"
            :error-messages="excludedStatesValidation.errorMessages"
            :disabled="saving"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="alias.parameters"
            outlined
            dense
            label="Parameters (JSON)"
            :success="parametersValidation.success"
            :error-messages="parametersValidation.errorMessages"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn outlined @click="closeDialog" class="text-none" :disabled="saving">
        Close
      </v-btn>
      <v-btn
        @click="saveAlias"
        color="primary"
        class="text-none"
        :loading="saving"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { createAlias, updateAlias } from "@/api/products.service";
import { states } from "@/data/states";
import ProductAliasFactory from "@/factories/ProductAliasFactory";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
const allStates = states.map(({ text, full }) => ({ text: full, value: text }));
export default {
  name: "AliasCreateOrEdit",
  props: {
    $alias: Object,
    productId: [Number, String],
    engines: Array
  },
  data() {
    let existingAlias = { parameters: '{"name": "replace-me"}' };
    if (this.$alias?.id) existingAlias = this.$alias;
    const alias = new ProductAliasFactory(existingAlias);
    return {
      allStates,
      alias,
      saving: false
    };
  },
  computed: {
    intersectingStates() {
      const allStates = [
        ...this.alias.includedStates,
        ...this.alias.excludedStates
      ];
      const stateSet = new Set(allStates);
      return stateSet.size !== allStates.length;
    },
    parametersValidation() {
      const model = this.$v.alias.parameters;
      const success = !model.$invalid;
      const errorMessages = [];
      if (!model.$dirty) return { success, errorMessages };
      if (!model.validJson) {
        errorMessages.push("Invalid JSON");
      }
      return { success, errorMessages };
    },
    includedStatesValidation() {
      const model = this.$v.alias.includedStates;
      let success = !model.$invalid;
      if (!this.alias.includedStates.length) {
        success = null;
      }
      const errorMessages = [];
      if (!model.$dirty) return { success, errorMessages };
      if (!model.cannotIntersectStates) {
        errorMessages.push("Must not intersect with Included States");
      }
      return { success, errorMessages };
    },
    excludedStatesValidation() {
      const model = this.$v.alias.excludedStates;
      let success = !model.$invalid;
      if (!this.alias.excludedStates.length) {
        success = null;
      }
      const errorMessages = [];
      if (!model.$dirty) return { success, errorMessages };
      if (!model.cannotIntersectStates) {
        errorMessages.push("Must not intersect with Excluded States");
      }
      return { success, errorMessages };
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    updateAlias() {
      return updateAlias(this.productId, this.alias.id, {
        engine: this.alias.engine,
        parameters: JSON.parse(this.alias.parameters),
        included_states: this.alias.includedStates,
        excluded_states: this.alias.excludedStates
      });
    },
    createAlias() {
      return createAlias(this.productId, this.alias.toCreateRequest());
    },
    async saveAlias() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.saving = true;
      try {
        if (this.alias.id) await this.updateAlias();
        else await this.createAlias();
        await this.closeDialog({ alias: this.alias });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.saving = false;
      }
    }
  },
  validations() {
    return {
      alias: {
        includedStates: {
          cannotIntersectStates: () => {
            return !this.intersectingStates;
          }
        },
        excludedStates: {
          cannotIntersectStates: () => {
            return !this.intersectingStates;
          }
        },
        parameters: {
          validJson: val => {
            try {
              JSON.parse(val);
              return true;
            } catch (e) {
              return false;
            }
          }
        }
      }
    };
  }
};
</script>
