<template>
  <v-card flat>
    <v-data-table
      must-sort
      data-testid="cases-table"
      class="transparent-data-table"
      :headers="table.tableHeaders.value"
      :items="table.mappedItems.value"
      :server-items-length="table.meta.value.total"
      :loading="table.loading.value"
      :options="table.options.value"
      :footer-props="footerProps"
      @update:options="updateOptions"
    >
      <template #top>
        <v-row class="ma-0 pa-3" align="center" dense>
          <h1 class="text-h5">Cases</h1>
          <generic-table-stats
            :stats="stats"
            :end="$vuetify.breakpoint.smAndUp"
            @hint="handleHint"
          />
          <div>
            <v-tooltip v-if="table.filter.value.view !== 'all'" top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  data-testid="cases-table-email-data"
                  v-on="on"
                  v-bind="attrs"
                  @click="confirmAndEmailData"
                >
                  <v-icon>$mdi-file-export</v-icon>
                </v-btn>
              </template>
              <span>Email Data</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  data-testid="cases-table-refresh-data"
                  v-on="on"
                  v-bind="attrs"
                  @click="getData"
                >
                  <v-icon>$mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </div>
        </v-row>
        <v-divider />
        <generic-table-filter-header
          :header-props="{
            class: 'mt-0 pa-3',
            rounded: false
          }"
          :show-view-filter="user.isGroupTwoPlus"
          :value="table.filter.value"
          :headers="table.filterHeaders.value"
          :loading="table.loading.value"
          @update="updateFilter"
        />
        <v-divider />
      </template>
      <template #[`item.created`]="{ item }">
        <timestamp-formatter :value="item.created" />
      </template>
      <template #[`item.conversion_expiry`]="{ item }">
        <timestamp-formatter
          :value="item.conversion_expiry"
          parser="sole-day"
        />
      </template>
      <template #[`item.inforce`]="{ item }">
        <timestamp-formatter :value="item.inforce" parser="sole-day" />
      </template>
      <template #[`item.closed`]="{ item }">
        <timestamp-formatter :value="item.closed" parser="sole-day" />
      </template>
      <template #[`item.product`]="{ item }">
        <td class="truncate">{{ item.product }}</td>
      </template>
      <template #[`item.status`]="{ item }">
        <td class="truncate">{{ item.status }}</td>
      </template>
      <template #[`item.insured`]="{ item }">
        <v-tooltip left>
          <template #activator="{ attrs, on }">
            <router-link
              :to="getRouterLink('Case', item.additional.id)"
              v-on="on"
              class="text-none"
              v-bind="attrs"
            >
              {{ item.insured }}
            </router-link>
          </template>
          <span>View Case</span>
        </v-tooltip>
      </template>
      <template #[`item.fy_premium`]="{ item }">
        <span>
          <currency-formatter :value="item.fy_premium" :decimal-length="0" />
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script setup>
import { ARTICLES } from "@/data/articles";
import {
  statusFilterOptions,
  lineFilterOptions,
  activeStatusFilter,
  inactiveStatusFilter,
  pendingStatusFilter,
  allStatusFilterOptions
} from "@/data/filter-options";
import { parseErrorMessage, getRouterLink } from "@/util/helpers";
import { useUserStore } from "@/stores/user";

import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import GenericTableStats from "@/components/shared/data-table/GenericTableStats.vue";
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import { states } from "@/data/states";

import { emailCases, getCases } from "@/api/cases.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";

import { computed } from "vue";
import { useTableStore } from "@/stores/table";
import { storeToRefs } from "pinia";
import { useHead } from "@unhead/vue";

useHead({ title: "Cases" });

const PLAN_TYPES = [
  { text: "Accidental Death", value: "Accidental Death" },
  { text: "Critical Illness", value: "Critical Illness" },
  { text: "DIA", value: "DIA" },
  { text: "Disability", value: "Disability" },
  { text: "FIA", value: "FIA" },
  { text: "Final Expense", value: "Final Expense" },
  { text: "Fixed", value: "Fixed" },
  { text: "Guaranteed Issue Whole Life", value: "GIWL" },
  { text: "IUL", value: "IUL" },
  { text: "LTC", value: "LTC" },
  { text: "MYGA", value: "MYGA" },
  { text: "SPIA", value: "SPIA" },
  { text: "Term", value: "TRM" },
  { text: "Term - Non Medical", value: "TRM - Non Medical" },
  { text: "Term - ROP", value: "TRM - ROP" },
  { text: "UL", value: "UL" },
  { text: "VUL", value: "VUL" },
  { text: "Whole Life", value: "Whole Life" },
  {
    text: "Whole Life - Non Participating",
    value: "Whole Life - Non Participating"
  },
  { text: "Other", value: "Other" }
];

const user = useUserStore();
const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const { casesTable } = storeToRefs(useTableStore());
function generateTable() {
  const headers = [
    new TableHeader({
      text: "View",
      value: "view",
      map: "view",
      selectableOptions: user.isGroupTwoPlus
        ? [
            { text: "All Cases", value: "all" },
            { text: "My Cases", value: "my" }
          ]
        : [],
      sortFilterMap: "view",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_MANDATORY,
      order: 1
    }),
    new TableHeader({
      text: "Insured",
      value: "insured",
      map: "insured.name",
      sortFilterMap: "contract_parties.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Advisor",
      value: "advisor",
      map: "advisor.name",
      sortFilterMap: [
        { key: "advisor_id", value: "id" },
        { key: "advisor_type", value: "type" }
      ],
      displayMap: "name",
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      selectableOptions: statusFilterOptions,
      showInTableIf: ({ status }) => !status,
      sortFilterMap: "status",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "carrier_name",
      sortFilterMap: [{ key: "carrier_id", value: "id" }],
      ...TableHeader.IS_CARRIER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Product",
      value: "product",
      map: "product_name",
      sortFilterMap: "products.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Policy Number",
      value: "policy",
      map: "policy_number",
      sortFilterMap: "cases.policy_number",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "FY Premium",
      value: "fy_premium",
      map: "first_year",
      sortFilterMap: "cases.first_year",
      ...TableHeader.IS_INVERTED_SORT,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Line",
      value: "line",
      map: "line.text",
      selectableOptions: lineFilterOptions,
      sortFilterMap: "products.line",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Created",
      value: "created",
      map: "created_at",
      sortFilterMap: "cases.created_at",
      showInTableIf: ({ status }) => !status || status === "pending",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    user.isGroupTwoPlus
      ? new TableHeader({
          text: "Case Manager",
          value: "case_manager_name",
          map: "case_manager_name",
          sortFilterMap: [{ key: "case_manager_id", value: "id" }],
          ...TableHeader.IS_CASE_MANAGER_SEARCH_FILTER_TYPE,
          ...TableHeader.IS_FILTERABLE,
          ...TableHeader.IS_QUERY_FILTER
        })
      : null,
    user.isGroupTwoPlus
      ? new TableHeader({
          text: "Case Concierge",
          value: "case_concierge_name",
          map: "case_concierge_name",
          sortFilterMap: [{ key: "case_concierge_id", value: "id" }],
          ...TableHeader.IS_CASE_MANAGER_SEARCH_FILTER_TYPE,
          ...TableHeader.IS_FILTERABLE,
          ...TableHeader.IS_QUERY_FILTER,
          ...TableHeader.IS_ADDITIONAL
        })
      : null,
    user.isGroupTwoPlus
      ? new TableHeader({
          text: "Manager",
          value: "marketing_manager",
          map: "marketing_manager",
          sortFilterMap: [
            { key: "marketing_manager_id", value: "marketing_manager_id" }
          ],
          ...TableHeader.IS_MARKETING_MANAGER_SEARCH_FILTER_TYPE,
          ...TableHeader.IS_FILTERABLE,
          ...TableHeader.IS_QUERY_FILTER
        })
      : null,
    new TableHeader({
      text: "Shared With",
      value: "additionalAdvisor",
      map: "additional_advisor",
      sortFilterMap: [
        { key: "shared_with_id", value: "id" },
        { key: "shared_with_type", value: "type" }
      ],
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Specific Status",
      value: "caseStatus",
      map: "caseStatus",
      selectableOptionsFn: specificStatusFunc,
      sortFilterMap: "cases.status",
      ...TableHeader.IS_MULTI_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL
    }),
    new TableHeader({
      text: "Inforce Date",
      value: "inforce",
      map: "inforce_date",
      sortFilterMap: "cases.inforce_date",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE,
      showIf: ({ status }) => status === "active"
    }),
    new TableHeader({
      text: "Closed Date",
      value: "closed",
      map: "closed_date",
      sortFilterMap: "cases.closed_date",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE,
      showIf: ({ status }) => status === "inactive"
    }),
    user.isGroupTwoPlus
      ? new TableHeader({
          text: "White Glove",
          value: "whiteGlove",
          map: "whiteGlove",
          sortFilterMap: "white_glove",
          selectableOptions: [
            { text: "Yes", value: true },
            { text: "No", value: false }
          ],
          ...TableHeader.SEND_FALSE,
          ...TableHeader.IS_SELECT_FILTER_TYPE,
          ...TableHeader.IS_FILTERABLE,
          ...TableHeader.IS_QUERY_FILTER,
          ...TableHeader.IS_ADDITIONAL
        })
      : null,
    new TableHeader({
      text: "Death Benefit",
      value: "death_benefit",
      map: "death_benefit",
      sortFilterMap: "death_benefit",
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_NUMBER_RANGE_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_CURRENCY
    }),
    new TableHeader({
      text: "Production Credit",
      value: "premium",
      map: "premium",
      sortFilterMap: "premium",
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_NUMBER_RANGE_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_CURRENCY
    }),
    new TableHeader({
      text: "Conversion Expiry",
      value: "conversion_expiry",
      map: "conversion_expiry",
      sortFilterMap: "cases.conversion_expiry",
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      futureDateValues: true,
      showIf: ({ status }) => status === "active",
      showInTableIf: ({ conversion_expiry }) =>
        Boolean(
          conversion_expiry?.value?.start || conversion_expiry?.value?.finish
        )
      // show conversion expiry in the table if it's used
    }),
    new TableHeader({
      text: "Client",
      value: "client",
      map: "client",
      sortFilterMap: "any_contract_party_name",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_STRING_FILTER_TYPE
    }),
    new TableHeader({
      text: "State",
      value: "state",
      map: "state",
      sortFilterMap: "state",
      selectableOptions: states.map(val => ({
        text: val.full,
        value: val.text
      })),
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_AUTOCOMPLETE_FILTER_TYPE
    }),
    user.isGroupTwoPlus
      ? new TableHeader({
          text: "Product Types",
          value: "plan_type",
          map: "plan_type",
          selectableOptions: PLAN_TYPES,
          ...TableHeader.IS_ADDITIONAL,
          ...TableHeader.IS_FILTERABLE,
          ...TableHeader.IS_SELECT_FILTER_TYPE,
          ...TableHeader.IS_MULTI_SELECT_FILTER_TYPE,
          ...TableHeader.IS_QUERY_FILTER
        })
      : null
  ].filter(Boolean);

  const filter = {
    status: null
  };

  if (user.isGroupTwoPlus) {
    filter.status = "pending";
    filter.view = user.isGroupFour ? "all" : "my";
  }

  if (casesTable.value.filter) {
    Object.keys(casesTable.value.filter).forEach(key => {
      if (!headers.find(header => header.value === key)) return;
      filter[key] = casesTable.value.filter[key];
    });
  }

  let options = new TableOptions(["created"], [true]);
  if (Object.keys(casesTable.value.options).length) {
    options = casesTable.value.options;
  } else if (Object.values(casesTable.value.filter).length) {
    const { sortBy, sortDesc } = getDefaultSortsByStatus(
      casesTable.value.filter.status
    );
    options = new TableOptions(sortBy, sortDesc);
  }

  return useTable({
    filter,
    headers,
    options,
    getData: getCases,
    emailData: emailCases
  });
}
const table = generateTable();

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(table.options.value, table.meta.value),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

const stats = computed(() => {
  const stats = [];
  const cases = { text: "Cases", icon: "$mdi-briefcase" };
  const premium = { text: "FY Premium", icon: "$mdi-currency-usd" };
  const deathBenefit = {
    text: "Total Death Benefit",
    icon: "$mdi-currency-usd"
  };
  const productionCredit = {
    text: "Production Credit",
    icon: "$mdi-home-currency-usd",
    hint: true,
    hintType: "PRODUCTION_CREDIT"
  };
  const statsObj = table.meta.value.stats;
  if (!statsObj) {
    return [
      { ...cases, type: "loading" },
      { ...premium, type: "loading" },
      { ...productionCredit, type: "loading" },
      { ...deathBenefit, type: "loading" }
    ];
  }
  Object.keys(statsObj).forEach(stat => {
    if (stat.indexOf("cases") > -1) {
      stats.push({ ...cases, value: statsObj[stat], type: "number" });
      return;
    }

    if (stat.indexOf("premium") > -1) {
      stats.push({ ...premium, value: statsObj[stat], type: "dollar" });
      return;
    }

    if (stat.indexOf("production") > -1) {
      stats.push({
        ...productionCredit,
        value: statsObj[stat],
        type: "dollar"
      });
      return;
    }
    if (stat.indexOf("death_benefit") > -1) {
      stats.push({
        ...deathBenefit,
        value: statsObj[stat],
        type: "dollar"
      });
      return;
    }
  });
  return stats;
});

function confirmAndEmailData() {
  if (table.filter.value.view === "all") return;
  dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Email Data?",
    subtitle: "Please confirm you would like this data emailed to you.",
    func: () => emailData()
  });
}
function handleHint({ hintType }) {
  if (hintType === "PRODUCTION_CREDIT") {
    dialog.showDialog({
      component: "HelpCenterReader",
      id: ARTICLES.PRODUCTION_CREDIT
    });
  }
}

function specificStatusFunc(filters) {
  const dict = {
    active: activeStatusFilter,
    pending: pendingStatusFilter,
    inactive: inactiveStatusFilter
  };

  return dict[filters.status] || allStatusFilterOptions;
}

async function emailData() {
  try {
    const additionalFilter = {};
    if (!user.isGroupTwoPlus) additionalFilter.view = "my";
    table.emailData(additionalFilter);
    snackbar.showSuccessSnackbar({
      message: `You will receive a copy of this data in your inbox shortly.`,
      timeout: 6000
    });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(error),
      timeout: -1
    });
  }
}

async function getData() {
  try {
    const additionalFilter = {};
    if (!user.isGroupTwoPlus) additionalFilter.view = "my";
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      color: "error"
    });
  }
}

function updateOptions(options) {
  if (table.optionsEquivalence(options)) return;
  table.handleCustomSorts(options);
  casesTable.value.options = table.options.value;
  getData();
}

function getDefaultSortsByStatus(status) {
  let sortBy = ["created"];
  let sortDesc = [true];

  if (status === "active") {
    sortBy = ["inforce"];
    sortDesc = [true];
  } else if (status === "pending") {
    sortBy = ["created"];
    sortDesc = [true];
  } else if (status === "inactive") {
    sortBy = ["closed"];
    sortDesc = [true];
  }

  return { sortBy, sortDesc };
}

function updateFilter(filter) {
  let unequalStatuses = false;
  if (table.filter.value.status || filter.status) {
    unequalStatuses = table.filter.value.status !== filter.status;
  }
  table.filter.value = filter;
  if (unequalStatuses) {
    const { sortBy, sortDesc } = getDefaultSortsByStatus(filter.status);
    table.options.value.sortBy = sortBy;
    table.options.value.sortDesc = sortDesc;

    table.filter.value.inforce = null;
    table.filter.value.closed = null;
  }
  table.resetPage();
  casesTable.value.filter = table.filter.value;
  getData();
}

getData();
</script>
