<template>
  <v-card>
    <v-card-title> Create a Form </v-card-title>
    <v-card-text>
      <form-mapping-form-details
        :validation-scope="validationScope"
        :category.sync="form.category"
        :carrier.sync="form.carrier"
        :advisor.sync="form.advisor"
        :is-contracting.sync="form.isContracting"
        :form-sets.sync="form.formSets"
        :form.sync="form.form"
        :name.sync="form.name"
        :submission-method.sync="form.submissionMethod"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="text-none"
        outlined
        :disabled="loading"
        @click="dialog.closeDialog"
      >
        Cancel
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        data-testid="form-create"
        :loading="loading"
        @click="create"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import FormMappingFormDetails from "@/components/form-mapping/FormMappingFormDetails.vue";
import { parseErrorMessage } from "@/util/helpers";
import { createForm } from "@/api/forms.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";

const form = ref({
  carrier: null,
  advisor: null,
  category: null,
  form: null,
  formSets: [],
  submissionMethod: null
});
const loading = ref(false);

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const validationScope = "create-form-dialog";
const v$ = useVuelidate({}, {}, { $scope: validationScope });

async function create() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  loading.value = true;
  try {
    const body = {
      file: form.value.form,
      form: {
        category: form.value.category,
        status: "Incomplete"
      }
    };

    if (form.value.carrier?.id) {
      body.form.carrier_id = form.value.carrier.id;
      body.form.form_set_links_attributes = form.value.formSets?.map(val => ({
        form_set_id: val.id
      }));
    } else if (form.value.advisor?.type === "Agency") {
      body.form.agency_id = form.value.advisor.id;
    } else if (form.value.advisor?.type === "Agent") {
      body.form.agent_id = form.value.advisor.id;
    }

    if (form.value.submissionMethod) {
      body.form.submission_method = form.value.submissionMethod;
    }
    const id = await createForm(body);
    dialog.closeDialog({ id });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: `Unable to create form: ${parseErrorMessage(e)}`,
      timeout: 5000
    });
  } finally {
    loading.value = false;
  }
}
</script>
