<template>
  <v-card data-testid="appointment-create">
    <v-card-title> Create Appointment </v-card-title>
    <v-divider />
    <v-card-text :class="{ 'my-3': props.isDialog }">
      <appointment-form ref="appointmentCreate" :value="appointment" />
    </v-card-text>
    <v-card-actions>
      <template v-if="props.isDialog">
        <v-spacer />
        <v-btn outlined @click="dialog.closeDialog()" class="text-none">
          Cancel
        </v-btn>
      </template>
      <v-btn
        color="primary"
        class="text-none"
        :loading="loading"
        data-testid="create-appointment"
        @click="createAppointment"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import AppointmentForm from "@/components/appointments/AppointmentForm.vue";
import { useDialogStore } from "@/stores/dialog";
import { useHead } from "@unhead/vue";
import { ref, defineProps } from "vue";
import { useRouter } from "@/composables/compatible.composables";

useHead({ title: "Create Appointment" });

const props = defineProps({
  isDialog: Boolean,
  advisor: {
    type: Object,
    required: false,
    default: () => {}
  },
  carrier: {
    type: Object,
    required: false,
    default: () => {}
  },
  productId: {
    type: [Number, String],
    required: false,
    default: null
  },
  insuredName: {
    type: String,
    required: false,
    default: ""
  },
  states: {
    type: Array,
    required: false,
    default: () => []
  }
});

const appointment = {
  carrier: props.carrier,
  advisor: props.advisor,
  productId: props.productId,
  insuredName: props.insuredName,
  states: props.states
};

const router = useRouter();
const loading = ref(false);
const appointmentCreate = ref();

const dialog = useDialogStore();
async function createAppointment() {
  //create the appointment wrapper
  loading.value = true;
  const newAppointment = await appointmentCreate.value.createAppointment();
  loading.value = false;
  if (!newAppointment) return;

  if (props.isDialog) {
    return dialog.closeDialog(newAppointment);
  } else if (router) {
    router.push({
      name: "AppointmentView",
      params: {
        id: newAppointment.id
      }
    });
  }
}
</script>
