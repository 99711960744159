<template>
  <v-expansion-panel outlined>
    <v-expansion-panel-header
      :hide-actions="!hasItems"
      :disabled="!hasItems"
      :color="quoteData.enabled ? 'primary' : undefined"
    >
      <v-row class="ma-0" align="center">
        <v-switch
          v-model="quoteData.enabled"
          hide-details
          class="mt-0"
          :disabled="props.disabled"
          :data-testid="props.type + '-enabled'"
          :color="quoteData.enabled ? 'white' : undefined"
          @click.stop
          @change="handleEnabledUpdate"
        />
        <div class="mt-1" :class="{ 'white--text': quoteData.enabled }">
          {{ props.type }}
        </div>
        <active-save-indicator
          class="ml-2"
          :controller="typeSavingBuffer.controller.value"
        />
      </v-row>
      <template #actions="{ open }">
        <v-btn icon @click="open = !open">
          <v-icon :color="quoteData.enabled ? 'white' : undefined"
            >$mdi-chevron-down</v-icon
          >
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content v-if="hasItems">
      <v-col v-if="requiresIncomeStartDate" cols="12">
        <quote-request-date-input
          title="Income Start Date"
          subtitle="Income Start Date is synced across all enabled types"
          label="Income Start Date"
          v-bind="incomeStartDateProps"
          :disabled="props.disabled"
          :model.sync="quoteData.incomeStartDate"
          :validation="incomeStartDateValidation"
        />
      </v-col>

      <v-col v-if="requiresIncomeDuration" cols="12">
        <quote-request-radio
          title="Income Duration"
          subtitle="Income Duration is synced across all enabled types"
          v-bind="incomeDurationTypeProps"
          :disabled="props.disabled"
          :validation="incomeDurationValidation"
          :model.sync="quoteData.incomeDurationType"
        />
      </v-col>
      <v-fade-transition mode="out-in">
        <v-col v-if="requiresRefundOption" cols="12">
          <quote-request-radio
            title="Refund Option"
            subtitle="Refund Option is synced across all enabled types"
            v-bind="annuityGuaranteeTypeProps"
            :disabled="props.disabled"
            :validation="refundOptionValidation"
            :model.sync="quoteData.annuityGuaranteeType"
          />
        </v-col>
      </v-fade-transition>
      <v-fade-transition mode="out-in">
        <v-col v-if="requiresCertainPeriod" cols="12">
          <quote-request-integer-input
            title="Certain Period"
            label="Years"
            subtitle="Certain Period is synced across all enabled types"
            v-bind="certainPeriodProps"
            :disabled="props.disabled"
            :validation="certainPeriodValidation"
            :model.sync="quoteData.certainPeriod"
            @input="v$.value.quoteData.certainPeriod.$touch()"
          />
        </v-col>
      </v-fade-transition>
      <v-fade-transition mode="out-in">
        <v-col v-if="requiresSoleIncomeStartAge" cols="12">
          <quote-request-integer-input
            title="Income Start Age"
            label="Start Age"
            subtitle="Income Start Age is synced across all enabled types"
            v-bind="soleIncomeStartAgeProps"
            :disabled="props.disabled"
            :validation="soleIncomeStartAgeValidation"
            :model.sync="quoteData.soleIncomeStartAge"
          />
        </v-col>
      </v-fade-transition>
      <v-col v-if="requiresSurrenderPeriod" cols="12">
        <quote-request-multi-select
          title="Surrender Period"
          subtitle="Surrender Period is synced across all enabled types"
          v-bind="surrenderPeriodsProps"
          :disabled="props.disabled"
          :enabled="quoteData.enabled"
          :model.sync="quoteData.surrenderPeriods"
          :validation="surrenderPeriodValidation"
        />
      </v-col>

      <v-col v-if="requiresPayDurations" cols="12">
        <quote-request-multi-select
          title="Premium Pay Years"
          subtitle="Pay Duration is synced across all enabled types"
          no-data-text="Please supply a birthdate to select pay durations"
          v-bind="payDurationsProps"
          :disabled="props.disabled"
          :enabled="quoteData.enabled"
          :model.sync="quoteData.payDurations"
          :validation="payDurationsValidation"
        />
      </v-col>

      <v-col v-if="requiresProductTypes" cols="12">
        <quote-request-multi-select
          title="Coverage Duration"
          v-bind="productTypesProps"
          :disabled="props.disabled"
          :enabled="quoteData.enabled"
          :model.sync="quoteData.productTypes"
          :validation="productTypesValidation"
        />
      </v-col>

      <v-col v-if="canHaveIncome" cols="12">
        <quote-request-income
          title="Income"
          subtitle="Income is synced across all enabled types"
          v-bind="incomeProps"
          :disabled="props.disabled"
          :enabled.sync="quoteData.incomeEnabled"
          :start.sync="quoteData.incomeStartAge"
          :end.sync="quoteData.incomeEndAge"
          :start-validation="incomeStartAgeValidation"
          :end-validation="incomeEndAgeValidation"
        />
      </v-col>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
function QuoteRequestProductTypeData(model = {}) {
  const numOrNull = v => (v || v === 0 ? v : null);
  return {
    enabled: model?.enabled || false,
    productTypes: model?.productTypes || [],
    payDurations: model?.payDurations || [],
    incomeEnabled: model?.incomeEnabled || false,
    incomeStartAge: numOrNull(model?.incomeStartAge),
    incomeEndAge: numOrNull(model?.incomeEndAge),
    incomeDurationType: numOrNull(model?.incomeDurationType),
    incomeStartDate: model?.incomeStartDate || null,
    annuityGuaranteeType: model?.annuityGuaranteeType || null,
    certainPeriod: model?.certainPeriod || null,
    surrenderPeriods: model?.surrenderPeriods || [],
    soleIncomeStartAge: numOrNull(model?.soleIncomeStartAge)
  };
}
</script>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import QuoteRequestIntegerInput from "@/components/quotes/quote-request/product-type/QuoteRequestIntegerInput.vue";
import QuoteRequestRadio from "@/components/quotes/quote-request/product-type/QuoteRequestRadio.vue";
import QuoteRequestIncome from "@/components/quotes/quote-request/product-type/QuoteRequestIncome.vue";
import QuoteRequestMultiSelect from "@/components/quotes/quote-request/product-type/QuoteRequestMultiSelect.vue";
import QuoteRequestDateInput from "@/components/quotes/quote-request/product-type/QuoteRequestDateInput.vue";

import { computedValidation } from "@/util/helpers";
import useVuelidate from "@vuelidate/core";
import { defineProps, ref, defineEmits, computed, watch, toRefs } from "vue";
import parse from "date-fns/parse";
import { useActiveSave } from "@/composables/active-save.composable";
const props = defineProps({
  disabled: Boolean,
  type: {
    type: String
  },

  enabled: Boolean,
  enabledProps: { type: Object, required: false, default: () => null },

  // Term Duration Items
  productTypes: {
    type: Array,
    required: false,
    default: () => []
  },
  productTypesProps: { type: Object, required: false, default: () => null },

  // Pay Duration Items
  payDurations: {
    type: Array,
    required: false,
    default: () => []
  },
  payDurationsProps: { type: Object, required: false, default: () => null },

  // Income Items
  incomeEnabled: Boolean,
  incomeStartAge: {
    type: Number,
    required: false
  },
  incomeEndAge: {
    type: Number,
    required: false
  },
  incomeProps: { type: Object, required: false, default: () => null },

  // Surrender Period
  surrenderPeriods: {
    type: Array,
    required: false,
    default: () => []
  },
  surrenderPeriodsProps: { type: Object, required: false, default: () => null },

  // Income Duration
  incomeDurationType: {
    type: String,
    required: false,
    default: null
  },
  incomeDurationTypeProps: {
    type: Object,
    required: false,
    default: () => null
  },

  // Refund Option
  annuityGuaranteeType: {
    type: String,
    required: false,
    default: null
  },
  annuityGuaranteeTypeProps: {
    type: Object,
    required: false,
    default: () => null
  },

  // Certain Period
  certainPeriod: {
    type: Number,
    required: false,
    default: null
  },
  certainPeriodProps: { type: Object, required: false, default: () => null },

  // Income Start Age
  soleIncomeStartAgeProps: {
    type: Object,
    required: false,
    default: () => null
  },
  soleIncomeStartAge: { type: Number, required: false, default: null },

  incomeStartDate: { type: String, required: false, default: null },
  incomeStartDateProps: { type: Object, required: false, default: () => null }
});

const quoteData = ref(QuoteRequestProductTypeData(props));

const emit = defineEmits([
  ...Object.values(QuoteRequestProductTypeData()).map(v => `update:${v}`),
  "expand"
]);

const typeSavingBuffer = useActiveSave();

const {
  payDurationsProps,
  incomeStartDateProps,
  surrenderPeriodsProps,
  productTypesProps,
  incomeDurationTypeProps,
  annuityGuaranteeTypeProps,
  certainPeriodProps,
  incomeProps,
  soleIncomeStartAgeProps
} = toRefs(props);

const v$ = useVuelidate(
  {
    quoteData: {
      productTypes: {
        required: (v, vm) =>
          !vm.enabled || !requiresProductTypes.value || v?.length
      },
      payDurations: {
        required: (v, vm) =>
          !vm.enabled || !payDurationsProps.value?.items?.length || v?.length
      },
      soleIncomeStartAge: {
        required: (v, vm) =>
          !vm.enabled || !requiresSoleIncomeStartAge.value || v || v === 0,
        withinRange: (v, vm) => {
          if (!v && (!vm.enabled || !requiresSoleIncomeStartAge.value)) {
            return true;
          }
          return v >= 0 && v <= 120;
        }
      },
      incomeStartAge: {
        required: (v, vm) =>
          !vm.enabled || !incomeIsRequired.value || v || v === 0,
        lessThanEndAge: (v, vm) => {
          if (!v && v !== 0) return !vm.enabled || !incomeIsRequired.value;
          return v < vm.incomeEndAge || vm.incomeEndAge === -1;
        }
      },
      incomeEndAge: {
        required: (v, vm) => !vm.enabled || !incomeIsRequired.value || v,
        greaterThanStartAge: (v, vm) => {
          if (!v && v !== 0) return !vm.enabled || !incomeIsRequired.value;
          return v === -1 || v > vm.incomeStartAge;
        }
      },
      surrenderPeriods: {
        required: (v, vm) =>
          !vm.enabled || !requiresSurrenderPeriod.value || v?.length
      },
      incomeDurationType: {
        required: (v, vm) => !vm.enabled || !requiresIncomeDuration.value || !!v
      },
      annuityGuaranteeType: {
        required: (v, vm) => !vm.enabled || !requiresRefundOption.value || !!v
      },
      certainPeriod: {
        required: (v, vm) => !vm.enabled || !requiresCertainPeriod.value || !!v,
        withinRange: (v, vm) => {
          if (!v && (!vm.enabled || !requiresCertainPeriod.value)) return true;
          return v >= 5 && v <= 30;
        }
      },
      incomeStartDate: {
        required: (v, vm) =>
          !vm.enabled || !requiresIncomeStartDate.value || !!v,
        afterBirthdate: (v, vm) => {
          if (!v) return !vm.enabled || !requiresIncomeStartDate.value;
          try {
            const setDate = parse(v, "yyyy-MM-dd", new Date());
            return setDate > incomeStartDateProps.value?.birthdate;
          } catch (e) {
            return false;
          }
        },
        afterToday: (v, vm) => {
          if (!v) return !vm.enabled || !requiresIncomeStartDate.value;
          try {
            const setDate = parse(v, "yyyy-MM-dd", new Date());
            return setDate > new Date();
          } catch (e) {
            return false;
          }
        }
      }
    }
  },
  {
    quoteData
  },
  { $autoDirty: true, $scope: "quote-request" }
);

const requiresSurrenderPeriod = computed(() => surrenderPeriodsProps.value);
const requiresProductTypes = computed(() => productTypesProps.value);
const requiresIncomeDuration = computed(() => incomeDurationTypeProps.value);
const requiresRefundOption = computed(() => annuityGuaranteeTypeProps.value);
const requiresIncomeStartDate = computed(() => incomeStartDateProps.value);
const requiresCertainPeriod = computed(() => certainPeriodProps.value);
const requiresPayDurations = computed(() => payDurationsProps.value);

const canHaveIncome = computed(() => incomeProps.value);
const incomeIsRequired = computed(
  () => incomeProps.value && quoteData.value.incomeEnabled
);
const requiresSoleIncomeStartAge = computed(
  () => soleIncomeStartAgeProps.value
);

const hasItems = computed(() =>
  [
    requiresProductTypes,
    canHaveIncome,
    incomeIsRequired,
    requiresSoleIncomeStartAge,
    requiresSurrenderPeriod,
    requiresIncomeDuration,
    requiresRefundOption,
    requiresCertainPeriod,
    requiresIncomeStartDate
  ].some(v => v.value)
);

const surrenderPeriodValidation = computedValidation(
  v$.value.quoteData.surrenderPeriods,
  [{ key: "required", message: "Required" }]
);

const productTypesValidation = computedValidation(
  v$.value.quoteData.productTypes,
  [{ key: "required", message: "Required" }]
);

const payDurationsValidation = computedValidation(
  v$.value.quoteData.payDurations,
  [{ key: "required", message: "Required" }]
);

const incomeStartAgeValidation = computedValidation(
  v$.value.quoteData.incomeStartAge,
  [
    { key: "required", message: "Required" },
    { key: "lessThanEndAge", message: "Start age must be less than end age" }
  ]
);

const incomeDurationValidation = computedValidation(
  v$.value.quoteData.incomeDurationType,
  [{ key: "required", message: "Required" }]
);

const refundOptionValidation = computedValidation(
  v$.value.quoteData.annuityGuaranteeType,
  [{ key: "required", message: "Required" }]
);

const certainPeriodValidation = computedValidation(
  v$.value.quoteData.certainPeriod,
  [
    { key: "required", message: "Required" },
    { key: "withinRange", message: "Must be between 5 and 30" }
  ]
);

const incomeStartDateValidation = computedValidation(
  v$.value.quoteData.incomeStartDate,
  [
    { key: "required", message: "Required" },
    {
      key: "afterBirthdate",
      message: "Income Start Date must be after birthdate"
    },
    { key: "afterToday", message: "Income Start Date must be after today" }
  ]
);

const soleIncomeStartAgeValidation = computedValidation(
  v$.value.quoteData.soleIncomeStartAge,
  [
    { key: "required", message: "Required" },
    { key: "withinRange", message: "Must be between 0 and 120" }
  ]
);

const incomeEndAgeValidation = computedValidation(
  v$.value.quoteData.incomeEndAge,
  [
    { key: "required", message: "Required" },
    {
      key: "greaterThanStartAge",
      message: "End age must be greater than start age"
    }
  ]
);

function handleEnabledUpdate() {
  if (props.enabledProps.save) {
    typeSavingBuffer.update(props.enabledProps.save);
  }
  if (hasItems.value && quoteData.value.enabled) emit("expand");
}

Object.keys(QuoteRequestProductTypeData()).forEach(attribute => {
  watch(
    () => props[attribute],
    v => {
      if (JSON.stringify(quoteData.value[attribute]) === JSON.stringify(v))
        return;
      quoteData.value[attribute] = v;
    },
    { deep: true }
  );

  watch(
    () => quoteData.value[attribute],
    v => {
      if (JSON.stringify(props[attribute]) === JSON.stringify(v)) return;
      emit(`update:${attribute}`, v);
    },
    { deep: true }
  );
});
</script>
