<template>
  <v-data-table
    class="transparent-data-table"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    data-testid="admin-logins-table"
    disable-pagination
    hide-default-footer
    disable-sort
    disable-filtering
  >
    <template #top>
      <v-card-subtitle
        v-if="primaryLogin?.userEmail"
        data-testid="primary-login-display"
        class="px-0 pt-0"
      >
        Primary Login: <strong>{{ primaryLogin.userEmail }}</strong>
      </v-card-subtitle>
      <v-btn
        v-if="!props.readonly"
        class="text-none"
        color="accent"
        data-testid="add-login"
        @click="showAddLoginDialog"
      >
        <v-icon> $mdi-plus</v-icon> Add Login
      </v-btn>
    </template>

    <template #[`item.username`]="{ item }">
      {{ item.username }}
    </template>

    <template #[`item.createdAt`]="{ item }">
      <timestamp-formatter :value="item.createdAt" />
    </template>

    <template #[`item.lastLogin`]="{ item }">
      <timestamp-formatter :value="item.lastLogin" />
    </template>

    <template #[`item.usersLastLogin`]="{ item }">
      <timestamp-formatter :value="item.usersLastLogin" />
    </template>

    <template #[`item.loginActions`]="{ item }">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            class="text-none"
            color="orange"
            icon
            data-testid="login-reset-tutorials"
            @click="resetTutorials(item)"
          >
            <v-icon> $mdi-school </v-icon>
          </v-btn>
        </template>
        <span> Reset User Tutorials </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            class="text-none"
            color="primary"
            icon
            data-testid="login-reset-password"
            @click="resetPassword(item)"
          >
            <v-icon> $mdi-lock-reset </v-icon>
          </v-btn>
        </template>
        <span> Send Forgot Password Email</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            class="text-none"
            color="red"
            icon
            data-testid="login-remove"
            @click="removeLogin(item)"
          >
            <v-icon> $mdi-delete </v-icon>
          </v-btn>
        </template>
        <span>Delete Login</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>
<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import TableHeader from "@/classes/data-table/TableHeader";

import { sendPasswordReset, updateUser } from "@/api/users.service";
import { useDialogStore } from "@/stores/dialog";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { useTable } from "@/composables/table.composable";
import { defineProps, watch } from "vue";
import { UserTutorials } from "@/stores/user";
import { useSettingsViewStore } from "@/stores/settings-view";
import { storeToRefs } from "pinia";
const props = defineProps({
  readonly: Boolean,
  isViewingSelf: Boolean,
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});
let store;

if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}

const { name, primaryLogin } = storeToRefs(store);

const headers = [
  new TableHeader({
    text: "Username",
    value: "username",
    map: "userEmail"
  }),
  new TableHeader({
    text: "Created",
    value: "createdAt",
    map: "createdAt"
  }),
  new TableHeader({
    text: `Last Login as ${name.value}`,
    value: "lastLogin",
    map: "lastLogin"
  }),
  new TableHeader({
    text: "User's Last Login",
    value: "usersLastLogin",
    map: "usersLastLogin"
  })
];
if (!props.readonly) {
  headers.push(
    new TableHeader({
      text: "Actions",
      value: "loginActions",
      map: "loginActions"
    })
  );
}

const table = useTable({
  headers
});

watch(name, () => {
  const index = table.headers.value.findIndex(t => t.value === "lastLogin");
  table.headers.value[index].text = `Last Login as ${name.value}`;
});

const dialog = useDialogStore();
function resetPassword(item) {
  dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Reset User Password",
    subtitle: "This will send a reset password email to the user.",
    func: () => sendPasswordReset(item.additional.userId)
  });
}
function resetTutorials(item) {
  dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Reset User Tutorials",
    subtitle: "This will reset all of the tutorials for this user.",
    func: () =>
      updateUser(item.additional.userId, { tutorials: UserTutorials() })
  });
}
async function removeLogin(item) {
  if (props.readonly) return;
  await dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Remove the login ${item.username} from this Advisor?`,
    subtitle: "This cannot be undone",
    func: () => store.deleteLogin(item.additional.id)
  });
  updateTable();
}
async function showAddLoginDialog() {
  if (props.readonly) return;
  await dialog.showDialog({
    component: "AddLoginDialog",
    loginableId: store.id,
    loginableType: store.type,
    func: store.createLogin
  });
  updateTable();
}

function updateTable() {
  table.items.value = Object.values(store.logins);
}

updateTable();
</script>
