<template>
  <v-card>
    <v-card-title>Add a Comp Builder</v-card-title>
    <v-card-text class="pb-0">
      <comp-builder-form v-model="compBuilder" ref="form" />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        @click="closeDialog"
        :disabled="loading"
      >
        Cancel
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        :loading="loading"
        @click="addCompBuilder"
      >
        <v-icon class="mr-1">$mdi-plus</v-icon>
        Create Comp Builder
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CompBuilderFactory from "@/factories/CompBuilderFactory";
import CompBuilderForm from "@/components/carriers/CompBuilderForm.vue";
import { createCompBuilder } from "@/api/comp-builders.service";
import { mapActions } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { useHead } from "@unhead/vue";

export default {
  setup() {
    useHead({ title: "Create Comp Builder" });
  },
  props: {
    carrier: Object
  },
  components: {
    CompBuilderForm
  },
  data() {
    return {
      compBuilder: new CompBuilderFactory(),
      loading: false
    };
  },
  created() {
    this.compBuilder = new CompBuilderFactory({ carrier: this.carrier });
  },
  methods: {
    ...mapActions(useDialogStore, ["closeDialog"]),
    addCompBuilder() {
      if (!this.$refs.form.valid()) return;
      this.loading = true;
      createCompBuilder({
        comp_builder: this.compBuilder.toCreateRequest()
      })
        .then(response => {
          this.closeDialog({
            compBuilder: {
              id: response.data.comp_builder.id,
              name: response.data.comp_builder.name,
              street: response.data.comp_builder.street,
              noPay: response.data.comp_builder.noPay
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
