<template>
  <v-card>
    <v-card-title>Impairment Counts by Type</v-card-title>
    <v-divider />
    <v-card-text>
      <v-data-table
        data-testid="impairment-report-table"
        must-sort
        :headers="headers"
        :items="items"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :loading="loading"
      >
        <template #[`item.text`]="{ item }">
          {{ item.text }}
        </template>
        <template #[`item.value`]="{ item }">
          <number-formatter :value="item.value" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import NumberFormatter from "@/components/shared/formatters/NumberFormatter.vue";
import { getImpairmentsReport } from "@/api/reports.service";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
export default {
  props: {
    all: Boolean
  },
  components: { NumberFormatter },
  data() {
    return {
      items: [],
      headers: [
        { text: "Impairment", value: "text" },
        { text: "Count", value: "value" }
      ],
      sortBy: "value",
      sortDesc: true,
      loading: false
    };
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async getData() {
      this.loading = true;
      const params = new URLSearchParams();
      if (this.all) params.append("view", "all");
      try {
        this.items = await getImpairmentsReport(params);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
