<template>
  <v-row dense class="ma-0">
    <v-col cols="12">
      <date-input
        v-model="followUp"
        label="Follow Up"
        data-testid="follow-up"
        :disabled="isGroupOne"
        :success="followUpValidation.success"
        :error-messages="followUpValidation.errorMessages"
        @input="validateAndSave('followUp')"
      >
        <template #append-outer>
          <active-save-indicator :controller="savingBuffer.followUp" />
        </template>
      </date-input>
    </v-col>
    <v-col cols="12">
      <component
        :is="lineComponent"
        :disabled="isGroupOne"
        :quote-id="id"
        :type="line"
        :birthdate="birthdate"
        :type-of-funds.sync="typeOfFunds"
        :product-type.sync="productType"
        :premium.sync="premium"
        :riders.sync="riders"
        :existing-coverage.sync="existingCoverage"
        :description.sync="description"
        :existing-benefit-maximum.sync="existingBenefitMaximum"
        :existing-benefit-period.sync="existingBenefitPeriod"
        :existing-elimination-period.sync="existingEliminationPeriod"
        :existing-employer-paid.sync="existingEmployerPaid"
        :existing-percentage-maximum.sync="existingPercentageMaximum"
        :monthly-benefit.sync="monthlyBenefit"
        :monthly-benefit-solve.sync="monthlyBenefitSolve"
        :mode.sync="mode"
        :benefit-period.sync="benefitPeriod"
        :elimination-period.sync="eliminationPeriod"
        :smoker.sync="smoker"
        :status.sync="status"
        :state.sync="state"
        :face-amount.sync="faceAmount"
        :inflation.sync="inflation"
        :discount.sync="discount"
        :surrender-periods.sync="surrenderPeriods"
        :income-duration-type.sync="incomeDurationType"
        :income-enabled.sync="incomeEnabled"
        :annuity-guarantee-type.sync="annuityGuaranteeType"
        :certain-period.sync="certainPeriod"
        :income-start-date.sync="incomeStartDate"
        :income-start-age.sync="incomeStartAge"
        :income-end-age.sync="incomeEndAge"
        :pay-durations.sync="payDurations"
        :business-owner.sync="businessOwner"
        :quote-save="quoteUpdate"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import DateInput from "@/components/shared/DateInput.vue";
import QuoteRequestAnnuityInfo from "@/components/quotes/quote-request/illustration-info/QuoteRequestAnnuityInfo.vue";
import QuoteRequestDisabilityInfo from "@/components/quotes/quote-request/illustration-info/QuoteRequestDisabilityInfo.vue";
import QuoteRequestLifeInfo from "@/components/quotes/quote-request/illustration-info/QuoteRequestLifeInfo.vue";
import QuoteRequestLtcInfo from "@/components/quotes/quote-request/illustration-info/QuoteRequestLtcInfo.vue";

import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { updateQuote } from "@/api/quotes.service";
import { updateIndividual } from "@/api/individual.service";
import { updateContractParty } from "@/api/contract-party.service";

import { storeToRefs } from "pinia";
import { useQuoteViewStore } from "@/stores/quote-view";
import { computedValidation } from "@/util/helpers";
import { isValid } from "date-fns";
import parse from "date-fns/parse";
import { computed, markRaw, nextTick } from "vue";
import * as QUOTE_TYPE from "@/constants/quote-types.constants";
import { useActiveSave } from "@/composables/active-save.composable";
import useVuelidate from "@vuelidate/core";
import { QuoteAttributeToRequest } from "@/factories/Quote";
import { useUserStore } from "@/stores/user";

const user = useUserStore();
const { isGroupOne } = storeToRefs(user);

const savingBuffer = {
  followUp: useActiveSave()
};

const quote = useQuoteViewStore();
const {
  id,
  line,
  typeOfFunds,
  productType,
  premium,
  riders,
  existingCoverage,
  description,
  existingBenefitMaximum,
  existingBenefitPeriod,
  existingEliminationPeriod,
  existingEmployerPaid,
  existingPercentageMaximum,
  followUp,
  monthlyBenefit,
  monthlyBenefitSolve,
  mode,
  benefitPeriod,
  eliminationPeriod,
  smoker,
  status,
  birthdate,
  state,
  faceAmount,
  inflation,
  discount,
  insured,

  // New fields
  surrenderPeriods,
  incomeDurationType,
  incomeEnabled,
  annuityGuaranteeType,
  certainPeriod,
  incomeStartDate,
  incomeStartAge,
  incomeEndAge,
  payDurations,
  businessOwner
} = storeToRefs(quote);

const lineComponent = computed(() => {
  switch (line.value) {
    case QUOTE_TYPE.ANNUITY:
      return markRaw(QuoteRequestAnnuityInfo);
    case QUOTE_TYPE.LIFE:
      return markRaw(QuoteRequestLifeInfo);
    case QUOTE_TYPE.LTC:
      return markRaw(QuoteRequestLtcInfo);
    default:
      return markRaw(QuoteRequestDisabilityInfo);
  }
});

const v$ = useVuelidate(
  {
    followUp: {
      valid: v => {
        try {
          if (!v) return false;
          const date = parse(v, "yyyy-MM-dd", new Date());
          return isValid(date);
        } catch (e) {
          return false;
        }
      },
      required: v => !!v
    }
  },
  { followUp },
  { $scope: "quote-request" }
);

const followUpValidation = computedValidation(v$.value.followUp, [
  { key: "required", message: "Required" },
  { key: "valid", message: "Must be a valid date" }
]);

function validateAndSave(attribute) {
  if (isGroupOne.value) return;
  if (v$.value[attribute].$invalid) return;
  savingBuffer[attribute].debounceUpdate(() => quoteUpdate(attribute));
}

async function quoteUpdate(attribute) {
  if (isGroupOne.value) return;
  await nextTick();
  await nextTick();
  if (attribute === "smoker") return insuredUpdate(attribute);
  return updateQuote(id.value, {
    quote: QuoteAttributeToRequest(quote, attribute)
  });
}

async function insuredUpdate(attribute) {
  const requestMap = {
    smoker: { smoker: smoker.value }
  };

  const body = requestMap[attribute];
  // Contract Party will occur when created from a case
  if (insured.value.ownable.type === "contract_party") {
    return updateContractParty(insured.value.ownable.id, body);
  }

  return updateIndividual(insured.value.ownable.id, {
    individual: body
  });
}
</script>
