<template>
  <div class="preliminary-card">
    <v-card>
      <v-form @submit.prevent="sendPasswordReset" autocomplete="off">
        <v-col>
          <v-row class="ma-0" align="center" justify="center">
            <app-logo large />
          </v-row>
        </v-col>
        <v-card-title class="justify-center font-weight-bold text-h5 mb-6">
          Sign in to BOSS
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="email"
                label="Email Address"
                outlined
                dense
                autofocus
                autocomplete="email"
                data-testid="email"
                :success="Boolean(email)"
                :prepend-inner-icon="mdiEmail"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="text-none"
            color="primary"
            block
            type="submit"
            data-testid="send-password-reset-email"
            :loading="loading"
          >
            Send Password Reset Email
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-card-text class="pt-0">
        Don't want to reset?
        <router-link :to="{ name: 'SignIn' }"> Sign in </router-link>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { mdiEmail } from "@mdi/js";
import AppLogo from "@/components/AppLogo.vue";
import { ref } from "vue";
import { resetPassword } from "@/api/auth.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useRouter } from "@/composables/compatible.composables";
import { storeToRefs } from "pinia";
import { useSignInStore } from "@/stores/sign-in";
import { useHead } from "@unhead/vue";

useHead({
  title: "Forgot Password"
});

const loading = ref(false);
const { email } = storeToRefs(useSignInStore());
const snackbar = useSnackbarStore();

const router = useRouter();
async function sendPasswordReset() {
  if (!email.value) return;
  loading.value = true;
  try {
    await resetPassword(email.value);
    snackbar.showSuccessSnackbar({
      message: "Password reset email sent successfully"
    });
    router.push({ name: "SignIn" });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value;
  }
}
</script>
