<template>
  <v-card flat color="section">
    <v-card-title>Quote & Apply</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <approved-domain-search
            v-model="defaultApprovedDomain"
            label="Default Quote & Apply Website"
            outlined
            dense
            data-testid="agent-profile-default-website"
            prepend-inner-icon="$mdi-sitemap"
            :agent-id="agent.id"
            :success="Boolean(defaultApprovedDomain)"
            @input="updateDefaultApprovedDomain"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="
                  savingBuffer.defaultApprovedDomain.controller.value
                "
              />
            </template>
          </approved-domain-search>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="schedulingLink"
            data-lpignore="true"
            label="Calendar Scheduling Link"
            prepend-inner-icon="$mdi-calendar"
            dense
            outlined
            data-testid="agent-profile-scheduling-link"
            prefix="https://"
            :success="schedulingLinkValidation.success"
            :error-messages="schedulingLinkValidation.errorMessages"
            persistent-hint
            hint=" "
            clearable
            @input="saveSchedulingLink"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.schedulingLink.controller.value"
              />
            </template>
            <template #message="{ message }">
              <v-row class="ma-0">
                <template v-if="message.length > 1">{{ message }}</template>
                <div v-else>
                  Add a calendar scheduling link (e.g. Calendly) to be used on
                  your Quote & Apply websites.
                  <a href="https://calendly.com/" target="_blank">
                    Click here to sign up for Calendly.</a
                  >
                </div>
              </v-row>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <div class="checkbox-width">
            <v-checkbox
              v-model="messageClient"
              label="Message Clients Status Updates"
              dense
              class="mt-1"
              hide-details
              data-testid="message-client"
              :success="Boolean(messageClient)"
              @change="updateMessageClient"
            >
              <template #label>
                Message Clients Status Updates
                <active-save-indicator
                  :controller="savingBuffer.messageClient.controller.value"
                />
              </template>
            </v-checkbox>
          </div>
          <div>
            Send automated status emails and text messages when your cases'
            status is changed to "Submitted", "Approved", or "Active".
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ApprovedDomainSearch from "@/components/shared/ApprovedDomainSearch.vue";

import { useActiveSave } from "@/composables/active-save.composable";
import { computedValidation } from "@/util/helpers";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { storeToRefs } from "pinia";
import { url } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const agent = useAgentSettingsStore();

const { schedulingLink, defaultApprovedDomain, messageClient } =
  storeToRefs(agent);

const savingBuffer = {
  defaultApprovedDomain: useActiveSave(),
  schedulingLink: useActiveSave(),
  messageClient: useActiveSave()
};

const v$ = useVuelidate(
  {
    schedulingLink: {
      validUrl(value) {
        if (!value) return true;
        if (value.includes("http://") || value.includes("https://"))
          return false;
        return url.$validator(`https://${value}`);
      }
    }
  },
  { schedulingLink, messageClient },
  { $autoDirty: true, $scope: false }
);

function updateDefaultApprovedDomain() {
  savingBuffer.defaultApprovedDomain.update(agent.updateDefaultApprovedDomain);
}

function updateMessageClient() {
  savingBuffer.messageClient.update(agent.updateMessageClient);
}

function saveSchedulingLink() {
  savingBuffer.schedulingLink.debounceUpdate(async () => {
    const isValid = await v$.value.schedulingLink.$validate();
    if (!isValid) return;
    return agent.updateSchedulingLink();
  });
}

const schedulingLinkValidation = computedValidation(v$.value.schedulingLink, {
  validUrl: "Invalid Url"
});
</script>
