<template>
  <v-card tile>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <div class="checkbox-width">
            <v-checkbox
              :input-value="strifeApproved"
              readonly
              @click="strifeApproved = !strifeApproved"
            >
              <template #label>
                Strife Approved (Available in Quote & Apply)
                <active-save-indicator
                  :controller="savingBuffer.strifeApproved"
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="checkbox-width">
            <v-checkbox
              :input-value="requiresWetSignature"
              readonly
              @click="requiresWetSignature = !requiresWetSignature"
            >
              <template #label>
                Requires Wet Signature
                <active-save-indicator
                  :controller="savingBuffer.requiresWetSignature"
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { mapActions } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { updateCarrier } from "@/api/carrier.service";
export default {
  mixins: [ActiveSaveMixin],
  components: { ActiveSaveIndicator },
  props: {
    carrier: Object
  },
  data() {
    const savingBuffer = this.generateSavingBuffer({
      items: ["strifeApproved", "requiresWetSignature"]
    });

    return {
      savingBuffer
    };
  },
  computed: {
    strifeApproved: {
      get() {
        return this.carrier.strifeApproved;
      },
      async set(v) {
        const res = await this.showDialog({
          component: "ConfirmationDialog",
          title: `${v ? "Enable" : "Disable"} ${
            this.carrier.name
          } in Quote & Apply`,
          subtitle: "Please confirm your intent"
        });

        if (!res?.confirm) return;

        this.carrier.strifeApproved = v;
        this.updateAttribute("strifeApproved");
      }
    },
    requiresWetSignature: {
      get() {
        return this.carrier.requiresWetSignature;
      },
      async set(v) {
        const res = await this.showDialog({
          component: "ConfirmationDialog",
          title: `${
            v
              ? "Require Wet Signature from"
              : "Remove Wet Signature Requirement from"
          } ${this.carrier.name} in Quote & Apply`,
          subtitle: "Please confirm your intent"
        });

        if (!res?.confirm) return;

        this.carrier.requiresWetSignature = v;
        this.updateAttribute("requiresWetSignature");
      }
    }
  },
  methods: {
    ...mapActions(useDialogStore, ["showDialog"]),
    updateAttribute(attribute) {
      this.updateAttributeWrapper(
        () =>
          updateCarrier(this.carrier.id, {
            carrier: this.carrier.getAttributeRequestValue(attribute)
          }),
        this.savingBuffer[attribute]
      );
    }
  }
};
</script>
