<template>
  <div>
    <h2 class="text-h6 mb-2">{{ props.title }}</h2>
    <p v-if="props.subtitle" class="text-body-2">
      {{ props.subtitle }}
    </p>
    <v-radio-group
      v-model="model"
      row
      :disabled="props.disabled"
      :success="validation.success && mappedItems.some(i => i.value === model)"
      :error-messages="validation.errorMessages"
    >
      <v-radio
        v-for="item in mappedItems"
        :data-testid="item.text"
        :key="item.value"
        :label="item.text"
        :value="item.value"
        @click="debounceUpdate(item.value)"
      >
        <template #label>
          <span>{{ item.text }}</span>
          <active-save-indicator
            v-if="props.save"
            :controller="savingBuffer[item.value].controller.value"
          />
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>
<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { useActiveSave } from "@/composables/active-save.composable";
import { defineProps, defineEmits, watch, ref, toRefs } from "vue";
const props = defineProps({
  disabled: Boolean,
  title: {
    type: String,
    required: true
  },
  subtitle: {
    type: String,
    required: false,
    default: ""
  },

  items: {
    type: Array,
    required: false,
    default: () => []
  },
  model: {
    type: String,
    required: false,
    default: null
  },
  validation: {
    type: Object,
    required: true
  },
  save: {
    type: Function,
    required: false,
    default: null
  }
});

const { model: modelWatcher } = toRefs(props);

const emit = defineEmits(["update:model"]);

const model = ref(props.model);

const mappedItems = props.items.map(item => {
  if (typeof item === "object") return item;
  return { value: item, text: item };
});

const savingBuffer = {};
mappedItems.forEach(r => {
  savingBuffer[r.value] = useActiveSave();
});

function debounceUpdate(key) {
  if (props.save && props.validation.success)
    savingBuffer[key].update(() => {
      if (props.validation.success) props.save();
    });
}

watch(modelWatcher, v => {
  if (model.value !== v) model.value = v;
});
watch(model, v => {
  if (modelWatcher.value !== v) emit("update:model", v);
});
</script>
