import { cropOrEllipsis } from "@/util/helpers";

const MarkdownIt = require("markdown-it");
const md = new MarkdownIt();
export default function Article(model = {}) {
  const calculateTimeToRead = content => {
    const wordCount = content.trim().split(/\s+/).length;
    const AVERAGE_WPM = 200;
    const calculatedMinutes = wordCount / AVERAGE_WPM;
    return Math.max(calculatedMinutes, 1).toFixed(0);
  };

  return {
    author: model?.author || "",
    avatar: model?.avatar || "",
    content: model?.content || "",
    createdAt: model?.createdAt || new Date(),
    updatedAt: model?.updatedAt || new Date(),
    link: model?.link || "",
    name: model?.name || "",
    id: model?.id || 0,
    published: model?.published || false,
    tags: model?.tags || [],
    timeToRead: 0,
    minimumGroup: null,
    setFromRequest(model = {}) {
      this.id = model?.id || 0;
      this.author = model?.author || "";
      this.avatar = model?.avatar_url || "";
      this.content = model?.content || "";
      this.createdAt = new Date(model?.created_at);
      this.updatedAt = new Date(model?.updated_at);
      this.link = model?.link || "";
      this.name = model?.name || "";
      this.published = model?.published;
      this.tags = model.tags || [];
      this.timeToRead = calculateTimeToRead(this.content);
      this.minimumGroup = model?.minimum_group || null;
    },
    generatePreview() {
      const div = document.createElement("div");
      div.innerHTML = md.render(this.content);
      return cropOrEllipsis(div.innerText);
    }
  };
}
