<template>
  <v-card>
    <v-card-title>
      <v-row class="pa-3 pr-0">
        eApps
        <v-spacer />
        <v-btn
          text
          color="primary"
          class="text-none"
          :to="{ name: 'QuoteAndApply', query: { view: 'list' } }"
          id="v-step-4"
        >
          <v-icon class="mr-1"> $mdi-open-in-new </v-icon>
          View eApps
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-list>
        <template v-if="eApps.length">
          <v-list-item
            link
            two-line
            v-for="(item, index) in eApps"
            :key="index"
            :to="{ name: 'ElectronicApplicationView', params: { id: item.id } }"
            class="orange-list-item"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }} - {{ item.status }}
              </v-list-item-title>
              <v-list-item-subtitle class="pt-2">
                <v-row class="pa-3">
                  <currency-formatter
                    class="mr-1"
                    :value="item.benefitAmount"
                    :decimal-length="0"
                  />
                  {{ item.productName }}</v-row
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-else>
          <v-list-item two-line class="ma-1 secondary rounded-list-item">
            <v-list-item-content>
              <v-list-item-title>
                Your recent eApps will appear here
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
export default {
  components: {
    CurrencyFormatter
  },
  props: {
    eApps: Array
  }
};
</script>
