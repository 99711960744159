<template>
  <v-card>
    <v-card-title>
      {{
        creating
          ? `Give an Advisor Access to ${advisorName}`
          : `Control ${connectionToEdit.connectedAdvisorName}'s Access`
      }}
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col v-if="creating" cols="12">
          <advisor-search
            v-model="advisor"
            data-testid="speicify-advisor"
            :success="advisorValidation.success"
            :error-messages="advisorValidation.errorMessages"
            :clearable="false"
          >
            <template #message v-if="canAddSelf && !currentAdvisorIsSelf">
              <a @click="makeUserTheAdvisor">
                <v-icon color="primary" size="12"> $mdi-plus </v-icon> Add
                {{ loginable.name }}
              </a>
            </template>
          </advisor-search>
        </v-col>
        <v-col cols="12">
          <v-sheet rounded color="section" class="pa-3">
            <connection-settings
              :connection-name="connectionName"
              :advisor-name="advisorName"
              :appointments.sync="connection.appointments"
              :appointments-emails.sync="connection.appointmentsEmails"
              :cases.sync="connection.cases"
              :cases-emails.sync="connection.casesEmails"
              :contract-parties.sync="connection.contractParties"
              :contract-parties-emails.sync="connection.contractPartiesEmails"
              :eapps.sync="connection.electronicApplications"
              :eapps-emails.sync="connection.electronicApplicationsEmails"
              :payments.sync="connection.payments"
              :payments-emails.sync="connection.paymentsEmails"
              :quick-quotes.sync="connection.quickQuotes"
              :quick-quotes-emails.sync="connection.quickQuotesEmails"
              :quotes.sync="connection.quotes"
              :quotes-emails.sync="connection.quotesEmails"
            />
          </v-sheet>
        </v-col>
        <v-col cols="12">
          <v-sheet color="section" rounded class="pa-3">
            <v-row class="ma-0">
              <v-col cols="12"> <h3>Additional Options</h3> </v-col>
              <v-col cols="6">
                <div class="checkbox-width">
                  <v-checkbox
                    dense
                    class="mt-0"
                    label="Custom Domains"
                    v-model="connection.customDomains"
                  />
                </div>
              </v-col>
              <v-col cols="6" v-if="!isForAgent">
                <div class="checkbox-width">
                  <v-checkbox
                    dense
                    class="mt-0"
                    label="Signer"
                    v-model="connection.signer"
                  />
                </div>
              </v-col>
              <v-col cols="6" v-if="isGroupTwoPlus">
                <div class="checkbox-width">
                  <v-checkbox
                    v-model="connection.hidden"
                    dense
                    class="mt-0"
                    label="Hidden"
                  />
                </div>
              </v-col>
              <v-col cols="6">
                <div class="checkbox-width">
                  <v-checkbox
                    v-model="connection.limitEmailRecipients"
                    dense
                    class="mt-0"
                    label="Limit Email Recipients"
                  />
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="text-none"
        outlined
        color="grey"
        :loading="loading"
        @click="closeDialog"
      >
        Cancel
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        data-testid="save-connection"
        :loading="loading"
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ConnectionSettings from "@/components/shared/ConnectionSettings.vue";
import { Connection } from "@/factories/ConnectionFactory";
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import { required } from "vuelidate/lib/validators";
import { parseErrorMessage, validationCompute } from "@/util/helpers";
import { mapActions, mapState } from "pinia";
import { updateConnection } from "@/api/connections.service";
import { createAgentConnection } from "@/api/agents.service";
import { createAgencyConnection } from "@/api/agencies.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  components: {
    AdvisorSearch,
    ConnectionSettings
  },
  props: {
    creating: Boolean,
    isForAgent: Boolean,
    canAddSelf: Boolean,
    advisorName: String,
    connectionToEdit: {
      type: Object,
      required: false,
      default: () => ({})
    },
    advisorId: {
      type: [Number, String],
      required: true
    },
    advisorType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      advisor: null,
      loading: false,
      connection: new Connection(this.connectionToEdit)
    };
  },
  created() {
    if (this.creating) this.initializeNewConnection();
  },
  watch: {
    advisor: {
      deep: true,
      handler({ id, type, name }) {
        this.connection.connectedAdvisorId = id;
        this.connection.connectedAdvisorType = type;
        this.connection.connectedAdvisorName = name;
      }
    }
  },
  computed: {
    ...mapState(useUserStore, ["isGroupTwoPlus", "loginable"]),
    connectionName() {
      if (this.creating) return this.advisor?.name || "The advisor";
      return this.connectionToEdit.connectedAdvisorName || "The advisor";
    },
    currentAdvisorIsSelf() {
      return this.advisor?.id === this.loginable.id;
    },
    advisorValidation() {
      const model = this.$v.advisor;

      return validationCompute(model, [
        { key: "required", message: "Required" }
      ]);
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, [
      "showSuccessSnackbar",
      "showErrorSnackbar",
      "showInfoSnackbar"
    ]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    makeUserTheAdvisor() {
      this.advisor = {
        name: this.loginable.name,
        id: this.loginable.id,
        type: this.loginable.type
      };
    },
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      this.showInfoSnackbar({
        message: "Applying Changes...",
        duration: 2000
      });

      if (this.creating) return this.createConnection();
      return this.updateConnection();
    },
    async createConnection() {
      if (this.creating) {
        let func = createAgencyConnection;

        if (this.advisorType.toLowerCase().includes("agent")) {
          func = createAgentConnection;
        }

        try {
          const connection = await func(this.advisorId, this.connection);
          this.loading = false;
          this.showSuccessSnackbar({
            message: "Connection Successfully Created.",
            duration: 5000
          });
          this.closeDialog({ connection });
        } catch (e) {
          this.loading = false;

          this.showErrorSnackbar({
            message: parseErrorMessage(e),
            duration: -1
          });
        }
      }
    },
    async updateConnection() {
      try {
        await updateConnection(this.connectionToEdit.id, this.connection);
        this.loading = false;
        this.showSuccessSnackbar({
          message: "Connection Successfully Updated.",
          duration: 5000
        });

        this.closeDialog({ connection: this.connection });
      } catch (e) {
        this.loading = false;
        this.showErrorSnackbar({
          message: parseErrorMessage(e),
          duration: -1
        });
      }
    },
    initializeNewConnection() {
      this.connection.appointments = true;
      this.connection.appointmentsEmails = true;
      this.connection.cases = true;
      this.connection.casesEmails = true;
      this.connection.contractParties = true;
      this.connection.contractPartiesEmails = true;
      this.connection.customDomains = true;
      this.connection.electronicApplications = true;
      this.connection.electronicApplicationsEmails = true;
      this.connection.hierarchy = false;
      this.connection.payments = true;
      this.connection.paymentsEmails = true;
      this.connection.quickQuotes = true;
      this.connection.quickQuotesEmails = true;
      this.connection.quotes = true;
      this.connection.quotesEmails = true;
      this.connection.signor = false;
    }
  },
  validations() {
    let validators = { advisor: {} };
    if (this.creating) {
      validators.advisor = { required };
    }
    return validators;
  }
};
</script>
