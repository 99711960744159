<template>
  <v-row dense>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12">
          <h2 class="font-weight-light mb-3">Appointment Information</h2>
        </v-col>
        <v-col cols="12" md="6">
          <carrier-search
            v-model="appointment.carrier"
            data-testid="carrier-search"
            :success="carrierValidation.success"
            :error-messages="carrierValidation.errorMessages"
            @input="handleCarrierChange"
          />
        </v-col>
        <v-col
          v-if="appointment.carrier && appointment.carrier.id"
          md="6"
          cols="12"
        >
          <advisor-search
            v-model="appointment.advisor"
            data-testid="advisor-search"
            appointment
            :carrier-id="appointment.carrier.id"
            :success="advisorValidation.success"
            :error-messages="advisorValidation.errorMessages"
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="appointment.insuredName"
            data-testid="insured-name"
            data-lpignore="true"
            prepend-inner-icon="$mdi-account"
            outlined
            dense
            label="Applicant Name"
            :success="Boolean(appointment.insuredName)"
          />
        </v-col>
        <template v-if="hasCarrier">
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="appointment.compBuilder"
              label="Comp Builder"
              placeholder="Select Comp Builder"
              item-value="id"
              item-text="name"
              prepend-inner-icon="$mdi-hand-saw"
              data-testid="comp-builder"
              outlined
              dense
              :success="compBuilderValidation.success"
              :error-messages="compBuilderValidation.errorMessages"
              :items="compBuilders"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="appointment.productId"
              label="Product"
              placeholder="Select Product"
              prepend-inner-icon="$mdi-cube"
              item-text="name"
              item-value="id"
              data-testid="product"
              outlined
              dense
              :items="products"
              :success="productIdValidation.success"
              :error-messages="productIdValidation.errorMessages"
            />
          </v-col>
        </template>
        <v-col cols="12" md="6">
          <state-select
            v-model="appointment.states"
            data-tesid="states"
            multiple
            data-testid="state"
            :success="statesValidation.success"
            :error-messages="statesValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <div class="checkbox-width">
            <v-checkbox
              v-model="appointment.disableAutoSubmit"
              class="mt-1"
              data-testid="disable-auto-submit"
              dense
              label="Disable Auto Submit"
              :success="Boolean(appointment.disableAutoSubmit)"
            />
          </div>
        </v-col>
        <v-col cols="12" md="6" v-if="showCreateForAssignable">
          <div class="checkbox-width">
            <v-checkbox
              v-model="appointment.createForAssignable"
              class="mt-1"
              data-testid="create-for-assignable"
              dense
              disabled
              :label="`Create Appointment for ${appointment.advisor.assignable_appointment_owner.name}`"
              :success="Boolean(appointment.createForAssignable)"
            />
          </div>
        </v-col>
        <v-col cols="12" md="6" v-if="showCreateForSigner">
          <div class="checkbox-width">
            <v-checkbox
              v-model="appointment.createForSigner"
              class="mt-1"
              data-testid="create-for-signer"
              dense
              disabled
              :label="`Create Appointment for ${appointment.advisor.principal_appointment_owner.name}`"
              :success="Boolean(appointment.createForSigner)"
            />
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12">
          <h2 class="font-weight-light mb-3">Appointment Dates</h2>
        </v-col>
        <v-col cols="12" md="6">
          <date-input
            v-model="appointment.applicationSignedDate"
            prepend-inner-icon="$mdi-calendar-edit"
            label="Application Sign Date"
            data-testid="application-signed-date"
            outlined
            dense
            clearable
            :success="Boolean(appointment.applicationSignedDate)"
          />
        </v-col>
        <v-col cols="12" md="6">
          <date-input
            v-model="appointment.effectiveDate"
            label="Effective"
            prepend-inner-icon="$mdi-calendar-start"
            data-testid="effective-date"
            outlined
            dense
            clearable
            :success="Boolean(appointment.effectiveDate)"
          />
        </v-col>
        <v-col cols="12" v-if="showDuplicateCreate">
          <v-alert :type="alertType" outlined>
            <div class="checkbox-width">
              <v-checkbox
                v-model="confirmDuplicate"
                data-testid="confirm-duplicate"
                hide-details
                class="mt-0"
                :label="`An existing appointment for ${appointment.advisor.name} already exists. Are you sure you want to create another?`"
                :success="confirmDuplicateValidation.success"
                :error-messages="confirmDuplicateValidation.errorMessage"
              />
            </div>
          </v-alert>
        </v-col>
        <v-col cols="12" v-if="showAppointmentAssignmentInfo">
          <v-alert
            data-testid="appointment-assignment-alert"
            type="info"
            outlined
          >
            This appointment will assign to
            {{ appointment.advisor.assignment_appointment.owner_name }}'s
            appointment "{{
              appointment.advisor.assignment_appointment.appointment_name
            }}"
          </v-alert>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import StateSelect from "@/components/shared/StateSelect.vue";
import DateInput from "@/components/shared/DateInput.vue";

import {
  AppointmentCreate,
  AppointmentCreateRequestBody
} from "@/factories/Appointment";

import { productSearch } from "@/api/products.service";
import { appointmentCreate } from "@/api/appointment.service";
import { compBuilderSearch } from "@/api/search.service";

import { useSnackbarStore } from "@/stores/snackbar";

import { computed, defineProps, ref, watch, defineExpose } from "vue";
import useVuelidate from "@vuelidate/core";
import { parseErrorMessage, validationComputeV2 } from "@/util/helpers";

const props = defineProps({ value: Object });

const snackbar = useSnackbarStore();
const appointment = ref(AppointmentCreate(props.value));
const confirmDuplicate = ref(false);
const products = ref([]);
const compBuilders = ref([]);

const v$ = useVuelidate(
  {
    appointment: {
      carrier: {
        required: v => Boolean(v?.id)
      },
      advisor: {
        required: v => Boolean(v?.id)
      },
      compBuilder: {
        required: v => Boolean(v)
      },
      states: {
        required: v => Boolean(v?.length)
      },
      productId: {
        required: v => Boolean(v)
      }
    },
    confirmDuplicate: {
      required: v => !showDuplicateCreate.value || v === true
    }
  },
  {
    appointment,
    confirmDuplicate
  }
);

const alertType = computed(() => {
  if (confirmDuplicate.value) return "success";
  if (confirmDuplicateValidation.value.errorMessages.length) return "error";
  return "warning";
});

const showDuplicateCreate = computed(
  () => appointment.value.advisor?.appointment_already_exists
);

const showCreateForAssignable = computed(
  () => appointment.value.advisor?.assignable_appointment_owner
);

const showCreateForSigner = computed(
  () => appointment.value.advisor?.principal_appointment_owner
);

const showAppointmentAssignmentInfo = computed(
  () => appointment.value.advisor?.assignment_appointment
);

const hasCarrier = computed(() => Boolean(appointment.value.carrier?.id));

const carrierValidation = computed(() => {
  return validationComputeV2(v$.value.appointment.carrier, [
    { key: "required", message: "Required" }
  ]);
});

const advisorValidation = computed(() => {
  return validationComputeV2(v$.value.appointment.advisor, [
    { key: "required", message: "Required" }
  ]);
});

const compBuilderValidation = computed(() => {
  return validationComputeV2(v$.value.appointment.compBuilder, [
    { key: "required", message: "Required" }
  ]);
});

const productIdValidation = computed(() => {
  return validationComputeV2(v$.value.appointment.productId, [
    { key: "required", message: "Required" }
  ]);
});

const statesValidation = computed(() => {
  return validationComputeV2(v$.value.appointment.states, [
    { key: "required", message: "Required" }
  ]);
});

const confirmDuplicateValidation = computed(() => {
  return validationComputeV2(v$.value.confirmDuplicate, [
    { key: "required", message: "Required" }
  ]);
});

watch(
  showCreateForAssignable,
  v => (appointment.value.createForAssignable = Boolean(v))
);

watch(
  showCreateForSigner,
  v => (appointment.value.createForSigner = Boolean(v))
);

watch(showDuplicateCreate, () => {
  confirmDuplicate.value = false;
});

function handleCarrierChange() {
  if (!appointment.value.carrier) return;
  if (appointment.value.advisor?.id) appointment.value.advisor = {};

  fetchCarrierData();
}

function fetchCarrierData() {
  fetchProducts(appointment.value.carrier);
  fetchCompBuilders(appointment.value.carrier);
}

async function fetchCompBuilders(value) {
  try {
    compBuilders.value = await compBuilderSearch(value.id);
    if (appointment.value.compBuilder) return;
    const defaultCompBuilder = compBuilders.value.find(v => v.street === true);
    appointment.value.compBuilder = defaultCompBuilder?.id;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}
async function fetchProducts(value) {
  products.value = await productSearch(value.id);
  if (props.value?.productId) {
    const product = products.value.find(p => p.id === props.value.productId);
    if (product) appointment.value.product = product;
  }
}

async function createAppointment() {
  const valid = await v$.value.$validate();
  if (!valid) return;

  const params = {
    appointment: AppointmentCreateRequestBody(appointment.value)
  };
  try {
    const res = await appointmentCreate(params);
    return res;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  }
}

defineExpose({ createAppointment });

if (appointment.value.carrier?.id) fetchCarrierData();
</script>
