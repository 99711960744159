import { getHttpClient } from "@/http-client";
import {
  setContractingQuestionExplanationFromRaw,
  setRawFromContractingQuestionExplanation
} from "@/factories/AgentSettingsFactory";
const baseURL = "api/boss/explanations";

/**
 * Create an explanation based on a legal question
 * @param {Number|String} param0 contractingAnswerId
 * @returns {Promise<LegalQuestionExplanationFactory>}
 */
export async function createExplanation(questionId, explanation) {
  const body = {
    contracting_answer_id: questionId,
    ...setRawFromContractingQuestionExplanation(explanation)
  };

  const { data } = await getHttpClient().post(baseURL, { explanation: body });
  return setContractingQuestionExplanationFromRaw(data.explanation);
}

/**
 * Update an explanation
 * @param {Number} param0 explanationId
 * @param {Object} param1 body
 * @returns {Promise}
 */
export async function updateExplanation(explanationId, explanation) {
  await getHttpClient().put(`${baseURL}/${explanationId}`, {
    explanation: setRawFromContractingQuestionExplanation(explanation)
  });

  return explanation;
}

/**
 * Delete an explanation
 * @param {Number} param0 explanationId
 * @returns {Promise}
 */
export function deleteExplanation(explanationId) {
  return getHttpClient().delete(`${baseURL}/${explanationId}`);
}
