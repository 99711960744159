import { add, format, sub } from "date-fns";

export const DATE_FILTERS = {
  TODAY: {
    text: "Today",
    value: "today",
    fn: () => ({
      start: format(new Date(), "yyyy-MM-dd"),
      finish: format(new Date(), "yyyy-MM-dd")
    })
  },
  LAST_7_DAYS: {
    text: "Last 7 Days",
    value: "last-7-days",
    fn: () => ({
      start: format(sub(new Date(), { days: 7 }), "yyyy-MM-dd"),
      finish: format(new Date(), "yyyy-MM-dd")
    })
  },
  LAST_30_DAYS: {
    text: "Last 30 Days",
    value: "last-30-days",
    fn: () => ({
      start: format(sub(new Date(), { days: 30 }), "yyyy-MM-dd"),
      finish: format(new Date(), "yyyy-MM-dd")
    })
  },
  LAST_6_MONTHS: {
    text: "Last 6 Months",
    value: "last-6-months",
    fn: () => ({
      start: format(sub(new Date(), { months: 6 }), "yyyy-MM-dd"),
      finish: format(new Date(), "yyyy-MM-dd")
    })
  },
  LAST_12_MONTHS: {
    text: "Last 12 Months",
    value: "last-12-months",
    fn: () => ({
      start: format(sub(new Date(), { years: 1 }), "yyyy-MM-dd"),
      finish: format(new Date(), "yyyy-MM-dd")
    })
  },
  THIS_MONTH: {
    text: "This Month",
    value: "this-month",
    fn: () => ({
      start: format(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        "yyyy-MM-dd"
      ),
      finish: format(new Date(), "yyyy-MM-dd")
    })
  },
  THIS_YEAR: {
    text: "This Year",
    value: "this-year",
    fn: () => ({
      start: format(new Date(new Date().getFullYear(), 0, 1), "yyyy-MM-dd"),
      finish: format(new Date(), "yyyy-MM-dd")
    })
  },
  LAST_YEAR: {
    text: "Last Year",
    value: "last-year",
    fn: () => ({
      start: format(
        sub(new Date(new Date().getFullYear(), 0, 1), { years: 1 }),
        "yyyy-MM-dd"
      ),
      finish: format(
        sub(new Date(new Date().getFullYear(), 0, 1), { days: 1 }),
        "yyyy-MM-dd"
      )
    })
  },
  NEXT_7_DAYS: {
    text: "Next 7 Days",
    value: "next-7-days",
    fn: () => ({
      start: format(new Date(), "yyyy-MM-dd"),
      finish: format(add(new Date(), { days: 7 }), "yyyy-MM-dd")
    })
  },
  NEXT_30_DAYS: {
    text: "Next 30 Days",
    value: "next-30-days",
    fn: () => ({
      start: format(new Date(), "yyyy-MM-dd"),
      finish: format(add(new Date(), { days: 30 }), "yyyy-MM-dd")
    })
  },
  NEXT_6_MONTHS: {
    text: "Next 6 Months",
    value: "next-6-months",
    fn: () => ({
      start: format(new Date(), "yyyy-MM-dd"),
      finish: format(add(new Date(), { months: 6 }), "yyyy-MM-dd")
    })
  },
  NEXT_12_MONTHS: {
    text: "Next 12 Months",
    value: "next-12-months",
    fn: () => ({
      start: format(new Date(), "yyyy-MM-dd"),
      finish: format(add(new Date(), { years: 1 }), "yyyy-MM-dd")
    })
  }
};

export const pastDateFilters = [
  DATE_FILTERS.TODAY,
  DATE_FILTERS.LAST_7_DAYS,
  DATE_FILTERS.LAST_30_DAYS,
  DATE_FILTERS.LAST_6_MONTHS,
  DATE_FILTERS.LAST_12_MONTHS,
  DATE_FILTERS.THIS_MONTH,
  DATE_FILTERS.THIS_YEAR,
  DATE_FILTERS.LAST_YEAR
];

export const futureDateFilters = [
  DATE_FILTERS.NEXT_7_DAYS,
  DATE_FILTERS.NEXT_30_DAYS,
  DATE_FILTERS.NEXT_6_MONTHS,
  DATE_FILTERS.NEXT_12_MONTHS
];

export const isDateType = type => {
  return pastDateFilters.some(filter => {
    return filter.value === type;
  });
};
