<template>
  <v-card color="section" flat tile>
    <v-card-text>
      <chat
        title="Quote Notes"
        :messages="messages"
        :pusher-id="`private-${id}-Quote`"
        ref="chat"
        email
        @send-message="sendChatMessage"
        @new-note="handleNewNote"
        @remove-message="removeFromMessages"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import Chat from "@/components/shared/chat/Chat.vue";
import sortBy from "lodash/sortBy";
import NoteFactory from "@/factories/NoteFactory";
import { parseErrorMessage } from "@/util/helpers";
import { mapActions, mapState } from "pinia";
import { createNote } from "@/api/notes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useQuoteViewStore } from "@/stores/quote-view";
export default {
  components: {
    Chat
  },
  data() {
    return {
      messages: []
    };
  },
  created() {
    const user = useUserStore();
    const messages = sortBy(this.notes, "createdAt");
    messages.unshift({
      note: `Hi ${user.loginable.name}, feel free to make notes here.`,
      ownable: { name: "BackNine" }
    });
    this.messages.push(...messages);
  },
  computed: {
    ...mapState(useUserStore, ["loginable"]),
    ...mapState(useQuoteViewStore, ["notes", "id"])
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    removeFromMessages(message) {
      const index = this.messages.findIndex(({ id }) => id === message.id);
      this.messages.splice(index, 1);
    },

    async sendChatMessage(note) {
      this.$refs.chat.$refs.chatFooter.loading = true;
      const newNote = new NoteFactory({
        documents: note.files,
        email: note.sendEmail ? 1 : 0,
        phoneCall: note.sendPhoneCall,
        note: note.message,
        notableId: this.id,
        notableType: "Quote"
      });
      try {
        await createNote(newNote.toCreateRequest());
        this.$refs.chat.$refs.chatFooter.reset();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
      } finally {
        this.$refs.chat.$refs.chatFooter.loading = false;
      }
    },
    handleNewNote({ note: rawNote } = {}) {
      const note = new NoteFactory();
      note.setFromRequest(rawNote);
      this.messages.push(note);
    }
  }
};
</script>
