<template>
  <v-data-table :headers="headers" :items="items" :loading="loading">
    <template #[`item.amount`]="{ item }">
      <currency-formatter :value="item.amount" />
    </template>
    <template #[`item.paymentTypes`]="{ item }">
      <p v-for="type in item.paymentTypes" :key="type">
        {{ type }}
      </p>
    </template>
  </v-data-table>
</template>

<script>
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";
import TableHeader from "@/classes/data-table/TableHeader";
import Table from "@/classes/data-table/Table";
export default {
  components: {
    CurrencyFormatter
  },
  props: {
    parties: Array,
    loading: Boolean
  },
  mixins: [GenericTableMixin],
  data() {
    const headers = [
      new TableHeader({
        text: "Payable",
        value: "payable",
        map: "payable.name"
      }),
      new TableHeader({
        text: "Amount",
        value: "amount",
        map: "amount"
      }),
      new TableHeader({
        text: "Payment Types",
        value: "paymentTypes",
        map: "payment_types"
      })
    ];

    const paymentsTable = new Table({
      text: "Payments",
      headers,
      key: "payments"
    });

    return {
      table: paymentsTable
    };
  },
  computed: {
    headers() {
      return this.table.headers;
    },
    items() {
      return this.tableMap(this.parties, this.table.headers);
    },
    sort: {
      get() {
        return this.table.sort;
      },
      set(value) {
        this.table.sort = value;
      }
    },
    options: {
      get() {
        return this.table.options;
      },
      set(value) {
        this.table.options = value;
      }
    },
    sortFilterMap() {
      return this.table.sortFilterMap;
    }
  }
};
</script>
