import { useZendeskStore } from "@/stores/zendesk";
import { useUserStore } from "@/stores/user";
import { useVuetify, useRouter } from "@/composables/compatible.composables";
import { computed, watch } from "vue";
import { storeToRefs } from "pinia";

export const useZendeskManager = () => {
  if (window.Cypress) return;
  const zendesk = useZendeskStore();
  const user = useUserStore();
  const { id } = storeToRefs(user);

  const vuetify = useVuetify();
  const router = useRouter();

  function updateZendeskLocation() {
    let breakpoint = vuetify.breakpoint.smAndDown;
    if (
      [
        "LoggedInUserSettings",
        "AdvisorEdit",
        "AgentEdit",
        "AgencyEdit"
      ].includes(router.currentRoute.name)
    ) {
      breakpoint = vuetify.breakpoint.mdAndDown;
    }
    if (breakpoint) {
      zendesk.useMobileOffset();
    } else {
      zendesk.useDesktopOffset();
    }
  }

  const routeName = computed(() => {
    return router?.currentRoute?.name;
  });

  watch(routeName, () => {
    const shouldHide = ["ElectronicApplicationView", "CaseView"].includes(
      routeName.value
    );
    if (shouldHide) zendesk.hide();
    else zendesk.show();
  });
  watch(() => vuetify.breakpoint, updateZendeskLocation, { deep: true });
  watch(id, zendesk.loadZendesk, { deep: true });
};
