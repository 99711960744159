<template>
  <v-card flat color="section">
    <v-card-title>Website Learning Series</v-card-title>
    <v-card-text>
      <v-row class="ma-0">
        <v-list style="width: 100%" class="py-0">
          <v-list-item
            v-for="{ key, title, subtitle, link, completed } in tutorialItems"
            :key="key"
            :to="completed ? undefined : link"
            exact
          >
            <v-list-item-icon>
              <v-icon v-if="completed" color="success">
                $mdi-checkbox-marked-circle-outline
              </v-icon>
              <v-icon v-else color="error"> $mdi-circle-off-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ title }}
                <span class="grey--text ml-1">
                  {{ completed ? "(Complete)" : "(Incomplete)" }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle> {{ subtitle }} </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip v-if="completed" top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    :data-testid="`reset-tutorial-${title}`"
                    @click="resetTutorial({ title, key })"
                  >
                    <v-icon>$mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span> Reset {{ title }} </span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { AVAILABLE_SERIES } from "@/composables/series-composable";
import { useDialogStore } from "@/stores/dialog";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const user = useUserStore();
const { tutorials } = storeToRefs(user);
const dialog = useDialogStore();
const tutorialItems = computed(() => {
  return [
    {
      title: "Welcome Tour",
      subtitle:
        "On first login, users are prompted with a brief explanation on the first page and site navigation",
      key: "home",
      completed: Boolean(tutorials.value.home),
      link: {
        name: "Home"
      }
    },
    {
      title: "Walkthrough",
      subtitle:
        "One of the onboarding items. The walkthrough explains available tooling, contracting, and Quote & Apply",
      key: "series_walkthrough",
      completed: Boolean(tutorials.value.series_walkthrough),
      link: {
        name: "HelpCenterSeries",
        params: { id: AVAILABLE_SERIES.WALKTHROUGH }
      }
    }
  ];
});

async function resetTutorial({ title, key }) {
  await dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Reset ${title}?`,
    subtitle: `This will mark ${title} as incomplete`,
    func: () => {
      tutorials.value[key] = false;
      return user.saveTutorials();
    }
  });
}
</script>
