<template>
  <v-card>
    <v-card-title>
      {{ props.explanationId ? "Update" : "Create" }} an Explanation
    </v-card-title>
    <v-card-subtitle>
      Please provide the event date, event, reason, and an explanation for this
      instance.
    </v-card-subtitle>
    <v-card-text id="explanation-container">
      <v-row class="mt-0" dense>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="explanation.action"
            label="Event"
            data-lpignore="true"
            prepend-inner-icon="$mdi-clipboard-text-outline"
            outlined
            dense
            data-testid="explanation-action"
            :success="actionValidation.success"
            :error-messages="actionValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <date-input
            v-model="explanation.actionDate"
            label="Event Date"
            prepend-inner-icon="$mdi-calendar"
            outlined
            dense
            data-testid="explanation-action-date"
            :success="actionDateValidation.success"
            :error-messages="actionDateValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="explanation.reason"
            label="Reason"
            data-lpignore="true"
            prepend-inner-icon="$mdi-clipboard-list-outline"
            outlined
            dense
            rows="2"
            data-testid="explanation-reason"
            :success="reasonValidation.success"
            :error-messages="reasonValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="explanation.explanation"
            label="Explanation"
            data-lpignore="true"
            prepend-inner-icon="$mdi-clipboard-alert-outline"
            outlined
            dense
            data-testid="explanation-explanation"
            rows="2"
            :success="explanationTextValidation.success"
            :error-messages="explanationTextValidation.errorMessages"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        data-testid="explanation-save"
        :loading="loading"
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import DateInput from "@/components/shared/DateInput.vue";

import { required } from "@vuelidate/validators";
import { dateIsBeforeNow } from "@/util/vuelidateValidators";
import useVuelidate from "@vuelidate/core";
import { parseErrorMessage, validationComputeV2 } from "@/util/helpers";

import { computed, defineProps, ref } from "vue";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { ContractingQuestionExplanation } from "@/factories/AgentSettingsFactory";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useVuetify } from "@/composables/compatible.composables";

const props = defineProps({
  questionId: [Number, String],
  explanationId: [Number, String]
});

const loading = ref(false);

const agent = useAgentSettingsStore();
const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const explanation = ref(ContractingQuestionExplanation());

const storedExplanation =
  agent.contractingQuestions[props.questionId].explanations[
    props.explanationId
  ];
if (storedExplanation) {
  explanation.value = ContractingQuestionExplanation(storedExplanation);
}

const rules = {
  explanation: {
    actionDate: {
      required,
      dateIsBeforeNow
    },
    action: { required },
    reason: { required },
    explanation: { required }
  }
};
const state = { explanation };
const v$ = useVuelidate(rules, state, { $autoDirty: true, $scope: false });

const actionDateValidation = computed(() => {
  const model = v$.value.explanation.actionDate;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    {
      key: "dateIsBeforeNow",
      message: "Must be a valid date on or before today"
    }
  ]);
});
const actionValidation = computed(() => {
  const model = v$.value.explanation.action;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const reasonValidation = computed(() => {
  const model = v$.value.explanation.reason;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const explanationTextValidation = computed(() => {
  const model = v$.value.explanation.explanation;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

async function save() {
  const validExplanation = await v$.value.$validate();
  if (!validExplanation) {
    snackbar.showErrorSnackbar({ message: "Invalid Fields Detected" });
    goToInvalid();
    return;
  }

  if (props.explanationId) update();
  else create();
}

async function create() {
  loading.value = true;
  try {
    await agent.createContractingExplanation(
      props.questionId,
      explanation.value
    );
    dialog.closeDialog();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

async function update() {
  loading.value = true;
  try {
    await agent.updateContractingExplanation(
      props.questionId,
      props.explanationId,
      explanation.value
    );
    dialog.closeDialog();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

const vuetify = useVuetify();
function goToInvalid() {
  const el = document.getElementsByClassName("v-input error--text")[0];
  vuetify.goTo(el, {
    container: "#explanation-container"
  });
}
</script>
