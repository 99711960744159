<template>
  <v-card tile flat color="section">
    <v-data-table
      show-expand
      item-key="additional.id"
      data-testid="quote-history-table"
      :loading="table.loading.value"
      :items="table.mappedItems.value"
      :headers="table.headers.value"
      :options="table.options.value"
      :custom-sort="handleSort"
      class="transparent-data-table"
    >
      <template #[`item.avatar`]="{ item }">
        <v-img
          :src="item.avatar"
          max-height="50"
          max-width="100"
          contain
          class="my-3"
        />
      </template>
      <template #[`item.amount`]="{ item }">
        <div v-if="item.additional.faceAmount">
          <currency-formatter
            :value="item.additional.faceAmount"
            :decimal-length="0"
          />
          <span class="grey--text"> (Death Benefit) </span>
        </div>
        <div v-if="item.additional.poolOfMoney">
          <currency-formatter
            :value="item.additional.poolOfMoney"
            :decimal-length="0"
          />
          <span class="grey--text"> (Pool of Money) </span>
        </div>
      </template>
      <template #[`item.premium`]="{ item }">
        <currency-formatter :value="item.premium" :decimal-length="2" />
        <div class="grey--text inline-block">
          /<mode-formatter
            :value="item.additional.mode"
            format="abbreviated"
            lowercase
          />
        </div>
      </template>
      <template #[`item.createdAt`]="{ item }">
        <timestamp-formatter
          :value="item.createdAt"
          parser="none"
          format="date-time"
        />
      </template>
      <template #[`item.insured`]="{ item }">
        {{ item.additional.firstName }} {{ item.additional.lastName }}
      </template>
      <template #[`item.illustration`]="{ item }">
        <v-btn
          v-if="item.illustration"
          icon
          v-bind="downloadDocument(item.illustration)"
        >
          <v-icon color="error">{{ mdiFilePdfBox }}</v-icon>
        </v-btn>
      </template>
      <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
        <div class="row ma-0 align-center justify-center">
          <v-btn
            v-if="item.additional.canDelete"
            icon
            color="error"
            small
            data-testid="delete-quote-button"
            @click="deleteQuote(item.additional)"
          >
            <v-icon> {{ mdiDelete }} </v-icon>
          </v-btn>

          <v-btn icon small @click="expand(!isExpanded)">
            <v-icon v-if="isExpanded"> {{ mdiChevronUp }} </v-icon>
            <v-icon v-else> {{ mdiChevronDown }} </v-icon>
          </v-btn>
        </div>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-4">
          <electronic-application-quote-history-item :item="item.additional" />
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script setup>
import ElectronicApplicationQuoteHistoryItem from "@/components/electronic-applications/ElectronicApplicationQuoteHistoryItem.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import ModeFormatter from "@/components/shared/formatters/ModeFormatter.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import { downloadFileAsLink, parseErrorMessage } from "@/util/helpers";

import { getDocumentUrl } from "@/api/documents.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useEappViewStore } from "@/stores/eapp-view";
import { useDialogStore } from "@/stores/dialog";

import { useTable } from "@/composables/table.composable";
import { useVuetify } from "@/composables/compatible.composables";

import {
  mdiFilePdfBox,
  mdiChevronUp,
  mdiChevronDown,
  mdiDelete
} from "@mdi/js";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const eapp = useEappViewStore();

const headers = [
  new TableHeader({
    text: "",
    value: "avatar",
    map: "carrier.avatarUrl"
  }),
  new TableHeader({
    text: "Product",
    value: "product",
    map: "product.name"
  }),
  new TableHeader({
    text: "Insured",
    value: "insured",
    map: "insured.name"
  }),
  new TableHeader({
    text: "Amount",
    value: "amount",
    map: "amount",
    ...TableHeader.IS_SORTABLE,
    sort: (a, b) => {
      if (!a || !b) return 0;
      return (
        a.additional.faceAmount +
        a.additional.poolOfMoney -
        b.additional.faceAmount -
        b.additional.poolOfMoney
      );
    }
  }),
  new TableHeader({
    text: "Premium",
    value: "premium",
    map: "premium"
  }),
  new TableHeader({
    text: "Illustration",
    value: "illustration",
    map: "illustration.uid",
    align: "center"
  }),
  new TableHeader({
    text: "Created",
    value: "createdAt",
    map: "createdAt",
    align: "center",
    ...TableHeader.IS_SORTABLE
  }),
  new TableHeader({
    text: "Actions",
    value: "data-table-expand",
    width: "125px",
    align: "center"
  })
];

const table = useTable({
  headers,
  getData: eapp.getQuoteHistory,
  options: new TableOptions(["createdAt"], [true])
});

function handleSort(items, sortBy, sortDesc) {
  const unsorted = [...items];
  const [col] = sortBy;
  if (!col) return unsorted;
  const [desc] = sortDesc;

  if (col === "amount") {
    unsorted.sort((a, b) => {
      let amount =
        a.additional.faceAmount +
        a.additional.poolOfMoney -
        b.additional.faceAmount -
        b.additional.poolOfMoney;

      if (desc) amount *= -1;
      return amount;
    });
  }
  if (col === "createdAt") {
    unsorted.sort((a, b) => {
      let delta = new Date(a.createdAt) - new Date(b.createdAt);
      if (desc) delta *= -1;
      return delta;
    });
  }

  return unsorted;
}
async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

function deleteQuote(quote) {
  dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Are you sure that you want delete this quote?",
    subtitle: "Please confirm your intent",
    func: async () => {
      await eapp.deleteQuote(quote.id);
      await getData();
    }
  });
}

const vuetify = useVuetify();
function downloadDocument(uid) {
  return downloadFileAsLink(getDocumentUrl(uid), vuetify.breakpoint.mdAndDown);
}

getData();
</script>
