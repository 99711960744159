<template>
  <div>
    <v-divider class="pb-3" />
    <v-row class="px-6 pb-3" v-if="checkboxes && checkboxes.length">
      <v-checkbox
        v-for="checkbox in checkboxes"
        v-model="options[checkbox.key]"
        class="mr-4"
        hide-details
        :key="checkbox.key"
        :label="checkbox.label"
        :data-testid="`chat-${checkbox.dataTestid}`"
        :disabled="loading"
      />
    </v-row>
    <v-row class="px-6" v-else-if="email || phone">
      <v-checkbox
        v-if="email"
        v-model="sendEmail"
        data-testid="chat-email"
        label="Send Email"
        class="mt-0"
        :disabled="loading"
      />
      <v-checkbox
        v-if="isGroupTwoPlus && phone"
        v-model="sendPhoneCall"
        data-testid="chat-phone-call"
        :disabled="loading"
        label="Phone Call"
        class="mt-0 ml-2"
      />
    </v-row>
    <v-textarea
      v-if="noDocuments"
      v-model="message"
      data-testid="chat-message-input"
      prepend-inner-icon="$mdi-text"
      dense
      outlined
      class="pa-3"
      v-cloak
      rows="2"
      auto-grow
      :disabled="loading"
      :placeholder="
        files.length ? filesOnlyPlaceholderMessage : 'Write a comment'
      "
      :hide-details="!messageValidation.errorMessages.length"
      :error-messages="messageValidation.errorMessages"
      :success="messageValidation.success"
    />
    <v-textarea
      v-else
      v-model="message"
      data-testid="chat-message-input"
      prepend-inner-icon="$mdi-text"
      dense
      outlined
      class="pa-3"
      v-cloak
      rows="2"
      auto-grow
      :placeholder="
        files && files.length ? filesOnlyPlaceholderMessage : 'Write a comment'
      "
      :disabled="loading"
      :hide-details="!messageValidation.errorMessages.length"
      :error-messages="messageValidation.errorMessages"
      :success="messageValidation.success"
      @drop.prevent="addDropFile"
      @dragover.prevent
    >
      <template #append>
        <v-file-input
          :disabled="loading"
          hide-input
          hide-details
          class="pt-0 mt-0"
          multiple
          v-model="compFiles"
          :accept="ALL_FILE_EXTENSIONS"
          style="max-width: 34px !important"
        />
      </template>
    </v-textarea>
    <v-row class="px-6 pt-2" align="center">
      <v-chip-group column>
        <v-chip
          :disabled="loading"
          pill
          v-for="(file, index) in files"
          :key="index"
          close
          @click:close="removeFile(index)"
          close-icon="$mdi-close"
        >
          {{ file.name }}
        </v-chip>
      </v-chip-group>
    </v-row>
    <v-row class="px-6 mb-3">
      <v-spacer />
      <template v-if="additionalButtons?.length">
        <v-btn
          v-for="button in additionalButtons"
          :key="button.text"
          :data-testid="button.dataTestid"
          class="text-none"
          @click="button.method"
        >
          {{ button.text }}
        </v-btn>
      </template>
      <v-btn
        color="primary"
        class="text-none ml-1"
        data-testid="chat-send-message"
        :loading="loading"
        @click="submitMessage"
      >
        Comment
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { ALL_FILE_EXTENSIONS } from "@/data/allowed-file-extensions";

import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";
export default {
  props: {
    email: Boolean,
    phone: Boolean,
    noDocuments: Boolean,
    checkboxes: Array,
    additionalButtons: Array,
    createFunc: Function
  },
  data() {
    const options = this.createOptions();
    return {
      options,
      sendEmail: true,
      sendPhoneCall: false,
      files: [],
      message: "",
      loading: false,
      ALL_FILE_EXTENSIONS
    };
  },
  computed: {
    ...mapState(useUserStore, ["isGroupTwoPlus", "loginable"]),
    compFiles: {
      get() {
        return this.files;
      },
      set(val) {
        this.files = this.files.concat(val);
      }
    },
    messageValidation() {
      const success = !this.$v.message.$invalid;
      const errorMessages = [];
      if (this.$v.message.$dirty) {
        if (!this.$v.message.required) errorMessages.push("Required");
      }
      return {
        success,
        errorMessages
      };
    },
    filesOnlyPlaceholderMessage() {
      return `${this.loginable.name} uploaded ${this.files?.length} attachment${
        this.files?.length === 1 ? "" : "s"
      }.`;
    }
  },
  methods: {
    createOptions() {
      const options = {};
      this.checkboxes?.forEach(checkbox => {
        options[checkbox.key] = checkbox.default || false;
      });
      return options;
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    submitMessage() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      if (this.createFunc) {
        this.loading = true;
        this.createFunc({
          ...this.options,
          message: this.message || this.filesOnlyPlaceholderMessage,
          files: this.files
        })
          .then(() => {
            this.reset();
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$emit("send-message", {
          sendEmail: this.sendEmail,
          sendPhoneCall: this.sendPhoneCall,
          files: this.files,
          message: this.message || this.filesOnlyPlaceholderMessage
        });
      }
    },
    addDropFile(e) {
      const files = [];
      if (Array.isArray(e)) {
        files.push(...e);
      } else {
        files.push(...Array.from(e.dataTransfer.files));
      }

      const fileDict = {};
      ALL_FILE_EXTENSIONS.replaceAll(",", "")
        .replaceAll(".", "")
        .split(" ")
        .filter(Boolean)
        .map(v => v.trim())
        .forEach(fe => (fileDict[fe] = true));
      const filteredFiles = files.filter(v => {
        const chunks = v.name.split(".");
        return fileDict[chunks[chunks.length - 1].toLowerCase()];
      });

      this.files = [...this.files, ...filteredFiles];
    },
    reset() {
      this.sendEmail = true;
      this.sendPhoneCall = false;
      this.files = [];
      this.message = "";
      this.options = this.createOptions();
      this.$v.$reset();
    }
  },
  validations: {
    message: {
      required: (value, parent) => {
        if (parent.files?.length) return true;
        return Boolean(value);
      }
    },
    files: {}
  }
};
</script>
