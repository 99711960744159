export function CommissionSchedules(model = {}) {
  return {
    annuity: model?.annuity,
    disability: model?.disability,
    life: model?.life,
    linkedBenefit: model?.linkedBenefit,
    ltc: model?.ltc,
    variableLife: model?.variableLife
  };
}

export function CommissionOptions(model = {}) {
  return {
    annuity: model?.annuity || [],
    disability: model?.disability || [],
    life: model?.life || [],
    linkedBenefit: model?.linkedBenefit || [],
    ltc: model?.ltc || [],
    variableLife: model?.variableLife || []
  };
}

export function setCommissionSchedulesFromRaw(raw = {}) {
  const commissions = CommissionSchedules();

  commissions.annuity = raw?.annuity?.schedule;
  commissions.disability = raw?.disability?.schedule;
  commissions.life = raw?.life?.schedule;
  commissions.linkedBenefit = raw?.linked_benefit?.schedule;
  commissions.ltc = raw?.ltc?.schedule;
  commissions.variableLife = raw?.variable_life?.schedule;

  return commissions;
}

export function setRawFromCommissionsSchedules(commissionSchedules = {}) {
  return {
    annuity: commissionSchedules.annuity,
    disability: commissionSchedules.disability,
    life: commissionSchedules.life,
    linked_benefit: commissionSchedules.linkedBenefit,
    ltc: commissionSchedules.ltc,
    variable_life: commissionSchedules.variableLife
  };
}

export function setCommissionOptionsFromRaw(raw = {}) {
  const options = CommissionOptions();

  options.annuity = raw?.annuity;
  options.disability = raw?.disability;
  options.life = raw?.life;
  options.linkedBenefit = raw?.linked_benefit;
  options.ltc = raw?.ltc;
  options.variableLife = raw?.variable_life;

  return options;
}
