<template>
  <v-fade-transition mode="out-in">
    <span v-if="formCategories.loading" class="grey--text" key="loading">
      Loading
    </span>
    <span v-else key="cleaned"> {{ cleanedValue }} </span>
  </v-fade-transition>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useSearchItemsCache } from "@/stores/search-items-cache";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  props: {
    value: String
  },
  created() {
    this.fetchItems();
  },
  computed: {
    ...mapState(useSearchItemsCache, ["formCategories"]),
    cleanedValue() {
      return this.formCategories.items.find(({ value }) => value === this.value)
        ?.text;
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useSearchItemsCache, ["getFormCategoriesList"]),
    async fetchItems() {
      try {
        await this.getFormCategoriesList();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      }
    }
  }
};
</script>
