<template>
  <v-card>
    <v-card-title>Add Product Build Chart</v-card-title>
    <v-card-text>
      <v-select
        prepend-inner-icon="$mdi-cube"
        v-model="productBuildChart.buildChart"
        :items="buildCharts"
        outlined
        :success="!$v.productBuildChart.buildChart.id.$invalid"
        :error-messages="
          $v.productBuildChart.buildChart.id.$dirty &&
          !$v.productBuildChart.buildChart.id.required
            ? ['Required']
            : []
        "
        dense
        label="Product Build Chart"
        :loading="fetchingBuildCharts"
        item-text="name"
        return-object
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        color="grey"
        @click="closeDialog"
        :disabled="creatingBuildChart"
      >
        Close
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        @click="save"
        :loading="creatingBuildChart"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ProductBuildChart } from "@/factories/ProductFactory";

import { required } from "vuelidate/lib/validators";

import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";

import { addProductBuildChart } from "@/api/product-build-charts.service";
import { getAllCarrierBuildCharts } from "@/api/build-charts.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

export default {
  validations: {
    productBuildChart: {
      buildChart: { id: { required } }
    }
  },
  props: {
    product: Object
  },
  data() {
    return {
      productBuildChart: new ProductBuildChart(),
      creatingBuildChart: false,
      fetchingBuildCharts: false,
      buildCharts: []
    };
  },
  mounted() {
    this.getCharts();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    async getCharts() {
      this.fetchingBuildCharts = true;
      try {
        this.buildCharts = await getAllCarrierBuildCharts(
          this.product.carrier.id
        );
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.fetchingBuildCharts = false;
      }
    },
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.creatingBuildChart = true;

      addProductBuildChart(
        this.productBuildChart.toCreateRequest(this.product.id)
      )
        .then(id => {
          this.productBuildChart.id = id;
          this.closeDialog({ productBuildChart: this.productBuildChart });
        })
        .catch(e => {
          this.showErrorSnackbar({
            message: `Unable to create build chart. ${parseErrorMessage(e)}`,
            timeout: -1
          });
        })
        .finally(() => {
          this.creatingBuildChart = false;
        });
    }
  }
};
</script>
