<template>
  <v-card flat tile>
    <v-row class="ma-0" dense align="center">
      <v-col cols="12" sm="6" order="2" order-sm="1" data-testid="insured">
        <v-row justify="center" justify-sm="start" class="ma-0 pa-3">
          <div>
            <strong style="font-size: 1.2rem"> {{ displayName }} </strong>
            <br />
            {{ type }}
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" order="1" order-sm="2">
        <v-row justify="center" justify-sm="end" class="ma-0 pa-3">
          <div style="max-height: 8rem">
            <v-img :src="avatar" class="rounded" contain />
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-scroll-y-transition mode="out-in">
      <v-card-text v-if="cardDetails.length" class="pa-0">
        <v-divider />
        <v-row class="ma-0 pa-3 py-1" dense>
          <v-col
            v-for="detail in cardDetails"
            :key="detail.key"
            cols="12"
            md="6"
            lg="3"
            xl="3"
          >
            <component
              :is="detail.component"
              v-bind="detail.props"
              :data-testid="detail.props.title"
              lg="12"
              xl="12"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-scroll-y-transition>
    <v-divider />
    <v-divider />
    <slot />
  </v-card>
</template>

<script setup>
import {
  linkItem,
  sensitiveInfoItem,
  textItem
} from "@/components/shared/card-items/card-items";
import { formatAddress } from "@/factories/AddressFactory";
import { timestampFormatter } from "@/util/helpers";
import { useContractPartyView } from "@/stores/contract-party-view";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const contractPartyView = useContractPartyView();
const {
  displayName,
  avatar,
  isEntity,
  isIndividual,
  maritalStatus,
  id,
  email,
  ssn,
  tin,
  birthdate,
  formationDate,
  phoneMobile,
  phoneWork,
  type,
  businessAddress
} = storeToRefs(contractPartyView);

const cardDetails = computed(() => {
  const details = [];

  if (isIndividual.value) {
    if (phoneMobile.value) {
      details.push(
        linkItem({
          title: "Cell Phone",
          href: `tel:${phoneMobile.value}`,
          text: phoneMobile.value,
          dataOutboundId: id.value,
          dataOutboundNumber: phoneMobile.value,
          dataOutboundType: "ContractParty"
        })
      );
    }

    if (phoneWork.value) {
      details.push(
        linkItem({
          title: "Office Phone",
          href: `tel:${phoneWork.value}`,
          text: phoneWork.value,
          dataOutboundId: id.value,
          dataOutboundNumber: phoneWork.value,
          dataOutboundType: "ContractParty"
        })
      );
    }

    if (email.value) {
      details.push(
        linkItem({
          title: "Email",
          href: `mailto:${email.value}`,
          text: email.value
        })
      );
    }

    if (maritalStatus.value) {
      details.push(
        textItem({ title: "Marital Status", text: maritalStatus.value })
      );
    }

    if (ssn.value) {
      details.push(
        sensitiveInfoItem({
          title: "SSN",
          type: "ssn",
          fetchFunc: contractPartyView.fetchSensitiveInfo
        })
      );
    }

    if (birthdate.value) {
      details.push(
        textItem({
          title: "Birthdate",
          text: timestampFormatter(birthdate.value, "sole-day")
        })
      );
    }
  }

  if (isEntity.value) {
    if (formationDate.value) {
      details.push(
        textItem({
          title: "Formation Date",
          text: timestampFormatter(formationDate.value, "sole-day")
        })
      );
    }

    if (phoneWork.value) {
      details.push(
        linkItem({
          title: "Office Phone",
          href: `tel:${phoneWork.value}`,
          text: phoneWork.value,
          dataOutboundId: id.value,
          dataOutboundNumber: phoneWork.value,
          dataOutboundType: "ContractParty"
        })
      );
    }

    if (email.value) {
      details.push(
        linkItem({
          title: "Email",
          href: `mailto:${email.value}`,
          text: email.value
        })
      );
    }

    if (tin.value) {
      details.push(
        sensitiveInfoItem({
          title: "TIN",
          type: "tin",
          fetchFunc: contractPartyView.fetchSensitiveInfo
        })
      );
    }

    if (businessAddress.value?.id) {
      const formattedAddress = formatAddress(businessAddress.value);
      if (formattedAddress) {
        details.push(
          textItem({
            title: "Business Address",
            text: formatAddress(businessAddress.value)
          })
        );
      }
    }
  }
  return details;
});
</script>
