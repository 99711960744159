<template>
  <v-autocomplete
    autocomplete="false"
    prepend-inner-icon="$mdi-domain"
    outlined
    dense
    v-model="audience"
    item-text="displayText"
    return-object
    no-filter
    hide-no-data
    :placeholder="placeholder"
    :label="label"
    :hide-details="hideDetails"
    :search-input.sync="search"
    :success="success"
    :error-messages="errorMessages"
    :items="audiences"
    :loading="loading"
    :disabled="disabled"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
  />
</template>

<script>
import { audienceSearch } from "@/api/search.service";

export default {
  props: {
    success: Boolean,
    errorMessages: Array,
    carrierId: Number,
    value: Object,
    hideDetails: Boolean,
    disabled: Boolean,
    placeholder: {
      type: String,
      default: "Search Audiences",
      required: false
    },
    label: {
      type: String,
      default: "Audience",
      required: false
    }
  },
  data() {
    let audiences = [];
    let audience = null;
    if (this.value && Object.keys(this.value).length) {
      audiences = [this.value];
      audience = this.value;
    }
    return {
      search: "",
      audienceTimer: null,
      loading: false,
      audiences,
      audience
    };
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.audience = null;
        this.audiences = [];
        return;
      }
      this.audience = value;
      this.audiences = [value];
    },
    audience(value) {
      this.$emit("input", value);
    },
    search(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (
        this.audiences.some(val => val?.name === value) &&
        this.audiences.length === 1
      ) {
        return;
      }

      if (this.audienceTimer) {
        clearTimeout(this.audienceTimer);
      }

      this.audienceTimer = setTimeout(() => {
        this.loading = true;
        audienceSearch(this.carrierId, value)
          .then(response => {
            const items = [...response].map(a => ({
              ...a,
              displayText: [a.name, a.email].filter(Boolean).join(" · ")
            }));
            items.sort((a, b) => a.name.localeCompare(b.name));
            this.audiences = items;
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  }
};
</script>
