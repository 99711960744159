<template>
  <v-text-field
    v-model="model"
    v-maska:[options]
    v-bind="$attrs"
    inputmode="numeric"
  >
    <template v-if="$slots.message" #message="{ message }">
      <v-row v-if="message.trim()" class="ma-0">
        {{ message }}
        <v-spacer />
        <slot name="message" />
      </v-row>
      <slot v-else name="message" />
    </template>
    <template v-if="$slots.append" #append>
      <slot name="append" />
    </template>
    <template v-if="$slots['append-outer']" #append-outer>
      <slot name="append-outer" />
    </template>
    <template v-if="$slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
  </v-text-field>
</template>

<script setup>
import { vMaska } from "maska";
import { watch, ref, toRef } from "vue";

// included by compiler
// eslint-disable-next-line
const props = defineProps({
  value: {
    type: [String, Number],
    required: false,
    default: null
  },
  includeNegative: Boolean,
  includeDecimals: Boolean
});

const options = {
  preProcess: unmaskVal,
  postProcess: maskVal
};

function maskVal(v) {
  if (!v && v !== 0) return "";
  if (v === "-" && props.includeNegative) return "-";
  if (v === "." && props.includeDecimals) return ".";
  if (v === "-." && props.includeDecimals && props.includeNegative) return "-.";

  let val = v.toString();

  let sub = 3 - (val.includes(".") ? val.length - val.indexOf(".") : 0);

  const formatterOptions = {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    signDisplay: props.includeNegative ? "auto" : "never"
  };

  if (!props.includeDecimals) {
    formatterOptions.maximumFractionDigits = 0;
    formatterOptions.minimumFractionDigits = 0;
    sub = 0;
  }
  const formattedValue = Intl.NumberFormat("en-US", formatterOptions).format(
    val
  );

  return formattedValue.slice(0, sub ? -sub : undefined).replace("$", "");
}

function unmaskVal(val) {
  return val.replace(/[$,]/g, "");
}

const propValue = toRef(props, "value");
let v = null;
if (props.value || props.value === 0) v = propValue.value;
if (props.includeDecimals && v?.toString && v.toString().indexOf(".") > -1) {
  v = v.toFixed(2);
}
const model = ref(v);

watch(
  () => props.value,
  () => {
    let unmasked = null;
    if (model.value || model.value === 0) unmasked = +unmaskVal(model.value);
    if (props.value === unmasked) return;
    model.value = maskVal(props.value);
  }
);

// included by compiler
// eslint-disable-next-line
const emit = defineEmits(["input"]);
const update = v => emit("input", v);
watch(model, () => {
  let unmasked = null;
  if (model.value || model.value === 0) unmasked = +unmaskVal(model.value);
  if (props.value === unmasked) return;
  update(unmasked);
});
</script>
