<template>
  <v-row style="position: relative; padding-right: 32px" dense>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="text"
        label="Text"
        outlined
        dense
        data-testid="option-text"
        :autofocus="props.autofocus && !text"
        :success="textValidation.success"
        :error-messages="textValidation.errorMessages"
        @input="emit('update:text', text)"
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="value"
        label="Value"
        outlined
        dense
        data-testid="option-value"
        :success="valueValidation.success"
        :error-messages="valueValidation.errorMessages"
        @input="emit('update:value', value)"
      />
    </v-col>
    <div
      data-testid="option-delete"
      style="position: absolute; right: -4px; top: 6px"
    >
      <v-btn icon @click="emit('delete')" color="error">
        <v-icon>$mdi-delete</v-icon>
      </v-btn>
    </div>
  </v-row>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { someTextValidator, validationComputeV2 } from "@/util/helpers";
const props = defineProps({
  text: { type: String, required: true },
  value: { type: String, required: true },
  autofocus: Boolean,
  duplicateValues: {
    type: Object,
    required: true
  }
});

const text = ref(props.text);
const value = ref(props.value);
const emit = defineEmits(["delete", "update:text", "update:value"]);
const v$ = useVuelidate(
  {
    text: {
      required: v => someTextValidator(true, v, 1),
      mustBeUnique: () => !props.duplicateValues.text
    },
    value: {
      required: v => someTextValidator(true, v, 1),
      mustBeUnique: () => !props.duplicateValues.value
    }
  },
  {
    text,
    value
  },
  { $autoDirty: true, $scope: "question-editor" }
);

const textValidation = computed(() => {
  const model = v$.value.text;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    { key: "mustBeUnique", message: "Must be unique" }
  ]);
});

const valueValidation = computed(() => {
  const model = v$.value.value;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    { key: "mustBeUnique", message: "Must be unique" }
  ]);
});
</script>
