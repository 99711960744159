<template>
  <div>
    <h2 class="text-h6 mb-2">
      {{ props.title }}
    </h2>
    <p v-if="props.subtitle" class="text-body-2">
      {{ props.subtitle }}
    </p>
    <v-chip-group
      v-if="mappedItems.length"
      v-model="model"
      active-class="primary--text"
      :class="{
        'error-sheet-outlined': validation.errorMessages.length > 0
      }"
      column
      multiple
    >
      <v-chip
        v-for="item in mappedItems"
        :disabled="props.disabled"
        :key="item.value"
        :value="item.value"
        :data-testid="item.text"
        @click="saveItem(item.value)"
      >
        {{ item.text }}
        <active-save-indicator
          v-if="props.save"
          class="saving-chip"
          :controller="savingBuffer[item.value].controller.value"
        />
      </v-chip>
    </v-chip-group>
    <div v-else>
      <v-alert outlined type="info">
        {{ props.noDataText }}
      </v-alert>
    </div>
  </div>
</template>
<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { useActiveSave } from "@/composables/active-save.composable";

import { defineProps, defineEmits, watch, ref, toRefs, computed } from "vue";
const props = defineProps({
  disabled: Boolean,
  title: {
    type: String,
    required: true
  },
  subtitle: {
    type: String,
    required: false,
    default: ""
  },

  items: {
    type: Array,
    required: false,
    default: () => []
  },
  model: {
    type: Array,
    required: false,
    default: () => []
  },
  validation: {
    type: Object,
    required: true
  },
  noDataText: {
    type: String,
    required: false,
    default: ""
  },
  save: {
    type: Function,
    required: false,
    default: null
  }
});

const { model: modelWatcher } = toRefs(props);

const emit = defineEmits(["update:model"]);

const model = ref(props.model);

const { items } = toRefs(props);

const mappedItems = computed(() => {
  return items.value.map(item => {
    if (typeof item === "object") return item;
    return { value: item, text: item };
  });
});

const savingBuffer = {};

mappedItems.value.forEach(v => {
  savingBuffer[v.value] = useActiveSave();
});

function saveItem(value) {
  if (props.save && props.validation.success)
    savingBuffer[value].update(props.save);
}

watch(
  modelWatcher,
  v => {
    if (JSON.stringify(model.value) === JSON.stringify(v)) return;
    model.value.splice(0, model.value.length);
    model.value.push(...v);
  },
  { deep: true }
);
watch(
  model,
  v => {
    if (JSON.stringify(modelWatcher.value) === JSON.stringify(v)) return;
    emit("update:model", v);
  },
  { deep: true }
);
</script>

<style lang="scss" scoped>
.saving-chip {
  position: absolute;
  backdrop-filter: blur(3px);
  width: 100%;
  height: 100%;
  margin-left: -12px;
  padding-top: 3px;
  text-align: center;
}
</style>
