<template>
  <v-card>
    <v-card-title>Add Build</v-card-title>
    <v-row dense class="px-5 ma-0">
      <v-col cols="12" md="6">
        <v-autocomplete
          :items="buildCharts"
          label="Build Chart"
          v-model="newBuild.buildChart"
          outlined
          dense
          :error-messages="buildChartValidation.errorMessages"
          :success="buildChartValidation.success"
          item-text="name"
          item-value="id"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          label="Rating"
          v-model="newBuild.rating"
          :items="ratings"
          outlined
          dense
          :error-messages="ratingValidation.errorMessages"
          :success="ratingValidation.success"
        />
      </v-col>
    </v-row>
    <v-card-text class="pt-1" id="build-box">
      <v-row
        v-for="(build, index) in $v.newBuild.builds.$each.$iter"
        :key="build.$model.id || index"
        dense
      >
        <v-col cols="12" :md="newBuild.builds.length > 1 ? 3 : 4">
          <v-text-field
            data-lpignore="true"
            type="text"
            inputmode="numeric"
            v-model="build.$model.inches"
            label="Inches"
            outlined
            dense
            :error-messages="
              build.inches.$dirty
                ? [
                    !build.inches.inRange ? 'Must be between 0 and 100' : ''
                  ].filter(Boolean)
                : []
            "
            :success="!build.inches.$invalid"
          />
        </v-col>
        <v-col cols="12" :md="newBuild.builds.length > 1 ? 3 : 4">
          <v-text-field
            data-lpignore="true"
            label="Min Weight"
            v-model="build.$model.minWeight"
            outlined
            dense
            type="text"
            inputmode="numeric"
            :error-messages="
              build.minWeight.$dirty
                ? [
                    !build.minWeight.inRange
                      ? 'Must be greater than 0 and less than or equal to 999'
                      : '',
                    !build.minWeight.lessThanMax
                      ? 'Must be less than specified Max Weight'
                      : ''
                  ].filter(Boolean)
                : []
            "
            :success="!build.minWeight.$invalid"
          />
        </v-col>
        <v-col cols="12" :md="4">
          <v-text-field
            data-lpignore="true"
            label="Max Weight"
            v-model="build.$model.maxWeight"
            outlined
            dense
            type="text"
            inputmode="numeric"
            :error-messages="
              build.maxWeight.$dirty
                ? [
                    !build.maxWeight.inRange
                      ? 'Must be greater than 0 and less than or equal to 999'
                      : '',
                    !build.maxWeight.greaterThanMin
                      ? 'Must be greater than specified Min Weight'
                      : ''
                  ].filter(Boolean)
                : []
            "
            :success="!build.maxWeight.$invalid"
          />
        </v-col>
        <v-col cols="12" md="2" v-if="newBuild.builds.length > 1">
          <v-row class="ma-0">
            <v-btn icon @click="saveBuild(index)">
              <v-icon v-if="build.$model.id">$mdi-content-save</v-icon>
              <v-icon v-else>$mdi-checkbox-blank-outline</v-icon>
            </v-btn>
            <v-btn v-if="build.$model.id" icon @click="destroyBuild(index)">
              <v-icon v-if="build.$model.id">$mdi-delete</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <div id="build-box-bottom" />
    </v-card-text>
    <v-card-actions align="center" class="px-6">
      <v-row class="ma-0" dense>
        <v-col cols="2">
          <v-text-field
            data-lpignore="true"
            label="Min"
            outlined
            dense
            hide-details
            v-model="min"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            data-lpignore="true"
            label="Max"
            outlined
            dense
            hide-details
            v-model="max"
          />
        </v-col>
        <v-col cols="2">
          <v-btn block class="text-none" color="accent" @click="generateRows">
            Generate
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn
            block
            @click="addAnotherBuild"
            v-if="!newBuild.id"
            class="text-none"
          >
            +1 Build
          </v-btn>
        </v-col>
        <v-spacer v-if="newBuild.builds.length > 1" />
        <v-col cols="2">
          <v-btn block class="text-none" @click="closeDialog" outlined>
            Cancel
          </v-btn>
        </v-col>
        <v-col cols="2" v-if="newBuild.builds.length === 1">
          <v-btn
            class="text-none"
            @click="saveBuild()"
            :loading="savingBuild"
            color="primary"
            block
          >
            <template v-if="newBuild.id"> Update </template>
            <template v-else> Create </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { RATINGS_BY_LINE } from "@/data/case-data";
import { getAllBuildCharts } from "@/api/build-charts.service";
import { createBuild, updateBuild } from "@/api/builds.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

export default {
  name: "AddBuildChartDialog",
  props: {
    build: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      min: null,
      max: null,
      buildCharts: [],
      savingBuild: false,
      ratings: [],
      addMany: false,
      newBuild: {
        buildChart: this.build?.build_chart || null,
        rating: this.build?.rating || null,
        builds: [
          {
            id: this.build?.id || null,
            inches: this.build?.inches || null,
            minWeight: this.build?.min_weight || null,
            maxWeight: this.build?.max_weight || null
          }
        ],
        id: this.build?.id || 0
      }
    };
  },
  created() {
    const ratings = [...RATINGS_BY_LINE.life, ...RATINGS_BY_LINE.ltc];
    this.ratings = [];
    ratings.forEach(rating => {
      if (this.ratings.includes(rating)) return;
      this.ratings.push(rating);
    });
    this.fetchBuildCharts();
    document.addEventListener("wheel", () => {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  },
  computed: {
    buildChartValidation() {
      const success = !this.$v.newBuild.buildChart.$invalid;
      const errorMessages = [];
      if (!this.$v.newBuild.buildChart.$dirty)
        return { success, errorMessages };
      if (!this.$v.newBuild.buildChart.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    ratingValidation() {
      const success = !this.$v.newBuild.rating.$invalid;
      const errorMessages = [];
      if (!this.$v.newBuild.rating.$dirty) return { success, errorMessages };
      if (!this.$v.newBuild.rating.required) errorMessages.push("Required");
      return { success, errorMessages };
    }
  },
  methods: {
    ...mapActions(useDialogStore, ["closeDialog"]),
    ...mapActions(useSnackbarStore, [
      "showErrorSnackbar",
      "showSuccessSnackbar"
    ]),
    generateRows() {
      if (!this.min || !this.max) return;

      if (this.newBuild.builds.length === 1) {
        const firstBuild = this.newBuild.builds[0];
        if (
          !firstBuild.inches &&
          !firstBuild.minWeight &&
          !firstBuild.maxWeight
        ) {
          this.newBuild.builds.splice(0, 1);
        }
      }

      for (let i = this.min; i <= this.max; i++) {
        this.addAnotherBuild(i, false);
      }

      this.min = null;
      this.max = null;
    },
    addAnotherBuild(inches = null, scrollTo = true) {
      this.newBuild.builds.push({
        inches,
        minWeight: null,
        maxWeight: null,
        id: null
      });

      if (!scrollTo) return;
      this.goToBottom();
    },
    goToBottom() {
      this.$nextTick(() => {
        const bottomOfBox = document.getElementById("build-box-bottom");
        this.$vuetify.goTo(bottomOfBox, {
          container: "#build-box",
          offset: 100
        });
      });
    },
    fetchBuildCharts() {
      getAllBuildCharts()
        .then(res => {
          this.buildCharts = res;
        })
        .catch(e => {
          this.showErrorSnackbar({
            message: parseErrorMessage(e),
            timeout: -1
          });
        });
    },
    destroyBuild(index) {
      this.deleteBuild(this.newBuild.builds[index].id)
        .then(() => {
          this.showSuccessSnackbar({
            message: "Successfully Deleted Build"
          });
          this.newBuild.builds[index].splice(index, 1);
        })
        .catch(e => {
          this.showErrorSnackbar({
            message: parseErrorMessage(e),
            timeout: -1
          });
        });
    },
    saveBuild(index = 0) {
      if (index === 0 && this.newBuild.builds.length === 1) {
        this.$v.$touch();
        if (this.$v.$invalid) return;
      } else {
        this.$v.newBuild.buildChart.$touch();
        this.$v.newBuild.rating.$touch();
        const anyInvalid =
          this.$v.newBuild.buildChart.$invalid ||
          this.$v.newBuild.rating.$invalid;
        this.$v.newBuild.builds.$each[index].$touch();
        if (anyInvalid || this.$v.newBuild.builds.$each[index].$invalid) return;
      }
      this.savingBuild = true;

      let func = () =>
        createBuild({
          build_chart_id: this.newBuild.buildChart,
          rating: this.newBuild.rating,
          inches: this.newBuild.builds[index].inches,
          min_weight: this.newBuild.builds[index].minWeight,
          max_weight: this.newBuild.builds[index].maxWeight
        });
      if (this.newBuild.builds[index].id) {
        func = () =>
          updateBuild(this.newBuild.builds[index].id, {
            build_chart_id: this.newBuild.buildChart,
            rating: this.newBuild.rating,
            inches: this.newBuild.builds[index].inches,
            min_weight: this.newBuild.builds[index].minWeight,
            max_weight: this.newBuild.builds[index].maxWeight
          });
      }
      func()
        .then(res => {
          const action = this.newBuild.builds[index].id ? "Updated" : "Created";
          this.showSuccessSnackbar({
            message: `Successfully ${action} Build`
          });
          this.newBuild.builds[index].id = res?.data?.build?.id;
          if (this.newBuild.builds.length > 1) return;
          this.closeDialog({ created: true });
        })
        .catch(e => {
          this.showErrorSnackbar({
            message: parseErrorMessage(e),
            timeout: -1
          });
        })
        .finally(() => {
          this.savingBuild = false;
        });
    }
  },
  validations: {
    newBuild: {
      buildChart: { required: Boolean },

      rating: { required: Boolean },
      builds: {
        $each: {
          inches: { inRange: val => val > 0 && val <= 100 },
          minWeight: {
            inRange: val => +val >= 0 && +val <= 999,
            lessThanMax: (val, parent) => +val < +parent.maxWeight
          },
          maxWeight: {
            inRange: val => +val > 0 && +val <= 999,
            greaterThanMin: (val, parent) => +val > +parent.minWeight
          }
        }
      }
    }
  }
};
</script>

<style lang="scss">
.many-builds {
  overflow-y: scroll;
  -moz-box-shadow: inset 0 0 3px #000000;
  -webkit-box-shadow: inset 0 0 3px #000000;
  box-shadow: inset 0 0 3px #000000;
  border-radius: 4px;
}
</style>
