<template>
  <v-card>
    <v-fade-transition mode="out-in">
      <v-progress-circular indeterminate v-if="loading" key="loader" />
      <div v-else-if="user.id" key="content">
        <v-card-title> {{ user.email }} </v-card-title>
        <v-divider />
        <v-alert
          v-if="user.isLocked"
          data-testid="is-locked-banner"
          type="error"
          key="locked"
          class="ma-3"
        >
          This user has been locked.
        </v-alert>
        <template v-for="(detailGroup, index) in detailGroups">
          <v-row class="ma-0 px-3" :key="index" dense>
            <v-col
              v-for="({ component, props, key, on }, index) in detailGroup"
              :key="index"
              cols="12"
              xl="3"
              lg="3"
              md="4"
              sm="6"
              xs="6"
            >
              <component
                :is="component"
                v-bind="props"
                v-on="on"
                :data-testid="`card-${key}`"
              />
            </v-col>
          </v-row>
          <v-divider :key="index + 'divider'" />
        </template>

        <v-row class="ma-0 pa-3" style="gap: 2px">
          <v-spacer />
          <v-btn
            data-testid="edit"
            color="accent"
            rounded
            outlined
            class="text-none"
            @click="editUser"
          >
            <v-icon class="mr-1"> {{ mdiPencil }} </v-icon> Edit
          </v-btn>
          <v-btn
            data-testid="send-password-reset-email"
            outlined
            rounded
            class="text-none"
            color="warning"
            @click="sendResetPassword"
          >
            <v-icon class="mr-1"> {{ mdiLockReset }} </v-icon>
            Send Password Reset Email
          </v-btn>
          <v-btn
            v-if="user.otpEnrolled"
            data-testid="reset-mfa"
            outlined
            rounded
            class="text-none"
            color="error"
            @click="resetOtp"
          >
            <v-icon class="mr-1"> {{ mdiShieldOffOutline }} </v-icon>
            Reset MFA
          </v-btn>
          <v-btn
            v-if="user.isLocked"
            outlined
            data-testid="unlock-user"
            rounded
            class="text-none"
            color="error"
            @click="userUnLock"
          >
            <v-icon class="mr-1"> {{ mdiAccountLockOpen }} </v-icon>
            Unlock User
          </v-btn>
          <v-btn
            v-else
            data-testid="lock-user"
            outlined
            rounded
            class="text-none"
            color="error"
            @click="userLock"
          >
            <v-icon class="mr-1"> {{ mdiAccountLock }} </v-icon>
            Lock User
          </v-btn>
        </v-row>
        <v-divider />

        <v-tabs v-model="activeTab">
          <v-tab
            v-for="(tab, index) in tabs"
            :key="index"
            class="text-none"
            :data-testid="`tab-${tab.title}`"
          >
            {{ tab.title }}
          </v-tab>
        </v-tabs>
        <v-divider />
        <v-tabs-items v-model="activeTab">
          <v-tab-item
            v-for="(tab, index) in tabs"
            :key="index"
            eager
            class="section"
          >
            <component :is="tab.component" v-bind="tab.props" class="section" />
          </v-tab-item>
        </v-tabs-items>
      </div>
      <v-alert v-else key="cannot-load" color="error">
        Unable to load data for user {{ props.id }}
      </v-alert>
    </v-fade-transition>
  </v-card>
</template>

<script setup>
import ApplicationVersionsTable from "@/components/application-versions/ApplicationVersionsTable.vue";
import UserSettingsDialog from "@/components/users/UserSettingsDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import {
  PERMISSION,
  PERMISSION_FILTER_ITEMS,
  PERMISSION_TEXT
} from "@/constants/permissions.constants";
import { User } from "@/factories/User";
import {
  getRouterLink,
  listToSentence,
  parseErrorMessage,
  timestampFormatter
} from "@/util/helpers";
import { computed, ref, defineProps, defineEmits, markRaw } from "vue";
import {
  routerLinkItem,
  textItem
} from "@/components/shared/card-items/card-items";
import { useSnackbarStore } from "@/stores/snackbar";
import {
  getUserDetails,
  lockUser,
  resetUserOTP,
  sendPasswordReset,
  unlockUser
} from "@/api/users.service";
import { useDialogStore } from "@/stores/dialog";
import {
  mdiLockReset,
  mdiShieldOffOutline,
  mdiPencil,
  mdiAccountLock,
  mdiAccountLockOpen
} from "@mdi/js";
const emit = defineEmits(["refresh"]);
const props = defineProps({
  id: {
    type: Number,
    required: true
  }
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();

const user = ref(User());
const loading = ref(false);
const activeTab = ref(0);

const tabs = [
  {
    title: "Changes To User",
    component: markRaw(ApplicationVersionsTable),
    props: {
      id: props.id,
      title: "Changes To User",
      changesToId: props.id,
      changesToType: "User"
    }
  },
  {
    title: "Changes By User",
    component: markRaw(ApplicationVersionsTable),
    props: {
      id: props.id,
      title: "Changes By User",
      changesById: props.id,
      changesByType: "User"
    }
  }
];

const general = computed(() => {
  const permissionsData = [];
  Object.values(PERMISSION).forEach(p => {
    if (user.value.permissions[p]) {
      permissionsData.push(PERMISSION_TEXT[p]);
    }
  });
  let permissionText = "None";
  if (permissionsData.length === PERMISSION_FILTER_ITEMS.length) {
    permissionText = "All";
  } else if (permissionsData.length) {
    permissionText = listToSentence(permissionsData);
  }

  return [
    textItem({
      title: "Email",
      text: user.value.email
    }),
    textItem({
      title: "Last Sign in At",
      text: user.value.lastSignInAt
        ? timestampFormatter(user.value.lastSignInAt, null, "date-time")
        : ""
    }),
    textItem({
      title: "Created At",
      text: timestampFormatter(user.value.createdAt, null, "date-time")
    }),
    textItem({
      title: "Permissions",
      text: permissionText
    }),
    textItem({
      title: "Group",
      text: user.value.group?.toString()
    }),
    textItem({
      title: "Highest Group",
      text: user.value.highestGroup?.toString()
    })
  ];
});

const signIn = computed(() => {
  return [
    textItem({
      title: "MFA Enrolled",
      text: boolToText(user.value.otpEnrolled)
    }),
    textItem({
      title: "MFA Required",
      text: boolToText(user.value.otpRequiredForLogin)
    }),
    textItem({
      title: "MFA Required By",
      text: user.value.otpRequiredOn
        ? timestampFormatter(user.value.otpRequiredOn, null, "date-time")
        : ""
    }),
    textItem({
      title: "Sign in Method",
      text: ssoFormatter(user.value.sso)
    }),
    ...user.value.identities.map(i =>
      textItem({
        title: "OAuth Identity",
        text: i
      })
    )
  ];
});

const logins = computed(() => {
  return user.value.logins.map(l => {
    let text = l.name;
    if (
      l.id === user.value.loggedInAs?.id &&
      l.type === user.value.loggedInAs?.type
    ) {
      text += " (Current)";
    }

    return routerLinkItem({
      title: "Login",
      text,
      to: getRouterLink(l.type, l.id)
    });
  });
});

const detailGroups = computed(() => {
  return [general.value, signIn.value, logins.value].filter(i => i.length);
});

function ssoFormatter(sso) {
  if (!sso) return "Email & Password";

  if (sso.oauthProvider) {
    return `${sso.authMethod}, via ${sso.oauthProvider}`;
  }

  return `${sso.authMethod}, via ${sso.emailDomain}`;
}

function boolToText(bool) {
  return bool ? "Yes" : "No";
}

async function fetchUserData() {
  try {
    loading.value = true;
    user.value = await getUserDetails(props.id);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  } finally {
    loading.value = false;
  }
}

function resetOtp() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    showErrorMessage: true,
    title: `Reset ${user.value.email}'s MFA?`,
    subtitle: "This will require them to re-enroll",
    func: async () => {
      await resetUserOTP(user.value.id);
      refresh();
    }
  });
}

function userLock() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    showErrorMessage: true,
    title: `Lock ${user.value.email}'s Account?`,
    subtitle: "This will sign them out and prevent signing in",
    func: async () => {
      await lockUser(user.value.id);
      refresh();
    }
  });
}

function userUnLock() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    showErrorMessage: true,
    title: `Unock ${user.value.email}'s Account?`,
    subtitle: "This will permit signing in",
    func: async () => {
      await unlockUser(user.value.id);
      refresh();
    }
  });
}

function sendResetPassword() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Reset User Password",
    subtitle: "This will send a reset password email to the user.",
    func: () => sendPasswordReset(user.value.id)
  });
}

async function editUser() {
  const result = await dialog.showDialog({
    component: markRaw(UserSettingsDialog),
    value: user.value
  });

  if (result?.refresh) refresh();
}

function refresh() {
  emit("refresh");
  fetchUserData();
}

fetchUserData();
</script>
