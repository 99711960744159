<template>
  <div style="height: 0; width: 100%; text-align: right">
    <v-btn class="ma-2" icon @click="$emit('click')" style="z-index: 1000">
      <v-icon :color="color">$mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "CloseButton",
  props: {
    darkColor: String,
    lightColor: String
  },
  computed: {
    color() {
      if (this.$vuetify.theme.dark) return this.darkColor || "white";
      return this.lightColor || "black";
    }
  }
};
</script>
