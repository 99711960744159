<template>
  <v-card>
    <v-card-title>Myga Rate </v-card-title>
    <v-card-text>
      <integer-input
        v-model="rate.year"
        data-lpignore="true"
        prepend-inner-icon="$mdi-pound"
        :label="rangeCreate ? 'Start Year' : 'Year'"
        outlined
        dense
        mask="##"
        type="text"
        inputmode="numeric"
        :persistent-hint="!editing"
        :hint="editing ? null : ' '"
        :success="yearValidation.success"
        :error-messages="yearValidation.errorMessages"
      >
        <template #message="{ message }">
          <span v-if="yearValidation.errorMessages.length">
            {{ message }}
          </span>
          <template v-else-if="!editing">
            <v-fade-transition mode="out-in">
              <a v-if="rangeCreate" @click="rangeCreate = false" key="variable">
                Variable MYGA rate?
              </a>
              <a v-else @click="rangeCreate = true" key="constant">
                Constant MYGA rate?
              </a>
            </v-fade-transition>
          </template>
        </template>
      </integer-input>
      <v-fade-transition mode="out-in">
        <integer-input
          data-lpignore="true"
          v-if="rangeCreate"
          v-model="endYear"
          mask="##"
          prepend-inner-icon="$mdi-pound"
          label="End Year"
          outlined
          dense
          type="text"
          inputmode="numeric"
          :success="endYearValidation.success"
          :error-messages="endYearValidation.errorMessages"
          @input="updateSurrenderCharges"
        />
      </v-fade-transition>
      <decimal-input
        v-model="rate.rate"
        data-lpignore="true"
        prepend-inner-icon="$mdi-percent"
        label="Rate"
        outlined
        dense
        :max="100"
        :decimal-length="2"
        :success="rateValidation.success"
        :error-messages="rateValidation.errorMessages"
      />
      <template v-if="rangeCreate">
        <integer-input
          v-for="(charge, index) in surrenderCharges"
          v-model="surrenderCharges[index]"
          :max="100"
          outlined
          dense
          :key="`c_${index}`"
          :label="`Year ${index + 1} Surrender Charge`"
          :success="surrenderChargesValidation[index].success"
          :error-messages="surrenderChargesValidation[index].errorMessages"
        />
      </template>
      <integer-input
        v-else
        v-model="rate.surrenderCharge"
        label="Surrender Charge"
        outlined
        dense
        :max="100"
        :success="surrenderChargeValidation.success"
        :error-messages="surrenderChargeValidation.errorMessages"
      />
      <v-select
        prepend-inner-icon="$mdi-percent"
        label="Withdrawal Allowance"
        v-model="rate.withdrawalAllowance"
        outlined
        dense
        :items="MYGA_WITHDRAWAL_ALLOWANCES"
        :success="withdrawalAllowanceValidation.success"
        :error-messages="withdrawalAllowanceValidation.errorMessages"
      />
      <v-select
        prepend-inner-icon="$mdi-currency-usd"
        label="Rate Band"
        v-model="rate.rateBand"
        outlined
        dense
        item-text="display"
        return-object
        persistent-hint
        hint=" "
        :items="rateBands"
        :success="rateBandValidation.success"
        :error-messages="rateBandValidation.errorMessages"
      >
        <template #message="{ message }">
          {{ message }}
          <a @click="createRateBand"> Can't find your rate band? </a>
        </template>
      </v-select>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="text-none"
        outlined
        :disabled="loading"
        @click="closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        v-if="editing"
        class="text-none"
        color="primary"
        :loading="loading"
        @click="updateRate"
      >
        Save
      </v-btn>
      <v-btn
        v-else-if="rangeCreate"
        class="text-none"
        color="primary"
        :loading="loading"
        @click="createConstantRates"
      >
        Save
      </v-btn>
      <v-btn v-else class="text-none" color="primary" @click="createRate">
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import IntegerInput from "@/components/shared/IntegerInput.vue";
import DecimalInput from "@/components/shared/DecimalInput.vue";
import {
  MYGARate,
  MYGA_WITHDRAWAL_ALLOWANCES
} from "@/factories/ProductFactory";

import { createMYGARate, updateMYGARate } from "@/api/products.service";
import { getRateBands } from "@/api/rate-bands.service";
import { listToSentence, parseErrorMessage } from "@/util/helpers";
import { mapActions } from "pinia";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  props: {
    id: Number,
    $rate: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  components: { IntegerInput, DecimalInput },
  data() {
    const rate = new MYGARate(this.$rate);
    rate.productId = this.id;
    const editing = Boolean(rate.id);
    return {
      rate,
      editing,
      MYGA_WITHDRAWAL_ALLOWANCES,
      endYear: null,
      rangeCreate: !editing,
      loading: false,
      fetchingRateBands: false,
      rateBands: [],
      surrenderCharges: []
    };
  },
  created() {
    this.fetchRateBands();
  },
  computed: {
    yearValidation() {
      const errorMessages = [];
      const success = !this.$v.rate.year.$invalid;
      if (!this.$v.rate.year.$dirty) {
        return { success, errorMessages };
      } else if (!this.$v.rate.year.required) {
        errorMessages.push("Required");
      } else if (!this.$v.rate.year.atLeastOne) {
        errorMessages.push("Must be at least 1");
      } else if (!this.$v.rate.year.atMostTwenty) {
        errorMessages.push("Must be at most 20");
      }
      return { success, errorMessages };
    },
    endYearValidation() {
      const errorMessages = [];
      const success = !this.$v.endYear.$invalid;
      if (!this.$v.endYear.$dirty) {
        return { success, errorMessages };
      } else if (!this.$v.endYear.required) {
        errorMessages.push("Required");
      } else if (!this.$v.endYear.atLeastOne) {
        errorMessages.push("Must be at least 1");
      } else if (!this.$v.endYear.atMostTwenty) {
        errorMessages.push("Must be at most 20");
      } else if (!this.$v.endYear.greaterThanStart) {
        errorMessages.push(`Must be at least ${this.rate.year + 1}`);
      }
      return { success, errorMessages };
    },
    rateValidation() {
      const errorMessages = [];
      const success = !this.$v.rate.rate.$invalid;
      if (!this.$v.rate.rate.$dirty) return { success, errorMessages };
      else if (!this.$v.rate.rate.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    surrenderChargeValidation() {
      const errorMessages = [];
      const success = !this.$v.rate.surrenderCharge.$invalid;
      if (!this.$v.rate.surrenderCharge.$dirty) {
        return { success, errorMessages };
      } else if (!this.$v.rate.surrenderCharge.required) {
        errorMessages.push("Required");
      } else if (!this.$v.rate.surrenderCharge.greaterThanZero) {
        errorMessages.push("Must be at least zero");
      } else if (!this.$v.rate.surrenderCharge.lessThanOneHundred) {
        errorMessages.push("Cannot be more than 100");
      }
      return { success, errorMessages };
    },
    surrenderChargesValidation() {
      return this.surrenderCharges.map(v => {
        const errorMessages = [];
        if (v < 0) errorMessages.push("Must be at least zero");
        else if (v > 100) errorMessages.push("Cannot be more than 100");
        else if (!v && v !== 0) errorMessages.push("Required");
        return { errorMessages, success: !errorMessages.length };
      });
    },
    withdrawalAllowanceValidation() {
      const errorMessages = [];
      const success = !this.$v.rate.withdrawalAllowance.$invalid;
      if (!this.$v.rate.withdrawalAllowance.$dirty) {
        return { success, errorMessages };
      } else if (!this.$v.rate.withdrawalAllowance.required) {
        errorMessages.push("Required");
      } else if (!this.$v.rate.withdrawalAllowance.inList) {
        errorMessages.push(
          `Must be one of these options: ${listToSentence(
            MYGA_WITHDRAWAL_ALLOWANCES
          )}`
        );
      }
      return { success, errorMessages };
    },
    rateBandValidation() {
      const errorMessages = [];
      const success = !this.$v.rate.rateBand.$invalid;
      if (!this.$v.rate.rateBand.$dirty) return { success, errorMessages };
      else if (!this.$v.rate.rateBand.required) errorMessages.push("Required");
      return { success, errorMessages };
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog", "showDialog"]),
    async createRateBand() {
      const rateBand = await this.showDialog({
        component: "RateBandDialog"
      });
      if (!rateBand?.id) return;
      await this.fetchRateBands();
      this.$set(this.rate, "rateBand", rateBand);
    },
    async fetchRateBands() {
      this.fetchingRateBands = true;
      try {
        const bands = await getRateBands();
        bands.forEach(band => {
          this.rateBands.push(band);
        });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.fetchingRateBands = false;
      }
    },
    async createConstantRates() {
      this.$v.$touch();
      const badSurrenderCharges = this.surrenderChargesValidation.some(
        ({ success }) => !success
      );
      if (this.$v.$invalid || badSurrenderCharges) return;
      this.loading = true;
      try {
        const promises = [];
        for (let i = this.rate.year; i < this.endYear; i++) {
          promises.push(
            createMYGARate({
              ...this.rate,
              surrenderCharge: this.surrenderCharges[i],
              year: i
            })
          );
        }
        await Promise.all(promises);
        this.closeDialog({ refresh: true });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    },
    async createRate() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      try {
        await createMYGARate(this.rate);
        this.closeDialog({ refresh: true });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    },
    async updateRate() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      try {
        await updateMYGARate(this.rate);
        this.closeDialog({ refresh: true });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    },
    updateSurrenderCharges() {
      if (this.surrenderCharges.length > this.endYear) {
        for (let i = this.endYear; i < this.surrenderCharges.length; i++) {
          this.surrenderCharges.pop();
        }
      }
      if (this.surrenderCharges.length < this.endYear) {
        for (let i = this.surrenderCharges.length; i < this.endYear; i++) {
          this.surrenderCharges.push(null);
        }
      }
    }
  },
  validations() {
    let endYear = {
      required: Boolean,
      atLeastOne: v => v >= 1,
      atMostTwenty: v => v <= 20,
      greaterThanStart: v => v > this.rate.year
    };
    if (this.editing || !this.rangeCreate) {
      endYear.required = true;
      endYear.atLeastOne = true;
      endYear.atMostTwenty = true;
      endYear.greaterThanStart = true;
    }
    return {
      endYear,
      rate: {
        year: {
          required: Boolean,
          atLeastOne: v => v >= 1,
          atMostTwenty: v => v <= 20
        },
        rate: {
          required: Boolean,
          greaterThanZero: v => v > 0,
          lessThanOneHundred: v => v <= 100
        },
        surrenderCharge: {
          required: v => this.rangeCreate || Boolean(v),
          greaterThanZero: v => this.rangeCreate || v >= 0,
          lessThanOneHundred: v => this.rangeCreate || v <= 100
        },
        withdrawalAllowance: {
          required: Boolean,
          inList: v => MYGA_WITHDRAWAL_ALLOWANCES.includes(v)
        },
        rateBand: { required: v => Boolean(v?.id) }
      }
    };
  }
};
</script>
