import NoteFactory from "@/factories/NoteFactory";
export default function (model = "") {
  return {
    id: model?.id || null,
    name: model?.name || null,
    carrier: model?.carrier || null,
    street: Boolean(model?.street),
    noPay: Boolean(model?.noPay),
    setFromRequest(model = {}) {
      this.id = model?.id || null;
      this.name = model?.name || null;
      this.carrier = model?.carrier || null;
      this.street = Boolean(model?.street);
      this.noPay = Boolean(model?.no_pay);
      this.notes = model?.notes?.map(rawNote => {
        const note = new NoteFactory();
        note.setFromRequest(rawNote);
        return note;
      });
    },
    toCreateRequest() {
      return {
        carrier_id: this.carrier.id,
        name: this.name,
        street: Boolean(this.street),
        no_pay: Boolean(this.noPay)
      };
    },
    generateAttributeMap() {
      return {
        name: { name: this.name },
        carrier: { carrier_id: this.carrier.id },
        street: { street: this.street },
        noPay: { no_pay: this.noPay }
      };
    },
    getAttributeRequestValue(attribute) {
      const attributeMap = this.generateAttributeMap();
      return attributeMap[attribute];
    }
  };
}
