<template>
  <v-card>
    <v-card-title>Add Smoker Chart</v-card-title>
    <v-card-text>
      <v-select
        v-model="productSmokerChart.smokerChart"
        prepend-inner-icon="$mdi-smoking"
        outlined
        dense
        label="Smoker Chart"
        return-object
        item-text="name"
        :items="smokerCharts"
        :success="smokerChartValidation.success"
        :error-messages="smokerChartValidation.errorMessages"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        color="grey"
        @click="closeDialog"
        :disabled="loading"
      >
        Close
      </v-btn>
      <v-btn class="text-none" color="primary" @click="save" :loading="loading">
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import ProductSmokerChartFactory from "@/factories/ProductSmokerChartFactory";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { getAll } from "@/api/smoker-charts.service";
import { addProductSmokerChart } from "@/api/product-smoker-chart.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  validations: {
    productSmokerChart: {
      smokerChart: { id: { required } }
    }
  },
  props: {
    product: Object
  },
  data() {
    return {
      productSmokerChart: new ProductSmokerChartFactory(),
      smokerCharts: [],
      loading: false
    };
  },
  computed: {
    smokerChartValidation() {
      const success = !this.$v.productSmokerChart.smokerChart.id.$invalid;
      const errorMessages = [];
      if (!this.$v.productSmokerChart.smokerChart.id.$dirty)
        return { success, errorMessages };
      else if (!this.$v.productSmokerChart.smokerChart.id.required)
        errorMessages.push("Required");
      return { success, errorMessages };
    }
  },
  created() {
    this.getSmokerCharts();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    getSmokerCharts() {
      getAll(this.product.carrier.id)
        .then(response => {
          this.smokerCharts.splice(0, this.smokerCharts.length);
          this.smokerCharts.push(...response);
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        });
    },
    save() {
      this.$v.$touch();

      if (this.$v.$invalid) return;
      this.loading = true;
      addProductSmokerChart(
        this.productSmokerChart.toCreateRequest(this.product.id)
      )
        .then(response => {
          this.productSmokerChart.id = response.data.product_smoker_chart.id;
          this.closeDialog({ productSmokerChart: this.productSmokerChart });
        })
        .catch(error => {
          this.showErrorSnackbar({
            message: `Unable to create smoker chart. ${parseErrorMessage(
              error
            )}`,
            timeout: -1
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
