import format from "date-fns/format";
import { cropOrEllipsis } from "@/util/helpers";
import { getHttpClient } from "@/http-client";
const baseURL = "/api/boss/webinars";

const convertToHoursAndMinutes = datetime => {
  const date = new Date(datetime);

  return format(date, "h:mma");
};

const convertToDate = datetime => {
  const date = new Date(datetime);

  return format(date, "MMMM do, yyyy");
};

function Webinar(model = {}) {
  return {
    subject: model?.subject,
    description: model?.description || "",
    endTime: model?.endTime,
    endTimeOfDay: model?.endTimeOfDay,
    registrationUrl: model?.registrationUrl,
    startTime: model?.startTime,
    startTimeOfDay: model?.startTimeOfDay,
    startDate: model?.startDate,
    preview: null
  };
}

function setWebinarFromRequest(req = {}) {
  const webinar = Webinar();

  webinar.description = req?.description || "";
  webinar.endTime = req?.end_time;
  webinar.registrationUrl = req?.registration_url;
  webinar.startTime = req?.start_time;
  webinar.subject = req?.subject;

  webinar.endTimeOfDay = convertToHoursAndMinutes(webinar.endTime);
  webinar.startTimeOfDay = convertToHoursAndMinutes(webinar.startTime);
  webinar.startDate = convertToDate(webinar.startTime);
  webinar.preview = cropOrEllipsis(webinar.description);

  return webinar;
}

export const getWebinars = async () => {
  const response = await getHttpClient().get(baseURL);
  return response.data.map(setWebinarFromRequest);
};
