<template>
  <v-card>
    <close-button @click="closeDialog({ completed: false })" />
    <v-card-title> Create a Note </v-card-title>
    <v-card-text>
      <v-textarea
        prepend-inner-icon="$mdi-message"
        dense
        outlined
        rows="2"
        no-resize
        class="pa-3"
        placeholder="Write a comment"
        v-model="message"
        :disabled="submittingMessage"
        :success="!$v.message.$invalid"
        :error-messages="
          $v.message.$dirty && !$v.message.required ? ['Required'] : []
        "
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        class="text-none ml-1"
        @click="submitMessage"
        :loading="submittingMessage"
        >Comment</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import CloseButton from "@/dialogs/CloseButton.vue";

import { required } from "vuelidate/lib/validators";
import { createNote } from "@/api/notes.service";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import NoteFactory from "@/factories/NoteFactory";
import { useDialogStore } from "@/stores/dialog";
export default {
  components: {
    CloseButton
  },
  props: {
    attachToTasks: Boolean
  },
  data() {
    return {
      message: "",
      submittingMessage: false
    };
  },
  computed: {
    ...mapState(useUserStore, ["loginable"])
  },
  methods: {
    ...mapActions(useDialogStore, ["closeDialog"]),
    submitMessage() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.submittingMessage = true;
      const promises = [];

      this.attachToTasks.forEach(task => {
        const note = new NoteFactory({
          email: 0,
          notableId: task.additional.id,
          notableType: "Task",
          note: this.message
        });
        promises.push(createNote(note.toCreateRequest()));
      });

      Promise.all(promises)
        .then(() => {
          this.closeDialog({ completed: true });
        })
        .finally(() => {
          this.submittingMessage = false;
        });
    }
  },
  validations: {
    message: { required }
  }
};
</script>
