export default function RecentElectronicApplication(model = {}) {
  return {
    status: model?.status || "",
    benefitAmount: model?.benefitAmount || 0,
    id: model?.id || 0,
    name: model?.name || "",
    productName: model?.productName || "",
    carrierName: model?.carrierName || "",
    setFromRequest(model = {}) {
      this.status = model?.status;
      this.benefitAmount = model?.benefit_amount;
      this.id = model?.id;
      this.name = model?.name;
      this.productName = model?.product?.name;
      this.carrierName = model?.product?.carrier?.name;
    }
  };
}
