<template>
  <v-row class="ma-0">
    <v-col cols="12" class="pb-0">
      <back-nine-statement-card v-if="loaded" :statement="statement" />
      <v-skeleton-loader type="card" v-else />
    </v-col>
    <v-col cols="12" class="py-0">
      <back-nine-statement-actions v-if="loaded" :statement="statement" />
      <v-skeleton-loader type="card" v-else />
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-card tile flat v-if="loaded">
        <v-tabs v-model="activeTab" class="pt-3" show-arrows>
          <v-tab class="text-none"> Payments </v-tab>
          <v-tab class="text-none">
            <v-badge :content="transactionCount || '0'" color="primary">
              Transactions
            </v-badge>
          </v-tab>
          <v-tab class="text-none"> Details </v-tab>
          <v-tab class="text-none">
            <v-badge :content="pendingCount || '0'" color="primary">
              Pending Transactions
            </v-badge>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab" touchless>
          <v-tab-item>
            <back-nine-statement-payments :id="id" />
          </v-tab-item>
          <v-tab-item eager>
            <back-nine-statement-transactions
              :id="id"
              @total="transactionCount = $event"
            />
          </v-tab-item>
          <v-tab-item>
            <back-nine-statement-details v-model="statement" />
          </v-tab-item>
          <v-tab-item eager>
            <queued-transactions
              :statement-id="id"
              @total="pendingCount = $event"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <v-skeleton-loader type="card" v-else />
    </v-col>
  </v-row>
</template>

<script>
import BackNineStatementCard from "@/components/commissions/back-nine-statement/BackNineStatementCard.vue";
import BackNineStatementActions from "@/components/commissions/back-nine-statement/BackNineStatementActions.vue";
import BackNineStatementPayments from "@/components/commissions/back-nine-statement/BackNineStatementPayments.vue";
import BackNineStatementTransactions from "@/components/commissions/back-nine-statement/BackNineStatementTransactions.vue";
import BackNineStatementDetails from "@/components/commissions/back-nine-statement/BackNineStatementDetails.vue";
import QueuedTransactions from "@/components/commissions/QueuedTransactions.vue";

import { getBackNineStatement } from "@/api/statements.service";

import { useSnackbarStore } from "@/stores/snackbar";
import { mapWritableState, mapActions } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { parseErrorMessage } from "@/util/helpers";
import { useHead } from "@unhead/vue";

const pages = ["payments", "transactions", "details", "pending-transactions"];

export default {
  components: {
    BackNineStatementDetails,
    BackNineStatementCard,
    BackNineStatementActions,
    BackNineStatementPayments,
    BackNineStatementTransactions,
    QueuedTransactions
  },
  data() {
    return {
      statement: null,
      loaded: false,
      activeTab: 0,
      pendingCount: 0,
      transactionCount: 0,
      id: this.$route.params.id
    };
  },
  setup() {
    const head = useHead({ title: "Statement Viewer" });
    return { head };
  },
  watch: {
    activeTab() {
      this.updatePage();
    }
  },
  created() {
    if (this.$route.query.page) {
      this.activeTab = pages.indexOf(this.$route.query.page);
    }
    this.updatePage();
    this.getStatement(this.id);
  },
  destroyed() {
    this.breadcrumb = "";
  },
  computed: {
    ...mapWritableState(useInstanceStore, ["breadcrumb"])
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    updatePage() {
      const activePageQuery = pages[this.activeTab];
      if (this.$route.query?.page === activePageQuery) return;
      this.$router.replace({ query: { page: activePageQuery } });
    },
    getStatement(id) {
      this.breadcrumb = id;
      this.head.patch({ title: id });
      getBackNineStatement(id)
        .then(res => {
          this.statement = res;
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
          this.$router.replace({ name: "Home" });
        })
        .finally(() => {
          this.loaded = true;
        });
    }
  }
};
</script>
