import Vue, { markRaw } from "vue";
import App from "@/App.vue";
import "@/stylesheet/main.scss";
import router from "@/router";
import vuetifyConfig from "@/plugins/vuetify";
import Vuetify from "vuetify/lib";

import Vuelidate from "vuelidate";
import browserUpdate from "browser-update";
import { createPinia, PiniaVuePlugin, setActivePinia } from "pinia";
import VueGtag from "vue-gtag";
import { install } from "resize-observer"; //make sure people have resize-observer
import { Vue2ProvideUnheadPlugin, createHead } from "@unhead/vue";
import { useInstanceStore } from "@/stores/instance";

Vue.use(Vuetify);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

setActivePinia(pinia);

pinia.use(({ store }) => (store.router = markRaw(router)));
pinia.use(syncWithLocalStoragePlugin);

install();

browserUpdate({
  required: { e: -4, f: -3, o: -3, s: -1, c: -3 },
  insecure: false,
  api: 2020.11
});

Vue.config.errorHandler = error => {
  console.error(error);
  logError(error);
};

Vue.config.productionTip = false;
Vue.use(Vuelidate);

const mountEl = document.querySelector("#app");
const isDemoMode = Boolean(mountEl?.dataset?.demo);

let apiUrl = process.env.VUE_APP_API_URL;

if (isDemoMode && process.env.VUE_APP_DEMO_API_URL) {
  apiUrl = process.env.VUE_APP_DEMO_API_URL;
}

import { logError } from "@/logger";
import { syncWithLocalStoragePlugin } from "@/stores/sync-with-local-storage-plugin";

if (process.env.NODE_ENV === "production") {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GTAG }
    },
    router
  );
}

const head = createHead();
Vue.use(Vue2ProvideUnheadPlugin, head);
Vue.use(head);

const app = new Vue({
  name: "BOSS",
  pinia,
  router,
  vuetify: new Vuetify(vuetifyConfig(mountEl?.dataset?.nonce)),
  render: h => h(App)
}).$mount("#app");

const instance = useInstanceStore();
instance.apiUrl = apiUrl;
instance.nonce = mountEl?.dataset?.nonce;

instance.setCsrfToken().then(() => app.$mount("#app"));
