import CardItemText from "@/components/shared/card-items/CardItemText.vue";
import CardItemRouterLink from "@/components/shared/card-items/CardItemRouterLink.vue";
import CardItemA from "@/components/shared/card-items/CardItemA.vue";
import CardItemSensitiveInfo from "@/components/shared/card-items/CardItemSensitiveInfo.vue";
import { markRaw } from "vue";

export const routerLinkItem = ({ title, text, to }) => ({
  component: markRaw(CardItemRouterLink),
  key: title,
  props: {
    title,
    text,
    to
  }
});

export const textItem = ({
  title,
  text,
  clickable = false,
  onClick = () => {}
}) => ({
  component: markRaw(CardItemText),
  key: title,
  props: {
    title,
    text,
    clickable
  },
  listeners: {
    onClick
  }
});

export const linkItem = ({
  title,
  href,
  text,
  dataOutboundType,
  dataOutboundNumber,
  dataOutboundId
}) => ({
  component: markRaw(CardItemA),
  key: title,
  props: {
    title,
    href,
    text,
    dataOutboundType,
    dataOutboundNumber,
    dataOutboundId
  }
});

export const sensitiveInfoItem = ({ title, type, fetchFunc }) => ({
  component: markRaw(CardItemSensitiveInfo),
  key: title,
  props: {
    title,
    type,
    fetchFunc
  }
});
