import { getHttpClient } from "@/http-client";

const baseUrl = "api/boss/engines";
export async function fetchEngines() {
  const { data } = await getHttpClient().get(baseUrl);
  return data || [];
}

export async function fetchCategories() {
  const { data } = await getHttpClient().get(`${baseUrl}/product-categories`);

  return data || [];
}
