import { getHttpClient } from "@/http-client";

const baseUrl = "api/boss/product_build_charts";
export async function addProductBuildChart(params) {
  const { data } = await getHttpClient().post(baseUrl, params);
  return data.product_build_chart.id;
}

export function removeProductBuildChart(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}
