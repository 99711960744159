<template>
  <v-card flat :color="isDialog ? null : 'transparent'">
    <v-card-text class="pa-0">
      <v-row class="pa-3" dense>
        <v-col cols="12" lg="9">
          <transaction-create-form
            ref="create-form"
            :fetching-policy="fetchingPolicy"
            :statement.sync="transaction.statement"
            :policy.sync="transaction.policy"
            :commission-type.sync="transaction.commissionType"
            :premium.sync="transaction.premium"
            :override.sync="transaction.override"
            :memo.sync="transaction.memo"
            :preserve-field-values="preserveFieldValues"
            :loading="loading"
            :editing="editing"
            :error-message="errorMessage"
            :show-back-nine-writing-agent-hint="showBackNineWritingAgentHint"
            @save="createTransaction"
          />
          <transaction-helper-table
            class="mt-2"
            :is-dialog="isDialog"
            :loading="loadingCaseTransactions"
            :loaded="loadedCaseTransactions"
            :simulating="simulatingTransaction"
            :simulated="simulatedTransaction"
            :transactions="transactions"
            :commissions="commissions"
            :parties="parties"
            :policy="policy"
            :payments="payments"
            @reset="resetCommissions"
            @fetch="refresh"
          />
        </v-col>
        <v-col cols="12" lg="3">
          <transaction-case-data
            v-if="
              policy &&
              policy.id &&
              statement &&
              statement.id &&
              transaction.commissionType
            "
            :key="`${policy.id}-${statement.id}`"
            :loading="simulatingTransaction"
            :final-override="finalOverride"
            :backnine-forecast="backnineForecast"
            :total-forecast="totalForecast"
            :case-id="policy.id"
            :policy-number="policy.policy_number"
            :commission-type="transaction.commissionType"
            :statement="statement"
            @refresh="refresh"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="isDialog" class="px-3 pt-0">
      <v-btn block @click="closeDialog()" class="text-none" color="primary">
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import TransactionCreateForm from "@/components/commissions/TransactionCreateForm.vue";
import TransactionCaseData from "@/components/commissions/TransactionCaseData.vue";
import TransactionHelperTable from "@/components/commissions/TransactionHelperTable.vue";

import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";

import {
  simulateTransaction,
  getCaseTransactionsAndParties
} from "@/api/transactions.service";
import { searchCaseTransactions } from "@/api/transactions.service";
import { createTransaction } from "@/api/transactions.service";

import { resetCaseCommissions } from "@/api/cases.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useHead } from "@unhead/vue";
const initialState = () => {
  return {
    errorMessage: "",
    transaction: {
      memo: null,
      premium: null,
      amount: null,
      commissionType: "First Year",
      policy: {
        id: null,
        policy_number: null,
        appointment_cases: [],
        line: null
      }
    },
    forecast: {},
    payments: [],
    commissions: [],
    transactions: [],
    parties: [],
    loading: false,
    simulationTimer: false,
    simulatingTransaction: false,
    simulatedTransaction: false,
    caseTransactionTimer: null,
    loadingCaseTransactions: false,
    loadedCaseTransactions: false,
    editing: false,
    preserveFieldValues: true,
    finalOverride: null,
    fetchingPolicy: false
  };
};

export default {
  setup() {
    useHead({ title: "Create Transaction" });
  },
  props: {
    isDialog: Boolean,
    premium: Number,
    amount: Number,
    id: Number,
    caseId: Number,
    policyNumber: String,
    commissionType: String,
    rawModel: Object
  },
  components: {
    TransactionCreateForm,
    TransactionCaseData,
    TransactionHelperTable
  },
  data() {
    return initialState();
  },
  created() {
    if (!this.id) return;
    this.$set(this.transaction, "statement", this.rawModel?.statement);
    this.transaction.premium = this.premium;
    this.transaction.override = this.amount;
    this.transaction.commissionType = this.commissionType;
    if (this.caseId && this.policyNumber) this.fetchAndSetPolicy();
    if (this.id) this.editing = true;
  },
  watch: {
    transaction: {
      deep: true,
      handler(newValue) {
        if (newValue?.policy?.id && newValue?.commissionType) {
          this.fetchCaseTransactions();
        }
      }
    },
    simulateKey(v) {
      if (v) this.simulateTransaction();
    }
  },
  computed: {
    simulateKey() {
      const values = [];
      values.push(this.transaction.premium);
      values.push(this.transaction.policy?.id);
      values.push(this.transaction.commissionType);
      values.push(this.transaction.statement?.id);
      values.push(this.transaction.override);
      if (values.some(v => !v)) return null;
      return values.join("-");
    },
    statement() {
      return this.transaction.statement;
    },
    isPaidFromCarrier() {
      return this.transaction.statement?.payor?.payable_type === "Carrier";
    },
    policy() {
      return this.transaction?.policy;
    },
    backnineForecast() {
      return this.forecast?.backnine;
    },
    totalForecast() {
      return this.forecast?.total;
    },
    showBackNineWritingAgentHint() {
      if (!this.commissions.length) return false;
      //TODO remove magic numbers or stick them in an enum
      return this.commissions.some(
        ({ assigned_payable, payor, payment_type }) =>
          assigned_payable.id === 18 &&
          assigned_payable.type === "Agency" &&
          payor.id !== 84 &&
          payment_type === "Flat"
      );
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, [
      "showErrorSnackbar",
      "showSuccessSnackbar"
    ]),
    ...mapActions(useDialogStore, ["showDialog", "closeDialog"]),
    async fetchAndSetPolicy() {
      this.fetchingPolicy = true;
      try {
        const cases = await searchCaseTransactions(null, this.caseId);
        const currentCase = cases.find(
          v => v.policy_number === this.policyNumber
        );
        if (!currentCase) return;
        this.$set(this.transaction, "policy", currentCase);
      } catch (e) {
        this.showErrorSnackbar(parseErrorMessage(e));
      } finally {
        this.fetchingPolicy = false;
      }
    },
    resetCommissions() {
      if (!this.policy?.id) return;
      this.showDialog({
        component: "ConfirmationDialog",
        title: "Reset Commissions",
        subtitle:
          "This will set all commission percentages to zero and re-build the commissions",
        func: () =>
          resetCaseCommissions(this.policy.id)
            .then(() => {
              this.refresh();
            })
            .catch(e => {
              this.showErrorSnackbar({
                message: parseErrorMessage(e),
                timeout: -1
              });
            })
      });
    },
    refresh() {
      this.simulateTransaction();
      this.fetchCaseTransactions();
    },
    transactionAsCreateBody() {
      return {
        memo: this.transaction.memo,
        premium: this.transaction.premium,
        case_id: this.transaction.policy?.id,
        commission_type: this.transaction.commissionType,
        statement_id: this.transaction.statement?.id,
        amount: this.transaction.override,
        queued_transaction_id: this.id || undefined
      };
    },
    createTransaction() {
      if (this.errorMessage) {
        this.showErrorSnackbar({ message: this.errorMessage });
        return;
      }
      const createAnon = () => {
        this.loading = true;
        createTransaction(this.transactionAsCreateBody())
          .then(() => {
            this.showSuccessSnackbar({
              message: "Successfully Created Transaction",
              timeout: 3000
            });
            if (this.isDialog) {
              this.closeDialog();
            } else {
              const statement = { ...this.transaction.statement };
              Object.assign(this.$data, initialState());
              if (this.preserveFieldValues) {
                this.$set(this.transaction, "statement", statement);
              }
              this.$refs["create-form"].$v.$reset();
            }
          })
          .catch(e => {
            this.showErrorSnackbar({
              message: parseErrorMessage(e),
              timeout: -1
            });
          })
          .finally(() => {
            this.loading = false;
          });
      };
      if (!this.transactionAlreadyExists()) return createAnon();
      return this.showDialog({
        component: "ConfirmationDialog",
        subtitle:
          "This transaction has already been created and nothing has changed. Are you sure that you still want to create a duplicate transaction?",
        title: "Duplicate Transaction Entered"
      }).then(res => {
        if (!res.confirm) return;
        createAnon();
      });
    },
    transactionAlreadyExists() {
      return this.transactions.some(
        ({ commission_type, statement_id, amount, direct_commission }) => {
          const sameCommissionType = commission_type.endsWith(
            this.transaction.commissionType
          );
          const sameStatementId =
            this.transaction.statement?.id === statement_id;
          const samePremium = this.transaction.premium === amount;
          const sameOverride = this.transaction.override === direct_commission;
          return [
            sameCommissionType,
            sameStatementId,
            samePremium,
            sameOverride
          ].every(v => v === true);
        }
      );
    },
    simulateTransaction() {
      if (this.simulationTimer) clearTimeout(this.simulationTimer);
      if (!this.simulateKey) return;
      this.simulationTimer = setTimeout(() => {
        this.simulatingTransaction = true;
        simulateTransaction(this.transactionAsCreateBody())
          .then(
            ({
              forecast,
              payments,
              commissions,
              finalOverride,
              errors,
              isErrored
            }) => {
              if (isErrored) {
                const hasPaymentsErrors = payments.some(v => v.errors);
                const hasCommissionsErrors = commissions.some(v => v.errors);
                const hasTransactionsErrors = Boolean(errors);
                this.finalOverride = finalOverride;
                this.errorMessage = "";
                if (hasPaymentsErrors) {
                  this.errorMessage += "Some payments have errors. ";
                }
                if (hasCommissionsErrors) {
                  this.errorMessage += "Some commissions have errors. ";
                }
                if (hasTransactionsErrors) {
                  this.errorMessage += errors;
                }

                this.$set(this, "forecast", forecast);
                this.$set(this, "payments", payments);
                this.$set(this, "commissions", commissions);
              } else {
                this.errorMessage = "";
                this.finalOverride = finalOverride;
                this.$set(this, "forecast", forecast);
                this.$set(this, "payments", payments);
                this.$set(this, "commissions", commissions);
              }
            }
          )
          .catch(e => {
            this.showErrorSnackbar({ message: parseErrorMessage(e) });
          })
          .finally(() => {
            this.simulatingTransaction = false;
            this.simulatedTransaction = true;
          });
      }, 1000);
    },
    fetchCaseTransactions() {
      if (!this.transaction?.policy?.id || !this.transaction?.commissionType)
        return;

      if (this.caseTransactionTimer) clearTimeout(this.caseTransactionTimer);
      this.caseTransactionTimer = setTimeout(() => {
        if (!this.transaction?.policy?.id || !this.transaction?.commissionType)
          return;

        this.loadingCaseTransactions = true;
        getCaseTransactionsAndParties({
          caseId: this.transaction.policy.id,
          commissionType: this.transaction.commissionType
        })
          .then(({ transactions, parties }) => {
            this.$set(this, "parties", parties);
            this.$set(this, "transactions", transactions);
          })
          .catch(e => {
            this.showErrorSnackbar({ message: parseErrorMessage(e) });
          })
          .finally(() => {
            this.loadingCaseTransactions = false;
            this.loadedCaseTransactions = true;
          });
      }, 1000);
    }
  }
};
</script>
