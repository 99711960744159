<template>
  <v-card>
    <close-button @click="closeDialog" />
    <v-card-title>Edit Statement</v-card-title>
    <v-card-text>
      <span v-if="!paid">
        <p>{{ errors }}</p>
        <p>
          Once this has been paid manually, you can enter a note below to close
          the period.
        </p>
      </span>
      <span v-else>
        <p>This period has been manually paid. You can only update the memo.</p>
      </span>

      <v-text-field
        data-lpignore="true"
        label="Memo"
        v-model="memo"
        dense
        outlined
        :success="!$v.memo.$invalid"
        :error-messages="
          $v.memo.$dirty && !$v.memo.required ? ['Required'] : []
        "
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        color="grey"
        @click="closeDialog"
        :disabled="loading"
      >
        Close
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        @click="savePayPeriod"
        :loading="loading"
      >
        {{ !paid ? "Mark as paid" : "Update" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CloseButton from "@/dialogs/CloseButton.vue";
import { required } from "vuelidate/lib/validators";
import { updatePayPeriod, payPayPeriod } from "@/api/pay-periods.service";
import { parseErrorMessage } from "@/util/helpers";
import { mapActions } from "pinia";
import { useDialogStore } from "@/stores/dialog";

export default {
  components: {
    CloseButton
  },
  props: {
    payPeriod: Object
  },
  data() {
    return {
      memo: this.payPeriod.memo,
      loading: false
    };
  },
  computed: {
    paid() {
      return this.payPeriod.transaction_id || this.payPeriod.memo
        ? true
        : false;
    },
    errors() {
      return this.payPeriod.errors;
    }
  },
  methods: {
    ...mapActions(useDialogStore, ["closeDialog"]),
    savePayPeriod() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;

      let promise = null;
      if (!this.paid) {
        promise = payPayPeriod(this.payPeriod.id, this.memo)
          .then(() => {
            this.closeDialog({ updated: true });
          })
          .catch(e => {
            if (e.response.status == 422) {
              this.payPeriod.errors = e.response.data.message;
            } else {
              this.showErrorSnackbar({
                message: parseErrorMessage(e),
                timeout: -1
              });
            }
          });
      } else {
        updatePayPeriod(this.payPeriod.id, this.memo).then(() => {
          this.closeDialog({ updated: true });
        });
      }

      promise.finally(() => {
        this.loading = false;
      });
    }
  },
  validations: {
    memo: { required }
  }
};
</script>
