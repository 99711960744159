<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    @update:options="updateOptions"
    must-sort
    :options="options"
    :footer-props="footerProps"
    :server-items-length="revenueTable.meta.total"
    item-key="additional.id"
  >
    <template #top>
      <generic-table-filter-header
        :show-view-filter="isGroupTwoPlus"
        :value="filter"
        :headers="allHeaders"
        :loading="loading"
        @update="updateFilter"
        hover
        color="section"
      />
    </template>
    <template #[`item.createdDate`]="{ item }">
      <timestamp-formatter :value="item.createdDate" parser="sole-day" />
    </template>
    <template #[`item.statementDate`]="{ item }">
      <timestamp-formatter :value="item.statementDate" parser="sole-day" />
    </template>
    <template #[`item.payPeriod`]="{ item }">
      <v-tooltip v-if="item.payPeriod" bottom>
        <template #activator="{ on, attrs }">
          <v-chip
            color="primary"
            label
            v-on="on"
            v-bind="attrs"
            :to="{
              name: 'PayPeriodView',
              params: {
                id: item.payPeriod
              }
            }"
          >
            <v-icon left>$mdi-calendar</v-icon>
            #{{ item.payPeriod }}
          </v-chip>
        </template>
        <span>View Statement</span>
      </v-tooltip>
    </template>
    <template #[`item.parties`]="{ item }">
      Sender: <strong> {{ item.paidBy }} </strong> <br />
      Recipient: <strong> {{ item.paidTo }} </strong> <br />
      Agent: <strong> {{ item.agent }} </strong>
    </template>
    <template #[`item.premium`]="{ item }">
      <currency-formatter :value="item.premium" />
    </template>
    <template #[`item.splitPercent`]="{ item }">
      <percentage-formatter :value="item.splitPercent" />
    </template>
    <template #[`item.commission`]="{ item }">
      <currency-formatter :value="item.commission" />
      <span class="grey--text">
        (<percentage-formatter :value="item.additional.percent" />)
      </span>
    </template>
    <template #[`item.policyNumber`]="{ item }">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-chip
            color="accent"
            label
            v-on="on"
            v-bind="attrs"
            :to="
              getRouterLink(
                'Case',
                item.additional.commission.appointment_case.case_id
              )
            "
          >
            <v-icon left>$mdi-briefcase</v-icon>
            {{ item.policyNumber }}
          </v-chip>
        </template>
        <span>View Case</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import TableHeader from "@/classes/data-table/TableHeader";
import Table from "@/classes/data-table/Table";
import TableOptions from "@/classes/data-table/TableOptions";

import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";

import { paymentTypes, commissionTypes } from "@/data/filter-options";
import { parseErrorMessage, getRouterLink } from "@/util/helpers";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/user";

import { emailPayments, getPayments } from "@/api/payments.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTableStore } from "@/stores/table";
import { useHead } from "@unhead/vue";
export default {
  name: "CommissionPayments",
  setup() {
    useHead({ title: "Payments" });
  },
  components: {
    TimestampFormatter,
    GenericTableFilterHeader,
    CurrencyFormatter,
    PercentageFormatter
  },
  mixins: [GenericTableMixin],
  data() {
    const user = useUserStore();
    const headers = [
      new TableHeader({
        text: "Statement ID",
        value: "payPeriod",
        map: "pay_period.id",
        ...TableHeader.IS_STRING_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        sortFilterMap: "pay_periods.id",
        icon: "$mdi-pound"
      }),
      new TableHeader({
        text: "Parties",
        value: "parties"
      }),
      new TableHeader({
        text: "Paid By",
        value: "paidBy",
        map: "commission.payor.name",
        ...TableHeader.IS_ADDITIONAL,
        ...TableHeader.IS_PAYOR_SEARCH_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_QUERY_FILTER,
        sortFilterMap: [{ key: "payor_id", value: "id" }]
      }),
      new TableHeader({
        text: "Paid To",
        value: "paidTo",
        map: "commission.assigned_payable.name",
        ...TableHeader.IS_ADDITIONAL,
        ...TableHeader.IS_ADVISOR_PAYMENT_SEARCH_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_QUERY_FILTER,
        sortFilterMap: [
          { key: "assigned_payable_id", value: "id" },
          { key: "assigned_payable_type", value: "type" }
        ]
      }),
      new TableHeader({
        text: "Agent",
        value: "agent",
        map: "commission.appointment_case.advisor.name",
        ...TableHeader.IS_ADDITIONAL,
        ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_QUERY_FILTER,
        sortFilterMap: [
          { key: "agent_id", value: "id" },
          { key: "agent_type", value: "type" }
        ]
      }),
      new TableHeader({
        text: "Paid on Behalf of",
        value: "paidOnBehalfOf",
        map: "",
        ...TableHeader.IS_ADDITIONAL,
        ...TableHeader.IS_ADVISOR_PAYMENT_SEARCH_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_QUERY_FILTER,
        sortFilterMap: [
          { key: "payable_id", value: "id" },
          { key: "payable_type", value: "type" }
        ]
      }),
      new TableHeader({
        text: "Policy Number",
        value: "policyNumber",
        map: "commission.appointment_case.policy_number", // can you link this to the case?
        ...TableHeader.IS_POLICY_SEARCH_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        sortFilterMap: [{ key: "case_id", value: "id" }],
        ...TableHeader.IS_QUERY_FILTER,
        displayMap: "policy_number"
      }),
      new TableHeader({
        text: "Insured",
        value: "insured",
        map: "commission.appointment_case.insured.name",
        ...TableHeader.IS_CONTRACT_PARTY_SEARCH_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        sortFilterMap: [{ key: "contract_party_id", value: "id" }],
        ...TableHeader.IS_QUERY_FILTER
      }),
      new TableHeader({
        text: "Premium",
        value: "premium",
        map: "premium",
        ...TableHeader.IS_STRING_FILTER_TYPE,
        filterable: false,
        sortFilterMap: "payments.premium"
      }),

      new TableHeader({
        text: "Commission",
        value: "commission",
        map: "dollar",
        ...TableHeader.IS_STRING_FILTER_TYPE,
        ...TableHeader.IS_SORTABLE,
        sortFilterMap: "payments.dollar"
      }),
      new TableHeader({
        text: "Commission Type",
        value: "type",
        map: "commission_type",
        ...TableHeader.IS_SELECT_FILTER_TYPE,
        selectableOptions: commissionTypes,
        ...TableHeader.IS_FILTERABLE,
        sortFilterMap: "payments.commission_type",
        icon: "$mdi-cash-multiple"
      }),
      new TableHeader({
        text: "Created",
        value: "createdDate",
        map: "created_date",
        ...TableHeader.IS_DATE_FILTER_TYPE,
        ...TableHeader.IS_SORTABLE,
        ...TableHeader.IS_FILTERABLE,
        sortFilterMap: "payments.created_at"
      }),
      new TableHeader({
        text: "Carrier",
        value: "carrier",
        map: "commission.appointment_case.carrier.name",
        ...TableHeader.IS_CARRIER_SEARCH_FILTER_TYPE,
        ...TableHeader.IS_ADDITIONAL,
        ...TableHeader.IS_QUERY_FILTER,
        ...TableHeader.IS_FILTERABLE,
        sortFilterMap: [{ key: "carrier_id", value: "id" }]
      }),
      new TableHeader({
        text: "View",
        value: "view",
        map: "view",
        ...TableHeader.IS_SELECT_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        selectableOptions: user.isGroupFour
          ? [
              { text: "All Payments", value: "all" },
              { text: "My Payments", value: "my" }
            ]
          : [],
        ...TableHeader.IS_ADDITIONAL,
        ...TableHeader.IS_QUERY_FILTER,
        sortFilterMap: "view",
        ...TableHeader.IS_MANDATORY,
        order: 1
      }),
      user.isGroupTwoPlus
        ? new TableHeader({
            text: "Payment Type",
            value: "paymentType",
            map: "payment_type",
            ...TableHeader.IS_SELECT_FILTER_TYPE,
            ...TableHeader.IS_FILTERABLE,
            selectableOptions: paymentTypes,
            ...TableHeader.IS_ADDITIONAL,
            sortFilterMap: "payment_type",
            ...TableHeader.IS_QUERY_FILTER,
            icon: "$mdi-cash-refund"
          })
        : null,
      user.isGroupTwoPlus
        ? new TableHeader({
            text: "Marketing Manager",
            value: "marketingManager",
            sortFilterMap: [
              { key: "marketing_manager_id", value: "marketing_manager_id" }
            ],
            ...TableHeader.IS_MARKETING_MANAGER_SEARCH_FILTER_TYPE,
            ...TableHeader.IS_FILTERABLE,
            ...TableHeader.IS_ADDITIONAL,
            ...TableHeader.IS_QUERY_FILTER
          })
        : null,
      user.isGroupTwoPlus
        ? new TableHeader({
            text: "White Glove eApp",
            value: "whiteGloveEApp",
            sortFilterMap: "white_glove_eapp",
            ...TableHeader.IS_CHECKBOX_TYPE,
            ...TableHeader.IS_FILTERABLE,
            ...TableHeader.IS_ADDITIONAL,
            ...TableHeader.IS_QUERY_FILTER
          })
        : null,
      new TableHeader({
        text: "Inforce Date",
        value: "inforce",
        map: "inforce_date",
        ...TableHeader.IS_DATE_FILTER_TYPE,
        ...TableHeader.IS_SORTABLE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_QUERY_FILTER,
        ...TableHeader.IS_ADDITIONAL,
        sortFilterMap: "inforce_date"
      })
    ].filter(Boolean);

    let filter = {
      view: "my"
    };
    const { paymentsTable } = useTableStore();
    if (paymentsTable.filter) {
      Object.keys(paymentsTable.filter).forEach(key => {
        if (!headers.find(header => header.value === key)) return;
        filter[key] = paymentsTable.filter[key];
      });
    }
    let options = new TableOptions(["createdDate"], [true]);
    if (Object.keys(paymentsTable.options).length) {
      options = paymentsTable.options;
    }
    const revenueTable = new Table({
      text: "Payments",
      method: getPayments,
      options,
      headers,
      key: "payments",
      filter,
      emailMethod: emailPayments
    });

    return {
      revenueTable,
      showStatistics: false,
      getRouterLink
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.$emit("can-email", this.filter.view !== "all");
    this.$emit("get-data-func", this.getData);
    this.$emit("email-data-func", this.emailData);
  },
  computed: {
    ...mapWritableState(useTableStore, ["paymentsTable"]),
    ...mapState(useUserStore, ["isGroupTwoPlus"]),
    footerProps() {
      return {
        pageText: this.pageTextFormatter(
          this.revenueTable.options,
          this.revenueTable.meta
        ),
        itemsPerPageOptions: [10, 20, 35, 50]
      };
    },
    allHeaders() {
      return this.revenueTable.headers;
    },
    headers() {
      return this.revenueTable.headers.filter(val => !val.isAdditional);
    },
    loading() {
      return this.revenueTable.loading;
    },
    items() {
      return this.tableMap(
        this.revenueTable.rawItems,
        this.revenueTable.headers
      );
    },
    meta() {
      return this.revenueTable.meta;
    },
    stats() {
      const stats = [];
      const revenue = { text: "Revenue", icon: "$mdi-sack" };
      const statsObj = this.revenueTable?.meta?.stats;
      if (!statsObj) {
        return [{ ...revenue, type: "loading" }];
      }
      Object.keys(statsObj).forEach(stat => {
        if (stat.indexOf("revenue") > -1) {
          stats.push({ ...revenue, value: statsObj[stat], type: "dollar" });
          return;
        }
      });
      return stats;
    },
    options: {
      get() {
        return this.revenueTable.options;
      },
      set(value) {
        this.revenueTable.options = value;
        this.paymentsTable.options = value;
      }
    },
    filter: {
      get() {
        return this.revenueTable.filter;
      },
      set(filter) {
        this.revenueTable.filter = filter;
        this.paymentsTable.filter = filter;
      }
    }
  },
  watch: {
    stats: {
      deep: true,
      handler(val) {
        this.$emit("stats", val);
      }
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, [
      "showErrorSnackbar",
      "showSuccessSnackbar"
    ]),
    getData() {
      this.revenueTable.loading = true;
      this.revenueTable
        .getData()
        .then(response => {
          if (!response) return;
          this.revenueTable.meta = response.data.meta;
          this.revenueTable.rawItems = response.data[this.revenueTable.key];
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        })
        .finally(() => {
          this.revenueTable.loading = false;
          this.revenueTable.loaded = true;
        });
    },
    async emailData() {
      try {
        await this.revenueTable.emailData();
        this.showSuccessSnackbar({
          message: "Your E-Mail will arrive shortly",
          timeout: 5000
        });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      }
    },
    updateOptions(options) {
      if (this.optionsEquivalence(options, this.options)) {
        return;
      }
      this.options = options;
      this.getData();
    },
    updateFilter(filter) {
      this.filter = filter;
      this.$emit("can-email", this.filter.view !== "all");
      this.revenueTable.resetPage();
      this.getData();
    },
    clearFilter(filter) {
      this.filter[filter] = undefined;
      this.getData();
    }
  }
};
</script>
