<template>
  <v-sheet color="section">
    <v-col>
      <h1 class="text-h5 mb-3">Edit Signer Email</h1>
      <basic-email-input
        v-model="signerEmail"
        data-lpignore="true"
        label="Email"
        prepend-inner-icon="$mdi-email"
        outlined
        dense
        data-testid="signer-edit-email"
        :success="emailValidations.success"
        :error-messages="emailValidations.errorMessages"
        @valid="emailIsValid = $event"
      />
      <v-row class="ma-0">
        <v-spacer />
        <v-btn
          outlined
          @click="closeDialog"
          class="text-none"
          :disabled="saving"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          @click="save"
          class="text-none ml-1"
          data-testid="signer-edit-save"
          :loading="saving"
          >Save</v-btn
        >
      </v-row>
    </v-col>
  </v-sheet>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { updateSignerEmail } from "@/api/signers.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";
export default {
  components: { BasicEmailInput },
  name: "SignerEdit",
  props: {
    $signerEmail: String,
    $signerId: Number
  },
  data() {
    return {
      signerEmail: this.$signerEmail,
      signerId: this.$signerId,
      saving: false,
      emailIsValid: true
    };
  },
  computed: {
    emailValidations() {
      const success = !this.$v.signerEmail.$invalid;
      const errorMessages = [];
      if (this.$v.signerEmail.$dirty) {
        if (!this.$v.signerEmail.required) errorMessages.push("Required");
        if (!this.$v.signerEmail.valid) errorMessages.push("Invalid Email");
      }

      return { success, errorMessages };
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    async save() {
      this.saving = true;
      try {
        await updateSignerEmail(this.signerId, this.signerEmail);
        this.closeDialog({ email: this.signerEmail });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.saving = false;
      }
    }
  },
  validations() {
    return {
      signerEmail: {
        required,
        valid: () => this.emailIsValid === true
      }
    };
  }
};
</script>
