<template>
  <v-card
    color="accent"
    :height="height"
    :style="{ overflow: inGroup ? 'auto !important' : null }"
  >
    <template v-if="!inGroup">
      <v-card-title class="px-4 white--text"> BackNine News Feed </v-card-title>
      <v-card-subtitle class="white--text">
        Register for an upcoming webinar and view our latest articles!
      </v-card-subtitle>
    </template>
    <v-card-text class="pa-0">
      <v-card
        flat
        style="overflow: auto !important"
        :style="{ height: itemsHeight }"
        color="transparent"
        id="scroll-list"
      >
        <div v-if="fetching" key="loader">
          <v-card v-for="(n, i) in 4" class="ma-3" elevation="6" :key="i">
            <v-skeleton-loader type="list-item-three-line" />
          </v-card>
          <v-card class="ma-3" elevation="6">
            <v-skeleton-loader type="list-item-three-line" />
          </v-card>
          <v-card class="ma-3" elevation="6">
            <v-skeleton-loader type="list-item-three-line" />
          </v-card>
          <v-card class="ma-3" elevation="6">
            <v-skeleton-loader type="list-item-three-line" />
          </v-card>
        </div>

        <div v-else-if="items.length" key="items" id="list">
          <template v-for="({ webinar, article, type }, index) in items">
            <feed-item
              v-if="type === 'webinar'"
              :class="{ 'mb-4': index === items.length - 1 }"
              :href="webinar.registrationUrl"
              :key="index"
            >
              <template #type> Webinar </template>
              <template #subject> {{ webinar.subject }} </template>
              <template #subtitle>
                {{ webinar.startDate }} {{ webinar.startTimeOfDay }} -
                {{ webinar.endTimeOfDay }}
              </template>
              <template #content>
                {{ webinar.preview }}
              </template>
              <template #c2a> Register Today </template>
            </feed-item>
            <feed-item
              v-else-if="type === 'article'"
              :key="index"
              :class="{ 'mb-4': index === items.length - 1 }"
              :to="{
                name: 'HelpCenterArticleViewer',
                params: { id: article.id }
              }"
            >
              <template #type> Article </template>
              <template #subject> {{ article.name }} </template>
              <template #subtitle>
                <v-row class="ma-0 mb-3 flex-nowrap" align="center">
                  <v-avatar size="32" class="mr-3">
                    <v-img :src="article.avatar" />
                  </v-avatar>
                  <div :class="{ 'grey--text': $vuetify.theme.dark }">
                    {{ article.author }}
                    <br />
                    <span class="grey--text">
                      {{ article.timeToRead }} min read
                    </span>
                  </div>
                </v-row>
              </template>
              <template #content> {{ article.preview }} </template>
              <template #c2a> Read this Article </template>
            </feed-item>
          </template>
          <feed-item :to="{ name: 'HelpCenter' }">
            <template #subject> View Help Center </template>
            <template #subtitle> Look for more articles </template>
            <template #c2a> Check out the Help Center </template>
          </feed-item>
        </div>
        <v-card v-else class="ma-3" elevation="6" key="no-webinars">
          <v-card-title
            class="mb-3"
            style="font-weight: 500; letter-spacing: 0.3px; font-size: 1.25rem"
          >
            No Recent Updates
          </v-card-title>
        </v-card>
      </v-card>
    </v-card-text>
    <v-row
      v-if="canScroll"
      style="position: absolute; bottom: 12px; right: 12px"
      key="more-to-see"
      class="text-center width-full white--text accent py-1"
      align="center"
      justify="center"
    >
      <div class="ml-1 pb-1" style="font-size: 12px; position: relative">
        <div style="display: grid; position: absolute; left: -15px">
          <v-icon x-small style="color: inherit">$mdi-mouse</v-icon>
          <v-icon x-small style="color: inherit"> $mdi-chevron-down </v-icon>
        </div>
        Scroll for more
      </div>
    </v-row>
  </v-card>
</template>

<script>
import FeedItem from "@/components/home/FeedItem.vue";
import { getWebinars } from "@/api/webinars.service";
import { getArticles } from "@/api/help-desk.service";

export default {
  components: {
    FeedItem
  },
  props: {
    height: { type: String, required: true },
    inGroup: Boolean
  },
  data: () => ({
    webinars: [],
    articles: [],
    fetching: false,
    canScroll: false
  }),
  created() {
    this.init();
  },
  computed: {
    itemsHeight() {
      if (this.canScroll) {
        return this.inGroup ? "calc(30rem - 72px)" : "calc(30rem - 122px)";
      }
      return this.inGroup ? "calc(30rem - 48px)" : "calc(30rem - 86px)";
    },
    items() {
      const mixinSorting = [];

      const articles = [...this.articles];
      this.webinars.forEach(webinar => {
        mixinSorting.push({ type: "webinar", webinar });
        if (articles.length)
          mixinSorting.push({ type: "article", article: articles.shift() });
      });
      mixinSorting.push(
        ...articles.map(article => ({ type: "article", article }))
      );
      return mixinSorting;
    },
    feedKey() {
      const articlesKey = this.articles.map(({ id }) => `article-${id}`);
      const webinarsKey = this.webinars.map(
        ({ registrationUrl }) => `webinar-${registrationUrl}`
      );
      return [...articlesKey, ...webinarsKey];
    }
  },
  methods: {
    async init() {
      await this.fetchAll();
      this.setCanScroll();
    },
    setCanScroll() {
      const visibleList = document.getElementById("scroll-list").offsetHeight;
      const listFullHeight = document.getElementById("list")?.offsetHeight || 0;
      this.canScroll = visibleList <= listFullHeight;
    },
    async fetchAll() {
      this.fetching = true;
      await Promise.all([this.fetchWebinars(), this.fetchArticles()]);
      this.fetching = false;
    },
    async fetchArticles() {
      try {
        const articles = await getArticles({ sort: { updated_at: "desc" } });
        this.articles.push(
          ...articles
            .filter(({ published }) => published)
            .map(article => ({
              ...article,
              preview: article.generatePreview()
            }))
        );
      } catch (e) {
        // still do nothing
      }
    },
    async fetchWebinars() {
      try {
        const webinars = await getWebinars();
        this.webinars.push(...webinars);
        this.webinars.sort(
          (a, b) =>
            new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
        );
      } catch (e) {
        // do nothing?
      }
    }
  }
};
</script>
