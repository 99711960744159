<template>
  <v-card>
    <v-card-text>
      <v-data-table
        must-sort
        data-testid="leads-table"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :server-items-length="table.meta.value.total"
        :loading="table.loading.value"
        :options="table.options.value"
        :footer-props="footerProps"
        @update:options="updateOptions"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5">Leads</h1>
            <v-spacer />
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  data-testid="leads-table-refresh"
                  @click="getData"
                >
                  <v-icon>$mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
          <generic-table-filter-header
            :value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>
        <template #[`item.email`]="{ item }">
          <a :href="`mailto:${item.email}`"> {{ item.email }} </a>
        </template>
        <template #[`item.phone`]="{ item }">
          <a
            :href="`tel:${item.phone}`"
            data-outbound-type="Lead"
            :data-outbound-id="item.additional.id"
            :data-outbound-number="item.phone"
          >
            {{ item.phone }}
          </a>
        </template>
        <template #[`item.name`]="{ item }">
          <v-btn
            color="primary"
            class="text-none"
            depressed
            small
            :to="{ name: 'LeadView', params: { id: item.additional.id } }"
          >
            <v-icon class="mr-1" small> $mdi-human-greeting </v-icon>
            {{ item.name || "Unnamed Lead" }}
          </v-btn>
        </template>
        <template #[`item.followUp`]="{ item }">
          <until-time-formatter :value="item.followUp" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import UntilTimeFormatter from "@/components/shared/formatters/UntilTimeFormatter.vue";
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import { parseErrorMessage } from "@/util/helpers";
import { getLeads } from "@/api/leads.service";
import { LEAD_CATEGORIES } from "@/factories/Lead";
import { states } from "@/data/states";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { computed } from "vue";

const stateOptions = states.map(({ text, full }) => ({
  value: text,
  text: full
}));

useHead({ title: "Leads" });

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      sortFilterMap: "leads.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "E-mail",
      value: "email",
      map: "email",
      sortFilterMap: "leads.email",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Phone",
      value: "phone",
      map: "phone",
      sortFilterMap: "leads.phone_work",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "State",
      value: "state",
      map: "state",
      selectableOptions: stateOptions,
      sortFilterMap: "addresses.state",
      ...TableHeader.IS_AUTOCOMPLETE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Manager",
      value: "manager",
      map: "manager",
      sortKey: "marketing_manager_id",
      sortFilterMap: [
        { key: "marketing_manager_id", value: "marketing_manager_id" }
      ],
      ...TableHeader.IS_MARKETING_MANAGER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Follow Up",
      value: "followUp",
      map: "followUp",
      sortFilterMap: "leads.follow_up_date",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Category",
      value: "category",
      map: "category",
      selectableOptions: LEAD_CATEGORIES,
      sortFilterMap: "leads.category",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    })
  ],
  getData: getLeads,
  options: new TableOptions(["followUp"], [true])
});

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(table.options.value, table.meta.value),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

function updateFilter(filter) {
  table.resetPage();
  table.filter.value = filter;
  getData();
}

function updateOptions(options) {
  if (table.optionsEquivalence(options)) return;
  table.options.value = options;
  getData();
}

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getData();
</script>
