import { getHttpClient } from "@/http-client";
import {
  setAppointmentFromRaw,
  setAppointmentAccessFromRaw,
  setAppointmentHierarchyFromRaw,
  setAppointmentAssignmentFromSearch
} from "@/factories/Appointment";
import { setChatTemplateFromRequest } from "@/factories/ChatTemplate";
import { useInstanceStore } from "@/stores/instance";

const baseUrl = "api/boss/appointments";

export async function getAppointments(params, cancelToken) {
  params.append("model", "appointments");
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!response?.data) return;
  return {
    items: response.data.appointments.map(setAppointmentTableItemFromRaw),
    meta: response.data.meta,
    stats: response.data.meta.stats
  };
}

export const emailAppointments = params => {
  return getHttpClient().post(`${baseUrl}/email`, params);
};

export const getAppointment = async id => {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  return setAppointmentFromRaw(data.appointment);
};

export const getCode = async (advisorId, advisorType, carrierId) => {
  const body = {
    advisor: { id: advisorId, type: advisorType },
    carrier: carrierId
  };

  const { data } = await getHttpClient().post(`${baseUrl}/codes`, body);
  return data.codes;
};
// pending - entered - life
export const appointmentCreate = async params => {
  const { data } = await getHttpClient().post(`${baseUrl}`, params);
  const {
    lines_of_authority,
    name: appointmentName,
    status,
    id
  } = data.appointment;

  const name = [status, appointmentName, lines_of_authority.join(",")].join(
    " - "
  );
  return {
    id,
    name
  };
};

export const appointmentUpdate = (id, params) => {
  return getHttpClient().put(`${baseUrl}/${id}`, params);
};

export const appointmentDelete = id => {
  return getHttpClient().delete(`${baseUrl}/${id}`);
};

export const downloadContractingForms = (id, includedFormIds) => {
  return getHttpClient().post(`${baseUrl}/${id}/packet`, {
    download: true,
    include: includedFormIds.join(",")
  });
};

export const emailContractingForms = (id, includedFormIds) => {
  return getHttpClient().post(`${baseUrl}/${id}/packet`, {
    email: true,
    include: includedFormIds.join(",")
  });
};

export async function authorizeContractingFormDownloadChannel(
  id,
  { channelName, socketId, callback } = {}
) {
  try {
    const { data } = await getHttpClient().post(
      `${baseUrl}/${id}/authorize-packet-subscription`,
      {
        channel_name: channelName,
        socket_id: socketId
      }
    );
    callback(null, data);
  } catch (e) {
    callback(e, { auth: false });
  }
}

export const updateAppointmentAccess = (
  appointmentId,
  accessId,
  appointment_access
) => {
  return getHttpClient().put(
    `${baseUrl}/${appointmentId}/accesses/${accessId}`,
    {
      appointment_access
    }
  );
};

export const removeAppointmentAccess = (appointmentId, id) => {
  return getHttpClient().delete(`${baseUrl}/${appointmentId}/accesses/${id}`);
};

export const createAppointmentAccess = async (
  appointmentId,
  appointment_access
) => {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${appointmentId}/accesses`,
    {
      appointment_access
    }
  );
  return setAppointmentAccessFromRaw(data.appointment_access);
};

export async function getAppointmentTemplates(appointmentId) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/${appointmentId}/chat_templates`
  );

  return data.map(setChatTemplateFromRequest);
}

export async function getAppointmentTemplate(appointmentId, templateId) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/${appointmentId}/chat_templates/${templateId}`,
    {
      headers: {
        Accept: "application/json"
      }
    }
  );

  return setChatTemplateFromRequest(data);
}

export function downloadAppointmentTemplateUrl(appointmentId, templateId) {
  const instance = useInstanceStore();
  return `${instance.apiUrl}/${baseUrl}/${appointmentId}/chat_templates/${templateId}/download`;
}

export const addAppointmentHierarchy = async (appointmentId, body) => {
  const res = await getHttpClient().post(
    `${baseUrl}/${appointmentId}/hierarchies`,
    {
      appointment_hierarchy: body
    }
  );

  return setAppointmentHierarchyFromRaw(res.data.appointment_hierarchy);
};

export const updateAppointmentHierarchy = (appointmentId, body) => {
  return getHttpClient().put(
    `${baseUrl}/${appointmentId}/hierarchies/update_many`,
    body
  );
};

export const deleteAppointmentHierarchy = (appointmentId, hierarchyId) => {
  return getHttpClient().delete(
    `${baseUrl}/${appointmentId}/hierarchies/${hierarchyId}`
  );
};

export const searchAppointments = (appointmentId, search) => {
  const params = new URLSearchParams();
  params.append("name", search);
  return getHttpClient().get(
    `${baseUrl}/${appointmentId}/search_add_hierarchy`,
    {
      params
    }
  );
};

export const searchAddHierarchy = async (id, name) => {
  const params = new URLSearchParams();
  params.append("name", name);
  const { data } = await getHttpClient().get(
    `${baseUrl}/${id}/search_add_hierarchy`,
    {
      params
    }
  );

  return data.appointments.map(setAppointmentAssignmentFromSearch);
};

function AppointmentTableItem(model = {}) {
  return {
    carrierName: model?.carrierName,
    code: model?.code,
    createdAt: model?.createdAt,
    effectiveDate: model?.effectiveDate,
    followUpDate: model?.followUpDate,
    id: model?.id,
    lastNote: model?.lastNote,
    linesOfAuthority: model?.linesOfAuthority || [],
    name: model?.name,
    ownableName: model?.ownableName,
    ownableId: model?.ownableId,
    ownableType: model?.ownableType,
    status: model?.status,
    terminatedDate: model?.terminatedDate
  };
}

function setAppointmentTableItemFromRaw(raw = {}) {
  const item = AppointmentTableItem();
  item.carrierName = raw?.carrier.name;
  item.code = raw?.code;
  item.createdAt = raw?.created_at;
  item.effectiveDate = raw?.effective_date;
  item.followUpDate = raw?.follow_up_date;
  item.id = raw?.id;
  item.lastNote = raw?.last_note;
  item.linesOfAuthority = raw?.lines_of_authority || [];
  item.name = raw?.name;
  item.ownableName = raw?.ownable?.name;
  item.ownableId = raw?.ownable?.id;
  item.ownableType = raw?.ownable?.type?.model;
  item.status = raw?.status;
  item.terminatedDate = raw?.terminated_date;
  return item;
}
