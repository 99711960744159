<template>
  <v-card flat tile>
    <v-row align="center" class="ma-0">
      <v-col cols="12" md="6">
        <strong style="font-size: 1.2rem">
          {{ party.name }}
        </strong>
        <br />
        <span class="text-capitalize">
          {{ type }}
        </span>
      </v-col>
      <v-col v-if="party.avatarUrl" cols="12" md="6">
        <v-row justify="end" class="ma-0">
          <div>
            <v-img :src="party.avatarUrl" height="150" contain />
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <party-card-items :party="party" :type="type" />
  </v-card>
</template>
<script>
export default {
  props: {
    party: Object,
    type: String
  }
};
</script>
