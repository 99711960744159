<template>
  <chat
    class="pa-3"
    title="Electronic Application Chat"
    ref="chat"
    flat
    :checkboxes="checkboxes"
    :pusher-id="`private-${id}-ElectronicApplication`"
    :messages="messages"
    @new-note="newNote"
    @send-message="sendChatMessage"
    @remove-message="removeFromMessages"
  />
</template>

<script>
import Chat from "@/components/shared/chat/Chat.vue";
import {
  setNoteFromRequest,
  Note,
  NoteToCreateRequest
} from "@/factories/NoteFactory";
import { parseErrorMessage } from "@/util/helpers";
import { mapActions, mapState } from "pinia";
import { createNote } from "@/api/notes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useEappViewStore } from "@/stores/eapp-view";
export default {
  components: {
    Chat
  },
  data() {
    return {
      messages: []
    };
  },
  created() {
    const messages = [...this.notes];
    messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    const name = this.insured?.name ? this.insured.name + "'s" : "this";
    const defaultMessage = {
      note: `Hi ${this.loginable.name}, you can make notes on ${name} Electronic Application here. These notes are not visible by clients.`,
      ownable: { name: "BackNine" }
    };
    messages.unshift(defaultMessage);

    this.messages.push(...messages);
  },
  computed: {
    ...mapState(useEappViewStore, ["notes", "insured", "id"]),
    ...mapState(useUserStore, ["loginable"]),
    checkboxes() {
      return [
        {
          key: "sendEmail",
          label: "Send Email",
          default: true,
          dataTestid: "email"
        }
      ];
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    removeFromMessages(message) {
      const index = this.messages.findIndex(({ id }) => id === message.id);
      this.messages.splice(index, 1);
    },
    newNote({ note: rawNote }) {
      this.messages.push(setNoteFromRequest(rawNote));
    },
    async sendChatMessage(note) {
      this.$refs.chat.$refs.chatFooter.loading = true;
      const newNote = Note({
        documents: note.files,
        email: note.sendEmail ? 1 : 0,
        phoneCall: Boolean(note.sendPhoneCall),
        note: note.message,
        notableId: this.id,
        notableType: "ElectronicApplication"
      });

      try {
        await createNote(NoteToCreateRequest(newNote));
        this.$refs.chat.$refs.chatFooter.reset();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
      } finally {
        this.$refs.chat.$refs.chatFooter.loading = false;
      }
    }
  }
};
</script>
