<template>
  <v-autocomplete
    v-model="inputValue"
    :item-value="v => `${v.type}-${v.id}`"
    no-filter
    auto-select-first
    autocomplete="off"
    v-bind="$attrs"
    ref="searchRef"
    :data-testid="props.dataTestid"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    @update:list-index="val => (focusedIndex = val)"
    @blur="emit('blur')"
  >
    <template #prepend-inner>
      <slot name="icon" />
    </template>
    <template #item="{ on, attrs, item }">
      <v-list-item
        v-on="on"
        v-bind="{ ...attrs, ...item.attrs }"
        :data-testid="`result-${item.index}`"
      >
        <v-list-item-content>
          <v-list-item-title>
            <div>
              <span
                v-for="(t, index) in item.texts"
                :data-testid="`part-${index}`"
                :key="`part-${t.text}-${index}`"
                :class="t.classes"
                >{{ t.text }}</span
              >
            </div>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="focusedIndex === item.index">
          <p class="caption mb-0">
            Select
            <span class="secondary pa-1" style="border-radius: 4px">
              <v-icon small class="white--text">$mdi-keyboard-return</v-icon>
            </span>
          </p>
        </v-list-item-action>
      </v-list-item>
    </template>
    <template #append-item v-if="props.showNavHelpers">
      <v-sheet elevation="2" style="position: sticky; bottom: 0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <span class="secondary pa-1 mr-1" style="border-radius: 4px">
                <v-icon small class="white--text">$mdi-arrow-up</v-icon>
              </span>
              <span class="secondary pa-1" style="border-radius: 4px">
                <v-icon small class="white--text">$mdi-arrow-down</v-icon>
              </span>
              <span class="caption"> To Navigate </span>
              <span class="secondary pa-1 ml-3" style="border-radius: 4px">
                <v-icon small class="white--text">$mdi-keyboard-return</v-icon>
              </span>
              <span class="caption"> Select</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-sheet>
    </template>
  </v-autocomplete>
</template>

<script setup>
import { searchGlobally } from "@/api/boss.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import {
  ref,
  watch,
  defineProps,
  defineEmits,
  defineExpose,
  toRef,
  computed
} from "vue";

const props = defineProps({
  showNavHelpers: Boolean,
  asRouterLink: Boolean,
  dataTestid: {
    type: String,
    required: false,
    default: null
  },
  value: {
    type: Object,
    required: false,
    default: () => null
  }
});

const emit = defineEmits(["input", "blur"]);

const snackbar = useSnackbarStore();

const focusedIndex = ref(0);
const items = ref([]);
const loading = ref(false);
const search = ref("");
const searchItem = ref(props.value);

const propValue = toRef(props, "value");

if (propValue.value) items.value.push(propValue.value);

let timer;
watch(search, v => {
  if (timer) clearTimeout(timer);
  timer = setTimeout(() => globallySearch(v), 200);
});

const inputValue = computed({
  get: () => {
    if (searchItem.value)
      return `${searchItem.value.type}-${searchItem.value.id}`;
    return null;
  },
  set(v) {
    searchItem.value = items.value.find(i => `${i.type}-${i.id}` === v);
  }
});

watch(searchItem, v => {
  if (propValue.value?.type === v?.type && propValue.value?.id === v?.id)
    return;
  emit("input", searchItem.value);
  emit("update:value", searchItem.value);
});
watch(propValue, v => {
  if (v?.type === searchItem.value?.type && v?.id === searchItem.value?.id)
    return;
  searchItem.value = v;
});

async function globallySearch(value) {
  try {
    if (!value) return;
    if (items.value.some(i => i.text === value)) return;
    if (value.length < 2) return;
    loading.value = true;
    items.value.splice(0, items.value.length);
    const res = await searchGlobally(value);
    if (!res) return;

    focusedIndex.value = 0;
    res.forEach((item, index) => {
      const matchIndex = item.text.toLowerCase().indexOf(value.toLowerCase());
      const texts = [];
      if (matchIndex === -1) {
        texts.push({ text: item.text, classes: "font-weight-light" });
      } else {
        const start = item.text.slice(0, matchIndex);
        const match = item.text.slice(matchIndex, matchIndex + value.length);
        const end = item.text.slice(matchIndex + value.length);

        if (start) {
          texts.push({
            text: start,
            classes: "font-weight-light"
          });
        }
        if (match) {
          texts.push({
            text: match,
            match: true,
            classes: "font-weight-bold"
          });
        }
        if (end) {
          texts.push({
            text: end,
            classes: "font-weight-light"
          });
        }
      }
      const attrs = {};
      if (props.asRouterLink) {
        attrs.to = item.routerLink;
      }
      items.value.push({
        text: item.text,
        type: item.type,
        id: item.id,
        texts,
        index,
        attrs
      });
    });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

const searchRef = ref(null);

function focus() {
  searchRef.value.$refs.input.focus();
}

function clear() {
  inputValue.value = null;
  items.value.splice(0, items.value.length);
}

defineExpose({ focus, clear });
</script>
