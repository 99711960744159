export default function HomeRecentCaseFactory(model = {}) {
  return {
    carrierName: model?.carrierName || "",
    id: model?.id || 0,
    insuredName: model?.insuredName || "",
    productionCreditTotal: model?.productionCreditTotal || 0,
    status: model?.status || "",
    setFromRequest(model = {}) {
      this.carrierName = model?.carrier?.name;
      this.id = model?.id;
      this.insuredName = model?.insured?.name;
      this.productionCreditTotal = model?.production_credit_total;
      this.status = model?.status;
    }
  };
}
