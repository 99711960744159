<template>
  <v-card tile flat>
    <v-card-text class="pa-0">
      <v-row class="ma-0" dense align="center">
        <v-col cols="12" data-testid="insured">
          <v-row justify="center" justify-sm="start" class="ma-0 pa-3">
            <div>
              <strong style="font-size: 1.2rem">
                {{ name }}
              </strong>
              <br />
              {{ title || "Lead" }}
            </div>
          </v-row>
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="ma-0 pa-3 py-1" dense>
        <v-col
          v-for="detail in cardDetails"
          :key="detail.key"
          cols="12"
          md="6"
          lg="3"
          xl="3"
        >
          <component
            :is="detail.component"
            v-bind="detail.props"
            :data-testid="detail.props.title"
            lg="12"
            xl="12"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { timestampFormatter } from "@/util/helpers";
import { useLeadView } from "@/stores/lead-view";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { linkItem, textItem } from "../shared/card-items/card-items";

const {
  email,
  marketingManager,
  phoneWork,
  phoneMobile,
  birthdate,
  followUpDate,
  category,
  id,
  name,
  title
} = storeToRefs(useLeadView());

const cardDetails = computed(() => {
  const details = [];
  if (email.value) {
    details.push(
      linkItem({
        title: "Email",
        href: `mailto:${email.value}`,
        text: email.value
      })
    );
  }
  if (marketingManager.value?.name) {
    details.push(
      textItem({
        title: "Marketing Manager",
        text: marketingManager.value.name
      })
    );
  }
  if (phoneWork.value) {
    details.push(
      linkItem({
        title: "Work Phone",
        href: `tel:${phoneWork.value}`,
        text: phoneWork.value,
        dataOutboundId: id.value,
        dataOutboundNumber: phoneWork.value,
        dataOutboundType: "Lead"
      })
    );
  }

  if (phoneMobile.value) {
    details.push(
      linkItem({
        title: "Mobile Phone",
        href: `tel:${phoneMobile.value}`,
        text: phoneMobile.value,
        dataOutboundId: id.value,
        dataOutboundNumber: phoneMobile.value,
        dataOutboundType: "Lead"
      })
    );
  }

  if (birthdate.value) {
    details.push(
      textItem({
        title: "Birthdate",
        text: timestampFormatter(birthdate.value, "sole-day", "basic")
      })
    );
  }

  if (followUpDate.value) {
    details.push(
      textItem({
        title: "Follow Up Date",
        text: timestampFormatter(followUpDate.value, "sole-day", "basic")
      })
    );
  }

  if (category.value) {
    details.push(
      textItem({
        title: "Category",
        text: category.value
      })
    );
  }

  return details;
});
</script>
