<template>
  <v-row dense>
    <v-col cols="12" md="6">
      <applications-graph all />
    </v-col>
    <v-col cols="12" md="6">
      <case-duration-graph all />
    </v-col>
    <v-col cols="12" md="6">
      <placement-ratio-graph all />
    </v-col>
    <v-col cols="12" :md="user.isGroupFour ? 6 : 12">
      <quote-and-apply-graph all />
    </v-col>
    <v-col cols="12" md="6">
      <completed-e-apps-graph all />
    </v-col>
    <v-col cols="12" md="6">
      <aps-graph all />
    </v-col>
    <v-col cols="12" :md="user.isGroupFour ? 6 : 12">
      <impairment-report all />
    </v-col>
    <v-col cols="12" md="6">
      <instant-decisions-graph all />
    </v-col>
    <v-col v-if="user.isGroupFour" cols="12">
      <production-by-carrier-table all />
    </v-col>
  </v-row>
</template>

<script setup>
import CompletedEAppsGraph from "@/components/reports/CompletedEAppsGraph.vue";
import ApplicationsGraph from "@/components/reports/ApplicationsGraph.vue";
import CaseDurationGraph from "@/components/reports/CaseDurationGraph.vue";
import ApsGraph from "@/components/reports/ApsGraph.vue";
import PlacementRatioGraph from "@/components/reports/PlacementRatioGraph.vue";
import QuoteAndApplyGraph from "@/components/reports/QuoteAndApplyGraph.vue";
import ImpairmentReport from "@/components/reports/ImpairmentReport.vue";
import ProductionByCarrierTable from "@/components/reports/ProductionByCarrierTable.vue";
import InstantDecisionsGraph from "@/components/reports/InstantDecisionsGraph.vue";

import { useUserStore } from "@/stores/user";
import { useHead } from "@unhead/vue";

const user = useUserStore();

useHead({ title: "BackNine Report" });
</script>
