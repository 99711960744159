<template>
  <v-card>
    <v-card-title>
      <template v-if="editing"> Update </template>
      <template v-else> Add </template> Employment History Record
    </v-card-title>
    <v-card-subtitle>
      <template v-if="editing">
        Make any necessary changes to this employment history record and then
        press "Save".
      </template>
      <template v-else>
        Enter information about a current or previous employment history and
        then press "Create"
      </template>
    </v-card-subtitle>
    <v-card-text id="occupation-container">
      <v-row dense class="py-3">
        <v-col cols="12" md="6">
          <date-input
            v-model="occupation.startDate"
            outlined
            dense
            autofocus
            label="Start Date"
            prepend-inner-icon="$mdi-calendar-start"
            data-testid="employment-start-date"
            :disabled="loading"
            :success="startDateValidation.success"
            :error-messages="startDateValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <date-input
            v-model="occupation.endDate"
            outlined
            dense
            label="End Date (Leave Blank if Current)"
            data-testid="employment-end-date"
            :success="Boolean(occupation.endDate)"
            :disabled="loading"
            prepend-inner-icon="$mdi-calendar-end"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            data-lpignore="true"
            v-model="occupation.occupation"
            outlined
            dense
            label="Position"
            prepend-inner-icon="$mdi-card-account-details"
            data-testid="employment-occupation"
            :disabled="loading"
            :success="occupationValidation.success"
            :error-messages="occupationValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            data-lpignore="true"
            v-model="occupation.employerName"
            outlined
            dense
            label="Employer Name"
            prepend-inner-icon="$mdi-domain"
            data-testid="employer-name"
            :disabled="loading"
            :success="employerNameValidation.success"
            :error-messages="employerNameValidation.errorMessages"
          />
        </v-col>

        <v-col cols="12">
          <basic-address-input
            autocomplete
            autofill-placeholder="Employer Address"
            autofill-label="Employer Address"
            data-testid="employer-address"
            :disabled="loading"
            :street-address-model.sync="
              occupation.employerAddress.street_address
            "
            :street-address-validation="streetAddressValidation"
            :city-model.sync="occupation.employerAddress.city"
            :city-validation="cityValidation"
            :state-model.sync="occupation.employerAddress.state"
            :state-validation="stateValidation"
            :zip-model.sync="occupation.employerAddress.zip"
            :zip-validation="zipValidation"
            @autofill="handleAutofill"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        :disabled="loading"
        @click="closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        :loading="loading"
        data-testid="employment-save"
        @click="save"
      >
        <template v-if="editing">Save</template>
        <template v-else>Create</template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import DateInput from "@/components/shared/DateInput.vue";

import {
  Occupation,
  setRawCreateFromOccupation,
  setRawUpdateFromOccupation
} from "@/factories/AgentSettingsFactory";
import { required } from "vuelidate/lib/validators";
import { parseErrorMessage, validationCompute } from "@/util/helpers";
import { mapActions } from "pinia";

import { createOccupation, updateOccupation } from "@/api/occupations.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  mixins: [ActiveSaveMixin],
  props: {
    readonly: Boolean,
    currentOccupation: {
      type: Object,
      required: true
    }
  },
  components: {
    DateInput,
    BasicAddressInput
  },
  data() {
    return {
      occupation: new Occupation(this.currentOccupation),
      loading: false,
      editing: Boolean(this.currentOccupation.id)
    };
  },
  computed: {
    startDateValidation() {
      const success = !this.$v.occupation.startDate.$invalid;
      const errorMessages = [];
      if (!this.$v.occupation.startDate.$dirty)
        return { success, errorMessages };
      if (!this.$v.occupation.startDate.required)
        errorMessages.push("Required");
      return { success, errorMessages };
    },
    occupationValidation() {
      const success = !this.$v.occupation.occupation.$invalid;
      const errorMessages = [];
      if (!this.$v.occupation.occupation.$dirty)
        return { success, errorMessages };
      if (!this.$v.occupation.occupation.required)
        errorMessages.push("Required");
      return { success, errorMessages };
    },
    employerNameValidation() {
      const success = !this.$v.occupation.employerName.$invalid;
      const errorMessages = [];
      if (!this.$v.occupation.employerName.$dirty)
        return { success, errorMessages };
      if (!this.$v.occupation.employerName.required)
        errorMessages.push("Required");
      return { success, errorMessages };
    },
    streetAddressValidation() {
      const model = this.$v.occupation.employerAddress.street_address;
      return validationCompute(model, [
        { key: "required", message: "Required" }
      ]);
    },
    cityValidation() {
      const model = this.$v.occupation.employerAddress.city;
      return validationCompute(model, [
        { key: "required", message: "Required" }
      ]);
    },
    stateValidation() {
      const model = this.$v.occupation.employerAddress.state;
      return validationCompute(model, [
        { key: "required", message: "Required" }
      ]);
    },
    zipValidation() {
      const model = this.$v.occupation.employerAddress.zip;
      return validationCompute(model, [
        { key: "required", message: "Required" }
      ]);
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          this.goToInvalid();
        });
        return;
      }
      this.loading = true;
      let func = this.createOccupation;
      if (this.editing) func = this.updateOccupation;
      try {
        const newOccupation = await func();
        this.closeDialog({ occupation: newOccupation });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    },
    createOccupation() {
      return createOccupation(setRawCreateFromOccupation(this.occupation));
    },
    updateOccupation() {
      return updateOccupation(
        this.occupation.id,
        setRawUpdateFromOccupation(this.occupation)
      );
    },
    goToInvalid() {
      const els = document.getElementsByClassName("v-input error--text");
      if (!els?.length) return;
      this.$vuetify.goTo(els[0], {
        container: "#occupation-container"
      });
    },
    handleAutofill({ street_address, city, state, zip }) {
      this.occupation.employerAddress.street_address = street_address;
      this.occupation.employerAddress.city = city;
      this.occupation.employerAddress.state = state;
      this.occupation.employerAddress.zip = zip;
    }
  },
  validations() {
    return {
      occupation: {
        employerName: { required },
        occupation: { required },
        startDate: { required },
        endDate: {
          required: true // it's optional
        },
        employerAddress: {
          street_address: {
            required
          },
          city: {
            required
          },
          state: {
            required
          },
          zip: {
            required
          }
        }
      }
    };
  }
};
</script>
