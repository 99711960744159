<template>
  <v-row dense style="position: relative">
    <v-col cols="12">
      <v-card flat id="quote-request">
        <generic-table-filter-header
          class="mx-2"
          :show-view-filter="user.isGroupTwoPlus"
          :value="table.filter.value"
          :headers="table.filterHeaders.value"
          :loading="table.loading.value"
          @update="updateFilter"
        />
        <v-card-text>
          <v-data-table
            must-sort
            data-testid="quote-table"
            :headers="table.tableHeaders.value"
            :items="table.mappedItems.value"
            :server-items-length="table.meta.value.total"
            :loading="table.loading.value"
            :options="table.options.value"
            :footer-props="footerProps"
            @update:options="updateOptions"
          >
            <template #[`item.insured`]="{ item }">
              <router-link
                :to="{
                  name: 'QuoteView',
                  params: { id: item.additional.id }
                }"
                class="a"
              >
                {{ item.insured }}
              </router-link>
            </template>
            <template #[`item.createdAt`]="{ item }">
              <timestamp-formatter :value="item.createdAt" />
            </template>
            <template #[`item.followUp`]="{ item }">
              <timestamp-formatter :value="item.followUp" />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";
import { parseErrorMessage } from "@/util/helpers";
import { getQuoteTable } from "@/api/quotes.service";
import { states } from "@/data/states";
import { lineFilterOptions } from "@/data/filter-options";
import { STATUSES } from "@/factories/Quote";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTableStore } from "@/stores/table";
import { storeToRefs } from "pinia";
import { useTable } from "@/composables/table.composable";
import { computed, watch } from "vue";

const { quotesTable } = storeToRefs(useTableStore());
const user = useUserStore();

function generateTable() {
  const headers = [
    new TableHeader({
      text: "Insured",
      value: "insured",
      map: "insured_name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "individuals.name"
    }),
    new TableHeader({
      text: "Agent",
      value: "agent",
      map: "agent_name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "agents.name"
    }),
    new TableHeader({
      text: "State",
      value: "state",
      map: "state",
      selectableOptions: states,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "quotes.state"
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      selectableOptions: STATUSES,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      sortFilterMap: "status"
    }),
    new TableHeader({
      text: "Line",
      value: "line",
      map: "line",
      selectableOptions: lineFilterOptions,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "quotes.line"
    }),
    new TableHeader({
      text: "Assigned To",
      value: "assignedTo",
      map: "assignable.name",
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      sortFilterMap: [
        { key: "assignable_id", value: "id" },
        { key: "assignable_type", value: "type" }
      ]
    }),
    user.isGroupThreePlus
      ? new TableHeader({
          text: "View",
          value: "view",
          map: "view",
          ...TableHeader.IS_SELECT_FILTER_TYPE,
          ...TableHeader.IS_FILTERABLE,
          selectableOptions: user.isGroupTwoPlus
            ? [
                { text: "All Quote Requests", value: "all" },
                { text: "My Quote Requests", value: "my" }
              ]
            : [],
          ...TableHeader.IS_QUERY_FILTER,
          ...TableHeader.IS_ADDITIONAL,
          ...TableHeader.IS_MANDATORY,
          sortFilterMap: "view",
          order: 1
        })
      : null,
    new TableHeader({
      text: "Shared With",
      value: "sharedWith",
      map: "sharedWith",
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      sortFilterMap: [
        { key: "shared_with_id", value: "id" },
        { key: "shared_with_type", value: "type" }
      ]
    }),
    user.isGroupThreePlus
      ? new TableHeader({
          text: "Manager",
          value: "marketingManagerName",
          map: "marketing_manager_name",
          showIf: ({ view }) => view === "all",
          ...TableHeader.IS_STRING_FILTER_TYPE,
          ...TableHeader.IS_FILTERABLE,
          sortFilterMap: "marketing_managers.name"
        })
      : null,
    new TableHeader({
      text: "Created",
      value: "createdAt",
      map: "created_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "quotes.created_at"
    }),
    new TableHeader({
      text: "Follow Up",
      value: "followUp",
      map: "follow_up_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      sortFilterMap: "quotes.follow_up_at"
    })
  ].filter(Boolean);

  let filter = {};
  if (user.isGroupThreePlus) filter.view = "all";
  if (quotesTable.value.filter) {
    Object.keys(quotesTable.value.filter).forEach(key => {
      if (!headers.find(header => header.value === key)) return;
      filter[key] = quotesTable.value.filter[key];
    });
  }

  let options = new TableOptions(["createdAt"], [true]);
  if (Object.keys(quotesTable.value.options).length) {
    options = quotesTable.value.options;
  }

  return useTable({
    filter,
    options,
    headers,
    getData: getQuoteTable
  });
}

const table = generateTable();
//eslint-disable-next-line
const emit = defineEmits(["stats", "get-data"]);

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(table.options.value, table.meta.value),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

const stats = computed(() => {
  const stats = [];
  const conversion = { text: "Conversion", icon: "$mdi-sack-percent" };
  const total = { text: "Total" };
  const statsObj = table.meta.value.stats;
  if (!statsObj) {
    return [
      { ...conversion, type: "loading" },
      { ...total, type: "loading" }
    ];
  }
  Object.keys(statsObj).forEach(stat => {
    if (stat.indexOf("conversion") > -1) {
      stats.push({
        ...conversion,
        value: Math.trunc(statsObj[stat] * 100),
        type: "percent"
      });
      return;
    }
    if (stat.indexOf("total") > -1) {
      stats.push({
        ...total,
        value: Math.trunc(statsObj[stat]),
        type: "number"
      });
      return;
    }
  });
  return stats;
});

watch(stats, val => emit("stats", val));

async function getData() {
  try {
    let additionalFilters = {};
    if (!user.isGroupThreePlus) additionalFilters.view = "my";
    await table.getData(additionalFilters);
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}
function updateOptions(options) {
  if (table.optionsEquivalence(options)) return;
  table.options.value = options;
  quotesTable.value.options = options;
  getData();
}
function updateFilter(filter) {
  table.resetPage();
  table.filter.value = filter;
  quotesTable.value.filter = filter;
  getData();
}

getData();
emit("get-data", getData);
</script>
