<template>
  <v-list-item
    three-line
    link
    :class="{ 'v-list-item--active': active }"
    @click="emit('click')"
  >
    <v-list-item-content>
      <v-list-item-subtitle>
        {{ name }}
      </v-list-item-subtitle>

      <v-row class="pa-3">
        <v-col
          cols="12"
          align="end"
          style="font-size: 0.8rem"
          class="grey--text pa-0 pb-1"
        >
          <v-icon
            v-if="email"
            data-testid="is-emailed"
            x-small
            class="grey--text"
          >
            {{ mdiEmail }}
          </v-icon>

          <span style="font-weight: 400">
            {{ dateStr }}
          </span>
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script setup>
import { mdiEmail } from "@mdi/js";
import differenceInDays from "date-fns/differenceInDays";
import { timestampFormatter } from "@/util/helpers";
import { computed, defineProps, defineEmits, toRefs } from "vue";

const emit = defineEmits(["click"]);
const props = defineProps({
  active: Boolean,
  email: Boolean,
  description: String,
  createdAt: { type: Date, default: null, required: false },
  completedAt: { type: Date, default: null, required: false },
  followUp: { type: Date, default: null, required: false }
});

const { active, email, description, createdAt, completedAt, followUp } =
  toRefs(props);

const txt = document.createElement("div");
txt.innerHTML = description.value;
const name = txt.textContent || txt.innerText;

const dateStr = computed(() => {
  if (completedAt.value) {
    const completedFormatted = timestampFormatter(completedAt.value);
    return `Completed: ${completedFormatted}`;
  }

  const dates = [];
  if (createdAt.value) {
    const elapsedFormatted = differenceInDays(new Date(), createdAt.value);
    dates.push(`${elapsedFormatted} Days Pending`);
  }
  if (followUp.value) {
    const followUpFormatted = timestampFormatter(followUp.value);
    dates.push(`Follow Up: ${followUpFormatted}`);
  }

  return dates.join(" | ");
});
</script>
