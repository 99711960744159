<template>
  <v-row dense>
    <v-col cols="12">
      <h1>BackNine Office Holidays</h1>
    </v-col>
    <v-col
      cols="6"
      md="3"
      sm="4"
      v-for="holiday in holidays"
      :key="holiday.text"
    >
      <v-card height="100%">
        <v-card-title>
          <v-col cols="12" class="grey--text pa-0">
            <timestamp-formatter
              :value="holiday.date"
              parser="sole-day"
              format="full-localize"
            />
          </v-col>
          <timestamp-formatter
            :value="holiday.date"
            parser="sole-day"
            format="full-month-date"
          />
        </v-card-title>
        <v-card-subtitle>
          {{ holiday.text }}
        </v-card-subtitle>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import { getHolidays } from "@/api/boss.service";
import { useHead } from "@unhead/vue";
export default {
  components: {
    TimestampFormatter
  },
  setup() {
    useHead({ title: "Holidays" });
  },
  name: "HolidaysReport",
  data: () => ({ holidays: [] }),
  created() {
    this.fetchHolidays();
  },
  methods: {
    fetchHolidays() {
      getHolidays().then(res => {
        this.holidays = res;
      });
    }
  }
};
</script>

<style scoped></style>
