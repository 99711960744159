import SmokerChartFactory from "@/factories/SmokerChartFactory";
export default function ProductSmokerChart({ id, smokerChart } = {}) {
  return {
    id,
    smokerChart,
    setFromRequest(model = {}) {
      this.id = model?.id;
      this.smokerChart = new SmokerChartFactory();
      this.smokerChart.setFromRequest(model?.smoker_chart);
    },
    toCreateRequest(productId) {
      return {
        product_smoker_chart: {
          smoker_chart_id: this.smokerChart.id,
          product_id: productId
        }
      };
    }
  };
}
