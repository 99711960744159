import PayorFactory from "@/factories/PayorFactory";

export const STATEMENT_TYPES = ["Carrier Statement", "IMO Statement"];

export const ACTIONS_DICT = {
  UPLOAD_ONLY: "upload_only",
  PAYMENTS_ONLY: "payments",
  ENQUEUE: "transactions_and_payments"
};

export const ACTIONS = [
  {
    text: "Create Transaction and Payments",
    value: ACTIONS_DICT.ENQUEUE
  },
  { text: "Create Payments", value: ACTIONS_DICT.PAYMENTS_ONLY },
  { text: "No Action", value: ACTIONS_DICT.UPLOAD_ONLY }
];

export default function StatementFactory(model = {}) {
  return {
    amount: model?.amount || null,
    deletable: model?.deletable || null,
    document: model?.document || {},
    errorsDocument: model?.errorsDocument || null,
    id: model?.id || null,
    payor: new PayorFactory(model?.payor),
    premium: model?.premium || null,
    premiumSum: model?.premiumSum || null,
    statementDate: model?.statementDate || null,
    statementType: model?.statementType || null,
    status: model?.status || null,
    commissionSum: model?.commissionSum || null,
    nonImportableDocument: model?.nonImportableDocument || null,

    setFromRequest(model = {}) {
      this.amount = model?.amount;
      this.deletable = model?.deletable;
      this.errorsDocument = model?.errors_document;
      this.commissionSum = model?.commission_sum;
      this.id = model?.id;
      this.premium = model?.premium;
      this.premiumSum = model?.premium_sum;
      this.statementDate = model?.statement_date;
      this.statementType = model?.statement_type;
      this.status = model?.status;
      this.document = model?.document;
      this.nonImportableDocument = model?.non_importable_document;

      this.payor = new PayorFactory();
      this.payor.setFromRequest(model?.payor);
    },

    generateAttributeMap() {
      return {
        amount: { amount: this.amount },
        premium: { premium: this.premium }
      };
    },

    getAttributeRequestValue(attribute) {
      const map = this.generateAttributeMap();
      return { statement: map[attribute] };
    }
  };
}

export function StatementCreate(model = {}) {
  return {
    statementDate: model?.statementDate || null,
    payor: model?.payor || null,
    premium: model?.premium || null,
    amount: model?.amount || null,
    statementType: model?.statementType || null,
    file: model?.file || null,
    optionalPdf: model?.pdfFile || null,
    action: model?.action || null
  };
}

export function StatementCreateToRequest(statementCreate) {
  const documents = [{ file: statementCreate.file, category: "Transactions" }];
  if (statementCreate.optionalPdf) {
    documents.push({
      file: statementCreate.optionalPdf,
      category: "Non Importable Transactions"
    });
  }
  return {
    amount: statementCreate.amount,
    payor_id: statementCreate.payor.id,
    premium: statementCreate.premium,
    statement_date: statementCreate.statementDate,
    statement_type: statementCreate.statementType,
    import_action: statementCreate.action || ACTIONS_DICT.UPLOAD_ONLY,
    documents_attributes: documents
  };
}
