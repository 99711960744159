<template>
  <v-card>
    <v-card-title> Create Quote Request </v-card-title>
    <v-card-subtitle>
      Use this form for BackNine's sales desk to run your quote.
    </v-card-subtitle>
    <v-card-text>
      <v-row class="ma-0">
        <v-col cols="12">
          <v-textarea
            v-model="description"
            label="What changes to the current quote would you like?"
            outlined
            dense
            data-testid="synthesize-description"
            prepend-inner-icon="$mdi-text"
            :disabled="loading"
            :success="descriptionValidation.success"
            :error-messages="descriptionValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <file-drag-and-drop
            v-model="files"
            :disabled="loading"
            :success="Boolean(files.length)"
            multiple
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        :disabled="loading"
        @click="closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        :loading="loading"
        data-testid="synthesize-create"
        @click="create"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";

import { createQuote } from "@/api/quotes.service";

import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

export default {
  components: {
    FileDragAndDrop
  },
  props: {
    caseId: {
      type: Number,
      required: false,
      default: 0
    },
    eAppId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: () => ({ description: "", files: [], loading: false }),
  computed: {
    descriptionValidation() {
      const success = !this.$v.description.$invalid;
      const errorMessages = [];
      if (!this.$v.description.$dirty) return { success, errorMessages };
      if (!this.$v.description.required) errorMessages.push("Required");
      return { success, errorMessages };
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    async create() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      const body = {
        case_id: undefined,
        eapp_id: undefined,
        quote: {
          description: this.description,
          documents_attributes: this.files
        }
      };

      if (this.caseId) {
        body.case_id = this.caseId;
      } else if (this.eAppId) {
        body.eapp_id = this.eAppId;
      }

      try {
        const quoteId = await createQuote(body);
        this.$router.push({
          name: "QuoteView",
          params: {
            id: quoteId
          }
        });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    }
  },
  validations: {
    description: {
      required: val => Boolean(val)
    }
  }
};
</script>
