<template>
  <v-skeleton-loader type="card@4" v-if="loading" />
  <div v-else>
    <v-row no-gutters>
      <v-col cols="12">
        <approved-domain-card />
      </v-col>
      <v-col cols="12">
        <approved-domain-actions />
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-card color="section" flat tile>
          <v-tabs v-model="activeTab">
            <v-tab
              v-for="tab in tabs"
              :key="`${tab.text}-tab`"
              :data-testid="`tab-${tab.page}`"
              class="text-none"
            >
              <v-icon class="mr-1"> {{ tab.icon }} </v-icon>
              {{ tab.text }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab" touchless>
            <v-tab-item v-for="tab in tabs" :key="`${tab.text}-body`">
              <component :is="tab.component" @refresh="getApprovedDomainInfo" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import ApprovedDomainCard from "@/components/approved-domain/ApprovedDomainCard.vue";
import ApprovedDomainActions from "@/components/approved-domain/ApprovedDomainActions.vue";
import ApprovedDomainSettings from "@/components/approved-domain/ApprovedDomainSettings.vue";
import ApprovedDomainTheme from "@/components/approved-domain/ApprovedDomainTheme.vue";
import ApprovedDomainCarriers from "@/components/approved-domain/ApprovedDomainCarriers.vue";
import ApprovedDomainAccess from "@/components/approved-domain/ApprovedDomainAccess.vue";
import ApprovedDomainMarketing from "@/components/approved-domain/ApprovedDomainMarketing.vue";
import ApprovedDomainCommissions from "@/components/approved-domain/ApprovedDomainCommissions.vue";
import ApprovedDomainAnalytics from "@/components/approved-domain/ApprovedDomainAnalytics.vue";
import ApprovedDomainProducts from "@/components/approved-domain/ApprovedDomainProducts.vue";
import ApprovedDomainRefer from "@/components/approved-domain/ApprovedDomainRefer.vue";
import ApprovedDomainIntegrations from "@/components/approved-domain/ApprovedDomainIntegrations.vue";

import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";

import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";
import { useUserStore } from "@/stores/user";
import { useHead } from "@unhead/vue";
import {
  markRaw,
  defineProps,
  ref,
  onBeforeUnmount,
  computed,
  watch,
  toRefs
} from "vue";
import { useRouter, useVuetify } from "@/composables/compatible.composables";
import {
  mdiCogs,
  mdiCube,
  mdiDomain,
  mdiFileDocumentEdit,
  mdiListBox,
  mdiPalette,
  mdiPoll,
  mdiSetSplit,
  mdiShieldAccount,
  mdiShopping
} from "@mdi/js";

const props = defineProps({
  id: {
    type: [Number, String],
    required: true
  },
  page: {
    type: String,
    required: false,
    default: ""
  },
  scrollTo: {
    type: String,
    required: false,
    default: ""
  }
});

const user = useUserStore();
const instance = useInstanceStore();
const approvedDomain = useApprovedDomainView();
const snackbar = useSnackbarStore();

const router = useRouter();
const vuetify = useVuetify();

const { breadcrumb } = storeToRefs(instance);
const { isGroupFour } = storeToRefs(user);
const { domain, isSignUpOrSelectAgent, isReferral } =
  storeToRefs(approvedDomain);

const head = useHead({ title: "Website" });

const loading = ref(false);
const activeTab = ref(0);
const { page: pageProp, scrollTo: scrollToProp } = toRefs(props);

onBeforeUnmount(() => (breadcrumb.value = ""));

const tabs = computed(() => {
  const tabs = [];
  tabs.push({
    icon: mdiFileDocumentEdit,
    text: "Details",
    component: markRaw(ApprovedDomainSettings),
    page: "details"
  });
  if (isReferral.value) {
    tabs.push({
      icon: mdiListBox,
      text: "Refer Settings",
      component: markRaw(ApprovedDomainRefer),
      page: "refer-settings"
    });
  }

  if (!isSignUpOrSelectAgent.value) {
    tabs.push(
      {
        icon: mdiPalette,
        text: "Theme & Colors",
        component: markRaw(ApprovedDomainTheme),
        page: "theme"
      },
      {
        icon: mdiCogs,
        text: "Integrations",
        component: markRaw(ApprovedDomainIntegrations),
        page: "integrations"
      },
      {
        icon: mdiDomain,
        text: "Carriers",
        component: markRaw(ApprovedDomainCarriers),
        page: "carriers"
      }
    );
  }

  tabs.push({
    icon: mdiShieldAccount,
    text: "Access",
    component: markRaw(ApprovedDomainAccess),
    page: "access"
  });

  if (!isSignUpOrSelectAgent.value) {
    tabs.push({
      icon: mdiSetSplit,
      text: "Commissions",
      component: markRaw(ApprovedDomainCommissions),
      page: "commissions"
    });
  }

  tabs.push(
    {
      icon: mdiShopping,
      text: "Marketing",
      component: markRaw(ApprovedDomainMarketing),
      page: "marketing"
    },
    {
      icon: mdiPoll,
      text: "Analytics",
      component: markRaw(ApprovedDomainAnalytics),
      page: "analytics"
    }
  );

  if (isGroupFour.value && !isSignUpOrSelectAgent.value) {
    tabs.push({
      icon: mdiCube,
      text: "Products",
      component: markRaw(ApprovedDomainProducts),
      page: "products"
    });
  }

  return tabs;
});

watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  handleQueryChange();
});

function handleQueryChange(initializing = false) {
  let page;
  if (initializing) {
    const tempPage = tabs.value.findIndex(v => v.page === pageProp.value);

    if (tempPage > -1) {
      page = tabs.value[tempPage].page;
      activeTab.value = tempPage;
    }
  } else {
    page = tabs.value[activeTab.value].page;
  }

  if (pageProp.value === page || !page) return;
  router.replace({ query: { page: page } });
}

function handleFocus() {
  if (!scrollToProp.value) return;
  const el = document.getElementById(scrollToProp.value);
  if (!el) return;
  el.parentNode.scrollIntoView({ behavior: "smooth", block: "center" });
  el.parentNode.classList.add("focusable-div");
  const focusClass = vuetify.theme.dark
    ? "focused-div-dark"
    : "focused-div-light";

  el.parentNode.classList.add(focusClass);
  setTimeout(() => {
    el.parentNode.classList.remove(focusClass);
  }, 4000);
}

async function getApprovedDomainInfo() {
  loading.value = true;
  try {
    await approvedDomain.initializeApprovedDomain(props.id);
    breadcrumb.value = domain.value;
    head.patch({ title: domain.value });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: 5000
    });
    router.replace({ name: "Home" });
  } finally {
    loading.value = false;
  }
}

getApprovedDomainInfo().then(async () => {
  handleQueryChange(true);
  await new Promise(r => setTimeout(r, 500));
  handleFocus();
});
</script>
