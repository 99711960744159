import { setNoteFromRequest } from "@/factories/NoteFactory";
import { listToSentence } from "@/util/helpers";
import { setDocumentFromRequest } from "@/factories/DocumentFactory";

export const APPOINTMENT_STATUSES = [
  "Active",
  "Active - Pending Requirements",
  "Closed",
  "Entered",
  "Just In Time",
  "Pending",
  "Terminated"
];

export const LINE_CODES = {
  ANNUITY: "Annuity",
  DISABILITY: "Disability",
  LIFE: "Life",
  LINKED_BENEFIT: "Linked Benefit",
  LTC: "LTC",
  VARIABLE_LIFE: "Variable Life"
};

export const LINES = Object.values(LINE_CODES);

export function Appointment(model = {}) {
  return {
    advisor: model?.advisor || {}, // add properties
    agents: model?.agents || [],
    annualized: model?.annualized || false,
    applicationSignedDate: model?.applicationSignedDate || null,
    appointment: model?.appointment || {},
    appSignDate: model?.appSignDate || null,
    assignment: AppointmentAssignment(model?.assignment || {}),
    carrier: Carrier(model?.carrier || {}),
    carrierAgreementType: model?.carrierAgreementType || null,
    cases: model?.cases || [],
    code: model?.code || null,
    commissionable: model?.commissionable || false,
    compBuilder: model?.compBuilder || null,
    createdAt: model?.createdAt || null,
    daysPending: model?.daysPending || null,
    description: model?.description || null,
    documents: model?.documents || [],
    dueDate: model?.dueDate || null,
    effectiveDate: model?.effectiveDate || null,
    followUpDate: model?.followUpDate || null,
    hierarchy: model?.hierarchy || [],
    contractingForms: model?.contractingForms || [],
    contractingErrors: model?.contractingErrors || "",
    id: model?.id || null,
    insuredName: model?.insuredName || null,
    linesOfAuthority: model?.linesOfAuthority || [],
    ltcRider: model?.ltcRider || false,
    name: model?.name || null,
    nextStep: model?.nextStep || null,
    notes: model?.notes || [],
    options: model?.options || {},
    ownable: AppointmentOwnable(model?.ownable || {}),
    processWorkerNotTriggeredReason:
      model?.processWorkerNotTriggeredReason || "",
    productId: model?.productId || null,
    room: model?.room || [],
    signer: model?.signer || {},
    states: model?.states || [],
    status: model?.status || null,
    submitted: model?.submitted || null,
    terminatedDate: model?.terminatedDate || null,
    timeline: model?.timeline || []
  };
}

export function setAppointmentFromRaw(rawModel = {}) {
  const appointment = Appointment();

  appointment.carrierAgreementType = rawModel?.carrier_agreement_type;
  appointment.commissionable = Boolean(rawModel?.commissionable);
  appointment.signer = rawModel?.signer;
  appointment.appointment = rawModel?.appointment;
  appointment.annualized = rawModel?.annualized;
  appointment.applicationSignedDate = rawModel?.app_sign_date;
  appointment.code = rawModel?.code;
  appointment.compBuilder = rawModel?.comp_builders?.id;
  appointment.daysPending = rawModel?.days_pending;
  appointment.description = rawModel?.description;
  appointment.dueDate = rawModel?.due_date;
  appointment.effectiveDate = rawModel?.effective_date;
  appointment.id = rawModel?.id;
  appointment.insuredName = rawModel?.insured_name;
  appointment.ltcRider = rawModel?.ltc_rider;
  appointment.name = rawModel?.name;
  appointment.options = rawModel?.options;
  appointment.processWorkerNotTriggeredReason =
    rawModel?.process_worker_not_triggered_reason;
  appointment.productId = rawModel?.product_id;
  appointment.states = rawModel?.states || [];
  appointment.status = rawModel?.status;
  appointment.submitted = rawModel?.submitted;
  appointment.terminatedDate = rawModel?.terminated_date;
  appointment.createdAt = rawModel?.created_at;
  appointment.followUpDate = rawModel?.follow_up_date;
  appointment.appSignDate = rawModel?.app_sign_date;

  appointment.linesOfAuthority = [];
  if (Array.isArray(rawModel?.lines_of_authority)) {
    appointment.linesOfAuthority = rawModel.lines_of_authority;
  }

  if (rawModel?.folder?.errors) {
    appointment.contractingErrors = listToSentence(rawModel.folder.errors);
  }

  appointment.carrier = setCarrierFromRaw(rawModel?.carrier);
  appointment.ownable = setAppointmentOwnableFromRaw(rawModel?.ownable);
  appointment.assignment = setAppointmentAssignmentFromRaw(
    rawModel?.assignment
  );

  appointment.notes = [];
  if (Array.isArray(rawModel?.notes)) {
    rawModel.notes.forEach(rawNote => {
      appointment.notes.push(setNoteFromRequest(rawNote));
    });
    appointment.notes.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
  }

  appointment.room = [];
  if (Array.isArray(rawModel?.room)) {
    rawModel.room.forEach(rawAccess => {
      appointment.room.push(setAppointmentAccessFromRaw(rawAccess));
    });
  }

  appointment.contractingForms = [];
  if (Array.isArray(rawModel?.folder?.parts)) {
    rawModel.folder.parts.forEach(rawForm => {
      appointment.contractingForms.push(
        setAppointmentContractingFormFromRequest(rawForm)
      );
    });
  }

  appointment.documents = [];
  if (Array.isArray(rawModel?.documents)) {
    rawModel.documents.forEach(rawDoc => {
      appointment.documents.push(setDocumentFromRequest(rawDoc));
    });

    appointment.documents.sort((a, b) => a.name.localeCompare(b.name));
  }

  appointment.hierarchy = [];
  if (Array.isArray(rawModel?.hierarchies)) {
    rawModel.hierarchies.forEach(rawHierarchy => {
      appointment.hierarchy.push(setAppointmentHierarchyFromRaw(rawHierarchy));
    });
  }

  appointment.agents = [];
  if (Array.isArray(rawModel?.agents)) {
    rawModel.agents.forEach(rawAgent => {
      appointment.agents.push(setSubAgentFromRequest(rawAgent));
    });
  }

  appointment.cases = [];
  if (Array.isArray(rawModel?.cases)) {
    rawModel.cases.forEach(rawCase => {
      appointment.cases.push(setAppointmentCaseFromRequest(rawCase));
    });
  }

  appointment.timeline = [];
  if (Array.isArray(rawModel?.timeline)) {
    rawModel.timeline.forEach(rawTimelineItem => {
      appointment.timeline.push(setTimelineItemFromRequest(rawTimelineItem));
    });
  }

  return appointment;
}

export function AppointmentCreate(model = {}) {
  return {
    advisor: model?.advisor || null,
    compBuilder: model?.compBuilder || null,
    productId: model?.productId || null,
    carrier: model?.carrier || null,
    states: model?.states || [],
    applicationSignedDate: model?.applicationSignedDate || null,
    disableAutoSubmit: model?.disableAutoSubmit || false,
    effectiveDate: model?.effectiveDate || null,
    insuredName: model?.insuredName || null,
    createForAssignable: model?.createForAssignable || false,
    createForSigner: model?.createForSigner || false
  };
}

export function AppointmentCreateRequestBody(model = {}) {
  const appointment = AppointmentCreate(model);

  return {
    advisor_id: appointment.advisor.id,
    advisor_type: appointment.advisor.type?.model || appointment.advisor.type,
    comp_builder_id: appointment.compBuilder,
    product_id: appointment.productId,
    carrier_id: appointment.carrier.id,
    states: appointment.states,
    disable_auto_submit: appointment.disableAutoSubmit,
    app_sign_date: appointment.applicationSignedDate,
    effective_date: appointment.effectiveDate,
    app_sign_date_form: appointment.applicationSignedDate,
    insured_name: appointment.insuredName,
    create_for_assignable: appointment.createForAssignable,
    create_for_signer: appointment.createForSigner,
    name: "Pending"
  };
}

function AppointmentContractingForm(model = {}) {
  return {
    id: model?.id,
    category: model?.category,
    default: model?.default,
    tag: model?.tag,
    name: model?.name
  };
}

function setAppointmentContractingFormFromRequest(rawForm = {}) {
  const contractingForm = AppointmentContractingForm();

  contractingForm.name = rawForm.name;
  contractingForm.id = rawForm.id;
  contractingForm.default = rawForm.default;
  contractingForm.tag = rawForm.tag;
  contractingForm.category = rawForm.category.replace(
    /^Agent Contracting /,
    ""
  );

  return contractingForm;
}

function AppointmentOwnable(model = {}) {
  return {
    avatar: model?.avatar || null,
    birthdate: model?.birthdate || null,
    contractingCompleted: model?.contractingCompleted || null,
    description: model?.description || null,
    email: model?.email || null,
    hasAml: model?.hasAml || false,
    hasContracting: model?.hasContracting || false,
    hasContractingAnswerDocuments:
      model?.hasContractingAnswerDocuments || false,
    hasEft: model?.hasEft || false,
    hasEo: model?.hasEo || false,
    hasLicenses: model?.hasLicenses || false,
    id: model?.id || null,
    identifier: model?.identifier || null,
    legalName: model?.legalName || null,
    name: model?.name || null,
    phoneWork: model?.phoneWork || null,
    primaryPhone: model?.primaryPhone || null,
    signerId: model?.signerId || null,
    signerName: model?.signerName || null,
    signerType: model?.signerType || null,
    status: model?.status || null,
    title: model?.title || null,
    type: model?.type || null
  };
}

function setAppointmentOwnableFromRaw(rawModel = {}) {
  const appointmentOwnable = AppointmentOwnable();
  appointmentOwnable.birthdate = rawModel?.date || rawModel?.birthdate;
  appointmentOwnable.contractingCompleted = rawModel?.contracting_completed;
  appointmentOwnable.description = rawModel?.description;
  appointmentOwnable.email = rawModel?.email;
  appointmentOwnable.hasAml = rawModel?.has_aml;
  appointmentOwnable.hasContracting = rawModel?.has_contracting;
  appointmentOwnable.hasContractingAnswerDocuments =
    rawModel?.has_contracting_answer_documents;
  appointmentOwnable.hasEft = rawModel?.has_eft;
  appointmentOwnable.hasEo = rawModel?.has_eo;
  appointmentOwnable.hasLicenses = rawModel?.has_licenses;
  appointmentOwnable.id = rawModel?.id;
  appointmentOwnable.identifier = rawModel?.identifier;
  appointmentOwnable.legalName = rawModel?.legal_name;
  appointmentOwnable.name = rawModel?.name;
  appointmentOwnable.phoneWork = rawModel?.phone_work;
  appointmentOwnable.primaryPhone = rawModel?.primary_phone;
  appointmentOwnable.title = rawModel?.title;
  appointmentOwnable.type = rawModel?.type?.model || rawModel?.type;

  if (rawModel?.signer) {
    appointmentOwnable.signerId = rawModel?.signer?.id;
    appointmentOwnable.signerName = rawModel?.signer?.name;
    appointmentOwnable.signerType = rawModel?.signer?.type?.model;
  }

  return appointmentOwnable;
}

function AppointmentAccess(model = {}) {
  return {
    advisorAccess: model?.advisorAccess || null,
    appointmentAccessId: model?.appointmentAccessId || null,
    appointmentManager: model?.appointmentManager || null,
    avatar: model?.avatar_url || null,
    canBeAppointmentManager: model?.canBeAppointmentManager || false,
    carrierAccess: model?.carrierAccess || null,
    carrierEmail: model?.carrierEmail || null,
    disabled: model?.disabled || false,
    email: model?.email || null,
    id: model?.id || null,
    name: model?.name || null,
    owner: model?.owner || null,
    phone: model?.phone || null,
    type: model?.type || null,
    userEmail: model?.userEmail || null,
    routerLink: model?.routerLink || null
  };
}

export function setAppointmentAccessFromRaw(rawModel = {}) {
  const access = AppointmentAccess();
  access.advisorAccess = rawModel?.advisor_access;
  access.appointmentAccessId = rawModel?.appointment_access_id;
  access.appointmentManager = rawModel?.appointment_manager;
  access.avatar = rawModel?.avatar_url;
  access.canBeAppointmentManager = rawModel?.can_be_appointment_manager;
  access.carrierAccess = rawModel?.carrier_access;
  access.carrierEmail = rawModel?.carrier_email;
  access.disabled = rawModel?.disabled;
  access.email = rawModel?.email;
  access.id = rawModel?.id;
  access.name = rawModel?.name;
  access.owner = rawModel?.owner;
  access.phone = rawModel?.phone;
  access.type = rawModel?.type;
  access.userEmail = rawModel?.user_email;

  if (access.type === "Personnel") {
    access.routerLink = {
      name: "PersonnelView",
      params: {
        id: access.id
      }
    };
  } else if (access.type === "Agency") {
    access.routerLink = {
      name: "AgencyView",
      params: {
        id: access.id
      }
    };
  } else if (access.type === "Agent") {
    access.routerLink = {
      name: "AgentView",
      params: {
        id: access.id
      }
    };
  }

  return access;
}

export function updateAppointmentAccess(appointmentRoom, attribute) {
  return {
    advisorAccess: { advisor_access: appointmentRoom.advisorAccess },
    carrierAccess: { carrier_access: appointmentRoom.carrierAccess },
    carrierEmail: { carrier_email: appointmentRoom.carrierEmail },
    email: { email: appointmentRoom.email }
  }[attribute];
}

function AppointmentAssignment(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    status: model?.status,
    signer: model?.signer,
    ownableId: model?.ownableId,
    ownableName: model?.ownableName
  };
}

function setAppointmentAssignmentFromRaw(rawModel = {}) {
  const assignment = AppointmentAssignment();

  if (!rawModel) return assignment;

  assignment.id = rawModel.id;
  assignment.name = rawModel.name;
  assignment.status = rawModel.status;
  assignment.signer = rawModel.signer;

  if (rawModel.ownable) {
    assignment.ownableId = rawModel.ownable.id;
    assignment.ownableName = rawModel.ownable.name;
  }

  return assignment;
}

export function setAppointmentAssignmentFromSearch(rawModel = {}) {
  const assignment = AppointmentAssignment();

  if (!rawModel) return assignment;

  assignment.id = rawModel.id;
  assignment.name = rawModel.name;
  assignment.status = rawModel.status;
  assignment.signer = rawModel.signer;

  if (rawModel.owner) {
    assignment.ownableId = rawModel.owner.id;
    assignment.ownableName = rawModel.owner.name;
  }

  return assignment;
}

export function AppointmentHierarchy(model = {}) {
  return {
    advisorId: model?.advisorId || null,
    advisorType: model?.advisorType || null,
    advisorName: model?.advisorName || null,
    advisorRouterLink: model?.advisorRouterLink || null,
    appointmentId: model?.appointmentId || null,
    appointmentName: model?.appointmentName || null,
    appointmentRouterLink: model?.appointmentRouterLink || null,
    compBuilderName: model?.compBuilderName || null,
    id: model?.id || null,
    order: model?.order || null
  };
}

export function setAppointmentHierarchyFromRaw(raw = {}) {
  const hierarchy = AppointmentHierarchy();

  hierarchy.compBuilderName = raw.comp_builder_name;
  hierarchy.id = raw.id;
  hierarchy.order = raw.order;

  if (raw?.advisor) {
    hierarchy.advisorId = raw.advisor.id;
    hierarchy.advisorName = raw.advisor.name;
    // legacy key. switch to type when available
    if (raw.advisor.link === "agents") {
      hierarchy.advisorType = "Agents";
      hierarchy.advisorRouterLink = {
        name: "AgentView",
        params: {
          id: hierarchy.advisorId
        }
      };
    } else if (raw.advisor.link === "agencies") {
      hierarchy.advisorType = "Agency";
      hierarchy.advisorRouterLink = {
        name: "AgencyView",
        params: {
          id: hierarchy.advisorId
        }
      };
    }
  }

  if (raw?.appointment) {
    hierarchy.appointmentId = raw.appointment.id;
    hierarchy.appointmentName = raw.appointment.name;
    hierarchy.appointmentRouterLink = {
      name: "AppointmentView",
      params: {
        id: hierarchy.appointmentId
      }
    };
  }

  return hierarchy;
}

function SubAgent(model = {}) {
  return {
    code: model?.code || null,
    appointmentId: model?.appointmentId || null,
    name: model?.name || null,
    compBuilder: model?.compBuilder || null,
    assignmentName: model?.assignmentName || null
  };
}

function setSubAgentFromRequest(raw = {}) {
  const subAgent = SubAgent();
  subAgent.code = raw?.code;
  subAgent.appointmentId = raw?.appointment_id;
  subAgent.name = raw?.name;
  subAgent.compBuilder = raw?.comp_builder;
  subAgent.assignmentName = raw?.assignment?.name;
  return subAgent;
}

function AppointmentCase(model = {}) {
  return {
    insuredName: model?.insuredName || null,
    id: model?.id || null,
    policyNumber: model?.policyNumber || null,
    lineText: model?.lineText || null,
    status: model?.status || null,
    fyPremium: model?.fyPremium || null,
    created: model?.created || null
  };
}

function setAppointmentCaseFromRequest(raw = {}) {
  const appointmentCase = AppointmentCase();

  appointmentCase.insuredName = raw?.insured?.name;
  appointmentCase.id = raw?.id;
  appointmentCase.policyNumber = raw?.policy_number;
  appointmentCase.lineText = raw?.line?.text;
  appointmentCase.status = raw?.status;
  appointmentCase.fyPremium = raw?.first_year;
  appointmentCase.created = raw?.created_at;

  return appointmentCase;
}

function TimelineItem(model = {}) {
  return {
    date: model?.date || null,
    description: model?.description || null
  };
}

function setTimelineItemFromRequest(raw = {}) {
  const item = TimelineItem();
  item.date = raw?.date;
  item.description = raw?.description;
  return item;
}

export function Carrier(model = {}) {
  return {
    avatarUrl: model?.avatarUrl || null,
    id: model?.id || undefined,
    name: model?.name || undefined,
    products: model?.products || [],
    lifePhone: model?.lifePhone || null,
    annuityPhone: model?.annuityPhone || null,
    disabilityPhone: model?.disabilityPhone || null,
    ltcPhone: model?.ltcPhone || null
  };
}

export function setCarrierFromRaw(rawModel = {}) {
  const carrier = Carrier();

  carrier.avatarUrl = rawModel?.avatar_url;
  carrier.id = rawModel?.id;
  carrier.name = rawModel?.name;
  carrier.products = rawModel?.products;

  carrier.lifePhone = rawModel?.phone?.life?.licensing;
  carrier.ltcPhone = rawModel?.phone?.ltc?.licensing;
  carrier.annuityPhone = rawModel?.phone?.annuity?.licensing;
  carrier.disabilityPhone = rawModel?.phone?.disability?.licensing;

  return carrier;
}
