<template>
  <v-autocomplete
    v-model="party"
    autocomplete="false"
    prepend-inner-icon="$mdi-account"
    placeholder="Search Contract Parties"
    outlined
    dense
    return-object
    no-filter
    hide-no-data
    :label="label"
    :item-text="itemTextGenerator"
    :disabled="disabled"
    :search-input.sync="partySearch"
    :success="success"
    :error-messages="errorMessages"
    :items="parties"
    :loading="loading"
    :hint="hint"
    :persistent-hint="persistentHint"
    :hide-details="hideDetails"
    :clearable="clearable"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    ref="contractPartySearch"
    @blur="$emit('blur')"
    @change="$emit('change')"
  >
    <template #message="{ message }">
      <v-row class="pa-3">
        {{ message }}
        <v-spacer />
        <slot name="message" />
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script>
import { searchForContractParty } from "@/api/contract-party.service";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  props: {
    success: Boolean,
    errorMessages: Array,
    value: [Object, String],
    hint: String,
    persistentHint: Boolean,
    disabled: Boolean,
    simple: Boolean,
    superSimple: Boolean,
    hideDetails: Boolean,
    clearable: Boolean,
    label: {
      type: String,
      default: "Contract Party"
    }
  },
  data() {
    let parties = [];
    let party = null;
    if (this.value && Object.keys(this.value).length) {
      parties = [this.value];
      party = this.value;
    }
    return {
      partySearch: "",
      timer: null,
      loading: false,
      parties,
      party
    };
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.party = null;
        this.parties = [];
        return;
      }
      this.party = value;
      this.parties = [value];
    },
    party(value) {
      this.$emit("input", value);
    },
    partySearch(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (
        this.parties.some(val => this.itemTextGenerator(val) === value) &&
        this.parties.length === 1
      ) {
        return;
      }

      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(this.getParties, 400, this);
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async getParties() {
      this.loading = true;
      try {
        this.parties = await searchForContractParty(this.partySearch);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    },
    focus() {
      this.$refs.contractPartySearch.$refs.input.focus();
    },
    itemTextGenerator(value) {
      if (!this.superSimple) return value.name;
      if (!value.lastFour) return value.name;
      return `${value.name} (${value.lastFour})`;
    },
    clear() {
      this.party = null;
      this.parties = [];
    }
  }
};
</script>
