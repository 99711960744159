<template>
  <v-card flat tile>
    <v-row class="ma-0" align="center">
      <v-col>
        <strong style="font-size: 1.2rem">
          {{ personnel.firstName }} {{ personnel.lastName }}
        </strong>
        <br />
        {{ personnel.title }}
      </v-col>
      <v-spacer />
      <v-col>
        <v-row justify="end" class="ma-0">
          <avatar-edit
            type="Personnel"
            :editable="isGroupTwoPlus"
            :avatar="avatar"
            :id="id"
            :show-avatar="showAvatar"
            @avatar-update="updateAvatar"
            @avatar-error="hideAvatar"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="ma-0">
      <personnel-card-item
        v-for="detail in cardDetails"
        :data-testid="detail"
        :item="detail"
        :key="detail"
        :model="personnel"
      />
    </v-row>
  </v-card>
</template>

<script>
import AvatarEdit from "@/components/shared/AvatarEdit.vue";
import PersonnelCardItem from "@/components/personnel/PersonnelCardItem.vue";

import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";

export default {
  components: {
    PersonnelCardItem,
    AvatarEdit
  },
  props: {
    personnel: Object,
    id: Number
  },
  data() {
    return {
      avatar: this.generateAvatarUrl(this.personnel.avatar),
      showAvatar: true
    };
  },
  computed: {
    ...mapState(useUserStore, ["isGroupTwoPlus"]),
    homeAddress() {
      return (
        this.personnel.homeAddress?.id &&
        this.personnel.homeAddress?.street_address
      );
    },
    businessAddress() {
      return (
        this.personnel.businessAddress?.id &&
        this.personnel.businessAddress?.street_address
      );
    },
    cardDetails() {
      const details = ["Carrier"];
      if (this.personnel.department) {
        details.push("Department");
      }
      if (this.personnel.phoneWork) {
        details.push("Work Phone");
      }
      if (this.personnel.phoneMobile) {
        details.push("Mobile Phone");
      }
      if (this.personnel.phoneFax) {
        details.push("Fax");
      }
      details.push("Email");
      if (this.homeAddress) {
        details.push("Home Address");
      }
      if (this.businessAddress) {
        details.push("Business Address");
      }
      return details;
    }
  },

  methods: {
    generateAvatarUrl(uri) {
      const date = new Date();
      return `${uri}?time=${date.getTime()}`;
    },
    updateAvatar(newUri) {
      this.avatar = this.generateAvatarUrl(newUri);
    },
    hideAvatar() {
      this.showAvatar = false;
    }
  }
};
</script>
