<template>
  <v-row dense>
    <v-col cols="12" md="6">
      <applications-graph />
    </v-col>
    <v-col cols="12" md="6">
      <case-duration-graph />
    </v-col>
    <v-col cols="12" md="6">
      <commissions-summary-graph divided />
    </v-col>
    <v-col cols="12" md="6">
      <completed-e-apps-graph />
    </v-col>
    <v-col cols="12" md="6">
      <impairment-report />
    </v-col>
    <v-col cols="12" md="6">
      <instant-decisions-graph />
    </v-col>
    <v-col cols="12" md="6">
      <placement-ratio-graph />
    </v-col>
    <v-col cols="12" md="6">
      <premium-graph />
    </v-col>
    <v-col cols="12" md="6">
      <quote-and-apply-graph />
    </v-col>
    <v-col cols="12" md="6">
      <personal-todo-report />
    </v-col>
  </v-row>
</template>

<script setup>
import CaseDurationGraph from "@/components/reports/CaseDurationGraph.vue";
import CompletedEAppsGraph from "@/components/reports/CompletedEAppsGraph.vue";
import ApplicationsGraph from "@/components/reports/ApplicationsGraph.vue";
import PlacementRatioGraph from "@/components/reports/PlacementRatioGraph.vue";
import PremiumGraph from "@/components/reports/PremiumGraph.vue";
import QuoteAndApplyGraph from "@/components/reports/QuoteAndApplyGraph.vue";
import ImpairmentReport from "@/components/reports/ImpairmentReport.vue";
import InstantDecisionsGraph from "@/components/reports/InstantDecisionsGraph.vue";
import CommissionsSummaryGraph from "@/components/reports/CommissionsSummaryGraph.vue";
import PersonalTodoReport from "@/components/reports/PersonalTodoReport.vue";
import { useHead } from "@unhead/vue";

useHead({ title: "Personal Report" });
</script>
