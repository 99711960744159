<template>
  <v-card flat color="section">
    <v-card-title>
      Commissions are assigned to
      {{ assignment.name || "yourself" }}.
    </v-card-title>
    <v-card-text>
      <v-fade-transition mode="out-in">
        <div v-if="showAssignmentBlurb" key="locked">
          Your commissions are locked which disables your ability to modify who
          you assign commissions to.
        </div>
        <v-row v-else-if="editingAssignment" key="edit" dense>
          <v-col cols="12" md="12">
            <v-select
              outlined
              dense
              hide-details
              label="Commissions Paid To"
              prepend-inner-icon="$mdi-bank"
              data-testid="commissions-paid-to"
              :value="currentAssignmentType"
              :items="possibleAssignmentOptions"
              :success="Boolean(assignment.id || assignment.type)"
              :disabled="props.readonly || agent.lockCommissions"
            >
              <template #item="{ attrs, item }">
                <v-list-item
                  @click="changeAssignment(item.value)"
                  v-bind="attrs"
                >
                  <v-list-item-title> {{ item.text }} </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12">
            <a @click="editingAssignment = false"> Cancel commission change </a>
          </v-col>
        </v-row>
        <v-row v-else class="ma-0" key="assignment-text">
          <v-btn
            class="text-none"
            outlined
            color="primary"
            data-testid="change-commission-assignment"
            @click="editingAssignment = true"
          >
            <v-icon class="mr-1">$mdi-currency-usd </v-icon>
            Change commission assignment
          </v-btn>
        </v-row>
      </v-fade-transition>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { commissionPaidToItems } from "@/data/advisor-data";
import { useDialogStore } from "@/stores/dialog";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { useSettingsViewStore } from "@/stores/settings-view";
import { computed, defineProps, ref } from "vue";
import { storeToRefs } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";

const props = defineProps({
  readonly: Boolean
});

const agent = useAgentSettingsStore();
const { assignment } = storeToRefs(agent);
const assignmentKey = ref(new Date().getTime());

const settings = useSettingsViewStore();
const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const editingAssignment = ref(false);

const showAssignmentBlurb = computed(() => {
  if (agent.lockCommissions) return true;
  const isSigner =
    agent.assignment.type === "Agency" && agent.agencies[agent.assignment.id];

  if (isSigner) return false;
  return assignment.value?.id && agent.lockCommissions;
});

const currentAssignmentType = computed(() => {
  if (assignment.value?.id) {
    return `${assignment.value?.id}-${assignment.value?.type}`;
  }
  return assignment.value?.type;
});

const possibleAssignmentOptions = computed(() => {
  let extraOptions = [];
  if (assignment.value?.id) {
    extraOptions.push({
      text: assignment.value?.name,
      value: `${assignment.value?.id}-${assignment.value?.type}`,
      id: assignment.value?.id,
      type: assignment.value?.type
    });
  }

  Object.values(agent.agencies).forEach(agency => {
    if (agency.id === assignment.value?.id) return;
    extraOptions.push({
      text: agency.name,
      value: `${agency.id}-${agency.type}`,
      id: agency.id,
      type: agency.type
    });
  });

  return commissionPaidToItems.concat(extraOptions);
});

async function changeAssignment(v) {
  const assignmentObj = possibleAssignmentOptions.value.find(
    ({ value }) => value == v
  );

  try {
    // Only signing agencies will populate the list
    if (assignmentObj.type === "Agency" && agent.agencies[assignmentObj.id]) {
      agent.setAssignment(agent.agencies[assignmentObj.id]);
      editingAssignment.value = false;
      await agent.updateAssignment();
      settings.currentAgencyId = assignment.value?.id;
      await settings.loadCurrentAgency();
      refreshAssignmentKey();
      return;
    }
    if (assignmentObj.value === "Self") {
      agent.setAssignment({ type: "Self", id: null, name: null });
      editingAssignment.value = false;
      await agent.updateAssignment();
      refreshAssignmentKey();
      return;
    }

    const result = await dialog.showDialog({
      component: "NewCommissionAssignmentDialog",
      type: assignmentObj.value,
      persistent: true
    });

    if (result?.alreadyExists) {
      const advisor = {
        name: result.loginable.name,
        id: result.loginable.id,
        type: result.loginable.type
      };
      await showAssignmentConfirmationDialog(advisor);
    } else if (
      result?.alreadyExists === false &&
      assignmentObj.value === "Agent"
    ) {
      await noSuchAgentDialog(result.npn);
      refreshAssignmentKey();
    } else if (
      result?.alreadyExists === false &&
      assignmentObj.value === "Agency"
    ) {
      await signingOfficerDialog(result.npn);
    }
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function showAssignmentConfirmationDialog(assignment) {
  const response = await dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Do you assign commissions to ${assignment.name}?`,
    subtitle: "Please confirm where your commissions are going to.",
    persistent: true,
    confirmText: "Yes",
    cancelText: "No",
    func: async () => {
      agent.setAssignment(assignment);
      editingAssignment.value = false;
      await agent.updateAssignment();
      const isSigner =
        assignment.type === "Agency" && agent.agencies[agent.assignment.id];

      if (isSigner) {
        agent.currentAgencyId = assignment.id;
        await settings.loadCurrentAgency();
      }
    }
  });

  if (!response?.confirm) refreshAssignmentKey();
}

function refreshAssignmentKey() {
  assignmentKey.value = new Date().getTime();
}

async function signingOfficerDialog(npn) {
  const result = await dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Are you the signing officer?",
    subtitle: "This agency does not exist with us yet.",
    persistent: true,
    confirmText: "Yes",
    cancelText: "No"
  });
  if (!result?.confirm) {
    refreshAssignmentKey();
    await displayUnableToCreateDialog();
    return false;
  }

  return showNewAgencyDialog(npn);
}

async function showNewAgencyDialog(npn) {
  const res = await dialog.showDialog({
    component: "NewAgencyDialog",
    persistent: true,
    npn
  });

  if (res.assigned) editingAssignment.value = false;
}

function noSuchAgentDialog(npn) {
  return dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Unable to find an agent with NPN ${npn}.`,
    html: "Please have that agent <a href='/sign-up'>sign up</a>. Have them enter Word of Mouth and name yourself as the referral contact",
    persistent: true,
    hideCancel: true,
    confirmText: "Close"
  });
}

function displayUnableToCreateDialog() {
  return dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Unable to create agency.`,
    html: "The signing officer needs to be the one who adds the agency. Please have your principal or signing officer <a href='/sign-up'>sign up</a>. Have them enter Word of Mouth and name yourself as the referral contact",
    persistent: true,
    hideCancel: true,
    confirmText: "Close"
  });
}
</script>
