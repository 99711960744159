<template>
  <v-card>
    <v-card-title>Add Temporary Insurance Profile</v-card-title>
    <v-card-text>
      <v-select
        v-model="data.temporary_insurance_profile"
        :items="temporaryInsuranceProfiles"
        item-text="name"
        return-object
        outlined
        :success="!$v.data.temporary_insurance_profile.$invalid"
        :error-messages="
          $v.data.temporary_insurance_profile.$dirty &&
          !$v.data.temporary_insurance_profile.required
            ? ['Required']
            : []
        "
        dense
        label="Temporary Insurance Profile"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="text-none" @click="closeDialog" outlined>Cancel</v-btn>
      <v-btn class="text-none" color="primary" @click="save">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { createTemporaryInsuranceProfile } from "@/api/products.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  validations: {
    data: {
      temporary_insurance_profile: { required }
    }
  },
  props: {
    product: Object,
    temporaryInsuranceProfiles: Array
  },
  data() {
    return {
      data: {
        temporary_insurance_profile: null
      }
    };
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    save() {
      this.$v.$touch();

      if (this.$v.$invalid) return;
      const params = {
        temporary_insurance_profile_id: this.data.temporary_insurance_profile.id
      };
      createTemporaryInsuranceProfile(this.product.id, params)
        .then(response => {
          this.closeDialog(response);
        })
        .catch(e => {
          this.showErrorSnackbar({
            message: parseErrorMessage(e),
            timeout: -1
          });
        });
    }
  }
};
</script>
