<template>
  <v-autocomplete
    autocomplete="false"
    placeholder="Search Affiliations"
    label="Affiliation"
    outlined
    item-text="name"
    return-object
    dense
    v-model="affiliation"
    prepend-inner-icon="$mdi-account-tie"
    no-filter
    hide-no-data
    :hide-details="hideDetails"
    :clearable="clearable"
    :disabled="disabled"
    :search-input.sync="searchText"
    :success="success"
    :error-messages="errorMessages"
    :items="affiliations"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
    @change="$emit('change')"
    @click:clear="$emit('input', {})"
  >
    <template #append-outer>
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import { getAffiliations } from "@/api/affiliation.service";
import { parseErrorMessage } from "@/util/helpers";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  props: {
    hideDetails: Boolean,
    clearable: Boolean,
    success: Boolean,
    errorMessages: Array,
    disabled: Boolean,
    value: [Object, String]
  },
  data() {
    let affiliations = [];
    let affiliation = null;
    if (this.value && Object.keys(this.value).length) {
      affiliations.push(this.value);
      affiliation = this.value;
    }
    return {
      searchText: "",
      affiliations,
      timer: null,
      affiliation,
      loading: false
    };
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.affiliation = null;
        this.affiliations = [];
        return;
      }
      this.affiliation = value;
      this.affiliations = [value];
    },
    affiliation(value) {
      this.$emit("input", value);
    },
    searchText(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (this.affiliations.some(val => val?.name === value)) {
        return;
      }

      this.debounceAndSearchText();
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    debounceAndSearchText() {
      if (this.timer) clearTimeout(this.timer);

      this.timer = setTimeout(() => this.searchAffiliations(), 300);
    },
    async searchAffiliations() {
      this.loading = true;
      try {
        this.affiliations = await getAffiliations({
          q: this.searchText
        });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.affiliation = null;
      this.affiliations = [];
    }
  }
};
</script>
