<template>
  <v-card>
    <v-card-title style="word-break: break-word"> {{ title }} </v-card-title>
    <v-card-subtitle class="mt-3"> {{ subtitle }} </v-card-subtitle>

    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        class="text-none"
        color="red"
        :loading="loading"
        data-testid="delete-dialog-confirm"
        @click="deleteAction"
        >Delete</v-btn
      >
      <v-btn text class="text-none" @click="cancelAction">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";
export default {
  data() {
    return {
      loading: false
    };
  },
  props: {
    title: String,
    subtitle: String,
    func: {
      type: Function,
      default: null
    }
  },
  methods: {
    ...mapActions(useDialogStore, ["closeDialog"]),
    deleteAction() {
      if (!this.func) {
        this.closeDialog({ delete: true });
        return;
      }
      this.loading = true;
      this.func()
        .then(() => {
          this.closeDialog({ delete: true });
          this.loading = false;
        })
        .catch(e => {
          this.closeDialog({
            delete: false,
            error: true,
            message: parseErrorMessage(e)
          });
          this.loading = false;
        });
    },
    cancelAction() {
      this.closeDialog({ delete: false });
    }
  }
};
</script>
