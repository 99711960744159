<template>
  <v-card>
    <v-card-title>Create {{ title }} </v-card-title>
    <v-card-text id="training-container" class="py-3">
      <v-text-field
        v-model="training.providerName"
        data-testid="training-provider-name"
        data-lpignore="true"
        prepend-inner-icon="$mdi-domain"
        label="Provider Name"
        dense
        outlined
        :success="providerNameValidation.success"
        :error-messages="providerNameValidation.errorMessages"
      />
      <v-text-field
        v-model="training.courseName"
        data-testid="training-course-name"
        data-lpignore="true"
        prepend-inner-icon="$mdi-clipboard-outline"
        label="Full Course Name"
        dense
        outlined
        :success="courseNameValidation.success"
        :error-messages="courseNameValidation.errorMessages"
      />
      <date-input
        v-model="training.completedAt"
        data-testid="training-completed-at"
        prepend-inner-icon="$mdi-calendar"
        label="Completed At"
        dense
        outlined
        :success="completedAtValidation.success"
        :error-messages="completedAtValidation.errorMessages"
      />
      <file-drag-and-drop
        v-model="training.file"
        data-testid="training-file"
        :success="fileValidation.success"
        :error-messages="fileValidation.errorMessages"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="closeDialog()" class="text-none mr-1" outlined>
        Cancel
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        data-testid="training-create"
        :loading="creatingTraining"
        @click="createEducation"
      >
        Create {{ title }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import DateInput from "@/components/shared/DateInput.vue";

import { Training, TRAININGS } from "@/factories/AgentSettingsFactory";
import { createEducation } from "@/api/educations.service";
import { required } from "vuelidate/lib/validators";
import differenceInMonths from "date-fns/differenceInMonths";
import isBefore from "date-fns/isBefore";
import isSameDay from "date-fns/isSameDay";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import { parseErrorMessage, validationCompute } from "@/util/helpers";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  name: "AmlForm",
  components: { DateInput, FileDragAndDrop },
  props: {
    advisorId: [Number, String],
    expiresInMonths: [Number],
    title: { type: String, required: true },
    category: {
      type: String,
      required: true,
      validator: v => [TRAININGS.AML, TRAININGS.REG_187].includes(v)
    }
  },
  data() {
    return {
      training: new Training(),
      creatingTraining: false
    };
  },
  created() {
    this.training = new Training({
      ownerId: this.advisorId,
      ownerType: "Agent",
      category: this.category
    });
  },
  computed: {
    providerNameValidation() {
      const model = this.$v.training.providerName;
      return validationCompute(model, [
        { key: "required", message: "Required" }
      ]);
    },
    courseNameValidation() {
      const model = this.$v.training.courseName;
      return validationCompute(model, [
        { key: "required", message: "Required" }
      ]);
    },
    completedAtValidation() {
      const model = this.$v.training.completedAt;
      return validationCompute(model, [
        { key: "required", message: "Required" },
        {
          key: "withinLastTwoYears",
          message: "Must be within the last two years."
        },
        { key: "beforeToday", message: "Must be today or before." }
      ]);
    },
    fileValidation() {
      const model = this.$v.training.file;
      return validationCompute(model, [
        { key: "required", message: "Required" },
        {
          key: "validSize",
          message:
            "Please confirm this file has data or try re-uploading the file"
        }
      ]);
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    goToInvalid() {
      const els = document.getElementsByClassName("v-input error--text");
      if (!els?.length) return;
      this.$vuetify.goTo(els[0], {
        container: "#training-container"
      });
    },
    async createEducation() {
      if (this.creatingTraining) return;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          this.goToInvalid();
        });
        return;
      }
      this.creatingTraining = true;
      try {
        const training = await createEducation(this.training);
        this.closeDialog({ training });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.creatingTraining = false;
      }
    }
  },
  validations() {
    return {
      training: {
        providerName: { required },
        courseName: { required },
        completedAt: {
          required,
          withinLastTwoYears: value => {
            if (!this.expiresInMonths) return true;
            return (
              differenceInMonths(
                new Date(),
                parse(value, "yyyy-MM-dd", new Date())
              ) < this.expiresInMonths
            );
          },
          beforeToday: val => {
            const date = parse(val, "yyyy-MM-dd", new Date());
            if (!isValid(date)) return false;
            if (isSameDay(new Date(), date)) return true;
            return isBefore(date, new Date());
          }
        },
        file: {
          required,
          validSize: val => {
            return val?.size > 0;
          }
        }
      }
    };
  }
};
</script>
