var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"transparent-data-table",attrs:{"data-testid":"revenue-table","item-key":"additional.key","filter-mode":"union","custom-filter":_setup.handleCustomFilter,"custom-sort":_setup.handleSort,"loading":_setup.loading,"search":_setup.search,"items":_setup.table.mappedItems.value,"headers":_setup.table.tableHeaders.value},scopedSlots:_vm._u([{key:`item.fullPayPeriodText`,fn:function({ item }){return [(item.fullPayPeriodText)?_c('router-link',{attrs:{"to":{
        name: 'PayPeriodView',
        params: { id: item.additional.payPeriodId }
      }}},[_vm._v(" "+_vm._s(item.fullPayPeriodText)+" ")]):_vm._e()]}},{key:`item.fullStatementText`,fn:function({ item }){return [(item.fullStatementText)?_c('router-link',{attrs:{"to":{
        name: 'StatementView',
        params: { id: item.additional.statementId }
      }}},[_vm._v(" "+_vm._s(item.fullStatementText)+" ")]):_vm._e()]}},{key:`item.fullCommissionPercentText`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.additional.commissionPercentText)+" "),_c('br'),_c('span',{staticClass:"grey--text"},[_vm._v(" ("+_vm._s(item.additional.paymentType)+") ")])]}},{key:`item.fullCommissionText`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.additional.commissionText)+" "),_c('br'),_c('span',{staticClass:"grey--text"},[_vm._v(" ("+_vm._s(item.additional.type)+") ")])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }