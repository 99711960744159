import {
  setRequestFromReview,
  setEmployeeFromRequest,
  setEmployeeReviewFromRequest,
  setEmployeeTableItemFromRequest
} from "@/factories/EmployeeFactory";
import { getHttpClient } from "@/http-client";

const baseUrl = "api/boss/employees";

export async function getEmployees({ agent_id = null } = {}) {
  const params = new URLSearchParams();
  if (agent_id) params.append("agent_id", agent_id);
  const { data } = await getHttpClient().get(baseUrl, { params });
  return data.employees.map(setEmployeeTableItemFromRequest);
}

export async function getEmployee(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);

  return setEmployeeFromRequest(data.employee);
}

export function updateEmployee(id, body) {
  return getHttpClient().put(`${baseUrl}/${id}`, { employee: body });
}

export async function createEmployeeReview(id, employeeReview) {
  const { data } = await getHttpClient().post(`${baseUrl}/${id}/reviews`, {
    employee_review: setRequestFromReview(employeeReview)
  });

  return setEmployeeReviewFromRequest(data.employee_review);
}
