<template>
  <v-row>
    <v-col cols="12">
      <div>
        <label for="contract-party-roles"> Role </label>
        <v-chip-group
          v-model="caseRole.roles"
          id="contract-party-roles"
          column
          multiple
          active-class="primary"
          :class="{
            'error-border rounded': rolesValidation.errorMessages.length
          }"
        >
          <v-chip
            v-for="role in visibleRoles"
            :data-testid="`role-${role.text}`"
            :key="role.value"
            :value="role.value"
            :disabled="role.disabled"
          >
            {{ role.text }}
          </v-chip>
          <a v-if="truncatedRoles" class="mt-3" @click="showMore = !showMore">
            {{ showMore ? "Show Less" : "Show More" }}
          </a>
        </v-chip-group>
      </div>
    </v-col>

    <v-col cols="12">
      <v-autocomplete
        v-if="displayRelationship"
        v-model="caseRole.relationship"
        data-testid="relationship"
        prepend-inner-icon="$mdi-briefcase"
        label="Relationship"
        outlined
        dense
        :items="relationships"
        :success="relationshipValidation.success"
        :error-messages="relationshipValidation.errorMessages"
        clearable
      />
    </v-col>

    <v-col cols="12">
      <decimal-input
        v-if="displayBeneficiaryQualifier"
        v-model="caseRole.beneficiaryAmount"
        data-testid="beneficiary-amount"
        data-lpignore="true"
        prepend-inner-icon="$mdi-percent"
        label="Beneficiary Percentage"
        type="text"
        inputmode="numeric"
        outlined
        dense
        :min="0"
        :max="100"
        :decimal-length="2"
        :success="beneficiaryAmountValidation.success"
        :error-messages="beneficiaryAmountValidation.errorMessages"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import DecimalInput from "@/components/shared/DecimalInput.vue";
import {
  ENTITY_RELATIONSHIPS,
  INDIVIDUAL_RELATIONSHIPS,
  MUTUALLY_EXCLUSIVE_ROLES,
  ROLES_BY_LINE
} from "@/factories/RoleFactory";
import { useVuetify } from "@/composables/compatible.composables";

import { computed, ref, defineProps, defineEmits, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { computedValidation } from "@/util/helpers";

const emit = defineEmits([
  "update:roles",
  "update:beneficiaryAmount",
  "update:relationship"
]);

const props = defineProps({
  roles: { type: Array, required: false, default: () => null },
  beneficiaryAmount: { type: Number, required: false, default: null },
  relationship: { type: String, required: false, default: null },
  line: { type: String, required: true },
  type: {
    type: String,
    required: true,
    validates: v => ["Individual", "Entity"].includes(v)
  },
  validationScope: { type: String, required: true },
  allowDeletion: Boolean
});

const caseRole = ref({
  roles: props.roles,
  beneficiaryAmount: props.beneficiaryAmount,
  relationship: props.relationship
});

const showMore = ref(false);

const displayBeneficiaryQualifier = computed(() =>
  caseRole.value.roles.some(val => val.includes("Beneficiary"))
);
const displayRelationship = computed(
  () =>
    caseRole.value.roles.length &&
    caseRole.value.roles.every(
      r => !r.includes("Insured") && !r.includes("Annuitant")
    )
);

const contractPartyRoles = computed(() =>
  caseRole.value.roles.reduce((acc, r) => ({ ...acc, [r]: true }), {})
);

const topRoles = computed(() => {
  if (!ROLES_BY_LINE[props.line]) return [];
  return ROLES_BY_LINE[props.line].slice(0, 5);
});

const remainingRoles = computed(() => {
  if (!ROLES_BY_LINE[props.line]) return [];
  return ROLES_BY_LINE[props.line].filter(v => !topRoles.value.includes(v));
});

const vuetify = useVuetify();
const truncatedRoles = computed(() => vuetify.breakpoint.smAndDown);

const visibleRoles = computed(() => {
  let roles = [...topRoles.value];

  if (showMore.value || !truncatedRoles.value) {
    roles.push(...remainingRoles.value);
  }

  return roles.map(r => {
    let disabled = false;
    if (MUTUALLY_EXCLUSIVE_ROLES[r]) {
      disabled = MUTUALLY_EXCLUSIVE_ROLES[r].some(
        role => contractPartyRoles.value[role]
      );
    }

    return {
      text: r,
      value: r,
      disabled
    };
  });
});

const relationships = computed(() => {
  if (!props.type) return [];
  if (props.type === "Entity") return ENTITY_RELATIONSHIPS;

  return INDIVIDUAL_RELATIONSHIPS;
});

const v$ = useVuelidate(
  {
    caseRole: {
      relationship: {
        required: v => !displayRelationship.value || Boolean(v)
      },
      beneficiaryAmount: {
        required: v => !displayBeneficiaryQualifier.value || (v > 0 && v <= 100)
      },
      roles: { required: r => props.allowDeletion || r.length > 0 }
    }
  },
  {
    caseRole
  },
  { $scope: props.validationScope, $autoDirty: true }
);

const relationshipValidation = computedValidation(
  v$.value.caseRole.relationship,
  {
    required: "Required"
  }
);
const beneficiaryAmountValidation = computedValidation(
  v$.value.caseRole.beneficiaryAmount,
  { required: "Required" }
);

const rolesValidation = computedValidation(v$.value.caseRole.roles, {
  required: "Required"
});

watch(
  () => caseRole.value.roles,
  () => emit("update:roles", caseRole.value.roles)
);

watch(
  () => caseRole.value.beneficiaryAmount,
  () => emit("update:beneficiaryAmount", caseRole.value.beneficiaryAmount)
);

watch(
  () => caseRole.value.relationship,
  () => emit("update:relationship", caseRole.value.relationship)
);
</script>
