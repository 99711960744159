<template>
  <v-menu left offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        data-testid="notifications-indicator-button"
        v-on="on"
        v-bind="attrs"
        :loading="loading"
        icon
      >
        <v-badge
          data-testid="notifications-indicator-count"
          color="pink"
          :value="total"
          offset-y="6"
        >
          <template #badge>
            {{ total }}
          </template>
          <v-icon>{{ mdiBell }} </v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="pb-0"> To-Dos </v-card-title>
      <v-card-text>
        <v-list nav class="px-0">
          <template v-if="items.length">
            <v-list-item
              v-for="{ key, routerLink, title, subtitle, text } in items"
              :key="key"
              exact
              :to="routerLink"
              data-testid="notification-item"
            >
              <v-list-item-content>
                <v-list-item-title class="grey--text">
                  {{ title }}
                </v-list-item-title>
                <v-list-item-title> {{ text }} </v-list-item-title>
                <v-list-item-subtitle> {{ subtitle }} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item v-else>
            <v-list-item-content>
              <v-list-item-title>
                All Caught Up
                <v-icon color="orange" class="mb-1">$mdi-party-popper</v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="primary"
          exact
          block
          class="text-none"
          data-testid="view-all-todos"
          :to="todoLink"
        >
          View All To-Dos
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script setup>
import { mdiBell } from "@mdi/js";
import { storeToRefs } from "pinia";
import { useNotificationsStore } from "@/stores/notifications.js";
const notifications = useNotificationsStore();

const { total, items, loading } = storeToRefs(notifications);
notifications.getDueTodos();

const todoLink = { name: "Tables", query: { page: "todos" } };
</script>
