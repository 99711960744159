import {
  getFormCategories,
  getFormSubmissionMethods
} from "@/api/forms.service";
import { getProductSolves } from "@/api/reference.service";
import { fetchCategories } from "@/api/engines.service";

import { useCacheableSearch } from "@/composables/cacheable-search";
import { defineStore } from "pinia";
import { computed } from "vue";

//Use this when retrieving a full list for an autocomplete
export const useSearchItemsCache = defineStore("search-items-cache", () => {
  const formCategories = useCacheableSearch(async () => {
    const items = await getFormCategories();
    return items.map(i => ({ text: i.label, value: i.id }));
  });
  const submissionMethods = useCacheableSearch(async () => {
    const items = await getFormSubmissionMethods();
    return items.map(i => ({ text: i.label, value: i.value }));
  });
  const productSolves = useCacheableSearch(async () => {
    const items = await getProductSolves();
    return items.map(i => ({ text: i, value: i }));
  });

  const productMeta = useCacheableSearch(async () => {
    const { types, traits } = await fetchCategories();
    return [types, traits];
  });

  const productTypes = computed(() => ({
    ...productMeta.state.value,
    items: productMeta.state.value.items?.[0]
  }));

  const productTraits = computed(() => ({
    ...productMeta.state.value,
    items: productMeta.state.value.items?.[1]
  }));

  return {
    formCategories: formCategories.state,
    getFormCategoriesList: formCategories.fetch,
    submissionMethods: submissionMethods.state,
    getFormSubmissionMethods: submissionMethods.fetch,
    productSolves: productSolves.state,
    getProductSolves: productSolves.fetch,
    getProductMeta: productMeta.fetch,
    productTypes,
    productTraits
  };
});
