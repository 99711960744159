<template>
  <v-row dense>
    <v-col cols="12" class="pb-0">
      <personnel-card v-if="loaded" :personnel="personnel" :id="id" />
      <v-skeleton-loader v-else type="card" />
    </v-col>
    <v-col cols="12" v-if="isGroupTwoPlus" class="py-0">
      <personnel-actions :personnel="personnel" v-if="loaded" />
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-card v-if="loaded" flat tile>
        <v-tabs v-model="activeTab" show-arrows>
          <v-tab class="text-none">
            <v-icon class="pr-1">$mdi-message</v-icon>Notes
          </v-tab>
          <v-tab class="text-none">
            <v-icon class="pr-1">$mdi-file-document-edit</v-icon>Details
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab" touchless>
          <v-tab-item eager>
            <personnel-chat :personnel="personnel" :personnel-id="id" />
          </v-tab-item>

          <v-tab-item>
            <v-card color="section" flat tile>
              <v-card-text>
                <personnel-create
                  :parent-personnel="personnel"
                  :editing="true"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <v-skeleton-loader v-else type="card" />
    </v-col>
  </v-row>
</template>

<script>
import PersonnelActions from "@/components/personnel/PersonnelActions.vue";
import PersonnelCard from "@/components/personnel/PersonnelCard.vue";
import PersonnelCreate from "@/components/personnel/PersonnelCreate.vue";
import PersonnelChat from "@/components/personnel/PersonnelChat.vue";

import { getPersonnel } from "@/api/personnel.service";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";
import { useHead } from "@unhead/vue";
export default {
  components: {
    PersonnelCreate,
    PersonnelCard,
    PersonnelActions,
    PersonnelChat
  },
  data() {
    return {
      personnel: {},
      activeTab: 0,
      loaded: false
    };
  },
  setup() {
    const head = useHead({ title: "Personnel Viewer" });
    return { head };
  },
  created() {
    this.getPersonnel();
  },
  destroyed() {
    this.breadcrumb = "";
  },
  computed: {
    ...mapWritableState(useInstanceStore, ["breadcrumb"]),
    ...mapState(useUserStore, ["isGroupTwoPlus"]),
    id() {
      return parseInt(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async getPersonnel() {
      try {
        this.personnel = await getPersonnel(this.$route.params.id);
        this.breadcrumb = this.personnel.name;
        this.head.patch({ title: this.personnel.name });
        this.loaded = true;
      } catch (e) {
        this.showErrorSnackbar({
          message: "Unable to locate personnel...Redirecting to home.",
          timeout: 6000
        });
        this.$router.push({ name: "Home" });
      }
    }
  }
};
</script>
