// This file is utilized for mocking esm composables that currently do not work in testing (in particular Cypress).

export function useRouter() {
  const vm = getCurrentInstance();
  return vm.proxy?.$router;
}

// DEPRECATED WITH VUE 3 + VUETIFY 3
import { getCurrentInstance } from "vue";

export function useVuetify() {
  const vm = getCurrentInstance();
  return vm.proxy?.$vuetify || undefined;
}
