<template>
  <div
    :id="id"
    ref="iframe"
    :src="url"
    frameborder="0"
    allowfullscreen
    height="100%"
    style="width: 100%; height: 100%; border-radius: 8px"
    data-vimeo-byline="false"
  />
</template>

<script>
export default {
  props: {
    url: String,
    id: {
      type: String,
      default: "vimeo-video"
    }
  },
  created() {
    this.handleLoad();
  },
  methods: {
    async handleLoad() {
      const options = {
        url: this.url,
        width: 560,
        byline: false,
        controls: true
      };
      const Player = await import("@vimeo/player");
      new Player.default(this.id, options);
    }
  }
};
</script>

<style>
#vimeo-video iframe {
  border-radius: 4px;
  width: 100%;
}
</style>
