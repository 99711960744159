import {
  mdiPartyPopper,
  mdiTabUnselected,
  mdiDebugStepOver,
  mdiSortDescending,
  mdiSortAscending,
  mdiViewColumnOutline,
  mdiContentDuplicate,
  mdiBullseyeArrow,
  mdiSourceRepository,
  mdiSourceRepositoryMultiple,
  mdiGiftOpenOutline,
  mdiGolf,
  mdiArchiveArrowDown,
  mdiCheckboxBlankOutline,
  mdiArrowUp,
  mdiArrowDown,
  mdiKeyboardReturn,
  mdiCommentQuestion,
  mdiShare,
  mdiRocketLaunch,
  mdiCalendar,
  mdiChevronDown,
  mdiAccountReactivate,
  mdiAccountTie,
  mdiPhone,
  mdiHome,
  mdiMagnify,
  mdiMenu,
  mdiArrowLeft,
  mdiLogout,
  mdiPlaylistPlus,
  mdiAccount,
  mdiBriefcaseVariant,
  mdiHandSaw,
  mdiCashRegister,
  mdiAccountCircle,
  mdiBarcode,
  mdiNewspaper,
  mdiPointOfSale,
  mdiFileDocument,
  mdiClipboardAccount,
  mdiHumanGreeting,
  mdiChartLine,
  mdiCheckDecagram,
  mdiCheckDecagramOutline,
  mdiAlertDecagramOutline,
  mdiKeyChange,
  mdiCog,
  mdiEmail,
  mdiTransitConnectionVariant,
  mdiWeatherSunny,
  mdiWeatherNight,
  mdiFileExport,
  mdiRefresh,
  mdiDrag,
  mdiDomain,
  mdiBullhorn,
  mdiBriefcase,
  mdiOffer,
  mdiSack,
  mdiCalendarWeek,
  mdiMessageText,
  mdiChartAreaspline,
  mdiPound,
  mdiPlus,
  mdiAccountMultiple,
  mdiOpenInApp,
  mdiText,
  mdiAccountSupervisor,
  mdiMailbox,
  mdiDelete,
  mdiDownload,
  mdiSend,
  mdiCubeScan,
  mdiListStatus,
  mdiCube,
  mdiCalendarEdit,
  mdiCalendarStart,
  mdiCalendarEnd,
  mdiCalendarAccount,
  mdiCounter,
  mdiMessage,
  mdiFileDocumentEdit,
  mdiSitemap,
  mdiShieldAccount,
  mdiWrench,
  mdiFileMultiple,
  mdiClock,
  mdiSubtitles,
  mdiCardAccountPhone,
  mdiFileDocumentOutline,
  mdiDeskphone,
  mdiEmailNewsletter,
  mdiCalendarMonth,
  mdiCellphoneBasic,
  mdiMonitorCellphone,
  mdiMail,
  mdiAccountDetails,
  mdiDraw,
  mdiSchool,
  mdiHumanCane,
  mdiCalendarLock,
  mdiBed,
  mdiRoomService,
  mdiOpenInNew,
  mdiCamera,
  mdiPalette,
  mdiPaperclip,
  mdiPercent,
  mdiStethoscope,
  mdiSignRealEstate,
  mdiCurrencyUsd,
  mdiCash,
  mdiHomeGroup,
  mdiViewWeek,
  mdiStar,
  mdiBank,
  mdiTruckDelivery,
  mdiPencil,
  mdiFileTree,
  mdiFile,
  mdiClose,
  mdiCheckCircle,
  mdiAlert,
  mdiArrowExpandAll,
  mdiExclamation,
  mdiCheck,
  mdiEyeOff,
  mdiChartBar,
  mdiGenderMaleFemale,
  mdiRing,
  mdiCardAccountDetails,
  mdiCardAccountDetailsOutline,
  mdiHumanMaleHeight,
  mdiScale,
  mdiCashMultiple,
  mdiCashRefund,
  mdiCubeOutline,
  mdiClipboardOutline,
  mdiCreditCard,
  mdiLink,
  mdiTarget,
  mdiRadar,
  mdiRotateLeft,
  mdiTable,
  mdiMenuDown,
  mdiWeb,
  mdiCancel,
  mdiContentCopy,
  mdiEarth,
  mdiDebugStepInto,
  mdiShape,
  mdiTimetable,
  mdiPuzzle,
  mdiChartTimelineVariant,
  mdiWeightLifter,
  mdiSmoking,
  mdiDoctor,
  mdiFastForward,
  mdiTimerSandFull,
  mdiHexagonMultiple,
  mdiTextBox,
  mdiMinus,
  mdiFormatTitle,
  mdiFullscreenExit,
  mdiFullscreen,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatBold,
  mdiFormatItalic,
  mdiImage,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiContentSave,
  mdiImageText,
  mdiLinkVariant,
  mdiCellphone,
  mdiFax,
  mdiEmailMultiple,
  mdiClipboard,
  mdiFileFind,
  mdiFileCode,
  mdiLock,
  mdiFileDocumentMultiple,
  mdiFileDelimited,
  mdiOfficeBuilding,
  mdiEngine,
  mdiGoogleCirclesExtended,
  mdiChat,
  mdiFlag,
  mdiAbTesting,
  mdiBookAlphabet,
  mdiDatabaseOff,
  mdiCodeTags,
  mdiSetSplit,
  mdiShopping,
  mdiLabel,
  mdiHandshake,
  mdiInformation,
  mdiLockOpenVariantOutline,
  mdiCash100,
  mdiTag,
  mdiAccountNetwork,
  mdiDevTo,
  mdiImport,
  mdiSearchWeb,
  mdiBookOpenPageVariant,
  mdiFloorPlan,
  mdiLibrary,
  mdiAsterisk,
  mdiTextBoxPlus,
  mdiViewListOutline,
  mdiGoogleCirclesGroup,
  mdiWizardHat,
  mdiHelpBox,
  mdiHistory,
  mdiFileHidden,
  mdiForum,
  mdiDecagramOutline,
  mdiMapOutline,
  mdiRelationOneToMany,
  mdiHelpNetwork,
  mdiPlusCircle,
  mdiChevronLeft,
  mdiChevronRight,
  mdiExport,
  mdiRadioboxMarked,
  mdiSignatureFreehand,
  mdiProgressQuestion,
  mdiSackPercent,
  mdiBookOpenVariant,
  mdiHeartPulse,
  mdiBottleTonicPlus,
  mdiFormatFontSizeIncrease,
  mdiFormatFontSizeDecrease,
  mdiRepeatOff,
  mdiRepeat,
  mdiStop,
  mdiPlay,
  mdiCity,
  mdiMapMarker,
  mdiWindowClose,
  mdiHeadQuestionOutline,
  mdiUpload,
  mdiFormTextboxPassword,
  mdiFileCancel,
  mdiUndo,
  mdiCalendarCursor,
  mdiEmailOutline,
  mdiEye,
  mdiHelpCircleOutline,
  mdiMicrophoneOff,
  mdiDialpad,
  mdiPhoneHangup,
  mdiBackspace,
  mdiCheckbook,
  mdiCheckboxBlankCircleOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiAlertCircleOutline,
  mdiDatabase,
  mdiFilterVariantMinus,
  mdiFilterVariantPlus,
  mdiFilter,
  mdiArchive,
  mdiLockReset,
  mdiHumanGreetingProximity,
  mdiLanConnect,
  mdiHomeImportOutline,
  mdiAlertCircle,
  mdiTruckFastOutline,
  mdiIdCard,
  mdiBriefcaseSearch,
  mdiCalculator,
  mdiCalendarClock,
  mdiClipboardTextOutline,
  mdiClipboardListOutline,
  mdiClipboardAlertOutline,
  mdiArrowRight,
  mdiBell,
  mdiFileEye,
  mdiGavel,
  mdiSecurity,
  mdiCloseCircle,
  mdiGoogleDownasaur,
  mdiFileChart,
  mdiLifebuoy,
  mdiShieldLock,
  mdiFormatListBulletedType,
  mdiChartBellCurve,
  mdiAccountSearch,
  mdiAccountPlus,
  mdiCardText,
  mdiCheckboxMarked,
  mdiCircleOffOutline,
  mdiThemeLightDark,
  mdiDomainPlus,
  mdiTablePlus,
  mdiFolderMultiple,
  mdiCircleSmall,
  mdiUndoVariant,
  mdiDotsHorizontal,
  mdiChevronUp,
  mdiBackupRestore,
  mdiMagnifyScan,
  mdiBug,
  mdiImageOff,
  mdiImagePlus,
  mdiVideoWirelessOutline,
  mdiGift,
  mdiCogs,
  mdiAccountMultiplePlus,
  mdiMouse,
  mdiApplicationImport,
  mdiPageNext,
  mdiFileEditOutline,
  mdiCogOff,
  mdiBird,
  mdiTableEye,
  mdiTableEyeOff,
  mdiExportVariant,
  mdiAlphaX,
  mdiBadgeAccountOutline,
  mdiPrinterEye,
  mdiMagnetOn,
  mdiCircleOutline,
  mdiCircleMultipleOutline,
  mdiPoll,
  mdiFilePdfBox,
  mdiBookOpenBlankVariant,
  mdiEmailArrowRight,
  mdiLandRowsVertical,
  mdiSquareOpacity,
  mdiPlayCircle,
  mdiTab,
  mdiFormSelect,
  mdiFormTextbox,
  mdiListBox,
  mdiFileReplace
} from "@mdi/js";

export default {
  "mdi-file-replace": mdiFileReplace,
  "mdi-list-box": mdiListBox,
  "mdi-form-textbox": mdiFormTextbox,
  "mdi-form-select": mdiFormSelect,
  "mdi-party-popper": mdiPartyPopper,
  "mdi-tab": mdiTab,
  "mdi-tab-unselected": mdiTabUnselected,
  "mdi-square-opacity": mdiSquareOpacity,
  "mdi-history": mdiHistory,
  "mdi-land-rows-vertical": mdiLandRowsVertical,
  "mdi-poll": mdiPoll,
  "mdi-debug-step-over": mdiDebugStepOver,
  "mdi-sort-descending": mdiSortDescending,
  "mdi-sort-ascending": mdiSortAscending,
  "mdi-view-column-outline": mdiViewColumnOutline,
  "mdi-printer-eye": mdiPrinterEye,
  "mdi-content-duplicate": mdiContentDuplicate,
  "mdi-cog-off": mdiCogOff,
  "mdi-file-edit": mdiFileEditOutline,
  "mdi-page-next": mdiPageNext,
  "mdi-application-import": mdiApplicationImport,
  "mdi-account-multiple-plus": mdiAccountMultiplePlus,
  "mdi-cogs": mdiCogs,
  "mdi-source-repository": mdiSourceRepository,
  "mdi-source-repository-multiple": mdiSourceRepositoryMultiple,
  "mdi-video-wireless-outline": mdiVideoWirelessOutline,
  "mdi-gift-open-outline": mdiGiftOpenOutline,
  "mdi-gift": mdiGift,
  "mdi-golf": mdiGolf,
  "mdi-archive-arrow-down": mdiArchiveArrowDown,
  "mdi-circle-small": mdiCircleSmall,
  "mdi-folder-multiple": mdiFolderMultiple,
  "mdi-table-plus": mdiTablePlus,
  "mdi-domain-plus": mdiDomainPlus,
  "mdi-theme-light-dark": mdiThemeLightDark,
  "mdi-circle-off-outline": mdiCircleOffOutline,
  "mdi-arrow-up": mdiArrowUp,
  "mdi-arrow-down": mdiArrowDown,
  "mdi-keyboard-return": mdiKeyboardReturn,
  "mdi-share": mdiShare,
  "mdi-rocket-launch": mdiRocketLaunch,
  "mdi-calendar": mdiCalendar,
  "mdi-chevron-down": mdiChevronDown,
  "mdi-account-reactivate": mdiAccountReactivate,
  "mdi-account-tie": mdiAccountTie,
  "mdi-phone": mdiPhone,
  "mdi-home": mdiHome,
  "mdi-magnify": mdiMagnify,
  "mdi-menu": mdiMenu,
  "mdi-arrow-left": mdiArrowLeft,
  "mdi-logout": mdiLogout,
  "mdi-playlist-plus": mdiPlaylistPlus,
  "mdi-account": mdiAccount,
  "mdi-briefcase-variant": mdiBriefcaseVariant,
  "mdi-hand-saw": mdiHandSaw,
  "mdi-book-information-variant": mdiBookOpenBlankVariant,
  "mdi-magnet-on": mdiMagnetOn,
  "mdi-cash-register": mdiCashRegister,
  "mdi-account-circle": mdiAccountCircle,
  "mdi-barcode": mdiBarcode,
  "mdi-newspaper": mdiNewspaper,
  "mdi-point-of-sale": mdiPointOfSale,
  "mdi-file-document": mdiFileDocument,
  "mdi-clipboard-account": mdiClipboardAccount,
  "mdi-human-greeting": mdiHumanGreeting,
  "mdi-chart-line": mdiChartLine,
  "mdi-alert-decagram-outline": mdiAlertDecagramOutline,
  "mdi-check-decagram": mdiCheckDecagram,
  "mdi-check-decagram-outline": mdiCheckDecagramOutline,
  "mdi-key-change": mdiKeyChange,
  "mdi-comment-question": mdiCommentQuestion,
  "mdi-cog": mdiCog,
  "mdi-email": mdiEmail,
  "mdi-palette": mdiPalette,
  "mdi-transit-connection-variant": mdiTransitConnectionVariant,
  "mdi-weather-sunny": mdiWeatherSunny,
  "mdi-weather-night": mdiWeatherNight,
  "mdi-file-export": mdiFileExport,
  "mdi-refresh": mdiRefresh,
  "mdi-drag": mdiDrag,
  "mdi-domain": mdiDomain,
  "mdi-bullhorn": mdiBullhorn,
  "mdi-briefcase": mdiBriefcase,
  "mdi-offer": mdiOffer,
  "mdi-sack": mdiSack,
  "mdi-human-cane": mdiHumanCane,
  "mdi-calendar-week": mdiCalendarWeek,
  "mdi-message-text": mdiMessageText,
  "mdi-chart-areaspline": mdiChartAreaspline,
  "mdi-pound": mdiPound,
  "mdi-plus": mdiPlus,
  "mdi-account-multiple": mdiAccountMultiple,
  "mdi-open-in-app": mdiOpenInApp,
  "mdi-text": mdiText,
  "mdi-account-supervisor": mdiAccountSupervisor,
  "mdi-email-send": mdiEmailArrowRight,
  "mdi-mailbox": mdiMailbox,
  "mdi-delete": mdiDelete,
  "mdi-download": mdiDownload,
  "mdi-send": mdiSend,
  "mdi-cube-scan": mdiCubeScan,
  "mdi-list-status": mdiListStatus,
  "mdi-cube": mdiCube,
  "mdi-calendar-edit": mdiCalendarEdit,
  "mdi-calendar-start": mdiCalendarStart,
  "mdi-calendar-end": mdiCalendarEnd,
  "mdi-calendar-account": mdiCalendarAccount,
  "mdi-counter": mdiCounter,
  "mdi-message": mdiMessage,
  "mdi-file-document-edit": mdiFileDocumentEdit,
  "mdi-sitemap": mdiSitemap,
  "mdi-shield-account": mdiShieldAccount,
  "mdi-wrench": mdiWrench,
  "mdi-file-multiple": mdiFileMultiple,
  "mdi-clock": mdiClock,
  "mdi-subtitles": mdiSubtitles,
  "mdi-card-account-phone": mdiCardAccountPhone,
  "mdi-file-document-outline": mdiFileDocumentOutline,
  "mdi-deskphone": mdiDeskphone,
  "mdi-email-newsletter": mdiEmailNewsletter,
  "mdi-calendar-month": mdiCalendarMonth,
  "mdi-cellphone-basic": mdiCellphoneBasic,
  "mdi-monitor-cellphone": mdiMonitorCellphone,
  "mdi-mail": mdiMail,
  "mdi-account-details": mdiAccountDetails,
  "mdi-draw": mdiDraw,
  "mdi-school": mdiSchool,
  "mdi-calendar-lock": mdiCalendarLock,
  "mdi-bed": mdiBed,
  "mdi-room-service": mdiRoomService,
  "mdi-open-in-new": mdiOpenInNew,
  "mdi-camera": mdiCamera,
  "mdi-paperclip": mdiPaperclip,
  "mdi-percent": mdiPercent,
  "mdi-stethoscope": mdiStethoscope,
  "mdi-sign-real-estate": mdiSignRealEstate,
  "mdi-currency-usd": mdiCurrencyUsd,
  "mdi-cash": mdiCash,
  "mdi-home-currency-usd": mdiCurrencyUsd,
  "mdi-home-group": mdiHomeGroup,
  "mdi-view-week": mdiViewWeek,
  "mdi-star": mdiStar,
  "mdi-bank": mdiBank,
  "mdi-truck-delivery": mdiTruckDelivery,
  "mdi-pencil": mdiPencil,
  "mdi-file-tree": mdiFileTree,
  "mdi-file": mdiFile,
  "mdi-close": mdiClose,
  "mdi-check-circle": mdiCheckCircle,
  "mdi-image-off": mdiImageOff,
  "mdi-image-plus": mdiImagePlus,
  "mdi-alert": mdiAlert,
  "mdi-arrow-expand-all": mdiArrowExpandAll,
  "mdi-exclamation": mdiExclamation,
  "mdi-check": mdiCheck,
  "mdi-eye-off": mdiEyeOff,
  "mdi-chart-bar": mdiChartBar,
  "mdi-gender-male-female": mdiGenderMaleFemale,
  "mdi-ring": mdiRing,
  "mdi-card-account-details": mdiCardAccountDetails,
  "mdi-card-account-details-outline": mdiCardAccountDetailsOutline,
  "mdi-human-male-height": mdiHumanMaleHeight,
  "mdi-scale": mdiScale,
  "mdi-cash-multiple": mdiCashMultiple,
  "mdi-cash-refund": mdiCashRefund,
  "mdi-cash-usd": mdiCurrencyUsd,
  "mdi-cube-outline": mdiCubeOutline,
  "mdi-clipboard-outline": mdiClipboardOutline,
  "mdi-credit-card": mdiCreditCard,
  "mdi-link": mdiLink,
  "mdi-target": mdiTarget,
  "mdi-radar": mdiRadar,
  "mdi-rotate-left": mdiRotateLeft,
  "mdi-table": mdiTable,
  "mdi-menu-down": mdiMenuDown,
  "mdi-web": mdiWeb,
  "mdi-file-pdf": mdiFilePdfBox,
  "mdi-cancel": mdiCancel,
  "mdi-content-copy": mdiContentCopy,
  "mdi-earth": mdiEarth,
  "mdi-debug-step-into": mdiDebugStepInto,
  "mdi-shape": mdiShape,
  "mdi-timetable": mdiTimetable,
  "mdi-puzzle": mdiPuzzle,
  "mdi-chart-timeline-variant": mdiChartTimelineVariant,
  "mdi-weight-lifter": mdiWeightLifter,
  "mdi-smoking": mdiSmoking,
  "mdi-doctor": mdiDoctor,
  "mdi-fast-forward": mdiFastForward,
  "mdi-timer-sand-full": mdiTimerSandFull,
  "mdi-hexagon-multiple": mdiHexagonMultiple,
  "mdi-text-box": mdiTextBox,
  "mdi-minus": mdiMinus,
  "mdi-format-title": mdiFormatTitle,
  "mdi-fullscreen-exit": mdiFullscreenExit,
  "mdi-fullscreen": mdiFullscreen,
  "mdi-format-header-1": mdiFormatHeader1,
  "mdi-format-header-2": mdiFormatHeader2,
  "mdi-format-header-3": mdiFormatHeader3,
  "mdi-format-bold": mdiFormatBold,
  "mdi-format-italic": mdiFormatItalic,
  "mdi-image": mdiImage,
  "mdi-format-list-bulleted": mdiFormatListBulleted,
  "mdi-format-list-numbered": mdiFormatListNumbered,
  "mdi-content-save": mdiContentSave,
  "mdi-image-text": mdiImageText,
  "mdi-link-variant": mdiLinkVariant,
  "mdi-cellphone": mdiCellphone,
  "mdi-fax": mdiFax,
  "mdi-email-multiple": mdiEmailMultiple,
  "mdi-clipboard": mdiClipboard,
  "mdi-file-find": mdiFileFind,
  "mdi-file-code": mdiFileCode,
  "mdi-lock": mdiLock,
  "mdi-file-document-multiple": mdiFileDocumentMultiple,
  "mdi-file-delimited": mdiFileDelimited,
  "mdi-office-building": mdiOfficeBuilding,
  "mdi-engine": mdiEngine,
  "mdi-google-circles-extended": mdiGoogleCirclesExtended,
  "mdi-chat": mdiChat,
  "mdi-flag": mdiFlag,
  "mdi-ab-testing": mdiAbTesting,
  "mdi-book-alphabet": mdiBookAlphabet,
  "mdi-database-off": mdiDatabaseOff,
  "mdi-code-tags": mdiCodeTags,
  "mdi-set-split": mdiSetSplit,
  "mdi-shopping": mdiShopping,
  "mdi-label": mdiLabel,
  "mdi-handshake": mdiHandshake,
  "mdi-information": mdiInformation,
  "mdi-lock-open-variant-outline": mdiLockOpenVariantOutline,
  "mdi-cash-100": mdiCash100,
  "mdi-tag": mdiTag,
  "mdi-account-network": mdiAccountNetwork,
  "mdi-dev-to": mdiDevTo,
  "mdi-import": mdiImport,
  "mdi-search-web": mdiSearchWeb,
  "mdi-book-open-page-variant": mdiBookOpenPageVariant,
  "mdi-floor-plan": mdiFloorPlan,
  "mdi-library": mdiLibrary,
  "mdi-asterisk": mdiAsterisk,
  "mdi-text-box-plus": mdiTextBoxPlus,
  "mdi-view-list-outline": mdiViewListOutline,
  "mdi-google-circles-group": mdiGoogleCirclesGroup,
  "mdi-wizard-hat": mdiWizardHat,
  "mdi-help-box": mdiHelpBox,
  "mdi-file-hidden": mdiFileHidden,
  "mdi-forum": mdiForum,
  "mdi-decagram-outline": mdiDecagramOutline,
  "mdi-map-outline": mdiMapOutline,
  "mdi-relation-one-to-many": mdiRelationOneToMany,
  "mdi-help-network": mdiHelpNetwork,
  "mdi-plus-circle": mdiPlusCircle,
  "mdi-chevron-left": mdiChevronLeft,
  "mdi-chevron-right": mdiChevronRight,
  "mdi-export": mdiExport,
  "mdi-radiobox-marked": mdiRadioboxMarked,
  "mdi-signature-freehand": mdiSignatureFreehand,
  "mdi-progress-question": mdiProgressQuestion,
  "mdi-sack-percent": mdiSackPercent,
  "mdi-book-open-variant": mdiBookOpenVariant,
  "mdi-heart-pulse": mdiHeartPulse,
  "mdi-bottle-tonic-plus": mdiBottleTonicPlus,
  "mdi-format-font-size-increase": mdiFormatFontSizeIncrease,
  "mdi-format-font-size-decrease": mdiFormatFontSizeDecrease,
  "mdi-repeat-off": mdiRepeatOff,
  "mdi-repeat": mdiRepeat,
  "mdi-stop": mdiStop,
  "mdi-play": mdiPlay,
  "mdi-play-circle": mdiPlayCircle,
  "mdi-city": mdiCity,
  "mdi-map-marker": mdiMapMarker,
  "mdi-window-close": mdiWindowClose,
  "mdi-head-question-outline": mdiHeadQuestionOutline,
  "mdi-upload": mdiUpload,
  "mdi-form-textbox-password": mdiFormTextboxPassword,
  "mdi-file-cancel": mdiFileCancel,
  "mdi-undo": mdiUndo,
  "mdi-calendar-cursor": mdiCalendarCursor,
  "mdi-email-outline": mdiEmailOutline,
  "mdi-eye": mdiEye,
  "mdi-help-circle-outline": mdiHelpCircleOutline,
  "mdi-microphone-off": mdiMicrophoneOff,
  "mdi-dialpad": mdiDialpad,
  "mdi-phone-hangup": mdiPhoneHangup,
  "mdi-backspace": mdiBackspace,
  "mdi-backup-restore": mdiBackupRestore,
  "mdi-magnify-scan": mdiMagnifyScan,
  "mdi-checkbook": mdiCheckbook,
  "mdi-checkbox-blank-circle-outline": mdiCheckboxBlankCircleOutline,
  "mdi-checkbox-marked-circle-outline": mdiCheckboxMarkedCircleOutline,
  "mdi-checkbox-blank-outline": mdiCheckboxBlankOutline,
  "mdi-checkbox-marked": mdiCheckboxMarked,
  "mdi-alert-circle-outline": mdiAlertCircleOutline,
  "mdi-database": mdiDatabase,
  "mdi-filter-variant-minus": mdiFilterVariantMinus,
  "mdi-filter-variant-plus": mdiFilterVariantPlus,
  "mdi-filter": mdiFilter,
  "mdi-archive": mdiArchive,
  "mdi-lock-reset": mdiLockReset,
  "mdi-human-greeting-proximity": mdiHumanGreetingProximity,
  "mdi-lan-connect": mdiLanConnect,
  "mdi-home-import-outline": mdiHomeImportOutline,
  "mdi-alert-circle": mdiAlertCircle,
  "mdi-truck-fast-outline": mdiTruckFastOutline,
  "mdi-id-card": mdiIdCard,
  "mdi-briefcase-search": mdiBriefcaseSearch,
  "mdi-calculator": mdiCalculator,
  "mdi-calendar-clock": mdiCalendarClock,
  "mdi-clipboard-text-outline": mdiClipboardTextOutline,
  "mdi-clipboard-list-outline": mdiClipboardListOutline,
  "mdi-clipboard-alert-outline": mdiClipboardAlertOutline,
  "mdi-arrow-right": mdiArrowRight,
  "mdi-bell": mdiBell,
  "mdi-file-eye": mdiFileEye,
  "mdi-gavel": mdiGavel,
  "mdi-security": mdiSecurity,
  "mdi-close-circle": mdiCloseCircle,
  "mdi-google-downasaur": mdiGoogleDownasaur,
  "mdi-file-chart": mdiFileChart,
  "mdi-lifebuoy": mdiLifebuoy,
  "mdi-shield-lock": mdiShieldLock,
  "mdi-format-list-bulleted-type": mdiFormatListBulletedType,
  "mdi-chart-bell-curve": mdiChartBellCurve,
  "mdi-account-search": mdiAccountSearch,
  "mdi-account-plus": mdiAccountPlus,
  "mdi-card-text": mdiCardText,
  "mdi-undo-variant": mdiUndoVariant,
  "mdi-dots-horizontal": mdiDotsHorizontal,
  "mdi-chevron-up": mdiChevronUp,
  "mdi-bug": mdiBug,
  "mdi-mouse": mdiMouse,
  "mdi-bird": mdiBird,
  "mdi-table-eye": mdiTableEye,
  "mdi-table-eye-off": mdiTableEyeOff,
  "mdi-export-variant": mdiExportVariant,
  "mdi-alpha-x": mdiAlphaX,
  "mdi-bullseye-arrow": mdiBullseyeArrow,
  "mdi-badge-account-outline": mdiBadgeAccountOutline,
  "mdi-circle-outline": mdiCircleOutline,
  "mdi-circle-multiple-outline": mdiCircleMultipleOutline
};
