import { render, staticRenderFns } from "./ApprovedDomainMarketing.vue?vue&type=template&id=033538cf&scoped=true"
import script from "./ApprovedDomainMarketing.vue?vue&type=script&setup=true&lang=js"
export * from "./ApprovedDomainMarketing.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ApprovedDomainMarketing.vue?vue&type=style&index=0&id=033538cf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "033538cf",
  null
  
)

export default component.exports