var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{attrs:{"must-sort":"","data-testid":"appointments-table","headers":_setup.table.tableHeaders.value,"items":_setup.table.mappedItems.value,"server-items-length":_setup.table.meta.value.total,"loading":_setup.table.loading.value,"options":_setup.table.options.value,"footer-props":_setup.footerProps},on:{"update:options":_setup.updateOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"ma-0 pa-3",attrs:{"align":"center"}},[_c('h1',{staticClass:"text-h5"},[_vm._v("Appointments")]),_c(_setup.GenericTableStats,{attrs:{"data-testid":"appointments-table-stats","stats":_setup.stats,"end":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-testid":"appointments-table-email","icon":""},on:{"click":_setup.confirmAndEmailData}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$mdi-file-export")])],1)]}}])},[_c('span',[_vm._v("Email Data")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-testid":"appointments-table-refresh-data","icon":""},on:{"click":_setup.getData}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-divider'),_c(_setup.GenericTableFilterHeader,{attrs:{"header-props":{
        class: 'mt-0 pa-3',
        rounded: false
      },"show-view-filter":_setup.user.isGroupTwoPlus,"value":_setup.table.filter.value,"headers":_setup.table.filterHeaders.value,"loading":_setup.table.loading.value},on:{"update":_setup.updateFilter}}),_c('v-divider')]},proxy:true},{key:`item.name`,fn:function({ item }){return [_c('td',{staticClass:"truncate"},[_c('router-link',{staticClass:"text-none",attrs:{"to":{
          name: 'AppointmentView',
          params: { id: item.additional.id }
        }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:`item.created`,fn:function({ item }){return [_c(_setup.TimestampFormatter,{attrs:{"value":item.created}})]}},{key:`item.followUp`,fn:function({ item }){return [_c(_setup.TimestampFormatter,{attrs:{"value":item.followUp,"parser":"sole-day"}})]}},{key:`item.lastNote`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('td',_vm._g(_vm._b({staticClass:"truncate"},'td',attrs,false),on),[_vm._v(" "+_vm._s(item.lastNote)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.lastNote)+" ")])])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }