export default function (model = {}) {
  return {
    insuranceType: model?.insuranceType || null,
    carrier: model?.carrier || null,
    product: model?.product || null,
    dateOfBirth: model?.dateOfBirth || null,
    state: model?.state || null,
    faceAmount: model?.faceAmount || null,
    notes: model?.notes || null,
    toCreateRequest() {
      return {
        birthdate: this.dateOfBirth,
        state: this.state,
        carrier_id: this.carrier.id,
        description: this.notes,
        product_id: this.product,
        face_amount: this.faceAmount
      };
    }
  };
}
