<template>
  <v-card>
    <v-card-title>Edit Role</v-card-title>
    <v-card-text>
      <case-role-input
        validation-scope="update-roles"
        allow-deletion
        :type="props.value.type"
        :line="props.line"
        :roles.sync="party.roles"
        :beneficiary-amount.sync="party.beneficiaryAmount"
        :relationship.sync="party.relationship"
      />
      <v-alert v-if="!party.roles.length" type="warning">
        This contract party will be removed from the case if there are no
        assigned roles.
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="text-none" outlined @click="dialog.closeDialog()">
        Cancel
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        :loading="loading"
        data-testid="save-role"
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import CaseRoleInput from "@/components/cases/case-dialogs/CaseRoleInput.vue";
import { parseErrorMessage } from "@/util/helpers";
import { caseContractPartyCrud } from "@/api/cases.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { ref, defineProps } from "vue";
import {
  CONTINGENT_BENEFICIARY,
  INSURED,
  JOINT_INSURED,
  PRIMARY_BENEFICIARY
} from "@/factories/RoleFactory";

const props = defineProps({
  value: {
    type: Object,
    required: true
  },
  caseId: {
    type: Number,
    required: true
  },
  line: {
    type: String,
    required: true
  }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const originalRoles = props.value.roles;

const party = ref({
  roles: [],
  beneficiaryAmount: null,
  relationship: null
});

props.value.roles.forEach(role => {
  if (role.beneficiaryAmount) {
    party.value.beneficiaryAmount = role.beneficiaryAmount * 100;
  }
  if (role.relationship) {
    party.value.relationship = role.relationship;
  }
  party.value.roles.push(role.role);
});

const loading = ref(false);

async function save() {
  loading.value = true;

  const rolesAttributes = [];

  // mark missing roles for deletion
  originalRoles.forEach(role => {
    const found = party.value.roles.find(r => r === role.role);
    if (!found) rolesAttributes.push({ id: role.id, _destroy: true });
  });

  // add or update new roles
  party.value.roles.forEach(role => {
    const attributes = {
      role,
      case_id: props.caseId
    };

    if ([PRIMARY_BENEFICIARY, CONTINGENT_BENEFICIARY].includes(role)) {
      attributes.beneficiary_amount = +(
        party.value.beneficiaryAmount / 100
      ).toFixed(2);
      attributes.beneficiary_qualifier = "Percent";
    }
    if (![INSURED, JOINT_INSURED].includes(role)) {
      attributes.relationship = party.value.relationship;
    }

    const found = originalRoles.find(r => r.role === role);
    if (found) attributes.id = found.id;

    rolesAttributes.push(attributes);
  });

  try {
    const party = await caseContractPartyCrud(props.caseId, props.value.id, {
      contract_party: { roles_attributes: rolesAttributes }
    });
    dialog.closeDialog({ party });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: `Unable to update Contract Party. ${parseErrorMessage(error)}`,
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}
</script>
