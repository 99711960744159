<template>
  <v-tooltip v-if="props.fullEmail" top>
    <template #activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        x-small
        icon
        data-testid="inspect-full-email"
        :loading="gettingFullEmail"
        color="primary"
        @click="retrieveFullEmail"
      >
        <v-icon small> {{ mdiEmail }} </v-icon>
      </v-btn>
    </template>
    <span> Emailed - Inspect Full Email </span>
  </v-tooltip>
  <v-tooltip v-else top>
    <template #activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" style="margin: 2px" small>
        {{ mdiEmail }}
      </v-icon>
    </template>
    <span> Emailed </span>
  </v-tooltip>
</template>

<script setup>
import { mdiEmail } from "@mdi/js";
import { getFullEmail } from "@/api/notes.service";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import { defineProps, ref } from "vue";
const props = defineProps({
  fullEmail: Boolean,
  messageId: { type: Number, required: true }
});

const gettingFullEmail = ref(false);
const dialog = useDialogStore();
const snackbar = useSnackbarStore();
async function retrieveFullEmail() {
  if (!props.fullEmail) return;
  try {
    gettingFullEmail.value = true;
    const html = await getFullEmail(props.messageId);
    dialog.showDialog({
      component: "ConfirmationDialog",
      title: "Full Email Viewer",
      html,
      htmlWrapperClass: "white-space-pre-wrap",
      scrollable: true,
      hideCancel: true,
      confirmText: "Close"
    });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    gettingFullEmail.value = false;
  }
}
</script>
