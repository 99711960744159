<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" md="6">
        <agent-search
          v-model="advisor.principal"
          label="Principal Agent"
          placeholder="Search Principal Agent"
          data-testid="agency-create-principal-agent"
          :disabled="loading"
          :success="principalValidation.success"
          :error-messages="principalValidation.errorMessages"
        />
      </v-col>
      <v-col cols="12" md="6">
        <user-search
          v-model="advisor.user"
          label="Existing User"
          placeholder="Search User by Email Address"
          data-testid="agency-create-user"
          :disabled="loading"
          :success="userValidation.success"
          :error-messages="userValidation.errorMessages"
        />
      </v-col>
      <v-col cols="12" md="8">
        <basic-email-input
          v-model="advisor.email"
          outlined
          dense
          data-lpignore="true"
          label="E-Mail Address"
          data-testid="agency-create-email"
          prepend-inner-icon="$mdi-email"
          :disabled="loading"
          :success="emailValidation.success"
          :error-messages="emailValidation.errorMessages"
          @valid="emailIsValid = $event"
        />
      </v-col>
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="advisor.marketing"
            class="mt-1"
            label="Subscribe to Marketing Emails"
            hide-details
            data-testid="agency-create-marketing"
            :disabled="loading"
          />
        </div>
      </v-col>
      <v-col cols="12" md="8" v-if="advisor.licensed">
        <npn-input
          v-model="advisor.npn"
          data-lpignore="true"
          prepend-inner-icon="$mdi-pound"
          label="NPN"
          validate
          data-testid="agency-create-npn"
          :disabled="loading"
          :success="npnValidation.success"
          :error-messages="npnValidation.errorMessages"
          @error-message="npnErrorMessage = $event"
          @valid="npnIsValid = $event"
        />
      </v-col>
      <v-col cols="12" md="8" v-else>
        <v-text-field
          v-model="advisor.name"
          data-lpignore="true"
          prepend-inner-icon="$mdi-domain"
          outlined
          dense
          label="Agency Name"
          data-testid="agency-create-name"
          :disabled="loading"
          :success="nameValidation.success"
          :error-messages="nameValidation.errorMessages"
        />
      </v-col>
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="advisor.licensed"
            class="mt-1"
            hide-details
            label="Agency has NPN"
            data-testid="agency-create-has-npn"
            :disabled="loading"
          />
        </div>
      </v-col>
      <v-col cols="12">
        <marketing-manager-search
          v-model="advisor.marketingManager"
          return-object
          data-testid="agency-create-marketing-manager"
          :disabled="loading"
          :success="marketingManagerValidation.success"
          :error-messages="marketingManagerValidation.errorMessages"
        />
      </v-col>
      <v-col cols="12">
        <v-btn
          color="primary"
          class="text-none"
          data-testid="agency-create-action"
          :loading="loading"
          @click="createAgency"
        >
          <v-icon class="mr-1"> $mdi-plus </v-icon> Create Agency
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MarketingManagerSearch from "@/components/shared/MarketingManagerSearch.vue";
import AgentSearch from "@/components/shared/AgentSearch.vue";
import UserSearch from "@/components/shared/UserSearch.vue";
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";
import NpnInput from "@/components/shared/NpnInput.vue";

import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { inviteAgency } from "@/api/invites.service";

import { useSnackbarStore } from "@/stores/snackbar";

export default {
  name: "AgencyForm",
  components: {
    MarketingManagerSearch,
    AgentSearch,
    UserSearch,
    BasicEmailInput,
    NpnInput
  },
  data() {
    const advisor = {
      user: null,
      marketing: false,
      email: "",
      licensed: true,
      name: "",
      marketingManager: null,
      npn: "",
      principal: null
    };
    return {
      emailIsValid: "",
      npnIsValid: "",
      advisor,
      loading: false,
      npnErrorMessage: ""
    };
  },
  computed: {
    principalValidation() {
      const success = !this.$v.advisor.principal.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.principal.$dirty) return { success, errorMessages };
      if (!this.$v.advisor.principal.required) errorMessages.push("Required");

      return { success, errorMessages };
    },
    userValidation() {
      const success = !this.$v.advisor.user.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.user.$dirty) return { success, errorMessages };
      if (!this.$v.advisor.user.required) errorMessages.push("Required");

      return { success, errorMessages };
    },
    emailValidation() {
      const success = !this.$v.advisor.email.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.email.$dirty) return { success, errorMessages };
      if (!this.$v.advisor.email.required) errorMessages.push("Required");
      if (!this.$v.advisor.email.isValid) errorMessages.push("Invalid Email");
      return { success, errorMessages };
    },
    npnValidation() {
      const success = !this.$v.advisor.npn.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.npn.$dirty) return { success, errorMessages };
      if (!this.$v.advisor.npn.required) errorMessages.push("Required");
      if (!this.$v.advisor.npn.isValid) errorMessages.push("Invalid NPN");
      if (this.npnErrorMessage) errorMessages.push(this.npnErrorMessage);
      return { success, errorMessages };
    },
    nameValidation() {
      const success = !this.$v.advisor.name.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.name.$dirty) return { success, errorMessages };
      if (!this.$v.advisor.name.required) errorMessages.push("Required");

      return { success, errorMessages };
    },
    marketingManagerValidation() {
      const success = !this.$v.advisor.marketingManager.$invalid;
      const errorMessages = [];
      if (!this.$v.advisor.marketingManager.$dirty)
        return { success, errorMessages };
      if (!this.$v.advisor.marketingManager.required)
        errorMessages.push("Required");

      return { success, errorMessages };
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async createAgency() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      try {
        const id = await inviteAgency({
          email: this.advisor.email,
          user_id: this.advisor.user.id,
          signer_id: this.advisor.principal.id,
          subscribe_to_marketing_emails: this.advisor.marketing,
          marketing_manager_id: this.advisor.marketingManager.id,
          name: this.advisor.licensed ? null : this.advisor.name,
          npn: this.advisor.licensed ? this.advisor.npn : null
        });
        this.$router.push({
          name: "AgencyView",
          params: { id }
        });
      } catch (error) {
        this.showErrorSnackbar({
          message: parseErrorMessage(error),
          timeout: -1
        });
      } finally {
        this.loading = false;
      }
    }
  },
  validations() {
    return {
      advisor: {
        licensed: {},
        principal: { required: Boolean },
        user: { required: Boolean },
        marketingManager: { required: Boolean },
        name: {
          required: (val, vm) => Boolean(val) || vm.licensed
        },
        npn: {
          required: (val, vm) => Boolean(val) || !vm.licensed,
          isValid: (val, vm) => Boolean(this.npnIsValid) || !vm.licensed
        },
        email: {
          required: Boolean,
          isValid: () => Boolean(this.emailIsValid)
        }
      }
    };
  }
};
</script>
