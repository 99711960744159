import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";
import { numberFormat } from "@/util/helpers";
import { computed, ref } from "vue";

const objectToUrlParams = (
  obj,
  parentKey = "",
  accumulator = new URLSearchParams(),
  level = 0
) => {
  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach(value => {
        let paramKey = `[${key}][]`;
        if (parentKey) paramKey = `${parentKey}${paramKey}`;
        else if (level === 0) paramKey = `${key}[]`;
        accumulator.append(paramKey, value);
      });
    } else if (typeof obj[key] === "object" && Boolean(obj[key])) {
      let paramKey = `${parentKey}`;
      if (!paramKey.length) paramKey = key;
      else paramKey += `[${key}]`;
      objectToUrlParams(obj[key], paramKey, accumulator, level + 1);
    } else {
      let paramKey = key;
      if (parentKey) paramKey = `${parentKey}[${key}]`;
      accumulator.append(paramKey, obj[key]);
    }
  });
};

export function useTable(
  args = {
    getData: () => {},
    emailData: () => {},
    stats: [],
    options: new TableOptions(),
    headers: [],
    filter: {},
    items: [],
    meta: {},
    shouldMapData: true,
    shouldIncludeCancelToken: false,
    localSort: false
  }
) {
  const method = args.getData;
  const emailMethod = args.emailData;

  const headers = ref(args.headers || []);
  const options = ref(args.options || new TableOptions());
  const filter = ref(args.filter || {});
  const items = ref(args.items || []);
  const meta = ref(args.meta || {});
  const stats = ref(args.stats || {});

  const loading = ref(false);
  const tableHeaders = computed(() => {
    const showIfArgs = { ...filter.value, tableItems: items.value };
    return headers.value.filter(val => {
      if (val.showIf && val.showInTableIf) {
        return val.showIf(showIfArgs) && val.showInTableIf(showIfArgs);
      }

      if (val.showInTableIf) {
        return val.showInTableIf(showIfArgs) && !val.isAdditional;
      }

      if (val.showIf) {
        return val.showIf(showIfArgs) && !val.isAdditional;
      }

      return !val.isAdditional;
    });
  });

  const mappedItems = computed(() => tableMap(items.value, tableHeaders.value));

  const filterHeaders = computed(() => {
    const showIfArgs = { ...filter.value, tableItems: items.value };
    return headers.value.filter(val => {
      if (!val.showIf) return true;
      return val.showIf(showIfArgs);
    });
  });

  function resetPage() {
    options.value.page = 1;
  }

  function structureRequest(additionalFilter = {}) {
    const body = additionalFilter;
    toTableFilter(body);
    if (!args.localSort) {
      toTableSort(body);
      if (options.value.page >= 1) {
        body.page = options.value.page;
      }
      if (options.value.itemsPerPage >= 1) {
        body.count = options.value.itemsPerPage;
      }
    }
    if (body.filter && !Object.keys(body.filter).length) {
      delete body.filter;
    }
    return body;
  }

  function generateQuery(additionalFilter = {}) {
    const body = structureRequest();
    const params = new URLSearchParams();
    objectToUrlParams(body, "", params);
    for (const [key, value] of Object.entries(additionalFilter)) {
      if (Array.isArray(value)) value.forEach(v => params.append(key, v));
      else params.append(key, value);
    }
    return params;
  }

  let cancelToken = null;
  async function getData(additionalFilter = {}) {
    loading.value = true;
    try {
      let fn = () => method(generateQuery(additionalFilter));
      if (args.shouldIncludeCancelToken) {
        fn = () => method(generateQuery(additionalFilter), cancelToken);
      }
      const response = await fn();
      if (response?.items) items.value = response.items;
      else if (Array.isArray(response)) items.value = response;
      if (response?.meta) meta.value = response.meta;
      if (response?.stats) stats.value = response.stats;
      loading.value = false;
      return response;
    } catch (e) {
      loading.value = false;
      throw e;
    }
  }

  function emailData(additionalFilter = {}) {
    return emailMethod(structureRequest(additionalFilter || {}));
  }

  function toTableFilter(params = {}) {
    const filterKeys = Object.keys(filter.value);

    if (!filterKeys.length) return;
    filterKeys.forEach(rawKey => {
      const header = filterHeaders.value.find(h => h.value === rawKey);
      if (!header && headers.value.some(v => v.value === rawKey)) return;
      if (!header) {
        console.warn(`unable to find header ${rawKey}, is it in the list?`);
      }

      if (!filter.value[rawKey] && !header.sendFalse) return;
      let key = header.sortFilterMap || rawKey;

      const isDateType = [header.type, header.filterType].includes(
        TableHeader.DATE_TYPE
      );

      const isNumberFilter = [header.type, header.filterType].includes(
        TableHeader.NUMBER_RANGE
      );

      let rootFilter = params;
      if (!header.isQueryFilter) {
        if (!params.filter) params.filter = {};
        rootFilter = params.filter;
      }
      if (isDateType) {
        const finish = filter.value[rawKey].value.finish;
        const start = filter.value[rawKey].value.start;
        if (!start && !finish) return;

        if (!params.date) params.date = {};
        rootFilter = params.date;
        rootFilter[key] = {};
        if (finish) rootFilter[key]["finish"] = finish;
        if (start) rootFilter[key]["start"] = start;
        return;
      }

      if (isNumberFilter) {
        const min = filter.value[rawKey].value.min;
        const max = filter.value[rawKey].value.max;
        if (!min && !max) return;
        rootFilter[key] = {};
        if (min) rootFilter[key]["min"] = min;
        if (max) rootFilter[key]["max"] = max;
        return;
      }

      if (Array.isArray(header.sortFilterMap)) {
        header.sortFilterMap.forEach(sortFilter => {
          let val = get(filter.value[rawKey], sortFilter.value);
          if (!val) return;
          rootFilter[sortFilter.key] = val;
        });
        return;
      }

      if (typeof header.filterMap === "function") {
        header.filterMap(filter.value[rawKey], rootFilter);
        return;
      }

      let val = get(filter.value[rawKey], header.map) || filter.value[rawKey];
      if (header.sendFalse && val === false) rootFilter[key] = val;
      else if (val) rootFilter[key] = val;
    });
  }

  function toTableSort(params = {}) {
    if (!Object.keys(options.value.sortBy).length) return;
    options.value.sortBy.forEach((item, index) => {
      const header = tableHeaders.value.find(val => val.value === item);
      if (!header) return;
      const val = options.value.sortDesc[index];
      const key = header.sortKey || header.sortFilterMap;
      if (!params["sorting"]) params["sorting"] = {};
      params["sorting"][key] = val ? "desc" : "asc";
    });
  }

  function tableMap(
    data,
    headers,
    tableHelpers = { downloading: false, deleting: false, loading: false }
  ) {
    if (!data || !headers) return [];
    return data?.map((value, index) => {
      const rowData = {};
      headers.forEach(header => {
        if (header.type === TableHeader.DATE_TYPE) {
          const val = get(value, header.map);
          rowData[header.value] = val;
          return;
        }
        if (Array.isArray(header.map)) {
          const newVal = [];
          header.map.forEach(piece => {
            newVal.push(get(value, piece));
          });
          rowData[header.value] = newVal.join(" ");
          return;
        }
        if (typeof header.map === "function") {
          rowData[header.value] = header.map(value);
          return;
        }
        rowData[header.value] = get(value, header.map);
      });
      rowData.key = index + JSON.stringify(rowData);
      rowData.additional = {
        ...value,
        tableHelpers: cloneDeep(tableHelpers)
      };
      return rowData;
    });
  }

  function pageTextFormatter(tableOptions, meta) {
    if (!meta && !meta?.total) return "No Results";
    const total = meta?.total || meta;
    const currentMinValue =
      (tableOptions.page - 1) * tableOptions.itemsPerPage + 1;
    const currentMaxValue = Math.min(
      total,
      tableOptions.page * tableOptions.itemsPerPage
    );

    return `${numberFormat(currentMinValue)}-${numberFormat(
      currentMaxValue
    )} of ${numberFormat(total)}`;
  }

  function optionsEquivalence(newOptions) {
    return ["itemsPerPage", "page", "sortBy", "sortDesc"].every(
      v => newOptions[v] === options.value[v]
    );
  }

  function handleCustomSorts(newOptions) {
    if (!newOptions.sortBy.length) return;
    newOptions.sortBy.forEach((option, index) => {
      const header = tableHeaders.value.find(header => option === header.value);

      if (header.invertedSort) {
        const oldOptionsIndex = options.value.sortBy.findIndex(
          value => value === option
        );
        // If the value was not previously present, we need to reverse the table behavior & set to true
        if (oldOptionsIndex > -1) return;
        newOptions.sortDesc[index] = true;
      }
    });

    options.value = newOptions;
  }

  function ingestFromStore({
    filter: storeFilter,
    options: storeOptions,
    defaultFilter,
    defaultOptions
  }) {
    let useDefaultFilter = true;
    if (storeFilter && Object.keys(storeFilter).length) {
      Object.keys(storeFilter).forEach(key => {
        if (!headers.value.find(h => h.value === key)) return;
        filter.value[key] = storeFilter[key];
        useDefaultFilter = false;
      });
    }

    if (useDefaultFilter && defaultFilter) {
      ingestFromStore({ filter: defaultFilter });
    }

    let useDefaultSort = true;
    if (storeOptions?.sortBy?.length) {
      storeOptions.sortBy.forEach((key, index) => {
        if (!headers.value.find(h => h.value === key)) return;
        options.value.sortBy.push(key);
        options.value.sortDesc.push(storeOptions.sortDesc[index]);
        useDefaultSort = false;
      });
    }
    if (useDefaultSort && defaultOptions) {
      ingestFromStore({ options: defaultOptions });
    }
  }

  const footerProps = computed(() => {
    return {
      pageText: pageTextFormatter(options.value, meta.value),
      itemsPerPageOptions: [10, 20, 35, 50]
    };
  });

  return {
    headers,
    filter,
    options,
    items,
    meta,
    stats,
    loading,
    mappedItems,
    tableHeaders,
    filterHeaders,
    resetPage,
    getData,
    emailData,
    pageTextFormatter,
    optionsEquivalence,
    handleCustomSorts,
    ingestFromStore,
    footerProps
  };
}
